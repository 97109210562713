import { BaseRenderable } from "./base_renderable"

export class Other extends BaseRenderable {
    constructor(color: any = 0xff0000, size: number = 10, alpha = 1.0) {
        super()

        super.beginFill(color, alpha)
        super.lineStyle(size, color, alpha)
        super.drawCircle(1, 1, 2)
        super.endFill()
    }
}
