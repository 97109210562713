import { ObjectPool, ObjectPoolItem } from "game-common/object_pool"
import { randomId } from "game-common/util"
import { Sprite } from "pixi.js"

export interface SpritePoolItem extends ObjectPoolItem<Sprite> {}

export class SpritePool extends ObjectPool<Sprite> {
    static instance: SpritePool = new SpritePool()
    private defaultSprite: Sprite

    constructor(size: number = 1000) {
        super(size)
        this.defaultSprite = new Sprite()
    }

    create(): SpritePoolItem {
        return {
            free: true,
            data: new Sprite(),
        }
    }

    clear(item: SpritePoolItem) {
        // item.data.texture = this.defaultSprite.texture
        item.data.x = this.defaultSprite.x
        item.data.y = this.defaultSprite.y
        item.data.tint = this.defaultSprite.tint
        item.data.visible = this.defaultSprite.visible
        item.data.alpha = this.defaultSprite.alpha
        item.data.scale.set(this.defaultSprite.scale.x, this.defaultSprite.scale.y)
        item.data.rotation = this.defaultSprite.rotation
        item.data.zIndex = this.defaultSprite.zIndex
        item.data.removeAllListeners()
        item.data.interactive = false
    }
}
