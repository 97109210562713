export const lpcSpriteSheets = [
	"lpc/spritesheets/body/male/human/white.png.json", 
	"lpc/spritesheets/weapon/slash/male/dagger.png.json", 
	"lpc/spritesheets/weapon/slash/male/dagger_brown.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/behind/longsword.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/longsword.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/longsword.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/universal/flail.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/flail.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/universal/halberd.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/halberd.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/universal/waraxe.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/male/waraxe.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/behind/mace.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/mace.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/mace.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/behind/saber.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/saber.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/saber.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/behind/rapier.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/rapier.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/rapier.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/behind/scythe.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/universal/scythe.png.json", 
	"lpc/spritesheets/weapon/slash_oversize_layered/all/scythe.png.json", 
	"lpc/spritesheets/weapon/slash/male/axe_2.png.json", 
	"lpc/spritesheets/weapon/slash/male/warhammer.png.json", 
	"lpc/spritesheets/weapon/thrust/male/spear_2.png.json", 
	"lpc/spritesheets/weapon/thrust/male/spear_black.png.json", 
	"lpc/spritesheets/weapon/thrust/male/spear_green.png.json", 
	"lpc/spritesheets/weapon/thrust/male/spear_metal.png.json", 
	"lpc/spritesheets/weapon/thrust/male/spear_purple.png.json", 
	"lpc/spritesheets/weapon/bow/normal.png.json", 
	"lpc/spritesheets/weapon/thrust/male/crossbow.png.json", 
	"lpc/spritesheets/weapon/thrust/male/simple_staff.png.json", 
	"lpc/spritesheets/body/female/human/white.png.json", 
	"lpc/spritesheets/weapon/slash/female/dagger.png.json", 
	"lpc/spritesheets/weapon/slash/female/dagger_brown.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/universal/flail.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/flail.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/universal/halberd.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/halberd.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/universal/waraxe.png.json", 
	"lpc/spritesheets/weapon/slash_oversize/female/waraxe.png.json", 
	"lpc/spritesheets/weapon/slash/female/axe_2.png.json", 
	"lpc/spritesheets/weapon/slash/female/warhammer.png.json", 
	"lpc/spritesheets/weapon/thrust/female/spear_2.png.json", 
	"lpc/spritesheets/weapon/thrust/female/spear_black.png.json", 
	"lpc/spritesheets/weapon/thrust/female/spear_green.png.json", 
	"lpc/spritesheets/weapon/thrust/female/spear_metal.png.json", 
	"lpc/spritesheets/weapon/thrust/female/spear_purple.png.json", 
	"lpc/spritesheets/weapon/thrust/female/crossbow.png.json", 
	"lpc/spritesheets/weapon/thrust/female/simple_staff.png.json", 
	"lpc/spritesheets/body/male/human/black.png.json", 
	"lpc/spritesheets/body/female/human/black.png.json", 
	"lpc/spritesheets/body/male/human/olive.png.json", 
	"lpc/spritesheets/body/female/human/olive.png.json", 
	"lpc/spritesheets/body/male/human/brown.png.json", 
	"lpc/spritesheets/body/female/human/brown.png.json", 
	"lpc/spritesheets/body/male/human/light.png.json", 
	"lpc/spritesheets/body/female/human/light.png.json", 
	"lpc/spritesheets/body/male/human/dark.png.json", 
	"lpc/spritesheets/body/female/human/dark.png.json", 
	"lpc/spritesheets/body/male/human/tanned.png.json", 
	"lpc/spritesheets/body/female/human/tanned.png.json", 
	"lpc/spritesheets/body/male/reptiles/green_unwinged.png.json", 
	"lpc/spritesheets/body/female/reptiles/green_unwinged.png.json", 
	"lpc/spritesheets/body/male/reptiles/lightgreen_unwinged.png.json", 
	"lpc/spritesheets/body/female/reptiles/lightgreen_unwinged.png.json", 
	"lpc/spritesheets/body/male/reptiles/blue_unwinged.png.json", 
	"lpc/spritesheets/body/female/reptiles/blue_unwinged.png.json", 
	"lpc/spritesheets/body/male/reptiles/green_winged.png.json", 
	"lpc/spritesheets/body/female/reptiles/green_winged.png.json", 
	"lpc/spritesheets/body/male/reptiles/lightgreen_winged.png.json", 
	"lpc/spritesheets/body/female/reptiles/lightgreen_winged.png.json", 
	"lpc/spritesheets/body/male/reptiles/blue_winged.png.json", 
	"lpc/spritesheets/body/female/reptiles/blue_winged.png.json", 
	"lpc/spritesheets/body/male/orcs/gray.png.json", 
	"lpc/spritesheets/body/female/orcs/gray.png.json", 
	"lpc/spritesheets/body/male/orcs/green_1.png.json", 
	"lpc/spritesheets/body/female/orcs/green_1.png.json", 
	"lpc/spritesheets/body/male/orcs/green_3.png.json", 
	"lpc/spritesheets/body/female/orcs/green_3.png.json", 
	"lpc/spritesheets/body/male/wolf/brown.png.json", 
	"lpc/spritesheets/body/male/wolf/head/brown.png.json", 
	"lpc/spritesheets/body/female/wolf/brown.png.json", 
	"lpc/spritesheets/body/female/wolf/head/brown.png.json", 
	"lpc/spritesheets/body/male/wolf/black.png.json", 
	"lpc/spritesheets/body/male/wolf/head/black.png.json", 
	"lpc/spritesheets/body/female/wolf/black.png.json", 
	"lpc/spritesheets/body/female/wolf/head/black.png.json", 
	"lpc/spritesheets/body/male/wolf/gray.png.json", 
	"lpc/spritesheets/body/male/wolf/head/gray.png.json", 
	"lpc/spritesheets/body/female/wolf/gray.png.json", 
	"lpc/spritesheets/body/female/wolf/head/gray.png.json", 
	"lpc/spritesheets/body/male/wolf/white.png.json", 
	"lpc/spritesheets/body/male/wolf/head/white.png.json", 
	"lpc/spritesheets/body/female/wolf/white.png.json", 
	"lpc/spritesheets/body/female/wolf/head/white.png.json", 
	"lpc/spritesheets/body/skeleton/beige.png.json", 
	"lpc/spritesheets/body/skeleton/gray.png.json", 
	"lpc/spritesheets/body/skeleton/green.png.json", 
	"lpc/spritesheets/body/male/special/zombie.png.json", 
	"lpc/spritesheets/hair/afro/male/white.png.json", 
	"lpc/spritesheets/hair/afro/male/blonde.png.json", 
	"lpc/spritesheets/hair/afro/male/gold.png.json", 
	"lpc/spritesheets/hair/afro/male/green.png.json", 
	"lpc/spritesheets/hair/afro/male/blue.png.json", 
	"lpc/spritesheets/hair/afro/male/red.png.json", 
	"lpc/spritesheets/hair/afro/male/orange.png.json", 
	"lpc/spritesheets/hair/afro/male/purple.png.json", 
	"lpc/spritesheets/hair/afro/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/afro/male/light_brown.png.json", 
	"lpc/spritesheets/hair/afro/male/gray.png.json", 
	"lpc/spritesheets/hair/afro/male/black.png.json", 
	"lpc/spritesheets/hair/balding/male/white.png.json", 
	"lpc/spritesheets/hair/balding/male/blonde.png.json", 
	"lpc/spritesheets/hair/balding/male/gold.png.json", 
	"lpc/spritesheets/hair/balding/male/green.png.json", 
	"lpc/spritesheets/hair/balding/male/blue.png.json", 
	"lpc/spritesheets/hair/balding/male/red.png.json", 
	"lpc/spritesheets/hair/balding/male/orange.png.json", 
	"lpc/spritesheets/hair/balding/male/purple.png.json", 
	"lpc/spritesheets/hair/balding/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/balding/male/light_brown.png.json", 
	"lpc/spritesheets/hair/balding/male/gray.png.json", 
	"lpc/spritesheets/hair/balding/male/black.png.json", 
	"lpc/spritesheets/hair/bangs/male/white.png.json", 
	"lpc/spritesheets/hair/bangs/male/blonde.png.json", 
	"lpc/spritesheets/hair/bangs/male/gold.png.json", 
	"lpc/spritesheets/hair/bangs/male/green.png.json", 
	"lpc/spritesheets/hair/bangs/male/blue.png.json", 
	"lpc/spritesheets/hair/bangs/male/red.png.json", 
	"lpc/spritesheets/hair/bangs/male/orange.png.json", 
	"lpc/spritesheets/hair/bangs/male/purple.png.json", 
	"lpc/spritesheets/hair/bangs/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangs/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bangs/male/gray.png.json", 
	"lpc/spritesheets/hair/bangs/male/black.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/white.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/blonde.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/gold.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/green.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/blue.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/red.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/orange.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/purple.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/gray.png.json", 
	"lpc/spritesheets/hair/bangs_bun/male/black.png.json", 
	"lpc/spritesheets/hair/bangslong/male/white.png.json", 
	"lpc/spritesheets/hair/bangslong/male/blonde.png.json", 
	"lpc/spritesheets/hair/bangslong/male/gold.png.json", 
	"lpc/spritesheets/hair/bangslong/male/green.png.json", 
	"lpc/spritesheets/hair/bangslong/male/blue.png.json", 
	"lpc/spritesheets/hair/bangslong/male/red.png.json", 
	"lpc/spritesheets/hair/bangslong/male/orange.png.json", 
	"lpc/spritesheets/hair/bangslong/male/purple.png.json", 
	"lpc/spritesheets/hair/bangslong/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangslong/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bangslong/male/gray.png.json", 
	"lpc/spritesheets/hair/bangslong/male/black.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/white.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/blonde.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/gold.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/green.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/blue.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/red.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/orange.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/purple.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/gray.png.json", 
	"lpc/spritesheets/hair/bangslong2/male/black.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/white.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/blonde.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/gold.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/green.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/blue.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/red.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/orange.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/purple.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/gray.png.json", 
	"lpc/spritesheets/hair/bangsshort/male/black.png.json", 
	"lpc/spritesheets/hair/bedhead/male/white.png.json", 
	"lpc/spritesheets/hair/bedhead/male/blonde.png.json", 
	"lpc/spritesheets/hair/bedhead/male/gold.png.json", 
	"lpc/spritesheets/hair/bedhead/male/green.png.json", 
	"lpc/spritesheets/hair/bedhead/male/blue.png.json", 
	"lpc/spritesheets/hair/bedhead/male/red.png.json", 
	"lpc/spritesheets/hair/bedhead/male/orange.png.json", 
	"lpc/spritesheets/hair/bedhead/male/purple.png.json", 
	"lpc/spritesheets/hair/bedhead/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bedhead/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bedhead/male/gray.png.json", 
	"lpc/spritesheets/hair/bedhead/male/black.png.json", 
	"lpc/spritesheets/hair/bob/male/white.png.json", 
	"lpc/spritesheets/hair/bob/male/blonde.png.json", 
	"lpc/spritesheets/hair/bob/male/gold.png.json", 
	"lpc/spritesheets/hair/bob/male/green.png.json", 
	"lpc/spritesheets/hair/bob/male/blue.png.json", 
	"lpc/spritesheets/hair/bob/male/red.png.json", 
	"lpc/spritesheets/hair/bob/male/orange.png.json", 
	"lpc/spritesheets/hair/bob/male/purple.png.json", 
	"lpc/spritesheets/hair/bob/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bob/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bob/male/gray.png.json", 
	"lpc/spritesheets/hair/bob/male/black.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/white.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/blonde.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/gold.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/green.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/blue.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/red.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/orange.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/purple.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/gray.png.json", 
	"lpc/spritesheets/hair/bob_side_part/male/black.png.json", 
	"lpc/spritesheets/hair/braid/male/white.png.json", 
	"lpc/spritesheets/hair/braid/male/blonde.png.json", 
	"lpc/spritesheets/hair/braid/male/gold.png.json", 
	"lpc/spritesheets/hair/braid/male/green.png.json", 
	"lpc/spritesheets/hair/braid/male/blue.png.json", 
	"lpc/spritesheets/hair/braid/male/red.png.json", 
	"lpc/spritesheets/hair/braid/male/orange.png.json", 
	"lpc/spritesheets/hair/braid/male/purple.png.json", 
	"lpc/spritesheets/hair/braid/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/braid/male/light_brown.png.json", 
	"lpc/spritesheets/hair/braid/male/gray.png.json", 
	"lpc/spritesheets/hair/braid/male/black.png.json", 
	"lpc/spritesheets/hair/braid2/male/white.png.json", 
	"lpc/spritesheets/hair/braid2/male/blonde.png.json", 
	"lpc/spritesheets/hair/braid2/male/gold.png.json", 
	"lpc/spritesheets/hair/braid2/male/green.png.json", 
	"lpc/spritesheets/hair/braid2/male/blue.png.json", 
	"lpc/spritesheets/hair/braid2/male/red.png.json", 
	"lpc/spritesheets/hair/braid2/male/orange.png.json", 
	"lpc/spritesheets/hair/braid2/male/purple.png.json", 
	"lpc/spritesheets/hair/braid2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/braid2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/braid2/male/gray.png.json", 
	"lpc/spritesheets/hair/braid2/male/black.png.json", 
	"lpc/spritesheets/hair/bunches/male/white.png.json", 
	"lpc/spritesheets/hair/bunches/male/blonde.png.json", 
	"lpc/spritesheets/hair/bunches/male/gold.png.json", 
	"lpc/spritesheets/hair/bunches/male/green.png.json", 
	"lpc/spritesheets/hair/bunches/male/blue.png.json", 
	"lpc/spritesheets/hair/bunches/male/red.png.json", 
	"lpc/spritesheets/hair/bunches/male/orange.png.json", 
	"lpc/spritesheets/hair/bunches/male/purple.png.json", 
	"lpc/spritesheets/hair/bunches/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/bunches/male/light_brown.png.json", 
	"lpc/spritesheets/hair/bunches/male/gray.png.json", 
	"lpc/spritesheets/hair/bunches/male/black.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/white.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/blonde.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/gold.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/green.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/blue.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/red.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/orange.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/purple.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/light_brown.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/gray.png.json", 
	"lpc/spritesheets/hair/buzzcut/male/black.png.json", 
	"lpc/spritesheets/hair/cornrows/male/white.png.json", 
	"lpc/spritesheets/hair/cornrows/male/blonde.png.json", 
	"lpc/spritesheets/hair/cornrows/male/gold.png.json", 
	"lpc/spritesheets/hair/cornrows/male/green.png.json", 
	"lpc/spritesheets/hair/cornrows/male/blue.png.json", 
	"lpc/spritesheets/hair/cornrows/male/red.png.json", 
	"lpc/spritesheets/hair/cornrows/male/orange.png.json", 
	"lpc/spritesheets/hair/cornrows/male/purple.png.json", 
	"lpc/spritesheets/hair/cornrows/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/cornrows/male/light_brown.png.json", 
	"lpc/spritesheets/hair/cornrows/male/gray.png.json", 
	"lpc/spritesheets/hair/cornrows/male/black.png.json", 
	"lpc/spritesheets/hair/cowlick/male/white.png.json", 
	"lpc/spritesheets/hair/cowlick/male/blonde.png.json", 
	"lpc/spritesheets/hair/cowlick/male/gold.png.json", 
	"lpc/spritesheets/hair/cowlick/male/green.png.json", 
	"lpc/spritesheets/hair/cowlick/male/blue.png.json", 
	"lpc/spritesheets/hair/cowlick/male/red.png.json", 
	"lpc/spritesheets/hair/cowlick/male/orange.png.json", 
	"lpc/spritesheets/hair/cowlick/male/purple.png.json", 
	"lpc/spritesheets/hair/cowlick/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/cowlick/male/light_brown.png.json", 
	"lpc/spritesheets/hair/cowlick/male/gray.png.json", 
	"lpc/spritesheets/hair/cowlick/male/black.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/white.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/blonde.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/gold.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/green.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/blue.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/red.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/orange.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/purple.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/light_brown.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/gray.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/male/black.png.json", 
	"lpc/spritesheets/hair/curly_long/male/white.png.json", 
	"lpc/spritesheets/hair/curly_long/male/blonde.png.json", 
	"lpc/spritesheets/hair/curly_long/male/gold.png.json", 
	"lpc/spritesheets/hair/curly_long/male/green.png.json", 
	"lpc/spritesheets/hair/curly_long/male/blue.png.json", 
	"lpc/spritesheets/hair/curly_long/male/red.png.json", 
	"lpc/spritesheets/hair/curly_long/male/orange.png.json", 
	"lpc/spritesheets/hair/curly_long/male/purple.png.json", 
	"lpc/spritesheets/hair/curly_long/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/curly_long/male/light_brown.png.json", 
	"lpc/spritesheets/hair/curly_long/male/gray.png.json", 
	"lpc/spritesheets/hair/curly_long/male/black.png.json", 
	"lpc/spritesheets/hair/curly_short/male/white.png.json", 
	"lpc/spritesheets/hair/curly_short/male/blonde.png.json", 
	"lpc/spritesheets/hair/curly_short/male/gold.png.json", 
	"lpc/spritesheets/hair/curly_short/male/green.png.json", 
	"lpc/spritesheets/hair/curly_short/male/blue.png.json", 
	"lpc/spritesheets/hair/curly_short/male/red.png.json", 
	"lpc/spritesheets/hair/curly_short/male/orange.png.json", 
	"lpc/spritesheets/hair/curly_short/male/purple.png.json", 
	"lpc/spritesheets/hair/curly_short/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/curly_short/male/light_brown.png.json", 
	"lpc/spritesheets/hair/curly_short/male/gray.png.json", 
	"lpc/spritesheets/hair/curly_short/male/black.png.json", 
	"lpc/spritesheets/hair/curtains/male/white.png.json", 
	"lpc/spritesheets/hair/curtains/male/blonde.png.json", 
	"lpc/spritesheets/hair/curtains/male/gold.png.json", 
	"lpc/spritesheets/hair/curtains/male/green.png.json", 
	"lpc/spritesheets/hair/curtains/male/blue.png.json", 
	"lpc/spritesheets/hair/curtains/male/red.png.json", 
	"lpc/spritesheets/hair/curtains/male/orange.png.json", 
	"lpc/spritesheets/hair/curtains/male/purple.png.json", 
	"lpc/spritesheets/hair/curtains/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/curtains/male/light_brown.png.json", 
	"lpc/spritesheets/hair/curtains/male/gray.png.json", 
	"lpc/spritesheets/hair/curtains/male/black.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/white.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/blonde.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/gold.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/green.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/blue.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/red.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/orange.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/purple.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/light_brown.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/gray.png.json", 
	"lpc/spritesheets/hair/curtains_long/male/black.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/white.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/blonde.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/gold.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/green.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/blue.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/red.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/orange.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/purple.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/light_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/gray.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/male/black.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/white.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/blonde.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/gold.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/green.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/blue.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/red.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/orange.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/purple.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/light_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/gray.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/male/black.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/white.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/blonde.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/gold.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/green.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/blue.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/red.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/orange.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/purple.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/light_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/gray.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/male/black.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/white.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/blonde.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/gold.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/green.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/blue.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/red.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/orange.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/purple.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/light_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/gray.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/male/black.png.json", 
	"lpc/spritesheets/hair/half_up/male/white.png.json", 
	"lpc/spritesheets/hair/half_up/male/blonde.png.json", 
	"lpc/spritesheets/hair/half_up/male/gold.png.json", 
	"lpc/spritesheets/hair/half_up/male/green.png.json", 
	"lpc/spritesheets/hair/half_up/male/blue.png.json", 
	"lpc/spritesheets/hair/half_up/male/red.png.json", 
	"lpc/spritesheets/hair/half_up/male/orange.png.json", 
	"lpc/spritesheets/hair/half_up/male/purple.png.json", 
	"lpc/spritesheets/hair/half_up/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/half_up/male/light_brown.png.json", 
	"lpc/spritesheets/hair/half_up/male/gray.png.json", 
	"lpc/spritesheets/hair/half_up/male/black.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/white.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/blonde.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/gold.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/green.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/blue.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/red.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/orange.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/purple.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/light_brown.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/gray.png.json", 
	"lpc/spritesheets/hair/halfmessy/male/black.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/white.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/blonde.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/gold.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/green.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/blue.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/red.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/orange.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/purple.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/light_brown.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/gray.png.json", 
	"lpc/spritesheets/hair/high_and_tight/male/black.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/white.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/blonde.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/gold.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/green.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/blue.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/red.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/orange.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/purple.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/light_brown.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/gray.png.json", 
	"lpc/spritesheets/hair/high_ponytail/male/black.png.json", 
	"lpc/spritesheets/hair/idol/male/white.png.json", 
	"lpc/spritesheets/hair/idol/male/blonde.png.json", 
	"lpc/spritesheets/hair/idol/male/gold.png.json", 
	"lpc/spritesheets/hair/idol/male/green.png.json", 
	"lpc/spritesheets/hair/idol/male/blue.png.json", 
	"lpc/spritesheets/hair/idol/male/red.png.json", 
	"lpc/spritesheets/hair/idol/male/orange.png.json", 
	"lpc/spritesheets/hair/idol/male/purple.png.json", 
	"lpc/spritesheets/hair/idol/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/idol/male/light_brown.png.json", 
	"lpc/spritesheets/hair/idol/male/gray.png.json", 
	"lpc/spritesheets/hair/idol/male/black.png.json", 
	"lpc/spritesheets/hair/jewfro/male/white.png.json", 
	"lpc/spritesheets/hair/jewfro/male/blonde.png.json", 
	"lpc/spritesheets/hair/jewfro/male/gold.png.json", 
	"lpc/spritesheets/hair/jewfro/male/green.png.json", 
	"lpc/spritesheets/hair/jewfro/male/blue.png.json", 
	"lpc/spritesheets/hair/jewfro/male/red.png.json", 
	"lpc/spritesheets/hair/jewfro/male/orange.png.json", 
	"lpc/spritesheets/hair/jewfro/male/purple.png.json", 
	"lpc/spritesheets/hair/jewfro/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/jewfro/male/light_brown.png.json", 
	"lpc/spritesheets/hair/jewfro/male/gray.png.json", 
	"lpc/spritesheets/hair/jewfro/male/black.png.json", 
	"lpc/spritesheets/hair/lob/male/white.png.json", 
	"lpc/spritesheets/hair/lob/male/blonde.png.json", 
	"lpc/spritesheets/hair/lob/male/gold.png.json", 
	"lpc/spritesheets/hair/lob/male/green.png.json", 
	"lpc/spritesheets/hair/lob/male/blue.png.json", 
	"lpc/spritesheets/hair/lob/male/red.png.json", 
	"lpc/spritesheets/hair/lob/male/orange.png.json", 
	"lpc/spritesheets/hair/lob/male/purple.png.json", 
	"lpc/spritesheets/hair/lob/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/lob/male/light_brown.png.json", 
	"lpc/spritesheets/hair/lob/male/gray.png.json", 
	"lpc/spritesheets/hair/lob/male/black.png.json", 
	"lpc/spritesheets/hair/long/male/white.png.json", 
	"lpc/spritesheets/hair/long/male/blonde.png.json", 
	"lpc/spritesheets/hair/long/male/gold.png.json", 
	"lpc/spritesheets/hair/long/male/green.png.json", 
	"lpc/spritesheets/hair/long/male/blue.png.json", 
	"lpc/spritesheets/hair/long/male/red.png.json", 
	"lpc/spritesheets/hair/long/male/orange.png.json", 
	"lpc/spritesheets/hair/long/male/purple.png.json", 
	"lpc/spritesheets/hair/long/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long/male/gray.png.json", 
	"lpc/spritesheets/hair/long/male/black.png.json", 
	"lpc/spritesheets/hair/long_band/male/white.png.json", 
	"lpc/spritesheets/hair/long_band/male/blonde.png.json", 
	"lpc/spritesheets/hair/long_band/male/gold.png.json", 
	"lpc/spritesheets/hair/long_band/male/green.png.json", 
	"lpc/spritesheets/hair/long_band/male/blue.png.json", 
	"lpc/spritesheets/hair/long_band/male/red.png.json", 
	"lpc/spritesheets/hair/long_band/male/orange.png.json", 
	"lpc/spritesheets/hair/long_band/male/purple.png.json", 
	"lpc/spritesheets/hair/long_band/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_band/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long_band/male/gray.png.json", 
	"lpc/spritesheets/hair/long_band/male/black.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/white.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/blonde.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/gold.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/green.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/blue.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/red.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/orange.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/purple.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/gray.png.json", 
	"lpc/spritesheets/hair/long_center_part/male/black.png.json", 
	"lpc/spritesheets/hair/long_messy/male/white.png.json", 
	"lpc/spritesheets/hair/long_messy/male/blonde.png.json", 
	"lpc/spritesheets/hair/long_messy/male/gold.png.json", 
	"lpc/spritesheets/hair/long_messy/male/green.png.json", 
	"lpc/spritesheets/hair/long_messy/male/blue.png.json", 
	"lpc/spritesheets/hair/long_messy/male/red.png.json", 
	"lpc/spritesheets/hair/long_messy/male/orange.png.json", 
	"lpc/spritesheets/hair/long_messy/male/purple.png.json", 
	"lpc/spritesheets/hair/long_messy/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_messy/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long_messy/male/gray.png.json", 
	"lpc/spritesheets/hair/long_messy/male/black.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/white.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/blonde.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/gold.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/green.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/blue.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/red.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/orange.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/purple.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/gray.png.json", 
	"lpc/spritesheets/hair/long_messy2/male/black.png.json", 
	"lpc/spritesheets/hair/long_tied/male/white.png.json", 
	"lpc/spritesheets/hair/long_tied/male/blonde.png.json", 
	"lpc/spritesheets/hair/long_tied/male/gold.png.json", 
	"lpc/spritesheets/hair/long_tied/male/green.png.json", 
	"lpc/spritesheets/hair/long_tied/male/blue.png.json", 
	"lpc/spritesheets/hair/long_tied/male/red.png.json", 
	"lpc/spritesheets/hair/long_tied/male/orange.png.json", 
	"lpc/spritesheets/hair/long_tied/male/purple.png.json", 
	"lpc/spritesheets/hair/long_tied/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_tied/male/light_brown.png.json", 
	"lpc/spritesheets/hair/long_tied/male/gray.png.json", 
	"lpc/spritesheets/hair/long_tied/male/black.png.json", 
	"lpc/spritesheets/hair/longhawk/male/white.png.json", 
	"lpc/spritesheets/hair/longhawk/male/blonde.png.json", 
	"lpc/spritesheets/hair/longhawk/male/gold.png.json", 
	"lpc/spritesheets/hair/longhawk/male/green.png.json", 
	"lpc/spritesheets/hair/longhawk/male/blue.png.json", 
	"lpc/spritesheets/hair/longhawk/male/red.png.json", 
	"lpc/spritesheets/hair/longhawk/male/orange.png.json", 
	"lpc/spritesheets/hair/longhawk/male/purple.png.json", 
	"lpc/spritesheets/hair/longhawk/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/longhawk/male/light_brown.png.json", 
	"lpc/spritesheets/hair/longhawk/male/gray.png.json", 
	"lpc/spritesheets/hair/longhawk/male/black.png.json", 
	"lpc/spritesheets/hair/longknot/male/white.png.json", 
	"lpc/spritesheets/hair/longknot/male/blonde.png.json", 
	"lpc/spritesheets/hair/longknot/male/gold.png.json", 
	"lpc/spritesheets/hair/longknot/male/green.png.json", 
	"lpc/spritesheets/hair/longknot/male/blue.png.json", 
	"lpc/spritesheets/hair/longknot/male/red.png.json", 
	"lpc/spritesheets/hair/longknot/male/orange.png.json", 
	"lpc/spritesheets/hair/longknot/male/purple.png.json", 
	"lpc/spritesheets/hair/longknot/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/longknot/male/light_brown.png.json", 
	"lpc/spritesheets/hair/longknot/male/gray.png.json", 
	"lpc/spritesheets/hair/longknot/male/black.png.json", 
	"lpc/spritesheets/hair/loose/male/white.png.json", 
	"lpc/spritesheets/hair/loose/male/blonde.png.json", 
	"lpc/spritesheets/hair/loose/male/gold.png.json", 
	"lpc/spritesheets/hair/loose/male/green.png.json", 
	"lpc/spritesheets/hair/loose/male/blue.png.json", 
	"lpc/spritesheets/hair/loose/male/red.png.json", 
	"lpc/spritesheets/hair/loose/male/orange.png.json", 
	"lpc/spritesheets/hair/loose/male/purple.png.json", 
	"lpc/spritesheets/hair/loose/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/loose/male/light_brown.png.json", 
	"lpc/spritesheets/hair/loose/male/gray.png.json", 
	"lpc/spritesheets/hair/loose/male/black.png.json", 
	"lpc/spritesheets/hair/messy1/male/white.png.json", 
	"lpc/spritesheets/hair/messy1/male/blonde.png.json", 
	"lpc/spritesheets/hair/messy1/male/gold.png.json", 
	"lpc/spritesheets/hair/messy1/male/green.png.json", 
	"lpc/spritesheets/hair/messy1/male/blue.png.json", 
	"lpc/spritesheets/hair/messy1/male/red.png.json", 
	"lpc/spritesheets/hair/messy1/male/orange.png.json", 
	"lpc/spritesheets/hair/messy1/male/purple.png.json", 
	"lpc/spritesheets/hair/messy1/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy1/male/light_brown.png.json", 
	"lpc/spritesheets/hair/messy1/male/gray.png.json", 
	"lpc/spritesheets/hair/messy1/male/black.png.json", 
	"lpc/spritesheets/hair/messy2/male/white.png.json", 
	"lpc/spritesheets/hair/messy2/male/blonde.png.json", 
	"lpc/spritesheets/hair/messy2/male/gold.png.json", 
	"lpc/spritesheets/hair/messy2/male/green.png.json", 
	"lpc/spritesheets/hair/messy2/male/blue.png.json", 
	"lpc/spritesheets/hair/messy2/male/red.png.json", 
	"lpc/spritesheets/hair/messy2/male/orange.png.json", 
	"lpc/spritesheets/hair/messy2/male/purple.png.json", 
	"lpc/spritesheets/hair/messy2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/messy2/male/gray.png.json", 
	"lpc/spritesheets/hair/messy2/male/black.png.json", 
	"lpc/spritesheets/hair/messy3/male/white.png.json", 
	"lpc/spritesheets/hair/messy3/male/blonde.png.json", 
	"lpc/spritesheets/hair/messy3/male/gold.png.json", 
	"lpc/spritesheets/hair/messy3/male/green.png.json", 
	"lpc/spritesheets/hair/messy3/male/blue.png.json", 
	"lpc/spritesheets/hair/messy3/male/red.png.json", 
	"lpc/spritesheets/hair/messy3/male/orange.png.json", 
	"lpc/spritesheets/hair/messy3/male/purple.png.json", 
	"lpc/spritesheets/hair/messy3/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy3/male/light_brown.png.json", 
	"lpc/spritesheets/hair/messy3/male/gray.png.json", 
	"lpc/spritesheets/hair/messy3/male/black.png.json", 
	"lpc/spritesheets/hair/mop/male/white.png.json", 
	"lpc/spritesheets/hair/mop/male/blonde.png.json", 
	"lpc/spritesheets/hair/mop/male/gold.png.json", 
	"lpc/spritesheets/hair/mop/male/green.png.json", 
	"lpc/spritesheets/hair/mop/male/blue.png.json", 
	"lpc/spritesheets/hair/mop/male/red.png.json", 
	"lpc/spritesheets/hair/mop/male/orange.png.json", 
	"lpc/spritesheets/hair/mop/male/purple.png.json", 
	"lpc/spritesheets/hair/mop/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/mop/male/light_brown.png.json", 
	"lpc/spritesheets/hair/mop/male/gray.png.json", 
	"lpc/spritesheets/hair/mop/male/black.png.json", 
	"lpc/spritesheets/hair/natural/male/white.png.json", 
	"lpc/spritesheets/hair/natural/male/blonde.png.json", 
	"lpc/spritesheets/hair/natural/male/gold.png.json", 
	"lpc/spritesheets/hair/natural/male/green.png.json", 
	"lpc/spritesheets/hair/natural/male/blue.png.json", 
	"lpc/spritesheets/hair/natural/male/red.png.json", 
	"lpc/spritesheets/hair/natural/male/orange.png.json", 
	"lpc/spritesheets/hair/natural/male/purple.png.json", 
	"lpc/spritesheets/hair/natural/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/natural/male/light_brown.png.json", 
	"lpc/spritesheets/hair/natural/male/gray.png.json", 
	"lpc/spritesheets/hair/natural/male/black.png.json", 
	"lpc/spritesheets/hair/page/male/white.png.json", 
	"lpc/spritesheets/hair/page/male/blonde.png.json", 
	"lpc/spritesheets/hair/page/male/gold.png.json", 
	"lpc/spritesheets/hair/page/male/green.png.json", 
	"lpc/spritesheets/hair/page/male/blue.png.json", 
	"lpc/spritesheets/hair/page/male/red.png.json", 
	"lpc/spritesheets/hair/page/male/orange.png.json", 
	"lpc/spritesheets/hair/page/male/purple.png.json", 
	"lpc/spritesheets/hair/page/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/page/male/light_brown.png.json", 
	"lpc/spritesheets/hair/page/male/gray.png.json", 
	"lpc/spritesheets/hair/page/male/black.png.json", 
	"lpc/spritesheets/hair/page2/male/white.png.json", 
	"lpc/spritesheets/hair/page2/male/blonde.png.json", 
	"lpc/spritesheets/hair/page2/male/gold.png.json", 
	"lpc/spritesheets/hair/page2/male/green.png.json", 
	"lpc/spritesheets/hair/page2/male/blue.png.json", 
	"lpc/spritesheets/hair/page2/male/red.png.json", 
	"lpc/spritesheets/hair/page2/male/orange.png.json", 
	"lpc/spritesheets/hair/page2/male/purple.png.json", 
	"lpc/spritesheets/hair/page2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/page2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/page2/male/gray.png.json", 
	"lpc/spritesheets/hair/page2/male/black.png.json", 
	"lpc/spritesheets/hair/part2/male/white.png.json", 
	"lpc/spritesheets/hair/part2/male/blonde.png.json", 
	"lpc/spritesheets/hair/part2/male/gold.png.json", 
	"lpc/spritesheets/hair/part2/male/green.png.json", 
	"lpc/spritesheets/hair/part2/male/blue.png.json", 
	"lpc/spritesheets/hair/part2/male/red.png.json", 
	"lpc/spritesheets/hair/part2/male/orange.png.json", 
	"lpc/spritesheets/hair/part2/male/purple.png.json", 
	"lpc/spritesheets/hair/part2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/part2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/part2/male/gray.png.json", 
	"lpc/spritesheets/hair/part2/male/black.png.json", 
	"lpc/spritesheets/hair/parted/male/white.png.json", 
	"lpc/spritesheets/hair/parted/male/blonde.png.json", 
	"lpc/spritesheets/hair/parted/male/gold.png.json", 
	"lpc/spritesheets/hair/parted/male/green.png.json", 
	"lpc/spritesheets/hair/parted/male/blue.png.json", 
	"lpc/spritesheets/hair/parted/male/red.png.json", 
	"lpc/spritesheets/hair/parted/male/orange.png.json", 
	"lpc/spritesheets/hair/parted/male/purple.png.json", 
	"lpc/spritesheets/hair/parted/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/parted/male/light_brown.png.json", 
	"lpc/spritesheets/hair/parted/male/gray.png.json", 
	"lpc/spritesheets/hair/parted/male/black.png.json", 
	"lpc/spritesheets/hair/pixie/male/white.png.json", 
	"lpc/spritesheets/hair/pixie/male/blonde.png.json", 
	"lpc/spritesheets/hair/pixie/male/gold.png.json", 
	"lpc/spritesheets/hair/pixie/male/green.png.json", 
	"lpc/spritesheets/hair/pixie/male/blue.png.json", 
	"lpc/spritesheets/hair/pixie/male/red.png.json", 
	"lpc/spritesheets/hair/pixie/male/orange.png.json", 
	"lpc/spritesheets/hair/pixie/male/purple.png.json", 
	"lpc/spritesheets/hair/pixie/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/pixie/male/light_brown.png.json", 
	"lpc/spritesheets/hair/pixie/male/gray.png.json", 
	"lpc/spritesheets/hair/pixie/male/black.png.json", 
	"lpc/spritesheets/hair/plain/male/white.png.json", 
	"lpc/spritesheets/hair/plain/male/blonde.png.json", 
	"lpc/spritesheets/hair/plain/male/gold.png.json", 
	"lpc/spritesheets/hair/plain/male/green.png.json", 
	"lpc/spritesheets/hair/plain/male/blue.png.json", 
	"lpc/spritesheets/hair/plain/male/red.png.json", 
	"lpc/spritesheets/hair/plain/male/orange.png.json", 
	"lpc/spritesheets/hair/plain/male/purple.png.json", 
	"lpc/spritesheets/hair/plain/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/plain/male/light_brown.png.json", 
	"lpc/spritesheets/hair/plain/male/gray.png.json", 
	"lpc/spritesheets/hair/plain/male/black.png.json", 
	"lpc/spritesheets/hair/ponytail/male/white.png.json", 
	"lpc/spritesheets/hair/ponytail/male/blonde.png.json", 
	"lpc/spritesheets/hair/ponytail/male/gold.png.json", 
	"lpc/spritesheets/hair/ponytail/male/green.png.json", 
	"lpc/spritesheets/hair/ponytail/male/blue.png.json", 
	"lpc/spritesheets/hair/ponytail/male/red.png.json", 
	"lpc/spritesheets/hair/ponytail/male/orange.png.json", 
	"lpc/spritesheets/hair/ponytail/male/purple.png.json", 
	"lpc/spritesheets/hair/ponytail/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/ponytail/male/light_brown.png.json", 
	"lpc/spritesheets/hair/ponytail/male/gray.png.json", 
	"lpc/spritesheets/hair/ponytail/male/black.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/white.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/blonde.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/gold.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/green.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/blue.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/red.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/orange.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/purple.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/gray.png.json", 
	"lpc/spritesheets/hair/ponytail2/male/black.png.json", 
	"lpc/spritesheets/hair/princess/male/white.png.json", 
	"lpc/spritesheets/hair/princess/male/blonde.png.json", 
	"lpc/spritesheets/hair/princess/male/gold.png.json", 
	"lpc/spritesheets/hair/princess/male/green.png.json", 
	"lpc/spritesheets/hair/princess/male/blue.png.json", 
	"lpc/spritesheets/hair/princess/male/red.png.json", 
	"lpc/spritesheets/hair/princess/male/orange.png.json", 
	"lpc/spritesheets/hair/princess/male/purple.png.json", 
	"lpc/spritesheets/hair/princess/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/princess/male/light_brown.png.json", 
	"lpc/spritesheets/hair/princess/male/gray.png.json", 
	"lpc/spritesheets/hair/princess/male/black.png.json", 
	"lpc/spritesheets/hair/sara/male/white.png.json", 
	"lpc/spritesheets/hair/sara/male/blonde.png.json", 
	"lpc/spritesheets/hair/sara/male/gold.png.json", 
	"lpc/spritesheets/hair/sara/male/green.png.json", 
	"lpc/spritesheets/hair/sara/male/blue.png.json", 
	"lpc/spritesheets/hair/sara/male/red.png.json", 
	"lpc/spritesheets/hair/sara/male/orange.png.json", 
	"lpc/spritesheets/hair/sara/male/purple.png.json", 
	"lpc/spritesheets/hair/sara/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/sara/male/light_brown.png.json", 
	"lpc/spritesheets/hair/sara/male/gray.png.json", 
	"lpc/spritesheets/hair/sara/male/black.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/white.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/blonde.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/gold.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/green.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/blue.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/red.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/orange.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/purple.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/light_brown.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/gray.png.json", 
	"lpc/spritesheets/hair/shorthawk/male/black.png.json", 
	"lpc/spritesheets/hair/shortknot/male/white.png.json", 
	"lpc/spritesheets/hair/shortknot/male/blonde.png.json", 
	"lpc/spritesheets/hair/shortknot/male/gold.png.json", 
	"lpc/spritesheets/hair/shortknot/male/green.png.json", 
	"lpc/spritesheets/hair/shortknot/male/blue.png.json", 
	"lpc/spritesheets/hair/shortknot/male/red.png.json", 
	"lpc/spritesheets/hair/shortknot/male/orange.png.json", 
	"lpc/spritesheets/hair/shortknot/male/purple.png.json", 
	"lpc/spritesheets/hair/shortknot/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/shortknot/male/light_brown.png.json", 
	"lpc/spritesheets/hair/shortknot/male/gray.png.json", 
	"lpc/spritesheets/hair/shortknot/male/black.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/white.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/blonde.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/gold.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/green.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/blue.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/red.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/orange.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/purple.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/light_brown.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/gray.png.json", 
	"lpc/spritesheets/hair/shoulderl/male/black.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/white.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/blonde.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/gold.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/green.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/blue.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/red.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/orange.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/purple.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/light_brown.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/gray.png.json", 
	"lpc/spritesheets/hair/shoulderr/male/black.png.json", 
	"lpc/spritesheets/hair/single/male/white.png.json", 
	"lpc/spritesheets/hair/single/male/blonde.png.json", 
	"lpc/spritesheets/hair/single/male/gold.png.json", 
	"lpc/spritesheets/hair/single/male/green.png.json", 
	"lpc/spritesheets/hair/single/male/blue.png.json", 
	"lpc/spritesheets/hair/single/male/red.png.json", 
	"lpc/spritesheets/hair/single/male/orange.png.json", 
	"lpc/spritesheets/hair/single/male/purple.png.json", 
	"lpc/spritesheets/hair/single/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/single/male/light_brown.png.json", 
	"lpc/spritesheets/hair/single/male/gray.png.json", 
	"lpc/spritesheets/hair/single/male/black.png.json", 
	"lpc/spritesheets/hair/spiked/male/white.png.json", 
	"lpc/spritesheets/hair/spiked/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked/male/green.png.json", 
	"lpc/spritesheets/hair/spiked/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked/male/red.png.json", 
	"lpc/spritesheets/hair/spiked/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked/male/black.png.json", 
	"lpc/spritesheets/hair/spiked2/male/white.png.json", 
	"lpc/spritesheets/hair/spiked2/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked2/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked2/male/green.png.json", 
	"lpc/spritesheets/hair/spiked2/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked2/male/red.png.json", 
	"lpc/spritesheets/hair/spiked2/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked2/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked2/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked2/male/black.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/white.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/green.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/red.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/male/black.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/white.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/green.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/red.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/male/black.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/white.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/green.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/red.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/male/black.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/white.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/gold.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/green.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/blue.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/red.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/orange.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/purple.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/gray.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/male/black.png.json", 
	"lpc/spritesheets/hair/swoop/male/white.png.json", 
	"lpc/spritesheets/hair/swoop/male/blonde.png.json", 
	"lpc/spritesheets/hair/swoop/male/gold.png.json", 
	"lpc/spritesheets/hair/swoop/male/green.png.json", 
	"lpc/spritesheets/hair/swoop/male/blue.png.json", 
	"lpc/spritesheets/hair/swoop/male/red.png.json", 
	"lpc/spritesheets/hair/swoop/male/orange.png.json", 
	"lpc/spritesheets/hair/swoop/male/purple.png.json", 
	"lpc/spritesheets/hair/swoop/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/swoop/male/light_brown.png.json", 
	"lpc/spritesheets/hair/swoop/male/gray.png.json", 
	"lpc/spritesheets/hair/swoop/male/black.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/white.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/blonde.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/gold.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/green.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/blue.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/red.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/orange.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/purple.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/light_brown.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/gray.png.json", 
	"lpc/spritesheets/hair/twists_fade/male/black.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/white.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/blonde.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/gold.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/green.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/blue.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/red.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/orange.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/purple.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/light_brown.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/gray.png.json", 
	"lpc/spritesheets/hair/twists_straight/male/black.png.json", 
	"lpc/spritesheets/hair/unkempt/male/white.png.json", 
	"lpc/spritesheets/hair/unkempt/male/blonde.png.json", 
	"lpc/spritesheets/hair/unkempt/male/gold.png.json", 
	"lpc/spritesheets/hair/unkempt/male/green.png.json", 
	"lpc/spritesheets/hair/unkempt/male/blue.png.json", 
	"lpc/spritesheets/hair/unkempt/male/red.png.json", 
	"lpc/spritesheets/hair/unkempt/male/orange.png.json", 
	"lpc/spritesheets/hair/unkempt/male/purple.png.json", 
	"lpc/spritesheets/hair/unkempt/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/unkempt/male/light_brown.png.json", 
	"lpc/spritesheets/hair/unkempt/male/gray.png.json", 
	"lpc/spritesheets/hair/unkempt/male/black.png.json", 
	"lpc/spritesheets/hair/wavy/male/white.png.json", 
	"lpc/spritesheets/hair/wavy/male/blonde.png.json", 
	"lpc/spritesheets/hair/wavy/male/gold.png.json", 
	"lpc/spritesheets/hair/wavy/male/green.png.json", 
	"lpc/spritesheets/hair/wavy/male/blue.png.json", 
	"lpc/spritesheets/hair/wavy/male/red.png.json", 
	"lpc/spritesheets/hair/wavy/male/orange.png.json", 
	"lpc/spritesheets/hair/wavy/male/purple.png.json", 
	"lpc/spritesheets/hair/wavy/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/wavy/male/light_brown.png.json", 
	"lpc/spritesheets/hair/wavy/male/gray.png.json", 
	"lpc/spritesheets/hair/wavy/male/black.png.json", 
	"lpc/spritesheets/hair/xlong/male/white.png.json", 
	"lpc/spritesheets/hair/xlong/male/blonde.png.json", 
	"lpc/spritesheets/hair/xlong/male/gold.png.json", 
	"lpc/spritesheets/hair/xlong/male/green.png.json", 
	"lpc/spritesheets/hair/xlong/male/blue.png.json", 
	"lpc/spritesheets/hair/xlong/male/red.png.json", 
	"lpc/spritesheets/hair/xlong/male/orange.png.json", 
	"lpc/spritesheets/hair/xlong/male/purple.png.json", 
	"lpc/spritesheets/hair/xlong/male/dark_brown.png.json", 
	"lpc/spritesheets/hair/xlong/male/light_brown.png.json", 
	"lpc/spritesheets/hair/xlong/male/gray.png.json", 
	"lpc/spritesheets/hair/xlong/male/black.png.json", 
	"lpc/spritesheets/head/nose/big/male/light.png.json", 
	"lpc/spritesheets/head/nose/big/male/tanned.png.json", 
	"lpc/spritesheets/head/nose/big/male/darkelf.png.json", 
	"lpc/spritesheets/head/nose/button/male/light.png.json", 
	"lpc/spritesheets/head/nose/button/male/tanned.png.json", 
	"lpc/spritesheets/head/nose/button/male/darkelf.png.json", 
	"lpc/spritesheets/head/nose/straight/male/light.png.json", 
	"lpc/spritesheets/head/nose/straight/male/tanned.png.json", 
	"lpc/spritesheets/head/nose/straight/male/darkelf.png.json", 
	"lpc/spritesheets/eyes/male/blue.png.json", 
	"lpc/spritesheets/eyes/male/brown.png.json", 
	"lpc/spritesheets/eyes/male/gray.png.json", 
	"lpc/spritesheets/eyes/male/green.png.json", 
	"lpc/spritesheets/eyes/male/orange.png.json", 
	"lpc/spritesheets/eyes/male/purple.png.json", 
	"lpc/spritesheets/eyes/male/red.png.json", 
	"lpc/spritesheets/eyes/male/yellow.png.json", 
	"lpc/spritesheets/eyes/male/cyclope.png.json", 
	"lpc/spritesheets/legs/armour/male/7.png.json", 
	"lpc/spritesheets/legs/armour/male/8.png.json", 
	"lpc/spritesheets/legs/armour/male/9.png.json", 
	"lpc/spritesheets/legs/armour/male/10.png.json", 
	"lpc/spritesheets/legs/armour/male/11.png.json", 
	"lpc/spritesheets/legs/pants/male/black.png.json", 
	"lpc/spritesheets/legs/pants/male/blue.png.json", 
	"lpc/spritesheets/legs/pants/male/brown.png.json", 
	"lpc/spritesheets/legs/pants/male/gray.png.json", 
	"lpc/spritesheets/legs/pants/male/green.png.json", 
	"lpc/spritesheets/legs/pants/male/maroon.png.json", 
	"lpc/spritesheets/legs/pants/male/orange.png.json", 
	"lpc/spritesheets/legs/pants/male/purple.png.json", 
	"lpc/spritesheets/legs/pants/male/red.png.json", 
	"lpc/spritesheets/legs/pants/male/tan.png.json", 
	"lpc/spritesheets/legs/pants/male/white.png.json", 
	"lpc/spritesheets/legs/pants/male/yellow.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/black.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/blue.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/brown.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/gray.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/green.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/maroon.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/orange.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/purple.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/red.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/tan.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/white.png.json", 
	"lpc/spritesheets/legs/pantaloons/male/yellow.png.json", 
	"lpc/spritesheets/legs/skirts/male/legion.png.json", 
	"lpc/spritesheets/legs/skirts/male/brown.png.json", 
	"lpc/spritesheets/legs/skirts/male/black.png.json", 
	"lpc/spritesheets/legs/skirts/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/black.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/blue.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/green.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/purple.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/red.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/teal.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/male/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/black.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/blue.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/green.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/purple.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/red.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/teal.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/tan.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/gray.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve_laced/male/orange.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/black.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/blue.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/green.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/purple.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/red.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/teal.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/brown.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/tan.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/gray.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless/male/orange.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/black.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/blue.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/green.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/purple.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/red.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/teal.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/brown.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/tan.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/gray.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_laced/male/orange.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/black.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/blue.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/green.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/purple.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/red.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/teal.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/white.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/brown.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/tan.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/gray.png.json", 
	"lpc/spritesheets/torso/clothes/sleeveless_striped/male/orange.png.json", 
	"lpc/spritesheets/torso/armour/plate/male/11.png.json", 
	"lpc/spritesheets/torso/armour/plate/male/12.png.json", 
	"lpc/spritesheets/torso/armour/plate/male/14.png.json", 
	"lpc/spritesheets/torso/armour/plate/male/16.png.json", 
	"lpc/spritesheets/torso/armour/plate/male/special.png.json", 
	"lpc/spritesheets/torso/armour/leather/male/blue.png.json", 
	"lpc/spritesheets/torso/armour/leather/male/brown.png.json", 
	"lpc/spritesheets/torso/armour/leather/male/green.png.json", 
	"lpc/spritesheets/torso/armour/legion/male/bronze.png.json", 
	"lpc/spritesheets/torso/armour/legion/male/steel.png.json", 
	"lpc/spritesheets/torso/armour/legion/male/gold.png.json", 
	"lpc/spritesheets/feet/armor/male/4.png.json", 
	"lpc/spritesheets/feet/armor/male/5.png.json", 
	"lpc/spritesheets/feet/armor/male/6.png.json", 
	"lpc/spritesheets/feet/armor/male/7.png.json", 
	"lpc/spritesheets/feet/armor/male/8.png.json", 
	"lpc/spritesheets/feet/armor/male/9.png.json", 
	"lpc/spritesheets/feet/boots/male/black.png.json", 
	"lpc/spritesheets/feet/boots/male/blue.png.json", 
	"lpc/spritesheets/feet/boots/male/brown.png.json", 
	"lpc/spritesheets/feet/boots/male/gray.png.json", 
	"lpc/spritesheets/feet/boots/male/green.png.json", 
	"lpc/spritesheets/feet/boots/male/maroon.png.json", 
	"lpc/spritesheets/feet/boots/male/orange.png.json", 
	"lpc/spritesheets/feet/boots/male/purple.png.json", 
	"lpc/spritesheets/feet/boots/male/red.png.json", 
	"lpc/spritesheets/feet/boots/male/tan.png.json", 
	"lpc/spritesheets/feet/boots/male/teal.png.json", 
	"lpc/spritesheets/feet/boots/male/white.png.json", 
	"lpc/spritesheets/feet/boots/male/yellow.png.json", 
	"lpc/spritesheets/feet/sandals/male/brown.png.json", 
	"lpc/spritesheets/feet/shoes/male/black.png.json", 
	"lpc/spritesheets/feet/shoes/male/brown.png.json", 
	"lpc/spritesheets/feet/shoes/male/maroon.png.json", 
	"lpc/spritesheets/shield/male/crusader.png.json", 
	"lpc/spritesheets/shield/male/spartan.png.json", 
	"lpc/spritesheets/shield/male/round_black.png.json", 
	"lpc/spritesheets/shield/male/round_brown.png.json", 
	"lpc/spritesheets/shield/male/round_gold.png.json", 
	"lpc/spritesheets/shield/male/round_green.png.json", 
	"lpc/spritesheets/shield/male/round_silver.png.json", 
	"lpc/spritesheets/shield/male/round_yellow.png.json", 
	"lpc/spritesheets/shield/male/kite_gray.png.json", 
	"lpc/spritesheets/shield/male/kite_orange.png.json", 
	"lpc/spritesheets/shield/male/kite_blue_blue.png.json", 
	"lpc/spritesheets/shield/male/kite_blue_gray.png.json", 
	"lpc/spritesheets/shield/male/kite_gray_blue.png.json", 
	"lpc/spritesheets/shield/male/kite_gray_gray.png.json", 
	"lpc/spritesheets/shield/male/kite_gray_green.png.json", 
	"lpc/spritesheets/shield/male/kite_gray_orange.png.json", 
	"lpc/spritesheets/shield/male/kite_green_gray.png.json", 
	"lpc/spritesheets/shield/male/kite_red_gray.png.json", 
	"lpc/spritesheets/cape/solid/male/black.png.json", 
	"lpc/spritesheets/cape/solid_behind/black.png.json", 
	"lpc/spritesheets/cape/solid/male/blue.png.json", 
	"lpc/spritesheets/cape/solid_behind/blue.png.json", 
	"lpc/spritesheets/cape/solid/male/brown.png.json", 
	"lpc/spritesheets/cape/solid_behind/brown.png.json", 
	"lpc/spritesheets/cape/solid/male/gray.png.json", 
	"lpc/spritesheets/cape/solid_behind/gray.png.json", 
	"lpc/spritesheets/cape/solid/male/green.png.json", 
	"lpc/spritesheets/cape/solid_behind/green.png.json", 
	"lpc/spritesheets/cape/solid/male/maroon.png.json", 
	"lpc/spritesheets/cape/solid_behind/maroon.png.json", 
	"lpc/spritesheets/cape/solid/male/red.png.json", 
	"lpc/spritesheets/cape/solid_behind/red.png.json", 
	"lpc/spritesheets/cape/solid/male/white.png.json", 
	"lpc/spritesheets/cape/solid_behind/white.png.json", 
	"lpc/spritesheets/cape/solid/male/yellow.png.json", 
	"lpc/spritesheets/cape/solid_behind/yellow.png.json", 
	"lpc/spritesheets/bauldron/male/brown.png.json", 
	"lpc/spritesheets/hair/afro/female/white.png.json", 
	"lpc/spritesheets/hair/afro/female/blonde.png.json", 
	"lpc/spritesheets/hair/afro/female/gold.png.json", 
	"lpc/spritesheets/hair/afro/female/green.png.json", 
	"lpc/spritesheets/hair/afro/female/blue.png.json", 
	"lpc/spritesheets/hair/afro/female/red.png.json", 
	"lpc/spritesheets/hair/afro/female/orange.png.json", 
	"lpc/spritesheets/hair/afro/female/purple.png.json", 
	"lpc/spritesheets/hair/afro/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/afro/female/light_brown.png.json", 
	"lpc/spritesheets/hair/afro/female/gray.png.json", 
	"lpc/spritesheets/hair/afro/female/black.png.json", 
	"lpc/spritesheets/hair/balding/female/white.png.json", 
	"lpc/spritesheets/hair/balding/female/blonde.png.json", 
	"lpc/spritesheets/hair/balding/female/gold.png.json", 
	"lpc/spritesheets/hair/balding/female/green.png.json", 
	"lpc/spritesheets/hair/balding/female/blue.png.json", 
	"lpc/spritesheets/hair/balding/female/red.png.json", 
	"lpc/spritesheets/hair/balding/female/orange.png.json", 
	"lpc/spritesheets/hair/balding/female/purple.png.json", 
	"lpc/spritesheets/hair/balding/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/balding/female/light_brown.png.json", 
	"lpc/spritesheets/hair/balding/female/gray.png.json", 
	"lpc/spritesheets/hair/balding/female/black.png.json", 
	"lpc/spritesheets/hair/bangs/female/white.png.json", 
	"lpc/spritesheets/hair/bangs/female/blonde.png.json", 
	"lpc/spritesheets/hair/bangs/female/gold.png.json", 
	"lpc/spritesheets/hair/bangs/female/green.png.json", 
	"lpc/spritesheets/hair/bangs/female/blue.png.json", 
	"lpc/spritesheets/hair/bangs/female/red.png.json", 
	"lpc/spritesheets/hair/bangs/female/orange.png.json", 
	"lpc/spritesheets/hair/bangs/female/purple.png.json", 
	"lpc/spritesheets/hair/bangs/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangs/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bangs/female/gray.png.json", 
	"lpc/spritesheets/hair/bangs/female/black.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/white.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/blonde.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/gold.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/green.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/blue.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/red.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/orange.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/purple.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/gray.png.json", 
	"lpc/spritesheets/hair/bangs_bun/female/black.png.json", 
	"lpc/spritesheets/hair/bangslong/female/white.png.json", 
	"lpc/spritesheets/hair/bangslong/female/blonde.png.json", 
	"lpc/spritesheets/hair/bangslong/female/gold.png.json", 
	"lpc/spritesheets/hair/bangslong/female/green.png.json", 
	"lpc/spritesheets/hair/bangslong/female/blue.png.json", 
	"lpc/spritesheets/hair/bangslong/female/red.png.json", 
	"lpc/spritesheets/hair/bangslong/female/orange.png.json", 
	"lpc/spritesheets/hair/bangslong/female/purple.png.json", 
	"lpc/spritesheets/hair/bangslong/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangslong/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bangslong/female/gray.png.json", 
	"lpc/spritesheets/hair/bangslong/female/black.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/white.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/blonde.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/gold.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/green.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/blue.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/red.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/orange.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/purple.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/gray.png.json", 
	"lpc/spritesheets/hair/bangslong2/female/black.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/white.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/blonde.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/gold.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/green.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/blue.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/red.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/orange.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/purple.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/gray.png.json", 
	"lpc/spritesheets/hair/bangsshort/female/black.png.json", 
	"lpc/spritesheets/hair/bedhead/female/white.png.json", 
	"lpc/spritesheets/hair/bedhead/female/blonde.png.json", 
	"lpc/spritesheets/hair/bedhead/female/gold.png.json", 
	"lpc/spritesheets/hair/bedhead/female/green.png.json", 
	"lpc/spritesheets/hair/bedhead/female/blue.png.json", 
	"lpc/spritesheets/hair/bedhead/female/red.png.json", 
	"lpc/spritesheets/hair/bedhead/female/orange.png.json", 
	"lpc/spritesheets/hair/bedhead/female/purple.png.json", 
	"lpc/spritesheets/hair/bedhead/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bedhead/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bedhead/female/gray.png.json", 
	"lpc/spritesheets/hair/bedhead/female/black.png.json", 
	"lpc/spritesheets/hair/bob/female/white.png.json", 
	"lpc/spritesheets/hair/bob/female/blonde.png.json", 
	"lpc/spritesheets/hair/bob/female/gold.png.json", 
	"lpc/spritesheets/hair/bob/female/green.png.json", 
	"lpc/spritesheets/hair/bob/female/blue.png.json", 
	"lpc/spritesheets/hair/bob/female/red.png.json", 
	"lpc/spritesheets/hair/bob/female/orange.png.json", 
	"lpc/spritesheets/hair/bob/female/purple.png.json", 
	"lpc/spritesheets/hair/bob/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bob/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bob/female/gray.png.json", 
	"lpc/spritesheets/hair/bob/female/black.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/white.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/blonde.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/gold.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/green.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/blue.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/red.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/orange.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/purple.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/gray.png.json", 
	"lpc/spritesheets/hair/bob_side_part/female/black.png.json", 
	"lpc/spritesheets/hair/braid/female/white.png.json", 
	"lpc/spritesheets/hair/braid/female/blonde.png.json", 
	"lpc/spritesheets/hair/braid/female/gold.png.json", 
	"lpc/spritesheets/hair/braid/female/green.png.json", 
	"lpc/spritesheets/hair/braid/female/blue.png.json", 
	"lpc/spritesheets/hair/braid/female/red.png.json", 
	"lpc/spritesheets/hair/braid/female/orange.png.json", 
	"lpc/spritesheets/hair/braid/female/purple.png.json", 
	"lpc/spritesheets/hair/braid/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/braid/female/light_brown.png.json", 
	"lpc/spritesheets/hair/braid/female/gray.png.json", 
	"lpc/spritesheets/hair/braid/female/black.png.json", 
	"lpc/spritesheets/hair/braid2/female/white.png.json", 
	"lpc/spritesheets/hair/braid2/female/blonde.png.json", 
	"lpc/spritesheets/hair/braid2/female/gold.png.json", 
	"lpc/spritesheets/hair/braid2/female/green.png.json", 
	"lpc/spritesheets/hair/braid2/female/blue.png.json", 
	"lpc/spritesheets/hair/braid2/female/red.png.json", 
	"lpc/spritesheets/hair/braid2/female/orange.png.json", 
	"lpc/spritesheets/hair/braid2/female/purple.png.json", 
	"lpc/spritesheets/hair/braid2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/braid2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/braid2/female/gray.png.json", 
	"lpc/spritesheets/hair/braid2/female/black.png.json", 
	"lpc/spritesheets/hair/bunches/female/white.png.json", 
	"lpc/spritesheets/hair/bunches/female/blonde.png.json", 
	"lpc/spritesheets/hair/bunches/female/gold.png.json", 
	"lpc/spritesheets/hair/bunches/female/green.png.json", 
	"lpc/spritesheets/hair/bunches/female/blue.png.json", 
	"lpc/spritesheets/hair/bunches/female/red.png.json", 
	"lpc/spritesheets/hair/bunches/female/orange.png.json", 
	"lpc/spritesheets/hair/bunches/female/purple.png.json", 
	"lpc/spritesheets/hair/bunches/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/bunches/female/light_brown.png.json", 
	"lpc/spritesheets/hair/bunches/female/gray.png.json", 
	"lpc/spritesheets/hair/bunches/female/black.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/white.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/blonde.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/gold.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/green.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/blue.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/red.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/orange.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/purple.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/light_brown.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/gray.png.json", 
	"lpc/spritesheets/hair/buzzcut/female/black.png.json", 
	"lpc/spritesheets/hair/cornrows/female/white.png.json", 
	"lpc/spritesheets/hair/cornrows/female/blonde.png.json", 
	"lpc/spritesheets/hair/cornrows/female/gold.png.json", 
	"lpc/spritesheets/hair/cornrows/female/green.png.json", 
	"lpc/spritesheets/hair/cornrows/female/blue.png.json", 
	"lpc/spritesheets/hair/cornrows/female/red.png.json", 
	"lpc/spritesheets/hair/cornrows/female/orange.png.json", 
	"lpc/spritesheets/hair/cornrows/female/purple.png.json", 
	"lpc/spritesheets/hair/cornrows/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/cornrows/female/light_brown.png.json", 
	"lpc/spritesheets/hair/cornrows/female/gray.png.json", 
	"lpc/spritesheets/hair/cornrows/female/black.png.json", 
	"lpc/spritesheets/hair/cowlick/female/white.png.json", 
	"lpc/spritesheets/hair/cowlick/female/blonde.png.json", 
	"lpc/spritesheets/hair/cowlick/female/gold.png.json", 
	"lpc/spritesheets/hair/cowlick/female/green.png.json", 
	"lpc/spritesheets/hair/cowlick/female/blue.png.json", 
	"lpc/spritesheets/hair/cowlick/female/red.png.json", 
	"lpc/spritesheets/hair/cowlick/female/orange.png.json", 
	"lpc/spritesheets/hair/cowlick/female/purple.png.json", 
	"lpc/spritesheets/hair/cowlick/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/cowlick/female/light_brown.png.json", 
	"lpc/spritesheets/hair/cowlick/female/gray.png.json", 
	"lpc/spritesheets/hair/cowlick/female/black.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/white.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/blonde.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/gold.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/green.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/blue.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/red.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/orange.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/purple.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/light_brown.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/gray.png.json", 
	"lpc/spritesheets/hair/cowlick_tall/female/black.png.json", 
	"lpc/spritesheets/hair/curly_long/female/white.png.json", 
	"lpc/spritesheets/hair/curly_long/female/blonde.png.json", 
	"lpc/spritesheets/hair/curly_long/female/gold.png.json", 
	"lpc/spritesheets/hair/curly_long/female/green.png.json", 
	"lpc/spritesheets/hair/curly_long/female/blue.png.json", 
	"lpc/spritesheets/hair/curly_long/female/red.png.json", 
	"lpc/spritesheets/hair/curly_long/female/orange.png.json", 
	"lpc/spritesheets/hair/curly_long/female/purple.png.json", 
	"lpc/spritesheets/hair/curly_long/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/curly_long/female/light_brown.png.json", 
	"lpc/spritesheets/hair/curly_long/female/gray.png.json", 
	"lpc/spritesheets/hair/curly_long/female/black.png.json", 
	"lpc/spritesheets/hair/curly_short/female/white.png.json", 
	"lpc/spritesheets/hair/curly_short/female/blonde.png.json", 
	"lpc/spritesheets/hair/curly_short/female/gold.png.json", 
	"lpc/spritesheets/hair/curly_short/female/green.png.json", 
	"lpc/spritesheets/hair/curly_short/female/blue.png.json", 
	"lpc/spritesheets/hair/curly_short/female/red.png.json", 
	"lpc/spritesheets/hair/curly_short/female/orange.png.json", 
	"lpc/spritesheets/hair/curly_short/female/purple.png.json", 
	"lpc/spritesheets/hair/curly_short/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/curly_short/female/light_brown.png.json", 
	"lpc/spritesheets/hair/curly_short/female/gray.png.json", 
	"lpc/spritesheets/hair/curly_short/female/black.png.json", 
	"lpc/spritesheets/hair/curtains/female/white.png.json", 
	"lpc/spritesheets/hair/curtains/female/blonde.png.json", 
	"lpc/spritesheets/hair/curtains/female/gold.png.json", 
	"lpc/spritesheets/hair/curtains/female/green.png.json", 
	"lpc/spritesheets/hair/curtains/female/blue.png.json", 
	"lpc/spritesheets/hair/curtains/female/red.png.json", 
	"lpc/spritesheets/hair/curtains/female/orange.png.json", 
	"lpc/spritesheets/hair/curtains/female/purple.png.json", 
	"lpc/spritesheets/hair/curtains/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/curtains/female/light_brown.png.json", 
	"lpc/spritesheets/hair/curtains/female/gray.png.json", 
	"lpc/spritesheets/hair/curtains/female/black.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/white.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/blonde.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/gold.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/green.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/blue.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/red.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/orange.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/purple.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/light_brown.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/gray.png.json", 
	"lpc/spritesheets/hair/curtains_long/female/black.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/white.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/blonde.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/gold.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/green.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/blue.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/red.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/orange.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/purple.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/light_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/gray.png.json", 
	"lpc/spritesheets/hair/dreadlocks_long/female/black.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/white.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/blonde.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/gold.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/green.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/blue.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/red.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/orange.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/purple.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/light_brown.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/gray.png.json", 
	"lpc/spritesheets/hair/dreadlocks_short/female/black.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/white.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/blonde.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/gold.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/green.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/blue.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/red.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/orange.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/purple.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/light_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/gray.png.json", 
	"lpc/spritesheets/hair/flat_top_fade/female/black.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/white.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/blonde.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/gold.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/green.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/blue.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/red.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/orange.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/purple.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/light_brown.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/gray.png.json", 
	"lpc/spritesheets/hair/flat_top_straight/female/black.png.json", 
	"lpc/spritesheets/hair/half_up/female/white.png.json", 
	"lpc/spritesheets/hair/half_up/female/blonde.png.json", 
	"lpc/spritesheets/hair/half_up/female/gold.png.json", 
	"lpc/spritesheets/hair/half_up/female/green.png.json", 
	"lpc/spritesheets/hair/half_up/female/blue.png.json", 
	"lpc/spritesheets/hair/half_up/female/red.png.json", 
	"lpc/spritesheets/hair/half_up/female/orange.png.json", 
	"lpc/spritesheets/hair/half_up/female/purple.png.json", 
	"lpc/spritesheets/hair/half_up/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/half_up/female/light_brown.png.json", 
	"lpc/spritesheets/hair/half_up/female/gray.png.json", 
	"lpc/spritesheets/hair/half_up/female/black.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/white.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/blonde.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/gold.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/green.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/blue.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/red.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/orange.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/purple.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/light_brown.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/gray.png.json", 
	"lpc/spritesheets/hair/halfmessy/female/black.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/white.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/blonde.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/gold.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/green.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/blue.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/red.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/orange.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/purple.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/light_brown.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/gray.png.json", 
	"lpc/spritesheets/hair/high_and_tight/female/black.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/white.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/blonde.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/gold.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/green.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/blue.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/red.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/orange.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/purple.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/light_brown.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/gray.png.json", 
	"lpc/spritesheets/hair/high_ponytail/female/black.png.json", 
	"lpc/spritesheets/hair/idol/female/white.png.json", 
	"lpc/spritesheets/hair/idol/female/blonde.png.json", 
	"lpc/spritesheets/hair/idol/female/gold.png.json", 
	"lpc/spritesheets/hair/idol/female/green.png.json", 
	"lpc/spritesheets/hair/idol/female/blue.png.json", 
	"lpc/spritesheets/hair/idol/female/red.png.json", 
	"lpc/spritesheets/hair/idol/female/orange.png.json", 
	"lpc/spritesheets/hair/idol/female/purple.png.json", 
	"lpc/spritesheets/hair/idol/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/idol/female/light_brown.png.json", 
	"lpc/spritesheets/hair/idol/female/gray.png.json", 
	"lpc/spritesheets/hair/idol/female/black.png.json", 
	"lpc/spritesheets/hair/jewfro/female/white.png.json", 
	"lpc/spritesheets/hair/jewfro/female/blonde.png.json", 
	"lpc/spritesheets/hair/jewfro/female/gold.png.json", 
	"lpc/spritesheets/hair/jewfro/female/green.png.json", 
	"lpc/spritesheets/hair/jewfro/female/blue.png.json", 
	"lpc/spritesheets/hair/jewfro/female/red.png.json", 
	"lpc/spritesheets/hair/jewfro/female/orange.png.json", 
	"lpc/spritesheets/hair/jewfro/female/purple.png.json", 
	"lpc/spritesheets/hair/jewfro/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/jewfro/female/light_brown.png.json", 
	"lpc/spritesheets/hair/jewfro/female/gray.png.json", 
	"lpc/spritesheets/hair/jewfro/female/black.png.json", 
	"lpc/spritesheets/hair/lob/female/white.png.json", 
	"lpc/spritesheets/hair/lob/female/blonde.png.json", 
	"lpc/spritesheets/hair/lob/female/gold.png.json", 
	"lpc/spritesheets/hair/lob/female/green.png.json", 
	"lpc/spritesheets/hair/lob/female/blue.png.json", 
	"lpc/spritesheets/hair/lob/female/red.png.json", 
	"lpc/spritesheets/hair/lob/female/orange.png.json", 
	"lpc/spritesheets/hair/lob/female/purple.png.json", 
	"lpc/spritesheets/hair/lob/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/lob/female/light_brown.png.json", 
	"lpc/spritesheets/hair/lob/female/gray.png.json", 
	"lpc/spritesheets/hair/lob/female/black.png.json", 
	"lpc/spritesheets/hair/long/female/white.png.json", 
	"lpc/spritesheets/hair/long/female/blonde.png.json", 
	"lpc/spritesheets/hair/long/female/gold.png.json", 
	"lpc/spritesheets/hair/long/female/green.png.json", 
	"lpc/spritesheets/hair/long/female/blue.png.json", 
	"lpc/spritesheets/hair/long/female/red.png.json", 
	"lpc/spritesheets/hair/long/female/orange.png.json", 
	"lpc/spritesheets/hair/long/female/purple.png.json", 
	"lpc/spritesheets/hair/long/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long/female/gray.png.json", 
	"lpc/spritesheets/hair/long/female/black.png.json", 
	"lpc/spritesheets/hair/long_band/female/white.png.json", 
	"lpc/spritesheets/hair/long_band/female/blonde.png.json", 
	"lpc/spritesheets/hair/long_band/female/gold.png.json", 
	"lpc/spritesheets/hair/long_band/female/green.png.json", 
	"lpc/spritesheets/hair/long_band/female/blue.png.json", 
	"lpc/spritesheets/hair/long_band/female/red.png.json", 
	"lpc/spritesheets/hair/long_band/female/orange.png.json", 
	"lpc/spritesheets/hair/long_band/female/purple.png.json", 
	"lpc/spritesheets/hair/long_band/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_band/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long_band/female/gray.png.json", 
	"lpc/spritesheets/hair/long_band/female/black.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/white.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/blonde.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/gold.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/green.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/blue.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/red.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/orange.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/purple.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/gray.png.json", 
	"lpc/spritesheets/hair/long_center_part/female/black.png.json", 
	"lpc/spritesheets/hair/long_messy/female/white.png.json", 
	"lpc/spritesheets/hair/long_messy/female/blonde.png.json", 
	"lpc/spritesheets/hair/long_messy/female/gold.png.json", 
	"lpc/spritesheets/hair/long_messy/female/green.png.json", 
	"lpc/spritesheets/hair/long_messy/female/blue.png.json", 
	"lpc/spritesheets/hair/long_messy/female/red.png.json", 
	"lpc/spritesheets/hair/long_messy/female/orange.png.json", 
	"lpc/spritesheets/hair/long_messy/female/purple.png.json", 
	"lpc/spritesheets/hair/long_messy/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_messy/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long_messy/female/gray.png.json", 
	"lpc/spritesheets/hair/long_messy/female/black.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/white.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/blonde.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/gold.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/green.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/blue.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/red.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/orange.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/purple.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/gray.png.json", 
	"lpc/spritesheets/hair/long_messy2/female/black.png.json", 
	"lpc/spritesheets/hair/long_tied/female/white.png.json", 
	"lpc/spritesheets/hair/long_tied/female/blonde.png.json", 
	"lpc/spritesheets/hair/long_tied/female/gold.png.json", 
	"lpc/spritesheets/hair/long_tied/female/green.png.json", 
	"lpc/spritesheets/hair/long_tied/female/blue.png.json", 
	"lpc/spritesheets/hair/long_tied/female/red.png.json", 
	"lpc/spritesheets/hair/long_tied/female/orange.png.json", 
	"lpc/spritesheets/hair/long_tied/female/purple.png.json", 
	"lpc/spritesheets/hair/long_tied/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/long_tied/female/light_brown.png.json", 
	"lpc/spritesheets/hair/long_tied/female/gray.png.json", 
	"lpc/spritesheets/hair/long_tied/female/black.png.json", 
	"lpc/spritesheets/hair/longhawk/female/white.png.json", 
	"lpc/spritesheets/hair/longhawk/female/blonde.png.json", 
	"lpc/spritesheets/hair/longhawk/female/gold.png.json", 
	"lpc/spritesheets/hair/longhawk/female/green.png.json", 
	"lpc/spritesheets/hair/longhawk/female/blue.png.json", 
	"lpc/spritesheets/hair/longhawk/female/red.png.json", 
	"lpc/spritesheets/hair/longhawk/female/orange.png.json", 
	"lpc/spritesheets/hair/longhawk/female/purple.png.json", 
	"lpc/spritesheets/hair/longhawk/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/longhawk/female/light_brown.png.json", 
	"lpc/spritesheets/hair/longhawk/female/gray.png.json", 
	"lpc/spritesheets/hair/longhawk/female/black.png.json", 
	"lpc/spritesheets/hair/longknot/female/white.png.json", 
	"lpc/spritesheets/hair/longknot/female/blonde.png.json", 
	"lpc/spritesheets/hair/longknot/female/gold.png.json", 
	"lpc/spritesheets/hair/longknot/female/green.png.json", 
	"lpc/spritesheets/hair/longknot/female/blue.png.json", 
	"lpc/spritesheets/hair/longknot/female/red.png.json", 
	"lpc/spritesheets/hair/longknot/female/orange.png.json", 
	"lpc/spritesheets/hair/longknot/female/purple.png.json", 
	"lpc/spritesheets/hair/longknot/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/longknot/female/light_brown.png.json", 
	"lpc/spritesheets/hair/longknot/female/gray.png.json", 
	"lpc/spritesheets/hair/longknot/female/black.png.json", 
	"lpc/spritesheets/hair/loose/female/white.png.json", 
	"lpc/spritesheets/hair/loose/female/blonde.png.json", 
	"lpc/spritesheets/hair/loose/female/gold.png.json", 
	"lpc/spritesheets/hair/loose/female/green.png.json", 
	"lpc/spritesheets/hair/loose/female/blue.png.json", 
	"lpc/spritesheets/hair/loose/female/red.png.json", 
	"lpc/spritesheets/hair/loose/female/orange.png.json", 
	"lpc/spritesheets/hair/loose/female/purple.png.json", 
	"lpc/spritesheets/hair/loose/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/loose/female/light_brown.png.json", 
	"lpc/spritesheets/hair/loose/female/gray.png.json", 
	"lpc/spritesheets/hair/loose/female/black.png.json", 
	"lpc/spritesheets/hair/messy1/female/white.png.json", 
	"lpc/spritesheets/hair/messy1/female/blonde.png.json", 
	"lpc/spritesheets/hair/messy1/female/gold.png.json", 
	"lpc/spritesheets/hair/messy1/female/green.png.json", 
	"lpc/spritesheets/hair/messy1/female/blue.png.json", 
	"lpc/spritesheets/hair/messy1/female/red.png.json", 
	"lpc/spritesheets/hair/messy1/female/orange.png.json", 
	"lpc/spritesheets/hair/messy1/female/purple.png.json", 
	"lpc/spritesheets/hair/messy1/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy1/female/light_brown.png.json", 
	"lpc/spritesheets/hair/messy1/female/gray.png.json", 
	"lpc/spritesheets/hair/messy1/female/black.png.json", 
	"lpc/spritesheets/hair/messy2/female/white.png.json", 
	"lpc/spritesheets/hair/messy2/female/blonde.png.json", 
	"lpc/spritesheets/hair/messy2/female/gold.png.json", 
	"lpc/spritesheets/hair/messy2/female/green.png.json", 
	"lpc/spritesheets/hair/messy2/female/blue.png.json", 
	"lpc/spritesheets/hair/messy2/female/red.png.json", 
	"lpc/spritesheets/hair/messy2/female/orange.png.json", 
	"lpc/spritesheets/hair/messy2/female/purple.png.json", 
	"lpc/spritesheets/hair/messy2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/messy2/female/gray.png.json", 
	"lpc/spritesheets/hair/messy2/female/black.png.json", 
	"lpc/spritesheets/hair/messy3/female/white.png.json", 
	"lpc/spritesheets/hair/messy3/female/blonde.png.json", 
	"lpc/spritesheets/hair/messy3/female/gold.png.json", 
	"lpc/spritesheets/hair/messy3/female/green.png.json", 
	"lpc/spritesheets/hair/messy3/female/blue.png.json", 
	"lpc/spritesheets/hair/messy3/female/red.png.json", 
	"lpc/spritesheets/hair/messy3/female/orange.png.json", 
	"lpc/spritesheets/hair/messy3/female/purple.png.json", 
	"lpc/spritesheets/hair/messy3/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/messy3/female/light_brown.png.json", 
	"lpc/spritesheets/hair/messy3/female/gray.png.json", 
	"lpc/spritesheets/hair/messy3/female/black.png.json", 
	"lpc/spritesheets/hair/mop/female/white.png.json", 
	"lpc/spritesheets/hair/mop/female/blonde.png.json", 
	"lpc/spritesheets/hair/mop/female/gold.png.json", 
	"lpc/spritesheets/hair/mop/female/green.png.json", 
	"lpc/spritesheets/hair/mop/female/blue.png.json", 
	"lpc/spritesheets/hair/mop/female/red.png.json", 
	"lpc/spritesheets/hair/mop/female/orange.png.json", 
	"lpc/spritesheets/hair/mop/female/purple.png.json", 
	"lpc/spritesheets/hair/mop/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/mop/female/light_brown.png.json", 
	"lpc/spritesheets/hair/mop/female/gray.png.json", 
	"lpc/spritesheets/hair/mop/female/black.png.json", 
	"lpc/spritesheets/hair/natural/female/white.png.json", 
	"lpc/spritesheets/hair/natural/female/blonde.png.json", 
	"lpc/spritesheets/hair/natural/female/gold.png.json", 
	"lpc/spritesheets/hair/natural/female/green.png.json", 
	"lpc/spritesheets/hair/natural/female/blue.png.json", 
	"lpc/spritesheets/hair/natural/female/red.png.json", 
	"lpc/spritesheets/hair/natural/female/orange.png.json", 
	"lpc/spritesheets/hair/natural/female/purple.png.json", 
	"lpc/spritesheets/hair/natural/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/natural/female/light_brown.png.json", 
	"lpc/spritesheets/hair/natural/female/gray.png.json", 
	"lpc/spritesheets/hair/natural/female/black.png.json", 
	"lpc/spritesheets/hair/page/female/white.png.json", 
	"lpc/spritesheets/hair/page/female/blonde.png.json", 
	"lpc/spritesheets/hair/page/female/gold.png.json", 
	"lpc/spritesheets/hair/page/female/green.png.json", 
	"lpc/spritesheets/hair/page/female/blue.png.json", 
	"lpc/spritesheets/hair/page/female/red.png.json", 
	"lpc/spritesheets/hair/page/female/orange.png.json", 
	"lpc/spritesheets/hair/page/female/purple.png.json", 
	"lpc/spritesheets/hair/page/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/page/female/light_brown.png.json", 
	"lpc/spritesheets/hair/page/female/gray.png.json", 
	"lpc/spritesheets/hair/page/female/black.png.json", 
	"lpc/spritesheets/hair/page2/female/white.png.json", 
	"lpc/spritesheets/hair/page2/female/blonde.png.json", 
	"lpc/spritesheets/hair/page2/female/gold.png.json", 
	"lpc/spritesheets/hair/page2/female/green.png.json", 
	"lpc/spritesheets/hair/page2/female/blue.png.json", 
	"lpc/spritesheets/hair/page2/female/red.png.json", 
	"lpc/spritesheets/hair/page2/female/orange.png.json", 
	"lpc/spritesheets/hair/page2/female/purple.png.json", 
	"lpc/spritesheets/hair/page2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/page2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/page2/female/gray.png.json", 
	"lpc/spritesheets/hair/page2/female/black.png.json", 
	"lpc/spritesheets/hair/part2/female/white.png.json", 
	"lpc/spritesheets/hair/part2/female/blonde.png.json", 
	"lpc/spritesheets/hair/part2/female/gold.png.json", 
	"lpc/spritesheets/hair/part2/female/green.png.json", 
	"lpc/spritesheets/hair/part2/female/blue.png.json", 
	"lpc/spritesheets/hair/part2/female/red.png.json", 
	"lpc/spritesheets/hair/part2/female/orange.png.json", 
	"lpc/spritesheets/hair/part2/female/purple.png.json", 
	"lpc/spritesheets/hair/part2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/part2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/part2/female/gray.png.json", 
	"lpc/spritesheets/hair/part2/female/black.png.json", 
	"lpc/spritesheets/hair/parted/female/white.png.json", 
	"lpc/spritesheets/hair/parted/female/blonde.png.json", 
	"lpc/spritesheets/hair/parted/female/gold.png.json", 
	"lpc/spritesheets/hair/parted/female/green.png.json", 
	"lpc/spritesheets/hair/parted/female/blue.png.json", 
	"lpc/spritesheets/hair/parted/female/red.png.json", 
	"lpc/spritesheets/hair/parted/female/orange.png.json", 
	"lpc/spritesheets/hair/parted/female/purple.png.json", 
	"lpc/spritesheets/hair/parted/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/parted/female/light_brown.png.json", 
	"lpc/spritesheets/hair/parted/female/gray.png.json", 
	"lpc/spritesheets/hair/parted/female/black.png.json", 
	"lpc/spritesheets/hair/pixie/female/white.png.json", 
	"lpc/spritesheets/hair/pixie/female/blonde.png.json", 
	"lpc/spritesheets/hair/pixie/female/gold.png.json", 
	"lpc/spritesheets/hair/pixie/female/green.png.json", 
	"lpc/spritesheets/hair/pixie/female/blue.png.json", 
	"lpc/spritesheets/hair/pixie/female/red.png.json", 
	"lpc/spritesheets/hair/pixie/female/orange.png.json", 
	"lpc/spritesheets/hair/pixie/female/purple.png.json", 
	"lpc/spritesheets/hair/pixie/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/pixie/female/light_brown.png.json", 
	"lpc/spritesheets/hair/pixie/female/gray.png.json", 
	"lpc/spritesheets/hair/pixie/female/black.png.json", 
	"lpc/spritesheets/hair/plain/female/white.png.json", 
	"lpc/spritesheets/hair/plain/female/blonde.png.json", 
	"lpc/spritesheets/hair/plain/female/gold.png.json", 
	"lpc/spritesheets/hair/plain/female/green.png.json", 
	"lpc/spritesheets/hair/plain/female/blue.png.json", 
	"lpc/spritesheets/hair/plain/female/red.png.json", 
	"lpc/spritesheets/hair/plain/female/orange.png.json", 
	"lpc/spritesheets/hair/plain/female/purple.png.json", 
	"lpc/spritesheets/hair/plain/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/plain/female/light_brown.png.json", 
	"lpc/spritesheets/hair/plain/female/gray.png.json", 
	"lpc/spritesheets/hair/plain/female/black.png.json", 
	"lpc/spritesheets/hair/ponytail/female/white.png.json", 
	"lpc/spritesheets/hair/ponytail/female/blonde.png.json", 
	"lpc/spritesheets/hair/ponytail/female/gold.png.json", 
	"lpc/spritesheets/hair/ponytail/female/green.png.json", 
	"lpc/spritesheets/hair/ponytail/female/blue.png.json", 
	"lpc/spritesheets/hair/ponytail/female/red.png.json", 
	"lpc/spritesheets/hair/ponytail/female/orange.png.json", 
	"lpc/spritesheets/hair/ponytail/female/purple.png.json", 
	"lpc/spritesheets/hair/ponytail/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/ponytail/female/light_brown.png.json", 
	"lpc/spritesheets/hair/ponytail/female/gray.png.json", 
	"lpc/spritesheets/hair/ponytail/female/black.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/white.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/blonde.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/gold.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/green.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/blue.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/red.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/orange.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/purple.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/gray.png.json", 
	"lpc/spritesheets/hair/ponytail2/female/black.png.json", 
	"lpc/spritesheets/hair/princess/female/white.png.json", 
	"lpc/spritesheets/hair/princess/female/blonde.png.json", 
	"lpc/spritesheets/hair/princess/female/gold.png.json", 
	"lpc/spritesheets/hair/princess/female/green.png.json", 
	"lpc/spritesheets/hair/princess/female/blue.png.json", 
	"lpc/spritesheets/hair/princess/female/red.png.json", 
	"lpc/spritesheets/hair/princess/female/orange.png.json", 
	"lpc/spritesheets/hair/princess/female/purple.png.json", 
	"lpc/spritesheets/hair/princess/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/princess/female/light_brown.png.json", 
	"lpc/spritesheets/hair/princess/female/gray.png.json", 
	"lpc/spritesheets/hair/princess/female/black.png.json", 
	"lpc/spritesheets/hair/sara/female/white.png.json", 
	"lpc/spritesheets/hair/sara/female/blonde.png.json", 
	"lpc/spritesheets/hair/sara/female/gold.png.json", 
	"lpc/spritesheets/hair/sara/female/green.png.json", 
	"lpc/spritesheets/hair/sara/female/blue.png.json", 
	"lpc/spritesheets/hair/sara/female/red.png.json", 
	"lpc/spritesheets/hair/sara/female/orange.png.json", 
	"lpc/spritesheets/hair/sara/female/purple.png.json", 
	"lpc/spritesheets/hair/sara/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/sara/female/light_brown.png.json", 
	"lpc/spritesheets/hair/sara/female/gray.png.json", 
	"lpc/spritesheets/hair/sara/female/black.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/white.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/blonde.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/gold.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/green.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/blue.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/red.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/orange.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/purple.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/light_brown.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/gray.png.json", 
	"lpc/spritesheets/hair/shorthawk/female/black.png.json", 
	"lpc/spritesheets/hair/shortknot/female/white.png.json", 
	"lpc/spritesheets/hair/shortknot/female/blonde.png.json", 
	"lpc/spritesheets/hair/shortknot/female/gold.png.json", 
	"lpc/spritesheets/hair/shortknot/female/green.png.json", 
	"lpc/spritesheets/hair/shortknot/female/blue.png.json", 
	"lpc/spritesheets/hair/shortknot/female/red.png.json", 
	"lpc/spritesheets/hair/shortknot/female/orange.png.json", 
	"lpc/spritesheets/hair/shortknot/female/purple.png.json", 
	"lpc/spritesheets/hair/shortknot/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/shortknot/female/light_brown.png.json", 
	"lpc/spritesheets/hair/shortknot/female/gray.png.json", 
	"lpc/spritesheets/hair/shortknot/female/black.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/white.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/blonde.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/gold.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/green.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/blue.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/red.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/orange.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/purple.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/light_brown.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/gray.png.json", 
	"lpc/spritesheets/hair/shoulderl/female/black.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/white.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/blonde.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/gold.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/green.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/blue.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/red.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/orange.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/purple.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/light_brown.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/gray.png.json", 
	"lpc/spritesheets/hair/shoulderr/female/black.png.json", 
	"lpc/spritesheets/hair/single/female/white.png.json", 
	"lpc/spritesheets/hair/single/female/blonde.png.json", 
	"lpc/spritesheets/hair/single/female/gold.png.json", 
	"lpc/spritesheets/hair/single/female/green.png.json", 
	"lpc/spritesheets/hair/single/female/blue.png.json", 
	"lpc/spritesheets/hair/single/female/red.png.json", 
	"lpc/spritesheets/hair/single/female/orange.png.json", 
	"lpc/spritesheets/hair/single/female/purple.png.json", 
	"lpc/spritesheets/hair/single/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/single/female/light_brown.png.json", 
	"lpc/spritesheets/hair/single/female/gray.png.json", 
	"lpc/spritesheets/hair/single/female/black.png.json", 
	"lpc/spritesheets/hair/spiked/female/white.png.json", 
	"lpc/spritesheets/hair/spiked/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked/female/green.png.json", 
	"lpc/spritesheets/hair/spiked/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked/female/red.png.json", 
	"lpc/spritesheets/hair/spiked/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked/female/black.png.json", 
	"lpc/spritesheets/hair/spiked2/female/white.png.json", 
	"lpc/spritesheets/hair/spiked2/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked2/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked2/female/green.png.json", 
	"lpc/spritesheets/hair/spiked2/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked2/female/red.png.json", 
	"lpc/spritesheets/hair/spiked2/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked2/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked2/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked2/female/black.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/white.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/green.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/red.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked_beehive/female/black.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/white.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/green.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/red.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked_liberty/female/black.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/white.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/green.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/red.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked_liberty2/female/black.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/white.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/blonde.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/gold.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/green.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/blue.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/red.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/orange.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/purple.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/light_brown.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/gray.png.json", 
	"lpc/spritesheets/hair/spiked_porcupine/female/black.png.json", 
	"lpc/spritesheets/hair/swoop/female/white.png.json", 
	"lpc/spritesheets/hair/swoop/female/blonde.png.json", 
	"lpc/spritesheets/hair/swoop/female/gold.png.json", 
	"lpc/spritesheets/hair/swoop/female/green.png.json", 
	"lpc/spritesheets/hair/swoop/female/blue.png.json", 
	"lpc/spritesheets/hair/swoop/female/red.png.json", 
	"lpc/spritesheets/hair/swoop/female/orange.png.json", 
	"lpc/spritesheets/hair/swoop/female/purple.png.json", 
	"lpc/spritesheets/hair/swoop/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/swoop/female/light_brown.png.json", 
	"lpc/spritesheets/hair/swoop/female/gray.png.json", 
	"lpc/spritesheets/hair/swoop/female/black.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/white.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/blonde.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/gold.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/green.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/blue.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/red.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/orange.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/purple.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/light_brown.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/gray.png.json", 
	"lpc/spritesheets/hair/twists_fade/female/black.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/white.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/blonde.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/gold.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/green.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/blue.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/red.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/orange.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/purple.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/light_brown.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/gray.png.json", 
	"lpc/spritesheets/hair/twists_straight/female/black.png.json", 
	"lpc/spritesheets/hair/unkempt/female/white.png.json", 
	"lpc/spritesheets/hair/unkempt/female/blonde.png.json", 
	"lpc/spritesheets/hair/unkempt/female/gold.png.json", 
	"lpc/spritesheets/hair/unkempt/female/green.png.json", 
	"lpc/spritesheets/hair/unkempt/female/blue.png.json", 
	"lpc/spritesheets/hair/unkempt/female/red.png.json", 
	"lpc/spritesheets/hair/unkempt/female/orange.png.json", 
	"lpc/spritesheets/hair/unkempt/female/purple.png.json", 
	"lpc/spritesheets/hair/unkempt/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/unkempt/female/light_brown.png.json", 
	"lpc/spritesheets/hair/unkempt/female/gray.png.json", 
	"lpc/spritesheets/hair/unkempt/female/black.png.json", 
	"lpc/spritesheets/hair/wavy/female/white.png.json", 
	"lpc/spritesheets/hair/wavy/female/blonde.png.json", 
	"lpc/spritesheets/hair/wavy/female/gold.png.json", 
	"lpc/spritesheets/hair/wavy/female/green.png.json", 
	"lpc/spritesheets/hair/wavy/female/blue.png.json", 
	"lpc/spritesheets/hair/wavy/female/red.png.json", 
	"lpc/spritesheets/hair/wavy/female/orange.png.json", 
	"lpc/spritesheets/hair/wavy/female/purple.png.json", 
	"lpc/spritesheets/hair/wavy/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/wavy/female/light_brown.png.json", 
	"lpc/spritesheets/hair/wavy/female/gray.png.json", 
	"lpc/spritesheets/hair/wavy/female/black.png.json", 
	"lpc/spritesheets/hair/xlong/female/white.png.json", 
	"lpc/spritesheets/hair/xlong/female/blonde.png.json", 
	"lpc/spritesheets/hair/xlong/female/gold.png.json", 
	"lpc/spritesheets/hair/xlong/female/green.png.json", 
	"lpc/spritesheets/hair/xlong/female/blue.png.json", 
	"lpc/spritesheets/hair/xlong/female/red.png.json", 
	"lpc/spritesheets/hair/xlong/female/orange.png.json", 
	"lpc/spritesheets/hair/xlong/female/purple.png.json", 
	"lpc/spritesheets/hair/xlong/female/dark_brown.png.json", 
	"lpc/spritesheets/hair/xlong/female/light_brown.png.json", 
	"lpc/spritesheets/hair/xlong/female/gray.png.json", 
	"lpc/spritesheets/hair/xlong/female/black.png.json", 
	"lpc/spritesheets/head/nose/big/female/light.png.json", 
	"lpc/spritesheets/head/nose/big/female/tanned.png.json", 
	"lpc/spritesheets/head/nose/big/female/darkelf.png.json", 
	"lpc/spritesheets/head/nose/button/female/light.png.json", 
	"lpc/spritesheets/head/nose/button/female/tanned.png.json", 
	"lpc/spritesheets/head/nose/button/female/darkelf.png.json", 
	"lpc/spritesheets/head/nose/straight/female/light.png.json", 
	"lpc/spritesheets/head/nose/straight/female/tanned.png.json", 
	"lpc/spritesheets/head/nose/straight/female/darkelf.png.json", 
	"lpc/spritesheets/eyes/female/blue.png.json", 
	"lpc/spritesheets/eyes/female/brown.png.json", 
	"lpc/spritesheets/eyes/female/gray.png.json", 
	"lpc/spritesheets/eyes/female/green.png.json", 
	"lpc/spritesheets/eyes/female/orange.png.json", 
	"lpc/spritesheets/eyes/female/purple.png.json", 
	"lpc/spritesheets/eyes/female/red.png.json", 
	"lpc/spritesheets/eyes/female/yellow.png.json", 
	"lpc/spritesheets/eyes/female/cyclope.png.json", 
	"lpc/spritesheets/legs/armour/female/7.png.json", 
	"lpc/spritesheets/legs/armour/female/8.png.json", 
	"lpc/spritesheets/legs/armour/female/9.png.json", 
	"lpc/spritesheets/legs/armour/female/10.png.json", 
	"lpc/spritesheets/legs/armour/female/11.png.json", 
	"lpc/spritesheets/legs/pants/female/black.png.json", 
	"lpc/spritesheets/legs/pants/female/blue.png.json", 
	"lpc/spritesheets/legs/pants/female/brown.png.json", 
	"lpc/spritesheets/legs/pants/female/gray.png.json", 
	"lpc/spritesheets/legs/pants/female/green.png.json", 
	"lpc/spritesheets/legs/pants/female/maroon.png.json", 
	"lpc/spritesheets/legs/pants/female/orange.png.json", 
	"lpc/spritesheets/legs/pants/female/purple.png.json", 
	"lpc/spritesheets/legs/pants/female/red.png.json", 
	"lpc/spritesheets/legs/pants/female/tan.png.json", 
	"lpc/spritesheets/legs/pants/female/white.png.json", 
	"lpc/spritesheets/legs/pants/female/yellow.png.json", 
	"lpc/spritesheets/legs/skirts/female/legion.png.json", 
	"lpc/spritesheets/legs/skirts/female/brown.png.json", 
	"lpc/spritesheets/legs/skirts/female/black.png.json", 
	"lpc/spritesheets/legs/skirts/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/tan.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/gray.png.json", 
	"lpc/spritesheets/torso/clothes/blouse/female/orange.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/tan.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/gray.png.json", 
	"lpc/spritesheets/torso/clothes/blouse_longsleeve/female/orange.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/leather.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/tan.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/gray.png.json", 
	"lpc/spritesheets/torso/clothes/corset/female/orange.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/longsleeve/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/gray.png.json", 
	"lpc/spritesheets/torso/clothes/scoop_shirt/female/orange.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/black.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/blue.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/green.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/purple.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/red.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/white.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/yellow.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/tan.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/gray.png.json", 
	"lpc/spritesheets/torso/clothes/tanktop/female/orange.png.json", 
	"lpc/spritesheets/torso/clothes/tunic/female/brown.png.json", 
	"lpc/spritesheets/torso/clothes/tunic/female/maroon.png.json", 
	"lpc/spritesheets/torso/clothes/tunic/female/teal.png.json", 
	"lpc/spritesheets/torso/clothes/tunic/female/white.png.json", 
	"lpc/spritesheets/torso/armour/plate/female/11.png.json", 
	"lpc/spritesheets/torso/armour/plate/female/12.png.json", 
	"lpc/spritesheets/torso/armour/plate/female/14.png.json", 
	"lpc/spritesheets/torso/armour/plate/female/16.png.json", 
	"lpc/spritesheets/torso/armour/plate/female/special.png.json", 
	"lpc/spritesheets/torso/armour/leather/female/blue.png.json", 
	"lpc/spritesheets/torso/armour/leather/female/brown.png.json", 
	"lpc/spritesheets/torso/armour/leather/female/green.png.json", 
	"lpc/spritesheets/torso/armour/legion/female/bronze.png.json", 
	"lpc/spritesheets/torso/armour/legion/female/steel.png.json", 
	"lpc/spritesheets/torso/armour/legion/female/gold.png.json", 
	"lpc/spritesheets/feet/armor/female/4.png.json", 
	"lpc/spritesheets/feet/armor/female/5.png.json", 
	"lpc/spritesheets/feet/armor/female/6.png.json", 
	"lpc/spritesheets/feet/armor/female/7.png.json", 
	"lpc/spritesheets/feet/armor/female/8.png.json", 
	"lpc/spritesheets/feet/armor/female/9.png.json", 
	"lpc/spritesheets/feet/boots/female/black.png.json", 
	"lpc/spritesheets/feet/boots/female/blue.png.json", 
	"lpc/spritesheets/feet/boots/female/brown.png.json", 
	"lpc/spritesheets/feet/boots/female/gray.png.json", 
	"lpc/spritesheets/feet/boots/female/green.png.json", 
	"lpc/spritesheets/feet/boots/female/maroon.png.json", 
	"lpc/spritesheets/feet/boots/female/orange.png.json", 
	"lpc/spritesheets/feet/boots/female/purple.png.json", 
	"lpc/spritesheets/feet/boots/female/red.png.json", 
	"lpc/spritesheets/feet/boots/female/tan.png.json", 
	"lpc/spritesheets/feet/boots/female/teal.png.json", 
	"lpc/spritesheets/feet/boots/female/white.png.json", 
	"lpc/spritesheets/feet/boots/female/yellow.png.json", 
	"lpc/spritesheets/feet/sandals/female/brown.png.json", 
	"lpc/spritesheets/feet/shoes/female/black.png.json", 
	"lpc/spritesheets/feet/shoes/female/brown.png.json", 
	"lpc/spritesheets/feet/shoes/female/maroon.png.json", 
	"lpc/spritesheets/feet/slippers/female/black.png.json", 
	"lpc/spritesheets/feet/slippers/female/brown.png.json", 
	"lpc/spritesheets/feet/slippers/female/gray.png.json", 
	"lpc/spritesheets/shield/female/crusader.png.json", 
	"lpc/spritesheets/shield/female/spartan.png.json", 
	"lpc/spritesheets/shield/female/round_black.png.json", 
	"lpc/spritesheets/shield/female/round_brown.png.json", 
	"lpc/spritesheets/shield/female/round_gold.png.json", 
	"lpc/spritesheets/shield/female/round_green.png.json", 
	"lpc/spritesheets/shield/female/round_silver.png.json", 
	"lpc/spritesheets/shield/female/round_yellow.png.json", 
	"lpc/spritesheets/shield/female/kite_gray.png.json", 
	"lpc/spritesheets/shield/female/kite_orange.png.json", 
	"lpc/spritesheets/shield/female/kite_blue_blue.png.json", 
	"lpc/spritesheets/shield/female/kite_blue_gray.png.json", 
	"lpc/spritesheets/shield/female/kite_gray_blue.png.json", 
	"lpc/spritesheets/shield/female/kite_gray_gray.png.json", 
	"lpc/spritesheets/shield/female/kite_gray_green.png.json", 
	"lpc/spritesheets/shield/female/kite_gray_orange.png.json", 
	"lpc/spritesheets/shield/female/kite_green_gray.png.json", 
	"lpc/spritesheets/shield/female/kite_red_gray.png.json", 
	"lpc/spritesheets/cape/tattered/female/black.png.json", 
	"lpc/spritesheets/cape/tattered_behind/black.png.json", 
	"lpc/spritesheets/cape/tattered/female/blue.png.json", 
	"lpc/spritesheets/cape/tattered_behind/blue.png.json", 
	"lpc/spritesheets/cape/tattered/female/brown.png.json", 
	"lpc/spritesheets/cape/tattered_behind/brown.png.json", 
	"lpc/spritesheets/cape/tattered/female/gray.png.json", 
	"lpc/spritesheets/cape/tattered_behind/gray.png.json", 
	"lpc/spritesheets/cape/tattered/female/green.png.json", 
	"lpc/spritesheets/cape/tattered_behind/green.png.json", 
	"lpc/spritesheets/cape/tattered/female/maroon.png.json", 
	"lpc/spritesheets/cape/tattered_behind/maroon.png.json", 
	"lpc/spritesheets/cape/tattered/female/red.png.json", 
	"lpc/spritesheets/cape/tattered_behind/red.png.json", 
	"lpc/spritesheets/cape/tattered/female/white.png.json", 
	"lpc/spritesheets/cape/tattered_behind/white.png.json", 
	"lpc/spritesheets/cape/tattered/female/yellow.png.json", 
	"lpc/spritesheets/cape/tattered_behind/yellow.png.json", 
	"lpc/spritesheets/cape/trimmed/female/white_blue.png.json", 
	"lpc/spritesheets/cape/trimmed_behind/white_blue.png.json", 
	"lpc/spritesheets/bauldron/female/brown.png.json"
]
