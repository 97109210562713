import { ActionType } from "game-common/character/character"
import { Coordinate, Entity, randomIntBetween } from "game-common/models"
import { degrees_to_radians, radians_to_cardinal_dir } from "game-common/util"
import {
    AnimatedSprite,
    BitmapText,
    Container,
    Graphics,
    Loader,
    LoaderResource,
    Rectangle,
    Sprite,
    Texture,
} from "pixi.js"
import { SpriteMeta } from "../entity_rendering_manager"
import { BaseRenderable } from "./base_renderable"

const dirs = ["left", "right", "front", "back"]

const SheetLoadingState: Record<string, "loading" | "loaded"> = {}

export const AnimationDictionaryTypes = [
    "walk-left",
    "walk-right",
    "walk-back",
    "walk-front",
    "idle-left",
    "idle-right",
    "idle-back",
    "idle-front",
    "thrust-left",
    "thrust-right",
    "thrust-back",
    "thrust-front",
    "slash-left",
    "slash-right",
    "slash-back",
    "slash-front",
    "shoot-left",
    "shoot-right",
    "shoot-back",
    "shoot-front",
    "hurt-front",
    "activate",
    "deactivate",
    "inactive",
    "active",
] as const

export type AnimationDictionaryType = (typeof AnimationDictionaryTypes)[number]

export type LpcAnimationRenderMeta = Record<AnimationDictionaryType, SpriteMeta>

export interface LpcSpriteRenderMeta {
    alwaysShowLabel?: boolean
    disallowMoveAttackAnimation?: boolean
    sheetId: string
    renderMeta?: Partial<LpcAnimationRenderMeta>
    attackAction?: ActionType
    angle?: number
    hasHalo?: boolean
    defaultAction?: AnimationDictionaryType
}

const defaultRenderMeta: LpcAnimationRenderMeta = {
    "walk-left": {
        scale: 1,
        speed: 0.25,
    },
    activate: {
        scale: 1,
        speed: 0.25,
    },
    deactivate: {
        scale: 1,
        speed: 0.25,
    },
    "walk-right": {
        scale: 1,
        speed: 0.25,
    },
    "walk-back": {
        scale: 1,
        speed: 0.25,
    },
    "walk-front": {
        scale: 1,
        speed: 0.25,
    },
    inactive: {
        scale: 1,
    },
    active: {
        scale: 1,
    },
    "idle-left": {
        scale: 1,
    },
    "idle-right": {
        scale: 1,
    },
    "idle-back": {
        scale: 1,
    },
    "idle-front": {
        scale: 1,
    },
    "thrust-left": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-right": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-back": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-front": {
        scale: 1,
        speed: 0.55,
    },
    "slash-left": {
        scale: 1,
        speed: 0.25,
    },
    "slash-right": {
        scale: 1,
        speed: 0.25,
    },
    "slash-back": {
        scale: 1,
        speed: 0.25,
    },
    "slash-front": {
        scale: 1,
        speed: 0.25,
    },
    "shoot-left": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-right": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-back": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-front": {
        scale: 1,
        speed: 0.85,
    },
    "hurt-front": {
        scale: 1,
    },
}

const animationToAction: Record<AnimationDictionaryType, ActionType> = {
    "walk-left": "walk",
    "walk-right": "walk",
    "walk-back": "walk",
    "walk-front": "walk",
    "idle-left": "idle",
    "idle-right": "idle",
    "idle-back": "idle",
    "idle-front": "idle",
    "thrust-left": "thrust",
    "thrust-right": "thrust",
    "thrust-back": "thrust",
    "thrust-front": "thrust",
    "slash-left": "slash",
    "slash-right": "slash",
    "slash-back": "slash",
    "slash-front": "slash",
    "shoot-left": "thrust",
    "shoot-right": "thrust",
    "shoot-back": "thrust",
    "shoot-front": "thrust",
    "hurt-front": "hurt",
    activate: "activate",
    deactivate: "deactivate",
    inactive: "inactive",
    active: "active",
}

const isAttackAnimation = (animationName: AnimationDictionaryType) =>
    ["shoot", "thrust", "slash"].includes(animationToAction[animationName])

const isWalkAnimation = (animationName: AnimationDictionaryType) => ["walk"].includes(animationToAction[animationName])

const extractDirection = (animation: AnimationDictionaryType) => animation.split("-")[1]

export class LpcSprite extends BaseRenderable {
    spriteAngle: number = 0

    currentColor: number
    animation: AnimatedSprite
    sprite: Sprite
    labelText: BitmapText

    container: Container

    lastX: number
    lastY: number

    action: AnimationDictionaryType = "idle-front"
    prevAction: AnimationDictionaryType = "idle-front"

    animations: Record<AnimationDictionaryType, AnimatedSprite> = {} as Record<AnimationDictionaryType, AnimatedSprite>

    idleTimeout: any

    disallowMoveAttackAnimation: boolean
    renderMeta: LpcAnimationRenderMeta
    spriteRootName: string

    facingDir: string = "front"
    sheetId: string

    spritesheetResource: LoaderResource
    loadingInterval: any

    attackAction: ActionType = "idle"
    halo: Graphics
    defaultAnimationType: AnimationDictionaryType

    constructor({
        sheetId,
        renderMeta = defaultRenderMeta,
        attackAction,
        angle,
        hasHalo,
        defaultAction = "idle-front",
    }: LpcSpriteRenderMeta) {
        super()

        this.renderMeta = { ...defaultRenderMeta, ...renderMeta }
        this.attackAction = attackAction

        this.spriteAngle = angle
        this.facingDir = this.calcDir(angle)

        this.container = new Graphics()
        this.addChild(this.container)

        if (hasHalo) {
            const halo = new Graphics()
            halo.alpha = 0.5
            halo.beginFill(0xffffff)
            halo.drawCircle(0, -10, 12)
            halo.endFill()
            this.halo = halo
            this.container.addChild(halo)
        }

        this.sheetId = sheetId
        this.defaultAnimationType = defaultAction
        this.initalize()
    }

    initalize = () => {
        const loadingState = SheetLoadingState[this.sheetId]
        if (loadingState === "loaded") {
            this.setup()
            return
        }

        if (loadingState === "loading") {
            this.loadingInterval = setInterval(() => {
                const loadingState = SheetLoadingState[this.sheetId]
                if (loadingState === "loaded") {
                    this.setup()
                    clearInterval(this.loadingInterval)
                    return
                } else {
                    // ... keep on waiting until its loaded!
                }
            }, 1000)

            return
        }

        // its not loaded at all -- start loading it!
        SheetLoadingState[this.sheetId] = "loading"
        Loader.shared.add(this.sheetId, `assets/${this.sheetId}`, () => {
            SheetLoadingState[this.sheetId] = "loaded"
            this.setup()
        })
    }

    setup = () => {
        const loader = Loader.shared
        this.spritesheetResource = loader.resources[this.sheetId]

        const sheet = this.spritesheetResource?.spritesheet
        if (!sheet) {
            return
        }

        this.disallowMoveAttackAnimation = false

        AnimationDictionaryTypes.forEach(animationName => {
            const source = this.renderMeta[animationName]
            if (!source) {
                return
            }
            const { scale, speed = 0.267, color = 0xffffff } = source

            const resource = sheet.animations[animationName]
            if (!resource) {
                return
            }
            const animation = new AnimatedSprite(resource)
            animation.scale.set(scale)
            animation.animationSpeed = speed
            animation.visible = false
            animation.tint = color

            this.container.addChild(animation)
            this.animations[animationName] = animation
            if (isAttackAnimation(animationName)) {
                animation.loop = false
                animation.onComplete = () => {
                    this.activateAnimation(`idle-${extractDirection(this.action)}` as AnimationDictionaryType)
                }
            }
        })
        this.action = "idle-front"
        this.activateAnimation(this.action, true)
    }

    recolor = (color: number) => {
        if (this.currentColor === color) {
            return
        }
        this.currentColor = color
        if (this.animation) {
            this.animation.tint = color
        }
        if (this.sprite) {
            this.sprite.tint = color
        }
    }

    calcDir(angle: number): string {
        return radians_to_cardinal_dir(angle)
    }

    calcSpriteAngle(): number {
        if (this.facingDir === "front") {
            return 90
        }
        if (this.facingDir === "back") {
            return 270
        }
        if (this.facingDir === "left") {
            return 181
        }
        return 0
    }

    doMovement(entity: Entity): void {
        const { movement } = entity
        if (!movement) {
            return
        }

        if (this.spriteAngle !== movement.angle) {
            const facingDir = this.calcDir(movement.angle)
            if (facingDir !== this.facingDir) {
                const parts = this.action.split("-")
                const updated = `${parts[0]}-${facingDir}` as any
                this.activateAnimation(updated)
            }
            this.facingDir = facingDir
        }
        this.spriteAngle = movement.angle

        const { x, y } = entity.location

        if (x !== this.lastX || y !== this.lastY) {
            this.lastX = x
            this.lastY = y

            if (this.idleTimeout !== undefined) {
                clearTimeout(this.idleTimeout)
                this.idleTimeout = undefined
            }
            if (!isAttackAnimation(this.action)) {
                this.activateAnimation(`walk-${this.facingDir}` as AnimationDictionaryType)
            }
        } else {
            if (this.idleTimeout === undefined) {
                this.idleTimeout = setTimeout(() => {
                    if (isAttackAnimation(this.action)) {
                        return
                    }
                    this.activateAnimation(`idle-${this.facingDir}` as AnimationDictionaryType)
                }, 50)
            }
        }
    }

    activateAnimation = (updatedAction: AnimationDictionaryType, force?: boolean) => {
        if (!this.spritesheetResource) {
            return
        }

        if (updatedAction === this.action && !force) {
            // noop
            return
        }

        const source = this.animations[`${this.action}`]

        const toUpdate = this.animations[`${updatedAction}`]
        if (!toUpdate) {
            return
        }

        if (source) {
            // stop and hide the current animation
            source.visible = false
            source.stop()
        }

        // start and show the next animation
        this.action = updatedAction
        toUpdate.visible = true
        toUpdate.gotoAndPlay(0)
    }

    doAttack(entity: Entity): void {
        if (isAttackAnimation(this.action)) {
            // noop - already attacking
            return
        }

        if (this.disallowMoveAttackAnimation && isWalkAnimation(this.action)) {
            return
        }
        this.prevAction = this.action
        this.activateAnimation(`${this.attackAction}-${this.facingDir}` as AnimationDictionaryType)
    }

    fragment = (entity: Entity) => {
        let actionToUse = this.action
        if (!this.renderMeta[this.action]) {
            actionToUse = `idle-${this.facingDir}` as AnimationDictionaryType
        }
        if (!this.renderMeta[actionToUse]) {
            return
        }

        const { scale } = this.renderMeta[actionToUse]
        const surface = new Graphics()
        surface.x = this.parent.x
        surface.y = this.parent.y
        surface.scale.set(scale)
        surface.rotation = this.container.rotation
        this.parent.parent.addChild(surface)

        const sprite: AnimatedSprite = this.animations[`${actionToUse}`]
        if (!sprite) {
            return
        }
        const f: Texture = sprite.textures[sprite.currentFrame] as any

        const { x: origX, y: origY, width, height } = f._frame

        surface.x -= width / 2
        surface.y -= height / 2

        const divisor = height < 50 || width < 50 ? 3 : randomIntBetween(10, 20)

        let awaitedCount = 0
        const cleaner = () => {
            if (awaitedCount < 1) {
                surface.parent.removeChild(surface)
            }
        }

        for (let y = 0; y < Math.floor(height / divisor); y++) {
            for (let x = 0; x < Math.floor(width / divisor); x++) {
                const fragX = origX + x * divisor
                const fragY = origY + y * divisor
                const fragWidth = divisor
                const fragHeight = divisor

                const frag = new Texture(
                    f.baseTexture,
                    new Rectangle(fragX, fragY, fragWidth, fragHeight),
                    undefined,
                    undefined,
                    f.rotate,
                    f.defaultAnchor,
                )

                const fragSprite = new Sprite(frag)
                fragSprite.x = x * divisor
                fragSprite.y = y * divisor
                // fragSprite.scale.set(1.5)

                surface.addChild(fragSprite)

                const angle = degrees_to_radians(randomIntBetween(0, 360))

                const speed2: Coordinate = {
                    x: Math.cos(angle),
                    y: Math.sin(angle),
                }

                let rotation = 0
                let startTs = Date.now()
                let changedColor1
                let changedColor2

                awaitedCount++

                const interval = setInterval(() => {
                    fragSprite.x += speed2.x * 10
                    fragSprite.y += speed2.y * 10
                    fragSprite.alpha -= 0.1

                    rotation += 1
                    if (rotation > 360) {
                        rotation = 0
                    }

                    fragSprite.angle = rotation

                    if (fragSprite.alpha < 0.7 && !changedColor1) {
                        fragSprite.tint = 0xffc0c0
                        changedColor1 = true
                    }

                    if (fragSprite.alpha < 0.5 && !changedColor2) {
                        fragSprite.tint = 0xff0000
                        changedColor2 = true
                    }

                    if (Date.now() - startTs > 500) {
                        fragSprite.parent.removeChild(fragSprite)
                        clearInterval(interval)
                        awaitedCount--
                        cleaner()
                    }
                }, 50)
            }
        }
    }
}
