import { InteractionType, InteractionTypes } from "game-common/mechanics/entity_mechanics"
import { Graphics } from "pixi.js"
import { EntityInteractionCallback } from "../../../../client_game_logic"
import { BaseRenderable } from "./base_renderable"
import { BasicSprite } from "./basic_sprite"

class InteractionWidget extends Graphics {
    interaction: InteractionType
    constructor(interaction: InteractionType) {
        super()
        this.interaction = interaction
    }
}

export class Interactions {
    interactionWidgets: InteractionWidget[] = []
    interactions: InteractionType[] = []
    interactionWidgetsContainer: Graphics
    interactionCallback: EntityInteractionCallback

    posY: number

    constructor(x: number, y: number, parentArea: BaseRenderable, interactionCallback: EntityInteractionCallback) {
        this.interactionWidgetsContainer = new Graphics()
        this.posY = y
        this.interactionWidgetsContainer.x = x
        this.interactionWidgetsContainer.y = y
        this.interactionWidgetsContainer.visible = false
        this.interactionCallback = interactionCallback

        InteractionTypes.map((interactionType, i) => {
            let iconName = ""
            switch (interactionType) {
                case "chat": {
                    iconName = "action-chat.png"
                    break
                }
                case "take": {
                    iconName = "action-take.png"
                    break
                }
                case "burn": {
                    iconName = "action-burn.png"
                    break
                }
                case "inventory": {
                    iconName = "inventory.png"
                    break
                }
                case "crown": {
                    iconName = "action-crown.png"
                    break
                }
            }
            const widget = new InteractionWidget(interactionType)

            widget.beginFill(0x000000)
            widget.alpha = 1
            widget.drawCircle(0, 0, 10)
            widget.endFill()
            widget.visible = false
            widget.interactive = true
            widget.zIndex = 10000
            const handler = () => {
                // alert(interactionType)
                this.interactionCallback("_", interactionType)
                this.interactionWidgetsContainer.visible = false
            }
            widget.on("click", handler)
            widget.on("tap", handler)
            widget.on("mouseover", () => {
                parentArea.active = true
            })
            widget.on("mouseout", () => {
                parentArea.active = false
            })
            widget.addChild(
                new BasicSprite({
                    name: iconName,
                    scale: 0.5,
                    color: 0xffffff,
                }),
            )
            widget.cursor = "pointer"
            this.interactionWidgetsContainer.addChild(widget)
            this.interactionWidgets.push(widget)
        })
    }

    updateInteractions(interactions: InteractionType[]): void {
        let pos = -1
        this.interactionWidgets.forEach((widget, i) => {
            if (interactions.includes(widget.interaction)) {
                pos++
                widget.y = this.posY + 25 + 22 * pos
                widget.visible = true
            } else {
                widget.visible = false
            }
        })
    }
}
