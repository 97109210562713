import { ItemRecipe, resolveItemTypeToMeta } from "./item"

export const itemRecipes: ItemRecipe[] = [
  {
    itemTypeMeta: resolveItemTypeToMeta(`metal-bar-iron`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`iron`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`metal-bar-silver`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`silver`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`metal-bar-copper`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`copper`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`metal-bar-gold`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`gold`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`stick`),
    quantity: 5,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`wood`),
        quantity: 1,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`rope`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`straw`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`planks`),
    quantity: 2,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`wood`),
        quantity: 1,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`wall-wood`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`floor-wood`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`door-wood`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`chest-wood`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`roof-wood`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 4,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`campfire`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`wood`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: {...resolveItemTypeToMeta(`dagger`), color: `metal`},
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 5,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`shard-metal`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: {...resolveItemTypeToMeta(`dagger`), color: `brown`},
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 5,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`arrow`),
    quantity: 10,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 2,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`feather`),
        quantity: 3,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`shard-metal`),
        quantity: 2,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`axe`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`metal-bar-iron`),
        quantity: 3,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 2,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`spear`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`wood`),
        quantity: 10,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`shard-metal`),
        quantity: 3,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`longsword`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`metal-bar-iron`),
        quantity: 3,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 1,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`waraxe`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`metal-bar-iron`),
        quantity: 4,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`planks`),
        quantity: 2,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`bow`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 15,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`shard-metal`),
        quantity: 5,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`rope`),
        quantity: 2,
      },
    ],
  },
  {
    itemTypeMeta: resolveItemTypeToMeta(`snare-rabbit`),
    quantity: 1,
    ingredients: [
      {
        itemTypeMeta: resolveItemTypeToMeta(`stick`),
        quantity: 3,
      },
      {
        itemTypeMeta: resolveItemTypeToMeta(`rope`),
        quantity: 1,
      },
    ],
  },
]
