import { Graphics, Text, settings } from "pixi.js"
import { TextRenderer } from "../text/text_renderer"
import { Fader } from "../gui/fader"
import { DyanmicTextContainer } from "../gui/dynamic_text_container"
import { Callback } from "game-common/util"

export class ProjectLogo extends Graphics {
    logoText: string = "samsara"

    constructor() {
        super()
    }

    makeLetter = (letter: string, fade: boolean = true, color: number = 0xffffff): Graphics => {
        const graphics = new Graphics()
        const r = new TextRenderer().variant("GiganticFont").color(color).render(letter)

        if (fade) {
            r.alpha = 0.01

            new Fader(r as Graphics, {
                direction: "in",
                minAlpha: 1.0,
                fadeAlphaDecrement: 0.002,
            }).update()
        } else {
            r.alpha = 1.0
        }

        graphics.addChild(r)

        return graphics
    }

    calcWidth = () => 630

    update = (callback: Callback<void>) => {
        const megaContainer = new DyanmicTextContainer()

        const container = new DyanmicTextContainer()
        const container2 = new DyanmicTextContainer()

        megaContainer.append2(container, {
            exactLocation: {
                x: 0,
                y: 0,
            },
        })

        const letters = this.logoText.split("")
        const letterDelay = 150

        letters
            .map(letter => {
                return () => {
                    const letterRenderer = this.makeLetter(letter, true, 0x00ff00)
                    container.append2(letterRenderer, { sameLine: true })
                }
            })
            .forEach((f, i) => {
                setTimeout(f, i * letterDelay)
            })

        setTimeout(() => {
            new Fader(container).update()
        }, letterDelay * 1.6 + letters.length * (letterDelay * 1.6))

        const startFadeTs = letters.length * letterDelay

        setTimeout(() => {
            callback()
        }, startFadeTs + 100)

        setTimeout(() => {
            container2.x = 0
            container2.alpha = 0.01
            megaContainer.append2(container2, {
                exactLocation: {
                    x: 0,
                    y: 0,
                },
            })
            letters.forEach(letter => {
                const letterRenderer = this.makeLetter(letter, false, 0xffffff)
                container2.append2(letterRenderer, { sameLine: true })
            })

            new Fader(container2, {
                direction: "in",
                minAlpha: 1.0,
                fadeAlphaDecrement: 0.002,
            }).update()
        }, startFadeTs)

        return megaContainer
    }
}
