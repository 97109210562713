import { TileSize } from "game-common/models"
import { BitmapText, Graphics, Text } from "pixi.js"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { TextRenderer } from "../text/text_renderer"

interface ResourceMeta {
    icon: string
    quantity: number
}

export class ResourceBar extends Graphics {
    guiManager: GuiManager

    constructor(guiManager: GuiManager) {
        super()
        this.guiManager = guiManager
    }

    setup = (resourcesMeta: ResourceMeta[] = []) => {
        this.removeChildren()

        let prev: number
        resourcesMeta.forEach((meta: ResourceMeta, idx) => {
            const icon = new BasicSprite({
                name: meta.icon,
                scale: 1.0,
                color: 0xffffff,
            })
            icon.x = prev ? prev + 40 : 0
            icon.y = icon.height < TileSize / 2 ? icon.height + 5 : icon.height / 2 + 2
            this.addChild(icon)

            const quantityText = new TextRenderer().render() as Text
            quantityText.x = Math.max(TileSize / 2, icon.x + icon.width - 8)
            quantityText.y = 1
            quantityText.text = `${meta.quantity}`
            this.addChild(quantityText)

            prev = icon.x + quantityText.width
        })
    }
}
