import { Callback, Coordinate, DynamicTextType, TextDecoration } from "game-common/models"
import { Graphics } from "pixi.js"

import { HoverText } from "./dynamic_dialog"
import { DynamicText } from "./dynamic_text"
import { chunkSentence, randomId } from "game-common/util"

export interface DyanmicTextContainerProps {
    defaultTextFont?: string
    linkCallback?: (context: string) => void
    progressive?: boolean
    hoverText?: HoverText
    backdrop?: boolean
    margin?: number
}

interface AppendProps {
    sameLine?: boolean
    leftSpacer?: number
    leftMargin?: number
    topSpacer?: number
    topMargin?: number
    bottomMargin?: number
    pause?: number
    type?: DynamicTextType
    onClick?: Callback
    mouseControlCallback?: (on: boolean) => void
    spriteId?: string
    textDecorations?: TextDecoration[]
    preservePreviousHeight?: boolean
    ignorePrevious?: boolean
    fixedWidth?: number
    fixedHeight?: number
    context?: any
    color?: number
    exactLocation?: Coordinate
    sameX?: boolean
    hoverText?: string[]
    font?: string
    scale?: number
    // zIndex?: number
}

export class DyanmicTextContainer extends Graphics {
    previous: DynamicText | Graphics | null
    defaultTextFont: string
    linkCallback?: (context: string) => void
    progressive?: boolean
    progressivePlayList: (DynamicText | Graphics)[] = []
    currentlyPlayingLineCleaner?: Callback
    activePlaybackId: string
    lineHasButton: boolean
    hoverText?: HoverText
    backdrop?: Graphics
    margin?: number

    constructor(props?: DyanmicTextContainerProps) {
        super()
        this.defaultTextFont = props?.defaultTextFont
        this.linkCallback = props?.linkCallback
        this.progressive = props?.progressive
        this.activePlaybackId = undefined
        this.lineHasButton = false
        this.hoverText = props?.hoverText

        if (props?.backdrop) {
            this.backdrop = new Graphics()
            this.addChild(this.backdrop)
        }
        this.margin = props?.margin || 0
    }

    wipe = () => {
        // this.cleanup()
        this.removeChildren()
        this.progressivePlayList = []
        this.previous = null
        this.lineHasButton = false
    }

    append2 = (item: Graphics | string, props: AppendProps = { sameLine: false }): DyanmicTextContainer => {
        const toAdd =
            typeof item === "string"
                ? new DynamicText(item, {
                      font: props.font || this.defaultTextFont,
                      extraOnClickListener: this.linkCallback,
                      pause: props.pause,
                      type: props.type,
                      onClick: props.onClick,
                      mouseControlCallback: props.mouseControlCallback,
                      spriteId: props.spriteId,
                      scale: props.scale,
                      textDecorations: props.textDecorations,
                      context: props.context,
                      color: props.color,
                  })
                : item

        this.progressivePlayList.push(toAdd)

        const typeToUse = props.type || (item as any).type

        if (typeToUse === "horizontalLine") {
            toAdd.beginFill(0xffffff, 0.8)
            toAdd.drawRect(0, 0, this.width, 1)
            toAdd.endFill()
        }

        if (!this.progressive) {
            this.addChild(toAdd)
        }

        if (this.previous && !props?.ignorePrevious) {
            if (props.sameLine) {
                toAdd.y = this.previous.y
            } else {
                toAdd.y =
                    this.previous.y +
                    (props.preservePreviousHeight === undefined || props.preservePreviousHeight
                        ? this.previous.height
                        : 0)
                // + toAdd.height
                this.lineHasButton = false
            }
        }

        if (typeToUse === "button" || typeToUse === "button|dynamic") {
            this.lineHasButton = true
        }

        if (props.leftSpacer) {
            toAdd.x = props.leftSpacer
        } else if (this.previous && !props?.ignorePrevious) {
            if (props.sameLine) {
                toAdd.x = this.previous.x + (props.fixedWidth !== undefined ? props.fixedWidth : this.previous.width)
            } else if (props.sameX) {
                toAdd.x = this.previous.x
            }
        }

        if (props.leftMargin) {
            toAdd.x += props.leftMargin
        }

        if (props.topSpacer) {
            toAdd.y = props.topSpacer
        }

        if (props.topMargin) {
            toAdd.y += props.topMargin
        }

        if (props.bottomMargin) {
            const spacer = new Graphics()
            spacer.drawRect(0, 0, this.width, props.bottomMargin)
            toAdd.addChild(spacer)
        }

        if (props.exactLocation) {
            toAdd.x = props.exactLocation.x
            toAdd.y = props.exactLocation.y
        }

        if (props.hoverText && this.hoverText) {
            toAdd.interactive = true
            toAdd.on("mouseover", () => {
                this.hoverText.visible = true
                this.hoverText.x = toAdd.x + this.x
                this.hoverText.y = toAdd.y + this.y

                this.hoverText.update(props.hoverText)
            })
        }

        if (this.progressive) {
            const g = new Graphics()
            g.drawRect(0, 0, toAdd.height, 1)
            g.x = toAdd.x
            g.y = toAdd.y

            this.addChild(g)
        }

        this.previous = toAdd

        if (this.backdrop) {
            this.backdrop.clear()
            this.backdrop.beginFill(0x000000, 0.5)
            this.backdrop.drawRoundedRect(
                -5 - this.margin,
                -5 - this.margin,
                this.width + 10 + this.margin,
                this.height + 10 + this.margin,
                5,
            )
            this.backdrop.endFill()
        }

        return this
    }

    append = (
        item: Graphics | DynamicText | string,
        sameLine: boolean = false,
        leftSpacer: number = 0,
        leftMargin: number = 0,
        pause?: number,
        type?: DynamicTextType,
        onClick?: Callback,
        mouseControlCallback?: (on: boolean) => void,
    ): DyanmicTextContainer => {
        const toAdd =
            typeof item === "string"
                ? new DynamicText(item, {
                      font: this.defaultTextFont,
                      extraOnClickListener: this.linkCallback,
                      pause,
                      type,
                      onClick,
                      mouseControlCallback,
                  })
                : item

        this.progressivePlayList.push(toAdd)

        if (!this.progressive) {
            this.addChild(toAdd)
        }

        if (this.previous) {
            if (sameLine) {
                toAdd.y = this.previous.y
            } else {
                toAdd.y = this.previous.y + toAdd.height
                this.lineHasButton = false
            }
        }

        if (type === "button") {
            this.lineHasButton = true
        }

        if (leftSpacer) {
            toAdd.x = leftSpacer
        } else if (this.previous && sameLine) {
            toAdd.x = this.previous.x + this.previous.width
        }
        if (leftMargin) {
            toAdd.x += leftMargin
        }

        if (this.progressive) {
            const g = new Graphics()
            g.drawRect(0, 0, toAdd.height, 1)
            g.x = toAdd.x
            g.y = toAdd.y

            this.addChild(g)
        }

        this.previous = toAdd

        return this
    }

    appendLink = (
        item: string,
        target: string,
        sameLine: boolean = false,
        leftSpacer: number = 0,
    ): DyanmicTextContainer => {
        return this.append(
            new DynamicText(item, { color: 0x00ff00, link: target, extraOnClickListener: this.linkCallback }),
            sameLine,
            leftSpacer,
        )
    }

    cleanup = () => {
        this.activePlaybackId = undefined
        if (this.currentlyPlayingLineCleaner) {
            this.currentlyPlayingLineCleaner()
        }
    }

    playback = (callback?: Callback) => {
        const playlistLength = this.progressivePlayList.length
        let idx = 0
        const activePlaybackId = randomId()
        this.activePlaybackId = activePlaybackId

        const doNext = () => {
            const nextElement = this.progressivePlayList[idx]
            idx++

            const dynamicText = nextElement as DynamicText
            const cleanup = dynamicText?.playback(20, this, () => {
                if (idx < playlistLength && this.activePlaybackId === activePlaybackId) {
                    setTimeout(
                        () => {
                            if (this.activePlaybackId === activePlaybackId) {
                                doNext()
                            } else {
                                dynamicText.stop()
                                if (callback) {
                                    callback()
                                }
                            }
                        },
                        dynamicText.pause ? dynamicText.pause : 1,
                    )
                } else {
                    dynamicText.stop()

                    if (callback) {
                        callback()
                    }
                }
            })
            this.currentlyPlayingLineCleaner = () => {
                dynamicText.active = false
                if (cleanup) {
                    cleanup()
                }
            }
        }

        doNext()
    }

    appendChunkedText = (source: string) => {
        const texts = chunkSentence(source, 95)
        texts.forEach(text => {
            this.append2(text, {
                sameLine: false,
            })
        })
    }
}
