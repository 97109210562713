import { Container, ITextStyle, Text, TextStyleAlign } from "pixi.js"

export type TextSize = "normal" | "tiny" | "small" | "medium" | "large"

export interface TextProps {
    size?: TextSize
}

export type TextVariant = "LogoFont" | "BannerFont"

export class TextRenderer {
    _color: number
    _namedSize: TextSize
    _size: number
    _align: TextStyleAlign
    _fontFamily: string
    _bold: boolean

    private namedSizeToNumberSize = (size?: TextSize): number => {
        if (size === "tiny") {
            return 8
        }
        if (size === "small") {
            return 12
        }
        if (size === "medium") {
            return 14
        }
        if (size === "normal") {
            return 16
        }

        return this.namedSizeToNumberSize("medium")
    }

    size = (size: TextSize): TextRenderer => {
        this._namedSize = size
        return this
    }

    color = (color: number): TextRenderer => {
        if (color !== undefined) {
            this._color = color
        }
        return this
    }

    bold = (): TextRenderer => {
        this._bold = true
        return this
    }

    align = (align: TextStyleAlign): TextRenderer => {
        this._align = align
        return this
    }

    fontFamily = (fontFamily: string): TextRenderer => {
        this._fontFamily = fontFamily
        return this
    }

    variant = (variant: TextVariant | string): TextRenderer => {
        if (variant) {
            if (variant === "BannerFont") {
                this._color = 0xffffff
                this._size = 20
            }
            if (variant === "LogoFont") {
                this._color = 0xffffff
                this._size = 40
            }
            if (variant === "RedChatFont") {
                this._color = 0xffa500
                this._size = 10
            }
            if (variant === "SmallFont") {
                this._size = 10
            }
            if (variant === "AlertFont") {
                this._color = 0xff0000
                this._size = 30
            }
            if (variant === "DamageFont") {
                this._color = 0xff0000
                this._size = 15
                this._bold = true
            }
            if (variant === "EntityImportantFont") {
                this._size = 15
                this._bold = true
            }
            if (variant === "ChatFont") {
                this._color = 0x00ee00
                this._size = 10
            }
            if (variant === "BubbleChatFont") {
                this._color = 0xffffff
                this._size = this.namedSizeToNumberSize("small")
            }
            if (variant === "Normal-Green-Bold") {
                this._color = 0x00ff00
                this._size = 15
            }
            if (variant === "ImportantFont") {
                this._color = 0xffffff
                this._size = 30
            }
            if (variant === "GiganticFont") {
                this._color = 0xffffff
                this._size = 100
            }
        }
        return this
    }

    render = (text?: string): Container => {
        const style: Partial<ITextStyle> = {
            fontFamily: this._fontFamily || "Verdana, Geneva, sans-serif",
            // font: 'bold italic Sans-Serif',
            fill: "#ffffff",
            // stroke: '#000000',
            fontWeight: this._bold ? "bolder" : "normal",
            align: this._align || "left",
            strokeThickness: 1,
            fontSize: this._size === undefined ? this.namedSizeToNumberSize(this._namedSize) : this._size,
        }

        var someText = new Text(text || "", style)
        someText.roundPixels = true
        someText.tint = this._color !== undefined ? this._color : 0xffffff
        someText.scale.set(1.0)

        return someText
    }
}
