import { Graphics } from "pixi.js"
import { BOTTOM, GuiManager } from "../gui_manager"
import { TextRenderer } from "../text/text_renderer"
import { DyanmicTextContainer } from "./dynamic_text_container"
import { FullScreenModal } from "./fullscreen_modal"
import { text } from "stream/consumers"

export class Help extends FullScreenModal {
    constructor(guiManager: GuiManager) {
        super(guiManager)
    }

    static attachHelp = (textBlock: DyanmicTextContainer) => {
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Movement") as Graphics)
                .append2(new TextRenderer().render("WASD, or Arrow Keys. Press SHIFT to run.") as Graphics)

            textBlock.append2(block)
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Interactions") as Graphics)
                .append2(new TextRenderer().render("Mouse over an object to reveal its interaction icons.") as Graphics)

            textBlock.append2(block, { topMargin: 8 })
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Combat & Mining") as Graphics)
                .append2(
                    new TextRenderer().render("Left-click or press space to attack or mine an object.") as Graphics,
                )

            textBlock.append2(block, { topMargin: 8 })
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Inventory") as Graphics)
                .append2(
                    new TextRenderer().render(
                        "Press 'i' or click on the inventory icon to access your inventory.",
                    ) as Graphics,
                )

            textBlock.append2(block, { topMargin: 8 })
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Chat") as Graphics)
                .append2(new TextRenderer().render("Press enter to send a world-wide message.") as Graphics)

            textBlock.append2(block, { topMargin: 8 })
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Help") as Graphics)
                .append2(new TextRenderer().render("Type '/help' to get some help.") as Graphics)

            textBlock.append2(block, { topMargin: 8 })
        }
        {
            const block = new DyanmicTextContainer()
            block
                .append2(new TextRenderer().color(0xffa500).bold().render("Register") as Graphics)
                .append2(
                    new TextRenderer().render(
                        "Type '/register' to create an account and save your progress.",
                    ) as Graphics,
                )

            textBlock.append2(block, { topMargin: 8 })
        }
    }

    createContent = () => {
        const textBlock = new DyanmicTextContainer()

        textBlock.append2("").append2("")

        Help.attachHelp(textBlock)

        const media = this.guiManager.socialMedia()
        textBlock.append2(media, {
            exactLocation: {
                x: 0,
                y: BOTTOM - media.height - 10,
            },
        })

        return textBlock
    }
}
