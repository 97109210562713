import { Callback } from "game-common/util"
import { Container, Graphics } from "pixi.js"

import { ClientGameLogic } from "../../../../client_game_logic"
import { Dialog, DialogOptions } from "./dialog"

export interface ModalProps extends DialogOptions {
    content: Graphics
    closeCallback?: Callback<boolean>
    fullScreen?: boolean
}
export class Modal {
    logic: ClientGameLogic
    parent: Container
    content: Graphics
    private props: ModalProps
    private dialog: Dialog
    width: number
    height: number
    suppressOnCloseCallback?: boolean
    closedClicked: boolean

    constructor(logic: ClientGameLogic, parent: Container, props: ModalProps) {
        this.logic = logic
        this.parent = parent
        this.width = props.fullScreen ? 800 - (props.padding || 0) * 2 : props.width
        this.height = props.fullScreen ? 442 : props.width
        if (props.fullScreen) {
            props = {
                ...props,
                x: 0,
                y: 0,
                width: this.width,
                height: this.height,
            }
        } else if (props.center) {
            props = {
                ...props,
                x: (799 - props.width) / 2,
                y: (441 - props.height) / 2,
            }
        }
        this.props = props
    }

    private closeListener = (key, down) => {
        if (key === "Escape") {
            this.closedClicked = true
            this.dialog.close()
        }
    }

    private release = () => {
        // this.logic.playerController.enabledKeyboardAction(true)
        this.logic.playerController.enabledMouseAction(true)
        this.logic.clientRenderer.keyboard().removeListener(this.closeListener)
        if (this.props.closeCallback && !this.suppressOnCloseCallback) {
            this.props.closeCallback(this.closedClicked)
        }
    }

    update = () => {
        const createDialog = () => {
            const mouseOver = () => {
                if (this.props.onMouseOver) {
                    this.props.onMouseOver()
                }
                this.logic.enablePlayerMouseAction(false)
            }
            const mouseOut = () => {
                if (this.props.onMouseOut) {
                    this.props.onMouseOut()
                }
                this.logic.enablePlayerMouseAction(true)
            }
            const dialog = new Dialog(this.parent, {
                ...this.props,
                onMouseOver: mouseOver,
                onMouseOut: mouseOut,
                closeCallback: this.release,
                onCloseClick: () => {
                    this.closedClicked = true
                    dialog.close()
                },
            })

            dialog.update()

            return dialog
        }

        const dialog = createDialog()
        this.dialog = dialog
        // setup
        // this.logic.playerController.enabledKeyboardAction(false)
        this.logic.playerController.enabledMouseAction(false)
        if (!this.props.unescapeable) {
            this.logic.clientRenderer.keyboard().addListener(this.closeListener)
        }
    }

    close = () => {
        this.dialog.close()
    }
}
