import { EntityId, EntityType, IdAware, IndexedMap, Predicate, TypeAware, UpdateTsAware, Visitor } from "./models"

export type EventListenerContext = any

export class EntityManager<T extends IdAware & TypeAware & UpdateTsAware> {
    private entities: IndexedMap<T> = new IndexedMap<T>()
    private entitiesByType: Record<EntityType, IndexedMap<T>>
    private entityTypes: EntityType[] = []
    private setEventListeners: Set<(entity: T, context?: EventListenerContext, existed?: boolean) => void> = new Set<
        (entity: T, context?: EventListenerContext) => void
    >()
    private removeEventListeners: Set<(entity: T, context?: EventListenerContext) => void> = new Set<
        (entity: T, context?: EventListenerContext) => void
    >()

    constructor(entityTypes: EntityType[]) {
        this.entityTypes = entityTypes
        this.entitiesByType = this.initEntitiesByType()
    }

    clear = () => {
        this.entities.clear()
        this.entitiesByType = this.initEntitiesByType()
    }

    private initEntitiesByType = () => {
        return this.entityTypes.reduce((acc, next) => {
            const players = new IndexedMap<T>()
            return {
                ...acc,
                [next]: players,
            }
        }, {}) as Record<EntityType, IndexedMap<T>>
    }

    size = (entityType?: EntityType, predicate?: Predicate<T>) => {
        if (entityType) {
            const set: IndexedMap<T> = this.entitiesByType[entityType]
            if (!predicate) {
                return set.size()
            }
            return set.filter(predicate).length
        }
        if (!predicate) {
            return this.entities.size()
        } else {
            return this.entities.filter(predicate).length
        }
    }

    get = (entityId: EntityId): T => {
        if (!entityId) {
            return undefined
        }
        return this.entities.get(entityId)
    }

    contains = (entityId: EntityId, entityType?: EntityType) => {
        if (!entityType) {
            return !!this.get(entityId)
        }

        return !!this.entitiesByType[entityType]?.get(entityId)
    }

    getAtIdx = (idx: number, entityType?: EntityType): T | undefined => {
        if (entityType) {
            return this.entitiesByType[entityType].getAtIdx(idx)
        }
        return this.entities.getAtIdx(idx)
    }

    set = (entity: T, unshift?: boolean, context?: EventListenerContext) => {
        const existed = this.entities.has(entity.entityId)
        this.entities.set(entity.entityId, entity, unshift)
        this.entitiesByType[entity.entityType].set(entity.entityId, entity, unshift)
        this.setEventListeners.forEach(next => next(entity, context, existed))
        entity.lastUpdateTs = Date.now()
    }

    remove = (entityId: EntityId, context?: EventListenerContext) => {
        // remove it from all types
        this.entityTypes.forEach(next => this.entitiesByType[next].remove(entityId))
        const entity = this.entities.remove(entityId)
        if (entity) {
            this.removeEventListeners.forEach(next => next(entity, context))
        }
    }

    count = (entityType: EntityType) => this.entitiesByType?.[entityType]?.size() || 0

    iterate = (entityType: EntityType | EntityType[] | null, visitor: Visitor<T>, predicate?: Predicate<T>) => {
        if (entityType) {
            if (Array.isArray(entityType)) {
                entityType.forEach(nextType => this.entitiesByType[nextType]?.iterate(visitor, predicate))
            } else {
                this.entitiesByType[entityType]?.iterate(visitor, predicate)
            }
        } else {
            this.entities.iterate(visitor, predicate)
        }
    }

    collect = (predicate: Predicate<T>): T[] => this.entities.collect(predicate)

    find = (predicate: Predicate<T>, entityType?: EntityType): T | undefined => {
        if (entityType) {
            return this.entitiesByType[entityType]?.find(predicate)
        }
        return this.entities.find(predicate)
    }

    registerSetListener = (listener: (entity: T, context?: EventListenerContext, existed?: boolean) => void) => {
        this.setEventListeners.add(listener)
    }

    unregisterSetListener = (listener: (entity: T, context?: EventListenerContext) => void) => {
        this.setEventListeners.delete(listener)
    }

    registerRemoveListener = (listener: (entity: T, context?: EventListenerContext) => void) => {
        this.removeEventListeners.add(listener)
    }

    unregisterRemoveListener = (listener: (entity: T, context?: EventListenerContext) => void) => {
        this.removeEventListeners.delete(listener)
    }
}
