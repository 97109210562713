import { EntityTile, EntityTileMeta } from "../map/map"
import { resolveItemTypeToMeta } from "./item"

export const EntityTileTypes = [
    "chest-wood",
    "door-wood",
    "floor-wood",
    "ore-coal",
    "palmTree1",
    "palmTree2",
    "palmTree3",
    "palmTree4",
    "palmTree5",
    "palmTree6",
    "roof-wood",
    "sapling",
    "smallTree1",
    "table-mid-round",
    "tallGrass",
    "tallReeds",
    "testore",
    "tree1",
    "tree1Small",
    "tree2",
    "tree2Small",
    "tree3",
    "tree4",
    "tree5",
    "wall-wood",
    "wall2-wood"
] as const

export const EntityTileCharacteristics: Record<EntityTile, EntityTileMeta> = {
  "door-wood": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("door-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "wall-wood": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wall-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "wall2-wood": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wall2-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "floor-wood": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("floor-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "roof-wood": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("roof-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "chest-wood": {
    "hp": 3,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("chest-wood"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "tree1": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "sapling",
        "requiredTime": 10
      },
      {
        "entityTileId": "tree1Small",
        "requiredTime": 20
      }
    ]
  },
  "tree2": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "sapling",
        "requiredTime": 10
      },
      {
        "entityTileId": "tree2Small",
        "requiredTime": 20
      }
    ]
  },
  "tree3": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "sapling",
        "requiredTime": 10
      },
      {
        "entityTileId": "tree2Small",
        "requiredTime": 20
      }
    ]
  },
  "tree4": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "sapling",
        "requiredTime": 10
      },
      {
        "entityTileId": "tree2Small",
        "requiredTime": 20
      }
    ]
  },
  "tree5": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "sapling",
        "requiredTime": 10
      },
      {
        "entityTileId": "tree2Small",
        "requiredTime": 20
      }
    ]
  },
  "smallTree1": {
    "hp": 15,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": [
      {
        "entityTileId": "tree2Small",
        "requiredTime": 30
      }
    ]
  },
  "sapling": {
    "hp": 1,
    "onDestructionCreateItems": [],
    "growthSequence": []
  },
  "tallReeds": {
    "hp": 3,
    "onDestructionCreateItems": [],
    "growthSequence": []
  },
  "tallGrass": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("straw"),
        "quantity": 3,
        "probability": 0.5,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "tree1Small": {
    "hp": 3,
    "onDestructionCreateItems": [],
    "growthSequence": []
  },
  "tree2Small": {
    "hp": 3,
    "onDestructionCreateItems": [],
    "growthSequence": []
  },
  "ore-coal": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("coal"),
        "quantity": 3,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "testore": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("coal"),
        "quantity": 3,
        "probability": 0.25,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("iron"),
        "quantity": 3,
        "probability": 0.15,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("copper"),
        "quantity": 3,
        "probability": 0.1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("silver"),
        "quantity": 3,
        "probability": 0.05,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("gold"),
        "quantity": 3,
        "probability": 0.025,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "table-mid-round": {
    "hp": 5,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("table-mid-round"),
        "quantity": 1,
        "probability": 1,
        "replicate": false
      }
    ],
    "growthSequence": []
  },
  "palmTree1": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  },
  "palmTree2": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  },
  "palmTree3": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  },
  "palmTree4": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  },
  "palmTree5": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  },
  "palmTree6": {
    "hp": 30,
    "onDestructionCreateItems": [
      {
        "itemType": resolveItemTypeToMeta("wood"),
        "quantity": 2,
        "probability": 1,
        "replicate": false
      },
      {
        "itemType": resolveItemTypeToMeta("seed"),
        "quantity": 2,
        "probability": 1,
        "replicate": true
      }
    ],
    "growthSequence": []
  }
}