import { ApplyInputResult } from "./models"
import { ObjectPool, ObjectPoolItem } from "./object_pool"
import { blankCoordinate, clearCoordinate } from "./util"

export interface ApplyInputResultPoolItem extends ObjectPoolItem<ApplyInputResult> {}
export class ApplyInputResultPool extends ObjectPool<ApplyInputResult> {
    static instance: ApplyInputResultPool = new ApplyInputResultPool()

    constructor(size: number = 1000) {
        super(size)
    }

    create(): ApplyInputResultPoolItem {
        return {
            free: true,
            data: {
                movedX: false,
                movedY: false,
                collision: blankCoordinate(),
                intersection: {
                    ...blankCoordinate(),
                    intersectionType: "",
                },
            },
        }
    }

    clear(item: ApplyInputResultPoolItem) {
        item.data.movedX = false
        item.data.movedY = false
        clearCoordinate(item.data.collision)
        clearCoordinate(item.data.intersection)
        item.data.intersection.intersectionType = ""
    }
}
