import { TutorialType } from "game-common/models"
import { Graphics } from "pixi.js"

import { GuiManager } from "../gui_manager"
import { DyanmicTextContainer } from "./dynamic_text_container"
import { Fader } from "./fader"
import { Throbber } from "./throbber"
import { TextRenderer } from "../text/text_renderer"
import { Callback } from "game-common/util"

export class Tutorial {
    gui: GuiManager
    surface: Graphics
    equippedWidget: Graphics
    controlBar: Graphics
    minimapSurface: Graphics
    activeTutorials: Set<TutorialType> = new Set()

    constructor(
        gui: GuiManager,
        surface: Graphics,
        equippedWidget: Graphics,
        controlBar: Graphics,
        minimapSurface: Graphics,
    ) {
        this.gui = gui
        this.surface = surface
        this.equippedWidget = equippedWidget
        this.controlBar = controlBar
        this.minimapSurface = minimapSurface
    }

    addDismiss = (container: DyanmicTextContainer, callback: Callback<void>) => {
        const dismissButton = new Graphics()
        dismissButton.interactive = true
        dismissButton.beginFill(0xffffff)
        dismissButton.drawCircle(0, 0, 8)
        dismissButton.endFill()
        dismissButton.on("click", callback)
        dismissButton.on("tap", callback)

        const text = new TextRenderer().color(0x000000).render("x")
        text.x = -4
        text.y = -11
        dismissButton.addChild(text)

        container.append2(dismissButton, {
            exactLocation: {
                x: -4,
                y: -7,
            },
        })
    }

    createStop = (lines: Graphics, container: DyanmicTextContainer, throbber: Throbber): Callback<void> => {
        const stop = () => {
            throbber.stop()
            this.activeTutorials.delete("controlbar")
            new Fader(container, {
                releaseCallback: () => {
                    container.parent.removeChild(container)
                },
            }).update()
            new Fader(lines, {
                releaseCallback: () => {
                    lines.parent.removeChild(lines)
                },
            }).update()
        }

        return stop
    }

    setupTutorial = (tutorialType: TutorialType, text: string[], attachToSurface: boolean = true) => {
        if (this.activeTutorials.has(tutorialType)) {
            return
        }
        this.activeTutorials.add(tutorialType)

        const container = new DyanmicTextContainer({
            backdrop: true,
            margin: 2,
        })
        const lines = new Graphics()
        if (attachToSurface) {
            this.surface.addChild(lines)
        }

        const throbber = new Throbber(lines, {
            blinkInterval: 250,
            minAlpha: 0.5,
        })

        container.interactive = true
        container.on("mouseover", () => {
            this.gui.mouseOverControl(true)
        })
        container.on("mouseout", () => {
            this.gui.mouseOverControl(false)
        })

        const stop = this.createStop(lines, container, throbber)

        text.forEach(l => container.append2(l))

        this.addDismiss(container, stop)

        throbber.update()

        if (attachToSurface) {
            this.surface.addChild(container)
        }

        setTimeout(stop, 7000)

        return { lines, container }
    }

    setupHotbarTutorial = () => {
        const { lines, container } = this.setupTutorial("hotbar", [
            "This is your hotbar. Activate readied equipment, supplies, and follower",
            "commands by pressing their associated numbers and letters (1, 2, J, K ...).",
        ])

        container.x = this.equippedWidget.x - 15
        container.y = this.equippedWidget.y - container.height - 45

        lines.x = container.x - 5
        lines.y = container.y + container.height + 10

        lines.lineStyle({
            width: 2,
            color: 0xff0000,
            alpha: 1.0,
        })
        lines.drawRect(250, -25, 0.5, 25)
        lines.drawRect(0, 0, 500, 55)
    }

    setupMapTutorial = () => {
        const { lines, container } = this.setupTutorial("map", [
            "This your map. Points of interest appear as icons, and new ones",
            "will pulse to get your attention!",
        ])

        container.x = this.minimapSurface.x - container.width
        container.y = this.minimapSurface.y - container.height - 55

        lines.x = container.x + container.width + 50
        lines.y = container.y + container.height / 2 - 25
        lines.lineStyle({
            width: 2,
            color: 0xff0000,
            alpha: 1.0,
        })
        lines.drawRect(-60, 0, 60, 0.5)
        lines.drawRect(0, 0, 0.5, 108)

        lines.drawRect(-60, 110, this.minimapSurface.width + 15, this.minimapSurface.height + 15)
    }

    setupControlbarTutorial = () => {
        const { lines, container } = this.setupTutorial("controlbar", [
            "Access your inventory and craft items using these controls.",
        ])

        container.x = this.controlBar.x - container.width + 10
        container.y = this.controlBar.y + 30

        lines.x = this.controlBar.x + 15
        lines.y = 0

        lines.lineStyle({
            width: 2,
            color: 0xff0000,
            alpha: 1.0,
        })
        lines.drawRect(0, 0, 32, 30)
        lines.drawRect(31, 30, 1, 40)
        lines.drawRect(31, 70, -50, 1)
    }

    setupCompanionTutorial = () => {
        const playerEntity = this.gui.logic.playerEntity
        if (!playerEntity) {
            this.activeTutorials.delete("companion")
            return
        }

        const companion = this.gui.logic.entityManager.find(e => e.leaderEntityId === playerEntity.entityId)
        if (!companion) {
            this.activeTutorials.delete("companion")
            return
        }

        const entityRenderable = this.gui.logic.clientRenderer.map().overlay().getEntityRenderable(companion.entityId)
        if (!entityRenderable) {
            this.activeTutorials.delete("companion")
            return
        }

        const { lines, container } = this.setupTutorial(
            "companion",
            ["This is your companion and protected. Mouse over", "him, and click the icons that appear to interact."],
            false,
        )

        // container.x = this.minimapSurface.x - container.width
        container.x = -container.width
        container.y = -25

        entityRenderable.addChild(lines)
        entityRenderable.addChild(container)

        lines.x = container.x + container.width
        lines.y = container.y + container.height / 2

        lines.lineStyle({
            width: 2,
            color: 0xff0000,
            alpha: 1.0,
        })
        lines.drawRect(-10, 0, 30, 0.5)
        lines.drawRect(20, 0, 0.5, 25)
    }

    setup = (tutorialType: TutorialType) => {
        const all = false
        if (all || tutorialType === "controlbar") {
            this.setupControlbarTutorial()
        }
        if (all || tutorialType === "hotbar") {
            this.setupHotbarTutorial()
        }
        if (all || tutorialType === "map") {
            this.setupMapTutorial()
        }
        if (all || tutorialType === "companion") {
            setTimeout(() => {
                this.setupCompanionTutorial()
            }, 4000)
        }
    }
}
