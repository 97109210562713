import { Callback } from "game-common/models"
import { BitmapText, Graphics } from "pixi.js"
import { TextRenderer } from "../text/text_renderer"

interface ButtonProps {
    text: string
    font: string
    color: number
    textColor?: number
    buttonWidth?: number
    onClick?: Callback
    mouseControlCallback?: (on: boolean) => void
}
export class Button extends Graphics {
    text: string
    font: string
    color: number
    textColor: number
    buttonWidth: number
    onClick?: Callback
    mouseControlCallback?: (on: boolean) => void

    constructor({ text, font, color, textColor, buttonWidth, mouseControlCallback, onClick }: ButtonProps) {
        super()
        this.text = text
        this.font = font
        this.color = color
        this.textColor = textColor || 0x000000
        this.buttonWidth = buttonWidth
        this.mouseControlCallback = mouseControlCallback
        this.onClick = onClick

        const button = new Graphics()
        const textObj = new TextRenderer().color(this.textColor).align("center").render(this.text)
        const padding = 3
        textObj.x = buttonWidth ? buttonWidth / 2 - textObj.width / 2 : padding
        textObj.y = padding

        button.lineStyle(2, color, 1.0)
        button.beginFill(color, 1.0)
        button.drawRoundedRect(0, 0, buttonWidth || textObj.width + padding * 3, textObj.height + padding * 2, 5)
        button.addChild(textObj)

        this.drawRect(0, 0, this.buttonWidth || 1, button.height + padding)

        this.addChild(button)

        this.interactive = true

        if (this.mouseControlCallback) {
            this.on("mouseover", () => {
                this.alpha = 0.7
                this.mouseControlCallback && this.mouseControlCallback(true)
            })
            this.on("mouseout", () => {
                this.alpha = 1.0
                this.mouseControlCallback && this.mouseControlCallback(false)
            })
            if (this.onClick) {
                this.on("click", this.onClick)
                this.on("tap", this.onClick)
            }
        }
        this.cursor = "pointer"
        this.y -= padding
    }
}
