
import { ItemCharacteristics } from "./item"
import { ItemTypes } from "./item_type"
export const ItemTypeCharacteristics: Partial<Record<ItemTypes, ItemCharacteristics>> = {
  "castlekey": {
    "stackable": true
  },
  "arrow": {
    "stackable": true,
    "isAmmo": true,
    "unsellable": true
  },
  "drumstick": {
    "useable": true,
    "useHasWorldEffect": true,
    "unbuyable": true
  },
  "feather": {
    "unsellable": true,
    "unbuyable": true
  },
  "flower-single-blue": {
    "unsellable": true,
    "unbuyable": true
  },
  "flower-single-purple": {
    "unsellable": true,
    "unbuyable": true
  },
  "flower-single-red": {
    "unsellable": true,
    "unbuyable": true
  },
  "flower-single-yellow": {
    "unsellable": true,
    "unbuyable": true
  },
  "hide": {
    "unbuyable": true
  },
  "mushroom-purple": {
    "useable": true,
    "useHasWorldEffect": true,
    "unbuyable": true
  },
  "mushroom-red": {
    "useable": true,
    "useHasWorldEffect": true,
    "unbuyable": true
  },
  "mushroom-yellow": {
    "useable": true,
    "useHasWorldEffect": true,
    "unbuyable": true
  },
  "rope": {
    "unbuyable": true
  },
  "seed": {
    "useable": true,
    "useHasWorldEffect": true,
    "unsellable": true,
    "unbuyable": true
  },
  "straw": {
    "unsellable": true,
    "unbuyable": true
  },
  "torch": {
    "useable": true
  },
  "wood": {
    "unbuyable": true
  },
  "campfire": {
    "useable": true,
    "useHasWorldEffect": true
  },
  "chest-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": false,
    "useHasWorldEffect": true,
    "buildType": "entity"
  },
  "door-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": false,
    "useHasWorldEffect": true,
    "buildType": "entity"
  },
  "floor-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": true,
    "useHasWorldEffect": true,
    "buildType": "floor"
  },
  "roof-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": true,
    "useHasWorldEffect": true,
    "buildType": "roof"
  },
  "snare-rabbit": {
    "useable": true,
    "useHasWorldEffect": true
  },
  "stool-small": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": false,
    "useHasWorldEffect": true
  },
  "table-large-rough": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": false,
    "useable": false,
    "useHasWorldEffect": true
  },
  "table-mid-round": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": false,
    "useable": false,
    "useHasWorldEffect": true
  },
  "wall-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": true,
    "useHasWorldEffect": true,
    "buildType": "wall"
  },
  "wall2-wood": {
    "buildable": true,
    "exclusiveReadiness": true,
    "stackable": true,
    "useHasWorldEffect": true,
    "buildType": "wall"
  },
  "barrel1": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true
  },
  "stackedBags": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true
  },
  "stool1": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true,
    "useable": false
  },
  "table-mid-rough": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true,
    "useable": false
  },
  "strawBed": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true,
    "useable": false
  },
  "strawBedSmall": {
    "unbuyable": true,
    "readyable": true,
    "buildable": true,
    "useable": false
  },
  "crossbow": {
    "unbuyable": true
  },
  "flail": {
    "unbuyable": true
  },
  "halberd": {
    "unbuyable": true
  },
  "longsword": {
    "unbuyable": true
  },
  "mace": {
    "unbuyable": true
  },
  "none": {
    "hasDurability": false
  },
  "rapier": {
    "unbuyable": true
  },
  "saber": {
    "unbuyable": true
  },
  "scythe": {
    "unbuyable": true
  },
  "staff": {
    "unbuyable": true
  },
  "waraxe": {
    "unbuyable": true
  },
  "warhammer": {
    "unbuyable": true
  }
}
