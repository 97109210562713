import { v4 as uuidv4 } from "uuid"
import {
    Bounds,
    Coordinate,
    Dimensions,
    Direction,
    Directions,
    EntityId,
    Latch,
    Location,
    Orientation,
    randomBool,
    randomIntBetween,
    Region,
    TileMapBase,
    TileSize,
    WorldMetaDimensions,
} from "./models"
import { TileMapMetaProvider } from "./tile_map_meta_provider"
import { generateName } from "./name/nameGenerator"
const seedrandom = require("seedrandom")

let RANDOM_NUMBER_GENERATOR = undefined

export const seedRng = (seed: string) => {
    RANDOM_NUMBER_GENERATOR = seedrandom(seed)
}

export const rngRandom = (seed: string) => seedrandom(seed)

export const randomIntBetweenRng = (min: number, max: number, seed?: string) => {
    if (seed) {
        const randomFloat = rngRandom(seed)()
        return Math.floor(randomFloat * (max - min + 1) + min)
    }

    if (RANDOM_NUMBER_GENERATOR === undefined) {
        return randomIntBetween(min, max)
    }
    const randomFloat = RANDOM_NUMBER_GENERATOR()
    // min and max included
    return Math.floor(randomFloat * (max - min + 1) + min)
}

export const randomRng = () => {
    if (RANDOM_NUMBER_GENERATOR === undefined) {
        return Math.random()
    }
    const randomFloat = RANDOM_NUMBER_GENERATOR()
    // min and max included
    return randomFloat
}

export const getAngle = (p1: Coordinate, p2: Coordinate) => Math.atan2(p2.y - p1.y, p2.x - p1.x) + Math.PI

export const degrees_to_radians = (degrees: number) => {
    const pi = Math.PI
    return degrees * (pi / 180)
}

export const radians_to_degrees = (radians: number) => {
    const pi = Math.PI
    return radians * (180 / pi)
}

export const radians_to_cardinal_dir = (radians: number): string => {
    function round5(x) {
        return Math.ceil(x / 25) * 25
    }
    const degrees = round5(radians_to_degrees(radians))
    let result = "right"

    if (between(degrees, 45, 135)) {
        result = "front"
    }
    if (between(degrees, 225, 315)) {
        result = "back"
    }
    if (between(degrees, 136, 224)) {
        result = "left"
    }

    return result
}

export const radians_to_orientation = (radians: number): Orientation => {
    const dir = radians_to_cardinal_dir(radians)
    switch (dir) {
        case "front":
            return "south"
        case "back":
            return "north"
        case "right":
            return "east"
        case "left":
            return "west"
    }
    return "north"
}

export const radiansToCardinalDirection = (radians: number): Direction => {
    const orientation = radians_to_cardinal_dir(radians)
    switch (orientation) {
        case "front":
            return "Down"
        case "back":
            return "Up"
        case "right":
            return "Right"
        case "left":
            return "Left"
    }
    return "Up"
}

export const isDiagonal = (radians: number): boolean => {
    const degrees = radians_to_degrees(radians)
    return ![90, 180, 270, 360].includes(degrees)
}

export const oppositeOfDirection = (direction: Direction): Direction => {
    switch (direction) {
        case "Down":
            return "Up"
        case "Up":
            return "Down"
        case "Right":
            return "Left"
        case "Left":
            return "Right"
    }
    return "Up"
}

export const between = (x: number, a: number, b: number) => x >= a && (b === undefined || x <= b)

export const clamp = (num: number, min: number, max: number) => Math.max(min, Math.min(max, num))

export const fineToRoughCoordinates = (fineCoordinate: Coordinate) => {
    return !fineCoordinate ? undefined : { x: fineToRough(fineCoordinate.x), y: fineToRough(fineCoordinate.y) }
}

export const fineToRough = (x: number) => Math.floor(x / TileSize)

export const locationToGlobalCoordinates = (
    roomWidth: number,
    roomHeight: number,
    roomId: EntityId,
    fineCoordinate: Coordinate,
): Coordinate => {
    const globalCoordinate = blankCoordinate()
    setLocationToGlobalCoordinates(roomWidth, roomHeight, roomId, fineCoordinate, globalCoordinate)
    return globalCoordinate
}

export const resolveGlobalToRoomCoordinates = (
    x: number,
    y: number,
    roomWidth: number,
    roomHeight: number,
): Location => {
    const roomX = Math.floor(x / roomWidth)
    const roomY = Math.floor(y / roomHeight)
    const roomId = `room-${roomX}-${roomY}`

    const localX = x % roomWidth
    const localY = y % roomHeight

    return {
        roomId,
        x: localX,
        y: localY,
    }
}

export const resolveLocalToGlobalCoordinates = (
    roomWidth: number,
    roomHeight: number,
    roomX: number,
    roomY: number,
    fineCoordinate: Coordinate,
): Coordinate => {
    const globalCoordinate = blankCoordinate()

    const roomWidthFine = roomWidth * TileSize
    const roomHeightFine = roomHeight * TileSize

    const x = roomWidthFine * roomX + fineCoordinate.x
    const y = roomHeightFine * roomY + fineCoordinate.y

    globalCoordinate.x = x
    globalCoordinate.y = y

    return globalCoordinate
}

export const roomIdToRoomCoordinates = (roomId: string): Coordinate => {
    const [_, _roomX, _roomY] = roomId.split("-")
    return {
        x: Number(_roomX),
        y: Number(_roomY),
    }
}

export const setLocationToGlobalCoordinates = (
    roomWidth: number,
    roomHeight: number,
    roomId: EntityId,
    fineCoordinate: Coordinate,
    globalCoordinate: Coordinate,
): void => {
    const [_, _roomX, _roomY] = roomId.split("-")
    const roomX = Number(_roomX)
    const roomY = Number(_roomY)

    const roomWidthFine = roomWidth * TileSize
    const roomHeightFine = roomHeight * TileSize

    const x = roomWidthFine * roomX + fineCoordinate.x
    const y = roomHeightFine * roomY + fineCoordinate.y

    globalCoordinate.x = x
    globalCoordinate.y = y
}

export const emptyCoordinate = (coordinate: Coordinate) => {
    return !coordinate || (coordinate.x < 0 && coordinate.y < 0)
}

export const blankCoordinate = (): Coordinate => {
    return {
        x: -1,
        y: -1,
    }
}

export const clearCoordinate = (coordinate: Coordinate) => {
    if (!coordinate) {
        return
    }
    coordinate.x = -1
    coordinate.y = -1
}

export const isDifferentCoordinate = (c1: Coordinate, c2: Coordinate) => c1.x !== c2.x || c1.y !== c2.y

export const setFineToRoughCoordinates = (fineCoordinate: Coordinate, roughCoordinate: Coordinate) => {
    if (!fineCoordinate || !roughCoordinate) {
        return
    }
    roughCoordinate.x = fineToRough(fineCoordinate.x)
    roughCoordinate.y = fineToRough(fineCoordinate.y)
}

export const fillCoordinate = (source: Coordinate, target: Coordinate) => {
    if (!source || !target) {
        return
    }
    target.x = source.x
    target.y = source.y
}

export const fineToRoughBounds = (meta: TileMapMetaProvider, fineBounds: Bounds, tileSize: number = TileSize) => {
    // console.trace()
    const f = (y: number) => {
        return Math.floor(y * tileSize) + tileSize / 2
    }

    const xStart = Math.floor(clamp(fineBounds.x1, 0, f(meta.height())) / tileSize)
    const yStart = Math.floor(clamp(fineBounds.y1, 0, f(meta.width())) / tileSize)

    const xEnd = Math.floor(clamp(fineBounds.x2, 0, f(meta.height())) / tileSize)
    const yEnd = Math.floor(clamp(fineBounds.y2, 0, f(meta.width())) / tileSize)

    return new Bounds(xStart, yStart, xEnd, yEnd)
}

export const fineToRoughBoundsViaDimensions = (
    dimensions: Dimensions,
    fineBounds: Bounds,
    tileSize: number = TileSize,
) => {
    // console.trace()
    const f = (y: number) => {
        return Math.floor(y * tileSize) + tileSize / 2
    }

    const xStart = Math.floor(clamp(fineBounds.x1, 0, f(dimensions.h)) / tileSize)
    const yStart = Math.floor(clamp(fineBounds.y1, 0, f(dimensions.w)) / tileSize)

    const xEnd = Math.floor(clamp(fineBounds.x2, 0, f(dimensions.h)) / tileSize)
    const yEnd = Math.floor(clamp(fineBounds.y2, 0, f(dimensions.w)) / tileSize)

    return new Bounds(xStart, yStart, xEnd, yEnd)
}

export const perturb = (coordinate: Coordinate, amount: number = 16) => {
    return {
        ...coordinate,
        x: coordinate.x + randomIntBetween(-amount, amount),
        y: coordinate.y + randomIntBetween(-amount, amount),
    }
}

export const perturbInPlace = (coordinate: Coordinate, amount: number = 16) => {
    coordinate.x = coordinate.x + randomIntBetween(-amount, amount)
    coordinate.y = coordinate.y + randomIntBetween(-amount, amount)
}

export const setPerturb = (coordinate: Coordinate, perturbed: Coordinate, amount: number = 16) => {
    perturbed.x = coordinate.x + randomIntBetween(-amount, amount)
    perturbed.y = coordinate.y + randomIntBetween(-amount, amount)
}

export const roundCoordinates = (coordinate: Coordinate) => {
    return {
        x: Math.round(coordinate.x),
        y: Math.round(coordinate.y),
    }
}

export const floorCoordinates = (coordinate: Coordinate) => {
    return {
        x: Math.floor(coordinate.x),
        y: Math.floor(coordinate.y),
    }
}

export const roughToFineCoordinatesOffset = (roughCoordinate: Coordinate) => {
    return {
        x: roughCoordinate.x * TileSize + TileSize * 0.5,
        y: roughCoordinate.y * TileSize + TileSize * 0.5,
    }
}

export const setRoughToFineCoordinatesOffset = (roughCoordinate: Coordinate, target: Coordinate) => {
    target.x = roughToFine(roughCoordinate.x) + TileSize * 0.5
    target.y = roughToFine(roughCoordinate.y) + TileSize * 0.5
}

export const roughToFineCoordinates = (roughCoordinate: Coordinate) => {
    const fine = blankCoordinate()
    setRoughToFineCoordinates(roughCoordinate, fine)
    return fine
}

export const roughToFine = (i: number) => i * TileSize

export const setRoughToFineCoordinates = (roughCoordinate: Coordinate, target: Coordinate) => {
    target.x = roughCoordinate.x * TileSize
    target.y = roughCoordinate.y * TileSize
}

export const randomCoordinateWithinRadius = (location: Coordinate, radius: number): Coordinate => {
    return {
        x: Math.floor(location.x + (randomBool() ? 1 : -1) * randomIntBetween(0, radius)),
        y: Math.floor(location.y + (randomBool() ? 1 : -1) * randomIntBetween(0, radius)),
    }
}

export const randomBoundsWithinBounds = (bounds: Bounds, dimension: Dimensions, budget: number, useRng?: boolean) => {
    const start = Date.now()
    while (Date.now() - start < budget) {
        const coordinate = randomCoordinateWithinBounds(bounds, useRng)
        const candidate = new Bounds(coordinate.x, coordinate.y, coordinate.x + dimension.w, coordinate.y + dimension.h)
        if (bounds.encloses(candidate)) {
            return candidate
        }
    }

    return null
}

export const roundUpToNearest100 = (num: number) => {
    return Math.ceil(num / 100) * 100
}

export const calcPolarity = (a: Coordinate, b: Coordinate) => {
    const x = a.x - b.x
    const y = a.y - b.y
    return `${x === 0 ? "0" : x < 0 ? "-" : "+"}|${y === 0 ? "0" : y < 0 ? "-" : "+"}`
}

export const randomCoordinateOnCircle = (location: Coordinate, radius: number): Coordinate => {
    const angle = Math.random() * Math.PI * 2
    const x = Math.floor(Math.cos(angle) * radius)
    const y = Math.floor(Math.sin(angle) * radius)
    return {
        x: x + location.x,
        y: y + location.y,
    }
}

export const evenCoordinatePointsOnCircle = (
    location: Coordinate,
    radius: number,
    numberOfPoints: number,
): Coordinate[] => {
    const centerX = location.x
    const centerY = location.y
    const theta = (2.0 * Math.PI) / numberOfPoints

    const points: Coordinate[] = []
    for (let i = 1; i <= numberOfPoints; i++) {
        const pointX = radius * Math.cos(theta * i) + centerX
        const pointY = radius * Math.sin(theta * i) + centerY
        points.push({
            x: pointX,
            y: pointY,
        })
    }
    return points
}

export const evenCoordinatePointsInsideRectangle = (
    location: Coordinate,
    dimensions: Dimensions,
    numberOfPoints: number,
): Coordinate[] => {
    const totalArea = dimensions.w * dimensions.h
    const pointArea = totalArea / numberOfPoints
    const length = Math.sqrt(pointArea)
    const points: Coordinate[] = []

    for (let i = length / 2; i < dimensions.w; i += length) {
        for (let j = length / 2; j < dimensions.h; j += length) {
            const x = location.x - dimensions.w / 2 + i
            const y = location.y - dimensions.h / 2 + j
            points.push({
                x,
                y,
            })
        }
    }

    return points
}

export const evenCoordinatePointsOnRectangle = (
    location: Coordinate,
    dimensions: Dimensions,
    numberOfPoints: number,
): Coordinate[] => {
    const centerX = location.x
    const centerY = location.y
    const points: Coordinate[] = []

    const perSide = Math.ceil(numberOfPoints / 4)

    // top / bottom
    for (let x = centerX - dimensions.w / 2; x < centerX + dimensions.w / 2; x += dimensions.w / perSide) {
        points.push({
            x,
            y: centerY - dimensions.h / 2,
        })
        points.push({
            x,
            y: centerY + dimensions.h / 2,
        })
    }

    // left / right
    for (let y = centerY - dimensions.h / 2; y < centerY + dimensions.h / 2; y += dimensions.h / perSide) {
        points.push({
            x: centerX - dimensions.w / 2,
            y,
        })
        points.push({
            x: centerX + dimensions.w / 2,
            y,
        })
    }

    return points
}

export const resolveQuadrantCoordinateAt = (roomId: EntityId, worldMetaDimensions: WorldMetaDimensions): Coordinate => {
    const [_, roomX, roomY] = roomId.split("-")
    const x = Number(roomX)
    const y = Number(roomY)
    return {
        x: Math.floor(x / worldMetaDimensions.quadrantWidth),
        y: Math.floor(y / worldMetaDimensions.quadrantHeight),
    }
}

export const stringifySimple = (coordinate: Coordinate) => `${coordinate.x}-${coordinate.y}`

export const resolveQuadrantIdFrom = (roomId: EntityId, worldDimensions: WorldMetaDimensions): string => {
    if (!roomId.startsWith("room")) {
        return roomId
    }

    return stringifySimple(resolveQuadrantCoordinateAt(roomId, worldDimensions))
}

export const toCoordinateFromSimple = (str: string): Coordinate => {
    if (!str || !str.includes("-")) {
        return {
            x: 0,
            y: 0,
        }
    }

    const parts = str.split("-").map(n => Number(n))
    return {
        x: parts[0],
        y: parts[1],
    }
}

export const randomCoordinateOnSquare = (location: Coordinate, d: Dimensions): Coordinate => {
    if (randomBool()) {
        return {
            x: location.x + randomIntBetween(-d.w / 2, d.w / 2),
            y: location.y + (randomBool() ? d.h / 2 : -d.h / 2),
        }
    } else {
        return {
            x: location.x + (randomBool() ? d.w / 2 : -d.w / 2),
            y: location.y + randomIntBetween(-d.h / 2, d.h / 2),
        }
    }
}

export const randomCoordinateWithinRegion = (region: Region): Coordinate => {
    const x = randomIntBetween(0, region.bounds.width)
    const y = randomIntBetween(0, region.bounds.height)
    return {
        x: x + region.x,
        y: y + region.y,
    }
}

export const randomCoordinateWithinBounds = (bounds: Bounds, useRng?: boolean): Coordinate => {
    const fnToUs = useRng ? randomIntBetweenRng : randomIntBetween
    const x = fnToUs(0, bounds.width)
    const y = fnToUs(0, bounds.height)
    return {
        x: x + bounds.x1,
        y: y + bounds.y1,
    }
}

export const circlePoints = (radius: number, callback: (x: number, y: number) => void) => {
    for (let i = 1; i <= radius; i++) {
        const r2 = i * i
        for (let dx = -radius; dx <= radius; dx++) {
            const h = Math.sqrt(r2 - dx * dx) | 0
            for (let dy = -h; dy <= h; dy++) {
                callback(dx, dy)
            }
        }
    }
}

export const squarePoints = (width: number, height: number, callback: (x: number, y: number) => void) => {
    for (let y = 0; y < height; y++) {
        for (let x = 0; x < width; x++) {
            callback(x, y)
        }
    }
}

export const zeroOnNaN = (n: number) => {
    return isNaN(n) ? 0 : n
}

export const isEqual = (p1: Coordinate, p2: Coordinate, rounded: boolean = false) => {
    if (p1 && !p2) {
        return false
    }
    if (!p1 && p2) {
        return false
    }
    if (!p1 && !p2) {
        return true
    }

    if (!rounded) {
        return p1.x === p2.x && p1.y === p2.y
    }

    return Math.floor(p1.x) === Math.floor(p2.x) && Math.floor(p1.y) === Math.floor(p2.y)
}

export const coordinatesEqual = isEqual

export const randomDir = (): Direction => {
    return Directions[randomIntBetween(0, Directions.length - 1)]
}

export const incrementViaDirection = (coordinate: Coordinate, direction: Direction, increment: number) => {
    switch (direction) {
        case "Up": {
            coordinate.y -= increment
            break
        }
        case "Down": {
            coordinate.y += increment
            break
        }
        case "Left": {
            coordinate.x -= increment
            break
        }
        case "Right": {
            coordinate.x += increment
            break
        }
        case "UpLeft": {
            coordinate.x -= increment
            coordinate.y -= increment
            break
        }
        case "DownLeft": {
            coordinate.x -= increment
            coordinate.y += increment
            break
        }
        case "UpRight": {
            coordinate.x += increment
            coordinate.y -= increment
            break
        }
        case "DownRight": {
            coordinate.x += increment
            coordinate.y += increment
            break
        }
    }
}

export const directionFrom = (a: Coordinate, b: Coordinate): Direction | null => {
    if (!a || !b) {
        return null
    }
    return directionFromRaw(a.x, a.y, b.x, b.y)
}

export const directionFromRaw = (aX: number, aY: number, bX: number, bY: number): Direction | null => {
    if (aX === bX && aY === bY) {
        return null
    }
    if (aX === bX) {
        if (aY < bY) {
            return "Down"
        } else {
            return "Up"
        }
    }

    if (aY === bY) {
        if (aX > bX) {
            return "Left"
        } else {
            return "Right"
        }
    }

    if (aX > bX && aY < bY) {
        return "DownLeft"
    }

    if (aX > bX && aY > bY) {
        return "UpLeft"
    }

    if (aX < bX && aY < bY) {
        return "DownRight"
    }

    if (aX < bX && aY > bY) {
        return "UpRight"
    }

    return null
}

export const toFloor = (coordinate: Coordinate) => {
    if (!coordinate) {
        return
    }
    return {
        x: Math.floor(coordinate.x),
        y: Math.floor(coordinate.y),
    }
}

export const setToFloor = (coordinate: Coordinate, target: Coordinate) => {
    if (!coordinate || !target) {
        return
    }
    target.x = Math.floor(coordinate.x)
    target.y = Math.floor(coordinate.y)
}

export const newLocationFromDir = (
    oldLocation: Coordinate,
    newLocation: Coordinate,
    dir: Direction,
    dist: number,
    fine: boolean = false,
) => {
    const multiplier = !fine ? TileSize : 1
    fillCoordinate(oldLocation, newLocation)
    switch (dir) {
        case "Up": {
            newLocation.y = Math.floor(oldLocation.y + multiplier * -dist)
            return
        }
        case "Down": {
            newLocation.y = Math.floor(oldLocation.y + multiplier * dist)
            return
        }
        case "Left": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * -dist)
            return
        }
        case "Right": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * dist)
            return
        }
        case "UpLeft": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * -dist)
            newLocation.y = Math.floor(oldLocation.y + multiplier * -dist)
            return
        }
        case "UpRight": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * dist)
            newLocation.y = Math.floor(oldLocation.y + multiplier * -dist)
            return
        }
        case "DownLeft": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * -dist)
            newLocation.y = Math.floor(oldLocation.y + multiplier * dist)
            return
        }
        case "DownRight": {
            newLocation.x = Math.floor(oldLocation.x + multiplier * dist)
            newLocation.y = Math.floor(oldLocation.y + multiplier * dist)
            return
        }
    }
}

export type Callback<T> = CallbackBase<T, void>
export type CallbackBase<T, R> = (arg?: T) => R

interface ThruputProps {
    durationWindow?: number
    label?: string
    average?: boolean
}

export class Thruput {
    durationWindow: number
    readings: number = 0
    latch: Latch
    label: string
    latest: number = 0
    average: boolean
    count: number = 0

    constructor({ durationWindow = 10 * 1000, label, average }: ThruputProps) {
        this.durationWindow = durationWindow
        this.latch = new Latch(durationWindow, Date.now())
        this.label = label
        this.average = average
    }

    offer = (value: number) => {
        this.readings += value
        this.count++
        if (this.latch.expired()) {
            const averageOverDuration = this.average
                ? Math.round(this.readings / this.count)
                : Math.round(this.readings / (this.durationWindow / 1000))
            // clear readings
            this.readings = 0
            this.count = 0
            this.latest = averageOverDuration
            return averageOverDuration
        }
        return null
    }

    emitMessage = () => {
        console.log(
            this.average
                ? `${this.label} average: ${this.latest} / ${this.durationWindow / 1000} sec window`
                : `${this.label} thruput: ${this.latest} /sec`,
        )
    }
}

export class ValueProvider<T> {
    private v: T
    constructor(v?: T) {
        this.v = v
    }

    set = (v: T) => (this.v = v)

    get = () => this.v
}

export const makeIterator = <T>(array: T[], terminates?: boolean, index?: ValueProvider<number>): Iterator<T> => {
    index = index || new ValueProvider<number>(-1)

    return {
        next: () => {
            if (array.length < 1) {
                return {
                    value: undefined,
                    done: true,
                }
            }
            index.set(index.get() + 1)

            if (!terminates) {
                if (index.get() > array.length - 1) {
                    index.set(0)
                }
                return {
                    value: array[index.get()],
                    done: false,
                }
            } else {
                if (index.get() > array.length - 1) {
                    return {
                        value: undefined,
                        done: true,
                    }
                }
                return {
                    value: array[index.get()],
                    done: false,
                }
            }
        },
    }
}

export class ResettableIterator<T> implements Iterator<T> {
    index: ValueProvider<number>
    iterator: Iterator<T>
    values: T[]

    constructor(values: T[], terminates: boolean = true) {
        this.index = new ValueProvider<number>(-1)
        this.iterator = makeIterator(values, terminates, this.index)
        this.values = values
    }

    reset = () => {
        this.index.set(-1)
    }

    add = (item: T) => {
        this.values.push(item)
        this.reset()
    }

    remove = (item: T) => {
        this.values = this.values.filter(next => next !== item)
        this.reset()
    }

    next = (): IteratorResult<T, T> => {
        return this.iterator.next()
    }
}

export const guessValType = (val: string) => {
    if (val === "true") {
        return true
    }
    if (val === "false") {
        return false
    }
    if (val === "") {
        return val
    }
    const nan = isNaN(Number(val))
    if (!nan) {
        return parseInt(val)
    }

    // its a string
    return val
}

export const randomOneOf = <T>(
    list: T[],
    options?: {
        leftBoundIdx?: number
        rightBoundIdx?: number
        randomIntBetween?: Function
        seed?: string
        rngFn?: Function
    },
): T => {
    if (!list || list.length < 0) {
        return
    }

    const min = 0 + (options?.leftBoundIdx || 0)
    const max = list.length - 1 - (options?.rightBoundIdx || 0)

    if (options?.rngFn) {
        const pos = randomIntBetween(min, max, {
            rngFn: options.rngFn,
        })
        return list[pos]
    }

    const rndFunctionToUse = options?.randomIntBetween
        ? options.randomIntBetween
        : (min: number, max: number) => randomIntBetween(min, max, options)
    const pos = rndFunctionToUse(min, max)
    return list[pos]
}

export const replaceAll = (src, str, newStr) => {
    // If a regex pattern
    if (Object.prototype.toString.call(str).toLowerCase() === "[object regexp]") {
        return src.replace(str, newStr)
    }

    // If a string
    return src.replace(new RegExp(str, "g"), newStr)
}

export const toPct = (flt: number) => flt * 100

export const blankMap = <T>(width: number, height: number, blankValue?: T): TileMapBase<T> => {
    const matrix: TileMapBase<T> = []
    for (let x = 0; x < width; x++) {
        const column = []
        matrix.push(column)
        for (let y = 0; y < height; y++) {
            column.push(blankValue)
        }
    }

    return matrix
}

export const chunkSentence = (str: string = "", lineLength: number) => {
    const arr = [""]

    str.split(" ").forEach(word => {
        if (arr[arr.length - 1].length + word.length > lineLength) arr.push("")
        arr[arr.length - 1] += word + " "
    })

    return arr.map(v => v.trim())
}

export const repeat = (fn: CallbackBase<unknown, any>, times: number): any[] => {
    const returned: any[] = []
    for (let i = 0; i < times; i++) {
        const val = fn()
        if (val) {
            returned.push(val)
        }
    }
    return returned
}

export const pluralize = (str: string, amt: number) => (amt > 1 ? `${str}s` : `${str}`)

export const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.substring(1)

export const addAll = <T>(src: T[], other: T[]) => {
    other.forEach(o => src.push(o))
}

export const toSet = <T>(src: T[] = []) => {
    const set = new Set()
    src.forEach(t => set.add(t))
    return set
}

export const parseOrUndefined = (str?: string) => (str ? JSON.parse(str) : undefined)
export const randomId = uuidv4

export const firstOrNull = (arr: any[]) => (arr?.length > 0 ? arr[0] : null)

export const randomName = generateName

export const toSafeString = (str: string) => {
    return str.replace(/\s+/gi, "-").replace(/[^a-z0-9]/gi, "")
}

export const deepEqual = (a: any, b: any): boolean => {
    if (a === b) return true

    if (typeof a !== "object" || a === null || typeof b !== "object" || b === null) return false

    const keysA = Object.keys(a),
        keysB = Object.keys(b)

    if (keysA.length !== keysB.length) return false

    for (const key of keysA) {
        if (!keysB.includes(key) || !deepEqual(a[key], b[key])) return false
    }

    return true
}

type StringProcessingFunction = (value: string) => string

export const processJsonStrings = (obj: any, processFn: StringProcessingFunction): any => {
    return JSON.parse(processFn(JSON.stringify(obj)))
}

export const locationToString = (location: Location) => {
    return `${location.roomId}/${location.x}/${location.y}`
}

export const calculatePointAtDistance = (start: Coordinate, end: Coordinate, distance: number): Coordinate => {
    // Calculate the direction vector (dx, dy)
    const dx = end.x - start.x
    const dy = end.y - start.y

    // Calculate the length of the vector
    const length = Math.sqrt(dx * dx + dy * dy)

    // Normalize the vector to get the unit vector
    const ux = dx / length
    const uy = dy / length

    // Scale the unit vector by the desired distance
    const vx = ux * distance
    const vy = uy * distance

    // Calculate the target coordinate
    const targetX = start.x + vx
    const targetY = start.y + vy

    return { x: Math.floor(targetX), y: Math.floor(targetY) }
}

export const calculateNewCoordinateFromRadians = (
    start: Coordinate,
    angleRadians: number,
    distance: number,
): Coordinate => {
    // Calculate the change in x and y using the angle in radians
    const dx = Math.cos(angleRadians) * distance
    const dy = Math.sin(angleRadians) * distance

    // Calculate the new coordinate
    const newX = start.x + dx
    const newY = start.y + dy

    return { x: Math.floor(newX), y: Math.floor(newY) }
}

export const parseLocationStr = (locationStr: string) => {
    if (locationStr) {
        const [roomId, xStr, yStr] = locationStr.split("/")
        return {
            roomId,
            x: Number(xStr),
            y: Number(yStr),
        }
    }
}

export const guaranteeArray = <T>(x: T[] | T) => {
    if (Array.isArray(x)) {
        return x
    }

    return [x]
}

function isNumber(value: string) {
    return !isNaN(parseFloat(value)) && isFinite(value as any)
}

export const jsonOrUndefined = (str: any) => {
    if (typeof str === "object") {
        return str
    }

    if (!str) {
        return
    }

    if (isNumber(str)) {
        return undefined
    }

    try {
        return JSON.parse(str)
    } catch (e) {}
}
