import { sound } from "@pixi/sound"
import { Entity } from "game-common/models"
import { BaseRenderable } from "./base_renderable"

export class Bomb extends BaseRenderable {
    startTs: number = 0
    pulseDelay: number
    i: number = 0
    size: number = 0
    detonted: boolean
    pulseTimeout: any
    entity: Entity
    tickPlayInterval: any

    constructor(entity: Entity) {
        super()
        this.entity = entity

        this.drawArea(0xb3ebf2, 0, 2)
        this.pulseDelay = 500
        this.startTs = Date.now()

        this.pulse()
        this.tickPlayInterval = setInterval(() => {
            sound.play("clocktick", {
                volume: 0.1,
                speed: 0.85 + Math.min(0.15, Math.random()),
            })
        }, 500)
    }

    pulse = () => {
        if (this.entity?.isDead() || this.detonted) {
            this.visible = false
            return
        }

        this.i++
        let color = this.i % 2 === 0 ? 0x80deea : 0xb3ebf2

        this.drawArea(color, this.i, 2)

        if (this.pulseDelay > 50) {
            this.pulseDelay *= 0.8
            this.pulseTimeout = setTimeout(this.pulse, this.pulseDelay)
        } else {
            this.pulseTimeout = setTimeout(this.pulse, this.pulseDelay)
        }
    }

    detonate = () => {
        if (this.detonted) {
            return
        }
        clearInterval(this.tickPlayInterval)
        clearTimeout(this.pulseTimeout)
        this.detonted = true
        let maxRadius = 100
        let i = 0

        sound.play("explosion")

        const interval = setInterval(() => {
            if (this.entity?.isDead() || this.size > maxRadius) {
                this.clear()
                clearInterval(interval)
                return
            }
            const increment = maxRadius / 17
            this.size += increment
            i++

            this.drawArea(i % 2 === 0 ? 0xff0000 : 0xefefef, 0, this.size)
        }, 17)
    }

    drawArea = (color: number, i: number, radius: number) => {
        super.clear()
        super.beginFill(color)
        super.lineStyle(10, color)
        super.drawCircle(0, 0, radius + (i % 2 === 0 ? 0 : 1))
        super.endFill()
    }

    remove() {
        super.remove()
        clearInterval(this.tickPlayInterval)
    }
}
