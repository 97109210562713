import { Entity, EntityId } from "game-common/models"

import { LpcLayered } from "../sprites/lpc_layered"
import { DyanmicTextContainer, DyanmicTextContainerProps } from "./dynamic_text_container"

export interface EntityDisplayerProps {
    entityId: EntityId
    scale?: number
}

export class EntityDisplayer extends DyanmicTextContainer {
    entityProps: EntityDisplayerProps
    entity: Entity

    constructor(entityProps: EntityDisplayerProps, entity: Entity, props?: DyanmicTextContainerProps) {
        super(props)
        this.entityProps = entityProps
        this.entity = entity
    }

    createContent = (): DyanmicTextContainer => {
        const content = new DyanmicTextContainer()

        const lpcComposite = new LpcLayered(this.entity.entityId, () => {})
        lpcComposite.updatePaperdoll(this.entity)
        lpcComposite.scale.set(this.entityProps.scale || 3)

        content.append2(lpcComposite, {
            topMargin: 32,
            leftMargin: 32,
        })

        return content
    }
}
