import { Graphics, BitmapText } from "pixi.js"
import {
    AbilityCharacteristics,
    AbilityType,
    Callback,
    Coordinate,
    EntityId,
    IndexedMap,
    Inventory,
} from "game-common/models"
import { ClientRenderable } from "../entity_rendering_manager"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { Plus } from "../sprites/plus"
import { TextRenderer } from "../text/text_renderer"

class Pulsar {
    private circle: Graphics
    private mask: Graphics
    private volume: any
    private circleGlow: any
    entityId: EntityId
    private pulsing: boolean

    constructor(id: EntityId, circle: Graphics, mask: Graphics) {
        this.entityId = id
        this.circle = circle
        this.mask = mask
    }

    pulse = (duration: number) => {
        if (this.pulsing) {
            clearInterval(this.circleGlow)
            clearInterval(this.volume)
        }
        this.pulsing = true
        let { circle, mask } = this

        circle.alpha = 1.0
        circle.lineStyle(1, 0xffffff)
        circle.beginFill(0xffffff)
        circle.drawCircle(0, 0, 14)
        circle.endFill()

        mask.clear()
        mask.beginFill(0xff3300)
        mask.drawRect(-15, -15, 30, 30)
        mask.endFill()

        let dir = 1
        this.circleGlow = setInterval(() => {
            if (dir === -1) {
                circle.alpha -= 0.01
            } else {
                circle.alpha += 0.01
            }

            if (circle.alpha < 0.2) {
                dir = 1
            }
            if (circle.alpha > 0.8) {
                dir = -1
            }
        }, 10)

        let h = 0
        this.volume = setInterval(() => {
            if (h >= 30) {
                clearInterval(this.volume)
                clearInterval(this.circleGlow)
                this.pulsing = false
                return
            }
            h += 1
            mask.clear()
            mask.beginFill(0xff3300)
            mask.drawRect(-15, h - 15, 30, 30)
            mask.endFill()
        }, duration / 30)
    }

    remove = () => {
        this.circle.parent.removeChild(this.circle)
        this.mask.parent.removeChild(this.mask)
    }
}

export class AbilitiesWidget extends Graphics {
    guiManager: GuiManager
    slotCoordinates: Map<string, Coordinate> = new Map()
    slotPulsar: IndexedMap<Pulsar> = new IndexedMap()

    constructor(guiManager: GuiManager) {
        super()
        this.guiManager = guiManager
    }

    renderSlot = (
        abilityType: AbilityType,
        label: string,
        item: ClientRenderable,
        quantity: number,
        cap: number,
        callback: Callback,
        showRing: boolean,
    ) => {
        const slot = new Graphics()

        const mask = new Graphics()
        mask.name = "mask"
        mask.beginFill(0xff3300)
        mask.drawRect(-15, -15, 30, 30)
        mask.endFill()

        const circle = new Graphics()
        circle.name = "circle"
        circle.alpha = 0.0
        circle.lineStyle(1, 0xffffff)
        circle.beginFill(0xffffff)
        circle.drawCircle(0, 0, 14)
        circle.endFill()
        circle.mask = mask

        slot.addChild(mask, circle)

        if (showRing) {
            const circle2 = new Graphics()
            circle2.lineStyle(1, 0xffffff)
            circle2.drawCircle(0, 0, 14)
            slot.addChild(circle2)
        }

        item.y = -2
        item.x = -2
        slot.addChild(item)

        const labelObject = new TextRenderer().render(`${label}: ${quantity}${cap ? `/${cap}` : ""}`)

        labelObject.x = -20
        labelObject.y = -30
        slot.addChild(labelObject)

        const pulsar = new Pulsar(abilityType, circle, mask)
        this.slotPulsar.set(abilityType, pulsar)

        slot.interactive = true
        slot.on("pointerdown", callback)

        return slot
    }

    update = (inventory: Inventory, slotCallback: (equipmentShortcut: string) => void) => {
        // clear all
        this.slotCoordinates.clear()
        this.slotPulsar.iterate(next => next.remove())
        this.slotPulsar.clear()
        this.removeChildren()

        let slotPos = 0
        Object.values(inventory?.abilities || {})
            .sort(
                (a, b) =>
                    AbilityCharacteristics[a.abilityType].equipOrder - AbilityCharacteristics[b.abilityType].equipOrder,
            )
            .map(item => {
                const { abilityType, quantity } = item
                if (quantity < 1) {
                    return
                }
                const meta = AbilityCharacteristics[abilityType]

                const shortcutKey = meta.shortcutKey

                let renderable: ClientRenderable
                let showRing = true
                switch (abilityType) {
                    case "heal": {
                        renderable = new Plus(0x00ff00)
                        renderable.scale.set(0.7)
                        break
                    }
                    case "mana": {
                        renderable = new Plus(0x399cbd)
                        renderable.scale.set(0.7)
                        break
                    }
                    case "bomb": {
                        renderable = new BasicSprite({
                            color: 0xffffff,
                            name: "bomb.png",
                            scale: 1.45,
                            animated: false,
                        })
                        showRing = false
                        break
                    }
                    case "speed": {
                        // todo
                        break
                    }
                }

                const slot = this.renderSlot(
                    abilityType,
                    shortcutKey,
                    renderable,
                    quantity,
                    meta.maxCarried || 0,
                    () => slotCallback(shortcutKey),
                    showRing,
                )

                slot.x = slotPos * 50
                this.addChild(slot)

                this.slotCoordinates.set(abilityType, {
                    x: slot.x,
                    y: slot.y,
                })

                slotPos++
            })
    }
}
