import { Entity, EntityId, Location, LocationIcon, Quest, QuestsContainer } from "../models"

export class QuestMechanics {
    static getCurrentQuest = (entity: Entity): Quest => {
        const currentQuestId = entity?.context?.questsContainer?.currentQuestId
        if (!currentQuestId) {
            return
        }
        return entity?.context?.questsContainer?.activeQuests?.find(n => n.entityId === currentQuestId)
    }

    static setCurrentQuest = (entity: Entity, quest: Quest) => {
        if (!entity.context) {
            entity.context = {}
        }
        const container: QuestsContainer = entity.context.questsContainer || {
            activeQuests: [],
            completedQuests: [],
        }
        entity.context.questsContainer = container
        container.currentQuestId = quest.entityId
    }

    static getQuest = (entity: Entity, questId: string) => {
        return entity?.context?.questsContainer?.activeQuests?.find(n => n.entityId === questId)
    }

    static isCurrentQuest = (entity: Entity, quest: Quest) => {
        return this.getCurrentQuest(entity)?.entityId === quest.entityId
    }

    static setActiveQuestMarker = (entity: Entity, questMarker: LocationIcon): Entity => {
        if (!entity.context) {
            entity.context = {}
        }
        entity.context.questActiveMarkerId = questMarker.id
        return entity
    }

    static getActiveQuestMarkerId = (entity: Entity): string => {
        return entity.context?.questActiveMarkerId
    }

    static setActiveQuestObjective = (
        originEntity: Entity,
        questId: string,
        objectiveId: string,
        markerLocation?: Location,
    ) => {
        const quest = originEntity.context?.questsContainer?.activeQuests.find(n => n.entityId === questId)
        if (!quest) {
            return originEntity
        }

        const active = quest.objectives?.find(n => n.status === "active")
        if (active) {
            active.status = "complete"
        }

        const objective = quest.objectives?.find(n => n.id === objectiveId)
        if (objective) {
            objective.status = "active"
            objective.markerLocation = markerLocation

            // also set the current quest
            originEntity.context.questsContainer.currentQuestId = quest.entityId
        }

        return originEntity
    }

    static getActiveQuestObjective = (originEntity: Entity, questId: string) => {
        const quest = originEntity.context?.questsContainer?.activeQuests.find(n => n.entityId === questId)
        return quest.objectives?.find(n => n.status === "active")
    }

    static addQuest = (entity: Entity, quest: Quest, setCurrent: boolean): Entity => {
        if (!entity.context) {
            entity.context = {}
        }
        const container: QuestsContainer = entity.context.questsContainer || {
            activeQuests: [],
            completedQuests: [],
        }
        entity.context.questsContainer = container

        if (!container.activeQuests.find(n => n.entityId === quest.entityId)) {
            container.activeQuests.push(quest)
        }
        if (setCurrent) {
            container.currentQuestId = quest.entityId
        }

        return entity
    }

    static completeQuest = (entity: Entity, questId: EntityId): Entity => {
        if (!entity.context) {
            entity.context = {}
        }
        const container: QuestsContainer = entity.context.questsContainer || {
            activeQuests: [],
            completedQuests: [],
        }
        entity.context.questsContainer = container

        const quest = container.activeQuests.find(n => n.entityId === questId)
        if (quest) {
            quest.status = "complete"
            container.activeQuests = container.activeQuests.filter(n => n.entityId !== questId)
            if (!container.completedQuests.find(n => n.entityId === questId)) {
                container.completedQuests.push(quest)
            }

            // clear current quest id
            container.currentQuestId = undefined
        }

        return entity
    }

    static findActiveQuest = (entity: Entity): Quest => {
        return entity.context?.questsContainer?.activeQuests?.find(n => n.status === "active")
    }

    static destroyQuest = (entity: Entity, questId: string) => {
        const quest = this.getQuest(entity, questId)
        if (!quest) {
            return
        }

        entity.context.questsContainer.activeQuests = entity?.context?.questsContainer.activeQuests?.filter(
            n => n.entityId !== questId,
        )

        return entity
    }
}
