import { Graphics, BitmapText } from "pixi.js"
import { LeaderboardMeta } from "game-common/models"
import { GuiManager } from "../gui_manager"

export class LeaderboardWidget extends Graphics {
    leaderboards: LeaderboardMeta[]
    guiManager: GuiManager
    show: boolean[] = []
    toggle: Graphics[]

    constructor(guiManager: GuiManager) {
        super()

        this.guiManager = guiManager
    }

    private createToggles = (height: number, i: number) => {
        if (this.toggle[i]) {
            return
        }
        const toggle = new Graphics()
        this.toggle[i] = toggle
        toggle.lineStyle(1, 0xff0000)

        // Because the array is initialized as [], we need to specifically check for undefined, not just a falsey value.
        if (this.show[i] === undefined) {
            this.show[i] = false
        }

        if (!this.show[i]) {
            toggle.beginFill(0x101010)
            toggle.drawRect(0, 0, 8, 8)
            toggle.endFill()
        } else {
            toggle.beginFill(0xcc0000)
            toggle.drawRect(0, 0, 8, 8)
            toggle.endFill()
        }

        toggle.x = -15
        toggle.y = 7 + height
        toggle.interactive = true
        toggle.on("mouseover", () => {
            this.guiManager.mouseOverControl(true)
        })
        toggle.on("mouseout", () => {
            this.guiManager.mouseOverControl(false)
        })
        toggle.on("mouseup", () => {
            this.show[i] = !this.show[i]
            this.update(this.leaderboards)
        })

        this.addChild(toggle)
    }
    private renderBoard = (boardIndex: number, leaderboard: LeaderboardMeta, pos: number, show: boolean) => {
        const graphics = new Graphics()

        const name = leaderboard.name
            .replace("_", " ")
            .toLowerCase()
            .split(" ")
            .map(n => {
                return n
                    .split("")
                    .map((l, i) => (i === 0 ? l.toUpperCase() : l))
                    .join("")
            })
            .join(" ")

        const leaderName = leaderboard.items[0].name

        const headerContainer = new Graphics()
        const headerText = new BitmapText(`Top ${name}`, { fontName: "LeaderboardFont" })
        headerContainer.addChild(headerText)
        headerContainer.interactive = true
        headerContainer.on("mouseover", () => {
            this.guiManager.mouseOverControl(true)
        })
        headerContainer.on("mouseout", () => {
            this.guiManager.mouseOverControl(false)
        })
        headerContainer.on("mouseup", () => {
            this.show[boardIndex] = !this.show[boardIndex]
            this.update(this.leaderboards)
        })
        if (!show) {
            const name = new BitmapText(leaderName, { fontName: "LeaderboardLeaderFont" })
            name.x = 175 - name.width
            headerContainer.addChild(name)
        }

        graphics.addChild(headerContainer)
        graphics.lineStyle(1, 0x00a000)
        graphics.drawRect(0, headerText.height, 175, 1)

        let height = headerContainer.height
        if (show) {
            leaderboard.items.forEach((item, i) => {
                const font = { fontName: i == 0 ? "LeaderboardLeaderFont" : "LeaderboardFont" }
                const name = new BitmapText(item.name, font)
                name.y = headerContainer.height + i * name.height + 3
                graphics.addChild(name)

                const count = new BitmapText("" + item.count, font)
                count.y = headerContainer.height + i * count.height + 3
                count.x = graphics.width - count.width
                graphics.addChild(count)

                height += name.height
            })
        }

        graphics.y = pos
        this.addChild(graphics)

        return height + (show ? 15 : 5)
    }

    update = (leaderboards: LeaderboardMeta[] | null) => {
        this.leaderboards = leaderboards
        this.toggle = this.leaderboards ? new Array(leaderboards.length).fill(null) : []

        // clear all
        this.removeChildren()

        if (!leaderboards) {
            return
        }

        let prevHeight = 0
        leaderboards.sort().forEach((next, i) => {
            this.createToggles(prevHeight, i)
            prevHeight += this.renderBoard(i, next, prevHeight, this.show[i])
        })
    }
}
