import { Entity } from "../models"
import { ItemMechanics } from "./item_mechanics"

class Defense {
    static maximumDefenseProbability = 0.8

    static calculateDefenseProbability = (entity: Entity) => {
        const pctOfTotalProtection = Math.min(
            this.maximumDefenseProbability,
            ItemMechanics.protection.computeProtectionPctOfMax(entity.inventory),
        )
        return pctOfTotalProtection
    }
}

export const FormationTypes = ["square", "circle", "random"] as const

export type FormationType = (typeof FormationTypes)[number]

export const SquadStanceTypes = ["defense", "strategic", "aggressive"]

export type SquadStanceType = (typeof SquadStanceTypes)[number]

export interface SquadState {
    stance: SquadStanceType
    follow: boolean
    formation: FormationType
    count: number
}

class Squad {}

export class CombatMechanics {
    static defense = Defense
    static squad = Squad
}
