import { EntityTile, EntityTileDamageMeta } from "../map/map"

export const EntityTilesDamageDefaults: Partial<Record<EntityTile, number>> = {
  "door-wood": 1,
  "roof-wood": 1,
  "floor-wood": 1,
  "chest-wood": 1,
  "wall-wood": 1,
  "wall2-wood": 1,
  "testore": 6,
  "ore-coal": 6,
  "tallReeds": 1,
  "tallGrass": 1,
  "sapling": 1,
  "tree1Small": 1,
  "tree2Small": 1,
  "table-mid-round": 1
}

export const EntityTileDamageSpecifics: Partial<Record<EntityTile, EntityTileDamageMeta[]>> = {
  "testore": [
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "ore-coal": [
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "tree1": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "tree2": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "tree3": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "tree4": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "tree5": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "smallTree1": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "tallReeds": [
    {
      "weaponType": "bow",
      "damage": 0
    },
    {
      "weaponType": "web",
      "damage": 0
    },
    {
      "weaponType": "longsword",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 0
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "tallGrass": [
    {
      "weaponType": "bow",
      "damage": 0
    },
    {
      "weaponType": "web",
      "damage": 0
    },
    {
      "weaponType": "longsword",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 0
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "sapling": [
    {
      "weaponType": "bow",
      "damage": 0
    },
    {
      "weaponType": "web",
      "damage": 0
    },
    {
      "weaponType": "longsword",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 0
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "tree1Small": [
    {
      "weaponType": "bow",
      "damage": 0
    },
    {
      "weaponType": "web",
      "damage": 0
    },
    {
      "weaponType": "longsword",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 0
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "tree2Small": [
    {
      "weaponType": "bow",
      "damage": 0
    },
    {
      "weaponType": "web",
      "damage": 0
    },
    {
      "weaponType": "longsword",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 0
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    }
  ],
  "palmTree1": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "palmTree2": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "palmTree3": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "palmTree4": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "palmTree5": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ],
  "palmTree6": [
    {
      "weaponType": "dagger",
      "damage": 6
    },
    {
      "weaponType": "spear",
      "damage": 6
    },
    {
      "weaponType": "longsword",
      "damage": 6
    },
    {
      "weaponType": "waraxe",
      "damage": 10
    },
    {
      "weaponType": "axe",
      "damage": 10
    },
    {
      "weaponType": "warhammer",
      "damage": 30
    },
    {
      "weaponType": "bow",
      "damage": 1
    },
    {
      "weaponType": "web",
      "damage": 1
    },
    {
      "weaponType": "crossbow",
      "damage": 1
    },
    {
      "weaponType": "none",
      "damage": 1
    }
  ]
}