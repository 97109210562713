import { LpcSheet } from "game-common/lpc/lpc"
import { Latch } from "game-common/models"
import { Callback } from "game-common/util"
import { AnimatedSprite, Graphics, Loader, Resource, Sprite, Texture } from "pixi.js"

import { PixiJsClientRenderer } from "../pixijs_client_renderer"
import { spriteSheetLoader } from "./spritesheet_loader"
import { LpcRenderProps } from "./lpc_composite_sprite"

const INTERVAL = 20

interface Job {
    process: () => void
    getResult: () => AnimatedSprite | null
    getKey: () => string
}

class SynchronousJob implements Job {
    action: any
    lpcRenderMeta: any
    lpcRenderProps: LpcRenderProps
    descriptors: LpcSheet[]
    result: AnimatedSprite
    callback: Callback<AnimatedSprite>
    startTs: number
    key: string
    oversized: boolean

    static textureKey: Map<string, Texture> = new Map()

    constructor(
        key: string,
        action: any,
        lpcRenderMeta: any,
        lpcRenderProps: LpcRenderProps,
        descriptors: LpcSheet[],
        callback: Callback<AnimatedSprite>,
    ) {
        this.key = key
        this.action = action
        this.lpcRenderMeta = lpcRenderMeta
        this.lpcRenderProps = lpcRenderProps
        this.descriptors = descriptors
        this.callback = callback
        this.startTs = Date.now()
    }

    process = () => {
        let textureLayers = []

        const processTextureLayers = () => {
            const layers: Texture<Resource>[][] = textureLayers.sort((a, b) => a.order - b.order).map(n => n.textures)
            const animationFrameTextures = []

            const frameCount = layers[0].length

            for (let frame = 0; frame < frameCount; frame++) {
                const textureKey = `${this.key}-f-${frame}`
                let combinedTexture = SynchronousJob.textureKey.get(textureKey)
                if (!combinedTexture) {
                    const surface = new Graphics()
                    for (let l = 0; l < layers.length; l++) {
                        const layerFrame = layers[l][frame]
                        const sprite = new Sprite(layerFrame)
                        surface.addChild(sprite)

                        if (this.lpcRenderProps.isSubmerged) {
                            const mask = new Graphics()
                            mask.beginFill(0x000000, 1.0)
                            mask.drawRect(-40, -40, 80, 64)
                            mask.endFill()

                            sprite.addChild(mask)
                            sprite.mask = mask
                        }
                    }
                    combinedTexture = PixiJsClientRenderer._app.renderer.generateTexture(surface)
                    SynchronousJob.textureKey.set(textureKey, combinedTexture)
                }

                animationFrameTextures.push(combinedTexture)
            }

            if (animationFrameTextures.length < 1) {
                this.callback(null)
                return
            }

            const source = this.lpcRenderMeta
            if (!source) {
                this.callback(null)
                return
            }

            const { scale, speed = 0.267, color = 0xffffff } = source

            const animation = new AnimatedSprite(animationFrameTextures)

            animation.scale.set(scale)
            animation.animationSpeed = speed
            animation.visible = false
            animation.tint = color

            if (this.oversized) {
                animation.x -= 64
                animation.y -= 51.25
            }

            this.result = animation

            this.callback(animation)
        }

        this.descriptors.forEach((descriptor, i) => {
            const sheetId = `lpc/spritesheets/${descriptor.entityId}.json`
            const url = `assets/${sheetId}`
            const key = `${url}`

            const loader = spriteSheetLoader.getLoaderFor(url)
            const texturesSource = loader.resources[key]?.textures
            if (!texturesSource) {
                return
            }
            Object.keys(texturesSource).forEach(k => {
                if (k.startsWith(this.action)) {
                    if (descriptor.type === "oversize") {
                        this.oversized = true
                    }
                }
            })
            const textures = Object.keys(texturesSource)
                .filter(k => k.startsWith(this.action))
                .map(k => loader.resources[key].textures[k])

            const x = {
                order: i,
                textures,
            }
            textureLayers.push(x)
        })

        processTextureLayers()
    }

    getResult = (): AnimatedSprite => this.result

    getKey = (): string => this.key
}

interface ProcessUnit {
    frameSurface: Graphics
    frame: number
    layer: number
    combine: boolean
}

class StatefulJob implements Job {
    action: any
    lpcRenderMeta: any
    descriptors: LpcSheet[]
    result: AnimatedSprite
    callback: Callback<AnimatedSprite>
    startTs: number
    layers: Texture<Resource>[][]
    processUnits: ProcessUnit[] = []
    currentProcessUnitIdx: number = 0
    done: boolean
    animationFrameTextures = []
    key: string

    constructor(
        key: string,
        action: any,
        lpcRenderMeta: any,
        descriptors: LpcSheet[],
        callback: Callback<AnimatedSprite>,
    ) {
        this.key = key
        this.action = action
        this.lpcRenderMeta = lpcRenderMeta
        this.descriptors = descriptors
        this.callback = callback
        this.startTs = Date.now()

        const textureLayers = []
        this.descriptors.forEach((descriptor, i) => {
            const sheetId = `lpc/spritesheets/${descriptor.entityId}.json`
            const url = `assets/${sheetId}`
            const key = `${url}`

            const loader = spriteSheetLoader.getLoaderFor(url)
            const texturesSource = loader.resources[key]?.textures
            if (!texturesSource) {
                return
            }
            const textures = Object.keys(texturesSource)
                .filter(k => k.startsWith(this.action))
                .map(k => loader.resources[key].textures[k])

            const x = {
                order: i,
                textures,
            }
            textureLayers.push(x)
        })

        this.layers = textureLayers.sort((a, b) => a.order - b.order).map(n => n.textures)

        const frameCount = this.layers[0].length

        for (let frame = 0; frame < frameCount; frame++) {
            const surface: Graphics = new Graphics()
            for (let l = 0; l < this.layers.length; l++) {
                const processUnit: ProcessUnit = {
                    frame,
                    layer: l,
                    frameSurface: surface,
                    combine: l === this.layers.length - 1,
                }
                this.processUnits.push(processUnit)
            }
        }
    }

    process = () => {
        // if (this.processUnits.length < 1) {
        //     this.callback(null)
        //     this.done = true
        //     return
        // }

        const source = this.lpcRenderMeta
        if (!source) {
            this.callback(null)
            return
        }

        const processUnit = this.processUnits[this.currentProcessUnitIdx]
        if (!processUnit) {
            this.done = true
            if (this.animationFrameTextures.length < 1) {
                this.callback(null)
                return
            }

            const { scale, speed = 0.267, color = 0xffffff } = source

            const animation = new AnimatedSprite(this.animationFrameTextures)
            animation.scale.set(scale)
            animation.animationSpeed = speed
            animation.visible = false
            animation.tint = color

            this.result = animation
            this.callback(animation)

            return
        }

        const frameSurface = processUnit.frameSurface
        const layer = this.layers[processUnit.layer]
        const layerFrame = layer[processUnit.frame]
        const sprite = new Sprite(layerFrame)
        frameSurface.addChild(sprite)

        if (processUnit.combine) {
            const combinedTexture = PixiJsClientRenderer._app.renderer.generateTexture(frameSurface)
            this.animationFrameTextures.push(combinedTexture)
        }

        this.currentProcessUnitIdx++
    }

    getResult = (): AnimatedSprite => this.result

    getKey = (): string => this.key
}

class LpcCompositeGenerator {
    loader: Loader
    jobs: any[] = []
    timer: any
    // cache: Map<string, AnimatedSprite> = new Map()
    processing: Set<string> = new Set()
    latch: Latch = new Latch(1000)

    generate = (
        priority: boolean,
        action: any,
        lpcRenderMeta: any,
        lpcRenderProps: LpcRenderProps,
        descriptors: LpcSheet[],
        callback: Callback<AnimatedSprite>,
    ) => {
        const key = descriptors.map(d => d.entityId).join("") + "_" + action + lpcRenderProps.isSubmerged
        const job: Job = new SynchronousJob(key, action, lpcRenderMeta, lpcRenderProps, descriptors, callback)

        if (priority) {
            this.jobs.unshift(job)
        } else {
            this.jobs.push(job)
        }
        if (!this.timer) {
            this.timer = setInterval(this.process, INTERVAL)
        }
    }

    process = () => {
        if (this.jobs.length < 1) {
            clearInterval(this.timer)
            this.timer = undefined
            return
        }

        const current: Job = this.jobs.shift()
        if (current) {
            current.process()
            const result = current.getResult()
        }
    }
}

const lpcCompositeGenerator = new LpcCompositeGenerator()
export { lpcCompositeGenerator }

// import { LpcSheet } from "game-common/lpc/lpc"
// import { Callback } from "game-common/util"
// import { AnimatedSprite, Graphics, Loader, Resource, Sprite, Texture } from "pixi.js"
// import { PixiJsClientRenderer } from "../pixijs_client_renderer"
// import { spriteSheetLoader } from "./spritesheet_loader"

// const INTERVAL = 1

// interface ProcessUnit {
//     frameSurface: Graphics
//     frame: number
//     layer: number
//     combine: boolean
// }

// class Job {
//     action: any
//     lpcRenderMeta: any
//     descriptors: LpcSheet[]
//     result: AnimatedSprite
//     callback: Callback<AnimatedSprite>
//     startTs: number
//     layers: Texture<Resource>[][]
//     processUnits: ProcessUnit[] = []
//     currentProcessUnitIdx: number = 0
//     done: boolean
//     animationFrameTextures = []

//     constructor(action: any, lpcRenderMeta: any, descriptors: LpcSheet[], callback: Callback<AnimatedSprite>) {
//         this.action = action
//         this.lpcRenderMeta = lpcRenderMeta
//         this.descriptors = descriptors
//         this.callback = callback
//         this.startTs = Date.now()

//         const textureLayers = []
//         this.descriptors.forEach((descriptor, i) => {
//             const sheetId = `lpc/spritesheets/${descriptor.id}.json`
//             const url = `assets/${sheetId}`
//             const key = `${url}`

//             const loader = spriteSheetLoader.getLoaderFor(url)
//             const texturesSource = loader.resources[key]?.textures
//             if (!texturesSource) {
//                 // console.log("COULD NOT FIND", key)
//                 return
//             } else {
//                 // console.log("* FOUND " + key + ".")
//             }
//             // console.log("----", JSON.stringify( Object.keys(texturesSource), null, 1))
//             const textures = Object.keys(texturesSource).filter(k => k.startsWith(this.action)).map(k => loader.resources[key].textures[k])

//             const x = {
//                 order: i,
//                 textures,
//             }
//             textureLayers.push(x)
//         })

//         this.layers = textureLayers.sort((a, b) => a.order - b.order).map(n => n.textures)

//         const frameCount = this.layers[0].length

//         for (let frame = 0; frame < frameCount; frame++) {
//             const surface: Graphics = new Graphics()
//             for (let l = 0; l < this.layers.length; l++) {
//                 const processUnit: ProcessUnit = {
//                     frame,
//                     layer: l,
//                     frameSurface: surface,
//                     combine: l === this.layers.length - 1,
//                 }
//                 this.processUnits.push(processUnit)
//             }
//         }
//     }

//     process = () => {
//         // if (this.processUnits.length < 1) {
//         //     this.callback(null)
//         //     this.done = true
//         //     return
//         // }

//         const source = this.lpcRenderMeta
//         if (!source) {
//             this.callback(null)
//             return
//         }

//         const processUnit = this.processUnits[this.currentProcessUnitIdx]
//         if (!processUnit) {
//             // console.log("done processing", this.processUnits.length)
//             // we're done
//             this.done = true
//             if (this.animationFrameTextures.length < 1) {
//                 this.callback(null)
//                 return
//             }

//             const { scale, speed = 0.267, color = 0xFFFFFF } = source

//             const animation = new AnimatedSprite(this.animationFrameTextures);
//             animation.scale.set(scale)
//             animation.animationSpeed = speed
//             animation.visible = false
//             animation.tint = color

//             this.result = animation
//             this.callback(animation)

//             return
//         }

//         const frameSurface = processUnit.frameSurface
//         const layer = this.layers[processUnit.layer]
//         const layerFrame = layer[processUnit.frame]
//         const sprite = new Sprite(layerFrame)
//         frameSurface.addChild(sprite)

//         if (processUnit.combine) {
//             const combinedTexture =  PixiJsClientRenderer._app.renderer.generateTexture(frameSurface)
//             this.animationFrameTextures.push(combinedTexture)
//         }

//         this.currentProcessUnitIdx++
//     }
// }

// class LpcCompositeGenerator {
//     loader: Loader
//     jobs: any[] = []
//     timer: any

//     generate = (
//         action: any,
//         lpcRenderMeta: any,
//         descriptors: LpcSheet[],
//         callback: Callback<AnimatedSprite>,
//     ) => {
//         const job: Job = new Job(
//             action,
//             lpcRenderMeta,
//             descriptors,
//             callback
//         )

//         this.jobs.push(job)
//         if (!this.timer) {
//             this.timer = setInterval(this.process, INTERVAL)
//         }
//     }

//     process = () => {
//         if (this.jobs.length < 1) {
//             clearInterval(this.timer)
//             this.timer = undefined
//             return
//         }

//         const current: Job = this.jobs.shift()
//         if (current) {
//             current.process()
//             if (!current.done) {
//                 // put it back
//                 this.jobs.unshift(current)
//             }
//         }
//     }

// }

// const lpcCompositeGenerator = new LpcCompositeGenerator()
// export {
//     lpcCompositeGenerator,
// }
