import { Application, InteractionEvent, Point } from "pixi.js"
import { IMouse } from "../../../../client_models"

interface MouseState {
    position: Point
    buttons: Map<string, boolean>
}

type Listener = (mouseState: MouseState) => void

export class Mouse implements IMouse {
    public static listeners: Listener[] = []

    public static state: MouseState = {
        position: new Point(),
        buttons: new Map(),
    }

    addListener = (listener: (mouseState: MouseState) => void) => {
        if (Mouse.listeners.indexOf(listener) < 0) {
            Mouse.listeners.push(listener)
        }
    }

    removeListener = (listener: (mouseState: MouseState) => void) => {
        Mouse.listeners = Mouse.listeners.filter(l => l != listener)
    }

    updateButton = (buttonId: string, val: boolean) => {
        Mouse.state.buttons.set(buttonId, val)
    }

    state = (): MouseState => {
        return Mouse.state
    }

    public static initialize(app: Application) {
        app.stage.on("pointermove", Mouse.movePointer)
        document.addEventListener("mousedown", Mouse.pointerDown)
        document.addEventListener("mouseup", Mouse.pointerUp)
    }
    private static movePointer(e: InteractionEvent): void {
        Mouse.state.position = e.data.global
        Mouse.listeners.forEach(listener => listener(Mouse.state))
    }

    private static pointerUp(e: MouseEvent): void {
        Mouse.state.buttons.set(e.button.toString(), false)
        Mouse.listeners.forEach(listener => listener(Mouse.state))
    }

    private static pointerDown(e: MouseEvent): void {
        Mouse.state.buttons.set(e.button.toString(), true)
        Mouse.listeners.forEach(listener => listener(Mouse.state))
    }
}
