import { Graphics } from "pixi.js"
import { GuiManager } from "../gui_manager"
import TextInput from "pixi-text-input"
import { DynamicText } from "./dynamic_text"
import { Modal } from "./modal"
import { Callback } from "game-common/util"
import { SubmittedFeedback } from "game-common/models"

const MODAL_WIDTH = 400
const MODAL_HEIGHT = 340

export interface FeedbackProps {
    onSubmit: Callback<SubmittedFeedback>
}

export class Feedback {
    guiManager: GuiManager
    surface: Graphics
    fields: TextInput[] = []
    feedbackText: string = ""
    emailText: string = ""
    modal: Modal
    props: FeedbackProps

    constructor(guiManager: GuiManager, props: FeedbackProps) {
        this.guiManager = guiManager
        this.props = props
        this.surface = new Graphics()
    }

    setup = () => {
        this.surface.clear()
        this.modal = this.guiManager.createModal({
            padding: 0,
            width: MODAL_WIDTH,
            height: MODAL_HEIGHT,
            content: this.surface,
            center: true,
            closeCallback: () => {
                this.fields.forEach(field => field.destroy())
                this.fields = []
            },
        })

        this.renderForm()
    }

    renderForm = () => {
        const fieldWidth = MODAL_WIDTH - 40

        const title: DynamicText = new DynamicText("Feedback - what do you think of this game?")
        ;(title.x = 20), (title.y = 10), this.surface.addChild(title)

        const feedback = new TextInput({
            input: {
                fontFamily: "Arial",
                fontSize: "10pt",
                lineHeight: "13px",
                padding: "10px 12px",
                color: "#26272E",
                multiline: true,
                width: `${fieldWidth}px`,
                height: `${fieldWidth / 2}px`,
            },
            box: {
                default: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xcbcee0, width: 2 } },
                focused: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xabafc6, width: 2 } },
                disabled: { fill: 0xdbdbdb, rounded: 10 },
            },
        })

        feedback.on("focus", (e: KeyboardEvent) => {
            this.guiManager.logic.playerController.enabledKeyboardAction(false)
        })
        feedback.on("blur", (e: KeyboardEvent) => {
            this.guiManager.logic.playerController.enabledKeyboardAction(true)
        })
        feedback.x = 20
        feedback.y = 40
        feedback.placeHolder = "What do you think of this game?"
        feedback.placeholderColor = 0x000000
        feedback.on("input", text => {
            this.feedbackText = text
        })

        this.fields.push(feedback)
        this.surface.addChild(feedback)
        feedback.focus()

        const emailField = new TextInput({
            input: {
                fontFamily: "Arial",
                fontSize: "10pt",
                lineHeight: "13px",
                padding: "10px 12px",
                color: "#26272E",
                multiline: false,
                width: `${fieldWidth}px`,
            },
            box: {
                default: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xcbcee0, width: 2 } },
                focused: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xabafc6, width: 2 } },
                disabled: { fill: 0xdbdbdb, rounded: 10 },
            },
        })

        emailField.x = 20
        emailField.y = feedback.y + feedback.height + 20
        emailField.placeholderColor = 0x000000
        emailField.placeholder = "Your email address (optional)"
        emailField.on("input", text => {
            this.emailText = text
        })
        emailField.on("focus", (e: KeyboardEvent) => {
            this.guiManager.logic.playerController.enabledKeyboardAction(false)
        })
        emailField.on("blur", (e: KeyboardEvent) => {
            this.guiManager.logic.playerController.enabledKeyboardAction(true)
        })

        this.surface.addChild(emailField)

        const submitButton: DynamicText = new DynamicText("Submit", {
            type: "button|dynamic",
            onClick: () => {
                this.props?.onSubmit({
                    feedback: this.feedbackText,
                    email: this.emailText,
                })
                this.modal.close()
            },
        })
        ;(submitButton.x = 20), (submitButton.y = MODAL_HEIGHT - submitButton.height - 10)

        this.fields.push(emailField)
        this.surface.addChild(submitButton)
    }
}
