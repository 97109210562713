import { Graphics } from "pixi.js"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { HoverText } from "./dynamic_dialog"
import { DynamicText } from "./dynamic_text"
import { DyanmicTextContainer } from "./dynamic_text_container"

export class StickyBanner extends Graphics {
    guiManager: GuiManager
    hoverText: HoverText

    constructor(guiManager: GuiManager) {
        super()
        this.guiManager = guiManager
        this.hoverText = new HoverText()
    }

    setup = (text?: string, sprite?: string, hoverText?: string[]) => {
        this.removeChildren()

        const container = new DyanmicTextContainer()
        let s: BasicSprite
        if (sprite) {
            const s = new BasicSprite({ name: sprite, color: 0xffffff, scale: 1.0 })
            container.append2(s, {
                topMargin: s.height / 2,
            })
        }
        if (text) {
            container.append2(new DynamicText(text), {
                sameLine: true,
                topMargin: -10,
                leftMargin: s ? -10 : undefined,
            })
        }
        this.addChild(container)

        if (hoverText) {
            this.addChild(this.hoverText)
            container.interactive = true
            container.on("mouseover", () => {
                this.hoverText.x = container.x - 15
                this.hoverText.y = container.y + 20
                this.hoverText.visible = true
                this.hoverText.update(hoverText)
            })
        }
    }
}
