import * as fs from "fs"
import { Bounds, Coordinate, Dimensions, TileMapBase, TileSize } from "game-common/models"
import { randomIntBetweenRng, randomOneOf } from "game-common/util"

import { AutoTiles, TileSetSpec, TileSpec } from "game-common/room/base_map_builder"
import { CraftableMeta } from "../mechanics/item_mechanics"
import { ItemTypes } from "game-common/item/item_type"
import { FragmentPointer } from "./house/feature/house"
import { ItemCharacteristics, ItemTypeMeta } from "../item/item"

export type FragmentRenderStrategy = "default" | "sprite"
export type FragmentMaps = Record<string, TileMapBase<TileSpec>>

export interface FragmentPoint {
    id: string
    location: Coordinate
    context?: any
}

export interface ItemTypeMetaDescriptor {
    itemTypeMeta: ItemTypeMeta
    characteristics?: ItemCharacteristics
}

export interface FragmentMeta {
    id: string
    category: string
    subcategory: string
    fragmentMaps: FragmentMaps
    tag: string
    renderStrategy: FragmentRenderStrategy
    dimensions: Dimensions
    location?: Coordinate
    points: FragmentPoint[]
    fragmentPointers: FragmentPoint[]
    mapSpriteCaps: FragmentPoint[]
    context?: any
    craftableMeta?: CraftableMeta
    itemTypeMetaDescriptor?: ItemTypeMetaDescriptor
}

export interface FeatureCatalog {
    id: string
    fragments: FragmentMeta[]
}

export type FeatureCategory =
    | "mountain"
    | "building"
    | "field"
    | "platform"
    | "house"
    | "tent"
    | "fragment"
    | "decoration"
    | "compound"
    | "tunnel"
    | "cave"
    | "object"
    | "dirt"
    | "door"

export type FeatureSubCategory = "large" | "medium" | "small" | "tall"

export class FeatureCatalogs {
    catalogs: Record<string, FeatureCatalog> = {}
    fragmentByItemType: Partial<Record<ItemTypes, FragmentPointer>> = {}
    itemTypeMetaDescriptors: ItemTypeMetaDescriptor[] = []

    private static globalFeatureCatalog = null
    static instance = () => {
        if (!this.globalFeatureCatalog) {
            this.globalFeatureCatalog = new FeatureCatalogs()
        }

        return this.globalFeatureCatalog
    }

    constructor() {
        const catalogs = ["ruins", "settlement", "geo", "caves", "item"]
        catalogs.forEach(catalog => {
            const mapPath = this.toMapPath(catalog)
            const catalogIdParts = mapPath.split("/")
            const catalogId = catalogIdParts[catalogIdParts.length - 1].split(".")[0]
            const content = fs.readFileSync(mapPath).toString()
            const mapObj = JSON.parse(content)
            this.analyze(catalogId, mapObj)
        })
    }

    toMapPath = (mapName: string) => {
        const rootDir = `${process.cwd()}/../..`
        return `${rootDir}/packages/server/assets/templates/${mapName}.tmj`
    }

    featureByTag = (catalog: string, tag: string) => this.randomFeature(catalog, null, null, tag)
    featureByFragmentPointer = (fragmentPointer: FragmentPointer) =>
        this.featureByTag(fragmentPointer.catalog, fragmentPointer.tag)

    randomFeature = (
        catalog: string,
        category: FeatureCategory,
        subCategory?: FeatureSubCategory,
        tag?: string,
    ): FragmentMeta => {
        const catalogMeta = this.catalogs[catalog]
        if (!catalogMeta) {
            return
        }

        const matches = catalogMeta.fragments.filter(
            n =>
                (!category || n.category === category) &&
                (!subCategory || n.subcategory === subCategory) &&
                (!tag || n.tag === tag),
        )

        if (matches?.length < 1) {
            return
        }
        return randomOneOf(matches, {
            randomIntBetween: randomIntBetweenRng,
        })
    }

    analyze = (catalogId: string, mapObj: any): FeatureCatalog => {
        const mapLayers: Record<string, TileMapBase<TileSpec>> = {}
        const { width, height, layers, tilesets } = mapObj

        //
        // parse tileset specs
        //
        const tilesetSpecs = tilesets
            .map((meta): TileSetSpec => {
                const tilesetParts = meta.source.split("/")
                const tilesetId = tilesetParts[tilesetParts.length - 1].split(".")[0]

                return {
                    tilesetId,
                    firstgid: meta.firstgid,
                }
            })
            .sort((a, b) => b.firstgid - a.firstgid)

        const resolveTileSet = (tileId: string): any => {
            const id = Number(tileId)
            for (let i = 0; i < tilesetSpecs.length; i++) {
                const tileset = tilesetSpecs[i]
                if (id >= tileset.firstgid) {
                    return tileset
                }
            }
        }

        //
        // parse tilemaps and fragments
        //
        let fragments = []
        let featurePoints = []
        let mapSpriteCaps = []

        layers.forEach(mapLayer => {
            const matrix: TileMapBase<TileSpec> = blankTileMap(width, height)
            let hasData = false
            mapLayer.data?.forEach((next, index) => {
                const x = index % width
                const y = Math.floor(index / width)
                const tileset = resolveTileSet(`${next}`)
                const rawId = tileset ? Math.max(0, next - tileset.firstgid) : 0
                if (rawId) {
                    hasData = true
                    const autotileMapTileset = AutoTiles[tileset.tilesetId]
                    const autotileMap = autotileMapTileset ? autotileMapTileset[rawId] : undefined
                    matrix[x][y] = {
                        tilesetId: tileset.tilesetId,
                        tilePos: rawId,
                        autotileMap: autotileMap
                            ? {
                                  tilesetId: tileset.tilesetId,
                                  tiles: autotileMap,
                              }
                            : undefined,
                    }
                }
            })

            if (hasData && mapLayer.type === "tilelayer") {
                mapLayers[mapLayer.name] = matrix
            }

            if (mapLayer.type === "objectgroup") {
                fragments = mapLayer.objects.filter(next => next.class === "FRAGMENT")
                featurePoints = mapLayer.objects.filter(next => next.class === "FRAGMENT_POINT")
                mapSpriteCaps = mapLayer.objects.filter(next => next.class === "mapSpriteCap")
            }
        })

        const featureFragments = fragments
            .filter(fragment => !fragment.point)
            .map(fragment => {
                const fragmentBounds = new Bounds(
                    fragment.x,
                    fragment.y,
                    fragment.x + fragment.width,
                    fragment.y + fragment.height,
                )
                const fragX = Math.floor(fragment.x / TileSize)
                const fragY = Math.floor(fragment.y / TileSize)
                const fragWidth = Math.floor(fragment.width / TileSize)
                const fragHeight = Math.floor(fragment.height / TileSize)

                const fragmentMaps: FragmentMaps = {}
                Object.keys(mapLayers).forEach(layer => {
                    const fragmentMap = blankTileMap<TileSpec>(fragWidth, fragHeight)
                    fragmentMaps[layer] = fragmentMap
                })

                // extract map slices
                let fx = 0
                let fy = 0
                let good = new Set()
                for (let x = fragX; x < fragX + fragWidth; x++) {
                    fy = 0
                    for (let y = fragY; y < fragY + fragHeight; y++) {
                        Object.keys(mapLayers).forEach(layer => {
                            const value = mapLayers[layer][x][y]
                            fragmentMaps[layer][fx][fy] = value
                            if (value) {
                                good.add(layer)
                            }
                        })
                        fy++
                    }
                    fx++
                }
                Object.keys(fragmentMaps)
                    .filter(k => !good.has(k))
                    .forEach(layer => {
                        delete fragmentMaps[layer]
                    })

                const mapSpriteCapsPoints: FragmentPoint[] = mapSpriteCaps
                    .filter(p => {
                        return fragmentBounds.includes({
                            x: p.x,
                            y: p.y,
                        })
                    })
                    .map(p => {
                        const contextRaw = p.properties?.find(property => property.name === "context")?.value
                        const context = contextRaw ? JSON.parse(contextRaw) : undefined
                        return {
                            id: p.name,
                            location: {
                                x: p.x - fragment.x,
                                y: p.y - fragment.y,
                            },
                            context,
                        }
                    })

                const points: FragmentPoint[] = featurePoints
                    .filter(p => {
                        const contextRaw = p.properties?.find(property => property.name === "context")?.value
                        const context = contextRaw ? JSON.parse(contextRaw) : undefined
                        return (
                            !context?.fragmentPointer &&
                            fragmentBounds.includes({
                                x: p.x,
                                y: p.y,
                            })
                        )
                    })
                    .map((p: any): FragmentPoint => {
                        const contextRaw = p.properties?.find(property => property.name === "context")?.value
                        return {
                            id: p.name,
                            location: {
                                x: p.x - fragment.x,
                                y: p.y - fragment.y,
                            },
                            context: contextRaw,
                        }
                    })

                const fragmentPointers = featurePoints
                    .filter(p => {
                        const contextRaw = p.properties?.find(property => property.name === "context")?.value
                        const context = contextRaw ? JSON.parse(contextRaw) : undefined
                        return (
                            !!context?.fragmentPointer &&
                            fragmentBounds.includes({
                                x: p.x,
                                y: p.y,
                            })
                        )
                    })
                    .map((p: any): FragmentPoint => {
                        const contextRaw = p.properties?.find(property => property.name === "context")?.value
                        return {
                            id: p.name,
                            location: {
                                x: p.x - fragment.x,
                                y: p.y - fragment.y,
                            },
                            context: contextRaw,
                        }
                    })

                const fragmentContextRaw = fragment.properties.find(p => p.name === "context")?.value
                const craftableMetaRaw = fragment.properties.find(p => p.name === "craftableMeta")?.value
                const itemTypeMetaDescriptorRaw = fragment.properties.find(p => p.name === "itemTypeMeta")?.value
                const craftableMeta: CraftableMeta = craftableMetaRaw ? JSON.parse(craftableMetaRaw) : undefined
                const itemTypeMetaDescriptor: ItemTypeMetaDescriptor = itemTypeMetaDescriptorRaw
                    ? JSON.parse(itemTypeMetaDescriptorRaw)
                    : undefined

                const fragmentMeta: FragmentMeta = {
                    id: fragment.name,
                    fragmentMaps,
                    category: fragment.properties.find(p => p.name === "category").value,
                    subcategory: fragment.properties.find(p => p.name === "subcategory")?.value,
                    renderStrategy: fragment.properties.find(p => p.name === "renderStrategy")?.value || "default",
                    tag: fragment.properties.find(p => p.name === "tag")?.value || undefined,
                    dimensions: { w: fragWidth, h: fragHeight },
                    points,
                    fragmentPointers,
                    mapSpriteCaps: mapSpriteCapsPoints,
                    context: fragmentContextRaw ? JSON.parse(fragmentContextRaw) : undefined,
                    craftableMeta,
                    itemTypeMetaDescriptor,
                }

                if (craftableMeta) {
                    this.fragmentByItemType[craftableMeta.itemType] = {
                        catalog: catalogId,
                        tag: fragmentMeta.tag,
                    }
                }

                if (itemTypeMetaDescriptor) {
                    this.itemTypeMetaDescriptors.push(itemTypeMetaDescriptor)
                }

                return fragmentMeta
            })

        const featureCatalog: FeatureCatalog = {
            id: catalogId,
            fragments: featureFragments,
        }

        this.catalogs[catalogId] = featureCatalog
        return featureCatalog
    }
}

const blankTileMap = <T>(width: number, height: number): TileMapBase<T> => {
    const matrix: TileMapBase<T> = []
    for (let x = 0; x < width; x++) {
        const column = []
        matrix.push(column)
        for (let y = 0; y < height; y++) {
            column.push()
        }
    }

    return matrix
}
