import { ScreenDimensions } from "game-common/models"
import { Application, BitmapFont, Loader, SCALE_MODES, settings } from "pixi.js"
import { Audio } from "./audio"
import { Client } from "./client"
import { ClientGameLogic } from "./client_game_logic"
import { SCALER } from "./client_util"
import { lpcSpriteSheets } from "./lpc-spritesheets"
import { Keyboard } from "./rendering/impl/pixijs/input/keyboard"
import { Mouse } from "./rendering/impl/pixijs/input/mouse"
import { PixiJsRenderingFactory } from "./rendering/impl/pixijs/pixijs_rendering_factory"
import { spriteSheetLoader } from "./rendering/impl/pixijs/sprites/spritesheet_loader"

settings.SCALE_MODE = SCALE_MODES.NEAREST
settings.RENDER_OPTIONS.antialias = false

const foo = setInterval(() => {
    if (window["__GO__"]) {
        clearInterval(foo)
        const stopLoading = window["stopLoading"]
        const app = new Application({
            view: document.getElementById("pixi-canvas") as HTMLCanvasElement,
            resolution: window.devicePixelRatio || 2,
            autoDensity: true,
            backgroundColor: 0x000000,
        })

        Keyboard.initialize()
        Mouse.initialize(app)

        const onLoadComplete = () => {
            if (stopLoading) {
                stopLoading()
            }
            const ratio = ScreenDimensions.w / ScreenDimensions.h

            function resize() {
                const width = window.innerWidth
                const height = window.innerHeight
                if (width / height >= ratio) {
                    const w = height * ratio
                    const h = height
                    app.renderer.view.style.width = w + "px"
                    app.renderer.view.style.height = h + "px"
                } else {
                    const w = width
                    const h = width / ratio
                    app.renderer.view.style.width = w + "px"
                    app.renderer.view.style.height = h + "px"
                }
                app.stage.scale.y = SCALER
            }

            resize()

            window.onresize = resize

            const factory: PixiJsRenderingFactory = new PixiJsRenderingFactory(app)
            const clientGameLogic = new ClientGameLogic(factory)
            const client: Client = new Client(clientGameLogic)

            const urlSearchParams = new URLSearchParams(window.location.search)
            const params = Object.fromEntries(urlSearchParams.entries())
            if (window["IS_ITCH"]) {
                client.connect("", "", true)
            } else {
                const { accessToken, guest } = params

                if (accessToken) {
                    client.connect("", accessToken)
                } else {
                    const isGuest = guest === "true"
                    const cachedPlayerName = isGuest ? "" : localStorage.getItem("_playerName_" || "")
                    client.connect(cachedPlayerName, "", isGuest)
                }
            }

            app.ticker.add(() => {
                client.update()
            })
        }

        loadAssets()

        const aggressiveLoad = false
        Loader.shared.load(
            aggressiveLoad
                ? () =>
                      spriteSheetLoader.bulkLoad(
                          lpcSpriteSheets.map(sheetId => `assets/${sheetId}`),
                          onLoadComplete,
                      )
                : onLoadComplete,
        )
    }
}, 1000)

const loadAssets = () => {
    //
    // fonts
    //
    const chars = {
        chars: [["a", "z"], ["0", "9"], ["A", "Z"], " .!?|,\":<>~!@#$%^&*()_+-=/\\'ë"],
    }

    BitmapFont.from(
        "LocationFont",
        {
            fill: "#00FF00",
            fontSize: 25,
        },
        chars,
    )

    BitmapFont.from(
        "BannerFont",
        {
            fill: "#FFFFFF",
            fontSize: 20,
        },
        chars,
    )

    BitmapFont.from(
        "ImportantFont",
        {
            fill: "#FFFFFF",
            fontSize: 30,
        },
        chars,
    )

    BitmapFont.from(
        "LogoFont",
        {
            fill: "#FFFFFF",
            fontSize: 40,
        },
        chars,
    )

    BitmapFont.from(
        "ScoreFont",
        {
            fill: "#FFFFFF",
            fontSize: 15,
            fontFamily: "Serif",
        },
        chars,
    )

    BitmapFont.from(
        "NameFont",
        {
            fill: "#FFFFFF",
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "ChatFont",
        {
            fill: "#00EE00",
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "RedChatFont",
        {
            fill: "#FFA500",
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "SmallFont",
        {
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "SignFont",
        {
            fill: "#000000",
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "ChatBubbleFont",
        {
            fill: "#FFFFFF",
            fontSize: 15,
        },
        chars,
    )

    BitmapFont.from(
        "BlinkFont",
        {
            fill: "#00FF00",
            fontSize: 10,
            fontWeight: "bold",
        },
        chars,
    )

    BitmapFont.from(
        "ChatHistoryFont",
        {
            fill: "#00FF00",
            fontSize: 10,
        },
        chars,
    )

    BitmapFont.from(
        "LeaderboardFont",
        {
            fill: "#00FF00",
            fontSize: 15,
        },
        chars,
    )

    BitmapFont.from(
        "LeaderboardLeaderFont",
        {
            fill: "#FFFFFF",
            fontSize: 15,
        },
        chars,
    )

    BitmapFont.from(
        "HpFont",
        {
            fill: "#FFFFFF",
            fontSize: 12,
            fontWeight: "bold",
        },
        chars,
    )

    BitmapFont.from(
        "IdFont",
        {
            fill: "#00FF00",
            fontSize: 12,
            fontWeight: "bold",
        },
        chars,
    )

    BitmapFont.from(
        "Normal-Green-Bold",
        {
            fill: "#00FF00",
            fontSize: 15,
            fontWeight: "bold",
        },
        chars,
    )

    BitmapFont.from(
        "TinyFont",
        {
            fill: "#FFFFFF",
            fontSize: 9,
        },
        chars,
    )

    //
    // audio
    //
    Audio.loadAssets()

    Loader.shared
        .add("hit1", "assets/strike1.ogg")
        .add("hit2", "assets/strike2.ogg")
        .add("woodhit", "assets/woodhit.ogg")
        .add("bowshot", "assets/shoot.ogg")
        .add("swordswing", "assets/swordswing.ogg")
        .add("explosion", "assets/explosion.mp3")
        .add("emp", "assets/emp.mp3")
        .add("health", "assets/health.mp3")
        .add("mana", "assets/mana.mp3")
        .add("door_open", "assets/door_open.ogg")
        .add("door_close", "assets/door_close.ogg")
        .add("clocktick", "assets/clocktick.mp3")
        .add("powerup", "assets/powerup.mp3")
        .add("map_location", "assets/map_location.ogg")
        .add("click", "assets/click.mp3")
        .add("coins", "assets/coin3.ogg")
        .add("cloth", "assets/cloth.ogg")

    //
    // image
    //
    Loader.shared
        .add("furniture-blonde-wood", "assets/furniture-blonde-wood.json")
        .add("fence_medieval", "assets/fence_medieval.json")
        .add("container", "assets/container.json")
        .add("walls", "assets/walls.json")
        .add("walls2", "assets/walls2.json")
        .add("floors", "assets/floors.json")
        .add("cottage", "assets/cottage.json")
        .add("roofs", "assets/roofs.json")
        .add("ore", "assets/ore.json")
        .add("decorations-medieval", "assets/decorations-medieval.json")
        .add("jungle-ruins", "assets/jungle-ruins.json")
        .add("mountains-v6", "assets/mountains-v6.json")
        .add("lpc-flowers-plants-fungi-wood", "assets/lpc-flowers-plants-fungi-wood.json")
        .add("lpc-terrain", "assets/lpc-terrain.json")
        .add("beach-desert", "assets/beach-desert.json")
        .add("map-atlas", "assets/map-atlas.json")
        .add("plants", "assets/plants.json")
        .add("build_atlas", "assets/build_atlas.json")
        .add("humanoid", "assets/humanoid.json")
        .add("outer", "assets/joystick.png")
        .add("inner", "assets/joystick-handle.png")
        .add("reddit", "assets/reddit.png")
        .add("discord", "assets/discord.png")
        .add("intro_scene", "assets/intro/scene.png")
}
