export interface Vector2D {
    x: number
    y: number
}

export interface VectorLine {
    a: Vector2D
    b: Vector2D
}

const getIntersection = (ray: VectorLine, segment: VectorLine) => {
    // RAY in parametric: Point + Delta*T1
    var r_px = ray.a.x
    var r_py = ray.a.y
    var r_dx = ray.b.x - ray.a.x
    var r_dy = ray.b.y - ray.a.y

    // SEGMENT in parametric: Point + Delta*T2
    var s_px = segment.a.x
    var s_py = segment.a.y
    var s_dx = segment.b.x - segment.a.x
    var s_dy = segment.b.y - segment.a.y

    // Are they parallel? If so, no intersect
    var r_mag = Math.sqrt(r_dx * r_dx + r_dy * r_dy)
    var s_mag = Math.sqrt(s_dx * s_dx + s_dy * s_dy)
    if (r_dx / r_mag == s_dx / s_mag && r_dy / r_mag == s_dy / s_mag) {
        // Unit vectors are the same.
        return null
    }

    // SOLVE FOR T1 & T2
    // r_px+r_dx*T1 = s_px+s_dx*T2 && r_py+r_dy*T1 = s_py+s_dy*T2
    // ==> T1 = (s_px+s_dx*T2-r_px)/r_dx = (s_py+s_dy*T2-r_py)/r_dy
    // ==> s_px*r_dy + s_dx*T2*r_dy - r_px*r_dy = s_py*r_dx + s_dy*T2*r_dx - r_py*r_dx
    // ==> T2 = (r_dx*(s_py-r_py) + r_dy*(r_px-s_px))/(s_dx*r_dy - s_dy*r_dx)
    var T2 = (r_dx * (s_py - r_py) + r_dy * (r_px - s_px)) / (s_dx * r_dy - s_dy * r_dx)
    var T1 = (s_px + s_dx * T2 - r_px) / r_dx

    // Must be within parametic whatevers for RAY/SEGMENT
    if (T1 < 0) return null
    if (T2 < 0 || T2 > 1) return null

    // Return the POINT OF INTERSECTION
    return {
        x: r_px + r_dx * T1,
        y: r_py + r_dy * T1,
        param: T1,
    }
}

export const getSightPolygon = (segments: Array<VectorLine>, sightX: number, sightY: number) => {
    // Track all unique points
    const unqiuePointsMap: Record<string, boolean> = {}
    // RAYS IN ALL DIRECTIONS
    let intersects = []

    const handleAngle = (angle: number) => {
        // Calculate dx & dy from angle
        const dx = Math.cos(angle)
        const dy = Math.sin(angle)

        // Ray from center of screen to mouse
        const ray: VectorLine = {
            a: { x: sightX, y: sightY },
            b: { x: sightX + dx, y: sightY + dy },
        }

        // Find CLOSEST intersection
        let closestIntersect = null
        for (let i = 0; i < segments.length; i++) {
            const intersect = getIntersection(ray, segments[i])
            if (!intersect) continue
            if (!closestIntersect || intersect.param < closestIntersect.param) {
                closestIntersect = intersect
            }
        }

        // Intersect angle
        if (!closestIntersect) return
        closestIntersect.angle = angle

        // Add to list of intersects
        intersects.push(closestIntersect)
    }

    const handleUniqueAngle = (vector: Vector2D) => {
        const key = `${vector.x}-${vector.y}`
        if (unqiuePointsMap[key]) {
            return
        }

        unqiuePointsMap[key] = true
        const angle = Math.atan2(vector.y - sightY, vector.x - sightX)
        handleAngle(angle - 0.00001)
        handleAngle(angle)
        handleAngle(angle + 0.00001)
    }

    segments.forEach(seg => {
        handleUniqueAngle(seg.a)
        handleUniqueAngle(seg.b)
    })

    // Sort intersects by angle
    intersects = intersects.sort((a, b) => {
        return b.angle - a.angle
    })

    // Polygon is intersects, in order of angle
    return intersects
}

export const pruneSegments = (
    segments: Array<VectorLine>,
    sightX: number,
    sightY: number,
    size = 100,
    skip = 0,
): Array<VectorLine> =>
    segments.filter(
        (s, i) =>
            i <= skip ||
            (Math.abs(sightX - s.a.x) < size && Math.abs(sightY - s.a.y) < size) ||
            (Math.abs(sightX - s.b.x) < size && Math.abs(sightY - s.b.y) < size),
    )
