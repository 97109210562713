import { ActionType, DefaultAttackActionType, WeaponAction } from "game-common/character/character"
import { locateInventoryActiveItem, locateInventoryItemObjectByItemType } from "game-common/item/item"
import { ItemTypes } from "game-common/item/item_type"
import { LpcSheet, toDescriptor } from "game-common/lpc/lpc"
import { forgeLpcSpritesheetDescriptors } from "game-common/lpc/lpc-forge"
import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { Mechanics } from "game-common/mechanics/mechanics"
import { Entity, EntityId, TileSize } from "game-common/models"
import { Container, Graphics } from "pixi.js"
import { EntityInteractionCallback } from "../../../../client_game_logic"

import { WorldOverlayItem } from "../world_overlay_surface"
import { AvatarHud } from "./avatar_hud"
import { BaseRenderable } from "./base_renderable"
import { LpcSprite } from "./lpc_sprite"

export class LpcLayered extends BaseRenderable {
    private parts: BaseRenderable[] = []
    private hud: AvatarHud
    private drawableSurface: BaseRenderable
    private clickArea: BaseRenderable
    private worldOverlayItem: WorldOverlayItem
    private halo: boolean
    private haloObject: Graphics
    private entityId: EntityId
    blinkingInterval: any

    constructor(entityId: EntityId, interactionCallback: EntityInteractionCallback) {
        super()

        this.entityId = entityId
        this.clickArea = new BaseRenderable()

        this.hud = new AvatarHud(
            0xffffff,
            {
                renderActions: {},
                alwaysShowLabel: false,
            },
            this.clickArea,
            (_: EntityId, interaction: InteractionType) => interactionCallback(this.entityId, interaction),
        )

        this.drawableSurface = new BaseRenderable()
    }

    getClickSurface = (): BaseRenderable => {
        return this.clickArea
    }

    private rebuildLayers = (entity: Entity) => {
        const dimensions = Mechanics.entity.body.dimensions(entity)

        this.parts.forEach(part => {
            part.remove()
        })

        this.parts = []

        const race = entity.appearance?.race
        const sex = entity.appearance?.sex
        const hair = entity.appearance?.hair
        const nose = entity.appearance?.nose
        const eyes = entity.appearance?.eyes

        const weapon = locateInventoryItemObjectByItemType(
            entity.inventory,
            entity?.weapon?.weaponType as ItemTypes,
        )?.itemTypeMeta
        const weaponAction = WeaponAction[entity?.weapon?.weaponType || "none"] || DefaultAttackActionType[race]
        const armor = locateInventoryActiveItem(entity.inventory, "armor")

        const descriptors = forgeLpcSpritesheetDescriptors({
            body: {
                race,
                sex,
                color: entity.appearance.bodyColor,
            },
            hair: hair
                ? {
                      component: hair,
                      color: entity.appearance.hairColor,
                  }
                : undefined,
            nose: nose
                ? {
                      component: nose,
                      color: entity.appearance.noseColor,
                  }
                : undefined,
            eyes: eyes
                ? {
                      component: eyes,
                      color: entity.appearance.eyesColor,
                  }
                : undefined,
            ///////
            weapon: toDescriptor(weapon),
            pants: toDescriptor(locateInventoryActiveItem(entity.inventory, "pants")),
            shirt: toDescriptor(locateInventoryActiveItem(entity.inventory, "shirt")),
            armor: toDescriptor(armor),
            shoes: toDescriptor(locateInventoryActiveItem(entity.inventory, "shoes")),
            shield: toDescriptor(locateInventoryActiveItem(entity.inventory, "shield")),
            cape: toDescriptor(locateInventoryActiveItem(entity.inventory, "cape")),
            bauldron: toDescriptor(locateInventoryActiveItem(entity.inventory, "bauldron")),
        })

        const all = []

        descriptors.body?.forEach(next => all.push(next))
        if (Mechanics.entity.body.isHumanoid(race)) {
            descriptors.hair?.forEach(next => all.push(next))
            descriptors.nose?.forEach(next => all.push(next))
            descriptors.eyes?.forEach(next => all.push(next))

            descriptors.weapon?.forEach(next => all.push(next))
            descriptors.pants?.forEach(next => all.push(next))
            descriptors.shirt?.forEach(next => all.push(next))
            descriptors.armor?.forEach(next => all.push(next))
            descriptors.shoes?.forEach(next => all.push(next))
            descriptors.shield?.forEach(next => all.push(next))
            descriptors.cape?.forEach(next => all.push(next))
            descriptors.bauldron?.forEach(next => all.push(next))
        }

        all.sort((a: LpcSheet, b: LpcSheet) => a.zIndex - b.zIndex)
            .map(sheet => this.buildPart(weaponAction, entity.movement.angle, sheet))
            .forEach(sprite => {
                if (sprite.halo) {
                    this.haloObject = sprite.halo
                }
                this.addChild(sprite)
                this.parts.push(sprite)
            })

        if (this.clickArea.parent) {
            this.clickArea.parent.removeChild(this.parentArea)
        }
        this.clickArea.clear()
        this.clickArea.beginFill(0xff0000, 0.001)
        this.clickArea.drawRect(0, 0, dimensions.w, dimensions.h)
        this.clickArea.x = -dimensions.w / 2
        this.clickArea.y = -dimensions.h / 2 + TileSize * 0.15
        this.clickArea.endFill()
        this.addChild(this.clickArea)
    }

    private buildPart = (attackAction: string, angle: number, dollComponent?: LpcSheet): LpcSprite => {
        if (!dollComponent) {
            return
        }
        return new LpcSprite({
            sheetId: `lpc/spritesheets/${dollComponent.entityId}.json`,
            attackAction: attackAction as ActionType,
            angle,
            hasHalo: this.halo && dollComponent.entityId.startsWith("body"),
        })
    }

    doMovement(entity?: Entity) {
        this.parts.forEach(part => part.doMovement(entity))
        this.drawableSurface.doMovement(entity)
    }

    updateHaloColor(hp: number, hpMax) {
        const hpPct = hp / hpMax
        if (hpPct < 0.7) {
            if (hpPct < 0.4) {
                this.haloObject.tint = 0xff0000
            } else {
                this.haloObject.tint = 0xffa500
            }
        } else {
            this.haloObject.tint = 0x00ff00
        }
    }

    update(hp: number, hpMax: number) {
        this.hud.update(hp, hpMax)
        if (this.haloObject) {
            this.updateHaloColor(hp, hpMax)
        }
    }

    updateName(name: string) {
        this.hud.updateName(name)
    }

    recolor(color: number) {
        this.hud.recolor(color)
    }

    updateBlinking(blinking: boolean): void {
        if (!blinking) {
            this.visible = true
            if (this.blinkingInterval) {
                clearInterval(this.blinkingInterval)
                this.blinkingInterval = null
                this.alpha = 1.0
            }
        } else {
            if (this.blinkingInterval) {
                return
            }

            let dir = -1
            this.blinkingInterval = setInterval(() => {
                this.alpha -= 0.1 * dir
                if (this.alpha < 0.4 || this.alpha > 1.0) {
                    dir = -1 * dir
                }
            }, 25)
        }
    }

    addTag() {
        this.hud.addTag()
    }

    removeTag() {
        this.hud.removeTag()
    }

    updateLevel(level: number) {
        this.hud.updateLevel(level)
    }

    doAttack(entity: Entity) {
        this.parts.forEach(part => part.doAttack(entity))
    }

    doDamaged(entity: Entity) {
        this.parts.forEach(part => part.doDamaged(entity))
    }

    spoke(message: string) {
        this.hud.spoke(message)
    }

    getDrawableSurface(): Container {
        return this.drawableSurface.getDrawableSurface()
    }

    fragment = (entity: Entity) => {
        this.parts.forEach(part => part.fragment(entity))
        this.drawableSurface.fragment(entity)
    }

    updatePaperdoll(entity: Entity): void {
        if (!entity) {
            return
        }
        const { appearance } = entity
        if (!appearance) {
            return
        }

        this.rebuildLayers(entity)
    }

    updateHalo(entity: Entity, enabled: boolean) {
        this.halo = enabled
        this.updatePaperdoll(entity)

        if (this.haloObject) {
            this.updateHaloColor(entity.hp, entity.maxHp)
        }
    }

    updateSpeed(factor: number) {
        //
    }

    onMouseOver = (show: boolean) => {
        this.hud.onMouseOver(show)
    }

    setWorldOverlayItem = (item: WorldOverlayItem) => {
        this.worldOverlayItem = item
        this.worldOverlayItem.addChild(this.hud)
    }

    updateInteractions = (interactions: InteractionType[]) => {
        this.hud.updateInteractions(interactions)
    }

    updatePointerIcon(show: boolean, angle?: number) {
        this.hud.updatePointerIcon(show, angle)
    }

    updateSigil(sigil?: string) {
        this.hud.updateSigil(sigil)
    }
}
