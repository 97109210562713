import { Callback } from "game-common/models"
import { Graphics } from "pixi.js"

interface FaderProps {
    direction?: "in" | "out"
    releaseCallback?: Callback
    effectCallback?: (alpha: number) => void
    minAlpha?: number
    maxAlpha?: number
    fadeInterval?: number
    fadeAlphaDecrement?: number
}

interface AlphaAware {
    alpha: number
}

export class FaderGeneric {
    private interval: any
    private minAlpha: number
    private releaseCallback?: Callback
    private effectCallback?: (alpha: number) => void
    private fadeInterval: number
    private faderStep = 0.01
    private direction: "in" | "out" = "out"
    private active: boolean
    private alpha: number

    constructor(subject: AlphaAware, props?: FaderProps) {
        this.releaseCallback = props?.releaseCallback
        this.effectCallback = props?.effectCallback
        this.minAlpha = props?.minAlpha !== undefined ? props.minAlpha : this.direction === "out" ? 0.0 : 1.0
        this.fadeInterval = props?.fadeInterval || 2
        this.faderStep = props?.fadeAlphaDecrement || 0.01
        this.direction = props?.direction || "out"
        if (this.direction === "out") {
            this.alpha = props.maxAlpha || 1.0
        } else {
            this.alpha = 0.0
        }
    }

    isActive = () => this.active

    update = () => {
        this.active = true
        if (this.interval) {
            return
        }
        this.interval = setInterval(() => {
            if (this.direction === "out") {
                this.alpha -= this.faderStep
                this.effectCallback(this.alpha)
                if (this.alpha <= this.minAlpha) {
                    this.releaseCallback && this.releaseCallback()
                    clearInterval(this.interval)
                    this.interval = undefined
                    return
                }
            } else if (this.direction === "in") {
                this.alpha += this.faderStep
                this.effectCallback(this.alpha)
                if (this.alpha >= this.minAlpha) {
                    this.releaseCallback && this.releaseCallback()
                    clearInterval(this.interval)
                    this.interval = undefined
                    return
                }
            } else {
                // not supported, clear it right away
                clearInterval(this.interval)
                this.interval = undefined
            }
        }, this.fadeInterval)
    }

    stop = (suppressCallback?: boolean) => {
        if (this.interval) {
            clearInterval(this.interval)
            this.interval = undefined
        }
        if (!suppressCallback && this.releaseCallback) {
            this.releaseCallback()
        }
        this.active = false
    }
}
