import { EntityTile, MapDelta } from "./map/map"
import {
    TileMapDirectory,
    Tileset,
    TileType,
    BiomeColorMap,
    LocationIcon,
    NumberedTileMeta,
    TileMeta,
    createBlankTileMeta,
    TileMap,
    FragmentSpriteMeta,
    MapIntersectionType,
    WorldMetaDimensions,
    Direction,
    TileLayerMapping,
    IndexedMap,
    LayerDescriptor,
    MapSpriteCap,
    tileMapIterator,
} from "./models"
import { roughToFine } from "./util"

export interface MinmapConfig {
    biomeBased: boolean
    worldMetaDimensions?: WorldMetaDimensions
}

export class TileMapMetaProvider {
    private tilemaps: TileMapDirectory
    private layerIndexedMap: IndexedMap<LayerDescriptor>
    private tilesets: Tileset[]
    private tileTypesMap: Array<Array<Set<TileType>>>
    private tileBlockingAllowed: Array<Array<Set<Direction>>>
    private tileBlockingNotAllowed: Array<Array<Set<Direction>>>
    private _width: number
    private _height: number
    lastEditedTs = 0
    private removeEventListeners: Set<(entity: MapDelta) => void> = new Set<(entity: MapDelta) => void>()
    private addEventListeners: Set<(entity: MapDelta) => void> = new Set<(entity: MapDelta) => void>()
    roomId: string
    biomeColorMap: BiomeColorMap
    locationIcons: LocationIcon[]
    private compositeTileMetaLookup: Record<string, NumberedTileMeta> = {}
    private tilesetsById: Record<string, Tileset> = {}
    minimapConfig: MinmapConfig

    constructor(
        roomId: string,
        tilesets: Tileset[],
        tileMapDirectory: TileMapDirectory,
        worldMetaDimensions: WorldMetaDimensions,
    ) {
        this.roomId = roomId
        this.tilemaps = tileMapDirectory
        this.tilesets = tilesets.sort((a, b) => b.firstGid - a.firstGid)

        const firstTileMap = tileMapDirectory.tilemaps[0]
        if (!firstTileMap) {
            return
        }
        this._width = firstTileMap.length
        this._height = firstTileMap[0].length

        this.tileTypesMap = []
        this.tileBlockingAllowed = []
        this.tileBlockingNotAllowed = []
        this.minimapConfig = {
            biomeBased: true,
            worldMetaDimensions,
        }

        this.layerIndexedMap = new IndexedMap<LayerDescriptor>()
        if (this.tilemaps.layerIdToTileMap) {
            Object.keys(this.tilemaps.layerIdToTileMap).forEach(layerId => {
                let tilemap
                const layerClass = this.tilemaps.layerIdToTileMap[layerId]
                const { maptype, idx } = layerClass
                switch (maptype) {
                    case "tilemap": {
                        tilemap = this.tilemaps?.tilemaps[idx]
                        break
                    }
                    case "sprited": {
                        tilemap = this.tilemaps?.sprited[idx]
                        break
                    }
                    case "sky": {
                        tilemap = this.tilemaps?.sky[idx]
                        break
                    }
                }
                this.layerIndexedMap.set(layerId, {
                    entityId: layerId,
                    tilemap,
                    layerClass,
                })
            })
        }

        const blockingTileSet = this.tilesets.find(n => n.tilesetId === "lpc-terrain")

        for (let x = 0; x < this._width; x++) {
            const column = []
            this.tileTypesMap.push(column)

            const tileBlockingAllowedColumn = []
            const tileBlockingNotAllowedColumn = []
            this.tileBlockingAllowed.push(tileBlockingAllowedColumn)
            this.tileBlockingNotAllowed.push(tileBlockingNotAllowedColumn)

            for (let y = 0; y < this._height; y++) {
                const rowSet = new Set()
                column.push(rowSet)

                const rowTileBlockingAllowedSet = new Set()
                const rowTileBlockingNotAllowedSet = new Set()
                tileBlockingAllowedColumn.push(rowTileBlockingAllowedSet)
                tileBlockingNotAllowedColumn.push(rowTileBlockingNotAllowedSet)

                // inspect every map level and merge the tile types relevant to each cell in the map
                for (let i = 0; i < tileMapDirectory.tilemaps.length; i++) {
                    // get the tile map
                    const tileMap = tileMapDirectory.tilemaps[i]

                    // look up the tile id of the current cell
                    const tileId = `${tileMap[x][y]}`
                    // look up the tiletypes that tile id and merge it into the cell's tile types set
                    const tileMeta: TileMeta = this.resolveTileMeta(tileId)

                    const toAdd: TileType[] = tileMeta.tileTypes
                    toAdd.forEach(tileType => rowSet.add(tileType as TileType))
                }

                // inspect blocking
                if (tileMapDirectory.blocking) {
                    const blockingTile = tileMapDirectory.blocking[x][y]
                    if (blockingTile && blockingTile !== `${0}`) {
                        rowSet.add("blocking")

                        if (blockingTileSet) {
                            const blockingTileId = Number(blockingTile) - blockingTileSet.firstGid
                            switch (blockingTileId) {
                                case 957: {
                                    rowTileBlockingAllowedSet.add("Down")
                                    rowTileBlockingNotAllowedSet.add("Right")
                                    break
                                }
                                case 958: {
                                    rowTileBlockingAllowedSet.add("Down")
                                    break
                                }
                                case 959: {
                                    rowTileBlockingAllowedSet.add("Down")
                                    rowTileBlockingNotAllowedSet.add("Left")
                                    break
                                }

                                case 989: {
                                    rowTileBlockingAllowedSet.add("Left")
                                    break
                                }
                                case 991: {
                                    rowTileBlockingAllowedSet.add("Right")
                                    break
                                }

                                case 1021: {
                                    rowTileBlockingAllowedSet.add("Left")
                                    rowTileBlockingAllowedSet.add("Up")
                                    break
                                }
                                case 1022: {
                                    rowTileBlockingAllowedSet.add("Down")
                                    break
                                }
                                case 1023: {
                                    rowTileBlockingAllowedSet.add("Right")
                                    rowTileBlockingAllowedSet.add("Up")
                                    break
                                }
                            }
                        }
                    }
                }

                if (tileMapDirectory.occlusion) {
                    const occlusionTile = tileMapDirectory.occlusion[x][y]
                    if (occlusionTile && occlusionTile !== `${0}`) {
                        rowSet.add("occlusion")
                    }
                }

                if (tileMapDirectory.sprited) {
                    tileMapDirectory.sprited.forEach(sprited => {
                        const spritedTileId = sprited[x][y]
                        const tileMeta: TileMeta = this.resolveTileMeta(spritedTileId)
                        const toAdd: TileType[] = tileMeta.tileTypes
                        toAdd.forEach(tileType => rowSet.add(tileType as TileType))
                    })
                }
            }
        }

        const { deltas } = tileMapDirectory
        const deltaLayers = Object.keys(deltas)
        if (deltaLayers.length > 0) {
            deltaLayers.forEach(layerId => {
                const layerDeltas = deltas[layerId]
                Object.values(layerDeltas).forEach(delta => {
                    if (delta.removed) {
                        this.removeTileAt(delta.x, delta.y, layerId)
                    } else {
                        this.addTileAt(
                            delta.x,
                            delta.y,
                            delta.blocking,
                            delta.occlusion,
                            delta.tileId,
                            delta.layerId,
                            delta.mapSpriteCap,
                            delta.temporary,
                            delta.collideable,
                            delta.context,
                        )
                        if (delta.mapSpriteCap) {
                            this.addMapSpriteCapAt(delta.x, delta.y, delta.mapSpriteCap.context)
                        }
                    }
                })
            })
        }

        this.tilesets.forEach(tileset => {
            this.tilesetsById[tileset.tilesetId] = tileset
            Object.keys(tileset.tiles).forEach(tileId => {
                const tileMeta: TileMeta = tileset.tiles[tileId]
                if (tileMeta?.compositeTileId && tileMeta.compositeTileParent) {
                    const children: NumberedTileMeta[] = []
                    this.compositeTileMetaLookup[`${tileset.tilesetId}-${tileMeta.compositeTileId}`] = {
                        tileMeta,
                        tileNumber: parseInt(tileId),
                        children,
                    }

                    Object.keys(tileset.tiles).reduce((acc, childTileId) => {
                        const childTileMeta: TileMeta = tileset.tiles[childTileId]
                        if (childTileMeta.compositeTileId === tileMeta.compositeTileId) {
                            children.push({
                                tileMeta: childTileMeta,
                                tileNumber: parseInt(childTileId),
                            })
                        }
                        return acc
                    }, [])
                }
            })
        })
    }

    resolveCompositeTileIdTileMeta = (compositeTileId: string, tilesetId: string): NumberedTileMeta => {
        return this.compositeTileMetaLookup[`${tilesetId}-${compositeTileId}`]
    }

    getTilesetById = (tilesetId: string): Tileset => {
        return this.tilesetsById[tilesetId]
    }

    resolveTileSet = (tileId: string): Tileset => {
        // tileId: 505
        // tileset 3: gid 1025
        //  505 >= 1025 ? no
        // tileset 2: gid 500
        //  505 >= 500 ? yes
        // ====== tileset 2
        // tileset 1: gid 1

        // tileId: 305
        // tileset 3: gid 1025
        //  305 >= 1025 ? no
        // tileset 2: gid 500
        //  305 >= 500 ? no
        // tileset 1: gid 1
        //  305 >= 1 ? no
        // ... must be first one, since we couldn't resolve any
        const id = Number(tileId)
        for (let i = 0; i < this.tilesets.length; i++) {
            const tileset = this.tilesets[i]
            if (id >= tileset.firstGid) {
                return tileset
            }
        }

        const first = this.tilesets[this.tilesets.length - 1]
        return first
    }

    resolveTileMeta = (tileId: string): TileMeta => {
        if (!tileId) {
            return createBlankTileMeta()
        }

        if (tileId.includes(":")) {
            const [tilesetId, id] = tileId.split(":")
            const tileset = this.tilesets.find(t => t.tilesetId == tilesetId)
            if (tileset) {
                return tileset.tiles[Number(id)] || createBlankTileMeta()
            }
        }
        const id = Number(tileId)

        const tileset = this.resolveTileSet(tileId)
        if (!tileset) {
            return createBlankTileMeta()
        }

        const { firstGid } = tileset
        const tilesetMemberId = `${id - firstGid + 1}`
        return tileset.tiles[tilesetMemberId] || createBlankTileMeta()
    }

    resolveTilesetTileId = (tile: string) => {
        const tileset = this.resolveTileSet(tile)
        const tileId = Number(tile)
        const adjustedTileId = tileId - tileset.firstGid + 1
        return `${tileset.tilesetId}-${adjustedTileId}`
    }

    toSerialiazeableMeta = () => {
        console.log("=======>", this.isCollisionAt(14, 9))
        return {
            atlas: this.tilesets,
            tilemaps: this.tilemaps,
            minimapConfig: this.minimapConfig,
        }
    }

    private drawableTile = (tile: string) => tile !== "0" && !!tile

    private removeableTile = (tile: string) => {
        return this.resolveTileMeta(tile)?.malleable
    }

    private hasRemovedDelta = (x: number, y: number, tileId: string, layerId: string) => {
        if (this.tilemaps.deltas) {
            const layerDeltas = this.tilemaps.deltas[layerId]
            if (!layerDeltas) {
                return false
            }
            const delta = layerDeltas[`${x}-${y}`]
            if (!!delta?.removed && delta.tileId === tileId) {
                return true
            }
        }

        return false
    }

    shouldDrawTileAt = (x: number, y: number, layerId: string, tilemap: TileMap) => {
        const id = tilemap[x][y]
        if (!this.drawableTile(id)) {
            return false
        }

        if (this.tilemaps.deltas) {
            const delta = this.getMapDeltaAt(x, y, layerId)
            if (!!delta?.removed && delta.tileId === id) {
                return false
            }
        }

        return true
    }

    getMapDeltas = (): MapDelta[] => {
        const deltas = []
        Object.keys(this.tilemaps.deltas).forEach(layerId => {
            const deltaLayer = this.tilemaps.deltas[layerId]
            Object.values(deltaLayer).forEach(delta => {
                deltas.push(delta)
            })
        })

        return deltas
    }

    getMapDeltaAt = (x: number, y: number, layerId: string): MapDelta => {
        return this.tilemaps.deltas[layerId]?.[`${x}-${y}`]
    }

    setMapDeltas = (deltas: MapDelta[], broadcast?: boolean) => {
        deltas.forEach((delta: MapDelta) => {
            const { x, y, blocking, occlusion, tileId, layerId, mapSpriteCap, temporary, collideable, context } = delta
            if (tileId) {
                if (delta.removed) {
                    this.removeTileAt(x, y, delta.layerId, broadcast)
                } else {
                    this.addTileAt(
                        x,
                        y,
                        blocking,
                        occlusion,
                        tileId,
                        layerId,
                        mapSpriteCap,
                        temporary,
                        collideable,
                        context,
                        broadcast,
                    )
                }
            } else {
                // update blocking
                this.updateBlockingAt(x, y, blocking, broadcast)
            }
        })
    }

    visit = (
        callback: ({
            tilemap,
            layerId,
            sky,
            sprited,
            mapSpriteCaps,
            index,
        }: {
            tilemap?: TileMap
            layerId?: string
            sky?: boolean
            sprited?: boolean
            mapSpriteCaps?: MapSpriteCap[]
            spriteFragments?: FragmentSpriteMeta[]
            index?: number
        }) => void,
    ) => {
        this.layerIndexedMap.iterate(
            (next: LayerDescriptor) => {
                if (next.layerClass.maptype === "tilemap") {
                    callback({ tilemap: next.tilemap, layerId: next.entityId })
                }
                if (next.layerClass.maptype === "sprited") {
                    callback({
                        tilemap: next.tilemap,
                        sprited: true,
                        mapSpriteCaps: this.tilemaps.mapSpriteCaps,
                        index: next.layerClass.idx + 1,
                        layerId: next.entityId,
                    })
                }
            },
            n => n.layerClass.maptype === "tilemap" || n.layerClass.maptype === "sprited",
        )

        if (this.tilemaps.spriteFragments?.length > 0) {
            callback({
                spriteFragments: this.tilemaps.spriteFragments,
            })
        }

        this.layerIndexedMap.iterate(
            (next: LayerDescriptor) => {
                callback({
                    tilemap: next.tilemap,
                    sky: true,
                    index: next.layerClass.idx + 1,
                    layerId: next.entityId,
                })
            },
            n => n.layerClass.maptype === "sky",
        )
    }

    isOcclusionAt = (x: number, y: number): boolean => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return false
        }
        if (!_x[y]?.has("occlusion")) {
            return false
        }

        return true
    }

    isCollisionAt = (
        x: number,
        y: number,
        approacherFineX?: number,
        approacherFineY?: number,
        direction?: Direction,
    ): boolean => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return false
        }
        if (!_x[y]?.has("blocking")) {
            return false
        }

        // there is blocking
        // if there is no approach specified, then we're blocked no matter what
        if (approacherFineX === undefined || approacherFineY === undefined) {
            return true
        }

        // special inspection
        let allowed: Set<Direction> = this.tileBlockingAllowed[x]?.[y]
        let notAllowed: Set<Direction> = this.tileBlockingNotAllowed[x]?.[y]

        if (allowed.size < 1) {
            return true
        }

        const blockedLeft = (() => {
            const xFine = roughToFine(x)
            const yFine = roughToFine(y)
            const diff = xFine - approacherFineX
            const dist = Math.abs(diff)

            if (allowed.has("Left")) {
                if (direction === "Left") {
                    // console.log("dist", dist, Date.now())

                    if (dist < 20) {
                        return true
                    } else {
                        // console.log("no collision")
                        return false
                    }
                } else if (direction === "Right") {
                    if (diff > 0) {
                        return true
                    } else {
                        return false
                    }
                }
            }

            if (notAllowed.has("Left")) {
                if (direction === "Left" && diff < -41) {
                    // console.log("[NOT ALLOWED LEFT] diff", diff)
                    return true
                }
            }
        })()

        const blockedDown = (() => {
            if (allowed.has("Down")) {
                const xFine = roughToFine(x)
                const yFine = roughToFine(y)
                const diff = yFine - approacherFineY
                const dist = Math.abs(diff)
                // console.log("dir", dir)

                if (direction === "Down") {
                    // console.log("dist down", dist, Date.now())

                    if (dist < 5) {
                        // console.log("(DOWN) blocked down")
                        return true
                    } else {
                        // console.log("(DOWN) no collision down")
                        return false
                    }
                } else if (direction === "Up") {
                    // console.log("(DOWN) up diff", diff)
                    if (diff > -22) {
                        // console.log("(DOWN) blocked up")
                        return true
                    } else {
                        // console.log("(DOWN) no collision up")
                        return false
                    }
                }
            }
        })()

        const blockedRight = (() => {
            const xFine = roughToFine(x)
            const yFine = roughToFine(y)
            const diff = xFine - approacherFineX
            const dist = Math.abs(diff)

            if (allowed.has("Right")) {
                // console.log("dir", dir)

                if (direction === "Right") {
                    // console.log("dist", dist, Date.now(), "diff", diff)

                    if (diff < -7) {
                        return true
                    } else {
                        // console.log("no collision")
                        return false
                    }
                } else if (direction === "Left") {
                    // console.log("going left, diff", diff)
                    if (diff > -40) {
                        return true
                    } else {
                        return false
                    }
                }
            }

            if (notAllowed.has("Right")) {
                if (direction === "Right" && diff < 3) {
                    // console.log("[NOT ALLOWED RIGHT] diff", diff)
                    return true
                }
            }
        })()

        const blockedUp = (() => {
            if (allowed.has("Up")) {
                const xFine = roughToFine(x)
                const yFine = roughToFine(y)
                const diff = yFine - approacherFineY
                const dist = Math.abs(diff)
                // console.log("dir", dir)

                if (direction === "Up") {
                    // console.log("dist up", dist, Date.now())

                    if (dist < 22) {
                        // console.log("(UP) blocked up")
                        return true
                    } else {
                        // console.log("(UP) no collision up")
                        return false
                    }
                } else if (direction === "Down") {
                    // console.log("(UP) down diff", diff)
                    if (diff > -22) {
                        // console.log("(UP) blocked down")
                        return true
                    } else {
                        // console.log("(UP) no collision down")
                        return false
                    }
                }
            }
        })()

        // console.log("default block")
        return blockedDown === true || blockedLeft === true || blockedRight === true || blockedUp === true
    }

    isDeepAt = (x: number, y: number): boolean => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return false
        }
        return _x[y]?.has("deep") && !_x[y]?.has("floor")
    }

    isSkyTileAt = (x: number, y: number): boolean => {
        if (!this.tilemaps.skyTrigger) {
            return false
        }
        const _x = this.tilemaps.skyTrigger[x]
        if (!_x) {
            return false
        }
        const hasSky = !!_x[y] && _x[y] !== "0"

        if (hasSky) {
            // debugger
        }
        return hasSky
    }

    intersectionAt = (x: number, y: number): MapIntersectionType => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return ""
        }
        if (!_x[y]) {
            return ""
        }

        if (_x[y]?.has("collideable")) {
            return "collideable"
        }

        if (_x[y]?.has("blocking")) {
            return "blocking"
        }

        if (_x[y]?.has("entityTile")) {
            return "entityTile"
        }

        return ""
    }

    resolveEntityTileLayerId = () => this.tilemaps.entityTileLayerId

    removeTileAt = (x: number, y: number, layerId: string, broadcast?: boolean): MapDelta => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return null
        }

        // console.log(
        //     "REMOVE",
        //     x,
        //     y,
        //     layerId,
        //     "occlusion?",
        //     this.tileTypesMap[x][y]?.has("occlusion"),
        //     "blocking?",
        //     this.tileTypesMap[x][y]?.has("blocking"),
        // )

        // resolve tileId to remove
        let tileId: string
        if (layerId) {
            const map = this.resolveTileMapFromLayerId(layerId)
            if (map) {
                tileId = map[x][y]
            }
        } else {
            const map = this.resolveTileMapFromLayerId(this.tilemaps.entityTileLayerId)
            if (map) {
                const id = map[x][y]
                if (this.removeableTile(id)) {
                    layerId = this.tilemaps.entityTileLayerId
                    tileId = id
                }
            }
        }

        const delta: MapDelta =
            layerId && tileId && tileId !== "0"
                ? {
                      x,
                      y,
                      removed: true,
                      layerId,
                      tileId,
                  }
                : undefined

        const deltaKey = `${x}-${y}`
        const existingDelta = this.tilemaps.deltas[layerId]?.[deltaKey]
        const wasTemporary = existingDelta && existingDelta.temporary
        if (wasTemporary) {
            // delete temporary delta
            delete this.tilemaps.deltas[layerId][deltaKey]
        }

        if (delta) {
            _x[y]?.delete("entityTile")

            this.lastEditedTs = Date.now()

            if (!wasTemporary) {
                let deltaLayer = this.tilemaps.deltas[layerId]

                if (!deltaLayer) {
                    deltaLayer = {}
                    this.tilemaps.deltas[layerId] = deltaLayer
                }
                deltaLayer[`${x}-${y}`] = delta
            }
            const map = this.resolveTileMapFromLayerId(layerId)
            delete map[x][y]

            let blocking
            let occlusion
            let collideable
            let deep
            let floor
            Object.values(this.tilemaps.deltas)
                .map(n => Object.values(n).filter(n => n.x === x && n.y === y && !n.removed))
                .flat()
                .forEach(delta => {
                    if (delta.blocking) {
                        blocking = true
                    }
                    if (delta.occlusion) {
                        occlusion = true
                    }
                    if (delta.collideable) {
                        collideable = true
                    }
                    const tileMeta = this.resolveTileMeta(delta.tileId)
                    if (tileMeta?.tileTypes?.includes("deep")) {
                        deep = true
                    }
                    if (tileMeta?.tileTypes?.includes("floor")) {
                        floor = true
                    }
                })

            if (!blocking) {
                _x[y]?.delete("blocking")
                delete this.tilemaps?.blocking[x]?.[y]
            }
            if (!occlusion) {
                _x[y]?.delete("occlusion")
                delete this.tilemaps?.occlusion[x]?.[y]
            }
            if (!collideable) {
                _x[y]?.delete("collideable")
            }

            const thisTileMeta = this.resolveTileMeta(tileId)
            if (thisTileMeta.tileTypes.includes("deep") && !deep) {
                _x[y]?.delete("deep")
            }
            if (thisTileMeta.tileTypes.includes("floor") && !floor) {
                _x[y]?.delete("floor")
            }

            if (broadcast) {
                this.removeEventListeners.forEach(listener => listener(delta))
            }
        }

        return delta
    }

    updateBlockingAt = (x: number, y: number, blocking: boolean, broadcast: boolean) => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return null
        }

        if (blocking) {
            _x[y]?.add("blocking")
        } else {
            _x[y]?.delete("blocking")
        }

        let blockingDeltaLayer = this.tilemaps.deltas["blocking"]
        if (!blockingDeltaLayer) {
            blockingDeltaLayer = {}
        }
        const delta: MapDelta = blockingDeltaLayer[`${x}-${y}`] || {
            x,
            y,
            blocking,
        }
        delta.blocking = blocking
        blockingDeltaLayer[`${x}-${y}`] = delta
        this.tilemaps.deltas["blocking"] = blockingDeltaLayer
        this.lastEditedTs = Date.now()

        if (broadcast) {
            this.removeEventListeners.forEach(listener => listener(delta))
        }
        return delta
    }

    getTileMeta = (
        tileId: string,
        tilesetId: string,
    ): {
        tileId: string
        meta: TileMeta
    } => {
        const tileset = this.tilesets.find(t => t.tilesetId === tilesetId)
        if (!tileset) {
            return null
        }
        const meta = tileset.tiles[tileId]
        if (meta) {
            return {
                tileId: `${tileset.firstGid + Number(tileId) - 1}`,
                meta,
            }
        }

        return null
    }

    entityTileIdToTileId = (
        entityTileId: EntityTile,
    ): {
        tileId: string
        meta: TileMeta
    } => {
        let foundId
        let foundTileSet: Tileset
        let foundMeta: TileMeta
        this.tilesets.every(tileset => {
            Object.keys(tileset.tiles).every(tileId => {
                const meta = tileset.tiles[tileId]
                if (meta.entityTileId === entityTileId) {
                    foundId = tileId
                    foundTileSet = tileset
                    foundMeta = meta
                    return false
                }
                return true
            })
            if (foundId) {
                return false
            }
            return true
        })

        if (foundId && foundTileSet) {
            return {
                tileId: `${foundTileSet.firstGid + Number(foundId) - 1}`,
                meta: foundMeta,
            }
        }
        return null
    }

    getEntityTileAt = (x: number, y: number): { tileId: string; entityTile: EntityTile } => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return null
        }

        if (this.tilemaps.tilemaps) {
            for (let i = 0; i < this.tilemaps.tilemaps.length; i++) {
                const map = this.tilemaps.tilemaps[i]
                const id = map[x][y]
                const tileMeta = this.resolveTileMeta(id)
                if (tileMeta?.entityTileId) {
                    if (this.hasRemovedDelta(x, y, id, this.tilemaps.entityTileLayerId)) {
                        return null
                    }
                    return { tileId: id, entityTile: tileMeta?.entityTileId }
                }
            }
        }
    }

    entityTileMap = () => {
        return this.tilemaps.tilemaps[this.tilemaps.entityTilemapIdx]
    }

    addMapSpriteCapAt = (x: number, y: number, context: any) => {
        this.tilemaps.mapSpriteCaps.push({
            x,
            y,
            context: context,
        })
    }

    addTileAt = (
        x: number,
        y: number,
        blocking: boolean,
        occlusion: boolean,
        tileId: string,
        layerId: string,
        mapSpriteCap?: MapSpriteCap,
        temporary?: boolean,
        collideable?: boolean,
        context?: any,
        broadcast?: boolean,
    ): MapDelta => {
        const _x = this.tileTypesMap[x]
        if (!_x) {
            return null
        }

        // console.log("ADD", x, y, layerId, `occlusion - ${occlusion}`, `blocking - ${blocking}`)

        if (blocking) {
            _x[y]?.add("blocking")
        }

        if (occlusion) {
            _x[y]?.add("occlusion")
        }

        if (collideable) {
            _x?.[y]?.add("collideable")
        }

        if (tileId) {
            // entityTiles
            const tileMeta = this.resolveTileMeta(tileId)
            if (tileMeta.entityTileId) {
                this.tileTypesMap[x]?.[y]?.add("entityTile")
            }

            // add tile id to the entity tile surface
            const entityTileMap = layerId
                ? this.resolveTileMapFromLayerId(layerId)
                : this.tilemaps.tilemaps[this.tilemaps.entityTilemapIdx]
            if (entityTileMap) {
                entityTileMap[x][y] = tileId
            }

            if (tileMeta.tileTypes.includes("deep")) {
                _x[y]?.add("deep")
            }

            if (tileMeta.tileTypes.includes("floor")) {
                _x[y]?.add("floor")
            }
        }

        //
        // update deltas
        //
        const existingMapDelta = this.getMapDeltaAt(x, y, layerId)
        if (existingMapDelta) {
            if (tileId !== existingMapDelta.tileId) {
                existingMapDelta.tileId = tileId
            }
            existingMapDelta.blocking = blocking
            existingMapDelta.occlusion = occlusion
            existingMapDelta.mapSpriteCap = mapSpriteCap
            delete existingMapDelta.removed

            this.addEventListeners.forEach(listener => listener(existingMapDelta))

            return existingMapDelta
        }

        const delta: MapDelta = {
            x,
            y,
            tileId,
            blocking: blocking === false ? undefined : blocking,
            occlusion: occlusion === false ? undefined : occlusion,
            layerId,
            mapSpriteCap: mapSpriteCap,
            temporary: temporary === false ? undefined : temporary,
            collideable: collideable === false ? undefined : collideable,
            context,
        }
        let deltaLayer = this.tilemaps.deltas[layerId]
        if (!deltaLayer) {
            deltaLayer = {}
            this.tilemaps.deltas[layerId] = deltaLayer
        }
        deltaLayer[`${x}-${y}`] = delta
        this.lastEditedTs = Date.now()

        if (broadcast) {
            this.addEventListeners.forEach(listener => listener(delta))
        }

        return delta
    }

    resolveLayerMapping = (layerId: string): TileLayerMapping => {
        if (layerId === "skyTrigger" || layerId === "occlusion") {
            return {
                idx: 0,
                maptype: layerId,
            }
        }

        return this.layerIndexedMap?.get(layerId)?.layerClass
    }

    getBlockingLayer = () => this.tilemaps.blocking

    resolveTileMapFromLayerId = (layerId: string): TileMap => {
        if (!layerId) {
            return null
        }

        switch (layerId) {
            case "skyTrigger": {
                return this.tilemaps?.skyTrigger
            }
            case "occlusion": {
                return this.tilemaps?.occlusion
            }
        }
        return this.layerIndexedMap?.get(layerId)?.tilemap
    }

    isHiddenTile = (tileId: string): boolean => this.resolveTileMeta(tileId).tileTypes.includes("hidden")

    getCompositeTileId = (tileId: string): string => this.resolveTileMeta(tileId).compositeTileId

    height = () => this._height

    width = () => this._width

    registerRemoveListener = (listener: (entity: MapDelta) => void) => {
        this.removeEventListeners.add(listener)
    }

    unregisterRemoveListener = (listener: (entity: MapDelta) => void) => {
        this.removeEventListeners.delete(listener)
    }

    registerAddListener = (listener: (entity: MapDelta) => void) => {
        this.addEventListeners.add(listener)
    }

    unregisterAddListener = (listener: (entity: MapDelta) => void) => {
        this.addEventListeners.delete(listener)
    }

    iterateLayer = (layerId: string, visitor: (x: number, y: number, t: string) => void) => {
        const map = this.resolveTileMapFromLayerId(layerId)
        if (!map) {
            return
        }
        tileMapIterator<string>(map, visitor)
    }
}
