import { Mechanics } from "game-common/mechanics/mechanics"
import { Coordinate, Entity, EntityId, TileSize } from "game-common/models"
import { Graphics } from "pixi.js"
import { ClientGameLogic } from "../../../client_game_logic"
import { IWorldOverlaySurface } from "../../../client_models"
import { ClientRenderable } from "./entity_rendering_manager"
import { BaseRenderable } from "./sprites/base_renderable"
import { ScalingParams } from "../../../client_util"

export class WorldOverlayItem extends BaseRenderable {}

const SCALING_CONSTANT = ScalingParams.surfaceZoom
export class WorldOverlaySurface extends Graphics implements IWorldOverlaySurface {
    private entityIdToOverlayItem: Map<EntityId, ClientRenderable> = new Map()

    logic: ClientGameLogic

    constructor(logic: ClientGameLogic) {
        super()
        this.logic = logic
    }

    offer = (entity: Entity): WorldOverlayItem => {
        const renderable = new WorldOverlayItem()
        this.entityIdToOverlayItem.set(entity.entityId, renderable)

        const f = 32 * SCALING_CONSTANT
        renderable.beginFill(0xff0000, 0.01)
        renderable.drawRect(0, 0, f, f)
        renderable.endFill()

        renderable.width = f
        renderable.height = f
        this.addChild(renderable)

        return renderable
    }

    remove = (entity: Entity) => {
        if (!entity) {
            return
        }
        const renderable = this.entityIdToOverlayItem.get(entity.entityId)
        if (!renderable) {
            return
        }

        renderable.remove()
        this.entityIdToOverlayItem.delete(entity.entityId)
    }

    updateEntityId = (originalEntityId: EntityId, updatedEntityId: EntityId) => {
        this.entityIdToOverlayItem.set(updatedEntityId, this.entityIdToOverlayItem.get(originalEntityId))
        this.entityIdToOverlayItem.delete(originalEntityId)
    }

    updateEntity = (entity: Entity) => {
        if (!entity) {
            return
        }

        const renderable = this.entityIdToOverlayItem.get(entity.entityId)
        if (!renderable) {
            return
        }

        const yScaler = Mechanics.entity.body.isShort(entity) ? 0.75 : 1.25
        renderable.visible = entity.visible
        renderable.x = entity.location.x * SCALING_CONSTANT - TileSize * 0.25 * SCALING_CONSTANT
        renderable.y = entity.location.y * SCALING_CONSTANT - TileSize * yScaler * SCALING_CONSTANT
    }

    getEntityRenderable = (entityId: EntityId): ClientRenderable => {
        return this.entityIdToOverlayItem.get(entityId)
    }

    computeLocation = (x: number, y: number): Coordinate => {
        return {
            x: x * SCALING_CONSTANT,
            y: y * SCALING_CONSTANT,
        }
    }
}
