import { Callback } from "game-common/util"
import { BaseTexture, Graphics, Loader, Sprite, Texture } from "pixi.js"
import { BasicSprite } from "../sprites/basic_sprite"
import { randomFloatBetween, randomIntBetween } from "game-common/models"
import { Fader } from "../gui/fader"

export class IntroBackground extends Graphics {
    bufferFlies = () => {
        const container = new Graphics()

        container.beginFill(0xffffff, 0)
        container.drawRect(0, 0, 800, 1)
        container.drawRect(0, 0, 1, 400)
        container.endFill()
        for (let i = 0; i < 3; i++) {
            setTimeout(() => {
                const renderable = new BasicSprite({
                    name: "bee",
                    scale: 1.0,
                    color: 0xffffff,
                    animationSpeed: randomFloatBetween(0.14, 0.167),
                })
                renderable.x = randomIntBetween(50, 800)
                renderable.y = randomIntBetween(50, 400)
                renderable.alpha = 0.0
                container.addChild(renderable)
                new Fader(renderable, {
                    direction: "in",
                    minAlpha: 1.0,
                    fadeAlphaDecrement: 0.1,
                }).update()
            }, randomIntBetween(0, 250))
        }
        return container
    }

    update = () => {
        const container = new Graphics()
        const sprite = new Sprite(Loader.shared.resources["intro_scene"].texture)
        sprite.scale.set(0.3)

        container.addChild(sprite)
        container.alpha = 0.0
        return container
    }
}
