export const clamp = (num: number, min: number, max: number) => Math.min(Math.max(num, min), max)
export const isMobile = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

export const SCALER = 1.3
export const getRandomColor = () => {
    const letters = "0123456789ABCDEF".split("")
    let color = "0x"
    for (var i = 0; i < 6; i++) {
        color += letters[Math.round(Math.random() * 15)]
    }
    return color
}

export const ScalingParams = {
    scale: SCALER,
    surfaceZoom: 2.0,
    viewportX: 7,
    viewportY: 5,
}
