import { BaseRenderable } from "./base_renderable"

export class MinimapItem extends BaseRenderable {
    constructor(color: any = 0xff00ff) {
        super()

        super.beginFill(color)
        super.drawRect(0, 0, 1, 1)
        super.endFill()
    }

    recolor(color: number) {
        super.beginFill(color)
        super.drawRect(0, 0, 1, 1)
        super.endFill()
    }
}
