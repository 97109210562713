import { ItemDetailMeta, ItemDetailOption } from "game-common/models"
import { Callback } from "game-common/util"
import { Graphics } from "pixi.js"

import { BasicSprite } from "../sprites/basic_sprite"
import { HoverText } from "./dynamic_dialog"
import { DynamicText } from "./dynamic_text"
import { DyanmicTextContainer, DyanmicTextContainerProps } from "./dynamic_text_container"
import { Fader } from "./fader"
import { TextRenderer } from "../text/text_renderer"
import { isMobile } from "../../../../client_util"

export class ItemDetail extends Graphics {
    surface: Graphics
    hoverText: HoverText
    fader: Fader

    constructor() {
        super()
        this.surface = new Graphics()
        this.addChild(this.surface)
        this.hoverText = new HoverText()
        this.interactive = true

        this.on("mouseover", () => {
            if (this.fader) {
                this.fader.stop()
                this.visible = true
                this.alpha = 1.0
            }
        })
        this.on("mouseout", () => {
            this.fader = new Fader(this, {
                fadeInterval: 1,
                fadeAlphaDecrement: 0.03,
                releaseCallback: () => {
                    this.alpha = 1.0
                    this.visible = false
                },
            })
            this.fader.update()
        })
    }

    update = (item: ItemDetailMeta, onSelectCallback: Callback<ItemDetailOption>) => {
        if (!item) {
            return
        }
        this.surface.removeChildren()
        this.clear()

        const main = new DyanmicTextContainer()
        this.surface.addChild(main)
        this.surface.addChild(this.hoverText)

        const title = new DynamicText(item.title, {
            color: 0x00ff00,
        })
        main.append2(title)

        if (item.hoverText?.length > 0) {
            this.hoverText.visible = true
            this.hoverText.visible = true
            this.hoverText.update(item.hoverText)
            this.hoverText.y = -this.hoverText.height
        } else {
            this.hoverText.visible = false
        }

        let width = 0
        item.options.forEach((itemOption, i) => {
            const option = new DynamicText(itemOption.title, {
                type: "button|dynamic",
                onClick: () => {
                    onSelectCallback({ ...itemOption })
                },
            })

            main.append2(option, {
                topMargin: i === 0 ? 5 : undefined,
            })
            if (option.width > width) {
                width = option.width
            }
        })
        this.beginFill(0xff0000, 0.1)
        this.drawRect(0, 0, width, main.height)
        this.endFill()
        this.zIndex = 100000
    }
}

export interface GridMeta {
    itemMeta: ItemDetailMeta[]
    optionSelectionHandler: Callback<ItemDetailOption>
    rows: number
    columns: number
}

export class Grid extends DyanmicTextContainer {
    meta: GridMeta
    foofoo: Graphics
    surface: Graphics
    itemMap: Map<string, Graphics> = new Map()

    constructor(gridMeta: GridMeta, props?: DyanmicTextContainerProps) {
        super(props)
        this.meta = gridMeta
    }

    createContent = (itemDetail: ItemDetail): DyanmicTextContainer => {
        const pane = new DyanmicTextContainer()

        const itemList = this.buildItems(
            this.meta.itemMeta,
            itemDetail,
            this.meta.rows,
            this.meta.columns,
            this.meta.optionSelectionHandler,
        )
        pane.append(itemList)

        this.surface = pane

        return pane
    }

    buildItems = (
        items: ItemDetailMeta[],
        detail: ItemDetail,
        rows: number,
        columns: number,
        optionSelectionHandler: Callback<ItemDetailOption>,
    ) => {
        const itemList = new DyanmicTextContainer()

        const surfaceWidth = 48

        const print = (item: ItemDetailMeta, row: number, column: number) => {
            const itemSurface = new Graphics()
            itemSurface.width = surfaceWidth
            itemSurface.height = surfaceWidth
            itemSurface.zIndex = 1

            if (item) {
                this.itemMap.set(item.id, itemSurface)
            }

            if (item && item.active) {
                itemSurface.beginFill(item.activeColor !== undefined ? item.activeColor : 0x00ff00, 0.5)
                itemSurface.drawCircle(surfaceWidth / 2, surfaceWidth / 2, surfaceWidth / 2 - 1)
                itemSurface.endFill()
            } else {
                itemSurface.beginFill(0xffffff, 0.3)
                itemSurface.drawCircle(surfaceWidth / 2, surfaceWidth / 2, surfaceWidth / 2 - 1)
                itemSurface.endFill()
                itemSurface.beginFill(0x000000, 1)
                itemSurface.drawCircle(surfaceWidth / 2, surfaceWidth / 2, surfaceWidth / 2 - 2)
                itemSurface.endFill()
            }
            if (item) {
                itemSurface.interactive = true
                itemSurface.on(isMobile() ? "tap" : "mouseover", () => {
                    detail.visible = true
                    detail.x = itemSurface.x + this.surface.x + (this.foofoo?.x || 0)
                    detail.y = itemSurface.y + this.surface.y + (this.foofoo?.y || 0)

                    detail.update(item, optionSelectionHandler)
                })

                const itemSprite = new BasicSprite({
                    name: item.spriteId,
                    scale: 0.8,
                    color: 0xffffff,
                    animated: false,
                    rotating: false,
                })
                itemSprite.x = surfaceWidth / 2
                itemSprite.y = surfaceWidth / 2
                itemSurface.addChild(itemSprite)

                if (item.quantity > 1) {
                    const quantityLabel = new TextRenderer().size("tiny").render(`${item.quantity}`)
                    quantityLabel.x = itemSprite.x - quantityLabel.width / 2
                    quantityLabel.y = itemSprite.y + 10
                    itemSurface.addChild(quantityLabel)
                }
            }

            itemSurface.x = column * surfaceWidth
            itemSurface.y = row * surfaceWidth
            itemList.addChild(itemSurface)
        }

        let itemPos = 0
        for (let row = 0; row < rows; row++) {
            for (let column = 0; column < columns; column++) {
                print(items[row * columns + column], row, column)
                itemPos++
            }
        }

        return itemList
    }
}
