import { TradingValueItemTypeMeta } from "./item"

import { ItemTypes } from "./item_type"

export const BaseTradeValue: Partial<Record<ItemTypes, TradingValueItemTypeMeta>> = {
    "armor-leather": {
        "itemType": "shard-metal",
        "quantity": 60
    },
    "armor-legion": {
        "itemType": "shard-metal",
        "quantity": 240
    },
    "armor-plate": {
        "itemType": "shard-metal",
        "quantity": 360
    },
    "arrow": {
        "itemType": "shard-metal",
        "quantity": 0.1
    },
    "coal": {
        "itemType": "shard-metal",
        "quantity": 3
    },
    "copper": {
        "itemType": "shard-metal",
        "quantity": 9
    },
    "drumstick": {
        "itemType": "shard-metal",
        "quantity": 10
    },
    "feather": {
        "itemType": "shard-metal",
        "quantity": 10
    },
    "gold": {
        "itemType": "shard-metal",
        "quantity": 15
    },
    "hide": {
        "itemType": "shard-metal",
        "quantity": 2
    },
    "iron": {
        "itemType": "shard-metal",
        "quantity": 6
    },
    "metal-bar-copper": {
        "itemType": "shard-metal",
        "quantity": 45
    },
    "metal-bar-gold": {
        "itemType": "shard-metal",
        "quantity": 80
    },
    "metal-bar-iron": {
        "itemType": "shard-metal",
        "quantity": 30
    },
    "metal-bar-silver": {
        "itemType": "shard-metal",
        "quantity": 60
    },
    "silver": {
        "itemType": "shard-metal",
        "quantity": 12
    },
    "shield-crusader": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-kite": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-kite_blue": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-kite_gray": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-kite_green": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-kite_red": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "shield-round": {
        "itemType": "shard-metal",
        "quantity": 120
    },
    "dagger": {
        "itemType": "shard-metal",
        "quantity": 10
    },
    "spear": {
        "itemType": "shard-metal",
        "quantity": 20
    }
}