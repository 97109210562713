import { IdAware, LoopingIndexedMap } from "game-common/models"
import { Callback } from "game-common/util"
import { Loader, LoaderResource } from "pixi.js"
import { lpcSpriteSheets } from "../../../../lpc-spritesheets"

export type LoadingLifecycle = "loading" | "loaded"

interface LoadingState {
    url: string
    lifeCycle: LoadingLifecycle
    callbacks: Callback<string>[]
}

interface LoadingMeta {
    url: string
    callback: Function
}

class LoaderContainer implements IdAware {
    static loaderNumber = 0

    entityId: string
    loader: Loader
    timer: any
    isLoading: boolean
    buffer: LoadingMeta[] = []

    constructor() {
        this.entityId = "loader-" + ++LoaderContainer.loaderNumber
        this.loader = new Loader()
    }

    add = (toLoad: LoadingMeta) => {
        this.buffer.push(toLoad)

        if (this.isLoading || this.loader.loading) {
            return
        }

        this.buffer.forEach(item => {
            const { url, callback } = item
            this.loader.add(url, url, callback)
        })
        this.buffer = []

        if (this.timer) {
            clearTimeout(this.timer)
        }

        this.timer = setTimeout(() => {
            this.loader.load(() => {
                this.isLoading = false
            })
        }, 100)
    }
}

interface LoaderContainer {
    entityId: string
    loader: Loader
}

export class SpriteSheetLoader {
    sheetLoadingState: Record<string, LoadingState> = {}
    loaders: LoopingIndexedMap<LoaderContainer> = new LoopingIndexedMap()
    urlLoaderMap: Map<string, Loader> = new Map()

    constructor() {
        for (let i = 0; i < 8; i++) {
            this.loaders.push(new LoaderContainer())
        }
    }

    bulkLoad = (urls: string[], completeCallback: Callback<void>) => {
        let descriptorCountdown = lpcSpriteSheets.length
        const s = Date.now()
        urls.forEach(url => {
            this.load(url, _ => {
                descriptorCountdown -= 1
                if (descriptorCountdown <= 0) {
                    console.log("Bulk asset load completed", urls.length)
                    completeCallback()
                }
            })
        })
    }

    getLoaderFor = (url: string): Loader => {
        return this.urlLoaderMap.get(url)
    }

    load = (url: string, callback: Callback<string>) => {
        const key = `${url}`

        const loadingState = this.sheetLoadingState[key]

        // its not yet been queued for loading; setup the sheet for loading
        // add the callback
        // register loader callback
        if (!loadingState) {
            this.sheetLoadingState[key] = {
                url,
                lifeCycle: "loading",
                callbacks: [callback],
            }
            const loaderContainer = this.loaders.next()
            const loader = loaderContainer.loader

            this.urlLoaderMap.set(url, loader)
            loaderContainer.add({
                url,
                callback: this.loadCallback,
            })
            return
        }

        // its already been loaded; callback immediately
        if (loadingState.lifeCycle === "loaded") {
            callback(url)
            return
        }

        // queue the callback
        loadingState.callbacks.push(callback)
    }

    loadCallback = (resource: LoaderResource) => {
        const url = resource.url
        const loadingState = this.sheetLoadingState[url]
        if (!loadingState) {
            return
        }

        // fullfill the waiting callbacks
        loadingState.callbacks.forEach(callback => {
            callback(loadingState.url)
        })

        // clear them out, mark resource as loaded
        loadingState.callbacks = []
        loadingState.lifeCycle = "loaded"
    }
}

const spriteSheetLoader = new SpriteSheetLoader()
export { spriteSheetLoader }
