import { Coordinate } from "./models"
import { ObjectPool, ObjectPoolItem } from "./object_pool"

export interface CoordinatePoolItem extends ObjectPoolItem<Coordinate> {}

export class CoordinatePool extends ObjectPool<Coordinate> {
    static instance: CoordinatePool = new CoordinatePool()

    constructor(size: number = 1000) {
        super(size)
    }

    create(): CoordinatePoolItem {
        return {
            free: true,
            data: {
                x: -1,
                y: -1,
            },
        }
    }

    clear(item: CoordinatePoolItem) {
        item.data.x = -1
        item.data.y = -1
    }
}
