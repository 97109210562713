export const ItemClassTypes = [
    "armor",
    "bauldron",
    "cape",
    "key",
    "pants",
    "resource",
    "shield",
    "shirt",
    "shoes",
    "structure",
    "weapon"
] as const
