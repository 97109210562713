import { Bauldron, ItemTypes, Key } from "game-common/item/item_type"

export const HumanoidRaceTypes = ["human", "reptile", "reptile-winged", "orc", "skeleton", "wolfman", "zombie"] as const

export const RaceTypes = [
    ...HumanoidRaceTypes,
    "horse",
    "snake",
    "deer",
    "slime",
    "rabbit",
    "chicken",
    "bee",
    "wasp",
    "redspider",
    "spikes1",
] as const

export type Race = (typeof RaceTypes)[number]

export const SexTypes = ["male", "female"] as const

export type Sex = (typeof SexTypes)[number]

export const RaceSexes: Record<Race, Sex[]> = {
    human: ["male", "female"],
    reptile: ["male", "female"],
    "reptile-winged": ["male", "female"],
    orc: ["male", "female"],
    wolfman: ["male", "female"],
    zombie: ["male"],
    skeleton: ["male"],
    horse: ["male"],
    snake: ["male"],
    slime: ["male"],
    rabbit: ["male"],
    deer: ["female"],
    chicken: ["male"],
    bee: ["male"],
    wasp: ["male"],
    redspider: ["male"],
    spikes1: ["male"],
}

export type Color =
    | "white"
    | "blonde"
    | "yellow"
    | "light"
    | "lightgreen"
    | "gold"
    | "green"
    | "teal"
    | "blue"
    | "orange"
    | "maroon"
    | "red"
    | "purple"
    | "olive"
    | "tan"
    | "lightbrown"
    | "brown"
    | "dark"
    | "gray"
    | "silver"
    | "metal"
    | "pink"
    | "black"
    | "na"

export const RaceColors: Record<Race, Color[]> = {
    human: ["white", "black", "olive", "brown", "light", "dark", "tan"],
    reptile: ["green", "lightgreen", "blue"],
    "reptile-winged": ["green", "lightgreen", "blue"],
    orc: ["gray", "green", "lightgreen"],
    wolfman: ["brown", "black", "gray", "white"],
    skeleton: ["white", "gray", "green"],
    zombie: ["green"],
    horse: ["brown"],
    snake: ["green"],
    slime: ["green"],
    deer: ["brown"],
    rabbit: ["white"],
    chicken: ["white"],
    bee: ["yellow"],
    wasp: ["yellow"],
    redspider: ["red"],
    spikes1: ["white"],
}

export const EyeTypes = ["normal", "cyclops"]

export type Eyes = (typeof EyeTypes)[number]

export const EyesColors: Record<Eyes, Color[]> = {
    normal: ["blue", "brown", "gray", "green", "orange", "purple", "red", "yellow"],
    cyclops: ["red"],
}

export const NoseTypes = ["button", "big", "straight"] as const

export type Nose = (typeof NoseTypes)[number]

const commonNoseColor: Color[] = ["white", "tan", "gray"]

export const NoseColors: Record<Nose, Color[]> = {
    big: [...commonNoseColor],
    button: [...commonNoseColor],
    straight: [...commonNoseColor],
}

// todo
export const RaceNoses: Partial<Record<Race, Nose[]>> = {
    human: ["big", "button", "straight"],
    reptile: [],
    "reptile-winged": [],
    orc: ["big", "button", "straight"],
    skeleton: ["big", "button", "straight"],
    wolfman: [],
    zombie: ["big", "button", "straight"],
}

export const RaceEyes: Partial<Record<Race, Eyes[]>> = {
    human: ["normal"],
    orc: ["normal"],
    skeleton: ["normal"],
    zombie: ["normal"],
}

export const WeaponTypes = [
    "dagger",
    "spear",
    "bow",
    "crossbow",
    "staff",
    "axe",
    "warhammer",
    "longsword",
    "mace",
    "rapier",
    "saber",
    "scythe",
    "flail",
    "halberd",
    "waraxe",
    "web",
    "none",
] as const

export type Weapon = (typeof WeaponTypes)[number]

const commonWeapons: Weapon[] = ["spear", "bow", "staff", "axe", "warhammer", "none"]

export const WeaponAction: Record<Weapon, string> = {
    dagger: "slash",
    longsword: "slash",
    flail: "slash",
    halberd: "slash",
    waraxe: "slash",
    rapier: "slash",
    mace: "slash",
    saber: "slash",
    scythe: "slash",
    spear: "thrust",
    bow: "shoot",
    crossbow: "thrust",
    staff: "thrust",
    axe: "slash",
    warhammer: "slash",
    none: "slash",
    web: "shoot",
}

export const WeaponAmmo: Partial<Record<Weapon, ItemTypes>> = {
    bow: "arrow",
    crossbow: "arrow",
    web: "webAmmo",
}

export const WeaponColors: Record<Weapon, Color[]> = {
    dagger: ["metal", "brown"],
    longsword: ["metal"],
    flail: ["metal"],
    halberd: ["metal"],
    waraxe: ["metal"],
    mace: ["metal"],
    saber: ["metal"],
    rapier: ["metal"],
    scythe: ["metal"],
    axe: ["metal"],
    warhammer: ["metal"],
    spear: ["brown", "black", "green", "metal", "purple"],
    bow: ["brown"],
    crossbow: ["brown"],
    staff: ["brown"],
    web: ["white"],
    none: [],
}

export const RaceWeapons: Partial<Record<Race, Weapon[]>> = {
    human: [...commonWeapons],
    reptile: [...commonWeapons],
    "reptile-winged": [...commonWeapons],
    orc: [...commonWeapons],
    wolfman: [...commonWeapons],
    skeleton: [...commonWeapons],
    zombie: [...commonWeapons],
}

export const DefaultAttackActionType: Partial<Record<Race, ActionType>> = {
    snake: "thrust",
    slime: "thrust",
    wasp: "thrust",
    redspider: "thrust",
    skeleton: "thrust",
    zombie: "slash",
    spikes1: "thrust",
}

export const HairTypes = [
    "afro",
    "balding",
    "bangs",
    "bangs_bun",
    "bangslong",
    "bangslong2",
    "bangsshort",
    "bedhead",
    "bob",
    "bob_side_part",
    "braid",
    "braid2",
    "bunches",
    "buzzcut",
    "cornrows",
    "cowlick",
    "cowlick_tall",
    "curly_long",
    "curly_short",
    "curtains",
    "curtains_long",
    "dreadlocks_long",
    "dreadlocks_short",
    "flat_top_fade",
    "flat_top_straight",
    "half_up",
    "halfmessy",
    "high_and_tight",
    "high_ponytail",
    "idol",
    "jewfro",
    "lob",
    "long",
    "long_band",
    "long_center_part",
    "long_messy",
    "long_messy2",
    "long_tied",
    "longhawk",
    "longknot",
    "loose",
    "messy1",
    "messy2",
    "messy3",
    "mop",
    "natural",
    "page",
    "page2",
    "part2",
    "parted",
    // // "pigtails",
    // // "pigtails_bangs",
    "pixie",
    "plain",
    "ponytail",
    "ponytail2",
    "princess",
    "sara",
    "shorthawk",
    "shortknot",
    "shoulderl",
    "shoulderr",
    "single",
    "spiked",
    "spiked2",
    "spiked_beehive",
    "spiked_liberty",
    "spiked_liberty2",
    "spiked_porcupine",
    "swoop",
    "twists_fade",
    "twists_straight",
    "unkempt",
    "wavy",
    "xlong",
] as const

export type Hair = (typeof HairTypes)[number]

const commonHairColor: Color[] = [
    "white",
    "blonde",
    "gold",
    "green",
    "blue",
    "red",
    "orange",
    "purple",
    "brown",
    "lightbrown",
    "gray",
    "black",
]

export const HairColors: Record<Hair, Color[]> = {
    afro: [...commonHairColor],
    balding: [...commonHairColor],
    bangs: [...commonHairColor],
    bangs_bun: [...commonHairColor],
    bangslong: [...commonHairColor],
    bangslong2: [...commonHairColor],
    bangsshort: [...commonHairColor],
    bedhead: [...commonHairColor],
    bob: [...commonHairColor],
    bob_side_part: [...commonHairColor],
    braid: [...commonHairColor],
    braid2: [...commonHairColor],
    bunches: [...commonHairColor],
    buzzcut: [...commonHairColor],
    cornrows: [...commonHairColor],
    cowlick: [...commonHairColor],
    cowlick_tall: [...commonHairColor],
    curly_long: [...commonHairColor],
    curly_short: [...commonHairColor],
    curtains: [...commonHairColor],
    curtains_long: [...commonHairColor],
    dreadlocks_long: [...commonHairColor],
    dreadlocks_short: [...commonHairColor],
    flat_top_fade: [...commonHairColor],
    flat_top_straight: [...commonHairColor],
    half_up: [...commonHairColor],
    halfmessy: [...commonHairColor],
    high_and_tight: [...commonHairColor],
    high_ponytail: [...commonHairColor],
    idol: [...commonHairColor],
    jewfro: [...commonHairColor],
    lob: [...commonHairColor],
    long: [...commonHairColor],
    long_band: [...commonHairColor],
    long_center_part: [...commonHairColor],
    long_messy: [...commonHairColor],
    long_messy2: [...commonHairColor],
    long_tied: [...commonHairColor],
    longhawk: [...commonHairColor],
    longknot: [...commonHairColor],
    loose: [...commonHairColor],
    messy1: [...commonHairColor],
    messy2: [...commonHairColor],
    messy3: [...commonHairColor],
    mop: [...commonHairColor],
    natural: [...commonHairColor],
    page: [...commonHairColor],
    page2: [...commonHairColor],
    part2: [...commonHairColor],
    parted: [...commonHairColor],
    // "pigtails": [ ...commonHairColor ],
    // "pigtails_bangs": [ ...commonHairColor ],
    pixie: [...commonHairColor],
    plain: [...commonHairColor],
    ponytail: [...commonHairColor],
    ponytail2: [...commonHairColor],
    princess: [...commonHairColor],
    sara: [...commonHairColor],
    shorthawk: [...commonHairColor],
    shortknot: [...commonHairColor],
    shoulderl: [...commonHairColor],
    shoulderr: [...commonHairColor],
    single: [...commonHairColor],
    spiked: [...commonHairColor],
    spiked2: [...commonHairColor],
    spiked_beehive: [...commonHairColor],
    spiked_liberty: [...commonHairColor],
    spiked_liberty2: [...commonHairColor],
    spiked_porcupine: [...commonHairColor],
    swoop: [...commonHairColor],
    twists_fade: [...commonHairColor],
    twists_straight: [...commonHairColor],
    unkempt: [...commonHairColor],
    wavy: [...commonHairColor],
    xlong: [...commonHairColor],
}

export const SexHair: Record<Sex, Hair[]> = {
    male: [
        ...HairTypes.filter(
            hair =>
                ![
                    "bob",
                    "bob_side_part",
                    "braid",
                    "braid2",
                    "bunches",
                    "curly_long",
                    "long_band",
                    // "pigtails",
                    // "pigtails_bang",
                    "princess",
                    "sara",
                    "shoulderl",
                    "shoulderr",
                    "xlong",
                ].includes(hair),
        ),
    ],
    female: [...HairTypes.filter(hair => !["balding"].includes(hair))],
}

export const PantsTypes = ["pants-armor", "pants", "pantaloons", "legion", "skirts"] as const

export type Pants = (typeof PantsTypes)[number]

export const SexPants: Record<Sex, Pants[]> = {
    male: ["pants-armor", "pants", "pantaloons", "legion", "skirts"],
    female: ["pants-armor", "pants", "legion", "skirts"],
}

export const PantsColors: Record<Pants, Color[]> = {
    "pants-armor": ["gray", "metal", "yellow", "gold", "tan", "orange"],
    pants: ["black", "blue", "brown", "gray", "green", "maroon", "orange", "purple", "red", "tan", "white", "yellow"],
    pantaloons: [
        "black",
        "blue",
        "brown",
        "gray",
        "green",
        "maroon",
        "orange",
        "purple",
        "red",
        "tan",
        "white",
        "yellow",
    ],
    skirts: ["brown", "black", "white"],
    legion: ["brown"],
}

export const ShirtTypes = [
    "blouse",
    "blouse_longsleeve",
    "corset",
    "longsleeve",
    "longsleeve_laced",
    // "robe",
    "scoop_shirt",
    "sleeveless",
    "sleeveless_laced",
    "sleeveless_striped",
    "tanktop",
    "tunic",
] as const

export type Shirt = (typeof ShirtTypes)[number]

export const SexShirts: Record<Sex, Shirt[]> = {
    male: ["longsleeve", "longsleeve_laced", "sleeveless", "sleeveless_laced", "sleeveless_striped"],
    female: [
        "blouse",
        "blouse_longsleeve",
        "corset",
        "longsleeve",
        // "robe",
        "scoop_shirt",
        "tanktop",
        "tunic",
    ],
}

const commonShirtColors: Color[] = ["black", "blue", "green", "maroon", "purple", "red", "teal", "white", "yellow"]

export const ShirtColors: Record<Shirt, Color[]> = {
    blouse: [...commonShirtColors, "tan", "gray", "orange"],
    blouse_longsleeve: [...commonShirtColors, "tan", "gray", "orange"],
    corset: [...commonShirtColors, "brown", "tan", "gray", "orange"],
    longsleeve: [...commonShirtColors],
    longsleeve_laced: [...commonShirtColors, "tan", "gray", "orange"],
    // "robe": [
    //     "black",
    //     "blue",
    //     "lightbrown",
    //     "brown",
    //     "lightgreen",
    //     "gray",
    //     "purple",
    //     "red",
    //     "white"
    // ],
    scoop_shirt: [...commonShirtColors, "gray", "orange"],
    sleeveless: [...commonShirtColors, "brown", "tan", "gray", "orange"],
    sleeveless_laced: [...commonShirtColors, "brown", "tan", "gray", "orange"],
    sleeveless_striped: [...commonShirtColors, "brown", "tan", "gray", "orange"],
    tanktop: [...commonShirtColors, "tan", "gray", "orange"],
    tunic: ["brown", "maroon", "teal", "white"],
}

export const ArmorTypes = ["armor-plate", "armor-leather", "armor-legion"] as const

export type Armor = (typeof ArmorTypes)[number]

export const ArmorColors: Record<Armor, Color[]> = {
    "armor-plate": [
        "gray", // 11
        "metal", //12
        "yellow", //13
        "gold", //14
        "orange", //16
        "tan", //special
    ],
    "armor-leather": ["blue", "brown", "green"],
    "armor-legion": [
        "brown", // bronze
        "gray", // steel
        "gold",
    ],
}

export const ShoeTypes = ["boots", "shoes", "shoes-armor", "slippers", "sandals"] as const

export type Shoe = (typeof ShoeTypes)[number]

export const SexShoes: Record<Sex, Shoe[]> = {
    male: ["shoes-armor", "boots", "sandals", "shoes"],

    female: ["shoes-armor", "boots", "sandals", "shoes", "slippers"],
}

export const ShoeColors: Record<Shoe, Color[]> = {
    "shoes-armor": [
        "gray", // 4 ->
        "metal",
        "yellow",
        "gold",
        "tan",
        "orange", // 9
    ],
    shoes: ["black", "brown", "maroon"],
    sandals: ["brown"],
    boots: [
        "black",
        "blue",
        "brown",
        "gray",
        "green",
        "maroon",
        "orange",
        "purple",
        "red",
        "tan",
        "teal",
        "white",
        "yellow",
    ],
    slippers: ["black", "brown", "gray"],
}

export const ShieldTypes = [
    "shield-crusader",
    "shield-spartan",
    "shield-round",
    "shield-kite",
    "shield-kite_blue",
    "shield-kite_gray",
    "shield-kite_green",
    "shield-kite_red",
] as const

export type Shield = (typeof ShieldTypes)[number]

export const ShieldColors: Record<Shield, Color[]> = {
    "shield-crusader": ["white"],
    "shield-spartan": ["brown"],
    "shield-round": ["black", "brown", "gold", "green", "silver", "yellow"],
    "shield-kite": ["gray", "orange"],
    "shield-kite_blue": ["blue", "gray"],
    "shield-kite_gray": ["blue", "gray", "green", "orange"],
    "shield-kite_green": ["gray"],
    "shield-kite_red": ["gray"],
}

export const CapeTypes = ["cape-solid", "cape-tattered", "cape-trimmed"] as const

export type Cape = (typeof CapeTypes)[number]

export const SexCapes: Record<Sex, Cape[]> = {
    male: ["cape-solid"],
    female: ["cape-tattered", "cape-trimmed"],
}

export const CapeColors: Record<Cape, Color[]> = {
    "cape-solid": ["black", "blue", "brown", "gray", "green", "maroon", "red", "white", "yellow"],
    "cape-tattered": ["black", "blue", "brown", "gray", "green", "maroon", "red", "white", "yellow"],
    "cape-trimmed": ["white"],
}

export const KeyColors: Record<Key, Color[]> = {
    castlekey: ["gold"],
}

export const BauldronColors: Record<Bauldron, Color[]> = {
    bauldron: ["brown"],
}
export type ActionType =
    | "walk"
    | "idle"
    | "thrust"
    | "slash"
    | "shoot"
    | "hurt"
    | "activate"
    | "deactivate"
    | "active"
    | "inactive"
