import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { AnimatedFeedbackParams, Coordinate, Entity, randomIntBetween, TileSize } from "game-common/models"
import { Callback, degrees_to_radians } from "game-common/util"
import { AnimatedSprite, Container, Graphics, Loader, Rectangle, Sprite, Text, Texture } from "pixi.js"

import { EntityInteractionCallback } from "../../../../client_game_logic"
import { SpriteMeta } from "../entity_rendering_manager"
import { WorldOverlayItem } from "../world_overlay_surface"
import { BaseRenderable } from "./base_renderable"
import { Interactions } from "./interactions"
import { TileTextureExtractor } from "./tile_texture_extractor"
import { TextRenderer } from "../text/text_renderer"
import { AnimatedFeedback } from "../effect/animated_feedback"
import { FeedbackContainer } from "./lpc_composite"

class InteractionsContainer extends Graphics {
    interactions: Interactions

    constructor(parentArea: BaseRenderable, interactionCallback?: EntityInteractionCallback) {
        super()

        this.interactions = new Interactions(0, 0, parentArea, interactionCallback)
        this.addChild(this.interactions.interactionWidgetsContainer)
    }
}

export class BasicSprite extends BaseRenderable {
    currentColor: number
    animation: AnimatedSprite
    sprite: Sprite
    labelText: Text

    container: Container
    rotationInterval: any
    private originalColor: number
    private worldOverlayItem: WorldOverlayItem
    private interactionsContainer: InteractionsContainer
    private clickArea: BaseRenderable
    private _scale: number
    private supportLabel: boolean
    private spriteId: string
    private scaleValue: number
    private spriteMeta: SpriteMeta
    private animatedFeedback: AnimatedFeedback
    private runningFeedback: Map<string, FeedbackContainer> = new Map()

    constructor(spriteMeta: SpriteMeta, interactionCallback?: EntityInteractionCallback) {
        super()

        this.spriteMeta = spriteMeta
        const {
            color = 0xffffff,
            name,
            scale = 1.0,
            alpha = 1.0,
            animated,
            animationSpeed = 0.167,
            rotating,
            looping = true,
            rotation,
            onLoopDone,
            supportLabel,
        } = spriteMeta

        this.spriteId = name
        this.supportLabel = supportLabel
        this.scaleValue = scale

        if (interactionCallback) {
            this.clickArea = new BaseRenderable()
            this.interactionsContainer = new InteractionsContainer(this.clickArea, interactionCallback)

            if (this.clickArea.parent) {
                this.clickArea.parent.removeChild(this.parentArea)
            }
            this.clickArea.clear()
            this.clickArea.beginFill(0xff0000, 0.0001)
            this.clickArea.drawRect(0, 0, 32, 32)
            this.clickArea.x = -32 / 2
            this.clickArea.y = -32 / 2 + TileSize * 0.15
            this.clickArea.endFill()
            this.addChild(this.clickArea)
        }
        this.container = new Graphics()
        this.addChild(this.container)

        this.originalColor = color
        this.currentColor = color

        const loader = Loader.shared

        let resource = loader.resources["humanoid"]

        const isAnimated = animated !== undefined ? animated : !!resource.spritesheet.animations[name]

        this._scale = scale

        if (isAnimated) {
            this.animation = new AnimatedSprite(resource.spritesheet.animations[name])
            this.animation.scale.set(0.35)

            // set speed, start playback and add it to the stage
            this.animation.animationSpeed = animationSpeed
            this.animation.tint = color
            this.animation.alpha = alpha
            this.animation.play()
            this.animation.scale.set(scale)
            this.container.addChild(this.animation)

            if (!looping) {
                this.animation.loop = false
                if (onLoopDone) {
                    this.animation.onComplete = onLoopDone
                }
            }
        } else {
            let tileIdToUse = name
            let texture
            if (name.includes(":")) {
                const [resourceName, tileId] = name.split(":")
                tileIdToUse = `${resourceName}-${tileId}`
                resource = Loader.shared.resources[resourceName]

                const tileset = spriteMeta?.tileMapMetaProvider?.getTilesetById(resourceName)

                if (tileset) {
                    const tilesetTileMeta = tileset.tiles[Number(tileId) - 1]
                    if (tilesetTileMeta) {
                        const compositeTileId = tilesetTileMeta.compositeTileId
                        if (compositeTileId) {
                            texture = TileTextureExtractor.instance.extract(
                                spriteMeta.tileMapMetaProvider,
                                tileset.tilesetId,
                                compositeTileId,
                            )
                        }
                    }
                }
            }

            if (!texture) {
                texture = resource?.textures[tileIdToUse]
            }

            if (texture) {
                this.sprite = new Sprite(texture)
                if (name.includes(":")) {
                    this.sprite.transform.position.x -= texture.width / 2
                    this.sprite.transform.position.y -= 14
                }
                this.sprite.tint = color
                this.sprite.scale.set(scale)
                this.container.addChild(this.sprite)
            }
        }

        this.labelText = new TextRenderer().size("tiny").color(0x00ff00).render() as Text
        this.labelText.visible = false
        this.labelText.x = -this.container.width * 0.23
        this.labelText.y = -this.container.height * 0.7
        this.addChild(this.labelText)

        if (rotation !== undefined) {
            this.container.angle = rotation
        }
        if (rotating) {
            this.startRotation()
        }

        this.animatedFeedback = new AnimatedFeedback()
    }

    updateScale = (scale: number) => {
        if (this.animation) {
            this.animation.scale.set(scale)
        }
        if (this.sprite) {
            this.sprite.scale.set(scale)
        }
    }
    startRotation = () => {
        let angle = 0
        this.rotationInterval = setInterval(() => {
            if (angle > 360) {
                angle = 0
            }
            this.container.angle = angle
            angle++
        }, 50)
    }

    updateRotating = (rotating: boolean) => {
        if (!rotating) {
            if (this.rotationInterval) {
                clearInterval(this.rotationInterval)
                this.container.angle = 0
            }
        } else {
            this.startRotation()
        }
    }

    getCurrentColor = () => this.currentColor

    recolor = (color: number) => {
        if (this.currentColor === color) {
            return
        }
        this.currentColor = color
        if (this.animation) {
            this.animation.tint = color
        }
        if (this.sprite) {
            this.sprite.tint = color
        }
    }

    updateName(name: string) {
        if (!this.supportLabel) {
            return
        }
        if (name) {
            this.labelText.text = name.replace(/ /g, "\n")
            this.labelText.visible = true
        } else {
            this.labelText.visible = false
        }
    }

    getClickSurface = (): BaseRenderable => {
        if (this.interactionsContainer) {
            return this.clickArea
        }

        return super.getClickSurface()
    }

    onMouseOver = (show: boolean) => {
        if (this.interactionsContainer?.interactions) {
            this.interactionsContainer.interactions.interactionWidgetsContainer.visible = show
        }
    }

    updateInteractions(interactions: InteractionType[]): void {
        this.interactionsContainer?.interactions.updateInteractions(interactions)
    }

    setWorldOverlayItem = (item: WorldOverlayItem) => {
        this.worldOverlayItem = item
        if (this.interactionsContainer) {
            this.worldOverlayItem.addChild(this.interactionsContainer)
        }
    }

    fragment(_: Entity, callback: Callback<void>) {
        const surface = new Graphics()
        surface.scale.set(this._scale)
        surface.rotation = this.container.rotation
        this.parent.addChild(surface)

        const f: Texture = this.animation
            ? (this.animation.textures[this.animation.currentFrame] as any)
            : this.sprite?.texture

        const { x: origX, y: origY, width, height } = f._frame

        surface.x -= width / 2
        surface.y -= height / 2

        const divisor = height < 50 || width < 50 ? 3 : randomIntBetween(10, 20)

        let awaitedCount = 0
        const cleaner = () => {
            if (awaitedCount < 1) {
                this.parent?.removeChild(surface)
            }
        }

        const total = Math.floor(height / divisor) * Math.floor(width / divisor)
        let count = 0

        for (let y = 0; y < Math.floor(height / divisor); y++) {
            for (let x = 0; x < Math.floor(width / divisor); x++) {
                const fragX = origX + x * divisor
                const fragY = origY + y * divisor
                const fragWidth = divisor
                const fragHeight = divisor

                const frag = new Texture(
                    f.baseTexture,
                    new Rectangle(fragX, fragY, fragWidth, fragHeight),
                    undefined,
                    undefined,
                    f.rotate,
                    f.defaultAnchor,
                )

                const fragSprite = new Sprite(frag)
                fragSprite.x = x * divisor
                fragSprite.y = y * divisor
                // fragSprite.scale.set(1.5)

                surface.addChild(fragSprite)

                const angle = degrees_to_radians(randomIntBetween(0, 360))

                const speed2: Coordinate = {
                    x: Math.cos(angle),
                    y: Math.sin(angle),
                }

                let rotation = 0
                let startTs = Date.now()
                let changedColor1
                let changedColor2

                awaitedCount++

                const interval = setInterval(() => {
                    fragSprite.x += speed2.x * 10
                    fragSprite.y += speed2.y * 10
                    fragSprite.alpha -= 0.1

                    rotation += 1
                    if (rotation > 360) {
                        rotation = 0
                    }

                    fragSprite.angle = rotation

                    if (fragSprite.alpha < 0.7 && !changedColor1) {
                        fragSprite.tint = 0xffc0c0
                        changedColor1 = true
                    }

                    if (fragSprite.alpha < 0.5 && !changedColor2) {
                        fragSprite.tint = 0xff0000
                        changedColor2 = true
                    }

                    if (Date.now() - startTs > 500) {
                        try {
                            if (fragSprite && fragSprite.parent) {
                                fragSprite.parent.removeChild(fragSprite)
                                // fragSprite.texture.destroy(true)
                            }
                        } catch (e) {
                            console.log(e)
                        }
                        clearInterval(interval)
                        awaitedCount--
                        cleaner()
                    }
                }, 50)
                count++
                if (count >= total && callback) {
                    callback()
                }
            }
        }
    }

    updateSpriteId(spriteId: string) {
        if (!spriteId) {
            return
        }
        if (this.spriteId === spriteId) {
            // noop, it has changed already
            return
        }

        // todo ... this only really supports non-animated sprites

        if (this.sprite) {
            this.container.removeChild(this.sprite)
        }
        if (this.animation) {
            this.container.removeChild(this.animation)
        }

        this.spriteId = spriteId

        let resource = Loader.shared.resources["humanoid"]
        let tileIdToUse = spriteId
        if (spriteId.includes(":")) {
            const [resourceName, tileId] = spriteId.split(":")
            tileIdToUse = `${resourceName}-${tileId}`
            resource = Loader.shared.resources[resourceName]
        }

        if (resource) {
            const isAnimated = !!resource.spritesheet.animations[spriteId]
            if (isAnimated) {
                const {
                    color = 0xffffff,
                    scale = 1.0,
                    alpha = 1.0,
                    animationSpeed = 0.167,
                    looping = true,
                    onLoopDone,
                } = this.spriteMeta
                this.animation = new AnimatedSprite(resource.spritesheet.animations[spriteId])
                this.animation.scale.set(0.35)

                // set speed, start playback and add it to the stage
                this.animation.animationSpeed = animationSpeed
                this.animation.tint = color
                this.animation.alpha = alpha
                this.animation.play()
                this.animation.scale.set(scale)
                this.container.addChild(this.animation)

                if (!looping) {
                    this.animation.loop = false
                    if (onLoopDone) {
                        this.animation.onComplete = onLoopDone
                    }
                }
            } else {
                this.sprite = new Sprite(resource.textures[tileIdToUse])
                this.sprite.tint = this.currentColor
                this.sprite.scale.set(this.scaleValue)
                this.container.addChild(this.sprite)
            }
        }
    }

    getYOffset() {
        if (this.spriteId.includes("-exact")) {
            return 0
        }

        return super.getYOffset()
    }

    // todo - DRY THIS UP
    feedback(entity: Entity, image: string, label: string, params?: AnimatedFeedbackParams) {
        console.log("Feedback")
        const { type, action } = params
        if (action === "remove") {
            const feedbackContainer = this.runningFeedback.get(type)
            if (feedbackContainer) {
                clearTimeout(feedbackContainer.interval)
                console.log("removing", feedbackContainer.jobs.length)
                feedbackContainer.jobs.forEach(job => this.animatedFeedback?.remove?.(job))
            } else {
                console.log("no feedback container")
            }
            this.runningFeedback.delete(type)
        } else {
            if (type === "foot") {
                // Immediately invoke the function for the first iteration
                this.scheduleFeedbackJob(image, label, params, type)
                const container = this.runningFeedback.get(type)

                let i = 1
                let startTs = Date.now()
                const job = () => {
                    if (Date.now() - startTs > 3000) {
                        // timeout
                        console.log("feedback job timeout!")
                        return
                    }
                    console.log("job count", i)
                    i++
                    this.scheduleFeedbackJob(image, label, params, type)
                    container.interval = setTimeout(job, 75)
                }
                container.interval = setTimeout(job, 75)
            } else {
                this.animatedFeedback.setup(
                    0,
                    this.worldOverlayItem.height / 2,
                    image,
                    label,
                    this.worldOverlayItem,
                    params,
                    this.sprite as any,
                )
            }
        }
    }

    private scheduleFeedbackJob(image: string, label: string, params: AnimatedFeedbackParams, type: string): void {
        const job = this.animatedFeedback.setup(
            0,
            this.worldOverlayItem.height / 2,
            image,
            label,
            this.worldOverlayItem,
            {
                ...params,
                immediate: true,
            },
        )

        let container = this.runningFeedback.get(type)
        if (!container) {
            container = {
                interval: null, // This will be potentially updated later
                jobs: [],
            }
            this.runningFeedback.set(type, container)
        }
        container.jobs.push(job)
    }
}
