import {
    Armor,
    Cape,
    Color,
    Eyes,
    EyesColors,
    Hair,
    HairColors,
    HairTypes,
    HumanoidRaceTypes,
    Nose,
    NoseColors,
    Pants,
    Race,
    RaceColors,
    RaceEyes,
    RaceNoses,
    RaceSexes,
    Sex,
    SexHair,
    Shield,
    Shirt,
    Shoe,
    Weapon,
} from "../character/character"
import { computeInventoryAppearanceHash, ItemTypeMeta } from "../item/item"
import { Bauldron } from "../item/item_type"
import { Appearance, Entity } from "../models"
import { randomOneOf } from "../util"

export interface LpcBodyDescriptor {
    race: Race
    sex: Sex
    color: Color
}

export interface LpcComponentDescriptor<T> {
    component: T
    color: Color
}

export type LpcSheetID = string

export type LpcSheetType = "primary" | "oversize"

export interface LpcSheet {
    entityId: LpcSheetID
    zIndex: number
    type?: LpcSheetType
}

export type LpcSheetCollection = LpcSheet[]

export type LpcSpriteComponents =
    | "body"
    | "hair"
    | "weapon"
    | "pants"
    | "shirt"
    | "armor"
    | "shoes"
    | "shield"
    | "cape"
    | "bauldron"
    | "nose"
    | "eyes"

export type LpcSpritesheetDescriptors = Partial<Record<LpcSpriteComponents, LpcSheetCollection>>

export interface LpcCharacterDescriptorCollection {
    body?: LpcBodyDescriptor
    weapon?: LpcComponentDescriptor<Weapon>
    hair?: LpcComponentDescriptor<Hair>
    pants?: LpcComponentDescriptor<Pants>
    shirt?: LpcComponentDescriptor<Shirt>
    armor?: LpcComponentDescriptor<Armor>
    shoes?: LpcComponentDescriptor<Shoe>
    shield?: LpcComponentDescriptor<Shield>
    cape?: LpcComponentDescriptor<Cape>
    bauldron?: LpcComponentDescriptor<Bauldron>
    sex?: Sex
    color?: Color
    nose?: LpcComponentDescriptor<Nose>
    eyes?: LpcComponentDescriptor<Eyes>
}

export const toDescriptor = (itemTypeMeta: ItemTypeMeta): LpcComponentDescriptor<any> => {
    if (!itemTypeMeta) {
        return
    }
    return {
        component: itemTypeMeta.type,
        color: itemTypeMeta.color,
    }
}

export const randomAppearanceFor = (entity?: Entity, raceToUse?: Race, sexToUse?: Sex): Appearance => {
    const race = raceToUse || randomOneOf<Race>(HumanoidRaceTypes as any)
    const hair = randomOneOf<Hair>(HairTypes as any)
    const nose = randomOneOf<Nose>(RaceNoses[race] as any)
    const eyes = randomOneOf<Nose>(RaceEyes[race] as any)
    const sex = sexToUse || randomOneOf<Sex>(RaceSexes[race])
    return {
        race,
        bodyColor: randomOneOf<Color>(RaceColors[race]),
        hair: randomOneOf<Hair>(SexHair[sex]),
        hairColor: randomOneOf<Color>(HairColors[hair]),
        sex,
        nose,
        noseColor: randomOneOf<Color>(NoseColors[nose]),
        eyes,
        eyesColor: randomOneOf<Color>(EyesColors[eyes]),
        inventoryAppearanceHash: computeInventoryAppearanceHash(entity?.inventory),
    }
}

export type Age = "young" | "old"

export interface RandomAppearanceSpec {
    race?: Race
    sex?: Sex
    age?: Age
    bodyColor?: Color
    hair?: Hair | Hair[]
    hairColor?: Color | Color[]
    nose?: Nose | Nose[]
    noseColor?: Color | Color[]
    eyes?: Eyes | Eyes[]
    eyesColor?: Color | Color[]
}

export const createAppearance = (appearanceSpec: RandomAppearanceSpec): Appearance => {
    const pick = <T>(t: T | T[]) => {
        if (Array.isArray(t)) {
            return randomOneOf(t)
        }
        return t
    }

    const { race = "human", sex = "male", age = "young" } = appearanceSpec
    const eyes = randomOneOf<Nose>(RaceEyes[race] as any)
    let hairColor: Color
    let hair: Hair = pick(appearanceSpec.hair) || randomOneOf<Hair>(HairTypes as any)
    let nose: Nose = pick(appearanceSpec.nose) || randomOneOf<Nose>(RaceNoses[race] as any)
    if (age === "old" && !appearanceSpec.hairColor) {
        hairColor = randomOneOf(["gray", "white"])
    } else {
        hairColor = pick(appearanceSpec.hairColor)
    }
    if (age === "old" && sex === "male") {
        hair = "balding"
        nose = randomOneOf([undefined, "big"])
    }
    if (age === "old" && sex === "female") {
        // hair = "loose"
        hair = randomOneOf(["long_center_part", "loose", "shortknot"])
    }
    const bodyColor: Color = pick(appearanceSpec.bodyColor)

    return {
        race,
        bodyColor: bodyColor || randomOneOf<Color>(RaceColors[race]),
        hair,
        hairColor: hairColor || randomOneOf<Color>(HairColors[hair]),
        sex,
        nose: nose || randomOneOf<Nose>(RaceNoses[race] as any),
        noseColor: randomOneOf<Color>(NoseColors[nose]),
        eyes,
        eyesColor: randomOneOf<Color>(EyesColors[eyes]),
        inventoryAppearanceHash: "",
    }
}
