import { CombatMechanics } from "./combat_mechanics"
import { EffectMechanics } from "./effect_mechanics"
import { EntityMechanics } from "./entity_mechanics"
import { EntityTileMechanics } from "./entity_tile_mechanics"
import { ItemMechanics } from "./item_mechanics"
import { PhysicsMechanics } from "./physics_mechanics"
import { PopulationMechanics } from "./population_mechanics"
import { SocialMechanics } from "./social_mechanics"
import { QuestMechanics } from "./quest_mechanics"

export class Mechanics {
    static version: string = "1.0.0"

    static item = ItemMechanics
    static entity = EntityMechanics
    static entityTile = EntityTileMechanics
    static combat = CombatMechanics
    static social = SocialMechanics
    static physics = PhysicsMechanics
    static population = PopulationMechanics
    static effect = EffectMechanics
    static quest = QuestMechanics
}
