import { Graphics } from "pixi.js"
import { DyanmicTextContainer } from "../gui/dynamic_text_container"
import { BasicSprite } from "../sprites/basic_sprite"
import { AnimatedFeedbackParams, TileSize, randomBool, randomIntBetween } from "game-common/models"
import { Throbber } from "../gui/throbber"

export class AnimatedFeedback {
    private itemPickupQueueInterval: any
    private itemPickupQueue: any[] = []
    private isExecuting: boolean = false

    remove = (job: any) => {
        this.itemPickupQueue = this.itemPickupQueue.filter(n => n !== job)
    }

    setup(
        x: number,
        y: number,
        image: string,
        label: string,
        parent: Graphics,
        params: AnimatedFeedbackParams = {},
        spriteParent?: Graphics,
    ) {
        const job = () => {
            this.isExecuting = true

            if (params.type === "vibrate" && spriteParent) {
                // this affects the parent
                let startTs = Date.now()
                let shakeDirLeft = randomBool()
                // let shakeDistance = randomIntBetween(1, 1)
                let shakeDistance = 1
                let currentShakeDistance = shakeDistance
                let duration = 750
                let up: boolean = randomBool()
                let shakeOffsetX = 0
                let shakeOffsetY = 0

                let originalX = spriteParent.x
                let originalY = spriteParent.y

                const interval = setInterval(() => {
                    let elapsed = Date.now() - startTs
                    if (elapsed > duration) {
                        clearInterval(interval)
                        shakeOffsetX = 0
                        shakeOffsetY = 0

                        spriteParent.x = originalX
                        spriteParent.y = originalY

                        return
                    }

                    if ((elapsed / duration) % 0.25 === 0 || currentShakeDistance <= 0) {
                        shakeDirLeft = !shakeDirLeft
                        shakeDistance = shakeDistance - 1
                        currentShakeDistance = shakeDistance
                    }

                    currentShakeDistance -= 1
                    shakeOffsetX += shakeDirLeft ? 1 : -1

                    // this.updatePlayerLocation(entity)
                    // shakeOffsetY += up ? -1 : 1

                    spriteParent.x = originalX + shakeOffsetX
                    spriteParent.y = originalY + shakeOffsetY

                    up = !up
                }, 10)
            }

            if (image || label) {
                const sprite = new DyanmicTextContainer()

                if (label) {
                    sprite.append2(label, {
                        leftMargin: 0,
                        color: params?.color,
                        font: params?.font || "ChatFont",
                    })
                }

                if (image) {
                    const spriteImg = new BasicSprite({
                        color: 0xffffff,
                        scale: 2.4,
                        name: image,
                    })

                    sprite.append2(spriteImg, {
                        sameLine: true,
                        leftMargin: 8,
                    })
                }

                let throbber: Throbber
                if (params?.alignment === "center") {
                    sprite.x = parent.width / 2 + (label.length > 1 ? -(label.length * 11) : 0)
                    sprite.y = y - TileSize
                    throbber = new Throbber(sprite, {
                        blinkInterval: 10,
                    })

                    throbber.update()
                } else {
                    // default
                    sprite.x = -sprite.width / 4 + x
                    sprite.y = y
                }

                if (params.type === "foot") {
                    sprite.x = parent.x
                    sprite.y = parent.y + parent.height * 2 - sprite.height
                    parent?.parent?.addChild(sprite)
                } else {
                    parent.addChild(sprite)
                }

                const now = Date.now()
                const budget = params?.duration || 750
                const { variant = "default" } = params
                const scrollIncrement = (params?.scrollSpeed || 1.0) * 2
                const interval = setInterval(() => {
                    if (variant === "default") {
                        sprite.y -= scrollIncrement
                    }

                    const elapsed = Date.now() - now
                    const alpha = elapsed / budget
                    if (variant === "default") {
                        if (alpha > 0.7) {
                            sprite.alpha = 1.0 - alpha + 0.2
                        }
                    }
                    if (elapsed > budget) {
                        clearInterval(interval)
                        sprite.parent?.removeChild(sprite)
                        // sprite.remove()
                        this.isExecuting = false

                        if (this.itemPickupQueue.length < 1) {
                            clearInterval(this.itemPickupQueueInterval)
                            this.itemPickupQueueInterval = undefined
                        }
                        if (throbber) {
                            throbber.stop()
                        }
                    }
                }, 20)
            }
        }

        if (params.immediate) {
            job()
            return job
        }

        if (this.itemPickupQueueInterval) {
            clearInterval(this.itemPickupQueueInterval)
        }

        this.itemPickupQueueInterval = setInterval(() => {
            if (this.itemPickupQueue.length < 1) {
                clearInterval(this.itemPickupQueueInterval)
                return
            }

            const next = this.itemPickupQueue.shift()
            if (next) {
                next()
            }
        }, 50)

        if (!this.isExecuting) {
            job()
        } else {
            this.itemPickupQueue.push(job)
        }

        return job
    }
}
