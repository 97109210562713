export interface ObjectPoolItem<T> {
    data: T
    free: boolean
}

export abstract class ObjectPool<T> {
    items: ObjectPoolItem<T>[] = []
    ptr: number = -1
    releasing: boolean

    constructor(size: number = 1000) {
        this.grow(size)
    }

    abstract create(): ObjectPoolItem<T>

    abstract clear(item: ObjectPoolItem<T>): void

    private grow = (size: number = 1000) => {
        for (let i = 0; i < size; i++) {
            this.items.push(this.create())
        }
    }

    checkout = (): ObjectPoolItem<T> => {
        if (this.releasing) {
            alert("BAD!")
        }
        this.ptr++
        if (this.ptr > this.items.length - 1) {
            // grow the array
            this.grow(1000)
        }
        const item = this.items[this.ptr]
        item.free = false
        return item
    }

    releaseAll = (visitor: (item: ObjectPoolItem<T>) => void): number => {
        let count = 0
        this.releasing = true
        const now = Date.now()
        while (this.ptr > 0 && Date.now() - now < 2000) {
            const toRelease = this.items[this.ptr]
            visitor(toRelease)
            this.release(toRelease)
            count++
        }

        this.releasing = false
        return count
    }

    release = (item: ObjectPoolItem<T>) => {
        if (item.free) {
            // already free, noop
            return
        }

        item.free = true
        this.clear(item)

        this.ptr--
        if (this.ptr < 0) {
            this.ptr = 0
        }
    }
}
