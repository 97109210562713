import { Callback, Inventory, WeaponCharacteristics } from "game-common/models"
import { BitmapText, Graphics, Text } from "pixi.js"

import { SpriteMeta } from "../entity_rendering_manager"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { DynamicText } from "./dynamic_text"
import { TextRenderer } from "../text/text_renderer"

export class EquippedWidget extends Graphics {
    guiManager: GuiManager

    constructor(guiManager: GuiManager) {
        super()

        this.guiManager = guiManager
    }

    renderSlot = (label: string, spriteMeta: SpriteMeta, selected: boolean, callback: Callback, amt?: number) => {
        const slot = new Graphics()

        const item = new BasicSprite(spriteMeta)
        item.interactive = true
        item.on("pointerdown", callback)
        slot.addChild(item)

        if (amt !== undefined) {
            const amtLabel = new DynamicText(`${amt}`, { font: "ChatFont" })
            amtLabel.y = 5
            slot.addChild(amtLabel)
        }

        const labelObject = new TextRenderer().size("tiny").render(label) as Text
        labelObject.x = -20
        labelObject.y = -30
        if (selected) {
            labelObject.tint = 0x00ff00
            slot.beginFill(0x00ff00, 0.25)
            slot.drawCircle(0, 0, 15)
            slot.endFill()
        }
        slot.addChild(labelObject)

        return slot
    }

    update = (inventory: Inventory, slotCallback: (itemId: string) => void): Record<number, Callback> => {
        // clear all
        this.removeChildren()

        const baseMeta: SpriteMeta = {
            animated: false,
            color: 0xffffff,
            name: "",
            scale: 1.0,
        }

        const slotCallbacks: Record<number, Callback> = {}

        inventory.items
            ?.filter(item => WeaponCharacteristics[item.itemType]?.slotVisible && item.readied)
            // .sort( (a, b) => WeaponCharacteristics[a.itemType].equipOrder - WeaponCharacteristics[b.itemType].equipOrder)
            .map((item, i) => {
                const { itemType } = item
                const scale = 1
                const name = item.itemTypeMeta.spriteId || `${item.itemTypeMeta.type}-${item.itemTypeMeta.color}.png`
                const shortcutKey = `${i + 1}`
                const callback = () => slotCallback(item.id)

                const slot = this.renderSlot(
                    shortcutKey,
                    {
                        ...baseMeta,
                        color: item.active ? 0x00ff00 : baseMeta.color,
                        name,
                        scale,
                    },
                    item.active,
                    callback,
                    item.quantity > 0 ? item.quantity : undefined,
                )

                slotCallbacks[i + 1] = callback

                slot.x = i * 50
                this.addChild(slot)
            })

        return slotCallbacks
    }
}
