import { Coordinate, LightsContainer, ScreenDimensions, TileSize, Visibility } from "game-common/models"
import { getSightPolygon, pruneSegments, VectorLine } from "game-common/ray_casting"
import { Application, Container, filters, Graphics, Rectangle, Sprite, Texture } from "pixi.js"
import { ClientGameLogic } from "../../../client_game_logic"
import { TranslationComputer } from "./translation_computer"
import { ScalingParams } from "../../../client_util"

interface VisionOptions {
    polyMap: Array<VectorLine>
    location: Coordinate
    debug?: Graphics
    visibility?: Visibility
    lights?: LightsContainer
}

export class ShadowRendering {
    private lastLocation: Coordinate = { x: -1, y: -1 }
    private visibilityTexture: Texture
    private visibilitySprite: Sprite
    private visibilityBounds: Rectangle
    private visibilitySurface: Graphics
    private visibilityLastUpdateTs?: number
    private app: Application
    private surface: Container
    private cleared: boolean = true
    private lightLastChangedTs?: number
    private latestDarkness: number
    private logic: ClientGameLogic
    private roomId: string
    private translationComputer = new TranslationComputer()

    constructor(app: Application, surface: Container, debug: boolean, logic: ClientGameLogic) {
        this.app = app
        this.surface = surface
        this.logic = logic

        const visibilitySurface = new Graphics()
        if (!debug) {
            visibilitySurface.filters = [new filters.BlurFilter(50)]
        }
        visibilitySurface.scale.set(ScalingParams.surfaceZoom)
        this.visibilitySurface = visibilitySurface
        this.translationComputer.noDelay = true
    }

    renderVisibility = (visionOptions: VisionOptions): boolean => {
        if (!this.prepare(visionOptions)) {
            return
        }

        this.render()
    }

    private prepare = ({ polyMap, location, visibility, debug, lights }: VisionOptions): boolean => {
        if (!this.logic.tilemapMetaProvider) {
            return
        }
        const darkness = visibility?.darkness
        const hasDarkness = darkness < 1.0
        const hasPolymap = polyMap?.length > 0

        if (this.roomId !== this.logic.tilemapMetaProvider?.roomId) {
            this.lastLocation = { x: -1, y: -1 }
            this.translationComputer.update(this.logic.tilemapMetaProvider)
            this.roomId = this.logic.tilemapMetaProvider.roomId
        }

        if (!hasPolymap && !hasDarkness && this.cleared === false) {
            this.translationComputer.reset()
            this.visibilitySurface.clear()
            this.cleared = true
            this.surface.mask = undefined
            if (this.visibilitySprite) {
                this.app.stage.removeChild(this.visibilitySprite)
            }
            this.lastLocation = { x: -1, y: -1 }

            return false
        }

        const lightsChanged = this.lightLastChangedTs !== lights?.lastUpdatedTs
        const playerLocationChanged = !(location.x === this.lastLocation.x && location.y === this.lastLocation.y)
        const darknessChanged = this.latestDarkness !== darkness

        if (!hasPolymap) {
            // there isn't a poly map. In this case, then we don't care about player location changes,
            // only pure darkness changes
            if (
                !hasDarkness ||
                (this.translationComputer.ready() && !darknessChanged && !playerLocationChanged && !lightsChanged)
            ) {
                return false
            }
        } else {
            // there's a poly map. in this case we care about player changes
            if (!playerLocationChanged && !lightsChanged) {
                if (this.visibilityLastUpdateTs === visibility?.lastUpdateTs) {
                    return false
                }
            }
        }

        if (this.visibilityLastUpdateTs !== visibility?.lastUpdateTs) {
            this.visibilityLastUpdateTs = visibility?.lastUpdateTs
        }

        this.lightLastChangedTs = lights.lastUpdatedTs
        this.latestDarkness = darkness
        this.cleared = false

        const g = this.visibilitySurface

        g.clear()

        const playerLocation = { x: location.x, y: location.y }

        this.translationComputer.compute(playerLocation)
        let { modifierX, modifierY, xStatus, yStatus } = this.translationComputer

        if (modifierX === undefined) {
            if (xStatus === "left") {
                modifierX = -180
            }
            if (xStatus === "right") {
                modifierX = -1330
            }
        }
        if (modifierY === undefined) {
            if (yStatus === "top") {
                modifierY = -80
            }
            if (yStatus === "bottom") {
                modifierY = -1420
            }
        }
        if (modifierX !== undefined) {
            // g.transform.position.x = ((-playerLocation.x + modifierX + ScreenDimensions.w / 2) * 2) - 32 * 11
            g.transform.position.x =
                (modifierX + ScreenDimensions.w / 2) * ScalingParams.surfaceZoom - 48 * ScalingParams.viewportX
        }

        if (modifierY !== undefined) {
            // g.transform.position.y = ((-playerLocation.y + modifierY + ScreenDimensions.h / 2) * 2) - 32 * 7
            g.transform.position.y =
                (modifierY + ScreenDimensions.h / 2) * ScalingParams.surfaceZoom - 48 * ScalingParams.viewportY
        }

        if (hasPolymap) {
            const vs = visibility?.range || 600

            // Top
            polyMap[0] = {
                a: { x: playerLocation.x - vs, y: playerLocation.y - vs },
                b: { x: playerLocation.x + vs, y: playerLocation.y - vs },
            }
            // Bottom
            polyMap[1] = {
                a: { x: playerLocation.x - vs, y: playerLocation.y + vs },
                b: { x: playerLocation.x + vs, y: playerLocation.y + vs },
            }
            // Left
            polyMap[2] = {
                a: { x: playerLocation.x - vs, y: playerLocation.y - vs },
                b: { x: playerLocation.x - vs, y: playerLocation.y + vs },
            }
            // Right
            polyMap[3] = {
                a: { x: playerLocation.x + vs, y: playerLocation.y - vs },
                b: { x: playerLocation.x + vs, y: playerLocation.y + vs },
            }

            const prunedSegments = pruneSegments(polyMap, playerLocation.x, playerLocation.y, vs, 3)

            let polygons = [getSightPolygon(prunedSegments, playerLocation.x, playerLocation.y)]
            if (debug) {
                // debug.transform.position.x = -playerLocation.x + 50 + ScreenDimensions.w / 2
                // debug.transform.position.y = -playerLocation.y + 50 + ScreenDimensions.h / 2

                debug.transform.position.x =
                    (-playerLocation.x + 50 + ScreenDimensions.w / 2) * 2 - 32 * ScalingParams.viewportX
                debug.transform.position.y =
                    (-playerLocation.y + 50 + ScreenDimensions.h / 2) * 2 - 32 * ScalingParams.viewportY

                debug.clear()
                const lineMap = prunedSegments.reduce((acc, x) => {
                    acc.push(x.a)
                    acc.push(x.b)
                    return acc
                }, [])

                debug.beginFill(0xff0000, 0.95)
                for (let point of lineMap) {
                    debug.drawCircle(point.x, point.y, 3)
                }
                debug.endFill()

                debug.beginFill(0x00ff00, debug ? 0.2 : 1)
                for (let polygon of polygons) {
                    for (let point of polygon) {
                        debug.drawCircle(point.x, point.y, 3)
                    }
                }
                debug.endFill()

                debug.beginFill(0xff0000, 0.2)
                for (let polygon of polygons) {
                    debug.drawPolygon(polygon)
                }
                debug.endFill()
            }

            g.beginFill(0xff0000, darkness)
            for (let polygon of polygons) {
                g.drawPolygon(polygon)
            }
            g.endFill()
        }

        if (darkness !== undefined) {
            g.beginFill(0xfffffff, hasPolymap ? 0.01 : darkness)
            // for now -- hardcode to large map size
            g.drawRect(
                0,
                0,
                this.logic.tilemapMetaProvider.width() * TileSize,
                this.logic.tilemapMetaProvider.height() * TileSize,
            )
            g.endFill()
        }

        if (darkness !== undefined && lights) {
            lights.iterate(light => {
                const {
                    location: { x, y },
                    intensity,
                    radius,
                } = light
                g.beginFill(0xffffff, intensity)
                g.drawCircle(x, y, radius)
                g.endFill()
            })
        }

        this.lastLocation.x = location.x
        this.lastLocation.y = location.y

        return true
    }

    private render = () => {
        this.visibilityTexture?.destroy(true)
        if (!this.visibilityBounds) {
            this.visibilityBounds = new Rectangle(0, 0, 27 * TileSize, 16 * TileSize)
        }
        this.visibilityTexture = this.app.renderer.generateTexture(this.visibilitySurface, {
            region: this.visibilityBounds,
        })
        if (!this.visibilitySprite) {
            this.visibilitySprite = new Sprite(this.visibilityTexture)
        } else {
            this.visibilitySprite.texture = this.visibilityTexture
        }
        this.surface.mask = this.visibilitySprite

        this.visibilitySprite.x = 0
        this.visibilitySprite.y = 0

        this.app.stage.addChild(this.visibilitySprite)
    }
}
