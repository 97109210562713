export class Protocol {
    static type_setEntityId: string = "0"
    static type_destroyEntityId: string = "1"
    static type_ping: string = "2"
    static type_struckEntityId: string = "3"
    static type_worldState: string = "4"
    static type_entityDisconnected: string = "5"
    static type_dropAction: string = "6"
    static type_screenMessage: string = "7"
    static type_detonate: string = "8"
    static type_chatMessage: string = "9"
    static type_resetAction: string = "10"
    static type_boostAction: string = "11"
    static type_aStarResult: string = "12"
    static type_aStarRequest: string = "14"
    static type_attack: string = "15"
    static type_killed: string = "16"
    static type_respawned: string = "17"
    static type_orientation: string = "18"
    static type_fadeInOut: string = "19"
    static type_fireWeapon: string = "20"
    static type_activateInventoryItem: string = "21"
    static type_entityEmp: string = "22"
    static type_hideRoomName: string = "23"
    static type_sendUpdateLocation: string = "24"
    static type_createLocalEntity: string = "25"
    static type_soundEffect: string = "26"
    static type_destroyLocalEntity: string = "27"
    static type_sendAnimateLocalEntityCollection: string = "28"
    static type_activateAbility: string = "29"
    static type_animateAbility: string = "30"
    static type_updateName: string = "31"
    static type_upgradeGuest: string = "32"
    static type_login: string = "33"
    static type_logActivity: string = "34"
    static type_playerEvent: string = "35"
    static type_showStatUpgradeUI: string = "36"
    static type_entityClicked: string = "37"
    static type_inventoryAction: string = "38"
    static type_requestEntityDetail: string = "39"
    static type_requestItemPickup: string = "40"
    static type_saveAppearance: string = "41"
    static type_mapDelta: string = "42"
    static type_entityTileEffect: string = "43"
    static type_dayPhase: string = "44"
    static type_startSession: string = "45"
    static type_updatePlayerEntityId: string = "46"
    static type_updateSquadState: string = "47"
    static type_requestEntityInteractions: string = "48"
    static type_updateEntityInteractions: string = "49"
    static type_updateEntityPointerIcon: string = "50"
    static type_buildCharacter: string = "51"
    static type_finalizeCharacter: string = "52"
    static type_itemPickedUp: string = "53"
    static type_showTutorial: string = "54"
    static type_updatePlayerBiomeColorMap: string = "55"
    static type_pulseInventoryItem: string = "56"
    static type_fragmentAnimation: string = "57"
    static type_entityFeedback: string = "58"
    static type_onDialogClose: string = "59"
    static type_sendVisibility: string = "60"
    static type_sendTeleported: string = "61"
    static type_locationIconEffect: string = "62"
    static type_updateAudio: string = "63"
    static type_submitFeedback: string = "64"
    static type_sendPleaseWait: string = "65"
    static type_requestPlayerLocations: string = "66"
    static type_activateWorldPoint: string = "67"
    static type_commitWorldEdits: string = "68"
    static type_redrawClient: string = "69"
    static type_getBuildingTilePreview: string = "70"
    static type_sendBuildingTilePreview: string = "71"
    static type_requestVisibility: string = "72"
    static type_builderAction: string = "73"
    static type_requestWorldMap: string = "74"
    static type_sendWorldMap: string = "75"
    static type_requestDropActiveItem: string = "76"
    static type_worldMapClicked: string = "77"
    static type_removeLocationIcon: string = "78"
    static type_addLocationIcon: string = "79"
    static type_startAttack: string = "80"
    static type_entityTileStrikeAt: string = "81"
    static type_attackComplete: string = "82"
    static type_updateActivation: string = "83"
}
