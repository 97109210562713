import { Graphics } from "pixi.js"

export class Meter extends Graphics {
    color: number
    lowColor?: number
    max: number
    barHeight: number

    constructor(color: number = 0x00ff00, max: number, lowColor?: number) {
        super()
        this.color = color
        this.lowColor = lowColor
        this.max = max
        this.barHeight = 6
    }

    update = (amt: number) => {
        const color = this.lowColor ? (amt < this.max / 2 ? 0xff0000 : this.color) : this.color

        this.clear()

        for (let i = 0; i < amt; i++) {
            super.lineStyle(0, color)
            super.beginFill(color)
            super.drawRoundedRect(i * 3, 2, 3, 2 + this.barHeight, 0)
            super.endFill()
        }

        super.lineStyle(1, color, 0.4)
        super.beginFill(color, 0.35)
        super.drawRoundedRect(-2, 0, this.max * 3 + 4, this.barHeight + 6, 2)
    }
}
// 0 - 3
// 6 - 9
// 12 - 15
// 24 - 27
// 30 - 33
