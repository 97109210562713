import { BaseRenderable } from "./base_renderable"

export class Bullet extends BaseRenderable {
    constructor(color: any = 0xff0000, alpha = 1.0) {
        super()

        super.beginFill(0xff0000, alpha)
        super.lineStyle(2, 0xff0000, alpha)
        super.drawRoundedRect(5, 0, 9, 1, 1)
        super.endFill()

        super.beginFill(0xffffff, 0.35)
        super.lineStyle(2, 0xffffff, 0.35)
        super.drawRoundedRect(2, 0, 4, 1, 1)
        super.endFill()

        super.beginFill(0xffffff, 0.1)
        super.lineStyle(2, 0xffffff, 0.1)
        super.drawRoundedRect(0, 0, 3, 1, 1)
        super.endFill()
    }
}
