import { CompositeTilemap } from "@pixi/tilemap"
import { CoordinatePool } from "game-common/coordinate_pool"
import { TileEffect } from "game-common/map/map"
import {
    Coordinate,
    Entity,
    EntityId,
    FragmentSpriteMeta,
    NumberedTileMeta,
    randomBool,
    randomIntBetween,
    ScreenDimensions,
    TileMap,
    TileSize,
    Visibility,
} from "game-common/models"
import {
    between,
    blankCoordinate,
    degrees_to_radians,
    fillCoordinate,
    isEqual,
    ResettableIterator,
    roughToFine,
    roughToFineCoordinates,
    setFineToRoughCoordinates,
    setRoughToFineCoordinates,
} from "game-common/util"
import { BitmapText, Container, filters, Graphics, Loader, Rectangle, Sprite, Texture } from "pixi.js"

import { TileMapMetaProvider } from "game-common/tile_map_meta_provider"
import { IMapRenderingManager, RenderResult } from "../../../client_models"
import { isMobile, ScalingParams } from "../../../client_util"
import { AnimatedFeedback } from "./effect/animated_feedback"
import { MinimapSurface } from "./minimap_surface"
import { SpritePool } from "./sprite_pool"
import { TileTextureExtractor } from "./sprites/tile_texture_extractor"
import { TranslationComputer } from "./translation_computer"
import { WorldOverlaySurface } from "./world_overlay_surface"
import { Fader } from "./gui/fader"
import { ClientGameLogic } from "../../../client_game_logic"
const seedrandom = require("seedrandom")

const screenshakeEnabled = true

interface CaptureGroup {
    x: number
    y: number
    tile: string
    context?: any
}

interface SurfaceObject {
    spriteSet: Sprite[]
    compositeTileId?: string
    tilesetId?: string
    flags?: Set<string>
}

export class MapRenderingManager implements IMapRenderingManager {
    private surface: Container
    private worldOverlaySurface: WorldOverlaySurface
    private skySurface: Container
    private tilemapRenderers: ResettableIterator<CompositeTilemap>
    private skyTilemapRenderer: CompositeTilemap
    private tilemapRendererList: CompositeTilemap[]
    private tilemapMetaProvider: TileMapMetaProvider
    private visibility?: Visibility
    private needsRender: boolean = false
    private miniMapSurface: MinimapSurface
    private mobile: boolean
    private shakeOffsetX: number
    private shakeOffsetY: number
    private isShaking: boolean
    private surfaceObjectsByKey: Map<string, SurfaceObject> = new Map()
    private compositeTileSpriteTextures: Record<string, Texture> = {}

    translationComputer: TranslationComputer = new TranslationComputer()
    private roomId: EntityId
    private RANDOM_NUMBER_GENERATOR
    private currentRoughCoordinates: Coordinate = blankCoordinate()
    private spritePool: SpritePool = new SpritePool(2000)
    private skySpriteSurfaceBelow: Container
    private skySpriteSurfaceAbove: Container
    private rngCache: Map<string, number> = new Map()
    private skyHidingAllowed: boolean = true
    private skyAlphFilter = new filters.AlphaFilter(1.0)
    private currentUnderSky: boolean = undefined
    private currentSkyFader: Fader
    private logic: ClientGameLogic

    static delayTil: number = 0
    constructor(
        surface: Container,
        skySurface: Container,
        miniMapSurface: MinimapSurface,
        worldOverlaySurface: WorldOverlaySurface,
        logic: ClientGameLogic,
    ) {
        this.surface = surface
        this.skySurface = skySurface
        this.miniMapSurface = miniMapSurface
        this.worldOverlaySurface = worldOverlaySurface
        this.shakeOffsetX = 0
        this.shakeOffsetY = 0
        this.isShaking = false
        this.logic = logic
    }

    init = () => {
        this.tilemapRendererList = [
            new CompositeTilemap(),
            new CompositeTilemap(),
            new CompositeTilemap(),
            new CompositeTilemap(),
            new CompositeTilemap(),
            new CompositeTilemap(),
        ]
        this.skyTilemapRenderer = new CompositeTilemap()
        this.tilemapRenderers = new ResettableIterator<CompositeTilemap>(this.tilemapRendererList)
        this.tilemapRendererList.forEach((renderer, i) => {
            this.surface.addChild(renderer)
        })
        this.skySpriteSurfaceAbove = new Graphics()
        this.skySpriteSurfaceBelow = new Graphics()
        this.skySurface.filters = [this.skyAlphFilter]
        this.skySurface.addChild(this.skySpriteSurfaceBelow)
        this.skySurface.addChild(this.skyTilemapRenderer)
        this.skySurface.addChild(this.skySpriteSurfaceAbove)
        this.mobile = isMobile()
    }

    attachTexture = (texture: Texture, surface?: Container): Sprite => {
        const spritePoolItem = this.spritePool.checkout()
        const sprite = spritePoolItem.data
        sprite.texture = texture
        const surfaceToUse = surface || this.surface
        surfaceToUse.addChild(sprite)
        spritePoolItem.data = sprite

        return sprite
    }

    overlay = () => this.worldOverlaySurface

    setStaticHidingAllowed = (allowed: boolean) => (this.skyHidingAllowed = allowed)

    update = (tilemapMetaProvider: TileMapMetaProvider, visibility?: Visibility) => {
        if (tilemapMetaProvider) {
            this.tilemapMetaProvider = tilemapMetaProvider
            if (this.roomId !== tilemapMetaProvider.roomId) {
                this.skyHidingAllowed = true
                this.roomId = tilemapMetaProvider.roomId
                // new room boundary has been crossed
                this.surfaceObjectsByKey.clear()
                this.RANDOM_NUMBER_GENERATOR = seedrandom(this.roomId)
                this.rngCache.clear()
                this.currentUnderSky = undefined
            }
            this.rerender()
            this.translationComputer.update(tilemapMetaProvider)
        }
        this.visibility = visibility
        if (this.visibility) {
            this.visibility.lastUpdateTs = Date.now()
        }
    }

    rerender = () => {
        this.needsRender = true
    }

    randomIntBetweenRng = (min: number, max: number, seed?: string) => {
        let randomFloat
        if (!seed) {
            randomFloat = this.RANDOM_NUMBER_GENERATOR ? this.RANDOM_NUMBER_GENERATOR() : Math.random()
        } else {
            const toUse = `${seed}-${min}-${max}-${this.roomId}`
            randomFloat = this.rngCache.get(toUse)
            if (randomFloat === undefined) {
                randomFloat = this.RANDOM_NUMBER_GENERATOR ? this.RANDOM_NUMBER_GENERATOR() : Math.random()
                this.rngCache.set(toUse, randomFloat)
            }
        }

        return Math.floor(randomFloat * (max - min + 1) + min)
    }

    pctChance = (pct: number, seed?: string) => {
        // min and max included
        return this.randomIntBetweenRng(0, 100, seed) >= 100 - pct
    }

    pertubation = (tileId: string, x: number, y: number, offset: number, type: string, range: number = 5) => {
        const key = `${type}-${tileId}-${x}-${y}-${range}-${offset}`
        return this.randomIntBetweenRng(-range, range, key)
    }

    renderCompositeTile = (
        tilemapMetaProvider: TileMapMetaProvider,
        parentTileId: string,
        compositeTileId: string,
        x: number,
        y: number,
        zIndex: number = 0,
    ) => {
        const key = `${parentTileId}-${x}-${y}`
        const parentTileMeta = tilemapMetaProvider.resolveTileMeta(parentTileId)

        //
        // dense microtiles
        //
        if (parentTileMeta.tileTypes.includes("dense")) {
            const set = []
            this.surfaceObjectsByKey.set(key, {
                spriteSet: set,
            })

            const fullTileId = this.tilemapMetaProvider.resolveTilesetTileId(parentTileId)
            let textTure = this.compositeTileSpriteTextures[fullTileId]
            if (!textTure) {
                if (parentTileMeta.spriteId) {
                    const loader = Loader.shared
                    let resource = loader.resources["humanoid"]
                    textTure = resource.textures[parentTileMeta.spriteId]
                } else {
                    textTure = Texture.from(`${fullTileId}`)
                    this.compositeTileSpriteTextures[fullTileId] = textTure
                }
            }

            const parentFine = roughToFineCoordinates({ x, y })

            const print = (offsetX: number = 0, offsetY: number = 0) => {
                const g = this.attachTexture(textTure)
                g.x =
                    parentFine.x +
                    offsetX +
                    (parentTileMeta.variant === "noisy"
                        ? this.randomIntBetweenRng(-5, 5, `print-${key}-${parentFine.x}-${offsetX}-${zIndex}`)
                        : 0)
                g.y =
                    parentFine.y +
                    offsetY +
                    (parentTileMeta.variant === "noisy"
                        ? this.randomIntBetweenRng(-5, 5, `print-${key}-${parentFine.y}-${offsetY}-${zIndex}`)
                        : 0)
                g.zIndex = 100 + g.y + TileSize / 2 + zIndex
                set.push(g)
            }

            const noiseChance = parentTileMeta.variant === "noisy" ? 80 : 99
            print()
            this.pctChance(noiseChance, `pct-${key}-${parentFine.x}-${parentFine.y}-${zIndex}`) &&
                print(-0.5 * TileSize, -0.5 * TileSize)
            this.pctChance(noiseChance, `pct-${key}-${parentFine.x}-${parentFine.y}-${zIndex}`) &&
                print(0.5 * TileSize, -0.5 * TileSize)
            this.pctChance(noiseChance, `pct-${key}-${parentFine.x}-${parentFine.y}-${zIndex}`) &&
                print(-0.5 * TileSize, 0.5 * TileSize)
            this.pctChance(noiseChance, `pct-${key}-${parentFine.x}-${parentFine.y}-${zIndex}`) &&
                print(0.5 * TileSize, 0.5 * TileSize)

            return
        }

        //
        // compositeTile
        //

        const tileset = tilemapMetaProvider.resolveTileSet(parentTileId)

        this.handleRenderCompositeTile(
            key,
            this.tilemapMetaProvider,
            compositeTileId,
            tileset.tilesetId,
            x,
            y,
            this.surface,
            zIndex,
        )
    }

    handleRenderCompositeTile = (
        key: string,
        tilemapMetaProvider: TileMapMetaProvider,
        compositeTileId: string,
        tilesetId: string,
        x: number = 0,
        y: number = 0,
        surface?: Container,
        extraZIndex?: number,
    ): Sprite[] => {
        let set: Sprite[] = this.surfaceObjectsByKey.get(key)?.spriteSet
        if (set) {
            return set
        }
        set = []

        const compositeTileMeta = tilemapMetaProvider.resolveCompositeTileIdTileMeta(compositeTileId, tilesetId)
        if (!compositeTileMeta) {
            return set
        }

        const parentTileSet = tilemapMetaProvider.getTilesetById(tilesetId)
        const parentTileMeta = compositeTileMeta.tileMeta

        //
        // compositeTile
        //
        const { width } = parentTileSet

        // find the parent tile location
        let parentTileSetX
        let parentTileSetY

        const tileNumber = compositeTileMeta.tileNumber - 1
        parentTileSetX = tileNumber % width
        parentTileSetY = Math.floor(tileNumber / width)

        const isDenseVertical = parentTileMeta.tileTypes.includes("denseVertical")
        if (isDenseVertical) {
            const count = this.pctChance(10, `dense-vertical-${key}-1`)
                ? this.pctChance(50, `dense-vertical-${key}-2`)
                    ? 1
                    : 3
                : 2
            const gradient = TileSize / count

            for (let i = 0; i < count; i++) {
                let verticalOffset = i * gradient
                const yPertubation = this.pertubation("_", x, y, i, `horizontalDensity`)

                const children: NumberedTileMeta[] = compositeTileMeta.children || []
                const parentTileIdNumber = compositeTileMeta.tileNumber

                let height = 0
                children.forEach(childTileMeta => {
                    const tileId = `${childTileMeta.tileNumber}`
                    const tileNumber = childTileMeta.tileNumber - 1
                    const tileSetX = tileNumber % width
                    const tileSetY = Math.floor(tileNumber / width)

                    const fullTileId = `${parentTileSet.tilesetId}-${tileId}`

                    const isParent = parentTileIdNumber === tileNumber
                    const childTileSetXOffset = tileSetX - parentTileSetX
                    const childTileSetYOffset = tileSetY - parentTileSetY
                    if (!isParent) {
                        height = Math.max(height, Math.abs(childTileSetYOffset))
                    }

                    let textTure = this.compositeTileSpriteTextures[`${fullTileId}`]
                    if (!textTure) {
                        textTure = Texture.from(`${fullTileId}`)
                        this.compositeTileSpriteTextures[`${fullTileId}`] = textTure
                    }
                    const g = this.attachTexture(textTure, surface)

                    const fineItem = CoordinatePool.instance.checkout()
                    const roughItem = CoordinatePool.instance.checkout()
                    const rough = roughItem.data
                    rough.x = x + childTileSetXOffset
                    rough.y = y + childTileSetYOffset
                    const fine = fineItem.data
                    setRoughToFineCoordinates(rough, fine)

                    g.x = fine.x - this.pertubation(tileId, fine.x, fine.y, i, `verticalDensity`, 1)
                    g.y = fine.y - yPertubation
                    g.zIndex =
                        100 +
                            roughToFineCoordinates({ x, y }).y -
                            verticalOffset -
                            yPertubation +
                            TileSize / 3 +
                            extraZIndex || 0

                    CoordinatePool.instance.release(roughItem)
                    CoordinatePool.instance.release(fineItem)

                    g.interactive = true
                    g.on("mouseover", () => {
                        this.logic.playerController.updateCompositeTile(x, y)
                    })
                    g.on("mouseout", () => {
                        this.logic.playerController.updateCompositeTile(-1, -1)
                    })

                    set.push(g)
                })
                if (height > 0 || parentTileMeta.renderZIdxOffset !== undefined) {
                    set.forEach(g => {
                        if (height > 1) {
                            g.y += TileSize
                            g.zIndex += TileSize
                        }
                        if (parentTileMeta.renderZIdxOffset) {
                            g.zIndex += parentTileMeta.renderZIdxOffset
                        }
                    })
                }
            }

            this.surfaceObjectsByKey.set(key, {
                spriteSet: set,
                compositeTileId,
                tilesetId,
            })
        } else {
            const fullTileId = `${parentTileSet.tilesetId}-${compositeTileId}`

            const texture = TileTextureExtractor.instance.extract(
                this.tilemapMetaProvider,
                parentTileSet.tilesetId,
                compositeTileId,
            )

            const bounds = TileTextureExtractor.compositeTileSpriteBounds[fullTileId]
            const children: NumberedTileMeta[] = compositeTileMeta.children || []
            let intersects = false
            for (let i = 0; i < children.length; i++) {
                const childTileMeta = children[i]
                const tileNumber = childTileMeta.tileNumber - 1
                const tileSetX = tileNumber % width
                const tileSetY = Math.floor(tileNumber / width)

                const childTileSetXOffset = tileSetX - parentTileSetX
                const childTileSetYOffset = tileSetY - parentTileSetY

                const xx = x + childTileSetXOffset
                const yy = y + childTileSetYOffset

                if (tilemapMetaProvider.isSkyTileAt(xx, yy)) {
                    intersects = true
                    break
                }
            }

            if (!intersects) {
                const compositeTileMeta = tilemapMetaProvider?.resolveCompositeTileIdTileMeta(
                    compositeTileId,
                    tilesetId,
                )

                const surfaceToUse = surface || this.surface
                const containingSprite: Sprite = this.attachTexture(texture, surfaceToUse)
                const fine = roughToFineCoordinates({ x, y: y - bounds.height + 1 })

                containingSprite.x =
                    fine.x -
                    (bounds.width > 1 ? bounds.width * 0.25 * TileSize : 0) +
                    (compositeTileMeta?.tileMeta?.renderXOffset || 0)
                containingSprite.y = fine.y + (compositeTileMeta?.tileMeta?.renderYOffset || 0)
                containingSprite.zIndex =
                    100 + (extraZIndex || 0) + (fine.y + bounds.height * TileSize) - TileSize * 0.75

                set.push(containingSprite)
            } else {
                // find the parent tile location
                const tileNumber = compositeTileMeta.tileNumber - 1
                const parentTileSetX = tileNumber % width
                const parentTileSetY = Math.floor(tileNumber / width)

                const fineXX = roughToFine(x)
                const fineYY = roughToFine(y)

                children.forEach(childTileMeta => {
                    const tileId = `${childTileMeta.tileNumber}`
                    const tileNumber = childTileMeta.tileNumber - 1
                    const tileSetX = tileNumber % width
                    const tileSetY = Math.floor(tileNumber / width)

                    const fullTileId = `${parentTileSet.tilesetId}-${tileId}`
                    const childTileSetXOffset = tileSetX - parentTileSetX
                    const childTileSetYOffset = tileSetY - parentTileSetY

                    const xx = x + childTileSetXOffset
                    const yy = y + childTileSetYOffset

                    const skyAt = tilemapMetaProvider.isSkyTileAt(xx, yy)
                    const surfaceToUse = skyAt ? this.skySpriteSurfaceAbove : surface
                    const subtileSprite = this.attachTexture(Texture.from(fullTileId), surfaceToUse)
                    const fine = roughToFineCoordinates({ x: xx, y: yy })

                    const fineX = fine.x
                    const fineY = fine.y + (compositeTileMeta?.tileMeta?.renderYOffset || 0)

                    subtileSprite.x = fineX
                    subtileSprite.y = fineY

                    subtileSprite.zIndex = +(extraZIndex || 0) + (fineYY + bounds.height * TileSize) - TileSize

                    set.push(subtileSprite)

                    if (false) {
                        if (!skyAt) {
                            {
                                const g = new Graphics()
                                g.beginFill(0xfffffff)
                                g.drawCircle(16, 16, 5)
                                g.zIndex = 10000000
                                g.endFill()
                                g.x = fineXX
                                g.y = subtileSprite.zIndex - 100
                                surfaceToUse.addChild(g)
                            }

                            const g = new Graphics()
                            g.beginFill(0xff0000)
                            g.drawCircle(16, 16, 5)
                            g.zIndex = 10000000
                            g.endFill()
                            g.x = fineX
                            g.y = fineY
                            surfaceToUse.addChild(g)
                        } else {
                            const g = new Graphics()
                            g.beginFill(0x00ff00)
                            g.drawCircle(16, 16, 5)
                            g.zIndex = 10000000
                            g.endFill()
                            g.x = fineX
                            g.y = fineY
                            surfaceToUse.addChild(g)
                        }
                    }
                })
            }

            this.surfaceObjectsByKey.set(key, {
                spriteSet: set,
                compositeTileId,
                tilesetId,
            })
        }

        return set
    }

    renderSpriteFragment = (fragment: FragmentSpriteMeta) => {
        const { location, height, mapEntries, fineLocationNudge, debugTxt } = fragment

        if (debugTxt) {
            const debugTxtLocation = roughToFineCoordinates({ x: location.x, y: location.y + 0 })
            const debugTextObj = new BitmapText(debugTxt, {
                fontName: "SignFont",
                align: "center",
            })
            debugTextObj.x = debugTxtLocation.x
            debugTextObj.y = debugTxtLocation.y

            this.surface.addChild(debugTextObj)
            return
        }

        const key = `frag-${fragment.id}-${location.x}-${location.y}`
        const set = []
        this.surfaceObjectsByKey.set(key, {
            spriteSet: set,
        })

        const bottom = roughToFineCoordinates({ x: location.x, y: location.y + 0 })
        bottom.x += fineLocationNudge?.x || 0
        bottom.y += fineLocationNudge?.y || 0
        let maxX = undefined
        let maxY = undefined
        const debug = false
        const renderZIdxOffset = mapEntries[0].renderZIdxOffset || 0

        mapEntries.forEach(mapEntry => {
            const tile: string = `${mapEntry.tilePos}`
            const compositeTileId = this.tilemapMetaProvider.getCompositeTileId(`${mapEntry.tilePos}`)

            if (compositeTileId) {
                this.renderCompositeTile(
                    this.tilemapMetaProvider,
                    tile,
                    compositeTileId,
                    mapEntry.x,
                    mapEntry.y,
                    renderZIdxOffset + 200,
                )
            } else {
                const tilesetTileId = this.tilemapMetaProvider.resolveTilesetTileId(tile)
                const g = this.attachTexture(
                    Texture.from(`${tilesetTileId}`),
                    mapEntry.roofLayer === "below"
                        ? this.skySpriteSurfaceBelow
                        : mapEntry.roofLayer === "above"
                        ? this.skySpriteSurfaceAbove
                        : undefined,
                )
                const fine = roughToFineCoordinates({ x: mapEntry.x, y: mapEntry.y })
                fine.x += fineLocationNudge?.x || 0
                fine.y += fineLocationNudge?.y || 0
                g.x = fine.x
                g.y = fine.y
                g.zIndex = 100 + bottom.y - TileSize * 0.5
                set.push(g)

                if (debug) {
                    const f = new Graphics()
                    f.beginFill(0x00ff00)
                    f.drawCircle(0, 0, 5)
                    f.endFill()
                    f.x = fine.x
                    f.y = fine.y
                    f.zIndex = 100000
                    this.surface.addChild(f)
                }
                if (maxY === undefined || fine.y > maxY) {
                    maxY = fine.y
                }
                if (maxX == undefined) {
                    maxX = fine.x
                }
            }
        })

        if (maxX !== undefined && maxY !== undefined) {
            const z = maxY + TileSize * 0.35
            set.forEach(sprite => {
                sprite.zIndex = 100 + renderZIdxOffset + z
            })

            if (debug) {
                const f = new Graphics()
                f.beginFill(0xff0000)
                f.drawCircle(0, 0, 5)
                f.endFill()
                f.x = maxX
                f.y = z
                f.zIndex = 100000
                this.surface.addChild(f)
            }
        }
    }

    renderTileMap = () => {
        // if (true) return
        if (!this.needsRender || !this.tilemapMetaProvider) {
            return
        }
        console.log("rerender start...", this.spritePool.ptr)
        let errorCount = 0
        const releasedCount = this.spritePool.releaseAll(spriteItemObject => {
            const sprite = spriteItemObject.data
            if (!sprite.parent) {
                spriteItemObject.data = new Sprite()
                errorCount++
            } else {
                sprite.parent?.removeChild(sprite)
            }
        })
        console.log("released", releasedCount, "errors", errorCount)

        this.tilemapRendererList.forEach(renderer => renderer.clear())
        this.skyTilemapRenderer.clear()
        this.skySpriteSurfaceAbove.removeChildren()
        this.skySpriteSurfaceBelow.removeChildren()
        this.tilemapRenderers.reset()

        this.surfaceObjectsByKey.clear()
        const { visit } = this.tilemapMetaProvider

        visit(({ tilemap, sky, sprited, mapSpriteCaps = [], spriteFragments, index, layerId }) => {
            if (spriteFragments) {
                spriteFragments.forEach(fragment => {
                    this.renderSpriteFragment(fragment)
                })
            } else if (sprited) {
                const processBuffer = buffer => {
                    let toUse: CaptureGroup = undefined
                    let subBuffer = []
                    buffer.forEach((item: CaptureGroup, i) => {
                        const tilesetTileId = this.tilemapMetaProvider.resolveTilesetTileId(item.tile)
                        const mapSpriteCap = mapSpriteCaps.find(n => n.x === item.x && n.y === item.y)
                        console.log("mapSpriteCap", mapSpriteCap, tilesetTileId, item.tile)
                        const foundSplitter =
                            !!mapSpriteCap &&
                            (mapSpriteCap.context?.exclusive === undefined ||
                                mapSpriteCap.context?.exclusive == tilesetTileId)
                        toUse = foundSplitter ? item : undefined
                        try {
                            const texture = Texture.from(`${tilesetTileId}`)
                            const g = this.attachTexture(texture)
                            const fine = roughToFineCoordinates({ x: item.x, y: item.y })
                            g.x = fine.x
                            g.y = fine.y
                            g.zIndex = 100 + index + fine.y + TileSize / 3
                            subBuffer.push({
                                g,
                                item,
                            })

                            const surfaceId = item.context?.surfaceId
                            this.processSurfaceId(surfaceId, g)
                        } catch (e) {
                            console.log(e)
                        }
                        const isLast = i === buffer.length - 1
                        if (toUse || isLast) {
                            if (isLast && !toUse) {
                                // last one
                                toUse = buffer[buffer.length - 1]
                            }
                            const toUseFine = roughToFineCoordinates({ x: toUse.x, y: toUse.y })
                            const zIndex = 100 + index + toUseFine.y + TileSize / 3
                            subBuffer.forEach(n => (n.g.zIndex = zIndex))
                            subBuffer = []
                            toUse = undefined
                        }
                    })
                }
                // special handling
                for (let x = 0; x < this.tilemapMetaProvider.width(); x++) {
                    let buffer: CaptureGroup[] = []
                    for (let y = 0; y < this.tilemapMetaProvider.height(); y++) {
                        const tile = tilemap[x][y]
                        const tilesetTileId = this.tilemapMetaProvider.resolveTilesetTileId(tile)
                        if (tile !== "0" && !!tile && tilesetTileId !== "lpc-terrain-723") {
                            const delta = this.tilemapMetaProvider.getMapDeltaAt(x, y, layerId)
                            const context = delta?.context
                            buffer.push({
                                x,
                                y,
                                tile,
                                context,
                            })
                        } else {
                            if (buffer.length > 0) {
                                processBuffer(buffer)
                                buffer = []
                            }
                        }
                    }
                    if (buffer.length > 0) {
                        processBuffer(buffer)
                        buffer = []
                    }
                }
            } else {
                if (tilemap) {
                    const tilemapRender = sky ? this.skyTilemapRenderer : this.tilemapRenderers.next().value
                    for (let y = 0; y < this.tilemapMetaProvider.height(); y++) {
                        for (let x = 0; x < this.tilemapMetaProvider.width(); x++) {
                            this.renderAt(x, y, tilemap, sky ? "sky" : undefined, {
                                tilemapRender,
                            })
                        }
                    }
                }
            }
        })

        this.miniMapSurface.update(this.tilemapMetaProvider)

        this.needsRender = false
        // this.translationComputer.reset()
    }

    addTileAt = (x: number, y: number, tile: string, layerId: string): RenderResult => {
        // todo dry this up
        const mapping = this.tilemapMetaProvider.resolveLayerMapping(layerId)

        const tilesetTileId = this.tilemapMetaProvider.resolveTilesetTileId(tile)
        const texture = Texture.from(`${tilesetTileId}`)
        const g = this.attachTexture(texture)
        const fine = roughToFineCoordinates({ x, y })
        g.x = fine.x
        g.y = fine.y
        g.zIndex = 100 + (mapping?.idx || 0) + fine.y + TileSize / 3

        return {
            sprite: g,
        }
    }

    processSurfaceId = (surfaceId: string, spriteToReturn: Sprite) => {
        if (!surfaceId) {
            return
        }

        let surfaceObject: SurfaceObject = this.surfaceObjectsByKey.get(surfaceId)
        if (!surfaceObject) {
            surfaceObject = {
                spriteSet: [spriteToReturn],
            }
            this.surfaceObjectsByKey.set(surfaceId, surfaceObject)
        }
        surfaceObject.spriteSet.push(spriteToReturn)
    }

    buildSpriteFrom = (tilesetTileId: string, x: number, y: number, z: number) => {
        const texture = Texture.from(`${tilesetTileId}`)
        const g = this.attachTexture(texture)
        const fine = roughToFineCoordinates({ x, y })
        g.x = fine.x
        g.y = fine.y
        g.zIndex = 100 + z + fine.y + TileSize / 3

        return
    }

    renderAt = (x: number, y: number, tilemap: TileMap, layerId: string, options?: any) => {
        let { tilemapRender, wasReplaced, surfaceId } = options || {}

        tilemapRender = tilemapRender || this.tilemapRenderers.next().value
        const size = TileSize
        const _x = tilemap[x]
        if (!_x) {
            return
        }
        let spriteToReturn
        const tile = _x[y]
        if (tile && this.tilemapMetaProvider.shouldDrawTileAt(x, y, layerId, tilemap)) {
            if (!this.tilemapMetaProvider.isHiddenTile(tile)) {
                const compositeTileId = this.tilemapMetaProvider.getCompositeTileId(tile)
                if (compositeTileId) {
                    this.renderCompositeTile(this.tilemapMetaProvider, tile, compositeTileId, x, y)
                } else {
                    const tilesetTileId = this.tilemapMetaProvider.resolveTilesetTileId(tile)
                    if (layerId) {
                        const layerMapping = this.tilemapMetaProvider.resolveLayerMapping(layerId)
                        if (layerMapping.maptype === "sprited") {
                            // todo dry this up
                            const texture = Texture.from(`${tilesetTileId}`)
                            const g = this.attachTexture(texture)
                            const fine = roughToFineCoordinates({ x, y })
                            g.x = fine.x
                            g.y = fine.y
                            g.zIndex = 100 + fine.y + TileSize / 3
                            spriteToReturn = g

                            this.processSurfaceId(surfaceId, spriteToReturn)
                        }
                        if (layerMapping.maptype === "tilemap") {
                            this.tilemapRenderers.values[layerMapping.idx]?.tile(`${tilesetTileId}`, x * size, y * size)
                            if (wasReplaced) {
                                // todo -- this is too blunt... maybe there is a way to avoid full
                                // rerenders on pixijs tilemap renders
                                this.rerender()
                            }
                        }
                        if (layerMapping.maptype === "sky") {
                            const delta = this.tilemapMetaProvider.getMapDeltaAt(x, y, "sky")
                            if (delta?.collideable) {
                                // todo dry this up
                                const texture = Texture.from(`${tilesetTileId}`)
                                const g = this.attachTexture(texture, this.skySpriteSurfaceAbove)
                                const fine = roughToFineCoordinates({ x, y })
                                g.x = fine.x
                                g.y = fine.y
                                g.zIndex = 100 + fine.y + TileSize / 3
                                spriteToReturn = g

                                this.processSurfaceId(surfaceId || delta?.context?.surfaceId, spriteToReturn)
                            } else {
                                this.skyTilemapRenderer.tile(`${tilesetTileId}`, x * size, y * size)
                            }
                        }
                    } else {
                        tilemapRender?.tile(`${tilesetTileId}`, x * size, y * size)
                    }
                }
            }
        }
        return spriteToReturn
    }

    reset = () => {
        this.translationComputer.reset()
    }

    getVisibilityMap = () => (!this.visibility?.mask ? undefined : this.visibility.mask)

    getVisibility = (): Visibility | undefined => this.visibility

    updatePlayerLocation = (entity: Entity, force?: boolean) => {
        if (!entity) {
            return
        }

        // turn sky map layer on or off based on player position
        const item = CoordinatePool.instance.checkout()
        setFineToRoughCoordinates(entity.location, item.data)
        if (force || !isEqual(item.data, this.currentRoughCoordinates)) {
            fillCoordinate(item.data, this.currentRoughCoordinates)
            const isSkyTile = this.tilemapMetaProvider.isSkyTileAt(item.data.x, item.data.y)

            if (isSkyTile !== this.currentUnderSky) {
                if (isSkyTile && this.skyHidingAllowed) {
                    if (this.currentSkyFader) {
                        this.currentSkyFader.stop()
                    }

                    this.currentSkyFader = new Fader(this.skyAlphFilter, {
                        // fadeInterval: 15,
                        direction: "out",

                        releaseCallback: () => {
                            this.skySurface.visible = false
                            this.skyAlphFilter.alpha = 0.0
                        },
                    })
                    this.currentSkyFader.update()
                } else if (!this.skySurface.visible) {
                    if (this.currentSkyFader) {
                        this.currentSkyFader.stop()
                    }

                    this.skySurface.visible = true
                    this.skyAlphFilter.alpha = 0.0
                    this.currentSkyFader = new Fader(this.skyAlphFilter, {
                        fadeInterval: 1,
                        direction: "in",
                        minAlpha: 1.0,
                    })
                    this.currentSkyFader.update()
                }

                this.currentUnderSky = isSkyTile
            }
        }
        CoordinatePool.instance.release(item)

        if (
            (!force && this.needsRender) ||
            !this.tilemapMetaProvider ||
            entity.location.x < 0 ||
            entity.location.y < 0
        ) {
            return
        }

        // these modifiers tell us how to translate the screen surface X,y so that its visible to
        // the palyer
        this.translationComputer.compute(entity.location, force)
        const { modifierX, modifierY } = this.translationComputer

        if (modifierX !== undefined) {
            const transformedX =
                (this.shakeOffsetX + modifierX + ScreenDimensions.w / 2) * ScalingParams.surfaceZoom -
                48 * ScalingParams.viewportX
            this.surface.transform.position.x = transformedX
            this.worldOverlaySurface.transform.position.x = transformedX
        }
        if (modifierY !== undefined) {
            const transformedY =
                (this.shakeOffsetY + modifierY + ScreenDimensions.h / 2) * ScalingParams.surfaceZoom -
                48 * ScalingParams.viewportY
            this.surface.transform.position.y = transformedY
            this.worldOverlaySurface.transform.position.y = transformedY
        }
    }

    screenShake = (entity: Entity) => {
        if (!screenshakeEnabled) {
            return
        }
        if (this.isShaking) {
            return
        }
        this.isShaking = true
        let startTs = Date.now()
        let shakeDirLeft = randomBool()
        let shakeDistance = randomIntBetween(5, 8)
        let currentShakeDistance = shakeDistance
        let duration = randomIntBetween(40, 60)
        let up: boolean = randomBool()
        const interval = setInterval(() => {
            let elapsed = Date.now() - startTs
            if (elapsed > duration) {
                clearInterval(interval)
                this.shakeOffsetX = 0
                this.shakeOffsetY = 0
                this.isShaking = false

                this.updatePlayerLocation(entity)

                return
            }

            if ((elapsed / duration) % 0.25 === 0 || currentShakeDistance <= 0) {
                shakeDirLeft = !shakeDirLeft
                shakeDistance = shakeDistance - 1
                currentShakeDistance = shakeDistance
            }

            currentShakeDistance -= 1
            this.shakeOffsetX += shakeDirLeft ? 1 : -1
            this.updatePlayerLocation(entity)

            this.shakeOffsetY += up ? -1 : 1
            up = !up
        }, 10)
    }

    tileEffect = (effect: TileEffect) => {
        if (!effect.tileId && !effect.surfaceId) {
            // we need either the tileId or surfaceId to do an effect
            return
        }

        if (effect.effectType === "remove") {
            const { tileId, surfaceId, mapRoughCoordinates } = effect
            const { x, y } = mapRoughCoordinates
            const key = surfaceId || `${tileId}-${x}-${y}`
            const set = this.surfaceObjectsByKey.get(key)?.spriteSet
            if (set) {
                set.forEach(g => {
                    g.parent?.removeChild(g)
                })
            }
        }
        if (effect.effectType === "destroy") {
            const { tileId, surfaceId, mapRoughCoordinates } = effect
            const { x, y } = mapRoughCoordinates
            const key = surfaceId || `${tileId}-${x}-${y}`
            const surfaceObject = this.surfaceObjectsByKey.get(key)
            const set = surfaceObject?.spriteSet

            if (set) {
                set.forEach(g => {
                    this.fragment(g.texture, g.x, g.y)
                    g.parent?.removeChild(g)
                })

                const compositeTileMeta = this.tilemapMetaProvider.resolveCompositeTileIdTileMeta(
                    surfaceObject.compositeTileId,
                    surfaceObject.tilesetId,
                )
                const breakageMeta = compositeTileMeta?.tileMeta?.breakageMeta
                if (breakageMeta) {
                    breakageMeta.descriptors.forEach(descriptor => {
                        const key = `${descriptor.compositeTileId}-${x}-${y}`
                        this.surfaceObjectsByKey.delete(key)
                    })
                }
            }
        }
        if (effect.effectType === "damage") {
            const { tileId, surfaceId, mapRoughCoordinates } = effect
            const { x, y } = mapRoughCoordinates
            const key = surfaceId || `${tileId}-${x}-${y}`
            const surfaceObject = this.surfaceObjectsByKey.get(key)
            const set = surfaceObject?.spriteSet

            if (set) {
                let startTs = Date.now()
                let shakeDirLeft = randomBool()
                let shakeDistance = randomIntBetween(2, 4)
                let currentShakeDistance = shakeDistance
                let duration = randomIntBetween(40, 60)
                let shakeOffsetX = 0

                const original = set.map(g => ({
                    x: g.x,
                    y: g.y,
                }))

                const interval = setInterval(() => {
                    let elapsed = Date.now() - startTs
                    if (elapsed > duration) {
                        clearInterval(interval)
                        shakeOffsetX = 0

                        set.forEach((g, i) => {
                            g.x = original[i].x
                        })

                        const sorted = set.sort((a, b) => {
                            return a.y - b.y
                        })

                        const compositeTileMeta = this.tilemapMetaProvider.resolveCompositeTileIdTileMeta(
                            surfaceObject.compositeTileId,
                            surfaceObject.tilesetId,
                        )
                        const breakageMeta = compositeTileMeta?.tileMeta?.breakageMeta

                        if (breakageMeta) {
                            const damagePct = 1.0 - effect.hpPct
                            if (!surfaceObject.flags) {
                                surfaceObject.flags = new Set()
                            }

                            const breakSets: Sprite[][] = []
                            breakageMeta?.descriptors.forEach((descriptor, i) => {
                                const key = `${descriptor.compositeTileId}-${x}-${y}`
                                const applies = between(damagePct, descriptor.thresholdLow, descriptor.thresholdHigh)
                                if (applies) {
                                    if (!surfaceObject.flags.has(key)) {
                                        let breakset = this.handleRenderCompositeTile(
                                            key,
                                            this.tilemapMetaProvider,
                                            descriptor.compositeTileId,
                                            descriptor.tilesetId,
                                        )
                                        surfaceObject.flags.add(key)
                                        breakSets.push(breakset)
                                    }
                                }
                            })

                            if (breakSets.length > 0) {
                                breakSets.forEach(breakSet => {
                                    sorted.forEach((next, i) => {
                                        const renderable = breakSet[i]
                                        renderable.x = 0
                                        renderable.y = 0
                                        next.addChild(renderable)
                                    })
                                })
                            }
                        }

                        return
                    }

                    if ((elapsed / duration) % 0.25 === 0 || currentShakeDistance <= 0) {
                        shakeDirLeft = !shakeDirLeft
                        shakeDistance = shakeDistance - 1
                        currentShakeDistance = shakeDistance
                    }

                    currentShakeDistance -= 1
                    shakeOffsetX += shakeDirLeft ? 1 : -1
                    set.forEach((g, i) => {
                        if (!g) {
                            return
                        }
                        g.x = original[i].x + shakeOffsetX
                    })
                }, 10)

                if (effect.hpPct) {
                    const indicator = new AnimatedFeedback()
                    const location = this.worldOverlaySurface.computeLocation(x * TileSize, y * TileSize)

                    indicator.setup(
                        location.x + set[0].width / 2,
                        location.y,
                        undefined,
                        `${Math.ceil(effect.hpPct * 100)}%`,
                        this.worldOverlaySurface,
                    )
                }
            }
        }
    }

    // todo - DRY this with entity fragment
    fragment = (f: Texture, parentX: number, parentY: number) => {
        const surface = new Graphics()
        surface.x = parentX
        surface.y = parentY
        surface.scale.set(1.0)
        this.surface.addChild(surface)
        const pooledItems: Sprite[] = []

        const { x: origX, y: origY, width, height } = f._frame

        const divisor = height < 50 || width < 50 ? 3 : randomIntBetween(10, 20)

        let awaitedCount = 0
        let released = false
        const cleaner = () => {
            if (awaitedCount < 1 && !released) {
                released = true

                pooledItems.forEach(p => {
                    try {
                        if (p?.parent) {
                            p.parent.removeChild(p)
                            p.texture.destroy()
                        }
                    } catch (e) {
                        console.log(e)
                    }
                })

                surface.parent.removeChild(surface)
            }
        }

        for (let y = 0; y < Math.floor(height / divisor); y++) {
            for (let x = 0; x < Math.floor(width / divisor); x++) {
                const fragX = origX + x * divisor
                const fragY = origY + y * divisor
                const fragWidth = divisor
                const fragHeight = divisor

                const frag = new Texture(
                    f.baseTexture,
                    new Rectangle(fragX, fragY, fragWidth, fragHeight),
                    undefined,
                    undefined,
                    f.rotate,
                    f.defaultAnchor,
                )

                const fragSprite = new Sprite(frag)
                fragSprite.x = x * divisor
                fragSprite.y = y * divisor

                surface.addChild(fragSprite)
                pooledItems.push(fragSprite)

                const angle = degrees_to_radians(randomIntBetween(0, 360))

                const speed2: Coordinate = {
                    x: Math.cos(angle),
                    y: Math.sin(angle),
                }

                let rotation = 0
                let startTs = Date.now()
                let changedColor1
                let changedColor2

                awaitedCount++

                const interval = setInterval(() => {
                    fragSprite.x += speed2.x * 10
                    fragSprite.y += speed2.y * 10
                    fragSprite.alpha -= 0.1

                    rotation += 1
                    if (rotation > 360) {
                        rotation = 0
                    }

                    fragSprite.angle = rotation

                    if (fragSprite.alpha < 0.7 && !changedColor1) {
                        fragSprite.tint = 0xffc0c0
                        changedColor1 = true
                    }

                    if (fragSprite.alpha < 0.5 && !changedColor2) {
                        fragSprite.tint = 0xff0000
                        changedColor2 = true
                    }

                    if (Date.now() - startTs > 500) {
                        clearInterval(interval)
                        awaitedCount--
                        cleaner()
                    }
                }, 50)
            }
        }
    }

    updateBiomeColorMap = (biomeCoordinate: Coordinate, color: number) => {
        this.miniMapSurface.updateBiomeColorMap(biomeCoordinate, color)
    }

    updateMinimap = (tilemapMetaProvider: TileMapMetaProvider) => {
        this.miniMapSurface.update(tilemapMetaProvider)
    }

    removeLocationIcon = (iconId: string) => {
        this.miniMapSurface.removeLocationIcon(iconId)
    }
}
