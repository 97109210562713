
import { ItemClass } from "./item"
import { ItemTypes } from "./item_type"
export const ItemTypesWeight: Partial<Record<ItemClass, Partial<Record<ItemTypes, number>>>> = {
  "armor": {
    "armor-leather": 0.1,
    "armor-legion": 0.2,
    "armor-plate": 0.3
  },
  "bauldron": {
    "bauldron": 0.05
  },
  "cape": {},
  "key": {},
  "pants": {
    "legion": 0.1,
    "pants-armor": 0.1
  },
  "resource": {},
  "shield": {
    "shield-crusader": 0.2,
    "shield-kite": 0.2,
    "shield-kite_blue": 0.2,
    "shield-kite_gray": 0.2,
    "shield-kite_green": 0.2,
    "shield-kite_red": 0.2,
    "shield-round": 0.1,
    "shield-spartan": 0.15
  },
  "shirt": {},
  "shoes": {
    "shoes-armor": 0.1,
    "slippers": 0.01
  },
  "structure": {},
  "weapon": {
    "axe": 0.08,
    "bow": 0.05,
    "spear": 0.05,
    "staff": 0.05,
    "warhammer": 0.1
  }
}
