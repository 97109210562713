import { ItemTypes } from "game-common/item/item_type"

export const ItemSprites: Partial<Record<ItemTypes, string>> = {
    "coal": "ore:161",
    "copper": "ore:180",
    "feather": "ore:72",
    "flower-single-blue": "lpc-flowers-plants-fungi-wood:104",
    "flower-single-purple": "lpc-flowers-plants-fungi-wood:103",
    "flower-single-red": "lpc-flowers-plants-fungi-wood:105",
    "flower-single-yellow": "lpc-flowers-plants-fungi-wood:106",
    "gold": "ore:182",
    "iron": "ore:179",
    "metal-bar-copper": "ore:180",
    "metal-bar-gold": "ore:182",
    "metal-bar-iron": "ore:179",
    "metal-bar-silver": "ore:181",
    "mushroom-purple": "lpc-flowers-plants-fungi-wood:353",
    "mushroom-red": "lpc-flowers-plants-fungi-wood:355",
    "mushroom-yellow": "lpc-flowers-plants-fungi-wood:354",
    "planks": "ore:25",
    "rope": "ore:88",
    "seed": "seed-green.png",
    "silver": "ore:181",
    "stick": "ore:26",
    "straw": "lpc-flowers-plants-fungi-wood:212",
    "chest-wood": "container:140",
    "door-wood": "fence_medieval:478",
    "floor-wood": "floors:33",
    "roof-wood": "fence_medieval:468",
    "snare-rabbit": "ore:89",
    "stool-small": "furniture-blonde-wood:126",
    "table-large-rough": "furniture-blonde-wood:458",
    "table-mid-round": "furniture-blonde-wood:124",
    "wall-wood": "fence_medieval:410",
    "wall2-wood": "walls2:43",
    "barrel1": "container:60",
    "stackedBags": "container:449",
    "stool1": "furniture-blond-wood:128",
    "table-mid-rough": "furniture-blonde-wood:141",
    "strawBed": "build_atlas:684",
    "strawBedSmall": "build_atlas:684"
}