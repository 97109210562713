import { IKeyboard, KeyboardState } from "../../../../client_models"

type Listener = (key: string, down: boolean) => void
export class Keyboard implements IKeyboard {
    public static readonly state: Map<string, boolean> = new Map()
    public static listeners: Listener[] = []
    public static initialize() {
        // The `.bind(this)` here isn't necesary as these functions won't use `this`!
        document.addEventListener("keydown", Keyboard.keyDown)
        document.addEventListener("keyup", Keyboard.keyUp)

        document.addEventListener("mouseleave", event => {
            if (
                event.clientY <= 0 ||
                event.clientX <= 0 ||
                event.clientX >= window.innerWidth ||
                event.clientY >= window.innerHeight
            ) {
                Keyboard.state.clear()
            }
        })
        window.addEventListener("blur", () => {
            Keyboard.state.clear()
        })
    }
    private static keyDown(e: KeyboardEvent): void {
        const isUppercase = e.key == e.key.toUpperCase()
        Keyboard.state.delete(e.key.toLowerCase())
        Keyboard.state.delete(e.key.toUpperCase())
        Keyboard.state.set(e.key, true)
        Keyboard.listeners.forEach(listener => listener(e.key, true))
    }

    private static keyUp(e: KeyboardEvent): void {
        Keyboard.state.delete(e.key.toLowerCase())
        Keyboard.state.delete(e.key.toUpperCase())
        Keyboard.state.delete(e.key)
        Keyboard.listeners.forEach(listener => listener(e.key, false))
    }

    addListener = (listener: (key: string, down: boolean) => void) => {
        if (Keyboard.listeners.indexOf(listener) < 0) {
            Keyboard.listeners.push(listener)
        }
    }

    removeListener = (listener: (key: string, down: boolean) => void) => {
        Keyboard.listeners = Keyboard.listeners.filter(l => l != listener)
    }

    state = (): KeyboardState => Keyboard.state
}
