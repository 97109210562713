import { ItemClass } from "./item"


export const ArmorTypes = [
    "armor-leather",
    "armor-legion",
    "armor-plate"
] as const
export type Armor = (typeof ArmorTypes)[number]

export const AmmoTypes = [
    "arrow",
    "webAmmo"
] as const
export type Ammo = (typeof AmmoTypes)[number]

export const OreTypes = [
    "coal",
    "copper",
    "gold",
    "iron",
    "silver"
] as const
export type Ore = (typeof OreTypes)[number]

export const DrumstickTypes = [
    "drumstick"
] as const
export type Drumstick = (typeof DrumstickTypes)[number]

export const CraftResourceTypes = [
    "feather",
    "planks",
    "rope",
    "stick"
] as const
export type CraftResource = (typeof CraftResourceTypes)[number]

export const FlowerTypes = [
    "flower-single-blue",
    "flower-single-purple",
    "flower-single-red",
    "flower-single-yellow"
] as const
export type Flower = (typeof FlowerTypes)[number]

export const HideTypes = [
    "hide"
] as const
export type Hide = (typeof HideTypes)[number]

export const MetalBarTypes = [
    "metal-bar-copper",
    "metal-bar-gold",
    "metal-bar-iron",
    "metal-bar-silver"
] as const
export type MetalBar = (typeof MetalBarTypes)[number]

export const MushroomTypes = [
    "mushroom-purple",
    "mushroom-red",
    "mushroom-yellow"
] as const
export type Mushroom = (typeof MushroomTypes)[number]

export const SeedTypes = [
    "seed"
] as const
export type Seed = (typeof SeedTypes)[number]

export const ShardTypes = [
    "shard-metal"
] as const
export type Shard = (typeof ShardTypes)[number]

export const StrawTypes = [
    "straw"
] as const
export type Straw = (typeof StrawTypes)[number]

export const TorchTypes = [
    "torch"
] as const
export type Torch = (typeof TorchTypes)[number]

export const WoodTypes = [
    "wood"
] as const
export type Wood = (typeof WoodTypes)[number]

export const ResourceTypes = [
    "shard-metal"
] as const
export type Resource = (typeof ResourceTypes)[number]

export const WeaponTypes = [
    "axe",
    "bow",
    "crossbow",
    "dagger",
    "flail",
    "halberd",
    "longsword",
    "mace",
    "none",
    "rapier",
    "saber",
    "scythe",
    "spear",
    "staff",
    "waraxe",
    "warhammer",
    "web"
] as const
export type Weapon = (typeof WeaponTypes)[number]

export const BauldronTypes = [
    "bauldron"
] as const
export type Bauldron = (typeof BauldronTypes)[number]

export const ShirtTypes = [
    "blouse",
    "blouse_longsleeve",
    "corset",
    "longsleeve",
    "longsleeve_laced",
    "scoop_shirt",
    "sleeveless",
    "sleeveless_laced",
    "sleeveless_striped",
    "tanktop",
    "tunic"
] as const
export type Shirt = (typeof ShirtTypes)[number]

export const ShoesTypes = [
    "boots",
    "sandals",
    "shoes",
    "shoes-armor",
    "slippers"
] as const
export type Shoes = (typeof ShoesTypes)[number]

export const StructureTypes = [
    "campfire",
    "snare-rabbit",
    "stool-small",
    "table-large-rough",
    "table-mid-round",
    "barrel1",
    "stackedBags",
    "stool1",
    "table-mid-rough",
    "strawBed",
    "strawBedSmall"
] as const
export type Structure = (typeof StructureTypes)[number]

export const ChestTypes = [
    "chest-wood"
] as const
export type Chest = (typeof ChestTypes)[number]

export const DoorTypes = [
    "door-wood"
] as const
export type Door = (typeof DoorTypes)[number]

export const FloorTypes = [
    "floor-wood"
] as const
export type Floor = (typeof FloorTypes)[number]

export const RoofTypes = [
    "roof-wood"
] as const
export type Roof = (typeof RoofTypes)[number]

export const WallTypes = [
    "wall-wood",
    "wall2-wood"
] as const
export type Wall = (typeof WallTypes)[number]

export const CapeTypes = [
    "cape-solid",
    "cape-tattered",
    "cape-trimmed"
] as const
export type Cape = (typeof CapeTypes)[number]

export const KeyTypes = [
    "castlekey"
] as const
export type Key = (typeof KeyTypes)[number]

export const PantsTypes = [
    "legion",
    "pantaloons",
    "pants",
    "pants-armor",
    "skirts"
] as const
export type Pants = (typeof PantsTypes)[number]

export const ShieldTypes = [
    "shield-crusader",
    "shield-kite",
    "shield-kite_blue",
    "shield-kite_gray",
    "shield-kite_green",
    "shield-kite_red",
    "shield-round",
    "shield-spartan"
] as const
export type Shield = (typeof ShieldTypes)[number]

export type ItemTypes = Armor
    | Ammo
    | Ore
    | Drumstick
    | CraftResource
    | Flower
    | Hide
    | MetalBar
    | Mushroom
    | Seed
    | Shard
    | Straw
    | Torch
    | Wood
    | Resource
    | Weapon
    | Bauldron
    | Shirt
    | Shoes
    | Structure
    | Chest
    | Door
    | Floor
    | Roof
    | Wall
    | Cape
    | Key
    | Pants
    | Shield

export const AllItemTypes: ItemTypes[] = [
    "armor-leather",
    "armor-legion",
    "armor-plate",
    "arrow",
    "axe",
    "bauldron",
    "blouse",
    "blouse_longsleeve",
    "boots",
    "bow",
    "campfire",
    "cape-solid",
    "cape-tattered",
    "cape-trimmed",
    "castlekey",
    "chest-wood",
    "coal",
    "copper",
    "corset",
    "crossbow",
    "dagger",
    "door-wood",
    "drumstick",
    "feather",
    "flail",
    "floor-wood",
    "flower-single-blue",
    "flower-single-purple",
    "flower-single-red",
    "flower-single-yellow",
    "gold",
    "halberd",
    "hide",
    "iron",
    "legion",
    "longsleeve",
    "longsleeve_laced",
    "longsword",
    "mace",
    "metal-bar-copper",
    "metal-bar-gold",
    "metal-bar-iron",
    "metal-bar-silver",
    "mushroom-purple",
    "mushroom-red",
    "mushroom-yellow",
    "none",
    "pantaloons",
    "pants",
    "pants-armor",
    "planks",
    "rapier",
    "roof-wood",
    "rope",
    "saber",
    "sandals",
    "scoop_shirt",
    "scythe",
    "seed",
    "shard-metal",
    "shield-crusader",
    "shield-kite",
    "shield-kite_blue",
    "shield-kite_gray",
    "shield-kite_green",
    "shield-kite_red",
    "shield-round",
    "shield-spartan",
    "shoes",
    "shoes-armor",
    "silver",
    "skirts",
    "sleeveless",
    "sleeveless_laced",
    "sleeveless_striped",
    "slippers",
    "snare-rabbit",
    "spear",
    "staff",
    "stick",
    "stool-small",
    "straw",
    "table-large-rough",
    "table-mid-round",
    "tanktop",
    "torch",
    "tunic",
    "wall-wood",
    "wall2-wood",
    "waraxe",
    "warhammer",
    "web",
    "webAmmo",
    "wood",
    "barrel1",
    "stackedBags",
    "stool1",
    "table-mid-rough",
    "strawBed",
    "strawBedSmall"
]

export const ItemClassToTypes: Record<ItemClass, ItemTypes[]> = {
    armor: [
        ...ArmorTypes
    ],
    resource: [
        ...AmmoTypes,
        ...OreTypes,
        ...DrumstickTypes,
        ...CraftResourceTypes,
        ...FlowerTypes,
        ...HideTypes,
        ...MetalBarTypes,
        ...MushroomTypes,
        ...SeedTypes,
        ...ShardTypes,
        ...StrawTypes,
        ...TorchTypes,
        ...WoodTypes,
        ...ResourceTypes
    ],
    weapon: [
        ...WeaponTypes
    ],
    bauldron: [
        ...BauldronTypes
    ],
    shirt: [
        ...ShirtTypes
    ],
    shoes: [
        ...ShoesTypes
    ],
    structure: [
        ...StructureTypes,
        ...ChestTypes,
        ...DoorTypes,
        ...FloorTypes,
        ...RoofTypes,
        ...WallTypes
    ],
    cape: [
        ...CapeTypes
    ],
    key: [
        ...KeyTypes
    ],
    pants: [
        ...PantsTypes
    ],
    shield: [
        ...ShieldTypes
    ]
}