import { Callback, DynamicTextType, PlayerEvent, TextDecoration } from "game-common/models"
import { BitmapText, Container, Graphics, Sprite, Texture, Text } from "pixi.js"
import { BasicSprite } from "../sprites/basic_sprite"
import { Button } from "./button"
import { TextRenderer } from "../text/text_renderer"

export interface DynamicTextProps {
    font?: string
    color?: number
    spriteId?: string
    scale?: number
    extraOnClickListener?: Function
    onClick?: Callback
    playerEventEmitter?: (playerEvent: PlayerEvent) => void
    mouseControlCallback?: (on: boolean) => void
    link?: string
    pause?: number
    type?: DynamicTextType
    textDecorations?: TextDecoration[]
    context?: any
}

export class DynamicText extends Graphics {
    text: string
    font: string
    color: number
    active?: boolean
    pause?: number
    type?: DynamicTextType

    constructor(text: string, props?: DynamicTextProps) {
        super()

        this.active = true
        this.text = text
        this.font = props?.font || "ScoreFont"
        this.color = props?.color || 0xffffff
        this.pause = props?.pause
        this.type = props?.type || "text"
        const { textDecorations = [] } = props || {}
        const disabled = textDecorations.includes("disabled")

        if (props?.onClick) {
            this.interactive = true
            const onClick = () => {
                props.onClick()
                if (props?.extraOnClickListener) {
                    props.extraOnClickListener(this.text)
                }
            }
            this.on("click", onClick)
            if (!disabled) {
                this.cursor = "pointer"
                const originalAlpha = this.alpha
                this.on("mouseover", () => {
                    this.alpha = 0.7
                })
                this.on("mouseout", () => {
                    this.alpha = originalAlpha
                })
            }
            this.on("tap", onClick)
        } else if (props?.link) {
            this.interactive = true
            const onClick = () => {
                if (props?.extraOnClickListener) {
                    props.extraOnClickListener(this.text)
                }
                window.open(props.link)
            }
            this.on("click", onClick)
            this.on("tap", onClick)
        }

        const textObj = new TextRenderer()
            .color(disabled ? 0x808080 : this.color)
            .variant(this.font as any)
            .render(this.text)

        if (this.type === "text") {
            this.addChild(textObj)
            if (this.text && textDecorations.includes("underline")) {
                const underline = new Sprite(Texture.WHITE)
                underline.position.y = textObj.height
                underline.width = textObj.width
                underline.height = 2
                textObj.addChild(underline)

                const spacer = new Graphics()
                spacer.drawRect(0, 0, 0, 40)

                this.addChild(spacer)
            }
        }

        if (this.type === "button" || this.type === "button|dynamic") {
            const button = new Button({
                text: this.text,
                font: this.font,
                color: disabled ? 0x808080 : this.color,
                buttonWidth: this.type === "button|dynamic" ? undefined : 100,
                mouseControlCallback: disabled ? undefined : props.mouseControlCallback,
            })
            this.addChild(button)
        }

        if (this.type === "sprite") {
            const itemSprite = new BasicSprite({
                name: props.spriteId,
                scale: props.scale || 1.0,
                color: disabled ? 0x808080 : 0xffffff,
                rotating: false,
            })
            itemSprite.y += itemSprite.height * 0.4
            this.addChild(itemSprite)

            if (itemSprite.height < 30) {
                const spacer = new Graphics()
                spacer.drawRect(0, 0, 0, 30)
                this.addChild(spacer)
            }
        }
    }

    stop = () => {
        this.active = false
    }

    playback = (charDelay: number, parent: Container, doneCallback: Callback) => {
        const items = this.text.split("")
        const canvas = new Graphics()
        canvas.x = this.x
        canvas.y = this.y
        canvas.width = this.width
        canvas.height = this.height

        parent.addChild(canvas)

        let idx = 0
        let str = ""

        let interval
        const next = () => {
            if (!this.active) {
                return
            }
            const nextChar = items[idx]
            str += nextChar

            canvas.removeChildren()

            const textObj = new TextRenderer().color(this.color).variant(this.font).render(str)
            canvas.addChild(textObj)

            idx++
            if (idx > items.length - 1) {
                doneCallback()
                parent.removeChild(canvas)

                parent.addChild(this)

                return
            }

            const delay =
                nextChar.trim() === "," ? charDelay * 12 : nextChar.trim() === "." ? charDelay * 24 : charDelay

            interval = setTimeout(next, delay)
        }

        next()

        return () => {
            clearTimeout(interval)
        }
    }
}
