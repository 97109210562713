import { TileSize } from "game-common/models"
import { Callback } from "game-common/util"
import { Graphics, Loader, Sprite } from "pixi.js"

import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"

type ControlBarItem = {
    sprite: BasicSprite
    callback: () => void
    padding?: number
}

export class ControlBar extends Graphics {
    guiManager: GuiManager

    constructor(guiManager: GuiManager) {
        super()
        this.guiManager = guiManager
    }

    setup = (inventoryCallback: Callback<void>, questsCallback: Callback<void>, feedbackCallback: Callback<void>, mapCallback: Callback<void>) => {
        this.interactive = true
        this.on("mouseover", () => {
            this.guiManager.mouseOverControl(true)
        })
        this.on("mouseout", () => {
            this.guiManager.mouseOverControl(false)
        })

        const items: ControlBarItem[] = [
            {
                sprite: new BasicSprite({
                    name: "inventory.png",
                    scale: 0.8,
                }),
                callback: inventoryCallback,
            },
            {
                sprite: new BasicSprite({
                    name: "globe.png",
                    scale: 0.8,
                }),
                callback: mapCallback,
            },
            {
                sprite: new BasicSprite({
                    name: "book.png",
                    scale: 1.4,
                }),
                callback: questsCallback,
            },
            {
                sprite: new BasicSprite({
                    name: "question.png",
                    scale: 0.6,
                }),
                callback: () => this.guiManager.help(null),
            },
            {
                sprite: new BasicSprite({
                    name: "discord.png",
                    scale: 0.7,
                }),
                callback: () => window.open("https://discord.gg/Mbt6UfqW6e"),
            },
            {
                sprite: new BasicSprite({
                    name: "feedback.png",
                    scale: 0.8,
                }),
                callback: feedbackCallback,
                padding: 5,
            },
        ]

        let prevObj
        items.forEach(item => {
            const obj = item.sprite
            obj.x = (prevObj ? prevObj.x : 0) + TileSize * 0.75 + (item.padding || 0)
            obj.y = item.sprite.height / 2
            obj.interactive = true
            obj.on("click", item.callback)
            obj.on("tap", item.callback)

            prevObj = obj

            this.addChild(obj)
        })
    }
}
