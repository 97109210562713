import { Callback } from "game-common/models"
import { Container, Graphics } from "pixi.js"

import { DynamicText } from "./dynamic_text"

export interface DialogOptions {
    padding?: number
    x?: number
    y?: number
    width?: number
    height?: number
    content?: Graphics
    borderColor?: number
    backgroundColor?: number
    backgroundAlpha?: number
    closeCallback?: Function
    onCloseClick?: Callback
    onMouseOver?: Function
    onMouseOut?: Function
    center?: boolean
    plain?: boolean
    unescapeable?: boolean
}

export class Dialog {
    parent: Container
    padding: number
    x: number
    y: number
    width: number
    height?: number
    content: Graphics
    backgroundColor: number
    borderColor: number
    backgroundAlpha: number
    private dialog: Graphics
    private closeCallback?: Function
    private onCloseClick?: Callback
    private onMouseOver?: Function
    private onMouseOut?: Function
    private closed: boolean
    private plain: boolean
    private unescapeable: boolean

    constructor(
        parent: Container,
        {
            padding = 0,
            x = 0,
            y = 0,
            content = new Graphics(),
            borderColor = 0xffffff,
            backgroundColor = 0x000000,
            backgroundAlpha = 0.91,
            width,
            height,
            closeCallback,
            onCloseClick,
            onMouseOver,
            onMouseOut,
            plain,
            unescapeable,
        }: DialogOptions,
    ) {
        this.parent = parent
        this.padding = padding
        this.content = content
        this.x = x
        this.y = y
        this.backgroundAlpha = backgroundAlpha
        this.backgroundColor = backgroundColor
        this.width = width
        this.height = height
        this.closeCallback = closeCallback
        this.onCloseClick = onCloseClick
        this.borderColor = borderColor
        this.onMouseOver = onMouseOver
        this.onMouseOut = onMouseOut
        this.plain = plain
        this.unescapeable = unescapeable
    }

    update = () => {
        const dialog = new Graphics()
        this.dialog = dialog
        this.dialog.zIndex = 3
        this.parent.addChild(dialog)

        this.content.x = this.padding
        this.content.y = this.padding

        dialog.addChild(this.content)

        if (this.width) {
            const spacer = new Graphics()
            spacer.x = 0
            spacer.y = 0
            spacer.drawRect(0, 0, this.width, 1)
            spacer.width = this.width
            dialog.addChild(spacer)
        }
        if (this.height) {
            const spacer = new Graphics()
            spacer.x = 0
            spacer.y = 0
            spacer.drawRect(0, 0, 1, this.height)
            spacer.height = this.height
            dialog.addChild(spacer)
        }

        dialog.x = this.x
        dialog.y = this.y
        if (!this.plain) {
            dialog.lineStyle(1, this.borderColor, 0.5)
        }
        dialog.beginFill(this.backgroundColor, this.backgroundAlpha)
        if (!this.plain) {
            dialog.drawRoundedRect(0, 0, dialog.width + this.padding * 2, dialog.height + this.padding * 2, 8)
        } else {
            dialog.drawRect(0, 0, dialog.width + this.padding * 2, dialog.height + this.padding * 2)
        }
        dialog.endFill()

        if (!this.unescapeable) {
            const close = new Graphics()
            close.addChild(
                new DynamicText("x", {
                    font: "ImportantFont",
                }),
            )
            close.x = dialog.width - close.width - 15
            close.interactive = true
            close.on("click", this.onCloseClick)
            close.on("tap", this.onCloseClick)

            dialog.addChild(close)
        }

        dialog.interactive = !!this.onMouseOut || !!this.onMouseOver
        if (this.onMouseOver) {
            dialog.on("mouseover", () => this.onMouseOver())
        }
        if (this.onMouseOut) {
            dialog.on("mouseout", () => this.onMouseOut())
        }
    }

    close = () => {
        if (this.closed) {
            return
        }
        this.parent.removeChild(this.dialog)
        this.closeCallback()
        this.closed = true
    }
}
