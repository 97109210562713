import { Coordinate, WorldMapMeta } from "game-common/models"
import { Callback } from "game-common/util"
import { Graphics } from "pixi.js"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { TextRenderer } from "../text/text_renderer"
import { FullScreenModal } from "./fullscreen_modal"
import { Throbber } from "./throbber"
import { FaderGeneric } from "./fader_generic"
import { join } from "path"

export class WorldMap extends FullScreenModal {
    map: WorldMapMeta
    throbbers: Throbber[] = []
    onClickCallback: Callback<Coordinate>
    constructor(guiManager: GuiManager, map: WorldMapMeta, onClickCallback: Callback<Coordinate>) {
        super(guiManager)
        this.map = map
        this.onClose = () => {
            this.throbbers?.forEach(throbber => throbber.stop)
            this.throbbers = []
        }
        this.onClickCallback = onClickCallback
    }

    createContent = () => {
        const main = new Graphics()
        const width = this.width()
        const height = this.height()
        const sizeX = width / this.map.dimensions.w
        const sizeY = height / this.map.dimensions.h

        main.beginFill(0x808080)
        main.drawRect(0, 0, width, height)
        main.endFill()
        this.map.map.points.forEach(point => {
            const pointObj = new Graphics()
            pointObj.interactive = true
            main.addChild(pointObj)
            const { x, y } = point.biomeCoordinate

            pointObj.x = x * sizeX
            pointObj.y = y * sizeY

            pointObj.beginFill(point.color)
            pointObj.drawRect(0, 0, sizeX, sizeY)
            pointObj.endFill()
            pointObj.on("click", () =>
                this.onClickCallback({
                    x,
                    y,
                }),
            )
            pointObj.on("tap", () =>
                this.onClickCallback({
                    x,
                    y,
                }),
            )
        })

        if (this.map.playerLocations) {
            this.map.playerLocations.forEach(location => {
                const playerLocation = new Graphics()
                playerLocation.beginFill(location.isViewer ? 0xff0000 : 0xffffff)
                playerLocation.drawCircle(0, 0, sizeX / 2)
                playerLocation.endFill()

                playerLocation.x = location.location.x * sizeX
                playerLocation.y = location.location.y * sizeY
                main.addChild(playerLocation)
                playerLocation.interactive = true

                if (location.isViewer) {
                    let dir = false
                    playerLocation.scale.set(1.2)
                    const throbber = new Throbber(playerLocation, {
                        blinkInterval: 25,
                        cycleCallback: () => {
                            if (dir) {
                                playerLocation.tint = 0xff0000
                            } else {
                                playerLocation.tint = 0x000000
                            }
                            dir = !dir
                        },
                    })
                    this.throbbers.push(throbber)
                    throbber.update()

                    const playerLocationHuge = new Graphics()
                    playerLocationHuge.beginFill(location.isViewer ? 0xff0000 : 0xffffff)
                    playerLocationHuge.drawCircle(0, 0, sizeX / 2)
                    playerLocationHuge.endFill()

                    playerLocationHuge.x = location.location.x * sizeX
                    playerLocationHuge.y = location.location.y * sizeY
                    playerLocationHuge.scale.set(2)
                    main.addChild(playerLocationHuge)

                    const fader = new FaderGeneric(playerLocationHuge, {
                        direction: "out",
                        maxAlpha: 4.0,
                        fadeAlphaDecrement: 0.03,
                        fadeInterval: 0.5,
                        effectCallback: alpha => {
                            playerLocationHuge.scale.set(alpha)
                        },
                    })
                    fader.update()
                }

                const text = new TextRenderer().render(location.label)
                playerLocation.on("mouseover", () => {
                    text.x = playerLocation.x - text.width / 2
                    text.y = playerLocation.y - text.height * 1.2
                    text.zIndex = 1000
                    main.addChild(text)
                })
                playerLocation.on("mouseout", () => {
                    text.parent.removeChild(text)
                })
            })
        }

        if (this.map.locationIcons) {
            this.map.locationIcons.forEach(icon => {
                const sprite = new BasicSprite({
                    name: icon.icon,
                    color: 0xffffff,
                    scale: 0.25,
                })
                sprite.x = icon.x * sizeX
                sprite.y = icon.y * sizeY
                sprite.interactive = true

                main.addChild(sprite)
            })
        }

        if (this.map.questMarkers) {
            this.map.questMarkers.forEach(icon => {
                const sprite = new BasicSprite({
                    name: icon.icon,
                    color: 0xffffff,
                    scale: 0.25,
                })
                sprite.x = icon.x * sizeX
                sprite.y = icon.y * sizeY
                sprite.interactive = true

                if (icon.context?.active) {
                    sprite.recolor(0xff0000)
                    sprite.scale.set(1.5)

                    let dir = false
                    const throbber = new Throbber(sprite, {
                        blinkInterval: 25,
                        cycleCallback: () => {
                            if (dir) {
                                sprite.recolor(0xff0000)
                            } else {
                                sprite.recolor(0x000000)
                            }
                            dir = !dir
                        },
                    })
                    this.throbbers.push(throbber)
                    throbber.update()

                    const spriteHuge = new BasicSprite({
                        name: icon.icon,
                        color: 0xffffff,
                        scale: 2.0,
                    })
                    spriteHuge.x = icon.x * sizeX
                    spriteHuge.y = icon.y * sizeY
                    main.addChild(spriteHuge)

                    const fader = new FaderGeneric(spriteHuge, {
                        direction: "out",
                        maxAlpha: 1.0,
                        fadeInterval: 0.2,
                        effectCallback: alpha => {
                            spriteHuge.scale.set(alpha)
                        },
                    })
                    fader.update()
                }

                const text = new TextRenderer().render(icon.context?.label || "")
                sprite.on("mouseover", () => {
                    text.x = sprite.x - text.width / 2
                    text.y = sprite.y - text.height * 1.2
                    text.zIndex = 1000
                    main.addChild(text)
                })
                sprite.on("mouseout", () => {
                    text.parent.removeChild(text)
                })

                sprite.on("click", () =>
                    this.onClickCallback({
                        x: icon.x,
                        y: icon.y,
                    }),
                )
                sprite.on("tap", () =>
                    this.onClickCallback({
                        x: icon.x,
                        y: icon.y,
                    }),
                )

                main.addChild(sprite)
            })
        }

        if (this.map.geographicFeatureLocations) {
            this.map.geographicFeatureLocations.forEach(location => {
                const chatHistoryText = new TextRenderer().size("tiny").render(location.name) as any
                chatHistoryText.x = location.x * sizeX
                chatHistoryText.y = location.y * sizeY

                main.addChild(chatHistoryText)
            })
        }

        return main
    }
}
