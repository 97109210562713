import { Entity, EntityId, getEntityDistance, NpcType } from "../models"
import { Disposition } from "../social/disposition"
import { Faction } from "../social/faction"

export class SocialMechanics {
    private static npcTypesAllowingDisposition: Set<NpcType> = new Set()
    static {
        this.npcTypesAllowingDisposition.add("zombie")
        this.npcTypesAllowingDisposition.add("marker")
    }

    private static isOutpostFaction = (f: Faction) => f.startsWith("outpost")
    private static parseStemFaction = (f: Faction) => f.split("-")[0]
    static computeFactionDisposition = (aFaction: Faction, bFaction: Faction): Disposition => {
        if (!aFaction || aFaction === "none") {
            return "neutral"
        }
        if (!bFaction || bFaction === "none") {
            return "neutral"
        }

        // if they don't belong to the same faction
        // or if neither belong to a faction
        if (aFaction !== bFaction) {
            // outpost isn't aggressive towards neutral faction
            if (this.isOutpostFaction(aFaction) && bFaction === "neutral") {
                return "neutral"
            }

            if (this.parseStemFaction(aFaction) === this.parseStemFaction(bFaction)) {
                // recognize each other as neutral, if they have the same
                // stem faction
                return "neutral"
            }

            // but everyone else is hostile to a different faction
            return "hostile"
        }
        return "neutral"
    }

    static addUnilateralDisposition = (entity: Entity, disposition: Disposition) => {
        if (!disposition) {
            delete entity.flags.unilateralDisposition
            return
        }

        entity.flags.unilateralDisposition = disposition
    }

    static uniLateralDisposition = (entity: Entity) => entity?.flags.unilateralDisposition

    static hardCodedDisposition = (entity: Entity, other: Entity): Disposition => {
        if (!other) {
            return "neutral"
        }
        if (entity.flags.entityDispositions) {
            return entity.flags.entityDispositions[other.entityId] || "neutral"
        }
        return "neutral"
    }

    static addHardCodedDisposition = (entity: Entity, other: Entity | EntityId, disposition: Disposition) => {
        if (!other) {
            return
        }

        if (!entity.flags.entityDispositions) {
            entity.flags.entityDispositions = {}
        }
        const otherEntityId = typeof other === "object" ? other.entityId : (other as EntityId)
        entity.flags.entityDispositions[otherEntityId] = disposition
    }

    static removeHardCodedDisposition = (entity: Entity, other: Entity) => {
        if (!entity.flags.entityDispositions) {
            entity.flags.entityDispositions = {}
        }
        delete entity.flags.entityDispositions[other.entityId]
    }

    static makeNpcSafe = (entity: Entity, durationMs: number) => {
        entity.npcSafeUntilTs = Date.now() + durationMs
    }

    static computeDisposition = (entity: Entity, other: Entity, now?: number): Disposition => {
        // both need to be present to calculate
        if (!entity || !other) {
            return "neutral"
        }

        // its the same entity
        if (entity.entityId === other.entityId) {
            return "neutral"
        }

        // if the other is marked to be safe
        if (other.isNpcSafe()) {
            return "neutral"
        }

        // if the other is marked away from keyboard
        if (other.afk) {
            return "neutral"
        }

        // there's an explicit unilateral disposition
        if (!!other?.flags.unilateralDisposition) {
            if (entity.lastStruckEntityId === other.entityId && other.flags.undefined !== "hostile") {
                return "hostile"
            }
            return other?.flags.unilateralDisposition
        }

        // there's a hardcoded disposition
        if (!!entity.flags.entityDispositions && entity.flags.entityDispositions[other.entityId]) {
            return entity.flags.entityDispositions[other.entityId]
        }

        // the entity leads the other entity
        if (other.leaderEntityId && entity.entityId === other.leaderEntityId) {
            return "friendly"
        }

        // if the other entity has the same leader
        if (entity.leaderEntityId && other.leaderEntityId && entity.leaderEntityId === other.leaderEntityId) {
            return "friendly"
        }

        // if the entity's leader is the other entity
        if (entity.leaderEntityId && entity.leaderEntityId === other.entityId) {
            return "defender"
        }

        if (other.faction === "none") {
            return "neutral"
        }

        if (entity.faction && other.faction && entity.faction === other.faction && entity.faction !== "neutral") {
            return "friendly"
        }

        // if they don't belong to the same faction
        // or if neither belong to a faction
        if ((!entity.faction && !other.faction) || entity.faction !== other.faction) {
            return this.computeFactionDisposition(entity.faction, other.faction)
        }

        // if struck by other entity recently -- hostility
        if (entity.lastStruckByEntityId && entity.lastStruckByEntityId === other.entityId) {
            const occurrenceTs = (now || Date.now()) - entity.lastStruckTs
            const distance = getEntityDistance(entity, other)
            if (occurrenceTs < 10000) {
                return "hostile"
            }
        }

        // if my leader struck by other entity recently -- hostility
        if (entity.leaderEntityId && entity.leaderEntityId === other.lastStruckEntityId) {
            const occurrenceTs = (now || Date.now()) - other.lastStruckEntityTs
            if (occurrenceTs < 10000) {
                return "hostile"
            }
        }

        // if my leader struck the other entity recently -- hostility
        if (entity.leaderEntityId && entity.leaderEntityId === other.lastStruckByEntityId) {
            const occurrenceTs = (now || Date.now()) - other.lastStruckTs
            if (occurrenceTs < 10000) {
                return "hostile"
            }
        }

        // its NPC but its not something you can develop a disposition about (eg. inanimate object)
        if (!!other.npcType && !this.npcTypesAllowingDisposition.has(other.npcType)) {
            return "neutral"
        }

        // its NPC but its not something you can develop a disposition about (eg. inanimate object)
        if (!!entity.npcType && !this.npcTypesAllowingDisposition.has(entity.npcType)) {
            return "neutral"
        }

        // // aron is for debugging
        // if (other.name === "aron") {
        //     return "neutral"
        // }

        if (entity.flags?.attackNeutral) {
            return "hostile"
        }

        // no relationship ... by default its neutral
        return "neutral"
    }
}
