import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { Entity, TileSize } from "game-common/models"
import { Callback, chunkSentence } from "game-common/util"
import { BitmapText, Graphics, Point, Text } from "pixi.js"

import { EntityInteractionCallback } from "../../../../client_game_logic"
import { EntityRenderMeta } from "../entity_rendering_manager"
import { Fader } from "../gui/fader"
import { BaseRenderable } from "./base_renderable"
import { BasicSprite } from "./basic_sprite"
import { Interactions } from "./interactions"
import { TextRenderer } from "../text/text_renderer"
import { DyanmicTextContainer } from "../gui/dynamic_text_container"

// interface Job {
//     run: (done: Callback<void>) => void
// }

// class JobQueue {
//     private queue: Job[] = []
//     private running: boolean = false

//     constructor() {
//         //
//     }

//     add = (callback: Job) => {
//         if (this.queue.length > 2) {
//             this.queue.shift()
//         }
//         this.queue.push(callback)
//         if (!this.running) {
//             this.run()
//         }
//     }

//     private run = () => {
//         this.running = true
//         const callback = this.queue.shift()
//         if (callback) {
//             callback.run(() => this.run())
//         } else {
//             this.running = false
//         }
//     }
// }

export class AvatarHud extends BaseRenderable {
    id: string
    idText: Text
    hpText: Text
    currentName: string = ""
    currentLevel: number
    currentColor: number
    currentHp: number = 0
    currentHpMax: number = 0

    alwaysShowLabel: boolean
    suppressDetailHover: boolean

    tag: Graphics

    idleTimeout: any
    blinkingInterval: any

    chatBubble: Graphics
    chatBubbleHorizontalSpacer: Graphics
    bubbleText: DyanmicTextContainer
    chatBubbleTimeout: any
    chatBubbleFadeInterval: any
    damageInterval: any

    interactions: Interactions
    pointerIcon: Graphics
    pointerIconPulseInterval: any
    pointerIconAngleInterval: any
    sigil: Graphics
    // chatQueue: JobQueue = new JobQueue()

    speaking: boolean

    constructor(
        color = 0xff00ff,
        renderMeta: EntityRenderMeta,
        parentArea: BaseRenderable,
        interactionCallback: EntityInteractionCallback,
        suppressDetailHover?: boolean,
    ) {
        super()
        this.parentArea = parentArea
        this.currentColor = color

        this.alwaysShowLabel = renderMeta.alwaysShowLabel

        this.idText = new TextRenderer().size("small").color(0x00ff00).render() as Text

        this.hpText = new TextRenderer().size("small").color(0x00ff00).render() as Text

        this.suppressDetailHover = suppressDetailHover
        this.idText.visible = !suppressDetailHover ? this.alwaysShowLabel : false
        this.hpText.visible = !suppressDetailHover ? this.alwaysShowLabel : false
        this.idText.alpha = 1
        this.hpText.alpha = 1

        const tag = new Graphics()
        tag.beginFill(0xffffff)
        tag.lineStyle(1, 0xffffff)
        tag.drawCircle(-8, 8, 6)
        tag.endFill()
        this.addChild(tag)
        tag.visible = false
        tag.alpha = 0.7
        this.tag = tag

        this.addChild(this.idText)
        this.addChild(this.hpText)
        this.hpText.x = this.idText.x + this.idText.width
        this.hpText.y = this.idText.y

        this.update(3, 3)
        this.calcLabel()

        this.chatBubble = new Graphics()
        this.chatBubble.visible = true
        this.chatBubble.x = 0
        this.addChild(this.chatBubble)

        const chatBubbleHorizontalSpacer = new Graphics()
        this.chatBubble.addChild(chatBubbleHorizontalSpacer)

        const bubbleText = this.makeBubbleText()
        this.chatBubble.addChild(bubbleText)
        this.chatBubble.visible = false

        this.interactions = new Interactions(0, this.hpText.y + 10, parentArea, interactionCallback)
        this.addChild(this.interactions.interactionWidgetsContainer)

        const pointer = new BasicSprite({
            name: "icon-arrow.png",
            color: 0xffffff,
            scale: 1.0,
        })
        pointer.visible = false

        this.addChild(pointer)
        this.pointerIcon = pointer
    }

    makeBubbleText = () => {
        const bubbleText = new DyanmicTextContainer({
            progressive: true,
            backdrop: true,
        })
        bubbleText.x = 5
        bubbleText.y = 5
        this.bubbleText = bubbleText

        return bubbleText
    }

    updateSigil(sigil?: string) {
        // remove existing sigil
        if (this.sigil) {
            this.sigil.parent?.removeChild(this.sigil)
        }

        if (sigil) {
            this.sigil = new BasicSprite({
                name: sigil,
                scale: 1.8,
                color: 0xffffff,
            })
            this.sigil.x += this.sigil.width * 0.3
            this.sigil.y = -3
            this.addChild(this.sigil)
        }
    }

    recolor = (color: number) => {
        if (this.currentColor === color) {
            return
        }
        this.currentColor = color
        if (this.tag) {
            this.tag.tint = color
        }
    }

    updateLevel = (level: number) => {
        if (this.currentLevel === level) {
            return
        }
        this.currentLevel = level
        this.calcLabel()
    }

    updateName = (name: string) => {
        if (name !== this.currentName) {
            this.currentName = name
            this.calcLabel()
        }
    }

    update = (hp: number, hpMax: number) => {
        if (this.currentHp !== hp) {
            this.currentHp = hp
            this.calcLabel()
        }
        if (this.currentHpMax !== hpMax) {
            this.currentHpMax = hpMax
            this.calcLabel()
        }
    }

    private calcLabel = () => {
        let name = this.currentName || ""
        const max = 15
        if (name.length > max) {
            name = name.substring(0, max - 3) + "..."
        }
        let level: string = !!name && this.currentLevel > 0 ? `lvl ${this.currentLevel} ` : ""

        this.idText.y = 0
        this.idText.text = (name + " " + level).trim()
        if (this.idText.visible) {
            this.hpText.x = this.idText.x + this.idText.width
            this.hpText.text = " " + this.calcHpBars().trim()
        } else {
            this.hpText.x = this.idText.x
            this.hpText.text = this.calcHpBars().trim()
        }

        const hpPct = this.currentHp / this.currentHpMax
        if (hpPct < 0.7) {
            if (hpPct < 0.4) {
                this.hpText.tint = 0xff0000
            } else {
                this.hpText.tint = 0xffa500
            }
        } else {
            this.hpText.tint = 0xffffff
        }
    }

    private calcHpBars = () => {
        if (this.currentHpMax < 1) {
            return ""
        }
        let text = ""

        const hp = Math.ceil((this.currentHp / this.currentHpMax) * 10) / 2
        for (let i = 0; i < hp; i++) {
            text += "|"
        }
        text = text.trim()
        return text
    }

    addTag() {
        this.tag.visible = true
        this.tag.tint = this.currentColor
    }

    removeTag() {
        this.tag.visible = false
    }

    spoke = (message: string) => {
        if (this.speaking) {
            this.bubbleText.wipe()
            this.bubbleText.parent.removeChild(this.bubbleText)

            this.bubbleText = this.makeBubbleText()
            this.chatBubble.addChild(this.bubbleText)
        }

        this.speaking = true
        this.spokeImpl(message, () => {
            this.speaking = false
        })
    }

    spokeImpl = (message: string, done: Callback<void>) => {
        if (this.chatBubbleTimeout) {
            clearTimeout(this.chatBubbleTimeout)
            this.chatBubbleTimeout = undefined
        }
        if (this.chatBubbleFadeInterval) {
            clearInterval(this.chatBubbleFadeInterval)
            this.chatBubbleFadeInterval = undefined
        }
        this.hpText.visible = false
        this.idText.visible = false

        const wordCount = message.split(" ").length
        const delayMs = Math.max(3000, ((wordCount * 60) / 200) * 1250)

        const sentences = chunkSentence(message, 22)

        this.chatBubble.clear()
        this.chatBubble.alpha = 1.0
        this.chatBubble.beginFill(0x000000, 0.4)
        // this.chatBubble.lineStyle(1, 0x000000, 0.1)
        this.chatBubble.drawRoundedRect(0, 0, 160, 20 + sentences.length * 15, 5)
        // this.chatBubble.y = -45
        this.chatBubble.x = TileSize * 1.4
        this.chatBubble.zIndex = 100000
        this.chatBubble.drawPolygon([new Point(0, 5), new Point(-11, 25), new Point(0, 25)])
        this.chatBubble.visible = true

        this.bubbleText.wipe()
        sentences.forEach(sentence => {
            this.bubbleText.append2(sentence, {
                font: "BubbleChatFont",
            })
        })
        this.bubbleText.playback(() => {
            this.chatBubbleTimeout = setTimeout(() => {
                const fader: Fader = new Fader(this.chatBubble, {
                    releaseCallback: () => {
                        clearInterval(this.chatBubbleFadeInterval)
                        this.bubbleText.wipe()
                        this.chatBubble.visible = false
                        this.chatBubble.alpha = 1.0

                        done()
                    },
                })
                fader.update()
            }, delayMs * 0.5)
        })
    }

    onMouseOver = (show: boolean) => {
        if (!this.suppressDetailHover && !this.speaking) {
            if (!this.alwaysShowLabel) {
                this.idText.visible = show
                this.hpText.visible = show
            }

            if (show) {
                this.calcLabel()
            }
        }

        this.interactions.interactionWidgetsContainer.visible = show
    }

    updateInteractions(interactions: InteractionType[]): void {
        this.interactions.updateInteractions(interactions)
    }

    updatePointerIcon(show: boolean, angle?: number) {
        if (!show) {
            clearInterval(this.pointerIconPulseInterval)
            clearInterval(this.pointerIconAngleInterval)
            this.pointerIconPulseInterval = undefined
            this.pointerIconAngleInterval = undefined
            this.pointerIcon.visible = false
            this.pointerIcon.angle = 0
        } else if (angle !== undefined) {
            this.pointerIcon.visible = true

            this.pointerIcon.angle = angle
            const radius = 32
            const x = radius * Math.sin((Math.PI * 2 * 90) / 360)
            const y = radius * Math.cos((Math.PI * 2 * 90) / 360)
            this.pointerIcon.x = x + radius - 10
            this.pointerIcon.y = y + radius + 10

            if (!this.pointerIconPulseInterval) {
                let scale = 1.0
                let scaleDir = 1

                this.pointerIconPulseInterval = setInterval(() => {
                    scale += 0.01 * scaleDir
                    if (scale > 1.2 || scale < 1.0) {
                        scaleDir = scaleDir * -1
                    }
                    this.pointerIcon.scale.set(scale)
                }, 50)
            }
        }
    }
}
