
import { ItemClass } from "./item"
import { ItemTypes } from "./item_type"
export const ItemTypesProtection: Partial<Record<ItemClass, Partial<Record<ItemTypes, number>>>> = {
  "armor": {
    "armor-leather": 0.1,
    "armor-legion": 0.2,
    "armor-plate": 0.3
  },
  "bauldron": {
    "bauldron": 0.05
  },
  "cape": {},
  "key": {},
  "pants": {
    "legion": 0.1,
    "pantaloons": 0.01,
    "pants-armor": 0.1,
    "skirts": 0.01
  },
  "resource": {},
  "shield": {
    "shield-crusader": 0.2,
    "shield-kite": 0.2,
    "shield-kite_blue": 0.2,
    "shield-kite_gray": 0.2,
    "shield-kite_green": 0.2,
    "shield-kite_red": 0.2,
    "shield-round": 0.1,
    "shield-spartan": 0.15
  },
  "shirt": {
    "blouse": 0.01,
    "blouse_longsleeve": 0.01,
    "corset": 0.01,
    "longsleeve": 0.01,
    "longsleeve_laced": 0.01,
    "scoop_shirt": 0.01,
    "sleeveless": 0.01,
    "sleeveless_laced": 0.01,
    "sleeveless_striped": 0.01,
    "tanktop": 0.01,
    "tunic": 0.01
  },
  "shoes": {
    "boots": 0.01,
    "sandals": 0.01,
    "shoes": 0.01,
    "shoes-armor": 0.1,
    "slippers": 0.01
  },
  "structure": {},
  "weapon": {}
}
