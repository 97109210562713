import { WeaponAmmo } from "game-common/character/character"
import {
    ItemTypeMeta,
    locateInventoryItemObjectByItemType,
    locateInventoryItemObjects,
    resolveItemTypeToMeta,
} from "game-common/item/item"
import { SquadState } from "game-common/mechanics/combat_mechanics"
import {
    AbilityType,
    Dimensions,
    Entity,
    EntityId,
    Inventory,
    InventoryItem,
    LeaderboardMeta,
    ScreenDimensions,
    ScreenMessage,
    TileSize,
    TutorialType,
    WorldMapMeta,
    getDistance,
} from "game-common/models"
import { Callback, chunkSentence, degrees_to_radians, getAngle } from "game-common/util"
import TextInput from "pixi-text-input"
import { Joystick } from "pixi-virtual-joystick"
import { BitmapText, Container, Graphics, Loader, Sprite, Text } from "pixi.js"

import { ClientGameLogic } from "../../../client_game_logic"
import { IGuiRenderingManager } from "../../../client_models"
import { isMobile } from "../../../client_util"
import { ClientRenderable, EntityRenderingManager } from "./entity_rendering_manager"
import { AbilitiesWidget } from "./gui/abilities"
import { About } from "./gui/about"
import { Button } from "./gui/button"
import { CharacterBuilder } from "./gui/character_builder"
import { CharacterCustomizer } from "./gui/character_customizer"
import { ChatHistory } from "./gui/chat_history"
import { ControlBar } from "./gui/control_bar"
import { DayCycle } from "./gui/day_cycle"
import { DynamicDialog } from "./gui/dynamic_dialog"
import { DynamicText } from "./gui/dynamic_text"
import { DyanmicTextContainer } from "./gui/dynamic_text_container"
import { EquippedWidget } from "./gui/equipped"
import { Fader } from "./gui/fader"
import { FeatureWidget } from "./gui/feature_widget"
import { Feedback } from "./gui/feedback"
import { Help } from "./gui/help"
import { IconMeter } from "./gui/icon_meter"
import { ImportantMessage } from "./gui/important_message"
import { LeaderboardWidget } from "./gui/leaderboard"
import { ManaMeter } from "./gui/mana_meter"
import { Modal, ModalProps } from "./gui/modal"
import { ResourceBar } from "./gui/resource_bar"
import { SquadWidget } from "./gui/squad"
import { StickyBanner } from "./gui/sticky_banner"
import { Throbber } from "./gui/throbber"
import { Tutorial } from "./gui/tutorial"
import { Typing } from "./gui/typing"
import { Intro } from "./intro/intro"
import { MinimapSurface } from "./minimap_surface"
import { BasicSprite } from "./sprites/basic_sprite"
import { TextRenderer } from "./text/text_renderer"
import { WorldMap } from "./gui/world_map"

interface IncomingMessageMeta {
    unread: boolean
    flasherInterval: any
    expires: number
    text: ScreenMessage
}

export const BOTTOM = 460
export class GuiManager implements IGuiRenderingManager {
    stage: Container
    minimapSurface: MinimapSurface

    // chat
    typing: Typing
    chatHistory: ChatHistory
    chatHistoryTextTimer: any
    chatMessages: string[] = []
    playerMessage: string = ""
    lastPlayerMessage: string = ""

    // screen messages
    bannerText: BitmapText
    screenMessages: ScreenMessage[] = []
    currentScreenMessage: ScreenMessage | null = null
    importantMessage: ImportantMessage

    // player
    // meters
    hp: IconMeter
    mana: ManaMeter
    hunger: ManaMeter
    nameText: Text

    // resource bar
    resourceBar: ResourceBar

    // leaderboard
    leaderboard: LeaderboardWidget

    // equipped
    equipped: EquippedWidget
    slotCallbacks: Record<number, Callback<void>>

    // squad
    squad: SquadWidget

    // abilities
    abilities: AbilitiesWidget

    private currentPlayerUpgradePoints: number = 0
    private currentPlayerName: string = ""
    private currentPlayerHp: number = 0
    private currentPlayerHpMax: number = 0
    private currentPlayerHunger: number = 0
    private currentPlayerHungerMax: number = 0
    private currentPlayerMana: number = 0
    private currentScore: number

    logic: ClientGameLogic
    private currentRoomId: EntityId
    private currenRoomName?: string
    private roomNameHidden: boolean

    private leftJoystickAngleAllowed: boolean = true
    private inventoryUpdateTs: Map<EntityId, number> = new Map()

    // blackout
    blackout: Graphics

    // redout
    redout: Graphics

    private container: Graphics
    private incomingMessageMeta: IncomingMessageMeta

    private currentModal: DynamicDialog | null
    private upgradeIndicator: boolean
    private joystickSetup: boolean
    private characterCustomizer: CharacterCustomizer
    private characterBuilder: CharacterBuilder
    private controlBar: ControlBar
    private stickyBanner: StickyBanner
    private userSuppressMinimap: boolean = false
    private tutorial: Tutorial
    private dayCycle: DayCycle

    private dynamicDialogToken: string
    private featureWidget: FeatureWidget
    private mouseHoverElement: Graphics

    private loggingIn: boolean
    private intro: Intro
    private mousePointer: BasicSprite

    constructor(stage: Container, minimapSurface: MinimapSurface, logic: ClientGameLogic) {
        this.stage = stage
        this.minimapSurface = minimapSurface
        this.logic = logic
        this.incomingMessageMeta = {
            unread: false,
            flasherInterval: null,
            expires: null,
            text: null,
        }
        this.currentModal = null

        this.upgradeIndicator = false
        this.minimapSurface.register(this.mapPointHoverListener)
    }

    init = () => {
        // meters

        const container = new Graphics()
        this.container = container
        container.x = 10
        container.zIndex = 2
        this.stage.addChild(container)

        const bannerText = new BitmapText("", {
            fontName: "BannerFont",
        })
        bannerText.y = 50
        this.bannerText = bannerText
        container.addChild(bannerText)

        container.addChild(this.minimapSurface)
        const screenWidth = ScreenDimensions.w + TileSize * 4.7

        // message
        const importantMessage = new ImportantMessage(0xffffff, {
            text: "",
        })
        importantMessage.x = 100
        importantMessage.y = 10
        this.importantMessage = importantMessage
        container.addChild(importantMessage)

        const hp = new IconMeter("heart", 0)
        hp.x = this.stage.width / 2 - hp.width
        hp.y = 2
        this.hp = hp
        container.addChild(hp)

        const mana = new ManaMeter(0xf0e54c, 19)
        mana.x = hp.x
        mana.y = hp.height + 8
        mana.visible = false
        this.mana = mana
        container.addChild(mana)

        const hunger = new ManaMeter(0xc4a484, 19, "drumstick-brown.png")
        hunger.x = mana.x
        hunger.y = mana.height + 20
        hunger.visible = false
        this.hunger = hunger
        container.addChild(hunger)

        const chatHistoryPos = BOTTOM - 70
        this.typing = new Typing()
        this.typing.x = 20
        this.typing.y = chatHistoryPos
        this.typing.visible = false
        this.typing.zIndex = 3
        this.stage.addChild(this.typing)

        const chatHistory = new ChatHistory(chatHistoryPos)
        chatHistory.zIndex = 3
        chatHistory.x = 15
        chatHistory.y = this.typing.y - 95
        this.chatHistory = chatHistory
        this.stage.addChild(chatHistory)

        // leaderboard
        this.leaderboard = new LeaderboardWidget(this)
        this.leaderboard.x = ScreenDimensions.w - 25
        this.leaderboard.y = 0
        this.stage.addChild(this.leaderboard)

        // equipped
        this.equipped = new EquippedWidget(this)
        this.equipped.x = container.x + 10
        this.equipped.y = BOTTOM - this.equipped.height - 20
        container.addChild(this.equipped)

        // squad
        this.squad = new SquadWidget(this)
        this.squad.x = 390
        this.squad.y = BOTTOM - this.squad.height - 20
        container.addChild(this.squad)

        // abilities
        this.abilities = new AbilitiesWidget(this)
        this.abilities.x = 390
        this.abilities.y = this.equipped.y
        container.addChild(this.abilities)

        // player
        this.nameText = new TextRenderer().color(0x00ff00).render() as Text
        this.nameText.y = 1
        this.nameText.x = 90
        container.addChild(this.nameText)

        // resource bar
        this.resourceBar = new ResourceBar(this)
        this.resourceBar.visible = true
        this.resourceBar.setup()
        this.resourceBar.x = this.nameText.x + 5
        this.resourceBar.y = this.nameText.y
        container.addChild(this.resourceBar)

        const blackout = new Graphics()
        blackout.x = 0
        blackout.y = 0
        blackout.lineStyle(2, 0x000000)
        blackout.beginFill(0x000000)
        blackout.drawRect(0, 0, 1000, 1000)
        blackout.visible = true
        blackout.alpha = 1.0
        blackout.endFill()
        this.blackout = blackout
        this.stage.addChild(blackout)

        const redout = new Graphics()
        redout.x = 0
        redout.y = 0
        redout.lineStyle(2, 0xff0000)
        redout.beginFill(0xff0000)
        redout.drawRect(0, 0, 1000, 1000)
        redout.visible = false
        redout.alpha = 0.3
        redout.endFill()
        this.redout = redout
        this.stage.addChild(redout)

        this.minimapSurface.x = ScreenDimensions.w + 40
        this.minimapSurface.y = BOTTOM - 105

        this.characterCustomizer = new CharacterCustomizer(this, this.logic)
        this.characterBuilder = new CharacterBuilder(this, this.logic)

        const controlBar = new ControlBar(this)
        if (isMobile()) {
            controlBar.scale.set(1.5)
        } else {
            controlBar.scale.set(0.7)
        }
        controlBar.setup(
            () => {
                this.logic.invokeInventory(null)
            },
            () => {
                this.logic.invokeQuests()
            },
            () => {
                this.invokeFeedback()
            },
            () => {
                this.logic.playerController.client.requestWorldMap()
            },
        )

        controlBar.x = screenWidth - controlBar.width - (isMobile() ? 32 : 18)
        controlBar.y = 4
        controlBar.visible = false
        this.controlBar = controlBar
        this.container.addChild(controlBar)

        const stickyBanner = new StickyBanner(this)
        stickyBanner.setup()

        stickyBanner.y = TileSize * 0.07
        stickyBanner.x = controlBar.x - stickyBanner.width - TileSize * 0.15

        this.stickyBanner = stickyBanner
        this.container.addChild(stickyBanner)

        this.tutorial = new Tutorial(this, this.container, this.equipped, this.controlBar, this.minimapSurface)
        this.dayCycle = new DayCycle(this)
        this.dayCycle.visible = false
        this.dayCycle.x = stickyBanner.x - TileSize * 5.25
        this.dayCycle.y = stickyBanner.y + TileSize * 0.46
        this.container.addChild(this.dayCycle)
        this.dayCycle.setup(0.25 * 360)

        this.featureWidget = new FeatureWidget(this)
        this.featureWidget.x = 0
        this.featureWidget.y = 55
        this.container.addChild(this.featureWidget)

        this.intro = new Intro(this)
    }

    updateDayPct = (pct: number) => {
        if (pct !== undefined) {
            this.dayCycle.visible = true
            this.dayCycle.setup(pct * 360)
        }
    }

    getIntro = () => this.intro

    log = (msg: string) => this.logic.playerController.client.logActivity(msg)

    showTutorial = (tutorialType: TutorialType) => {
        this.tutorial.setup(tutorialType)
    }

    invokeFeedback = () => {
        this.logic.playerController.client.logActivity("Opened feedback widget")
        const feedback = new Feedback(this, {
            onSubmit: feedback => {
                if ((feedback.feedback || "").trim().length > 0) {
                    this.addScreenMessage({
                        target: "important",
                        text: "Thanks. Your feedback will improve the game!",
                    })
                    this.logic.playerController.client.submitFeedback(feedback)
                }
            },
        })
        feedback.setup()
    }

    helpMessaging = () => {
        // cleanout old message
        const existingMessage = this.container.getChildByName("_help_messaging_")
        if (existingMessage) {
            existingMessage.parent.removeChild(existingMessage)
        }

        const helpMessaging = new DyanmicTextContainer()
        helpMessaging.name = "_help_messaging_"
        this.container.addChild(helpMessaging)

        const messages: string[] = []
        const items: Container[] = []

        if (this.logic.playerController.isGuest()) {
            messages.push("/register to save progress")
        }

        let previous = null
        messages.forEach(message => {
            const messageText = new TextRenderer().align("right").render(message)
            helpMessaging.addChild(messageText)

            if (previous) {
                messageText.y = previous.y - messageText.height
            }

            items.push(messageText)
            previous = messageText
        })

        if (this.incomingMessageMeta.unread) {
            const haltIndicator = (showDialog: boolean = true) => {
                if (showDialog) {
                    this.doDynamicDialog(this.incomingMessageMeta.text)
                }
                this.haltMessageIndicator()
            }

            const button = new Button({
                text: "Incoming message",
                font: "NameFont",
                color: 0xff0000,
                textColor: 0xffffff,
                buttonWidth: 100,
                onClick: () => {
                    this.log("open incoming message")
                    haltIndicator(true)
                },
                mouseControlCallback: (on: boolean) => {
                    this.logic.playerController.enabledMouseAction(!on)
                },
            })
            button.y = previous.y - button.height
            new Throbber(button, {
                expires: 30 * 1000,
                blinkInterval: 20,
                releaseCallback: () => haltIndicator(false),
            }).update()
            previous = button
            helpMessaging.addChild(button)
            items.push(button)
        }

        if (this.upgradeIndicator) {
            const button = new Button({
                text: "Level up stats!",
                font: "NameFont",
                color: 0xfffff,
                buttonWidth: 120,
                onClick: () => {
                    this.log("open stats upgrade ui")
                    this.logic.playerController.client.invokeStatUpgradeUI()
                },
                mouseControlCallback: (on: boolean) => {
                    this.logic.playerController.enabledMouseAction(!on)
                },
            })
            // button.y = previous?.y - button.height
            new Throbber(button, {
                expires: 5000,
                blinkInterval: 20,
            }).update()

            previous = button
            helpMessaging.addChild(button)
            items.push(button)
        }

        helpMessaging.x = this.minimapSurface.x - helpMessaging.width - 10
        helpMessaging.y = BOTTOM - 30
        items.forEach(item => {
            item.x = helpMessaging.width - item.width
        })
    }

    update = (player: Entity) => {
        if (this.currentPlayerHp !== player.hp || this.currentPlayerHpMax !== player.maxHp) {
            this.hp.max = 10
            this.hp.update((player.hp / player.maxHp) * this.hp.max)
            this.hunger.x = this.hp.x
            this.currentPlayerHp = player.hp
            this.currentPlayerHpMax = player.maxHp
        }
        if (this.currentPlayerHunger !== player.hunger || this.currentPlayerHungerMax !== player.maxHunger) {
            this.hunger.visible = true
            this.hunger.update((player.hunger / player.maxHunger) * this.hunger.max)
            this.mana.x = this.hunger.x
            this.currentPlayerHunger = player.hunger
            this.currentPlayerHungerMax = player.maxHunger
        }
        if (this.currentPlayerMana !== player.mana) {
            this.mana.visible = true
            this.mana.y = this.hp.height + 8
            this.mana.update((player.mana / player.maxMana) * this.mana.max)
        }
        if (this.currentPlayerName !== player.name || this.currentScore !== player.score) {
            let name = player.safeName()
            this.nameText.text = `${name}, Lvl ${Math.floor(player.level)}`
            // this.nameText.text = `${name} - Lvl ${player.level} | `
            // this.nameText.text += `${Math.floor(player.score)}/${Math.floor(player.score + player.scoreTilNextLevel)} next level`
            this.currentPlayerName = player.name
            this.currentScore = player.score
        }
        if (this.currentPlayerUpgradePoints !== player.upgradePoints) {
            this.currentPlayerUpgradePoints = player.upgradePoints
            this.upgradeIndicator = this.currentPlayerUpgradePoints > 0
            this.helpMessaging()
        }
        if (player && player.entityId === this.logic.playerEntity.entityId && !this.joystickSetup) {
            this.setupJoystick(this.container)
            this.joystickSetup = true
        }
        if (player && player.entityId === this.logic.playerEntity.entityId) {
            if (this.characterBuilder.isOpen()) {
                this.characterBuilder.update()
            }
        }
    }

    updateSquadState = (squadState: SquadState) => {
        this.squad.update(squadState)
    }

    updateBannerText = (text: string) => {
        this.bannerText.text = text
    }

    updateLocation = (label: string) => {
        //
    }

    startChat = () => {
        this.typing.updateText("")
        clearTimeout(this.chatHistoryTextTimer)
        this.chatHistory.show()
    }

    private updateChatHistoryText = () => {
        this.chatHistory.updateMessages(this.chatMessages.map(next => chunkSentence(next, 50)).flat())
    }

    appendChatHistory = (message: string) => {
        this.chatHistory.show()

        this.chatMessages.push(message)
        this.chatMessages = this.chatMessages.slice(Math.max(this.chatMessages.length - 10, 0))

        this.updateChatHistoryText()
        clearTimeout(this.chatHistoryTextTimer)
        this.chatHistoryTextTimer = setTimeout(() => {
            this.chatHistory.hide()
        }, 10000)
    }

    captureKeystroke = (c: string) => {
        if (c === "Escape") {
            this.playerMessage = ""
            return
        }
        if (c === "Backspace") {
            this.playerMessage = this.playerMessage.substring(0, this.playerMessage.length - 1)
            this.typing.updateText(this.playerMessage)

            return
        }
        if (c === "ArrowUp") {
            this.playerMessage = this.lastPlayerMessage
            this.typing.updateText(this.playerMessage)

            return
        }
        if (this.playerMessage.length + 1 > 150) {
            return
        }

        this.playerMessage += c
        this.typing.updateText(this.playerMessage.trim())
    }

    stopChat = (): string => {
        clearTimeout(this.chatHistoryTextTimer)
        const messageToSend = this.playerMessage.trim()

        if (messageToSend === "/register") {
            this.typing.removeText()
            this.chatHistory.hide()
            this.playerMessage = ""

            if (this.logic.playerController.isGuest()) {
                this.collectPlayerAccount(
                    this.logic.playerEntity.name,
                    (username: string, password: string, email: string) => {
                        if (username && password) {
                            this.logic.playerController.client.register(username, password, email)
                        }
                    },
                )
            } else {
                this.addScreenMessage({
                    target: "important",
                    text: "You're already registered",
                })
            }

            return ""
        }

        if (messageToSend === "/message") {
            this.typing.removeText()
            this.chatHistory.hide()
            this.playerMessage = ""

            if (this.incomingMessageMeta.text) {
                this.doDynamicDialog(this.incomingMessageMeta.text)
                this.haltMessageIndicator()
            }
            return ""
        }

        if (messageToSend === "/help") {
            this.logic.playerController.client.logActivity("invoke help")

            this.typing.removeText()
            this.chatHistory.hide()
            this.playerMessage = ""
            this.help(() => {})

            return ""
        }

        if (messageToSend === "/upgrade") {
            this.logic.playerController.client.invokeStatUpgradeUI()
            this.typing.removeText()
            this.chatHistory.hide()
            this.playerMessage = ""

            return ""
        }

        if (messageToSend === "/about") {
            this.logic.playerController.client.logActivity("invoke about")

            this.typing.removeText()
            this.chatHistory.hide()
            this.playerMessage = ""
            this.about()

            return ""
        }

        this.chatHistoryTextTimer = setTimeout(() => {
            this.chatHistory.hide()
        }, 10000)

        this.lastPlayerMessage = messageToSend
        this.playerMessage = ""
        this.typing.removeText()

        return messageToSend
    }

    addScreenMessage = (message: ScreenMessage) => {
        const { target, text, roomId, action, actionToken, context, suppressOnCloseEmitEvent } = message
        if (suppressOnCloseEmitEvent && this.currentModal) {
            this.currentModal.modal.suppressOnCloseCallback = suppressOnCloseEmitEvent
        }

        if (!!roomId && roomId !== this.currentRoomId) {
            return
        }

        if (action === "close" && target !== "featureWidget") {
            this.closeModal()
            return
        }

        if (action === "openDynamicDialog") {
            this.dynamicDialogToken = actionToken
        }

        if (!target) {
            this.screenMessages.push(message)
            this.consumeMessages()
        } else {
            if (target === "leaderboard") {
                this.updateBannerText(text)
            }
            if (target === "important") {
                this.importantMessage.update(message)
                this.importantMessage.y = this.importantMessage.height / 2 - 55
                this.importantMessage.x = ScreenDimensions.w * 0.61 - this.importantMessage.width / 2
            }
            if (target === "dialog") {
                if (message.forceTarget) {
                    this.doDynamicDialog(message)
                } else {
                    this.availableMessage(message)
                }
            }
            if (target === "stickyBanner") {
                this.stickyBanner.setup(message.text, message.context?.icon, message.context?.hoverText)
                this.stickyBanner.x = this.controlBar.x - this.stickyBanner.width - TileSize
                this.dayCycle.x = this.stickyBanner.x - TileSize * 1.25
            }
            if (target === "featureWidget") {
                this.featureWidget.setup(message)
            }
        }
    }

    updateLeaderboard = (leaderboards: LeaderboardMeta[] | null) => {
        this.leaderboard.update(leaderboards)
    }

    updateInventory = (owner: Entity, inventory: Inventory) => {
        if (this.inventoryUpdateTs.get(owner.entityId) === inventory.lastUpdateTs) {
            return false
        }

        this.inventoryUpdateTs.set(owner.entityId, inventory.lastUpdateTs)

        const player = this.getPlayer()
        if (player.entityId === owner.entityId) {
            if (this.dynamicDialogToken) {
                this.logic.emitConversationResponse(this.dynamicDialogToken)
            }
            this.slotCallbacks = this.equipped.update(inventory, (itemId: string) => {
                this.logic.playerController.selectSlot(itemId)
            })

            this.abilities.update(inventory, (shortcut: string) => {
                this.logic.playerController.activeAbility(shortcut)
            })

            this.abilities.x = this.equipped.x + this.equipped.width

            const ammoBearingWeapon = locateInventoryItemObjects(inventory, "weapon")
                .sort((b, a) => (a.active ? 2 : 1 - (b.active ? 2 : 1)))
                .find((o: InventoryItem) => {
                    return !!WeaponAmmo[o.itemType]
                })

            const shards = locateInventoryItemObjectByItemType(inventory, "shard-metal")
            const sources = [
                {
                    icon: `shard-metal-gray.png`,
                    quantity: shards?.quantity || 0,
                },
            ]

            if (ammoBearingWeapon) {
                const ammoType: ItemTypeMeta = resolveItemTypeToMeta(WeaponAmmo[ammoBearingWeapon.itemTypeMeta.type])
                if (ammoType) {
                    this.resourceBar.visible = true
                    const ammoItem = locateInventoryItemObjectByItemType(inventory, ammoType.type)
                    sources.push({
                        icon: `${ammoType.type}-${ammoType.color}.png`,
                        quantity: ammoItem?.quantity || 0,
                    })
                    this.logic.playerController.updateLongRangedEquippedState(ammoBearingWeapon.active)
                } else {
                    this.logic.playerController.updateLongRangedEquippedState(false)
                }
            } else {
                this.logic.playerController.updateLongRangedEquippedState(false)
            }
            this.resourceBar.setup(sources)
            this.resourceBar.x = this.nameText.x + this.nameText.width + 20
        }

        // this.inventoryGui.update()

        return true
    }

    activateInventorySlot = (slot: number) => {
        const callback = this.slotCallbacks[slot]
        if (callback) {
            callback()
        }
    }

    mouseOverControl = (disable: boolean) => {
        this.logic.enablePlayerMouseAction(!disable)
    }

    private consumeMessages = () => {
        if (this.currentScreenMessage) {
            return
        }

        this.currentScreenMessage = this.screenMessages.shift()
        if (!this.currentScreenMessage) {
            return
        }

        const { text, roomId, displayCallback } = this.currentScreenMessage
        let delayMs = 0
        if (!roomId || roomId === this.currentRoomId) {
            this.bannerText.text = text

            const wordCount = text.split(" ").length
            delayMs = Math.max(1000, ((wordCount * 60) / 200) * 1000)
        }

        setTimeout(() => {
            if (displayCallback) {
                displayCallback()
            }
            this.bannerText.text = ""
            this.currentScreenMessage = null
            this.consumeMessages()
        }, delayMs)
    }

    fadeOut = (immediate?: boolean) => {
        this.blackout.alpha = 0.0
        this.blackout.visible = true
        this.bannerText.text = ""
        if (immediate) {
            this.blackout.alpha = 1.0
        } else {
            const loop = setInterval(() => {
                const newAlpha = Math.min(1.0, this.blackout.alpha + 0.1)
                this.blackout.alpha = newAlpha

                if (newAlpha === 1.0) {
                    clearInterval(loop)
                }
            }, 100)
        }
    }

    fadeIn = () => {
        this.blackout.alpha = 1.0
        this.blackout.visible = true
        const loop = setInterval(() => {
            const newAlpha = Math.max(0.0, this.blackout.alpha - 0.1)
            this.blackout.alpha = newAlpha

            if (newAlpha === 0.0) {
                clearInterval(loop)
                this.blackout.alpha = 0.0
                this.blackout.visible = false
            }
        }, 100)
    }

    roomUpdated = (roomId: EntityId, roomName?: string, suppressMinimap?: boolean) => {
        this.currentRoomId = roomId
        this.currenRoomName = roomName
        this.screenMessages = this.screenMessages.filter(n => !n.roomId || n.roomId === roomId)
        this.controlBar.visible = true
        this.minimapSurface.roomUpdated(this.userSuppressMinimap || suppressMinimap)
        this.featureWidget.remove()
    }

    hideRoomName = (hidden: boolean) => {
        this.roomNameHidden = hidden
    }

    animateAbility = (abilityType: AbilityType, duration?: number) => {
        const pulsar = this.abilities.slotPulsar.get(abilityType)
        if (!pulsar) {
            return
        }

        pulsar.pulse(duration || 750)
    }

    animateLocalEntityCollection = (entity: Entity, target: string, position?: number) => {
        // for now -- all local entity animations relate to ability slot
        const abilityType = target.split(":")[1]
        const location = this.abilities.slotCoordinates.get(abilityType)
        if (!location) {
            return
        }

        const destX = this.abilities.x + location.x + 3
        const destY = this.abilities.y + location.y - 10

        const entityRenderingManager: EntityRenderingManager =
            this.logic.clientRenderer.entity() as EntityRenderingManager
        const clientRenderable: ClientRenderable = entityRenderingManager.renderEntity(entity)

        this.stage.addChild(clientRenderable)
        clientRenderable.x = ScreenDimensions.w * 0.58
        clientRenderable.y = 285

        const increment = 6
        const animationInterval = setInterval(() => {
            const currentDistance = getDistance(clientRenderable.x, clientRenderable.y, destX, destY)
            if (currentDistance < increment) {
                clearInterval(animationInterval)
                // clientRenderable.remove()
                const fadeInterval = setInterval(() => {
                    if (clientRenderable.alpha <= 0.0) {
                        clearInterval(fadeInterval)
                        clientRenderable.remove()
                        return
                    }

                    clientRenderable.alpha -= 0.01
                }, 1)
                return
            }
            if (clientRenderable.alpha > 0.6) {
                clientRenderable.alpha -= 0.01
            }

            clientRenderable.x += clientRenderable.x > destX ? -increment : increment
            clientRenderable.y += clientRenderable.y > destY ? -increment : increment
        }, 1)
    }

    setupJoystick = (container: Container) => {
        if (isMobile()) {
            {
                const joystick = new Joystick({
                    outer: Sprite.from("outer"),
                    inner: Sprite.from("inner"),

                    outerScale: { x: 0.5, y: 0.5 },
                    innerScale: { x: 0.8, y: 0.8 },

                    onChange: data => {
                        const entity = this.logic.playerEntity
                        const { power } = data
                        if (power < 0.9) {
                            return
                        }
                        switch (data.direction) {
                            case "left": {
                                entity.speed2.x = -1
                                entity.speed2.y = 0
                                break
                            }
                            case "right": {
                                entity.speed2.x = 1
                                entity.speed2.y = 0
                                break
                            }
                            case "top": {
                                entity.speed2.x = 0
                                entity.speed2.y = -1
                                break
                            }
                            case "bottom": {
                                entity.speed2.x = 0
                                entity.speed2.y = 1
                                break
                            }
                            case "top_left": {
                                entity.speed2.x = -1
                                entity.speed2.y = -1
                                break
                            }
                            case "top_right": {
                                entity.speed2.x = 1
                                entity.speed2.y = -1
                                break
                            }
                            case "bottom_left": {
                                entity.speed2.x = -1
                                entity.speed2.y = 1
                                break
                            }
                            case "bottom_right": {
                                entity.speed2.x = 1
                                entity.speed2.y = 1
                                break
                            }
                        }
                        const aa = getAngle(
                            { x: entity.location.x + entity.speed2.x, y: entity.location.y + entity.speed2.y },
                            { x: entity.location.x, y: entity.location.y },
                        )
                        entity.movement.angle = aa
                        entity.movement.moving = true
                    },

                    onStart: () => {
                        this.logic.playerController.enabledMouseAction(false)
                    },

                    onEnd: () => {
                        this.logic.playerEntity.speed2.x = 0
                        this.logic.playerEntity.speed2.y = 0
                        const entity = this.logic.playerEntity
                        entity.movement.moving = false
                        this.logic.playerController.enabledMouseAction(true)
                    },
                })
                joystick.x = 80
                joystick.y = BOTTOM - 120
                container.addChild(joystick)
            }

            {
                const sprite = Sprite.from("inner")
                sprite.tint = 0xff0000
                let foo: number
                const joystick = new Joystick({
                    outer: Sprite.from("outer"),
                    inner: sprite,

                    outerScale: { x: 0.5, y: 0.5 },
                    innerScale: { x: 0.8, y: 0.8 },

                    onChange: data => {
                        const entity = this.logic.playerEntity
                        const { power, angle } = data
                        if (power < 1.0) {
                            return
                        }
                        this.logic.playerController.client.fireBullet()

                        let x = 0
                        let y = 0
                        switch (data.direction) {
                            case "left": {
                                x = -1
                                y = 0
                                break
                            }
                            case "right": {
                                x = 1
                                y = 0
                                break
                            }
                            case "top": {
                                x = 0
                                y = -1
                                break
                            }
                            case "bottom": {
                                x = 0
                                y = 1
                                break
                            }
                            case "top_left": {
                                x = -1
                                y = -1
                                break
                            }
                            case "top_right": {
                                x = 1
                                y = -1
                                break
                            }
                            case "bottom_left": {
                                x = -1
                                y = 1
                                break
                            }
                            case "bottom_right": {
                                x = 1
                                y = 1
                                break
                            }
                        }

                        const aa = getAngle(
                            { x: entity.location.x + x, y: entity.location.y + y },
                            { x: entity.location.x, y: entity.location.y },
                        )
                        entity.movement.angle = aa
                    },

                    onStart: () => {
                        this.logic.playerController.enabledMouseAction(false)
                        this.leftJoystickAngleAllowed = false
                        foo = this.getPlayer().movement.angle
                    },

                    onEnd: () => {
                        this.logic.playerEntity.speed2.x = 0
                        this.logic.playerEntity.speed2.y = 0
                        this.logic.playerController.enabledMouseAction(true)
                        this.leftJoystickAngleAllowed = true
                        this.getPlayer().movement.angle = foo
                    },
                })
                joystick.x = 650
                joystick.y = BOTTOM - 120
                container.addChild(joystick)

                joystick.interactive = true
                joystick.on("tap", () => {
                    this.logic.playerController.client.fireBullet()
                })
            }
        }
    }

    socialMedia = (parent?: Container): Graphics => {
        const container = new Graphics()
        container.y = 480
        if (parent) {
            parent.addChild(container)
        }

        const socialMedia = new Graphics()

        const reddit = new Sprite(Loader.shared.resources["reddit"].texture)
        reddit.scale.set(0.05)
        reddit.interactive = true
        reddit.on("click", () => {
            window.open("https://www.reddit.com/r/projectsamsara/")
            this.logic.playerController.client.logActivity(
                `>> ${this.logic.playerEntity?.name || "guest"} visited discord <<`,
            )
        })
        reddit.on("tap", () => {
            window.open("https://www.reddit.com/r/projectsamsara/")
            this.logic.playerController.client.logActivity(
                `>> ${this.logic.playerEntity?.name || "guest"} visited subreddit <<`,
            )
        })

        const discord = new Sprite(Loader.shared.resources["discord"].texture)
        discord.scale.set(0.13)
        discord.x = reddit.x + reddit.width + 12
        discord.y = -1
        discord.interactive = true
        discord.on("click", () => window.open("https://discord.gg/Mbt6UfqW6e"))
        discord.on("tap", () => window.open("https://discord.gg/Mbt6UfqW6e"))

        socialMedia.addChild(reddit)
        socialMedia.addChild(discord)

        container.addChild(socialMedia)

        return container
    }

    collectPlayerAccount = (name: string, doneCallback: Function) => {
        const release = () => {
            this.logic.playerController.enabledKeyboardAction(true)
            this.logic.playerController.enabledMouseAction(true)
        }

        const askForName = () => {
            const dialog = new Graphics()
            this.stage.addChild(dialog)

            const padding = 20

            const container = new Graphics()
            container.x = padding
            container.y = padding
            dialog.addChild(container)

            const bannerText = new TextRenderer().variant("BannerFont").render() as Text
            bannerText.y = 10
            bannerText.text = "Register new player:"
            container.addChild(bannerText)

            const placeHolder = name
            const nameField = new TextInput({
                input: {
                    fontSize: "20pt",
                    padding: "10px",
                    width: "530px",
                    color: "#26272E",
                },
                box: {
                    default: { fill: 0xe8e9f3, rounded: 16, stroke: { color: 0xcbcee0, width: 4 } },
                    focused: { fill: 0xe1e3ee, rounded: 16, stroke: { color: 0xabafc6, width: 4 } },
                    disabled: { fill: 0xdbdbdb, rounded: 16 },
                },
            })

            nameField.text = placeHolder
            nameField.y = 50
            nameField.placeholder = placeHolder
            nameField.placeholderColor = 0x000000

            container.addChild(nameField)
            nameField.focus()

            let currentName = placeHolder
            let currentPassword = ""
            let currentEmail = ""

            const submit = () => {
                if (!currentName) {
                    this.addScreenMessage({
                        target: "important",
                        text: "Player name is required",
                    })
                    return false
                }
                if (!currentPassword) {
                    this.addScreenMessage({
                        target: "important",
                        text: "Password is required",
                    })
                    return false
                }

                release()
                doneCallback(currentName, currentPassword, currentEmail)

                return true
            }

            nameField.on("input", text => {
                currentName = text
            })
            nameField.on("keyup", keycode => {
                if (keycode === 27) {
                    release()
                    container.removeChildren()
                    dialog.parent.removeChild(dialog)
                    return
                }

                if (keycode === 13) {
                    passwordField.focus()
                }
            })

            const passwordField = new TextInput({
                input: {
                    fontSize: "20pt",
                    padding: "10px",
                    width: "530px",
                    color: "#26272E",
                },
                box: {
                    default: { fill: 0xe8e9f3, rounded: 16, stroke: { color: 0xcbcee0, width: 4 } },
                    focused: { fill: 0xe1e3ee, rounded: 16, stroke: { color: 0xabafc6, width: 4 } },
                    disabled: { fill: 0xdbdbdb, rounded: 16 },
                },
            })

            passwordField._dom_input.type = "password"
            passwordField.y = 115
            passwordField.placeholder = "Password"
            passwordField.placeholderColor = 0x000000
            passwordField.on("input", text => {
                currentPassword = text
            })

            passwordField.on("keyup", keycode => {
                if (keycode === 27) {
                    release()
                    container.removeChildren()
                    dialog.parent.removeChild(dialog)
                    return
                }
                if (keycode === 13) {
                    if (submit()) {
                        container.removeChildren()
                        dialog.parent.removeChild(dialog)
                    }
                }
            })

            container.addChild(passwordField)

            const emailField = new TextInput({
                input: {
                    fontSize: "20pt",
                    padding: "10px",
                    width: "530px",
                    color: "#26272E",
                },
                box: {
                    default: { fill: 0xe8e9f3, rounded: 16, stroke: { color: 0xcbcee0, width: 4 } },
                    focused: { fill: 0xe1e3ee, rounded: 16, stroke: { color: 0xabafc6, width: 4 } },
                    disabled: { fill: 0xdbdbdb, rounded: 16 },
                },
            })

            emailField.y = 180
            emailField.placeholder = "Email (optional)"
            emailField.placeholderColor = 0x000000
            emailField.on("input", text => {
                currentEmail = text
            })
            container.addChild(emailField)

            const buttonContainer = new Graphics()
            buttonContainer.y = 245
            container.addChild(buttonContainer)

            {
                const button = new Graphics()
                button.addChild(new TextRenderer().variant("ImportantFont").render("Cancel") as Text)
                button.x = 125
                button.interactive = true
                button.on("click", () => {
                    release()
                    doneCallback()
                    container.removeChildren()
                    dialog.parent.removeChild(dialog)
                })
                buttonContainer.addChild(button)
            }

            {
                const button = new Graphics()
                const text = new TextRenderer().variant("ImportantFont").render("Register") as Text
                text.tint = 0x00ff00
                button.addChild(text)
                button.interactive = true
                button.on("click", () => {
                    if (submit()) {
                        container.removeChildren()
                        dialog.parent.removeChild(dialog)
                        doneCallback()
                    }
                })
                buttonContainer.addChild(button)
            }

            dialog.x = 15
            dialog.y = 50
            dialog.lineStyle(1, 0xffffff, 0.5)
            dialog.beginFill(0x000000, 0.9)
            dialog.drawRoundedRect(0, 0, dialog.width + padding * 2, dialog.height + padding * 2.5, 8)
            dialog.endFill()
        }

        this.logic.playerController.enabledKeyboardAction(false)
        this.logic.playerController.enabledMouseAction(false)

        askForName()
    }

    help = (doneCallback: Function) => {
        const help = new Help(this)
        help.toggle()
    }

    screenDimensions = (): Dimensions => {
        return {
            w: this.stage.width,
            h: this.stage.height,
        }
    }

    createModal = (props: ModalProps) => {
        const modal = new Modal(this.logic, this.stage, props)
        modal.update()

        return modal
    }

    about = () => {
        const about = new About(this)
        about.toggle()
    }

    doDynamicDialog = (message: ScreenMessage) => {
        if (this.currentModal) {
            this.currentModal.updateContent(message)
            return
        }

        if (!message) {
            return
        }

        const dialog = new DynamicDialog(this, this.logic, message, () => {
            if (message.onCloseEmitEvent) {
                this.logic.playerController.client.emitPlayerEvent(message.onCloseEmitEvent)
            }
            this.logic.playerController.client.onDialogClose()
            this.currentModal = undefined
            this.dynamicDialogToken = undefined
        })
        dialog.toggle((clicked: boolean) => {
            if (clicked) {
                // this.dynamicDialogToken = null
                this.closeModal()
            }
        })
        this.currentModal = dialog
    }

    availableMessage = (message: ScreenMessage) => {
        this.incomingMessageMeta.unread = true
        this.incomingMessageMeta.expires = Date.now() + 60 * 1000
        this.incomingMessageMeta.text = { ...message }
        this.helpMessaging()
    }

    haltMessageIndicator = () => {
        clearInterval(this.incomingMessageMeta.flasherInterval)
        this.incomingMessageMeta.unread = false
        this.incomingMessageMeta.expires = null
        this.incomingMessageMeta.text = null
        this.helpMessaging()
    }

    showUpgradeIndicator = () => {}

    closeModal = () => {
        if (this.currentModal) {
            this.currentModal.close()
        }
        this.dynamicDialogToken = null
        this.currentModal = null
    }

    toggleInventory = () => {
        if (!this.dynamicDialogToken) {
            this.logic.invokeInventory(null)
        } else {
            this.closeModal()
        }
    }

    toggleCharacterCustomizer = () => {
        this.characterCustomizer.toggle()
    }

    buildCharacter = (entityId: EntityId) => {
        const player = this.getPlayer()
        if (player?.entityId !== entityId) {
            // for now -- you can only build your own character
            return
        }

        if (!this.characterBuilder.isOpen()) {
            this.characterBuilder.toggle(() => {
                this.logic.playerController.client.finalizeCharacter()
            })
        }
    }

    getPlayer = (): Entity => this.logic.playerEntity

    pulseInventoryItem = (itemId: EntityId) => {
        if (!this.currentModal) {
            return
        }
        this.currentModal.pulseGridItem(itemId)
    }

    mapPointHoverListener = (onMouseOver: boolean, label: string) => {
        // this.container.beginFill(0xFF0000)
        // this.container.drawCircle(705, 454, 20)
        // this.container.endFill()

        const position = this.logic.clientRenderer.mouse().state().position
        if (onMouseOver) {
            const container = new DyanmicTextContainer({
                backdrop: true,
            })
            container.append2(
                new DynamicText(label, {
                    font: "ChatFont",
                }),
            )
            this.mouseHoverElement = container
            this.container.addChild(this.mouseHoverElement)
            this.mouseHoverElement.x =
                5 + this.minimapSurface.x + this.minimapSurface.width / 2 - this.mouseHoverElement.width / 2
            this.mouseHoverElement.y = this.minimapSurface.y - this.mouseHoverElement.height + 2
        } else {
            if (this.mouseHoverElement) {
                this.container.removeChild(this.mouseHoverElement)
            }
        }
    }

    createCursorSprite = (): BasicSprite => {
        if (this.mousePointer) {
            return this.mousePointer
        }
        const mousePointer = new BasicSprite({
            scale: 0.3,
            name: "crosshairs.png",
        })

        mousePointer.x = 0
        mousePointer.y = 0
        mousePointer.visible = false
        this.mousePointer = mousePointer

        this.stage.addChild(mousePointer)

        this.logic.clientRenderer?.mouse().addListener(state => {
            mousePointer.x = state.position.x
            mousePointer.y = state.position.y * 0.77
        })
    }

    pulseDamage = (): void => {
        this.redout.alpha = 0.3
        this.redout.visible = true
        new Fader(this.redout, {
            fadeInterval: 25,
            releaseCallback: () => {
                this.redout.visible = false
            },
        }).update()
    }

    showWorldMap = (map: WorldMapMeta) => {
        const worldMap = new WorldMap(this, map, coordinate => {
            if (this.logic.runLevel === "dev") {
                this.logic.playerController.client.worldMapClicked(coordinate)
                worldMap.close()
            }
        })
        worldMap.toggle()
    }
}
