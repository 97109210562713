import * as fs from "fs"
import { BiomeColorsMap, BiomeMeta, BiomesMap } from "game-common/biome/biomes"
import { Mechanics } from "game-common/mechanics/mechanics"
import {
    Bounds,
    Coordinate,
    Dimensions,
    GeographicFeature,
    LocationIcon,
    MapFeature,
    MapFeatureType,
    TileSize,
    WorldMeta,
} from "game-common/models"
import {
    blankMap,
    fineToRoughBoundsViaDimensions,
    fineToRoughCoordinates,
    randomIntBetweenRng,
    randomRng,
    randomOneOf,
    repeat,
    resolveGlobalToRoomCoordinates,
    randomCoordinateWithinBounds,
    randomName,
    randomId,
} from "game-common/util"

import { autotile } from "./autotiler"
import { FeatureCatalogs, FragmentMaps, FragmentMeta, FragmentPoint, FragmentRenderStrategy } from "./feature_catalog"
import { extractBiomeRegions, extractRegions } from "../tilemap_util"

type AutotileVariant = number | AutotileSpec

type AutotileMap = {
    tilePos?: number
    tilesetId: string
    tiles: Record<number, AutotileVariant>
    autoTileFallback?: number
    mapLayerYDelta?: number
}

export interface TileSpec {
    tilesetId: string
    tilePos: number
    autotileMap?: AutotileMap
}

export interface TileSetSpec {
    tilesetId: string
    firstgid: number
}

export const entityTileSurface = "map3"

export const tileSetSpecs: Record<string, TileSetSpec> = {
    "lpc-terrain": {
        tilesetId: "lpc-terrain",
        firstgid: 1000,
    },
    plants: {
        tilesetId: "plants",
        firstgid: 3000,
    },
    "lpc-flowers-plants-fungi-wood": {
        tilesetId: "lpc-flowers-plants-fungi-wood",
        firstgid: 5000,
    },
    "mountains-v6": {
        tilesetId: "mountains-v6",
        firstgid: 7000,
    },
    ore: {
        tilesetId: "ore",
        firstgid: 10000,
    },
    "jungle-ruins": {
        tilesetId: "jungle-ruins",
        firstgid: 12000,
    },
    build_atlas: {
        tilesetId: "build_atlas",
        firstgid: 14000,
    },
    "decorations-medieval": {
        tilesetId: "decorations-medieval",
        firstgid: 16000,
    },
    roofs: {
        tilesetId: "roofs",
        firstgid: 19000,
    },
    cottage: {
        tilesetId: "cottage",
        firstgid: 24000,
    },
    walls: {
        tilesetId: "walls",
        firstgid: 34000,
    },
    fence_medieval: {
        tilesetId: "fence_medieval",
        firstgid: 44000,
    },
    container: {
        tilesetId: "container",
        firstgid: 54000,
    },
    "furniture-blonde-wood": {
        tilesetId: "furniture-blonde-wood",
        firstgid: 64000,
    },
    "beach-desert": {
        tilesetId: "beach-desert",
        firstgid: 74000,
    },
    floors: {
        tilesetId: "floors",
        firstgid: 84000,
    },
    walls2: {
        tilesetId: "walls2",
        firstgid: 94000,
    },
}

export const AutoTiles: Record<string, Record<number, Record<number, number>>> = {
    "lpc-terrain": {
        353: {
            34: 256,
            42: 257,
            26: 258,
            36: 288,
            28: 290,
            7: 320,
            12: 321,
            4: 322,

            33: 193,
            41: 194,
            44: 225,
            45: 226,
        },
        676: {
            34: 643,
            42: 644,
            26: 645,
            36: 675,
            28: 677,
            7: 707,
            12: 708,
            4: 709,

            33: 580,
            41: 581,
            44: 612,
            45: 613,
        },
    },
}

export const PlatformAutoTiles: Record<string, Record<number, number[]>> = {
    "lpc-terrain": {
        377: [
            // 0 - 13
            344, 345, 346, 376, 378, 408, 409, 410, 440, 441, 442, 472, 473, 474,

            // 14 - 18
            223, 222, 191, 190, 5,

            // 19 - 22
            344, 346, 408, 410,
        ],
    },
    "mountains-v6": {
        837: [
            // 0 - 13
            772, 773, 774, 836, 838, 900, 901, 902, 964, 965, 966, 1028, 1029, 1030,

            // 14 - 18
            839, 835, 1481, 1483, -1,

            // 19 - 22
            772, 774, 900, 902,
        ],
        861: [796, 797, 798, 860, 862, 924, 925, 926, 988, 989, 990, 1052, 1053, 1054, 863, 859, 1505, 1507, 23, 796, 798, 924, 926],
    },
}

export interface MapSpec {
    mapX: number
    mapY: number
    mapSizeX: number
    mapSizeY: number
    roomId: string
    layers: Record<string, MapLayerSpec>
    fineBiomes: string[][]
}

export interface AutotileDecoration {
    offsetX?: number
    offsetY?: number
    tile: number
    mapLayerYDelta?: number
    namedTileLayerId?: string
    tilesetId?: string
}

export interface AutotileSpec {
    tile: number | AutotileDecoration
    decorations?: AutotileDecoration[]
    noUndertile?: boolean
}

export interface RoomMeta {
    biomes: any[]
    spawners: any[]
    features: FeatureMeta[]
    featurePoints: FeaturePointMeta[]
    id?: string
}

export interface RegionMeta {
    id: string
    bounds: Bounds
    fragment?: FragmentMeta
    context?: any
}

export interface FeatureMeta {
    roomId: string
    spawnLocation: Coordinate
    type: string
    region: any
    bounds: Bounds
    featureSpriteMeta: any[]
    subFeaturesMeta: RegionMeta[]
    subFeaturesRegions: any[]
    featurePoints: FeaturePointMeta[]
    postFeatureMapEntries: MapEntry[]
    debug?: DebugMeta[]
    mapFeature?: MapFeature
}

export interface BuilderOutputSpec {
    roomMaps: Map<string, any>
    worldMeta: WorldMeta
}

class MapLayerSpec {
    layerId: string
    layerNumber: number
    columns: number[][]
    blank: boolean = true
    autoTiles: Map<number, AutotileMap> = new Map()
    mapSizeX: number
    mapSizeY: number

    occlusion?: boolean
    blocking?: boolean
    sky?: boolean
    skyTrigger?: boolean
    sprite?: boolean

    constructor(mapId: string, mapNumber: number, mapSizeX: number, mapSizeY: number) {
        this.layerId = mapId
        this.layerNumber = mapNumber
        this.mapSizeX = mapSizeX
        this.mapSizeY = mapSizeY
        this.columns = blankMap<number>(mapSizeX, mapSizeY, 0)
    }

    toRowsWiseArray = (tile: number) => {
        const rows = []
        for (let y = 0; y < this.mapSizeY; y++) {
            const row = []
            rows.push(row)
            for (let x = 0; x < this.mapSizeX; x++) {
                const column = this.columns[x]
                if (column) {
                    row.push(column[y] === tile ? 1 : 0)
                } else {
                    row.push(0)
                }
            }
        }

        return rows
    }

    toArray = () => {
        const array: number[] = []
        for (let y = 0; y < this.mapSizeY; y++) {
            for (let x = 0; x < this.mapSizeX; x++) {
                const column = this.columns[x]
                if (column) {
                    array.push(column[y])
                }
            }
        }

        return array
    }

    get = (x: number, y: number) => {
        const column = this.columns[x]
        if (column) {
            return column[y] || 0
        }

        return 0
    }

    set = (x: number, y: number, tile: number) => {
        const column = this.columns[x]
        if (column) {
            column[y] = tile
            return true
        }
    }
}

type AdderResultTile = TileSpec & {
    mapLayerId: string
    x?: number
    y?: number
    containmentRange?: number
    containmentBiome?: string
    overwrite?: boolean
}

export type AdderFeatureRegionMeta = {
    x: number
    y: number
    width: number
    height: number
    context: any
    id?: string
    mapFeature?: MapFeature
}

export type AdderResult = {
    tiles: AdderResultTile[]
    featureRegionMeta?: AdderFeatureRegionMeta[]
    pointMeta?: FragmentPoint[]
}

export type ReplaceFunction = (currentTilePos: number) => TileSpec
export type AdderFunction = (currentTilePos: number, mapId: string, x: number, y: number) => AdderResult
export type FeatureFunctionResult = any
export type FeatureFunction = () => FeatureFunctionResult

export interface DebugMeta {
    x: number
    y: number
    text: string
}

export interface MapEntry {
    mapId: string
    tileSpec: TileSpec
    layerId: string
    x: number
    y: number
    containmentRange?: number
    containmentBiome?: string
    replacer?: ReplaceFunction
    adder?: AdderFunction
    feature?: FeatureFunction
    forcedContainmentOk?: boolean
    mapSpriteCap?: any
    context?: any
}

export interface FragmentMapEntry {
    id?: string
    x: number
    y: number
    tilePos: number
    context?: any
}

export type FragmentPointMeta = FragmentPoint
export type FeaturePointMeta = FragmentPoint

export interface FragmentSpriteMeta {
    id: string
    mapEntries: MapEntry[]
    postFeatureMapEntries: MapEntry[]
    spriteEntries: FragmentMapEntry[]
    width: number
    height: number
    location: Coordinate
    context?: any
    subFeatures: RegionMeta[]
}

export const DefaultTileSpec: TileSpec = {
    tilesetId: "lpc-terrain",
    tilePos: 121,
}

export interface FeatureSpec {
    dimensions: Dimensions
    fragments: FragmentMeta[]
    mapEntries: MapEntry[]
    postFeatureEntries?: MapEntry[]
    debug?: DebugMeta[]
}

export interface GlobalMapProperties {
    dayPhaseSupported?: boolean
    suppressMinimap?: boolean
    darkness?: number
    autoVisibility?: boolean
    fullOcclusion?: boolean
    name?: string
    campaigns?: string[]
}

export interface BaseMapBuilderProps {
    globalSize: Dimensions
    quadrantSize: Dimensions
    mapSize: number
    roomPrefix: string
    featureCatalogs?: string[]
    mapProperties?: GlobalMapProperties
}

export class BaseMapBuilder {
    globalWidth: number
    globalHeight: number

    maps: Record<string, MapSpec> = {}
    globalMap: MapSpec
    objectId: number = 1
    regionId: number = 1
    objectSequenceId: number = 1
    mapWidth: number
    mapHeight: number
    deferredMapEntries: MapEntry[] = []
    finalizationEntries: MapEntry[] = []
    featureEntries: MapEntry[] = []
    biomeMap: BiomeMeta[][]
    featureCatalogs: FeatureCatalogs = FeatureCatalogs.instance()
    mapSize: number
    roomPrefix: string
    globalMapProperties: GlobalMapProperties
    defaultSpawnCoordinate?: Coordinate
    quadrantDimensions: Dimensions
    roomMeta: Map<string, RoomMeta>

    constructor(props: BaseMapBuilderProps) {
        const { w: width, h: height } = props.globalSize

        this.globalWidth = width
        this.globalHeight = height
        this.globalMapProperties = props.mapProperties
        this.roomPrefix = props.roomPrefix
        this.quadrantDimensions = props.quadrantSize

        this.mapSize = props.mapSize
        this.mapWidth = width / props.mapSize
        this.mapHeight = height / props.mapSize

        const makeLayers = (mapSizeX: number, mapSizeY: number) => {
            const subMapCount = 6
            const sky3 = new MapLayerSpec(`sky3`, subMapCount + 9, mapSizeX, mapSizeY)
            sky3.sky = true

            const sky2 = new MapLayerSpec(`sky2`, subMapCount + 8, mapSizeX, mapSizeY)
            sky2.sky = true

            const sky = new MapLayerSpec(`sky`, subMapCount + 7, mapSizeX, mapSizeY)
            sky.sky = true

            const skyTrigger = new MapLayerSpec(`skyTrigger`, subMapCount + 6, mapSizeX, mapSizeY)
            skyTrigger.skyTrigger = true

            const occlusion = new MapLayerSpec(`occlusion`, subMapCount + 5, mapSizeX, mapSizeY)
            occlusion.occlusion = true

            const blocking = new MapLayerSpec(`blocking`, subMapCount + 4, mapSizeX, mapSizeY)
            blocking.blocking = true

            const sprite3 = new MapLayerSpec(`sprite3`, subMapCount + 3, mapSizeX, mapSizeY)
            sprite3.sprite = true

            const sprite2 = new MapLayerSpec(`sprite2`, subMapCount + 2, mapSizeX, mapSizeY)
            sprite2.sprite = true

            const sprite = new MapLayerSpec(`sprite`, subMapCount + 1, mapSizeX, mapSizeY)
            sprite.sprite = true

            const layers = {
                sky,
                sky2,
                sky3,
                skyTrigger,
                occlusion,
                blocking,
                sprite,
                sprite2,
                sprite3,
            }
            for (let i = 1; i <= subMapCount; i++) {
                layers[`map${i}`] = new MapLayerSpec(`map${i}`, i, mapSizeX, mapSizeY)
            }
            return layers
        }

        for (let x = 0; x < this.mapWidth; x++) {
            for (let y = 0; y < this.mapHeight; y++) {
                const roomId = `${this.roomPrefix}-${x}-${y}`
                const map: MapSpec = {
                    mapX: x,
                    mapY: y,
                    mapSizeX: this.mapSize,
                    mapSizeY: this.mapSize,
                    roomId,
                    layers: makeLayers(this.mapSize, this.mapSize),
                    fineBiomes: blankMap(this.mapSize, this.mapSize, ""),
                }
                this.maps[roomId] = map
            }
        }

        this.globalMap = {
            mapX: 0,
            mapY: 0,
            mapSizeX: width,
            mapSizeY: height,
            roomId: "global",
            layers: makeLayers(width, height),
            fineBiomes: blankMap<string>(width, height, ""),
        }
    }

    pctChance = (pct: number) => {
        // min and max included
        return randomIntBetweenRng(0, 100) >= 100 - pct
    }

    randomIntBetween = (min: number, max: number) => randomIntBetweenRng(min, max)

    random = () => randomRng()

    randomOneOf = <T>(
        list: T[],
        options?: {
            leftBoundIdx?: number
            rightBoundIdx?: number
            randomIntBetween?: Function
        },
    ): T => {
        return randomOneOf<T>(list, {
            ...(options ? {} : options),
            randomIntBetween: this.randomIntBetween,
        })
    }

    randomFloatBetween = (min: number, max: number) => {
        const str = (this.random() * (max - min) + min).toFixed(3)
        return parseFloat(str)
    }

    randomCoordinateWithinBounds = (bounds: Bounds) => {
        return randomCoordinateWithinBounds(bounds, true)
    }

    getGlobal = (gX: number, gY: number, layerId: string, tilesetId?: string) => {
        const layer = this.getGlobalLayerFor(gX, gY, layerId)
        if (!layer) {
            return 0
        }
        const x = gX % this.mapSize
        const y = gY % this.mapSize

        const absoluteTilePos = layer.get(x, y)

        if (tilesetId) {
            const tileset = tileSetSpecs[tilesetId]
            if (tileset) {
                return absoluteTilePos - tileset.firstgid
            }
        }

        return absoluteTilePos
    }

    setGlobal = (gX: number, gY: number, layerId: string, tile: number) => {
        const layer = this.getGlobalLayerFor(gX, gY, layerId)
        if (!layer) {
            return
        }
        const x = gX % this.mapSize
        const y = gY % this.mapSize

        return layer.set(x, y, tile)
    }

    getGlobalLayerFor = (gX: number, gY: number, layerId: string) => {
        const roomId = `${this.roomPrefix}-${Math.floor(gX / this.mapSize)}-${Math.floor(gY / this.mapSize)}`
        const map = this.maps[roomId]
        if (!map) {
            return
        }

        return map.layers[layerId]
    }

    // random mountain
    scanPoint = (biome: string, gX: number, gY: number) => {
        const bX = this.biomeMap[gX]
        const bY = bX ? bX[gY] : undefined
        if (bY && bY.name === biome) {
            return true
        }
        return false
    }

    applyEntries = (mapEntries: MapEntry[] = [], roomMetas?: Map<string, RoomMeta>) => {
        const meetsContainment = (x: number, y: number, containmentBiome: string, containmentRange: number) => {
            let contained = true
            const stride = containmentRange
            for (let sY = -stride; sY <= stride; sY++) {
                for (let sX = -stride; sX <= stride; sX++) {
                    if (!this.scanPoint(containmentBiome, sX + x, sY + y)) {
                        contained = false
                        break
                    }
                }
                if (!contained) {
                    break
                }
            }

            return contained
        }

        mapEntries.forEach(entry => {
            const {
                tileSpec,
                layerId,
                x,
                y,
                containmentBiome,
                containmentRange,
                forcedContainmentOk,
                replacer,
                adder,
                feature,
            } = entry
            if (tileSpec.tilePos < 0) {
                // remove
                const mapLayer = this.globalMap.layers[layerId]
                mapLayer.set(x, y, 0)
                this.setGlobal(x, y, layerId, 0)
            } else if (feature) {
                const needsContainment = !!containmentBiome && !!containmentRange
                const contained =
                    forcedContainmentOk ||
                    !needsContainment ||
                    meetsContainment(x, y, containmentBiome, containmentRange)

                if (contained) {
                    feature()
                }
            } else if (replacer) {
                // replace
                const firstgid = tileSetSpecs[tileSpec.tilesetId].firstgid
                const mapLayer = this.globalMap.layers[layerId]
                const absoluteTilePos = mapLayer.get(x, y)
                const currentTilePos = absoluteTilePos - firstgid

                const needsContainment = !!containmentBiome && !!containmentRange
                const contained = !needsContainment || meetsContainment(x, y, containmentBiome, containmentRange)

                if (contained) {
                    const updatedTileSpec = replacer(currentTilePos)
                    if (updatedTileSpec !== undefined) {
                        const replacementFirstgid = tileSetSpecs[updatedTileSpec.tilesetId].firstgid
                        const updatedTileId = replacementFirstgid + updatedTileSpec.tilePos
                        mapLayer.set(x, y, updatedTileId)
                        this.setGlobal(x, y, layerId, updatedTileId)
                    }
                }
            } else if (adder) {
                /// add
                const firstgid = tileSetSpecs[tileSpec.tilesetId].firstgid
                const mapLayer = this.globalMap.layers[layerId]
                const absoluteTilePos = mapLayer.get(x, y)
                const currentTilePos = absoluteTilePos - firstgid

                const needsContainment = !!containmentBiome && !!containmentRange
                const contained =
                    forcedContainmentOk ||
                    !needsContainment ||
                    meetsContainment(x, y, containmentBiome, containmentRange)

                if (contained) {
                    const { tiles: addedTileSpecs, featureRegionMeta, pointMeta }: AdderResult = adder(
                        currentTilePos,
                        entry.mapId,
                        x,
                        y,
                    ) || {
                        tiles: [],
                    }

                    if (pointMeta && roomMetas) {
                        const roomMeta = roomMetas.get(entry.mapId)
                        pointMeta.forEach(point => {
                            roomMeta.featurePoints.push(point)
                        })
                    }

                    if (featureRegionMeta && roomMetas) {
                        const roomMeta = roomMetas.get(entry.mapId)
                        featureRegionMeta.forEach(regionMeta => {
                            const found = roomMeta.features.find(
                                n => n.region.x === regionMeta.x && n.region.y === regionMeta.y,
                            )
                            if (found) {
                                return
                            }
                            const region = this.toFeatureRegion(
                                regionMeta.x,
                                regionMeta.y,
                                regionMeta.width,
                                regionMeta.height,
                                regionMeta.id || randomId(),
                                regionMeta.context,
                            )
                            const feature: FeatureMeta = {
                                roomId: entry.mapId,
                                spawnLocation: {
                                    x: regionMeta.x,
                                    y: regionMeta.y,
                                },
                                type: "feature",
                                region,
                                bounds: new Bounds(
                                    regionMeta.x,
                                    regionMeta.y,
                                    regionMeta.x + regionMeta.width,
                                    regionMeta.y + regionMeta.height,
                                ),
                                featureSpriteMeta: [],
                                subFeaturesMeta: [],
                                subFeaturesRegions: [],
                                featurePoints: [],
                                postFeatureMapEntries: [],
                                mapFeature: regionMeta.mapFeature,
                            }
                            roomMeta.features.push(feature)
                        })
                    }

                    addedTileSpecs.forEach(addedTileSpec => {
                        const addedTileContainmentBiome = addedTileSpec.containmentBiome || containmentBiome
                        const addedContaimentRange = addedTileSpec.containmentRange || containmentRange

                        const addedTileX = addedTileSpec?.x || x
                        const addedTileY = addedTileSpec?.y || y
                        const addedNeedsContainment = !!addedTileContainmentBiome && !!addedContaimentRange
                        const addedContained =
                            !addedNeedsContainment ||
                            meetsContainment(addedTileX, addedTileY, addedTileContainmentBiome, addedContaimentRange)

                        if (addedContained) {
                            const replacementFirstgid = tileSetSpecs[addedTileSpec.tilesetId].firstgid
                            const updatedTileId = replacementFirstgid + addedTileSpec.tilePos
                            const updatedTileMapLayer = this.globalMap.layers[addedTileSpec.mapLayerId]

                            const current = updatedTileMapLayer.get(addedTileX, addedTileY)
                            if (addedTileSpec.overwrite || !current) {
                                if (addedTileSpec.tilePos < 1) {
                                    // remove
                                    updatedTileMapLayer.set(addedTileX, addedTileY, 0)
                                    this.setGlobal(addedTileX, addedTileY, addedTileSpec.mapLayerId, 0)
                                } else {
                                    updatedTileMapLayer.set(addedTileX, addedTileY, updatedTileId)
                                    this.setGlobal(addedTileX, addedTileY, addedTileSpec.mapLayerId, updatedTileId)
                                }
                            }
                        }
                    })
                }
            } else {
                // set
                const firstgid = tileSetSpecs[tileSpec.tilesetId].firstgid
                const tileId = firstgid + tileSpec.tilePos
                const needsContainment = !!containmentBiome && !!containmentRange
                const contained = !needsContainment || meetsContainment(x, y, containmentBiome, containmentRange)

                if (contained) {
                    const mapLayer = this.globalMap.layers[layerId]
                    mapLayer.set(x, y, tileId)
                    if (tileSpec.autotileMap) {
                        mapLayer.autoTiles.set(tileId, tileSpec.autotileMap)
                    }
                    this.setGlobal(x, y, layerId, tileId)
                    this.getGlobalLayerFor(x, y, layerId)?.autoTiles.set(tileId, tileSpec.autotileMap)
                }
            }
        })
    }

    applyAutotiling = (
        mapSpec: MapLayerSpec,
        callback: (
            x: number,
            y: number,
            tilesetId: string,
            tile: AutotileVariant,
            variantPos: number,
            layerUpNumber: number,
            layerUpId: string,
        ) => void,
    ) => {
        const s = new Set()
        for (let tileId of mapSpec.autoTiles.keys()) {
            const autoTileSpec = mapSpec.autoTiles.get(tileId)
            const rowsWise = mapSpec.toRowsWiseArray(tileId)
            const autoTiled = autotile(rowsWise)

            const thisLayerNumber = mapSpec.layerNumber
            const layerUpNumber = thisLayerNumber + (autoTileSpec.mapLayerYDelta || 1)
            const layerUpId = `map${layerUpNumber}`

            for (let y = 0; y < mapSpec.mapSizeY; y++) {
                const row = autoTiled[y]
                for (let x = 0; x < mapSpec.mapSizeX; x++) {
                    const colValue = row[x]
                    const tileVariant: AutotileVariant = autoTileSpec.tiles[colValue]
                    const tilesetId = autoTileSpec.tilesetId
                    const layerIdTouse =
                        typeof tileVariant === "number"
                            ? layerUpId
                            : typeof tileVariant?.tile === "number"
                            ? layerUpId
                            : tileVariant?.tile?.namedTileLayerId || layerUpId
                    if (tileVariant !== undefined) {
                        callback(x, y, tilesetId, tileVariant, colValue, layerUpNumber, layerIdTouse)

                        // record
                        if (false) {
                            const tileset: TileSetSpec = tileSetSpecs[tilesetId]
                            console.log(
                                "hit",
                                tilesetId,
                                tileId - tileset.firstgid,
                                resolveGlobalToRoomCoordinates(x, y, this.mapSize, this.mapSize),
                                `global ${x}, ${y}`,
                                colValue,
                            )
                        }
                    } else {
                        if (autoTileSpec.tilePos === 353) {
                            s.add(colValue)
                        }

                        // record
                        if (![46, 0].includes(colValue)) {
                            if (true) {
                                const tileset: TileSetSpec = tileSetSpecs[tilesetId]
                                console.log(
                                    "miss",
                                    tilesetId,
                                    tileId - tileset.firstgid,
                                    resolveGlobalToRoomCoordinates(x, y, this.mapSize, this.mapSize),
                                    `global ${x}, ${y}`,
                                    colValue,
                                )
                            }
                        }

                        if (![46, 0].includes(colValue)) {
                            if (mapSpec.layerId === "map1") {
                                callback(
                                    x,
                                    y,
                                    "lpc-terrain",
                                    autoTileSpec.autoTileFallback || 100,
                                    -1,
                                    layerUpNumber,
                                    layerIdTouse,
                                )
                            } else {
                                callback(x, y, tilesetId, -1, colValue, layerUpNumber, layerIdTouse)
                            }
                        }
                    }
                }
            }
        }
        if (s.size > 0) {
            console.log("missing", s)
        }
    }

    toTiledLayer = (mapSpec: MapLayerSpec) => {
        const campaigns = (this.globalMapProperties.campaigns || []).map(campaign => ({
            name: `campaign:${campaign}`,
            type: "string",
            value: "",
        }))

        const tiled = {
            data: mapSpec.toArray(),
            height: mapSpec.mapSizeY,
            id: mapSpec.layerNumber,
            name: mapSpec.layerId,
            opacity: mapSpec.blocking || mapSpec.occlusion ? 0.175 : 1,
            properties: [
                mapSpec.blocking
                    ? {
                          name: "blocking",
                          type: "boolean",
                          value: true,
                      }
                    : undefined,
                mapSpec.sky
                    ? {
                          name: "sky",
                          type: "boolean",
                          value: true,
                      }
                    : undefined,
                mapSpec.skyTrigger
                    ? {
                          name: "skyTrigger",
                          type: "boolean",
                          value: true,
                      }
                    : undefined,
                mapSpec.sprite
                    ? {
                          name: "sprite",
                          type: "boolean",
                          value: true,
                      }
                    : undefined,
                mapSpec.layerId === entityTileSurface
                    ? {
                          name: "entityTileSurface",
                          type: "boolean",
                          value: true,
                      }
                    : undefined,
                mapSpec.layerId === "map1"
                    ? {
                          name: "campaign:encounters",
                          type: "string",
                          value: "",
                      }
                    : undefined,
                mapSpec.layerId === "map1"
                    ? {
                          name: "campaign:default",
                          type: "string",
                          value: "hide_room_name:true",
                      }
                    : undefined,
                mapSpec.layerId === "map1"
                    ? {
                          name: "campaign:standard",
                          type: "string",
                          value: "",
                      }
                    : undefined,
                mapSpec.layerId === "map1"
                    ? {
                          name: "campaign:respawn",
                          type: "string",
                          value: "",
                      }
                    : undefined,
                ...campaigns,
            ].filter(n => !!n),
            type: "tilelayer",
            visible: true,
            width: mapSpec.mapSizeX,
            x: 0,
            y: 0,
        }

        return tiled
    }

    toTiledTileset = (tileSetSpec: TileSetSpec) => {
        const rootDir = `${process.cwd()}/../..`
        const tiled = {
            firstgid: tileSetSpec.firstgid,
            source: `${rootDir}/content/tiled/${tileSetSpec.tilesetId}.tsx`,
        }

        return tiled
    }

    toMapPath = (mapName: string) => {
        const rootDir = `${process.cwd()}/../..`
        return `${rootDir}/packages/server/assets/templates/${mapName}.tmj`
    }

    toWorldMetaPath = () => {
        const rootDir = `${process.cwd()}/../..`
        return `${rootDir}/packages/server/assets/world/world_meta.json`
    }

    toRoomOutputPath(roomId: string) {
        const rootDir = `${process.cwd()}/../..`
        return `${rootDir}/packages/server/assets/rooms/${roomId}.tmj`
    }

    toSpawnRegion = (x: number, y: number, width: number, height: number, spawner: {}): {} => {
        return {
            class: "REGION",
            height: height,
            id: ++this.objectId,
            name: `spawn-region-${++this.objectSequenceId}`,
            properties: [
                {
                    name: "spawner",
                    type: "string",
                    value: JSON.stringify(spawner, null, 1),
                },
            ],
            rotation: 0,
            visible: true,
            width: width,
            x: x,
            y: y,
        }
    }

    toMapSpriteCap = (x: number, y: number, context: any): {} => {
        return {
            class: "mapSpriteCap",
            height: 0,
            id: ++this.objectId,
            name: "",
            point: true,
            rotation: 0,
            visible: true,
            width: 0,
            x: x,
            y: y,
            properties: context
                ? [
                      {
                          name: "context",
                          type: "string",
                          value: JSON.stringify(context, null, 1),
                      },
                  ]
                : undefined,
        }
    }

    toDebugObject = (text: string, x: number, y: number) => {
        return {
            class: "",
            height: 18,
            id: ++this.objectId,
            name: "",
            rotation: 0,
            text: {
                text,
                wrap: true,
                color: "#ff2600",
            },
            visible: true,
            width: 84,
            x: x + 8,
            y: y + 8,
        }
    }

    toFeatureRegion = (x: number, y: number, width: number, height: number, feature: string, context?: any): {} => {
        return {
            class: "FEATURE_REGION",
            height: height,
            id: ++this.objectId,
            name: `feature-region-${++this.objectSequenceId}`,
            properties: [
                {
                    name: "feature",
                    type: "string",
                    value: feature,
                },
                context
                    ? {
                          name: "context",
                          type: "string",
                          value: JSON.stringify(context, null, 1),
                      }
                    : undefined,
            ].filter(n => !!n),
            rotation: 0,
            visible: true,
            width: width,
            x: x,
            y: y,
        }
    }

    toFeatureSpriteRegion = (fragmentSpriteMeta: FragmentSpriteMeta) => {
        const properties = fragmentSpriteMeta.spriteEntries.map(next => {
            return {
                name: `${next.x},${next.y}^${next.id}`,
                type: "string",
                value: JSON.stringify({
                    context: next.context,
                    tileId: next.tilePos,
                }),
            }
        })
        if (!!fragmentSpriteMeta.context) {
            properties.push({
                name: "context",
                type: "string",
                value: JSON.stringify(fragmentSpriteMeta.context) as any,
            })
        }
        return {
            class: "FEATURE_SPRITE_REGION",
            height: fragmentSpriteMeta.height * TileSize,
            id: ++this.objectId,
            name: `feature-sprite-region-${++this.objectSequenceId}`,
            properties: properties,
            rotation: 0,
            visible: true,
            width: fragmentSpriteMeta.width * TileSize,
            x: fragmentSpriteMeta.location.x * TileSize,
            y: fragmentSpriteMeta.location.y * TileSize,
        }
    }

    toBiomeRegion = (x: number, y: number, width: number, height: number, biome: string): {} => {
        return {
            class: "BIOMEREGION",
            height: height,
            id: ++this.objectId,
            name: `biome-region-${++this.objectSequenceId}`,
            properties: [
                {
                    name: "biome",
                    type: "string",
                    value: biome,
                },
            ],
            rotation: 0,
            visible: true,
            width: width,
            x: x,
            y: y,
        }
    }

    toNavRegion = (
        x: number,
        y: number,
        width: number,
        height: number,
        map: MapSpec,
        deltaMapX: number,
        deltaMapY: number,
    ) => {
        const nextMapX = map.mapX + deltaMapX
        const nextMapY = map.mapY + deltaMapY
        if (nextMapX < 0 || nextMapY < 0 || nextMapX > this.mapWidth || nextMapY > this.mapHeight) {
            return null
        }

        const nextRoomId = `${this.roomPrefix}-${nextMapX}-${nextMapY}`
        return {
            class: "REGION",
            height: height,
            id: ++this.objectId,
            name: `nav-region-${++this.regionId}`,
            properties: [
                {
                    name: "navToRoomId",
                    type: "string",
                    value: nextRoomId,
                },
                {
                    name: "deltaMapX",
                    type: "number",
                    value: deltaMapX,
                },
                {
                    name: "deltaMapY",
                    type: "number",
                    value: deltaMapY,
                },
            ],
            rotation: 0,
            visible: true,
            width: width,
            x: x,
            y: y,
        }
    }

    toObjectLayer = (map: MapSpec, biomes, biomeSpawners, features, featurePoints) => {
        return {
            draworder: "topdown",
            id: 1,
            name: "objects",
            objects: [
                {
                    class: "LOCATION",
                    height: 0,
                    id: ++this.objectId,
                    name: "spawn",
                    point: true,
                    rotation: 0,
                    visible: true,
                    width: 0,
                    x: this.defaultSpawnCoordinate?.x || 25 * 32,
                    y: this.defaultSpawnCoordinate?.y || 25 * 32,
                },
                // north
                this.toNavRegion(0, 0, TileSize * map.mapSizeX, 4, map, 0, -1),
                // south
                this.toNavRegion(
                    0,
                    TileSize * (map.mapSizeY - 1) + TileSize - 4,
                    TileSize * map.mapSizeX,
                    4,
                    map,
                    0,
                    1,
                ),
                // east
                this.toNavRegion(
                    TileSize * (map.mapSizeX - 1) + TileSize - 4,
                    0,
                    4,
                    TileSize * map.mapSizeY,
                    map,
                    1,
                    0,
                ),
                // west
                this.toNavRegion(0, 0, 4, TileSize * map.mapSizeY, map, -1, 0),
                ...biomes,
                ...biomeSpawners,
                ...features.map(feature => feature.region),
                ...features.map(feature => feature.featureSpriteMeta).flat(),
                ...features
                    .map(feature => {
                        return feature.subFeaturesRegions.flat()
                    })
                    .flat(),
                ...features
                    .map(feature => feature.featurePoints)
                    .flat()
                    .map((point: FeaturePointMeta) => ({
                        class: "LOCATION",
                        height: 0,
                        id: ++this.objectId,
                        name: point.id,
                        point: true,
                        rotation: 0,
                        visible: true,
                        width: 0,
                        x: point.location.x,
                        y: point.location.y,
                        properties: [{ name: "context", value: point.context }],
                    })),
                ...features.map(feature => feature.debug).flat(),
                ...featurePoints.flat().map((point: FeaturePointMeta) => ({
                    class: "LOCATION",
                    height: 0,
                    id: ++this.objectId,
                    name: point.id,
                    point: true,
                    rotation: 0,
                    visible: true,
                    width: 0,
                    x: point.location.x,
                    y: point.location.y,
                    properties: [{ name: "context", value: point.context }],
                })),
            ].filter(n => !!n),
            opacity: 1,
            type: "objectgroup",
            visible: true,
            x: 0,
            y: 0,
        }
    }

    toVisibility = (id: number) => {
        return {
            draworder: "topdown",
            id: id,
            name: "visibility",
            objects: [],
            opacity: 1,
            properties: [
                this.globalMapProperties.autoVisibility !== undefined
                    ? {
                          name: "auto",
                          type: "bool",
                          value: this.globalMapProperties.autoVisibility,
                      }
                    : undefined,

                this.globalMapProperties.fullOcclusion !== undefined
                    ? {
                          name: "fullOcclusion",
                          type: "bool",
                          value: this.globalMapProperties.fullOcclusion,
                      }
                    : undefined,

                this.globalMapProperties.darkness !== undefined
                    ? {
                          name: "darkness",
                          type: "number",
                          value: this.globalMapProperties.darkness,
                      }
                    : undefined,
            ].filter(n => !!n),
            type: "objectgroup",
            visible: true,
            x: 0,
            y: 0,
        }
    }

    analyzeBiomeSector = (map: MapSpec, bX: number, bY: number) => {
        const biomeCounts = {}
        for (let gX = bX * 10; gX < bX * 10 + 10; gX++) {
            for (let gY = bY * 10; gY < bY * 10 + 10; gY++) {
                const biomeName = map.fineBiomes[gX][gY]
                let count = biomeCounts[biomeName] || 0
                biomeCounts[biomeName] = count + 1
            }
        }
        let maxCount = 0
        let max = ""
        Object.keys(biomeCounts).forEach(biome => {
            const count = biomeCounts[biome]
            if (count > maxCount) {
                max = biome
                maxCount = count
            }
        })

        return max
    }

    biomeSpawnMeta = (biome: string) => {
        if (biome === "Plains_high") {
            return {
                strategy: "lineOfSight",
                diurnal: {
                    creatures: [
                        {
                            race: "rabbit",
                            probability: 0.5,
                        },
                        {
                            race: "snake",
                            probability: 0.1,
                        },
                        {
                            race: "chicken",
                            probability: 0.5,
                        },
                        {
                            race: "bee",
                            probability: 0.5,
                        },
                        {
                            race: "wasp",
                            probability: 0.1,
                            hp: 1,
                            minQuantity: 5,
                            maxQuantity: 8,
                            exemptFromMax: true,
                        },
                    ],
                    max: 2,
                },
                nocturnal: {
                    creatures: [
                        {
                            race: "skeleton",
                            probability: 0.8,
                            hp: 3,
                            bufferTs: 5000,
                            maxQuantity: 2,
                            weaponTypeRange: ["dagger", "spear", "none", "none", "none", "bow"],
                        },
                    ],
                    max: 2,
                },
            }
        }

        if (biome === "Plains_mid") {
            return {
                strategy: "lineOfSight",
                diurnal: {
                    creatures: [
                        {
                            race: "rabbit",
                            probability: 0.5,
                        },
                        {
                            race: "rabbit",
                            probability: 0.5,
                        },
                        {
                            race: "chicken",
                            probability: 0.5,
                        },
                        {
                            race: "deer",
                            probability: 0.5,
                        },
                        {
                            race: "bee",
                            probability: 0.3,
                        },
                        {
                            race: "wasp",
                            probability: 0.1,
                            hp: 2,
                            minQuantity: 3,
                            maxQuantity: 5,
                            exemptFromMax: true,
                        },
                    ],
                    max: 1,
                },
                nocturnal: {
                    creatures: [
                        {
                            race: "skeleton",
                            probability: 0.8,
                            hp: 2,
                            bufferTs: 5000,
                            weaponTypeRange: ["dagger", "spear", "none", "none", "none", "bow"],
                        },
                    ],
                    max: 1,
                },
            }
        }

        if (biome === "Forest") {
            return {
                strategy: "random",
                diurnal: {
                    creatures: [
                        {
                            race: "slime",
                            probability: 1.0,
                        },
                        {
                            race: "chicken",
                            probability: 0.5,
                        },
                        {
                            race: "bee",
                            probability: 0.2,
                        },
                    ],
                    max: 4,
                },
                nocturnal: {
                    creatures: [
                        {
                            race: "skeleton",
                            probability: 1.0,
                            hp: 3,
                            weaponTypeRange: ["dagger", "spear", "none", "none", "none", "bow"],
                        },
                    ],
                    max: 1,
                },
            }
        }

        if (biome === "Swamp") {
            return {
                strategy: "lineOfSight",
                diurnal: {
                    creatures: [
                        {
                            race: "redspider",
                            probability: 0.5,
                        },
                        {
                            race: "slime",
                            probability: 0.5,
                        },
                    ],
                    max: 4,
                },
                nocturnal: {
                    creatures: [
                        {
                            race: "skeleton",
                            probability: 1.0,
                            hp: 3,
                            weaponTypeRange: ["dagger", "spear", "none", "none", "none", "bow"],
                        },
                        {
                            race: "redspider",
                            probability: 1.0,
                        },
                    ],
                    max: 1,
                },
            }
        }

        return null
    }

    analyzeMapBiomeSectors = (map: MapSpec): RoomMeta => {
        const width = map.mapSizeX
        const height = map.mapSizeY
        const spawners = []
        const biomes = []

        for (let bX = 0; bX < Math.floor(width / 10); bX++) {
            for (let bY = 0; bY < Math.floor(height / 10); bY++) {
                // const mapId = map.roomId
                const biome = this.analyzeBiomeSector(map, bX, bY)
                if (biome) {
                    const region = this.toBiomeRegion(
                        bX * 10 * TileSize,
                        bY * 10 * TileSize,
                        10 * TileSize,
                        10 * TileSize,
                        biome,
                    )

                    biomes.push(region)
                }
                const biomeMeta = this.biomeSpawnMeta(biome)
                if (biomeMeta) {
                    const region = this.toSpawnRegion(
                        bX * 10 * TileSize,
                        bY * 10 * TileSize,
                        10 * TileSize,
                        10 * TileSize,
                        biomeMeta,
                    )

                    spawners.push(region)
                }
            }
        }

        return {
            spawners,
            biomes,
            features: [],
            featurePoints: [],
        }
    }

    extractWorldMeta = (): { worldMeta: WorldMeta; roomMeta: Map<string, RoomMeta> } => {
        const worldMeta: WorldMeta = {
            id: `world-${this.random()}`,
            dimensions: {
                width: this.globalWidth,
                height: this.globalHeight,
                roomWidth: this.mapSize,
                roomHeight: this.mapSize,
                quadrantWidth: this.quadrantDimensions.w,
                quadrantHeight: this.quadrantDimensions.h,
                roomsCountX: Math.floor(this.globalWidth / this.mapSize),
                roomsCountY: Math.floor(this.globalHeight / this.mapSize),
                sectorWidth: 10,
                sectorHeight: 10,
            },
            roomBiomesDirectory: {},
            biomesToRooms: {},
            allBiomes: [],
            biomeColorMap: [],
            locationIconsMap: {},
            geographicFeatures: {},
            mapFeatures: {},
        }

        const roomMeta: Map<string, RoomMeta> = new Map()
        //
        // establish world room biomes
        //
        const biomesSet: Set<string> = new Set()
        Object.values(this.maps).forEach(map => {
            const roomId = map.roomId

            const meta = this.analyzeMapBiomeSectors(map)
            meta.id = roomId
            roomMeta.set(roomId, meta)
            const { biomes } = meta

            const roomBiomes = biomes.reduce(
                (acc, next) => (acc.indexOf(next.properties[0].value) > -1 ? acc : [...acc, next.properties[0].value]),
                [],
            )
            roomBiomes.forEach(biome => {
                biomesSet.add(biome)
                if (!worldMeta.biomesToRooms[biome]) {
                    worldMeta.biomesToRooms[biome] = []
                }
                if (worldMeta.biomesToRooms[biome].indexOf(roomId) < 0) {
                    worldMeta.biomesToRooms[biome].push(roomId)
                }
            })
            worldMeta.roomBiomesDirectory[roomId] = roomBiomes
        })
        worldMeta.allBiomes = Array.from(biomesSet)

        return { worldMeta, roomMeta }
    }

    fillFragmentDetails = (
        roomId: string,
        mapX: number,
        mapY: number,
        x: number,
        y: number,
        offset: Coordinate,
        fragmentMaps: FragmentMaps,
        renderStrategy: FragmentRenderStrategy,
        mapEntries: MapEntry[],
        spriteEntries: FragmentMapEntry[],
        context?: any,
        locationCallback?: (x: number, y: number) => void,
        logIt?: boolean,
    ): Bounds => {
        const bounds = new Bounds(-1, -1, -1, -1)

        Object.keys(fragmentMaps).forEach(layer => {
            const fragmentMap = fragmentMaps[layer]

            bounds.widenViaCoordinate({
                x: TileSize * (x + offset.x),
                y: TileSize * (y + offset.y),
            })
            bounds.widenViaCoordinate({
                x: TileSize * (x + fragmentMap.length + offset.x),
                y: TileSize * (y + fragmentMap[0].length + offset.y),
            })

            for (let rx = 0; rx < fragmentMap.length; rx++) {
                for (let ry = 0; ry < fragmentMap[0].length; ry++) {
                    const value = fragmentMap[rx][ry]

                    if (!!value) {
                        if (renderStrategy === "default" || layer === "blocking") {
                            const gX = mapX * this.mapSize + x + rx + offset.x
                            const gY = mapY * this.mapSize + y + ry + offset.y

                            if (locationCallback) {
                                locationCallback(gX, gY)
                            }
                            const tilesetPlatformAutotile = PlatformAutoTiles[value.tilesetId]
                            const platformAutoTileMeta = tilesetPlatformAutotile
                                ? tilesetPlatformAutotile[value.tilePos]
                                : undefined
                            if (platformAutoTileMeta) {
                                mapEntries.push(
                                    platformAutotile({
                                        mapId: roomId,
                                        tilesetId: value.tilesetId,
                                        layerId: layer,
                                        tile: value.tilePos,
                                        variants: platformAutoTileMeta,
                                        x: gX,
                                        y: gY,
                                    }),
                                )
                            } else {
                                mapEntries.push({
                                    mapId: roomId,
                                    tileSpec: value,
                                    layerId: layer,
                                    x: gX,
                                    y: gY,
                                })
                            }
                        }
                        if (renderStrategy === "sprite" && layer !== "blocking") {
                            const mapEntry: FragmentMapEntry = {
                                id: `${offset.x},${offset.y}`,
                                x: x + rx + offset.x,
                                y: y + ry + offset.y,
                                tilePos: value.tilePos + tileSetSpecs[value.tilesetId].firstgid,
                                context,
                            }
                            spriteEntries.push(mapEntry)
                        }
                    }
                }
            }
        })

        return bounds
    }

    buildFeatureFragment = (
        featureFragment: FragmentMeta,
        x: number,
        y: number,
        mapX: number,
        mapY: number,
        roomId: string,
        featurePoints: FeaturePointMeta[],
        mapSpriteCaps: Coordinate[],
    ): FragmentSpriteMeta => {
        const {
            fragmentMaps,
            renderStrategy,
            id,
            dimensions,
            points,
            fragmentPointers,
            mapSpriteCaps: mapSpriteCapsRaw,
            context,
        } = featureFragment
        points
            .filter(p => !p.context?.fragmentPointer)
            .forEach(point => {
                const fineMapX = x * TileSize + point.location.x
                const fineMapY = y * TileSize + point.location.y
                const p = {
                    ...point,
                    location: {
                        x: fineMapX,
                        y: fineMapY,
                    },
                }
                featurePoints.push(p)
            })

        // record map mutations
        const mapEntries: MapEntry[] = []
        const postFeatureMapEntries: MapEntry[] = []
        const subFeatures: RegionMeta[] = []

        const fragmentSpriteMeta: FragmentSpriteMeta = {
            id,
            width: dimensions.w,
            height: dimensions.h,
            mapEntries,
            postFeatureMapEntries,
            spriteEntries: [],
            location: { x, y },
            context,
            subFeatures,
        }

        fragmentPointers.forEach(pointer => {
            const context = pointer.context ? JSON.parse(pointer.context) : undefined
            if (context?.fragmentPointer) {
                const {
                    catalog,
                    category,
                    subCategory,
                    tag: tagRaw,
                    context: fragmentPointerContext,
                } = context.fragmentPointer
                let tag = tagRaw
                if (tagRaw?.includes("|")) {
                    tag = this.randomOneOf(tagRaw.split("|"))
                }
                const fragment = this.featureCatalogs.randomFeature(catalog, category, subCategory, tag)
                if (fragment) {
                    const contextToUse = {
                        ...(fragment?.context || {}),
                        ...(fragmentPointerContext || {}),
                    }
                    const rough = fineToRoughCoordinates(pointer.location)
                    const fragmentMaps = fragment.fragmentMaps
                    const bounds = this.fillFragmentDetails(
                        roomId,
                        mapX,
                        mapY,
                        x,
                        y,
                        rough,
                        fragmentMaps,
                        fragment.renderStrategy,
                        postFeatureMapEntries,
                        fragmentSpriteMeta.spriteEntries,
                        contextToUse,
                        undefined,
                        fragment.tag === "bucket2",
                    )

                    // fragment pointers can be things like doors, so we should capture
                    // enough information about them to create regions
                    const regionMeta: RegionMeta = {
                        id: `${roomId}-${fragment.category || "category"}-${fragment.subcategory || "subcategory"}-${
                            fragment.tag || "tag"
                        }`,
                        fragment,
                        bounds,
                        context: contextToUse,
                    }
                    subFeatures.push(regionMeta)
                }
            }
        })

        if (mapSpriteCapsRaw) {
            mapSpriteCapsRaw.forEach(point => {
                const fineMapX = x * TileSize + point.location.x
                const fineMapY = y * TileSize + point.location.y
                const p = {
                    x: fineMapX,
                    y: fineMapY,
                }

                mapSpriteCaps.push(p)
            })
        }

        this.fillFragmentDetails(
            roomId,
            mapX,
            mapY,
            x,
            y,
            {
                x: 0,
                y: 0,
            },
            fragmentMaps,
            renderStrategy,
            mapEntries,
            fragmentSpriteMeta.spriteEntries,
        )

        return fragmentSpriteMeta
    }

    printFeature = (
        featureFragment: FragmentMeta,
        featureLocation: Coordinate,
        mapX: number,
        mapY: number,
        roomId: string,
        featureSprites: FragmentSpriteMeta[],
        featurePoints: FeaturePointMeta[],
        postFeatureMapEntries: MapEntry[],
        mapSpriteCaps: Coordinate[],
    ) => {
        const fragmentSpriteMeta: FragmentSpriteMeta = this.buildFeatureFragment(
            featureFragment,
            featureLocation.x,
            featureLocation.y,
            mapX,
            mapY,
            roomId,
            featurePoints,
            mapSpriteCaps,
        )
        const { mapEntries, spriteEntries, subFeatures, postFeatureMapEntries: postMapEntries } = fragmentSpriteMeta
        this.applyEntries(mapEntries)
        if (spriteEntries.length > 0 || subFeatures.length > 0) {
            featureSprites.push(fragmentSpriteMeta)
        }
        postMapEntries.forEach(entry => postFeatureMapEntries.push(entry))
    }

    createDefenders = () => {
        const context = {
            npcMeta: {
                npc: {
                    type: "zombie",
                    weaponTypeRange: ["axe", "dagger", "dagger", "spear", "bow"],
                    faction: "wild",
                    appearance: {
                        race: "wolfman",
                        sex: "male",
                        bodyColor: "brown",
                        inventoryAppearanceHash: "",
                    },
                    speed: Mechanics.entity.speed.fromPctOfMax(Math.random()),
                },
                brainProps: {
                    shooter: true,
                    scanner: true,
                    seeker: "astar",
                    scanPlayersOnly: false,
                    psychProfile: {
                        hostility: Mechanics.entity.psych.hostility().fromPctOfMax(this.randomFloatBetween(0.1, 0.5)),
                        aggroResponse: "pause",
                    },
                    targetLocationOffset: {
                        x: this.randomIntBetween(-TileSize, TileSize),
                        y: this.randomIntBetween(-TileSize, TileSize),
                    },
                    targetLocationMaxDistance: 5 * TileSize,
                },
            },
        }

        return context
    }

    buildFeatures = (worldMeta: WorldMeta, roomMeta: Map<string, RoomMeta>) => {
        // todo
    }

    roomBounds = () => {
        const roomBounds = new Bounds(0, 0, this.mapSize * TileSize, this.mapSize * TileSize)
        return roomBounds
    }

    applyGlobalBoundaries = () => {
        for (let x = 0; x < this.globalWidth; x++) {
            this.setGlobal(x, 0, "blocking", 121)
            this.setGlobal(x, this.globalHeight - 1, "blocking", 121)
        }
        for (let y = 0; y < this.globalHeight; y++) {
            this.setGlobal(0, y, "blocking", 121)
            this.setGlobal(this.globalWidth - 1, y, "blocking", 121)
        }
    }

    buildResources = (worldMeta: WorldMeta, roomMetas: Map<string, RoomMeta>) => {
        Array.from(roomMetas.keys()).forEach(roomId => {
            const roomMeta: RoomMeta = roomMetas.get(roomId)
            roomMeta.biomes.forEach(biome => {
                const biomeType = biome.properties.find(p => p.name === "biome")?.value
                if (biomeType === "Plains_mid") {
                    const biomeBounds = new Bounds(biome.x, biome.y, biome.x + biome.width, biome.y + biome.height)
                    repeat(() => {
                        roomMeta.featurePoints.push({
                            id: "RESOURCE",
                            location: randomCoordinateWithinBounds(biomeBounds, true),
                            context: JSON.stringify({
                                itemType: this.pctChance(60)
                                    ? "flower-single-purple"
                                    : this.randomOneOf([
                                          "flower-single-blue",
                                          "flower-single-red",
                                          "flower-single-yellow",
                                      ]),
                            }),
                        })
                    }, this.randomIntBetween(2, 4))
                }
                if (biomeType === "Forest") {
                    const biomeBounds = new Bounds(biome.x, biome.y, biome.x + biome.width, biome.y + biome.height)
                    repeat(() => {
                        roomMeta.featurePoints.push({
                            id: "RESOURCE",
                            location: randomCoordinateWithinBounds(biomeBounds, true),
                            context: JSON.stringify({
                                itemType: this.pctChance(60)
                                    ? "mushroom-red"
                                    : this.randomOneOf(["mushroom-purple", "mushroom-yellow"]),
                            }),
                        })
                    }, this.randomIntBetween(1, 2))
                }
            })
        })
    }

    finalize() {
        const { worldMeta, roomMeta } = this.extractWorldMeta()
        this.roomMeta = roomMeta

        const output: BuilderOutputSpec = {
            worldMeta,
            roomMaps: new Map(),
        }

        // apply post-terraforming features
        // baseline tiles
        this.applyGlobalBoundaries()
        this.applyEntries(this.deferredMapEntries)
        this.buildFeatures(worldMeta, roomMeta)
        this.buildResources(worldMeta, roomMeta)

        this.applyEntries(this.featureEntries)

        // apply autotiling
        Object.values(this.globalMap.layers).forEach((layer: MapLayerSpec) => {
            this.applyAutotiling(
                layer,
                (
                    gX: number,
                    gY: number,
                    tilesetId: string,
                    autotileVariantTile: AutotileVariant,
                    variantPos: number,
                    layerUpNumber,
                    layerUpId,
                ) => {
                    const tileMeta: AutotileDecoration | number =
                        typeof autotileVariantTile === "number"
                            ? (autotileVariantTile as number)
                            : autotileVariantTile.tile

                    const decorations: AutotileDecoration[] = (
                        typeof autotileVariantTile === "number" ? [] : autotileVariantTile.decorations || []
                    ).filter(n => !!n)

                    const tileset: TileSetSpec = tileSetSpecs[tilesetId]

                    const baseTile = typeof tileMeta === "number" ? (tileMeta as number) : tileMeta?.tile

                    if (baseTile === -1) {
                        // erase
                        const mapLayer = this.globalMap.layers[layerUpId]
                        mapLayer.set(gX, gY, 0)
                        this.setGlobal(gX, gY, layer.layerId, 0)
                        return
                    }
                    const tile = baseTile + tileset.firstgid

                    // paste it into the next layer up
                    const mapLayer = this.globalMap.layers[layerUpId]
                    mapLayer.set(gX, gY, tile)
                    this.setGlobal(gX, gY, layerUpId, tile)
                    // remove original
                    this.setGlobal(gX, gY, layer.layerId, 0)

                    decorations.forEach(decoration => {
                        const decorationTilesetId = decoration.tilesetId
                        const decorationFirstGid = decorationTilesetId
                            ? tileSetSpecs[decorationTilesetId].firstgid
                            : tileset.firstgid

                        const decorationLayerId =
                            decoration.namedTileLayerId || `map${(decoration.mapLayerYDelta || 0) + layerUpNumber}`

                        this.setGlobal(
                            gX + (decoration.offsetX || 0),
                            gY + (decoration.offsetY || 0),
                            decorationLayerId,
                            decoration.tile + decorationFirstGid,
                        )
                    })

                    const noUnderTile =
                        typeof autotileVariantTile === "number" ? false : autotileVariantTile.noUndertile

                    if (!noUnderTile) {
                        // get adjacent tile to put it under current layer
                        let gXDelta = 0
                        let gyDelta = 0

                        switch (variantPos) {
                            case 2:
                            case 3:
                            case 15:
                            case 27:
                            case 28:
                            case 31: {
                                gXDelta = 1
                                break
                            }

                            case 26:
                            case 44: {
                                gXDelta = 1
                                gyDelta = -1
                                break
                            }

                            case 12:
                            case 13: {
                                gyDelta = 1
                                break
                            }

                            case 5:
                            case 8:
                            case 10:
                            case 42: {
                                gyDelta = -1
                                break
                            }
                            case 36: {
                                gXDelta = -1
                                break
                            }
                            case 25:
                            case 1: {
                                gXDelta = -1
                                break
                            }

                            case 4:
                            case 33: {
                                gXDelta = 1
                                gyDelta = 1
                                break
                            }

                            case 45: {
                                gXDelta = -1
                                gyDelta = -1
                                break
                            }

                            case 11:
                            case 41: {
                                gXDelta = -1
                                gyDelta = 1
                                break
                            }

                            case 7:
                            case 17:
                            case 35:
                            case 14:
                            case 18:
                            case 20:
                            case 29:
                            case 40:
                            case 34:
                            case 32: {
                                gXDelta = -1
                                break
                            }
                        }

                        if (gXDelta || gyDelta) {
                            const replaceTile = this.getGlobal(gX + gXDelta, gY + gyDelta, layer.layerId)
                            if (replaceTile !== 0) {
                                this.setGlobal(gX, gY, layer.layerId, replaceTile)
                            }
                        }
                    }
                },
            )
        })

        this.applyEntries(this.finalizationEntries, roomMeta)

        //
        // finalize rooms
        //
        Object.values(this.maps).forEach(map => {
            const { biomes, spawners, features, featurePoints } = roomMeta.get(map.roomId)

            features.forEach(feature => {
                const mapEntries = feature.postFeatureMapEntries
                this.applyEntries(mapEntries)
            })

            const layers = [
                ...Object.values(map.layers)
                    .sort((a, b) => a.layerNumber - b.layerNumber)
                    .map(layer => this.toTiledLayer(layer)),
                this.toObjectLayer(map, biomes, spawners, features, featurePoints),
            ]

            layers.push(this.toVisibility(layers.length + 1))

            const tiled = {
                compressionlevel: -1,
                height: map.mapSizeY,
                infinite: false,
                layers,
                nextlayerid: 2,
                nextobjectid: this.objectId + 1,
                orientation: "orthogonal",
                properties: [
                    {
                        name: "name",
                        type: "string",
                        value: this.globalMapProperties?.name || map.roomId,
                    },
                    {
                        name: "roomId",
                        type: "string",
                        value: map.roomId,
                    },
                    {
                        name: "triggers",
                        type: "string",
                        value: "[]",
                    },
                    {
                        name: "dayPhaseSupported",
                        type: "boolean",
                        value: this.globalMapProperties?.dayPhaseSupported,
                    },
                    {
                        name: "suppressMinimap",
                        type: "boolean",
                        value: this.globalMapProperties?.suppressMinimap,
                    },
                    {
                        name: "mapX",
                        type: "string",
                        value: map.mapX,
                    },
                    {
                        name: "mapY",
                        type: "string",
                        value: map.mapY,
                    },
                    ...this.mapPropertyBuilder(map),
                ],
                renderorder: "right-down",
                tiledversion: "1.9.0",
                tileheight: 32,
                tilesets: Object.values(tileSetSpecs).map(next => this.toTiledTileset(next)),
                tilewidth: 32,
                type: "map",
                version: "1.9",
                width: map.mapSizeX,
            }
            output.roomMaps.set(map.roomId, tiled)

            const stringified = JSON.stringify(tiled)
            const roomOutputPath = this.toRoomOutputPath(map.roomId)
            if (roomOutputPath) {
                fs.writeFileSync(roomOutputPath, stringified)
            }
        })

        worldMeta.biomeColorMap = this.genBiomeColorMap(output)
        worldMeta.locationIconsMap = this.genLocationIcons(output)
        worldMeta.geographicFeatures = this.genGeographicFeatures(output)
        worldMeta.mapFeatures = this.genMapFeatures(output, roomMeta)

        // persist worldmeta
        const worldMetaPath = this.toWorldMetaPath()
        if (worldMetaPath) {
            fs.writeFileSync(this.toWorldMetaPath(), JSON.stringify(worldMeta, null, 1))
        }

        return output
    }

    mapPropertyBuilder = (map: MapSpec): any[] => {
        return []
    }

    genLocationIcons = (output: BuilderOutputSpec) => {
        const rooms = Array.from(output.roomMaps.keys()).map(mapId => output.roomMaps.get(mapId))

        const locationIconsMap = {}

        const toMinimapCoordinates = (roomId: string, fineCoordinate: Coordinate): Coordinate => {
            const [_, roomX, roomY] = roomId.split("-")
            const rough = fineToRoughCoordinates(fineCoordinate)
            const roughRoomX = rough.x
            const roughRoomY = rough.y

            const biomeX = roughRoomX / 10
            const biomeY = roughRoomY / 10
            const x = Math.floor(Number(roomX) * 5 + biomeX)
            const y = Math.floor(Number(roomY) * 5 + biomeY)

            return {
                x,
                y,
            }
        }

        rooms.forEach(roomJson => {
            const roomIdToUse = roomJson?.properties?.find(property => property.name === "roomId")?.value
            Object.values(extractRegions(roomIdToUse, roomJson, ["REGION", "FEATURE_REGION"]))
                .flat()
                .forEach(region => {
                    if (region.context?.context) {
                        const regionExtraContext = JSON.parse(region.context.context)
                        if (regionExtraContext.locationIcon) {
                            const location = region.bounds.center()
                            const minimapCoordinates = toMinimapCoordinates(roomIdToUse, location)
                            const { x, y } = minimapCoordinates

                            const locationIcon: LocationIcon = {
                                id: `${roomIdToUse}-${x}-${y}-${regionExtraContext.locationName}`,
                                roomId: roomIdToUse,
                                x,
                                y,
                                icon: regionExtraContext.locationIcon,
                                initialMapFeature: regionExtraContext.initialMapFeature,
                                noProximitySpawn: regionExtraContext.noProximitySpawn,
                                name: regionExtraContext.locationName,
                            }
                            locationIconsMap[`${x}-${y}`] = locationIcon
                        }
                    }
                })
        })
        return locationIconsMap
    }

    genBiomeColorMap(output: BuilderOutputSpec) {
        const rooms = Array.from(output.roomMaps.keys()).map(mapId => output.roomMaps.get(mapId))

        const biomeWidth = 10
        const biomeHeight = 10

        const { worldMeta } = output
        const dimensions = worldMeta.dimensions
        const width = dimensions.roomsCountX * (dimensions.roomWidth / biomeWidth)
        const height = dimensions.roomsCountY * (dimensions.roomHeight / biomeHeight)

        const roomBiomeHeight = Math.floor(dimensions.roomHeight / biomeHeight)
        const roomBiomeWidth = Math.floor(dimensions.roomWidth / biomeWidth)

        const colorMap = blankMap(width, height, 0)

        rooms.forEach(roomJson => {
            const roomIdToUse = roomJson?.properties?.find(property => property.name === "roomId")?.value
            const biomeRegions = Object.values(extractBiomeRegions(roomIdToUse, roomJson)).flat()

            const worldMapX = roomJson.properties.find(property => property.name === "mapX")?.value
            const worldMapY = roomJson.properties.find(property => property.name === "mapY")?.value

            biomeRegions.forEach(region => {
                const roughBounds = fineToRoughBoundsViaDimensions(
                    {
                        w: dimensions.roomWidth,
                        h: dimensions.roomHeight,
                    },
                    region.bounds,
                )

                const biomeNameFull = region.context?.biome
                const biomeName: string = biomeNameFull.split("_")[0]
                const biomeColor = BiomesMap.get(biomeName).color >> 8
                const biomeX = Math.floor(roughBounds.x1 / biomeWidth)
                const biomeY = Math.floor(roughBounds.y1 / biomeHeight)
                const x = worldMapX * roomBiomeWidth + biomeX
                const y = worldMapY * roomBiomeHeight + biomeY

                colorMap[x][y] = biomeColor
            })
        })

        return colorMap
    }

    genMapFeatures(output: BuilderOutputSpec, roomMetas: Map<string, RoomMeta>) {
        const featureTypeMap: Map<MapFeatureType, MapFeature[]> = new Map()
        const worldFeatures = {}
        roomMetas.forEach((roomMeta, key) => {
            roomMeta.features?.forEach(feature => {
                const mapFeature = feature.mapFeature
                if (mapFeature) {
                    const ff = featureTypeMap.get(mapFeature.type) || []
                    ff.push(mapFeature)
                    featureTypeMap.set(mapFeature.type, ff)
                }
            })
        })

        featureTypeMap.forEach((ff, type) => {
            worldFeatures[type] = ff
        })

        return worldFeatures
    }

    genGeographicFeatures(output: BuilderOutputSpec) {
        const { worldMeta } = output
        type Point = [number, number]
        type Feature = Point[]

        function detectFeatures(image: number[][], threshold: number): Feature[] {
            let features: Feature[] = []

            for (let y = 0; y < image.length; y++) {
                for (let x = 0; x < image[0].length; x++) {
                    if (!belongsToExistingFeature([x, y], features)) {
                        let newFeature = regionGrowing(image, [x, y], threshold)
                        features.push(newFeature)
                    }
                }
            }

            return features
        }

        function belongsToExistingFeature(point: Point, features: Feature[]): boolean {
            for (let feature of features) {
                if (feature.some(p => p[0] === point[0] && p[1] === point[1])) {
                    return true
                }
            }

            return false
        }
        function regionGrowing(image: number[][], seed: Point, threshold: number): Point[] {
            let region = [seed]
            let stack = [seed]

            while (stack.length > 0) {
                let point = stack.pop()!
                let neighbors = getNeighbors(point)

                for (let neighbor of neighbors) {
                    if (
                        isSimilar(image[point[0]]?.[point[1]], image[neighbor[0]]?.[neighbor[1]], threshold) &&
                        !region.some(p => p[0] === neighbor[0] && p[1] === neighbor[1])
                    ) {
                        region.push(neighbor)
                        stack.push(neighbor)
                    }
                }
            }

            return region
        }

        function getNeighbors(point: Point): Point[] {
            let [x, y] = point
            return [
                [x - 1, y],
                [x + 1, y],
                [x, y - 1],
                [x, y + 1],
            ] // 4-connectivity
        }

        function isSimilar(color1: number, color2: number, threshold: number): boolean {
            return Math.abs(color1 - color2) <= threshold
        }

        const f = detectFeatures(worldMeta.biomeColorMap, 0).filter(n => n.length > 10 && n.length < 200)

        const biomeFeatures: Record<string, GeographicFeature[]> = {}
        f.forEach(feature => {
            const [x, y] = feature[0]
            // console.log(i, x, ",", y)
            const color = worldMeta.biomeColorMap[x][y]
            const biome: BiomeMeta = BiomeColorsMap.get(color)

            if (biome) {
                const name = randomName("elf", { gender: "male" }).toString()
                const biomeCoordinates: Coordinate[] = []
                const geographicFeature: GeographicFeature = {
                    id: `${x}-${y}-${biome.name}`,
                    biome: biome.name,
                    name,
                    biomeCoordinates,
                }

                feature.forEach(coordinate => {
                    const [cx, cy] = coordinate
                    geographicFeature.biomeCoordinates.push({
                        x: cx,
                        y: cy,
                    })
                })
                const features = biomeFeatures[biome.name] || []
                features.push(geographicFeature)
                biomeFeatures[biome.name] = features
            }
        })

        return biomeFeatures
    }
}

export const platformAutotile = ({
    mapId,
    tile,
    tilesetId,
    layerId,
    variants,
    x,
    y,
    containmentBiome,
    containmentRange,
    autoBlocking = true,
    autoOcclusion = false,
}: {
    mapId: string
    tile: number
    tilesetId: string
    layerId: string
    variants: number[]
    x: number
    y: number
    containmentBiome?: string
    containmentRange?: number
    autoBlocking?: boolean
    autoOcclusion?: boolean
}): MapEntry => {
    const blocking = autoBlocking
        ? {
              tilesetId: "lpc-terrain",
              namedTileLayerId: "blocking",
              tile: 121,
          }
        : undefined
    const occlusion = autoOcclusion
        ? {
              tilesetId: "lpc-terrain",
              namedTileLayerId: "occlusion",
              tile: 121,
          }
        : undefined

    const autotileMap: AutotileMap = {
        tilePos: tile,
        tilesetId,
        mapLayerYDelta: 2,
        tiles: {
            34: {
                tile: variants[0],
                decorations: [blocking, occlusion],
            },
            43: {
                tile: variants[1],
                decorations: [blocking, occlusion],
            },
            42: {
                tile: variants[1],
                decorations: [blocking, occlusion],
            },
            26: {
                tile: variants[2],
                decorations: [blocking, occlusion],
            },

            ////////////////////////////////////

            36: {
                tile: variants[3],
                decorations: [blocking, occlusion],
            },
            40: {
                tile: variants[3],
                decorations: [blocking, occlusion],
            },
            28: {
                tile: variants[4],
                decorations: [blocking, occlusion],
            },
            31: {
                tile: variants[4],
                decorations: [blocking, occlusion],
            },

            ////////////////////////////////////

            7: {
                tile: variants[5],
                decorations: [
                    variants[8] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[8],
                          }
                        : undefined,
                    variants[11] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[11],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            12: {
                tile: variants[6],
                decorations: [
                    variants[9] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[9],
                          }
                        : undefined,
                    variants[12] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[12],
                          }
                        : undefined,

                    blocking,
                    occlusion,
                ],
            },
            25: {
                tile: variants[6],
                decorations: [
                    variants[9] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[9],
                          }
                        : undefined,
                    variants[12] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[12],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            4: {
                tile: variants[7],
                decorations: [
                    variants[10] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[10],
                          }
                        : undefined,
                    variants[13] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[13],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },

            ////////////////////////////////////
            ////////////////////////////////////

            33: {
                tile: variants[14],
                decorations: [
                    variants[9] != -1
                        ? {
                              mapLayerYDelta: -1,
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[9],
                          }
                        : undefined,
                    variants[12] != -1
                        ? {
                              mapLayerYDelta: -1,
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[12],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            41: {
                tile: variants[15],
                decorations: [
                    variants[9] != -1
                        ? {
                              mapLayerYDelta: -1,
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[9],
                          }
                        : undefined,
                    variants[12] != -1
                        ? {
                              mapLayerYDelta: -1,
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[12],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },

            ////////////////////////////////////

            44: {
                tile: variants[16],
                decorations: [blocking, occlusion],
            },
            45: {
                tile: variants[17],
                decorations: [blocking, occlusion],
            },

            ////////////////////////////////////

            // top left
            35: {
                tile: variants[19],
            },
            37: {
                tile: variants[19],
            },

            // top right
            27: {
                tile: variants[20],
                decorations: blocking ? [blocking] : undefined,
            },
            29: {
                tile: variants[20],
                decorations: [blocking, occlusion],
            },

            // bottom left
            32: {
                tile: variants[21],
                decorations: [
                    variants[8] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[8],
                          }
                        : undefined,
                    variants[11] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[11],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            11: {
                tile: variants[21],
                decorations: [
                    variants[8] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[8],
                          }
                        : undefined,
                    variants[11] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[11],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            20: {
                tile: variants[21],
                decorations: [
                    variants[8] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[8],
                          }
                        : undefined,
                    variants[11] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[11],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },

            // bottom right
            10: {
                tile: variants[22],
                decorations: [
                    variants[10] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[10],
                          }
                        : undefined,
                    variants[13] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[13],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
            17: {
                tile: variants[22],
                decorations: [
                    variants[10] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 1,
                              tile: variants[10],
                          }
                        : undefined,
                    variants[13] != -1
                        ? {
                              offsetX: 0,
                              offsetY: 2,
                              tile: variants[13],
                          }
                        : undefined,
                    blocking,
                    occlusion,
                ],
            },
        },
    }

    Object.values(autotileMap.tiles).forEach((t: AutotileSpec) => (t.noUndertile = true))

    const tileSpec: TileSpec = {
        tilesetId,
        tilePos: tile,
        autotileMap,
    }

    const mapEntry: MapEntry = {
        mapId,
        tileSpec,
        layerId,
        x,
        y,
        containmentRange,
        containmentBiome,
    }

    return mapEntry
}
