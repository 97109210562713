import { Graphics, BitmapText, Text } from "pixi.js"
import { TextRenderer } from "../text/text_renderer"

export class Typing extends Graphics {
    typingText: Text
    typingTexPrompt: Text
    typingTextBlink: Text
    blinkOn: boolean = true
    blinkInterval: any

    constructor() {
        super()

        this.typingText = new TextRenderer().size("medium").render() as Text
        this.typingTexPrompt = new TextRenderer().size("medium").render() as Text
        this.typingTexPrompt.text = ">"
        this.typingTexPrompt.x = -15
        this.typingText.text = ""
        this.typingTextBlink = new TextRenderer().size("medium").color(0x00ff00).render() as Text
        this.addChild(this.typingText)
        this.addChild(this.typingTexPrompt)
        this.addChild(this.typingTextBlink)
    }

    handleBlink = () => {
        if (!this.blinkInterval) {
            this.blinkInterval = setInterval(() => {
                this.typingTextBlink.visible = this.blinkOn
                this.blinkOn = !this.blinkOn
            }, 400)
        }
        this.blinkOn = true
    }

    updateText = (str: string) => {
        this.handleBlink()
        this.visible = true
        this.typingText.text = str

        this.typingTextBlink.text = "|"
        this.typingTextBlink.x = !!str ? this.typingText.width + 1 : 0
    }

    removeText = () => {
        this.visible = false
        this.typingText.text = ""
        this.blinkOn = false
        clearInterval(this.blinkInterval)
        this.blinkInterval = undefined
    }
}
