import { BaseRenderable } from "./base_renderable"

export class Plus extends BaseRenderable {
    constructor(color: any = 0xff00ff, rotating?: boolean) {
        super()

        super.lineStyle(1, color)
        super.beginFill(color)
        super.drawRect(-10, 0, 25, 5)

        super.lineStyle(1, color)
        super.beginFill(color)
        super.drawRect(0, -10, 5, 25)

        super.endFill()

        let angle = 0
        if (rotating) {
            setInterval(() => {
                if (angle > 360) {
                    angle = 0
                }
                this.angle = angle
                angle++
            }, 50)
        }
    }
}
