import { Bounds, NumberedTileMeta, TileSize } from "game-common/models"
import { Graphics, Rectangle, Sprite, Texture } from "pixi.js"
import { PixiJsClientRenderer } from "../pixijs_client_renderer"
import { TileMapMetaProvider } from "game-common/tile_map_meta_provider"

export class TileTextureExtractor {
    static instance: TileTextureExtractor = new TileTextureExtractor()

    static textureCache: Map<string, Texture> = new Map()
    static compositeTileSpriteBounds: Map<string, Bounds> = new Map()

    extract = (tilemapMetaProvider: TileMapMetaProvider, tilesetId: string, compositeTileId: string): Texture => {
        const parentTileSet = tilemapMetaProvider.getTilesetById(tilesetId)

        const compositeTileMeta = tilemapMetaProvider.resolveCompositeTileIdTileMeta(compositeTileId, tilesetId)
        if (!compositeTileMeta) {
            return null
        }

        const fullTileId = `${parentTileSet.tilesetId}-${compositeTileId}`
        let texture = TileTextureExtractor.textureCache[`${fullTileId}`]
        if (texture) {
            return texture
        }

        const { width } = parentTileSet
        const children: NumberedTileMeta[] = compositeTileMeta.children || []

        const bounds: Bounds = new Bounds(0, 0, 0, 0)
        const childBounds = children.map(childTileMeta => {
            const tileNumber = childTileMeta.tileNumber - 1
            const tileSetX = tileNumber % width
            const tileSetY = Math.floor(tileNumber / width)

            const childBounds: Bounds = new Bounds(tileSetX, tileSetY, tileSetX + 1, tileSetY + 1)
            return childBounds
        })

        bounds.widen(childBounds)
        const xx = bounds.x1
        const yy = bounds.y1

        bounds.translate(-bounds.x1, -bounds.y1)
        TileTextureExtractor.compositeTileSpriteBounds[`${fullTileId}`] = bounds

        const rect = new Rectangle(0, 0, bounds.width * TileSize, bounds.height * TileSize)

        const textureSurface = new Graphics()
        const textureToCache = PixiJsClientRenderer._app.renderer.generateTexture(textureSurface, {
            region: rect,
        })

        const containingSprite: Sprite = new Sprite(textureToCache)
        // debug
        // const g = new Graphics()
        // g.beginFill(0xFF0000, 0.2)
        // g.drawRect(0, 0, rect.width, rect.height)
        // g.endFill()
        // containingSprite.addChild(g)

        children.forEach(childTileMeta => {
            const tileId = `${childTileMeta.tileNumber}`
            const tileNumber = childTileMeta.tileNumber - 1
            const tileSetX = (tileNumber % width) - xx
            const tileSetY = Math.floor(tileNumber / width) - yy

            const subtileSprite = new Sprite(Texture.from(`${parentTileSet.tilesetId}-${tileId}`))
            subtileSprite.x = tileSetX * TileSize - 0
            subtileSprite.y = tileSetY * TileSize + 0
            containingSprite.addChild(subtileSprite)
        })

        texture = PixiJsClientRenderer._app.renderer.generateTexture(containingSprite)
        TileTextureExtractor.textureCache[`${fullTileId}`] = texture

        return texture
    }
}
