import { Graphics } from "pixi.js"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"

export class DayCycle extends Graphics {
    gui: GuiManager
    surface: Graphics
    moon: Graphics
    sun: Graphics

    constructor(gui: GuiManager) {
        super()

        this.gui = gui
        this.surface = new Graphics()
        this.addChild(this.surface)

        this.scale.set(0.44)

        const mask = new Graphics()

        mask.beginFill(0x000000, 1.0)
        mask.drawRect(-40, -40, 80, 39)
        mask.endFill()

        this.addChild(mask)
        this.surface.mask = mask

        const sun = new BasicSprite({
            color: 0xffffff,
            scale: 1.0,
            name: "sun-icon.png",
        })
        const moon = new BasicSprite({
            color: 0xffffff,
            scale: 1.0,
            name: "moon-icon.png",
        })
        this.surface.addChild(sun)
        sun.x = -25
        moon.x = 25

        this.surface.addChild(moon)

        this.beginFill(0x00ff00, 1)
        this.drawRect(-40, -1, 80, 2)
        this.endFill()

        this.sun = sun
        this.moon = moon
    }

    setup = (angle: number) => {
        //
        // this.surface.removeChildren()

        // let angle = 0
        // setInterval(() => {
        //     angle++
        //     if (angle > 360) {
        //         angle = 0
        //     }
        this.surface.angle = angle
        // sun.angle -= angle

        const x = Math.sin((Math.PI * 2 * 90) / 360)
        const y = Math.cos((Math.PI * 2 * 90) / 360)
        this.surface.x = x
        this.surface.y = y

        this.sun.angle = -this.surface.angle
        this.moon.angle = -this.surface.angle

        // }, 25)
    }
}
