import { ApplyInputResultPool } from "game-common/apply_input_result_pool"
import { WeaponAmmo } from "game-common/character/character"
import { EntityManager } from "game-common/entity_manager"
import {
    ItemTypeMeta,
    locateInventoryItemObjectByItemType,
    locateInventoryReadiedItemObjects,
} from "game-common/item/item"
import { TileEffect } from "game-common/map/map"
import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { DayPhase } from "game-common/mechanics/physics_mechanics"
import {
    AbilityType,
    Appearance,
    ApplyInputResult,
    BuildingPointOptions,
    Coordinate,
    Entity,
    EntityDimensions,
    EntityId,
    EntityTypes,
    Latch,
    Message,
    Orientation,
    RunLevel,
    TileSize,
    Visibility,
    Weapon,
    WeaponCharacteristics,
    WorldState,
    collisionMover,
    getDistance,
    getEntityDistance,
} from "game-common/models"
import { emitWeaponParticles } from "game-common/weapons"
import { v4 as uuidv4 } from "uuid"

import { TileMapMetaProvider } from "game-common/tile_map_meta_provider"
import { Callback, emptyCoordinate, resolveQuadrantIdFrom, toCoordinateFromSimple } from "game-common/util"
import { Audio } from "./audio"
import { Client } from "./client"
import { ClientRenderer, RenderingFactory } from "./client_models"
import { Player } from "./player"

export type EntityInteractionCallback = (entityId: EntityId, interaction: InteractionType) => void

export const PlayerEntityId: { value: string } = {
    value: "",
}

export class ClientGameLogic {
    private destroyedEntities: Set<EntityId> = new Set<EntityId>()
    private destroyedEntitiesLagBuffer: Set<EntityId> = new Set<EntityId>()

    private expiredTs: number = 0
    private lagbufferTs: Latch = new Latch(1500)
    private lastFireTs: Latch
    private lastWeapon: Weapon

    tilemapMetaProvider: TileMapMetaProvider

    roomId?: EntityId
    quadrantId?: string = ""
    playerController: Player

    playerEntity: Entity
    entityManager: EntityManager<Entity> = new EntityManager<Entity>(EntityTypes)

    clientRenderer: ClientRenderer
    audio: Audio
    dayPhase: DayPhase
    currentBrightness: number = 0.0
    runLevel: RunLevel

    started: boolean
    private incompleteEntities: Set<EntityId> = new Set()
    private brightnessInterval: NodeJS.Timeout
    private blackedOutTimeout: any

    constructor(factory: RenderingFactory) {
        this.clientRenderer = factory.create(this)
        this.audio = new Audio(this)
    }

    hasEntity = (entityId: EntityId): boolean => !!this.entityManager.get(entityId)

    getEntity = (entityId: EntityId): Entity | undefined => this.entityManager.get(entityId)

    updateScores = (scores: Record<EntityId, number>) => {
        const scoreEntityIds = Object.keys(scores)
        scoreEntityIds.forEach(entityId => {
            const entity = this.getEntity(entityId)
            if (entity) {
                this.clientRenderer.entity().update(entity, scores[entityId])
            }
        })
    }

    initializePlayer = (entityId: EntityId, client: Client, guest: boolean) => {
        PlayerEntityId.value = entityId

        const playerEntity = new Entity()
        playerEntity.entityType = "player"
        playerEntity.entityId = entityId
        playerEntity.dimensions = { ...EntityDimensions["player"] }
        playerEntity.hp = -1
        playerEntity.mana = -1
        playerEntity.hunger = -1
        playerEntity.visible = false
        this.playerEntity = playerEntity
        this.entityManager.set(this.playerEntity)

        this.playerController = new Player(
            client,
            playerEntity,
            this.clientRenderer.mouse(),
            this.clientRenderer.keyboard(),
            guest,
        )

        this.clientRenderer.entity().create(playerEntity)
        this.clientRenderer.gui().helpMessaging()
    }

    updatePlayerEntityId = (entityId: EntityId) => {
        if (!this.playerEntity) {
            return
        }

        const originalEntityId = this.playerEntity.entityId
        this.playerEntity.entityId = entityId
        this.clientRenderer.entity().updateEntityId(originalEntityId, entityId)
    }

    shouldInterpolate = (entity: Entity) => {
        // No point in interpolating this client"s entity.
        if (entity.entityId == this.playerEntity?.entityId) {
            return false
        }

        // its a local bullet; don"t interpolate
        if (this.entityManager.contains(entity.entityId, "bullet")) {
            return false
        }

        return true
    }

    createEntity = (message: Message): Entity | undefined => {
        const { entityId, entityType } = message
        if (this.destroyedEntitiesLagBuffer.has(entityId) || message.entityState === "dead") {
            return
        }

        if (entityId === this.playerEntity?.entityId) {
            return this.playerEntity
        }

        // create a local client entity based on the remote message from the server
        const entity = new Entity()

        entity.entityType = entityType
        entity.npcType = message.npcType
        entity.spriteId = message.spriteId
        entity.entityId = entityId
        entity.position_buffer = []
        if (message.origin) {
            entity.origin = { ...message.origin }
        }
        entity.maxDistance = message.maxDistance
        entity.location = { x: message?.position[0], y: message?.position[1] }
        entity.movement.direction = message.direction
        entity.movement.angle = message.angle
        entity.speed = message.speed
        entity.speed2 = message.speed2
        entity.color = message.color
        entity.dimensions = message.dimensions || { ...EntityDimensions[entityType] }
        entity.hp = -1
        entity.roomId = message.entityId
        entity.clickable = message.clickable
        entity.inventory = message.inventory
        entity.expirationTs = message.expirationTs
        entity.debug = message.debug
        entity.active = message.active

        // abort entity creation if its already travelled the maximum distance; should only
        // in theory apply to bullets
        if (entity.maxDistance !== undefined && entity.travelledDistance() > entity.maxDistance) {
            return
        }
        this.entityManager.set(entity)

        //// for any created entity -- create a renderable representation
        //// and add it onto the displayable surface

        this.clientRenderer.entity().create(entity)

        return entity
    }

    canFireBulletFrom = (source: Entity): boolean => {
        if (!this.playerEntity.visible) {
            // can"t fire bullets when you are visible
            // todo - serverside enforcement
            return false
        }
        if (!source.canShootBullets) {
            return false
        }

        const { weapon } = source

        const weaponType = weapon?.weaponType || "none"
        const characteristics = WeaponCharacteristics[weaponType]
        if (!characteristics) {
            return false
        }

        if (source.mana < characteristics.manaCost) {
            return false
        }

        if (this.lastWeapon?.weaponType !== weaponType) {
            this.lastFireTs = new Latch(characteristics.firingLatchMs)
            this.lastWeapon = weapon
        }

        if (!this.lastFireTs.expired()) {
            return false
        }

        const ammoNeeded = WeaponAmmo[weaponType]
        if (ammoNeeded) {
            const foundAmmo = locateInventoryItemObjectByItemType(source.inventory, ammoNeeded)
            if (!foundAmmo || foundAmmo.quantity < 1) {
                this.handleChat("I need craft or buy more " + ammoNeeded + "s.", source.entityId, "")

                let foundSlot
                locateInventoryReadiedItemObjects(source.inventory).find((item, slot) => {
                    if (!WeaponAmmo[item.itemType]) {
                        foundSlot = slot
                        return item
                    }
                })

                if (foundSlot !== undefined) {
                    this.clientRenderer.gui().activateInventorySlot(foundSlot + 1)
                }

                return false
            }
        }

        return true
    }

    createBulletFrom = (source: Entity): Entity[] | undefined => {
        const bullets: Entity[] = emitWeaponParticles(
            this.lastWeapon,
            {
                ...source.location,
                roomId: source.roomId,
            },
            source.movement,
        )

        return bullets
    }

    createBombFrom = (source: Entity): Entity | undefined => {
        if (!this.playerEntity.visible) {
            // can"t fire bullets when you are visible
            // todo - serverside enforcement
            return
        }
        if (!source.canLayBomb) {
            return
        }
        if (source.mana < 3) {
            return
        }

        if (!source.inventory?.items.find(item => item.itemType === "bomb")) {
            return
        }

        const bomb = new Entity()
        bomb.entityType = "bomb"
        bomb.location = { ...source.location }
        bomb.entityId = uuidv4()
        bomb.dimensions = { ...EntityDimensions[bomb.entityType] }
        bomb.speed = 600
        bomb.roomId = source.roomId
        this.entityManager.set(bomb)

        ////

        this.clientRenderer.entity().create(bomb)

        return bomb
    }

    isBootstrapped = () => {
        return this.roomId && this.tilemapMetaProvider
    }

    updateFromWorldState = (worldState: WorldState) => {
        const {
            scores,
            tilemaps,
            worldMetaDimensions,
            tilemapMeta,
            visibility,
            roomId,
            roomName,
            leaderboards,
            dayPhase,
            dayPct,
            biomeColorMap,
            locationIcons,
            playerLocations,
            minimapConfig,
            runLevel,
        } = worldState

        const updatedRoom = this.roomId !== roomId
        let updatedQuadrant = false
        if (updatedRoom) {
            //
            // we"re in a new room
            //
            this.runLevel = runLevel
            this.dayPhase = null
            this.roomId = roomId
            const currentQuardrantId = resolveQuadrantIdFrom(roomId, worldMetaDimensions)
            if (this.quadrantId !== currentQuardrantId) {
                this.quadrantId = currentQuardrantId
                updatedQuadrant = true
            }
            const toRemove = []
            this.entityManager.iterate(null, next => {
                if (next.entityId !== this.playerEntity.entityId) {
                    this.clientRenderer.entity().destroy(next)
                    toRemove.push(next.entityId)
                }
            })
            for (let i = 0; i < toRemove.length; i++) {
                this.entityManager.remove(toRemove[i])
            }
            this.incompleteEntities.clear()
            this.clientRenderer.gui().roomUpdated(roomId, roomName, visibility?.suppressMinimap)
            this.clientRenderer.gui().updateLeaderboard(null)

            clearInterval(this.brightnessInterval)
            this.currentBrightness = dayPhase ? dayPhase.brightness : 0.0

            if (dayPct !== undefined) {
                this.clientRenderer.gui().updateDayPct(dayPct)
            }
            this.updateDayPhase(dayPhase)
            this.playerController.updateRoom()
        }

        if (scores) {
            this.updateScores(scores)
        }

        if (updatedRoom && tilemaps && tilemapMeta) {
            this.tilemapMetaProvider = new TileMapMetaProvider(roomId, tilemapMeta, tilemaps, worldMetaDimensions)
            this.tilemapMetaProvider.biomeColorMap = biomeColorMap
            this.tilemapMetaProvider.locationIcons = locationIcons
            this.tilemapMetaProvider.minimapConfig = minimapConfig

            this.clientRenderer.map().update(
                this.tilemapMetaProvider,
                visibility
                    ? visibility
                    : dayPhase
                    ? {
                          darkness: dayPhase.brightness,
                      }
                    : undefined,
            )
            this.playerController.enabledMouseAction(true)
            this.playerController.enabledKeyboardAction(true)

            setTimeout(() => {
                this.playerController.perturb()
            }, 100)
        }

        if (leaderboards) {
            this.clientRenderer.gui().updateLeaderboard(leaderboards)
        }

        if (playerLocations) {
            this.clientRenderer.entity().updatePlayerLocations(playerLocations)
        }

        if (updatedRoom) {
            this.playerController.client.requestPlayerLocations()
            this.playerController.client.requestVisibility()
        }

        if (updatedQuadrant) {
            // alert("new quadrant")
        }

        return updatedRoom
    }

    updateFromPlayerMessage = (message: Message) => {
        if (this.playerEntity.entityId !== message.entityId) {
            return
        }

        if (message.roomId !== this.playerEntity.roomId) {
            this.playerEntity.roomId = message.roomId
        }

        this.playerEntity.mana = message.mana
        this.playerEntity.visible = message.visible
        this.playerEntity.canShootBullets = message.canShootBullets
        this.playerEntity.canLayBomb = message.canLayBomb
        // this.playerController.paralyze(message.paralyzeUntilTs > Date.now())
        this.playerEntity.upgradePoints = message.upgradePoints
        this.clientRenderer.entity().update(this.playerEntity)

        if (message.inventory) {
            this.updateInventoryFrom(this.playerEntity, message)
        }

        this.started = true
    }

    updateFromNonPlayerMessage = (message: Message) => {
        if (this.playerEntity.entityId === message.entityId) {
            return
        }

        if (message.roomId !== this.playerEntity.roomId) {
            return
        }

        const entity = this.entityManager.get(message.entityId)
        if (!entity) {
            return
        }

        if (message.inventory) {
            this.updateInventoryFrom(entity, message)
        }
    }

    updateInventoryFrom = (entity: Entity, message: Message) => {
        if (this.clientRenderer.gui().updateInventory(entity, message.inventory)) {
            entity.inventory = { ...message.inventory }
            return true
        }

        return false
    }

    scheduleForDestruction = (entityId: EntityId) => {
        this.destroyedEntities.add(entityId)
        this.destroyedEntitiesLagBuffer.add(entityId)
    }

    isDestroyed = (entityId: EntityId) => this.destroyedEntities.has(entityId)

    destroyEntity = (entity: Entity) => {
        if (entity) {
            this.clientRenderer.entity().destroy(entity)
        }
    }

    processEntities = (dt_sec: number) => {
        // process bullets
        this.entityManager.iterate("bullet", bullet => {
            if (bullet.isDead()) {
                this.scheduleForDestruction(bullet.entityId)
                return
            }

            const result = ApplyInputResultPool.instance.checkout()
            bullet.applyInput(
                undefined,
                dt_sec,
                bullet.speed2,
                result.data,
                collisionMover(this.tilemapMetaProvider),
                true,
            )
            if (!result.data?.movedX && !result.data?.movedY) {
                this.scheduleForDestruction(bullet.entityId)
            } else {
                const distance = bullet.travelledDistance()

                if (distance > bullet.maxDistance) {
                    this.scheduleForDestruction(bullet.entityId)
                }
            }
            ApplyInputResultPool.instance.release(result)
        })
    }

    cleanup = () => {
        const now = Date.now()
        if (now - this.expiredTs > 3000) {
            this.entityManager.iterate(
                null,
                next => {
                    this.scheduleForDestruction(next.entityId)
                },
                next => next.entityId !== this.playerEntity.entityId && next.roomId !== this.roomId,
            )
        }

        this.destroyedEntities.forEach(entityId => {
            const entity = this.entityManager.get(entityId)
            if (entity) {
                this.destroyEntity(entity)
                this.entityManager.remove(entity.entityId)
            }
        })
        this.destroyedEntities.clear()

        if (this.lagbufferTs.expired()) {
            this.destroyedEntitiesLagBuffer.clear()
        }
    }

    isTooDistantFromPlayer = (entity: Entity) => {
        return (
            this.playerEntity.entityId !== entity.entityId &&
            getEntityDistance(this.playerEntity, entity) > TileSize * 10
        )
    }

    doStruck = (entityId: EntityId, strikerEntityId: EntityId) => {
        const entity = this.entityManager.get(entityId)
        if (!entity) {
            return
        }

        if (!this.isTooDistantFromPlayer(entity)) {
            this.clientRenderer.entity().animateStrikeOn(entity)
            this.audio.hit(entity)
        }

        const strikerEntity = this.entityManager.get(strikerEntityId)
        if (strikerEntity) {
            this.scheduleForDestruction(strikerEntityId)
        }
        if (entity.entityId === this.playerEntity.entityId) {
            this.clientRenderer.map().screenShake(entity)
            if (entity.hp < Math.max(3, Math.ceil(entity.maxHp * 0.6))) {
                this.clientRenderer.gui().pulseDamage()
            }
        }
    }

    tileEffect = (effect: TileEffect) => {
        this.clientRenderer.map().tileEffect(effect)
        if (!["tallGrass", "tallReeds"].includes(effect.entityTile)) {
            this.audio.play("woodhit", {
                volume: 0.125,
                speed: 0.85 + Math.min(0.15, Math.random()),
            })
        }
    }

    detonate = (entityId: EntityId) => {
        const entity = this.entityManager.get(entityId)
        if (entity) {
            if (!this.isTooDistantFromPlayer(entity)) {
                this.clientRenderer.entity().detonate(entity)
            }
        }
    }

    attack = (entityId: EntityId, trigger?: Callback<void>) => {
        const entity = this.entityManager.get(entityId)
        if (entity) {
            if (!this.isTooDistantFromPlayer(entity)) {
                this.playerController.client.startAttack(entityId)
                this.clientRenderer.entity().attack(entity, trigger)
                this.audio.attack(entity)
            }
        }
    }

    updateActivation = (entityId: EntityId, active: boolean) => {
        const entity = this.entityManager.get(entityId)
        if (entity) {
            this.clientRenderer.entity().updateActivation(entity, active)
        }
    }

    itemPickup = (entity: Entity, itemTypeMeta: ItemTypeMeta, quantity: number) => {
        this.clientRenderer.entity().itemPickedUp(entity, itemTypeMeta, quantity)
        this.audio.pickup(itemTypeMeta)
    }

    orientation = (orientation: Orientation) => {
        this.playerController.updateOrientation(orientation)
    }

    emp = (entityId: EntityId, range: number) => {
        const entity = this.entityManager.get(entityId)
        if (entity) {
            this.clientRenderer.entity().emp(entity, range)
            this.audio.emp(entity)
        }
    }

    killed = (entityId: EntityId) => {
        const entity = this.entityManager.get(entityId)
        if (entityId === this.playerEntity.entityId) {
            this.clientRenderer.entity().death(this.playerEntity)

            this.playerController.paralyze(true, 5000)
            // this.clientRenderer.gui().fadeOut()
        } else {
            if (entity) {
                this.clientRenderer.entity().death(entity)
                this.audio.death(entity)
            }
        }
    }

    respawned = (entityId: EntityId) => {
        if (entityId === this.playerEntity.entityId) {
            this.fadeInOut("in")
            setTimeout(() => {
                this.playerController.perturb()
            }, 100)
        }
    }

    teleported = (entityId: EntityId) => {
        if (entityId === this.playerEntity.entityId) {
            this.clientRenderer.map().reset()
        }
    }

    pleaseWait = () => {
        this.clientRenderer.gui().addScreenMessage({
            text: "Loading, please wait...",
            target: "important",
        })
        this.fadeInOut("inOut", 10000)
    }

    fadeInOut = (direction: "in" | "out" | "inOut", timeOut: number = 1000) => {
        if (direction === "in") {
            if (this.blackedOutTimeout) {
                clearTimeout(this.blackedOutTimeout)
                this.blackedOutTimeout = undefined
            }
            this.clientRenderer.gui().fadeIn()
            this.playerController.paralyze(false)
        }
        if (direction === "out") {
            this.clientRenderer.gui().fadeOut(true)
            this.playerController.paralyze(true, timeOut)
        }
        if (direction === "inOut") {
            this.fadeInOut("out", timeOut)
            this.blackedOutTimeout = setTimeout(() => {
                this.fadeInOut("in")
            }, timeOut)
        }
    }

    advanceEntity = (result: ApplyInputResult, entity: Entity, message: Message, allowSlide?: boolean) => {
        if (!this.tilemapMetaProvider) {
            return
        }

        return entity.applyInput(
            message.angle,
            message.press_time,
            message.speed2,
            result,
            collisionMover(this.tilemapMetaProvider),
            allowSlide,
            allowSlide,
        )
    }

    advancePlayer = (result: ApplyInputResult, message: Message) => {
        const { x: originalX, y: originalY } = this.playerEntity.location
        this.advanceEntity(result, this.playerEntity, message, true)
        this.clientRenderer.updatePlayerLocation(this.playerEntity)
        if (originalX !== this.playerEntity.location.x || originalY !== this.playerEntity.location.y) {
            this.audio.walk(this.playerEntity)
        }
    }

    handleChat = (message: string, entityId: EntityId, entityName: string) => {
        const entity = this.entityManager.get(entityId)
        if (entityName) {
            this.clientRenderer.gui().appendChatHistory(`${entityName}: ${message}`)
        }
        if (entity) {
            this.clientRenderer.entity().spoke(entity, message)
        }
    }

    hideRoomName = (hidden: boolean) => {
        this.clientRenderer.gui().hideRoomName(hidden)
    }

    updateLocation = (location: string, roomId?: string) => {
        if (!roomId || roomId === this.roomId) {
            this.clientRenderer.gui().updateLocation(location)
        }
    }

    renderScene = () => {
        // one ring to rule them all
        this.clientRenderer.render()
    }

    createLocalEntity = (entitySrc: any) => {
        const entity = Entity.fromJson(entitySrc)
        setTimeout(() => {
            this.entityManager.set(entity)
            this.clientRenderer.entity().create(entity)
        }, 250)
    }

    destroyLocalEntity = (targetFlags: Record<string, any>) => {
        this.entityManager
            .collect(entity => {
                return !Object.keys(targetFlags).find(flag => targetFlags[flag] !== entity.flags[flag])
            })
            .forEach(entity => {
                entity.visible = false
                this.scheduleForDestruction(entity.entityId)
            })
    }

    playSoundEffect = (effect: string, loop: boolean, target: string) => {
        this.audio.soundEffect(effect, loop, target)
    }

    enablePlayerMouseAction = (enabled: boolean) => this.playerController?.enabledMouseAction(enabled)

    animateLocalEntityCollection = (entityId: EntityId, target: string, position?: number) => {
        const entity = this.entityManager.get(entityId)
        if (!entity) {
            return
        }
        entity.visible = false
        this.clientRenderer.gui().animateLocalEntityCollection(entity, target, position)
    }

    animateAbility = (abilityType: AbilityType, duration?: number) => {
        this.clientRenderer.gui().animateAbility(abilityType, duration)
    }

    upgradedAccount = () => {
        this.playerController.setIsGuest(false)
        this.clientRenderer.gui().helpMessaging()
    }

    updateDayPhase = (phase: DayPhase) => {
        if (phase?.name !== this.dayPhase?.name) {
            this.dayPhase = { ...phase }

            const targetBrightness = this.dayPhase.brightness
            if (this.brightnessInterval) {
                clearInterval(this.brightnessInterval)
            }
            this.brightnessInterval = setInterval(() => {
                if (this.currentBrightness > targetBrightness) {
                    this.currentBrightness -= 0.01
                } else if (this.currentBrightness < targetBrightness) {
                    this.currentBrightness += 0.01
                }

                if (Math.abs(this.currentBrightness - targetBrightness) <= 0.01) {
                    clearInterval(this.brightnessInterval)
                    this.currentBrightness = targetBrightness
                }

                const currentVisibility = this.clientRenderer.map().getVisibility()
                const visibility: Visibility = {
                    ...(currentVisibility || {}),
                    darkness: this.currentBrightness,
                }
                this.clientRenderer.map().update(null, visibility)
            }, 60)
        }
    }

    log = (text: string) => {
        this.playerController.client.logActivity(text)
    }

    incompleteEntity = (entity: Entity) => {
        this.incompleteEntities.add(entity.entityId)
    }

    nearbyIncompleteEntities = (): EntityId[] => {
        const entities = []
        this.incompleteEntities.forEach(e => {
            if (e === this.playerEntity.entityId) {
                return false
            }
            const inc = this.entityManager.get(e)
            if (!inc) {
                return false
            }
            const distance = getDistance(
                inc.location.x,
                inc.location.y,
                this.playerEntity.location.x,
                this.playerEntity.location.y,
            )
            if (distance < 10 * TileSize) {
                entities.push(e)
            }
        })

        return entities
    }

    clearIncompleteEntities = (entityIds: EntityId[]) => {
        entityIds.forEach(id => {
            this.incompleteEntities.delete(id)
        })
    }

    saveAppearance = (appearance: Appearance) => {
        this.playerController.client.saveAppearance(appearance)
    }

    invokeCrafting = () => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "REQUEST_CRAFTING",
            context: {},
        })
    }

    invokeInventory = (inventoryActionToken: string) => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "REQUEST_INVENTORY",
            context: {
                actionToken: inventoryActionToken,
            },
        })
    }

    invokeQuests = () => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "REQUEST_QUESTS",
            context: {},
        })
    }

    emitConversationResponse = (response: any) => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "CONVERSATION_RESPONSE",
            context: response,
        })
    }

    requestEntityInteractions = (entityId: EntityId) => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "REQUEST_ENTITY_INTERACTIONS",
            context: {
                entityId,
            },
        })
    }

    interactionCallback = (entityId: EntityId, interaction: InteractionType) => {
        this.playerController.client.emitPlayerEvent({
            playerEventType: "ACTIVATE_ENTITY_INTERACTION",
            context: {
                interaction,
                entityId,
            },
        })
    }

    selectSlot = (itemId: string) => {
        const item = this.playerEntity.inventory?.items.find(i => i.id === itemId)
        if (!item) {
            return
        }
        this.playerController.client.inventoryAction(
            this.playerEntity.entityId,
            itemId,
            item.active ? "deactivate" : "activate",
        )
    }

    activateWorldPoint = (roomFinePoint: Coordinate, options?: BuildingPointOptions) => {
        if (emptyCoordinate(roomFinePoint)) {
            return
        }
        this.playerController.client.activateWorldPoint(roomFinePoint, options)
    }

    entityClicked = (entity: Entity) => {
        if (entity.clickable) {
            // todo - rename this, entity.clickable means that the server has some
            // plan for whenever the player explicitly clicks on the entity
            this.playerController.client.entityClicked(entity)
        }
    }
}
