import { Graphics, Text } from "pixi.js"
import { ScreenDimensions, ScreenMessage } from "game-common/models"
import { TextRenderer } from "../text/text_renderer"

export class ImportantMessage extends Graphics {
    color: number
    screenMessage: ScreenMessage
    bannerText: Text
    dismiss: any
    dismissInterval: any

    constructor(color: number = 0xffffff, screenMessage: ScreenMessage) {
        super()
        this.color = color
        this.screenMessage = screenMessage
    }

    update = (message: ScreenMessage) => {
        const { text, duration } = message
        if (this.dismiss) {
            clearTimeout(this.dismiss)
        }
        if (this.dismissInterval) {
            clearInterval(this.dismissInterval)
        }

        this.clear()
        this.removeChildren()
        this.scale.set(1.0)
        this.alpha = 1.0

        const bannerText = new TextRenderer().variant("ImportantFont").color(this.color).render(text) as Text
        const padding = 60
        bannerText.x = padding
        bannerText.y = padding

        this.bannerText = bannerText

        this.addChild(bannerText)

        super.lineStyle(1, 0xffffff, 0.5)
        super.beginFill(0x000000, 0.8)
        super.drawRoundedRect(0, 0, this.width + padding * 2, this.height + padding * 2, 8)
        super.endFill()

        let scale = 1.0

        const wordCount = text.split(" ").length
        const delayMs = duration || Math.max(1000, ((wordCount * 60) / 200) * 1000)

        this.dismiss = setTimeout(() => {
            this.dismissInterval = setInterval(() => {
                scale -= 0.05
                this.scale.set(scale)
                this.alpha = scale

                this.x = ScreenDimensions.w * 0.65 - this.width / 2

                if (scale < 0.0) {
                    if (message.displayCallback) {
                        message.displayCallback()
                    }
                    clearInterval(this.dismissInterval)
                    this.clear()
                    this.removeChildren()
                    this.dismissInterval = null
                }
            }, 10)

            this.dismiss = null
        }, delayMs)
    }
}
