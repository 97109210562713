import { v4 as uuidv4 } from "uuid"

import {
    Armor,
    ArmorColors,
    ArmorTypes,
    BauldronColors,
    Cape,
    CapeColors,
    Color,
    Pants,
    PantsColors,
    Sex,
    SexCapes,
    SexPants,
    SexShirts,
    SexShoes,
    Shield,
    ShieldColors,
    ShieldTypes,
    Shirt,
    ShirtColors,
    Shoe,
    ShoeColors,
    WeaponColors,
    WeaponTypes,
} from "../character/character"
import { EffectDescriptor } from "../mechanics/effect_mechanics"
import { Mechanics } from "../mechanics/mechanics"
import { Entity, EntityFlags, EntityId, EntityType, Inventory, InventoryItem, WeaponType, randomBool } from "../models"
import { Skill } from "../skills/skills"
import { randomOneOf } from "../util"
import { ItemClassTypes } from "./item_class"
import { ItemSprites } from "./item_sprites"
import { Bauldron, ItemClassToTypes, ItemTypes } from "./item_type"
import { ItemTypesToMeta } from "./item_meta"
import { BuildType } from "../mechanics/item_mechanics"

export const maxInventoryItemsSize = 30
export type ItemMaterial = "wood" | "metal" | "gold"

export type ItemClass = (typeof ItemClassTypes)[number]

export interface ItemCharacteristics {
    equippable?: boolean
    readyable?: boolean
    buildable?: boolean
    exclusiveReadiness?: boolean
    stackable?: boolean
    useable?: boolean
    useOnBuild?: boolean
    useHasWorldEffect?: boolean
    stableAngleOnDrop?: boolean
    isCurrency?: boolean
    isAmmo?: boolean
    unsellable?: boolean
    unbuyable?: boolean
    hasDurability?: boolean
    disallowedClassesOnActive?: ItemClass[]
    buildType?: BuildType
}

export const InventoryCapableEntityTypes: Partial<Record<EntityType, boolean>> = {
    npc: true,
    player: true,
}

export interface ItemTypeMeta {
    type: ItemTypes
    class: ItemClass
    color: Color
    spriteId?: string
}

export const StackableItemClasses: Partial<Record<ItemClass, boolean>> = {
    resource: true,
    structure: true,
}
const computeAllItemTypesToMetaByColor = () => {
    const metaRecord: Partial<Record<ItemTypes, ItemTypeMeta[]>> = {}

    ItemClassTypes.forEach(itemClass => {
        const itemTypes = ItemClassToTypes[itemClass] || []
        itemTypes.forEach((itemType: ItemTypes) => {
            //
            // check the explicit list first
            //
            if (ItemTypesToMeta[itemType]) {
                metaRecord[itemType] = ItemTypesToMeta[itemType]
                return
            }

            //
            // not explicitly defined, generate it
            //
            // by default, no particular color
            let colors: Color[] = ["na"]

            //
            // these are the lpc items
            //
            if ("weapon" === itemClass) {
                colors = WeaponColors[itemType]
            }

            if ("pants" === itemClass) {
                colors = PantsColors[itemType]
            }

            if ("shirt" === itemClass) {
                colors = ShirtColors[itemType]
            }

            if ("armor" === itemClass) {
                colors = ArmorColors[itemType]
            }

            if ("shoes" === itemClass) {
                colors = ShoeColors[itemType]
            }

            if ("shield" === itemClass) {
                colors = ShieldColors[itemType]
            }

            if ("cape" === itemClass) {
                colors = CapeColors[itemType]
            }

            if ("bauldron" === itemClass) {
                colors = BauldronColors[itemType]
            }

            let spriteId = ItemSprites[itemType]
            colors.forEach(color => {
                const itemTypeMeta: ItemTypeMeta = {
                    type: itemType,
                    class: itemClass,
                    color,
                    spriteId,
                }
                if (!metaRecord[itemType]) {
                    metaRecord[itemType] = []
                }
                metaRecord[itemType].push(itemTypeMeta)
            })
        })
    })
    return metaRecord
}

export const AllItemTypesToMetaByColor: Partial<Record<ItemTypes, ItemTypeMeta[]>> = computeAllItemTypesToMetaByColor()

export const resolveItemTypeToMeta = (itemType: ItemTypes, color?: Color, random?: boolean): ItemTypeMeta => {
    const range = AllItemTypesToMetaByColor[itemType]
    if (color) {
        return range?.find(item => item.color === color)
    }

    return random ? randomOneOf(range) : range[0]
}

export const resolveItemTypeToMetaRange = (itemType: ItemTypes): ItemTypeMeta[] => AllItemTypesToMetaByColor[itemType]

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// effects
export const ItemEffects: Partial<Record<ItemTypes, EffectDescriptor>> = {
    torch: {
        effect: "light",
        clockDuration: 5 * 10,
    },
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// crafting

export interface CraftableItemTypeMeta {
    itemTypeMeta: ItemTypeMeta
    craftable: boolean
}

export interface ItemIngredient {
    itemTypeMeta: ItemTypeMeta
    quantity: number
}

export interface ItemRecipe {
    itemTypeMeta: ItemTypeMeta
    ingredients: ItemIngredient[]
    requiredSkills?: Skill[]
    quantity?: number
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////
// trading
export interface TradingValueItemTypeMeta {
    itemType: ItemTypes
    quantity: number
}

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export const randomThing = <T>(
    sex: Sex,
    clazz: ItemClass,
    pool: Record<Sex, T[]>,
    colorPool: Record<any, Color[]>,
    defaultColor?: Color,
) => {
    const shirt = randomOneOf<T>(pool[sex])
    return {
        type: shirt,
        class: clazz,
        color:
            defaultColor && colorPool[shirt].find((c: Color) => c === defaultColor)
                ? defaultColor
                : randomOneOf<Color>(colorPool[shirt]),
    }
}

export const randomArmor = (sex: Sex, color?: Color) =>
    randomThing<Armor>(sex, "armor", { male: ArmorTypes as any, female: ArmorTypes as any }, ArmorColors, color)
export const randomShield = (sex: Sex) =>
    randomThing<Shield>(sex, "shield", { male: ShieldTypes as any, female: ShieldTypes as any }, ShieldColors)
export const randomPants = (sex: Sex, color?: Color) => randomThing<Pants>(sex, "pants", SexPants, PantsColors, color)
export const randomShirt = (sex: Sex, color?: Color) => randomThing<Shirt>(sex, "shirt", SexShirts, ShirtColors, color)
export const randomShoes = (sex: Sex, color?: Color) => randomThing<Shoe>(sex, "shoes", SexShoes, ShoeColors, color)
export const randomCape = (sex: Sex, color?: Color) => randomThing<Cape>(sex, "cape", SexCapes, CapeColors, color)
export const randomBauldron = (sex: Sex) =>
    randomThing<Bauldron>(sex, "bauldron", { female: ["bauldron"], male: ["bauldron"] }, BauldronColors)

export interface RandomEquipmentSpec {
    weapon?: boolean | WeaponType
    pants?: boolean | Pants
    shirt?: boolean | Shirt
    armor?: boolean | Armor
    shoe?: boolean | Shoe
    shield?: boolean | Shield
    cape?: boolean | Cape
    bauldron?: boolean | Bauldron
    averageColor?: Color
    chicken?: number
    arrows?: number
    shards?: number
}

export const generateItems = (sex: Sex, spec?: RandomEquipmentSpec): InventoryItem[] => {
    const itemTypes: ItemTypeMeta[] = []
    const averageColor = spec?.averageColor

    const randomWeapon = () => {
        const weapon = randomOneOf<any>(WeaponTypes as any)
        return {
            type: weapon,
            class: "weapon",
            color: WeaponColors[weapon]?.find((c: Color) => c === averageColor)
                ? averageColor
                : randomOneOf<Color>(WeaponColors[weapon]),
        }
    }

    const weapon =
        !spec || spec.weapon
            ? typeof spec.weapon === "boolean"
                ? randomWeapon()
                : resolveItemTypeToMeta(spec.weapon as ItemTypes)
            : undefined
    const pants =
        !spec || spec.pants
            ? typeof spec.pants === "boolean"
                ? randomPants(sex, averageColor)
                : resolveItemTypeToMeta(spec.pants)
            : undefined
    const shirt =
        !spec || spec.shirt
            ? typeof spec.shirt === "boolean"
                ? randomShirt(sex, averageColor)
                : resolveItemTypeToMeta(spec.shirt)
            : undefined
    const armor =
        !spec || spec.armor
            ? typeof spec.armor === "boolean"
                ? randomArmor(sex, averageColor)
                : resolveItemTypeToMeta(spec.armor)
            : undefined
    const shoe =
        !spec || spec.shoe
            ? typeof spec.shoe === "boolean"
                ? randomShoes(sex, averageColor)
                : resolveItemTypeToMeta(spec.shoe)
            : undefined
    const shield = !spec || spec.shield ? randomShield(sex) : undefined
    const cape = !spec || spec.cape ? randomCape(sex, averageColor) : undefined
    const bauldron =
        !spec || spec.bauldron
            ? typeof spec.bauldron === "boolean"
                ? randomBauldron(sex)
                : resolveItemTypeToMeta(spec.bauldron)
            : undefined

    if (weapon) {
        itemTypes.push(weapon as ItemTypeMeta)
    }

    if (pants) {
        itemTypes.push(pants)
    }

    if (shirt) {
        itemTypes.push(shirt)
    }

    if (armor) {
        itemTypes.push(armor)
    }

    if (shoe) {
        itemTypes.push(shoe)
    }

    if (shield) {
        itemTypes.push(shield)
    }

    if (cape) {
        itemTypes.push(cape)
    }

    if (bauldron) {
        itemTypes.push(bauldron)
    }

    if (spec.chicken) {
        itemTypes.push(resolveItemTypeToMeta("drumstick"))
    }

    if (spec.shards) {
        itemTypes.push(resolveItemTypeToMeta("shard-metal"))
    }

    return itemTypes.map((itemTypeMeta: ItemTypeMeta): InventoryItem => {
        return {
            itemType: "none",
            itemTypeMeta,
            active: itemTypeMeta.class !== "resource",
            id: uuidv4(),
        }
    })
}

export const randomItems = (sex: Sex, noWeapon?: boolean): ItemTypeMeta[] => {
    const itemTypes: ItemTypeMeta[] = []

    const weapon = !noWeapon && randomBool() ? randomOneOf<any>(WeaponTypes as any) : undefined
    const pants = randomPants(sex)
    const shirt = sex === "female" ? randomShirt(sex) : randomBool() ? randomShirt(sex) : undefined
    const armor = randomArmor(sex)
    const shoe = randomBool() ? randomShoes(sex) : undefined
    const shield = randomBool() ? randomOneOf<any>(ShieldTypes as any) : undefined
    const cape = randomBool() ? randomCape(sex) : undefined
    const bauldron = randomBool() ? randomBauldron(sex) : undefined

    if (weapon) {
        itemTypes.push({
            type: weapon,
            class: "weapon",
            color: randomOneOf<Color>(WeaponColors[weapon]),
        })
    }
    if (pants) {
        itemTypes.push(pants)
    }
    if (shirt) {
        itemTypes.push(shirt)
    }
    if (armor) {
        itemTypes.push(armor)
    }
    if (shoe) {
        itemTypes.push(shoe)
    }
    if (shield) {
        itemTypes.push({
            type: shield,
            class: "shield",
            color: randomOneOf<Color>(ShieldColors[shield]),
        })
    }
    if (cape) {
        itemTypes.push(cape)
    }
    if (bauldron) {
        itemTypes.push(bauldron)
    }

    return itemTypes
}

export const locateInventoryItemObjects = (inventory: Inventory, itemClass: ItemClass): InventoryItem[] =>
    inventory?.items?.filter(item => item.itemTypeMeta?.class === itemClass) || []

export const locateInventoryActiveItem = (inventory: Inventory, itemClass: ItemClass): ItemTypeMeta =>
    locateInventoryActiveItemObject(inventory, itemClass)?.itemTypeMeta

export const locateInventoryActiveItemObject = (inventory: Inventory, itemClass: ItemClass): InventoryItem =>
    inventory?.items?.find(item => item.itemTypeMeta?.class === itemClass && item.active)

export const locateInventoryReadiedItemObjects = (inventory: Inventory, itemClass?: ItemClass): InventoryItem[] =>
    inventory?.items?.filter(item => (!itemClass || item.itemTypeMeta?.class === itemClass) && item.readied) || []

export const locateInventoryItemObjectByItemType = (inventory: Inventory, itemType: ItemTypes): InventoryItem =>
    inventory?.items?.find(item => item.itemTypeMeta?.type === itemType)

export const locateInventoryItemObjectByItemTypeMeta = (
    inventory: Inventory,
    meta: ItemTypeMeta,
    maxQuantity?: number,
): InventoryItem =>
    inventory?.items?.find(
        item =>
            (!maxQuantity || (item.quantity || 1) < maxQuantity) &&
            item.itemTypeMeta?.type === meta.type &&
            item.itemTypeMeta?.class === meta.class &&
            item.itemTypeMeta?.color === meta.color,
    )

export type InventoryAction = "ready" | "unready" | "activate" | "deactivate" | "drop" | "use"

export const readyInventoryItem = (entity: Entity, itemId: string): Entity => {
    return changeInventoryItemFlags(entity, itemId, { readied: true })
}

export const unreadyInventoryItem = (entity: Entity, itemId: string): Entity => {
    return changeInventoryItemFlags(entity, itemId, { readied: false })
}

export const activateInventoryItem = (entity: Entity, itemId: string): Entity => {
    const { inventory } = entity
    const item = inventory.items?.find(item => item.id === itemId)
    if (!item) {
        return entity
    }

    const { itemTypeMeta } = item
    Mechanics.item.properties
        .disallowedClassesOnActive(itemTypeMeta)
        .map(klass => locateInventoryActiveItemObject(entity.inventory, klass))
        .filter(n => !!n)
        .forEach(conflict => {
            const currentlyActiveItem = locateInventoryActiveItemObject(entity.inventory, conflict.itemTypeMeta.class)
            if (currentlyActiveItem) {
                entity = deactivateInventoryitem(entity, currentlyActiveItem.id)
            }
        })

    if (Mechanics.item.properties.exclusiveReadiness(itemTypeMeta)) {
        // can only have one active at a time per class
        const currentlyActiveItem = locateInventoryActiveItemObject(entity.inventory, itemTypeMeta.class)
        if (currentlyActiveItem) {
            entity = deactivateInventoryitem(entity, currentlyActiveItem.id)
        }
    }

    return changeInventoryItemFlags(entity, itemId, { active: true })
}

export const deactivateInventoryitem = (entity: Entity, itemId: string): Entity => {
    const itemToDeactiate = entity.inventory?.items?.find(item => item.id === itemId)
    let updatedEntity = changeInventoryItemFlags(entity, itemId, { active: false })
    if (itemToDeactiate?.itemTypeMeta?.class === "weapon" && updatedEntity.weapon) {
        updatedEntity.weapon.weaponType = "none"
    }
    return updatedEntity
}

export interface CreateInventoryItemProps {
    sourceItem?: Entity
    quantity?: number
    hp?: number
    id?: EntityId
    flags?: EntityFlags
}
export const createInventoryItem = (item: ItemTypeMeta, props?: CreateInventoryItemProps): InventoryItem => {
    const { sourceItem, quantity, hp, id, flags } = props
    const inventoryItem: InventoryItem = {
        id: id || sourceItem?.entityId || uuidv4(),
        itemType: (item.class === "weapon" ? item.type : "none") as WeaponType,
        itemTypeMeta: { ...item },
        flags: sourceItem?.flags ? { ...sourceItem.flags } : flags,
        quantity: quantity || sourceItem?.quantity,
        hp: sourceItem?.hp || hp || Mechanics.item.durability.computeMaxDurability(item),
    }
    return inventoryItem
}

export const addInventoryItem = (entity: Entity, item: InventoryItem): Entity => {
    const { inventory } = entity
    inventory.items?.push(item)
    return updateInventorySideEffects(entity)
}

export const updateInventoryItemQuantity = (entity: Entity, item: InventoryItem, quantity: number): Entity => {
    const updatedQuantity = (item.quantity || 1) + quantity
    item.quantity = updatedQuantity
    return updateInventorySideEffects(entity)
}

export const updateInventoryItemDurability = (entity: Entity, item: InventoryItem, hp: number): Entity => {
    const updatedHp = (item.hp || 1) + hp
    item.hp = Math.max(0, updatedHp)
    return updateInventorySideEffects(entity)
}

export const removeInventoryItem = (entity: Entity, itemId: string): Entity => {
    const { inventory } = entity
    const item = inventory?.items.find(n => n.id === itemId)
    if (item?.active && item.itemTypeMeta.class === "weapon") {
        entity.weapon.weaponType = "none"
    }
    const updatedItems = inventory.items?.filter(i => i.id !== itemId)
    inventory.items = updatedItems
    return updateInventorySideEffects(entity)
}

const changeInventoryItemFlags = (
    entity: Entity,
    itemId: string,
    {
        active,
        readied,
    }: {
        active?: boolean
        readied?: boolean
    },
): Entity => {
    const { inventory } = entity
    const item = inventory.items?.find(item => item.id === itemId)
    if (!item) {
        return entity
    }

    item.active = active !== undefined ? active : item.active
    item.readied = readied !== undefined ? readied : item.readied
    return updateInventorySideEffects(entity)
}

const updateInventorySideEffects = (entity: Entity): Entity => {
    const { inventory } = entity
    if (entity.appearance) {
        entity.appearance.inventoryAppearanceHash = computeInventoryAppearanceHash(inventory)
        entity.inventory.lastUpdateTs = Date.now()
    }

    return entity
}

export const computeInventoryAppearanceHash = (inventory?: Inventory) => {
    if (!inventory) {
        return ""
    }

    const weapon = locateInventoryActiveItem(inventory, "weapon")
    const pants = locateInventoryActiveItem(inventory, "pants")
    const shirt = locateInventoryActiveItem(inventory, "shirt")
    const armor = locateInventoryActiveItem(inventory, "armor")
    const shoes = locateInventoryActiveItem(inventory, "shoes")
    const shield = locateInventoryActiveItem(inventory, "shield")
    const cape = locateInventoryActiveItem(inventory, "cape")
    const bauldron = locateInventoryActiveItem(inventory, "bauldron")

    return [weapon, pants, shirt, armor, shoes, shield, cape, bauldron]
        .filter(n => !!n)
        .map(n => `${n.class}/${n.color}/${n.type}`)
        .join("-")
}
