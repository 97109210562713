import {
    Coordinate,
    Entity,
    EntityId,
    getCoordinateDistance,
    getDistance,
    getEntityDistance,
    Latch,
    Orientation,
    randomIntBetween,
    TileSize,
} from "game-common/models"
import {
    blankCoordinate,
    calculateNewCoordinateFromRadians,
    calculatePointAtDistance,
    Callback,
    clearCoordinate,
    degrees_to_radians,
    emptyCoordinate,
    fineToRough,
    fineToRoughCoordinates,
    floorCoordinates,
    getAngle,
    isEqual,
    radians_to_degrees,
    roughToFine,
    roughToFineCoordinates,
    roundCoordinates,
} from "game-common/util"

import { Client } from "./client"
import { IKeyboard, IMouse, MouseState } from "./client_models"
import { isMobile, ScalingParams } from "./client_util"
import { MapRenderingManager } from "./rendering/impl/pixijs/map_rendering_manager"
import { Fader } from "./rendering/impl/pixijs/gui/fader"
import { MapEntry } from "game-common/room/base_map_builder"
import { Sprite } from "pixi.js"

class DegreesRotator {
    targetAngle: number
    increment: number
    currentAngle: number

    constructor(targetAngle: number, increment: number, currentAngle: number) {
        this.targetAngle = targetAngle
        this.currentAngle = currentAngle
        this.increment = increment

        const delta = this.targetAngle - this.currentAngle

        if (Math.abs(delta) >= 180) {
            if (this.targetAngle > this.currentAngle) {
                this.currentAngle += 360
            } else {
                this.targetAngle += 360
            }
        }
    }

    update = (): boolean => {
        const delta = this.targetAngle - this.currentAngle
        if (delta < 0) {
            this.currentAngle -= this.increment
        } else {
            this.currentAngle += this.increment
        }

        if (Math.abs(delta) < this.increment) {
            return true
        }
        return false
    }

    normalizedCurrent = () => {
        return this.currentAngle > 360 ? this.currentAngle - 360 : this.currentAngle
    }

    normalizedTarget = () => {
        return this.targetAngle > 360 ? this.targetAngle - 360 : this.targetAngle
    }
}

export class Player {
    client: Client
    entity: Entity
    private mouseHandler: IMouse
    private keyboardHandler: IKeyboard
    private isChat: boolean = false
    private lastMouseMoveTs: number = 0
    private keyboardActionEnabled: boolean = true
    private mouseActionEnabled: boolean = true
    private paralyzed: boolean
    private randomness: any = undefined
    private isFiring: boolean
    private fireInterval: any
    private guest: boolean
    private targetKeyboardAngle: number
    private keyboardAndleAdjustInterval: any
    private pointer: Coordinate
    private roughPointer: Coordinate
    private roughCompositeTilePointer: Coordinate = blankCoordinate()
    private movementDaemon: any
    private longRangedEquipped: boolean = undefined
    private mousePointerFadeInterval: any
    private mousePointerFader: Fader
    private terrainPointerOn: boolean = false
    private terrainPointerSprites: Set<Sprite> = new Set()
    private isAutoMoving: boolean
    private hoveredEntity: EntityId
    private autoMovingInterval: any

    constructor(client: Client, entity: Entity, mouseHandler: IMouse, keyboardHandler: IKeyboard, guest: boolean) {
        this.client = client
        this.entity = entity
        this.mouseHandler = mouseHandler
        this.keyboardHandler = keyboardHandler
        this.guest = guest

        keyboardHandler.addListener(this.respondToKeyBoard)
        mouseHandler.addListener(this.respondToMouse)
    }

    private isKeyOn = (key: string) =>
        this.keyboardHandler.state().get(key) || this.keyboardHandler.state().get(key.toUpperCase())
    private isButtonOn = (key: string) => this.mouseHandler.state().buttons.get(key) == true

    adjustEntityAngle = (entity: Entity, delta: Coordinate) =>
        getAngle(
            { x: entity.location.x, y: entity.location.y },
            { x: entity.location.x + delta.x, y: entity.location.y + delta.y },
        )

    private keyboardBasedAngle = (entity: Entity) => {
        const leftRightOn =
            this.isKeyOn("a") || this.isKeyOn("ArrowLeft") || this.isKeyOn("d") || this.isKeyOn("ArrowRight")
        const upDownOn = this.isKeyOn("w") || this.isKeyOn("ArrowUp") || this.isKeyOn("s") || this.isKeyOn("ArrowDown")

        let newTargetAngle

        if (!leftRightOn) {
            if (this.isKeyOn("w") || this.isKeyOn("ArrowUp")) {
                newTargetAngle = this.adjustEntityAngle(entity, { x: 0, y: 1 })
            }
            if (this.isKeyOn("s") || this.isKeyOn("ArrowDown")) {
                newTargetAngle = this.adjustEntityAngle(entity, { x: 0, y: -1 })
            }
        } else {
            if (this.isKeyOn("w") || this.isKeyOn("ArrowUp")) {
                if (this.isKeyOn("a") || this.isKeyOn("ArrowLeft")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: 1, y: 1 })
                }
                if (this.isKeyOn("d") || this.isKeyOn("ArrowRight")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: -1, y: 1 })
                }
            }
            if (this.isKeyOn("s") || this.isKeyOn("ArrowDown")) {
                if (this.isKeyOn("a") || this.isKeyOn("ArrowLeft")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: 1, y: -1 })
                }
                if (this.isKeyOn("d") || this.isKeyOn("ArrowRight")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: -1, y: -1 })
                }
            }
        }

        if (!upDownOn) {
            if (this.isKeyOn("a") || this.isKeyOn("ArrowLeft")) {
                newTargetAngle = this.adjustEntityAngle(entity, { x: 1, y: 0 })
            }
            if (this.isKeyOn("d") || this.isKeyOn("ArrowRight")) {
                newTargetAngle = this.adjustEntityAngle(entity, { x: -1, y: 0 })
            }
        } else {
            if (this.isKeyOn("a") || this.isKeyOn("ArrowLeft")) {
                if (this.isKeyOn("w") || this.isKeyOn("ArrowUp")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: 1, y: 1 })
                }
                if (this.isKeyOn("s") || this.isKeyOn("ArrowDown")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: 1, y: -1 })
                }
            }
            if (this.isKeyOn("d") || this.isKeyOn("ArrowRight")) {
                if (this.isKeyOn("w") || this.isKeyOn("ArrowUp")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: -1, y: 1 })
                }
                if (this.isKeyOn("s") || this.isKeyOn("ArrowDown")) {
                    newTargetAngle = this.adjustEntityAngle(entity, { x: -1, y: -1 })
                }
            }
        }

        if (newTargetAngle !== undefined && newTargetAngle !== this.targetKeyboardAngle) {
            if (this.keyboardAndleAdjustInterval) {
                clearInterval(this.keyboardAndleAdjustInterval)
            }
            this.targetKeyboardAngle = newTargetAngle

            const increment = 5

            const rotator = new DegreesRotator(
                Math.floor(radians_to_degrees(newTargetAngle)),
                increment,
                Math.floor(radians_to_degrees(this.entity.movement.angle)),
            )

            this.keyboardAndleAdjustInterval = setInterval(() => {
                const done = rotator.update()
                this.entity.movement.angle = degrees_to_radians(rotator.normalizedCurrent())
                if (done) {
                    this.entity.movement.angle = degrees_to_radians(rotator.normalizedTarget())
                    clearInterval(this.keyboardAndleAdjustInterval)
                }
            }, 5)
        }
    }

    updateRoom = () => {
        if (this.pointer) {
            this.pointer = undefined
        }

        if (this.roughPointer) {
            this.roughPointer = undefined
        }

        this.hoveredEntity = undefined
    }

    updateOrientation = (orientation: Orientation) => {
        const { entity } = this

        switch (orientation) {
            case "south": {
                entity.movement.angle = this.adjustEntityAngle(entity, { x: 0, y: -1 })
                break
            }
            case "north": {
                entity.movement.angle = this.adjustEntityAngle(entity, { x: 0, y: 1 })
                break
            }
            case "east": {
                entity.movement.angle = this.adjustEntityAngle(entity, { x: -1, y: 0 })
                break
            }
            case "west": {
                entity.movement.angle = this.adjustEntityAngle(entity, { x: 1, y: 0 })
                break
            }
        }
    }

    private respondToKeyBoard = (key: string, down: boolean): void => {
        if (!this.client.clientGameLogic.isBootstrapped()) {
            // can't do anything unless you're bootstrapped
            return
        }
        if (!this.keyboardActionEnabled) {
            return
        }
        const { entity } = this

        if (this.isKeyOn("/") && !this.isChat) {
            this.client.startChat()
            this.isChat = true
        }

        if (this.isKeyOn("Enter")) {
            if (this.isChat) {
                this.client.stopChat()
            } else {
                this.client.startChat()
            }

            this.isChat = !this.isChat
        }

        if (this.isChat && down) {
            this.client.captureKeystroke(key)
        }

        if (this.isKeyOn("Escape") && this.isChat) {
            this.client.stopChat()
            this.isChat = false
        }

        if (this.isChat) {
            return
        }

        if (this.paralyzed) {
            entity.movement.moving = false
            entity.speed2.x = 0
            entity.speed2.y = 0
            return
        }

        if (Date.now() - this.lastMouseMoveTs > 150) {
            this.keyboardBasedAngle(entity)
        }

        const isMoving =
            this.isKeyOn("w") ||
            this.isKeyOn("ArrowUp") ||
            this.isKeyOn("s") ||
            this.isKeyOn("ArrowDown") ||
            this.isKeyOn("a") ||
            this.isKeyOn("ArrowLeft") ||
            this.isKeyOn("d") ||
            this.isKeyOn("ArrowRight")

        let manualControl = isMoving
        if (isMoving) {
            clearInterval(this.autoMovingInterval)
            this.setAutoMoving(false)
        }

        if (!this.isAutoMoving) {
            entity.speed2.x = 0
            entity.speed2.y = 0
        }

        if (manualControl) {
            const wasMoving = entity.movement.moving

            entity.movement.moving = isMoving

            if (!wasMoving && entity.movement.moving) {
                // started moving!
                if (this.movementDaemon !== undefined) {
                    clearInterval(this.movementDaemon)
                }
                this.movementDaemon = setInterval(() => {
                    if (Date.now() - this.lastMouseMoveTs > 150) {
                        this.keyboardBasedAngle(entity)
                    }
                }, 150)
            }
            if (wasMoving && !entity.movement.moving) {
                if (this.movementDaemon !== undefined) {
                    clearInterval(this.movementDaemon)
                }
            }

            if (!entity.afk) {
                if (this.isKeyOn("w") || this.isKeyOn("ArrowUp")) {
                    entity.speed2.y--
                }
                if (this.isKeyOn("s") || this.isKeyOn("ArrowDown")) {
                    entity.speed2.y++
                }
                if (this.isKeyOn("a") || this.isKeyOn("ArrowLeft")) {
                    entity.speed2.x--
                }
                if (this.isKeyOn("d") || this.isKeyOn("ArrowRight")) {
                    entity.speed2.x++
                }
            }
        }

        if (this.isKeyOn("e")) {
            if (this.terrainPointerOn) {
                this.client.clientGameLogic.activateWorldPoint(this.pointer, {
                    delete: true,
                })
            }
        }

        if (this.isKeyOn("p")) {
            this.updateTerrainPointerOn(!this.terrainPointerOn)
        }

        if (this.isKeyOn("u")) {
            this.client.spawnNpc()
        }

        if (this.isKeyOn("x")) {
            this.client.dropAction()
        }

        if (this.isKeyOn("y")) {
            this.client.itemPickupAction()
        }

        if (this.isKeyOn("b")) {
            this.activeAbility("b")
        }

        if (this.isKeyOn(" ")) {
            this.manualAttack(isMoving)
        }

        if (this.isKeyOn("q")) {
            this.client.dropActiveItem()
        }

        if (this.isKeyOn("]")) {
            this.client.activateAbility("e")
        }

        if (this.isKeyOn("1")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(1)
        }

        if (this.isKeyOn("2")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(2)
        }

        if (this.isKeyOn("3")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(3)
        }

        if (this.isKeyOn("4")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(4)
        }

        if (this.isKeyOn("5")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(5)
        }

        if (this.isKeyOn("6")) {
            this.client.clientGameLogic.clientRenderer.gui().activateInventorySlot(6)
        }

        if (this.isKeyOn("j")) {
            this.client.emitPlayerEvent({
                playerEventType: "SQUAD_COMMAND",
                context: {
                    command: "come",
                },
            })
        }

        if (this.isKeyOn("h")) {
            this.client.emitPlayerEvent({
                playerEventType: "SQUAD_COMMAND",
                context: {
                    command: "stopStart",
                },
            })
        }

        if (this.isKeyOn("k")) {
            this.client.emitPlayerEvent({
                playerEventType: "SQUAD_COMMAND",
                context: {
                    command: "formation",
                },
            })
        }

        if (this.isKeyOn("l")) {
            this.client.emitPlayerEvent({
                playerEventType: "SQUAD_COMMAND",
                context: {
                    command: "updateStance",
                },
            })
        }

        if (key === "Shift") {
            if (down) {
                this.client.boost(true)
            } else {
                this.client.boost(false)
            }
        }

        if (this.isKeyOn("~")) {
            if (this.randomness) {
                clearInterval(this.randomness)
                this.randomness = null
            } else {
                const latch = new Latch(500)
                this.randomness = setInterval(() => {
                    if (latch.expired()) {
                        const w = randomIntBetween(0, 20)
                        this.client.fireBullet()
                    }
                    const r = randomIntBetween(0, 1)
                    entity.movement.moving = true
                    entity.speed2.x = r * (randomIntBetween(0, 1) === 1 ? -1 : 1)
                    entity.speed2.y = r * (randomIntBetween(0, 1) === 1 ? -1 : 1)
                }, 100)
            }
        }

        if (this.isKeyOn("i")) {
            this.client.clientGameLogic.clientRenderer.gui().toggleInventory()
            // this.client.clientGameLogic.invokeInventory()
        }

        if (this.isKeyOn("c")) {
            this.client.clientGameLogic.invokeCrafting()
        }

        if (this.isKeyOn("t")) {
            if (entity.debug || entity.name === "aron") {
                this.client.clientGameLogic.clientRenderer.gui().toggleCharacterCustomizer()
            }
            // this.client.clientGameLogic.clientRenderer.gui().showTutorial("hotbar")
        }
    }

    perturb = () => {
        const { entity } = this
        entity.speed2.x = 0.001
        setTimeout(() => {
            entity.speed2.x = 0
        }, 10)
    }

    boostedAttack = () => {
        this.client.boost(true)
        setTimeout(() => {
            this.client.boost(false)
        }, 750)
        this.client.fireBullet()
    }

    private respondToMouse = (mouseState: MouseState): void => {
        if (!this.mouseActionEnabled) {
            return
        }
        this.lastMouseMoveTs = Date.now()
        if (!isMobile()) {
            if (!this.pointer && this.client.clientGameLogic.tilemapMetaProvider) {
                const pointer = {
                    x: 64,
                    y: 64,
                }
                this.pointer = pointer
            }
            if (!this.roughPointer && this.client.clientGameLogic.tilemapMetaProvider) {
                const pointer = {
                    x: 0,
                    y: 0,
                }
                this.roughPointer = pointer
            }
            this.client.clientGameLogic.clientRenderer.entity()
            const X_FACTOR = ScalingParams.viewportX
            const Y_FACTOR = ScalingParams.viewportY - 1
            const entity = this.client.clientGameLogic.playerEntity
            const tilemapMetaProvider = this.client.clientGameLogic.tilemapMetaProvider
            if (tilemapMetaProvider) {
                const location = entity.location
                const rightThreshold = (tilemapMetaProvider.width() - X_FACTOR) * TileSize
                const bottomThreshold = (tilemapMetaProvider.height() - Y_FACTOR) * TileSize

                const isOverX = location.x > TileSize * X_FACTOR && location.x > rightThreshold
                const isOverY = location.y > TileSize * Y_FACTOR && location.y > bottomThreshold

                if (this.pointer) {
                    const renderer: MapRenderingManager =
                        this.client.clientGameLogic.clientRenderer.map() as MapRenderingManager
                    const { modifierX, modifierY } = renderer.translationComputer
                    const factor = 0.5
                    const yfactor = 0.38
                    this.pointer = {
                        x:
                            Math.round(mouseState.position.x * factor) +
                            Math.round((modifierX !== undefined ? -modifierX : 0) * 1) -
                            (modifierX !== undefined
                                ? Math.round(TileSize * X_FACTOR * factor)
                                : isOverX
                                ? -rightThreshold + TileSize * X_FACTOR
                                : 0) -
                            (modifierX !== undefined ? 40 : -24),
                        y:
                            Math.round(mouseState.position.y * yfactor) +
                            Math.round((modifierY !== undefined ? -modifierY : 0) * 1) -
                            (modifierY !== undefined
                                ? Math.round(TileSize * Y_FACTOR * yfactor)
                                : isOverY
                                ? -bottomThreshold + TileSize * Y_FACTOR
                                : 0) -
                            (modifierY !== undefined ? 0 : -30),
                    }
                    this.pointer.y += -10
                    const newRoughPointerX = Math.floor(this.pointer.x / TileSize)
                    const newRoughPointerY = Math.floor(this.pointer.y / TileSize)
                    if (this.roughPointer.x !== newRoughPointerX || this.roughPointer.y !== newRoughPointerY) {
                        this.roughPointer.x = newRoughPointerX
                        this.roughPointer.y = newRoughPointerY
                        this.updatedRoughPointer(this.roughPointer)
                    }

                    this.entity.movement.angle = getAngle(this.pointer, entity.location)
                    this.targetKeyboardAngle = this.entity.movement.angle

                    if (this.longRangedEquipped || this.terrainPointerOn) {
                        const mouseSprite = this.client.clientGameLogic.clientRenderer.gui().createCursorSprite()
                        mouseSprite.visible = true
                        mouseSprite.alpha = 1.0
                        if (this.mousePointerFadeInterval) {
                            clearInterval(this.mousePointerFadeInterval)
                        }
                        if (this.mousePointerFader) {
                            this.mousePointerFader.stop(true)
                            this.mousePointerFader = null
                        }
                        this.mousePointerFadeInterval = setTimeout(() => {
                            this.mousePointerFader = new Fader(mouseSprite, {
                                releaseCallback: () => {
                                    mouseSprite.visible = false
                                },
                            })
                            this.mousePointerFader.update()
                        }, 500)
                    }
                }
            }

            clearInterval(this.keyboardAndleAdjustInterval)
        }

        if (this.isButtonOn("0")) {
            // button pressed
            if (!this.isFiring) {
                this.isFiring = true

                if (this.terrainPointerOn) {
                    this.client.commitWorldEdits(this.roughPointer)
                    this.clearTerrainPointer()

                    this.fireInterval = setInterval(() => {
                        this.client.commitWorldEdits(this.roughPointer)
                    }, 400)

                    this.lastMouseMoveTs = Date.now()
                    return
                }

                const hoveredEntity = this.client.clientGameLogic.getEntity(this.hoveredEntity)
                let isAutoTravelling = false

                const entityTilePointer = !emptyCoordinate(this.roughCompositeTilePointer)
                    ? this.roughCompositeTilePointer
                    : this.roughPointer

                const entityTile = this.client.clientGameLogic.tilemapMetaProvider.getEntityTileAt(
                    entityTilePointer.x,
                    entityTilePointer.y,
                )
                const entityTileAccessible =
                    entityTile &&
                    getCoordinateDistance(this.entity.location, roughToFineCoordinates(entityTilePointer)) < TileSize

                if (!entityTileAccessible && !this.longRangedEquipped) {
                    if (hoveredEntity) {
                        isAutoTravelling = this.scheduleAutoTravelToEntity(hoveredEntity, () => {
                            this.client.fireBullet()
                        })
                    } else {
                        const collisionCheck = calculateNewCoordinateFromRadians(
                            this.entity.location,
                            this.entity.movement.angle,
                            TileSize,
                        )
                        if (
                            this.client.clientGameLogic.tilemapMetaProvider.isCollisionAt(
                                fineToRough(collisionCheck.x),
                                fineToRough(collisionCheck.y),
                            )
                        ) {
                            isAutoTravelling = false
                        } else {
                            const thePoint = calculatePointAtDistance(
                                this.entity.location,
                                this.pointer,
                                TileSize * 1.5,
                            )
                            isAutoTravelling = this.scheduleAutoTravelToPoint(
                                thePoint,
                                () => {
                                    this.client.fireBullet()
                                },
                                false,
                            )
                        }
                    }
                }

                if (!isAutoTravelling) {
                    if (entityTileAccessible && !hoveredEntity) {
                        const doStrike = () => {
                            const s = { ...this.roughCompositeTilePointer }
                            this.client.clientGameLogic.attack(this.entity.entityId, () => {
                                this.client.entityTileStrike(s)
                            })
                        }

                        doStrike()
                        this.fireInterval = setInterval(() => {
                            doStrike()
                        }, 400)
                    } else {
                        this.boostedAttack()

                        this.fireInterval = setInterval(() => {
                            this.boostedAttack()
                        }, 400)
                    }
                }

                this.lastMouseMoveTs = Date.now()
            }
        } else if (this.isFiring) {
            // button unpressed
            mouseState.buttons.set("0", false)
            this.isFiring = false
            clearInterval(this.fireInterval)
        }
    }

    updatedRoughPointer = (coordinate: Coordinate) => {
        if (this.terrainPointerOn) {
            this.client.getBuildingTilePreview(coordinate)
        }
    }

    clearTerrainPointer = () => {
        this.terrainPointerSprites.forEach(sprite => sprite.parent?.removeChild(sprite))
        this.terrainPointerSprites.clear()
    }

    updateTerrainPointer = (deltas: MapEntry[]) => {
        this.clearTerrainPointer()

        if (deltas?.length < 1) {
            return
        }

        deltas.forEach((delta: MapEntry) => {
            if (delta.layerId === "blocking" || delta.layerId === "skyTrigger") {
                return
            }
            const result = this.client.clientGameLogic.clientRenderer
                .map()
                .addTileAt(delta.x, delta.y, String(delta.tileSpec.tilePos), delta.layerId)
            if (result.sprite) {
                this.terrainPointerSprites.add(result.sprite)
                result.sprite.alpha = 0.5
                if (delta?.context?.highZ) {
                    result.sprite.zIndex = 100000
                }
            }
        })
    }

    enabledMouseAction = (enabled: boolean) => {
        this.mouseActionEnabled = enabled
    }

    setHoveredEntity = (entityId: EntityId) => {
        this.hoveredEntity = entityId
    }

    unHoveredEntity = (entityId: EntityId) => {
        this.hoveredEntity = undefined
    }

    isMouseActionEnabled = () => this.mouseActionEnabled

    enabledKeyboardAction = (enabled: boolean) => {
        this.keyboardActionEnabled = enabled
    }

    paralyze = (value: boolean, timeout: number = 1000) => {
        this.paralyzed = value
        if (value) {
            setTimeout(() => {
                this.paralyzed = false
            }, timeout)
        }
    }

    fireBullet = () => {
        this.client.fireBullet()
    }

    selectSlot = (itemId: string) => {
        this.client.clientGameLogic.selectSlot(itemId)
    }

    activeAbility = (shortcutKey: string) => {
        this.client.activateAbility(shortcutKey)
    }

    isGuest = () => this.guest

    setIsGuest = (guest: boolean) => {
        this.guest = guest
    }

    updateLongRangedEquippedState = (equipped: boolean) => {
        if (this.longRangedEquipped !== equipped) {
            this.longRangedEquipped = equipped

            const mouseSprite = this.client.clientGameLogic.clientRenderer.gui().createCursorSprite()
            if (!equipped && mouseSprite) {
                mouseSprite.visible = false
            }
        }
    }

    updateTerrainPointerOn = (on: boolean) => {
        this.terrainPointerOn = on
        if (!this.terrainPointerOn) {
            this.clearTerrainPointer()
        }
    }

    builderActivation = (on: boolean) => {
        this.updateTerrainPointerOn(on)
        if (on) {
            const pointer =
                !this.roughPointer || this.roughPointer.x < 1 || this.roughPointer.y < 1
                    ? fineToRoughCoordinates(this.entity.location)
                    : this.roughPointer
            this.client.getBuildingTilePreview(pointer)
        }
    }

    manualAttack = (isMoving: boolean) => {
        const useMousePointer = Date.now() - this.lastMouseMoveTs < 150
        const collisionCheck = calculateNewCoordinateFromRadians(
            this.entity.location,
            this.entity.movement.angle,
            TileSize,
        )

        if (
            this.client.clientGameLogic.tilemapMetaProvider.isCollisionAt(
                fineToRough(collisionCheck.x),
                fineToRough(collisionCheck.y),
            )
        ) {
            this.client.fireBullet()
            return
        }

        if (isMoving) {
            this.boostedAttack()
            return
        }

        const distance = this.longRangedEquipped ? 0 : TileSize * 1.5
        const thePoint = useMousePointer
            ? calculatePointAtDistance(this.entity.location, this.pointer, distance)
            : calculateNewCoordinateFromRadians(this.entity.location, this.entity.movement.angle, distance)

        this.scheduleAutoTravelToPoint(
            thePoint,
            () => {
                this.client.fireBullet()
            },
            false,
        )
    }

    setAutoMoving = (moving: boolean) => {
        this.isAutoMoving = moving
    }

    scheduleAutoTravelToEntity = (entity: Entity, travelCallback: Callback<void>) => {
        if (!entity) {
            return false
        }

        if (this.autoMovingInterval) {
            clearInterval(this.autoMovingInterval)
        }
        const { entity: playerEntity } = this

        const distance = getEntityDistance(playerEntity, entity)
        const tooClose = distance < 24
        const tooFar = distance > TileSize * 2

        if (!tooClose && !tooFar) {
            // console.log("distance", distance)
            this.autoTravelToEntity(entity, travelCallback)
            return true
        }

        return false
    }

    scheduleAutoTravelToPoint = (point: Coordinate, travelCallback: Callback<void>, restricted: boolean = true) => {
        if (!point) {
            return
        }

        if (this.autoMovingInterval) {
            clearInterval(this.autoMovingInterval)
        }
        const { entity: playerEntity } = this

        const distance = getDistance(point.x, point.y, playerEntity.location.x, playerEntity.location.y)
        const tooClose = distance < 24
        const tooFar = distance > TileSize * 2

        if (!restricted || (!tooClose && !tooFar)) {
            // console.log("distance", distance)
            this.autoTravelTo(point, travelCallback)
            return true
        }

        return false
    }

    autoTravelToEntity = (entity: Entity, arriveCallback?: Callback<void>) => {
        const targetCoordinate: Coordinate = floorCoordinates(entity.location)
        return this.autoTravelTo(targetCoordinate, arriveCallback)
    }

    autoTravelTo = (targetCoordinate: Coordinate, arriveCallback?: Callback<void>) => {
        const { entity: playerEntity } = this
        this.setAutoMoving(true)
        this.client.boost(true)

        arriveCallback()

        let startTs = Date.now()
        this.autoMovingInterval = setInterval(() => {
            const distance = Math.floor(getCoordinateDistance(playerEntity.location, targetCoordinate))
            const tooClose = distance < 24
            const tooFar = distance > TileSize * 2

            const isThere = isEqual(targetCoordinate, playerEntity.location)
            if (tooClose || isThere || tooFar) {
                // console.log("distance done", distance, "tooClose", tooClose, "tooFar", tooFar, "isThere", isThere)
                clearInterval(this.autoMovingInterval)
                this.setAutoMoving(false)

                playerEntity.speed2.x = 0
                playerEntity.speed2.y = 0
                this.client.boost(false)

                return
            }

            if (Date.now() - startTs < 200) {
                return
            }

            playerEntity.speed2.x = 0
            playerEntity.speed2.y = 0

            if (Math.floor(playerEntity.location.x) > targetCoordinate.x) {
                playerEntity.speed2.x = -1
            } else if (Math.floor(playerEntity.location.x) < targetCoordinate.x) {
                playerEntity.speed2.x = 1
            }

            if (Math.floor(playerEntity.location.y) > targetCoordinate.y) {
                playerEntity.speed2.y = -1
            } else if (Math.floor(playerEntity.location.y) < targetCoordinate.y) {
                playerEntity.speed2.y = 1
            }
        }, 5)
    }

    updateCompositeTile = (x: number, y: number) => {
        const entityTile = this.client.clientGameLogic.tilemapMetaProvider.getEntityTileAt(
            this.roughPointer.x,
            this.roughPointer.y,
        )
        if (entityTile && x > -1 && y > -1) {
            this.roughCompositeTilePointer.x = x
            this.roughCompositeTilePointer.y = y
            console.log(this.roughCompositeTilePointer, entityTile)
        } else {
            clearCoordinate(this.roughCompositeTilePointer)
        }
    }
}
