import { Callback } from "game-common/util"
import { Graphics } from "pixi.js"
import { GuiManager } from "../gui_manager"
import { Modal } from "./modal"

export interface FullScreenModalProps {
    padding?: number
    opaque?: boolean
    unescapable?: boolean
}

export abstract class FullScreenModal {
    protected guiManager: GuiManager
    private show: boolean
    modal: Modal
    private surface: Graphics
    private padding: number
    onClose: Callback<boolean>
    private opaque: boolean
    private unescapable: boolean

    constructor(guiManager: GuiManager, props?: FullScreenModalProps) {
        this.guiManager = guiManager
        this.show = false
        this.surface = new Graphics()
        this.padding = props?.padding || 10
        this.opaque = props?.opaque || false
        this.unescapable = props?.unescapable || false
    }

    abstract createContent(): Graphics

    isOpen = () => this.show
    update = () => {
        this.surface.removeChildren()
        this.surface.addChild(this.createContent())
    }

    width = () => this.modal?.width
    height = () => this.modal?.height

    close = () => {
        this.show = !this.show
        if (!this.show && this.modal) {
            this.modal.close()
        }
    }

    toggle = (onClose?: Callback<boolean>) => {
        this.show = !this.show
        if (!this.show && this.modal) {
            this.modal.close()
        }

        if (this.show) {
            this.modal = this.guiManager.createModal({
                padding: this.padding,
                content: this.surface,
                fullScreen: true,
                backgroundAlpha: !this.opaque ? 0.91 : 1.0,
                plain: true,
                unescapeable: this.unescapable,
                closeCallback: (clicked: boolean) => {
                    this.show = false
                    if (this.onClose) {
                        this.onClose(clicked)
                    } else if (onClose) {
                        onClose(clicked)
                    }
                },
            })

            this.update()
        }
    }
}
