import { ItemTypeMeta } from "game-common/item/item"
import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { AnimatedFeedbackParams, Entity } from "game-common/models"
import { Callback } from "game-common/util"
import { Container, Graphics } from "pixi.js"

import { WorldOverlayItem } from "../world_overlay_surface"

export class BaseRenderable extends Graphics {
    parentArea: BaseRenderable
    active: boolean
    defaultZIndex: number = 0
    sideCar: BaseRenderable

    doMovement(entity?: Entity) {
        //
    }

    update(hp: number, hpMax: number) {
        //
    }

    updateName(name: string) {
        //
    }

    updateSubmerged(submerged: boolean) {
        //
    }

    setId(id: string) {
        //
    }

    recolor(color: number) {
        //
    }

    updateBlinking(blinking: boolean) {
        //
    }

    updateScale(scale: number) {
        //
    }

    addTag() {
        //
    }

    removeTag() {
        //
    }

    updateSigil(sigil?: string) {
        //
    }

    remove() {
        this.parent?.removeChild(this)
    }

    detonate() {
        //
    }

    updateLevel(level: number) {
        //
    }

    doAttack(entity: Entity, callback?: Callback<void>) {
        //
    }

    updateActivation(entity: Entity, active: boolean, callback?: Callback<void>) {
        //
    }

    doDamaged(entity: Entity) {
        //
    }

    spoke(message: string) {
        //
    }

    getDrawableSurface(): Container {
        return null
        //
    }

    updatePaperdoll(entity: Entity) {
        //
    }

    updateHalo(entity: Entity, enabled: boolean) {
        //
    }

    updateSpeed(factor: number) {
        //
    }

    fragment(entity: Entity, callback?: Callback<void>) {
        //
    }

    updateRotating(rotating: boolean) {
        //
    }

    onMouseOver(show: boolean) {
        //
    }

    fadeIn() {
        this.alpha = 0
        const t = setInterval(() => {
            this.alpha += 0.1
            if (this.alpha >= 1.0) {
                this.alpha = 1.0
                clearInterval(t)
            }
        }, 25)
    }

    fadeOut(callback?: Callback<void>) {
        const t = setInterval(() => {
            this.alpha -= 0.1
            if (this.alpha <= 0.0) {
                this.alpha = 0
                clearInterval(t)
                if (callback) {
                    callback()
                }
            }
        }, 25)
    }

    getClickSurface(): BaseRenderable {
        return this
    }

    setWorldOverlayItem(item: WorldOverlayItem) {
        //
    }

    updateInteractions(interactions: InteractionType[]) {
        //
    }

    updatePointerIcon(show: boolean, angle?: number) {
        //
    }

    itemPickedUp(entity: Entity, itemTypeMeta: ItemTypeMeta, quantity: number) {
        //
    }

    feedback(entity: Entity, image: string, label: string, params?: AnimatedFeedbackParams) {
        //
    }

    updateSpriteId(spriteId: string) {
        //
    }

    updateAfk(afk: boolean) {
        //
    }

    addSidecar(entity: Entity) {
        if (entity.debug) {
            let g = this.sideCar
            if (!g) {
                g = new BaseRenderable()
                this.parent.addChild(g)
                this.sideCar = g
            }

            g.clear()
            g.beginFill(0xff0000)
            g.alpha = 0.6
            const bounds = entity.getCollisionBounds()
            // console.log(bounds.x1, bounds.y1, bounds.width, bounds.height)
            g.drawRect(bounds.x1, bounds.y1, bounds.width, bounds.height)
            g.endFill()
        }
    }

    getYOffset() {
        return -this.height * 0.25
    }

    updateRenderedLocationFrom(entity: Entity) {
        this.x = entity.location.x
        this.y = entity.location.y + this.getYOffset()

        this.addSidecar(entity)
    }

    getCurrentColor = () => this.tint
}
