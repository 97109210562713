import { locateInventoryActiveItem } from "game-common/item/item"
import { DynamicTextMeta, Entity, EntityId, ScreenMessage } from "game-common/models"
import { Callback, chunkSentence } from "game-common/util"
import { Graphics } from "pixi.js"

import { ClientGameLogic } from "../../../../client_game_logic"
import { GuiManager } from "../gui_manager"
import { DynamicText } from "./dynamic_text"
import { DyanmicTextContainer, DyanmicTextContainerProps } from "./dynamic_text_container"
import { EntityDisplayer } from "./entity_displayer"
import { FullScreenModal } from "./fullscreen_modal"
import { Grid, ItemDetail } from "./grid"
import { Throbber } from "./throbber"
import { Fader } from "./fader"

export class HoverText extends Graphics {
    surface: Graphics
    fader: Fader

    constructor() {
        super()
        this.surface = new Graphics()
        this.addChild(this.surface)
        this.interactive = true

        this.on("mouseover", () => {
            if (this.fader) {
                this.fader.stop()
                this.visible = true
                this.alpha = 1.0
            }
        })
        this.on("mouseout", () => {
            this.fader = new Fader(this, {
                fadeInterval: 1,
                fadeAlphaDecrement: 0.03,
                releaseCallback: () => {
                    this.alpha = 1.0
                    this.visible = false
                },
            })
            this.fader.update()
        })
    }

    update = (texts: string[]) => {
        if (texts.length < 1) {
            return
        }
        this.surface.removeChildren()
        this.clear()

        const main = new DyanmicTextContainer()
        this.surface.addChild(main)

        texts.forEach((text, i) => {
            main.append2(new DynamicText(text), {
                topMargin: i === 0 ? 5 : 0,
                leftMargin: 5,
                bottomMargin: 2,
            })
        })
        this.beginFill(0x808080)
        this.drawRoundedRect(0, 0, main.width + 10, main.height + 10, 5)
        this.endFill()
    }
}

export class DynamicDialog extends FullScreenModal {
    logic: ClientGameLogic
    message: ScreenMessage
    grids: Grid[] = []
    throbbers: Throbber[] = []

    constructor(guiManager: GuiManager, logic: ClientGameLogic, message: ScreenMessage, onClose?: Callback<void>) {
        super(guiManager)
        this.logic = logic
        this.message = message

        if (onClose) {
            this.onClose = () => {
                if (onClose) {
                    onClose()
                }
            }
        }
    }

    updateContent = (message: ScreenMessage) => {
        this.grids = []
        this.message = message
        // this.logic.enablePlayerMouseAction(false)
        this.update()
    }

    createContent = () => {
        const { message } = this
        return DynamicDialog.createContent(this.logic, message, this.width(), this.grids)
    }

    static createContent = (
        logic: ClientGameLogic,
        message: ScreenMessage,
        width: number,
        grids: Grid[],
        props?: DyanmicTextContainerProps,
    ): Graphics => {
        const progressive = message.progressive
        const content = new Graphics()

        const { dynamicText = [], columnCount = 1 } = message

        const columns: Map<number, DyanmicTextContainer> = new Map()
        const columnWidth = width / columnCount
        const detailContainer = new DyanmicTextContainer()
        const itemDetail = new ItemDetail()
        const hoverText = new HoverText()
        detailContainer.addChild(itemDetail)
        detailContainer.addChild(hoverText)

        for (let i = 1; i <= columnCount; i++) {
            const columnSurface = new DyanmicTextContainer({
                linkCallback: logic.log,
                progressive,
                hoverText,
                ...(props || {}),
            })
            columnSurface.x = (i - 1) * columnWidth
            content.addChild(columnSurface)
            columns.set(i, columnSurface)
        }

        const processText = (text: DynamicTextMeta, targetContainer: DyanmicTextContainer) => {
            if (text.type === "entity") {
                const { entityId, scale, appearance, inventory } = text.context

                const entity = new Entity()
                entity.weapon = { weaponType: locateInventoryActiveItem(inventory, "weapon")?.type as any }
                entity.appearance = appearance
                entity.inventory = inventory

                const entityDisplayer = new EntityDisplayer(
                    {
                        entityId,
                        scale,
                    },
                    entity,
                )

                const c = entityDisplayer.createContent()
                targetContainer.append2(c, {
                    sameLine: text.sameLine,
                    leftSpacer: text.leftSpacer !== undefined ? text.leftSpacer : 0,
                    leftMargin: text.leftMargin !== undefined ? text.leftMargin : 15,
                    topMargin: text.topMargin,
                    exactLocation: text.exactLocation,
                    hoverText: text.hoverText,
                })
            }

            if (text.type === "grid") {
                const { gridMeta: meta, rows, columns } = text.context

                const grid = new Grid({
                    itemMeta: meta,
                    optionSelectionHandler: option => {
                        logic.playerController.client.emitPlayerEvent({
                            ...option.onClickContext,
                        })
                    },
                    rows,
                    columns,
                })
                grid.foofoo = targetContainer
                grids.push(grid)

                targetContainer.append2(grid.createContent(itemDetail), {
                    sameLine: text.sameLine,
                    leftSpacer: text.leftSpacer !== undefined ? text.leftSpacer : 0,
                    leftMargin: text.leftMargin !== undefined ? text.leftMargin : 0,
                    topMargin: text.topMargin,
                    exactLocation: text.exactLocation,
                    hoverText: text.hoverText,
                })
            } else if (text.type === "horizontalLine") {
                targetContainer.append2(new DynamicText("", { type: "horizontalLine" }), {
                    topMargin: text.topMargin,
                    bottomMargin: text.bottomMargin,
                    leftMargin: text.leftMargin,
                    hoverText: text.hoverText,
                })
            } else if (text.type === "container") {
                const containerSurface = new DyanmicTextContainer({
                    linkCallback: logic.log,
                    progressive,
                    hoverText,
                })

                text.children.forEach(child => {
                    processText(child, containerSurface)
                })

                targetContainer.append2(containerSurface, {
                    sameLine: text.sameLine,
                    sameX: text.sameX,
                    leftSpacer: text.leftSpacer !== undefined ? text.leftSpacer : 0,
                    leftMargin: text.leftMargin !== undefined ? text.leftMargin : 15,
                    topMargin: text.topMargin,
                    exactLocation: text.exactLocation,
                    hoverText: text.hoverText,
                })
            } else {
                const texts = chunkSentence(text.text, 95)
                texts.forEach(nextText => {
                    targetContainer.append2(nextText, {
                        sameLine: text.sameLine,
                        sameX: text.sameX,
                        leftSpacer: text.leftSpacer !== undefined ? text.leftSpacer : 0,
                        leftMargin: text.leftMargin !== undefined ? text.leftMargin : 15,
                        pause: text.pause,
                        type: text.type,
                        textDecorations: text.textDecorations,
                        onClick: text.clickEventEmitter
                            ? () => {
                                  logic.playerController.client.emitPlayerEvent(text.clickEventEmitter)
                              }
                            : undefined,
                        mouseControlCallback: (on: boolean) => {
                            const wasEnabled = logic.playerController.isMouseActionEnabled()
                            const enableRequested = !on
                            if (enableRequested) {
                                // turn on player mouse control
                                if (wasEnabled) {
                                    // mouse control was allowed, so turn it on
                                    logic.playerController.enabledMouseAction(true)
                                } else {
                                    // it wasn't allowed prior, so don't turn it on
                                }
                            } else {
                                // turn off player mouse control
                                logic.playerController.enabledMouseAction(enableRequested)
                            }
                        },
                        spriteId: text.spriteId,
                        scale: text.scale,
                        context: text.context,
                        exactLocation: text.exactLocation,
                        hoverText: text.hoverText,
                        font: text.font,
                        color: text.color,
                    })
                })
            }
        }

        dynamicText
            .map(next =>
                typeof next === "string"
                    ? {
                          text: next,
                      }
                    : next,
            )
            .forEach(text => {
                const { column = 1 } = text
                const textBlock = columns.get(column)
                processText(text, textBlock)
            })

        content.addChild(detailContainer)

        if (progressive) {
            for (let i = 1; i <= columnCount; i++) {
                columns.get(i).playback()
            }
        }

        return content
    }

    pulseGridItem = (itemId: EntityId) => {
        const slots: Graphics[] = this.grids
            .map(grid => grid.itemMap.get(itemId))
            .filter(items => !!items)
            .flat()
        slots.forEach(slot => {
            const newIndicator = new DynamicText("New!", {
                font: "RedChatFont",
            })
            newIndicator.x = slot.width / 2 - newIndicator.width / 2
            newIndicator.y = 2
            slot.addChild(newIndicator)
            const throbber: Throbber = new Throbber(slot, {
                expires: 10000,
                blinkInterval: 50,
                releaseCallback: () => {
                    slot.removeChild(newIndicator)
                },
            })
            throbber.update()
        })
    }
}
