import { Graphics, BitmapText, Text } from "pixi.js"
import { TextRenderer } from "../text/text_renderer"

export class ChatHistory extends Graphics {
    chatHistoryText: Text
    pos: number
    bannerText: Text
    chatSurface: Graphics

    constructor(pos: number) {
        super()

        this.pos = pos
        this.chatSurface = new Graphics()
        this.chatSurface.x = 5
        this.chatSurface.y = 5
        this.addChild(this.chatSurface)

        const chatHistoryText = new TextRenderer().size("tiny").render() as Text

        this.chatSurface.addChild(chatHistoryText)
        chatHistoryText.text = ""

        this.chatHistoryText = chatHistoryText
    }

    hide = () => {
        this.visible = false
    }

    show = () => {
        this.visible = true
    }

    updateMessages = (chatMessages: string[]) => {
        const messagesToUse = chatMessages.filter(n => !!n)
        this.clear()
        this.beginFill(0x000000, 0.5)
        this.drawRoundedRect(0, 0, 255, messagesToUse.length * 12 + 12, 5)
        this.endFill()

        this.chatSurface.clear()
        this.chatSurface.addChild(this.chatHistoryText)
        this.chatHistoryText.text = messagesToUse.join("\n")
        this.y = this.pos - this.height
    }
}
