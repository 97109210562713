import { Container, Graphics, Text } from "pixi.js"
import { IntroBackground } from "./intro_background"
import { BOTTOM, GuiManager } from "../gui_manager"
import { ProjectLogo } from "./project_logo"
import { DyanmicTextContainer } from "../gui/dynamic_text_container"
import { Fader } from "../gui/fader"
import { randomIntBetween } from "game-common/models"
import TextInput from "pixi-text-input"
import { TextRenderer } from "../text/text_renderer"
import { BasicSprite } from "../sprites/basic_sprite"

type LoginCallback = (username?: string, password?: string) => void

export class Intro extends Graphics {
    guiManager: GuiManager
    loggingIn: boolean
    landingContainer: Graphics
    container: Graphics
    socials: Graphics

    constructor(guiManager: GuiManager) {
        super()
        this.guiManager = guiManager
    }

    formRemover = () => {
        this.container?.children?.forEach((child: Graphics) => {
            child.removeChildren()
            this.container.removeChild(child)
        })
        this.container?.removeChildren()
        this.container?.parent?.removeChild(this.container)
    }

    guestLandingRemover = () => {
        this.formRemover()

        this.landingContainer?.removeChildren()
        this.landingContainer?.parent?.removeChild(this.landingContainer)

        this.socials?.removeChildren()
        this.socials?.parent?.removeChild(this.socials)
    }

    loginSuccess = () => {
        this.loggingIn = false
        this.guestLandingRemover()
    }

    loginFailed = () => {
        this.loggingIn = false
        this.container.visible = true
    }

    createInput = (fieldWidth: number, placeHolder: string, type: "password" | "plain" = "plain") => {
        const nameField = new TextInput({
            input: {
                fontFamily: "Arial",
                fontSize: "10pt",
                lineHeight: "13px",
                padding: "10px 12px",
                color: "#000000",
                multiline: false,
                width: `${fieldWidth}px`,
            },
            box: {
                default: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xcbcee0, width: 2 } },
                focused: { fill: 0xe1e3ee, rounded: 10, stroke: { color: 0xabafc6, width: 2 } },
                disabled: { fill: 0xdbdbdb, rounded: 10 },
            },
        })

        nameField.placeholder = placeHolder
        nameField.placeholderColor = 0x000000

        if (type === "password") {
            nameField._dom_input.type = "password"
        }

        return nameField
    }

    buildLoginContainer = (
        intro: Graphics,
        doneCallback: LoginCallback,
    ): { container: Graphics; nameField: TextInput } => {
        const container = new Graphics()

        container.x = 0
        container.y = 0

        const formContainer = new DyanmicTextContainer()
        container.addChild(formContainer)
        formContainer.y = intro.y
        formContainer.x = intro.x

        const guestId = "Guest " + randomIntBetween(1, 10000)
        const placeHolder = "Choose a player name, or press ENTER for guest."
        const fieldWidth = intro.width
        const nameField = this.createInput(fieldWidth, placeHolder)

        formContainer.append2(nameField)

        // nameField.focus()

        let currentName = guestId
        nameField.on("input", text => {
            currentName = text
        })
        nameField.on("keyup", keycode => {
            if (keycode === 13) {
                container.visible = false

                setTimeout(() => {
                    doneCallback(currentName)
                }, 250)
            }
        })

        const loginHandler = () => {
            // formRemover()
            container.visible = false

            this.collectPlayerLogin(intro, (username: string, password: string) => {
                if (!username || !password) {
                    // askForName()
                    container.visible = true
                    nameField.focus()
                    // this.renderGuestLanding(currentName, doneCallback)
                } else {
                    // guestLandingRemover()
                    this.loggingIn = true
                    doneCallback(username, password)
                }
            })
        }

        const loginContainer = new DyanmicTextContainer()
        formContainer.append2(loginContainer)
        loginContainer.y = nameField.y + nameField.height + 10
        loginContainer.interactive = true
        loginContainer.on("click", loginHandler)
        loginContainer.on("tap", loginHandler)

        const login = new TextRenderer().variant("BannerFont").render() as Text
        login.text = "Login"
        login.tint = 0x00ff00
        loginContainer.append2(login as any)

        const login2 = new TextRenderer().variant("BannerFont").size("medium").render() as Text
        login2.text = "if you have an account"
        loginContainer.append2(login2 as any, {
            leftMargin: 6,
            sameLine: true,
        })

        return {
            container,
            nameField,
        }
    }

    init = (doneCallback: LoginCallback) => {
        if (this.loggingIn) {
            return
        }

        const landingContainer = new Graphics()
        this.landingContainer = landingContainer

        const introBackgroundGenerator = new IntroBackground()
        const background = introBackgroundGenerator.update()
        background.x = 0
        landingContainer.addChild(background)

        const butterflies = introBackgroundGenerator.bufferFlies()
        butterflies.x = 0
        landingContainer.addChild(butterflies)

        const socialMedia = this.guiManager.socialMedia()
        socialMedia.y = 3

        const introGenerator = new ProjectLogo()
        let nameField: TextInput
        const intro = introGenerator.update(() => {
            let y = 120
            const interval = setInterval(() => {
                y -= 1
                intro.y = y
                if (y < 0) {
                    clearInterval(interval)
                }
            }, 1)

            new Fader(background as Graphics, {
                direction: "in",
                minAlpha: 1.0,
                fadeAlphaDecrement: 0.005,
                releaseCallback: () => {
                    this.container.visible = true
                    nameField?.focus()
                },
            }).update()

            const { container: loginContainer, nameField: _namefield } = this.buildLoginContainer(intro, doneCallback)
            loginContainer.visible = false
            nameField = _namefield
            this.container = loginContainer
            this.guiManager.stage.addChild(loginContainer)

            this.socials = this.buildSocials(this.guiManager.stage)
        })

        intro.x = this.guiManager.screenDimensions().w / 2 - introGenerator.calcWidth() / 2
        intro.y = 120

        landingContainer.addChild(intro)
        this.guiManager.stage.addChild(landingContainer)
    }

    buildSocials = (parent?: Container): Graphics => {
        const allContainer = new DyanmicTextContainer()
        if (parent) {
            allContainer.y = BOTTOM - 40
            parent.addChild(allContainer)
        }

        const socialContainer = new DyanmicTextContainer({
            backdrop: true,
        })

        const socialMedia = this.guiManager.socialMedia()
        socialMedia.y = 3
        socialContainer.append2(socialMedia)

        allContainer.append2(socialContainer)

        const expandFullScreen = new BasicSprite({ name: "fullscreen.png", scale: 0.7 })
        parent.addChild(expandFullScreen)

        expandFullScreen.interactive = true
        expandFullScreen.cursor = "pointer"
        let expanded = false
        const handler = () => {
            expanded = !expanded
            if (expanded) {
                const elem = document.documentElement
                if (elem.requestFullscreen) {
                    elem.requestFullscreen()
                } else if (elem.webkitRequestFullscreen) {
                    /* Safari */
                    elem.webkitRequestFullscreen()
                } else if (elem.msRequestFullscreen) {
                    /* IE11 */
                    elem.msRequestFullscreen()
                }
            } else {
                if (document.exitFullscreen) {
                    document.exitFullscreen()
                } else if (document.webkitExitFullscreen) {
                    /* Safari */
                    document.webkitExitFullscreen()
                } else if (document.msExitFullscreen) {
                    /* IE11 */
                    document.msExitFullscreen()
                }
            }
        }
        expandFullScreen.on("click", handler)
        expandFullScreen.on("tap", handler)

        allContainer.append2(expandFullScreen, {
            sameLine: true,
            topMargin: 20,
            leftSpacer: 800 - expandFullScreen.width - 5,
        })

        return allContainer
    }

    collectPlayerLogin = (intro: Graphics, doneCallback: Function) => {
        const askForName = () => {
            const container = new DyanmicTextContainer()
            this.guiManager.stage.addChild(container)
            container.x = intro.x
            container.y = intro.y + intro.height

            const placeHolder = ""
            const nameField = this.createInput(intro.width, "Player name")

            container.append2(nameField)
            nameField.focus()

            const release = () => {
                container.removeChildren()
                container.parent?.removeChild(container)
            }

            /////////

            let currentName = placeHolder
            let currentPassword = ""

            const submit = () => {
                if (!currentName) {
                    this.guiManager.addScreenMessage({
                        target: "important",
                        text: "Username is required",
                    })
                    return false
                }
                if (!currentPassword) {
                    this.guiManager.addScreenMessage({
                        target: "important",
                        text: "Password is required",
                    })
                    return false
                }

                release()
                doneCallback(currentName, currentPassword)

                return true
            }

            nameField.on("input", text => {
                currentName = text
            })
            nameField.on("keyup", keycode => {
                if (keycode === 27) {
                    container.removeChildren()
                    container.parent.removeChild(container)
                    return
                }

                if (keycode === 13) {
                    passwordField.focus()
                }
            })

            const passwordField = this.createInput(intro.width, "Password", "password")
            passwordField.on("input", text => {
                currentPassword = text
            })

            passwordField.on("keyup", keycode => {
                if (keycode === 27) {
                    container.removeChildren()
                    container.parent.removeChild(container)
                    return
                }
                if (keycode === 13) {
                    if (submit()) {
                        container.removeChildren()
                        container.parent.removeChild(container)
                    }
                }
            })

            container.append2(passwordField, {
                topMargin: 5,
            })

            const buttonContainer = new DyanmicTextContainer()
            container.append2(buttonContainer)
            {
                const button = new Graphics()
                const text = new TextRenderer().variant("BannerFont").render("Submit") as Text
                text.tint = 0x00ff00
                button.addChild(text)
                button.interactive = true
                const handler = () => {
                    if (submit()) {
                        release()
                    }
                }
                button.on("click", handler)
                button.on("tap", handler)
                buttonContainer.append2(button)
            }
            {
                const button = new Graphics()
                button.addChild(new TextRenderer().variant("BannerFont").render("Cancel") as Text)
                button.interactive = true
                const handler = () => {
                    release()
                    doneCallback()
                }
                button.on("click", handler)
                button.on("tap", handler)
                buttonContainer.append2(button, { sameLine: true, leftMargin: 5 })
            }
        }

        askForName()
    }
}
