import { GuiManager } from "../gui_manager"
import { DyanmicTextContainer } from "./dynamic_text_container"
import { FullScreenModal } from "./fullscreen_modal"

export class About extends FullScreenModal {
    constructor(guiManager: GuiManager) {
        super(guiManager)
    }

    createContent = () => {
        const main = new DyanmicTextContainer()

        main.append("~~~~~~~~~~~~~~~~~~~")
            .append("About Project Samsara")
            .append("v2.0.0")
            .append("~~~~~~~~~~~~~~~~~~~")
            .append("Project Samsara is an experimental multiplayer combat game, in the vein")
            .append("of its forebearers of the 90's, similar to Diablo I. This is about")
            .append("that old feeling of blasting away the hordes, with or without your friends.")
            .append("")
            .append("...and also of blasting away your friends.")
            .append("")
            .append("The initial focus of this project is getting the gameplay mechanics right,")
            .append("which is advancing your character's stats and equipment by completing the")
            .append("game's challenges.")
            .append("")
            .append("Players will make decisions on what weapons, abilities, and equipment to")
            .append("lean on in real-time during combat.")
            .append("")
            .append("There is a storyline in the works, which can be enjoyed as part of the")
            .append("optional exploration aspect of this world that is yet to be developed.")
            .append("")
            .append("Stick with me as I evolve this game. Drop me a line at")
            .appendLink("project-samsara@olioapps.com", "mailto:project-samsara@olioapps.com")
            .append(", join the ", true)
            .appendLink("project subreddit", "https://www.reddit.com/r/projectsamsara/", true)
            .append(" or ", true)
            .appendLink("discord channel", "https://discord.gg/Mbt6UfqW6e")
            .append(" for latest developments!", true)

        return main
    }
}
