import { Point } from "pixi.js"
import { BaseRenderable } from "./base_renderable"

export class Goal extends BaseRenderable {
    constructor(color: any = 0xff00ff) {
        super()

        super.beginFill(color)
        super.lineStyle(4, color)
        super.drawPolygon([new Point(0, 0), new Point(50, 50)])
        super.drawPolygon([new Point(50, 0), new Point(0, 50)])
        super.endFill()
    }
}
