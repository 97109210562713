import { Graphics } from "pixi.js"
import { Meter } from "./meter"
import { BasicSprite } from "../sprites/basic_sprite"

export class ManaMeter extends Graphics {
    meter: Meter
    max: number

    constructor(color: number = 0x00ff00, max: number, spriteId?: string, lowColor?: number) {
        super()
        this.max = max
        this.meter = new Meter(color, max, lowColor)
        const icon = new BasicSprite({
            name: spriteId || "stamina.png",
            scale: 0.9,
        })
        icon.y = icon.height / 2 + 1
        this.addChild(icon)
        this.meter.x = 13
        this.addChild(this.meter)
    }

    update = (amt: number) => {
        this.meter.update(amt)
    }
}
