import { between } from "game-common/util"
const MAX_HEIGHT = 255

export type RangeEvaluation = "high" | "low" | "mid"

class BiomeRange {
    min: number
    max: number

    constructor(min: number, max: number) {
        this.min = min
        this.max = max
    }

    fits = (value: number) => {
        return this.min <= value && value <= this.max
    }

    eval = (
        value: number,
    ): {
        subRange: BiomeRange
        evaluation: RangeEvaluation
    } => {
        const spread = this.max - this.min
        const subRangeWidth = Math.floor(spread / 3)
        const mid = this.min + Math.floor((this.max - this.min) / 2)

        if (value < this.min) {
            return {
                subRange: new BiomeRange(this.min, this.min + subRangeWidth),
                evaluation: "low",
            }
        }

        if (value > this.max) {
            return {
                subRange: new BiomeRange(this.min + subRangeWidth + subRangeWidth, this.max),
                evaluation: "high",
            }
        }

        if (between(value, this.min, this.min + subRangeWidth)) {
            return {
                subRange: new BiomeRange(this.min, this.min + subRangeWidth),
                evaluation: "low",
            }
        }

        if (between(value, this.min + subRangeWidth, this.min + subRangeWidth + subRangeWidth)) {
            return {
                subRange: new BiomeRange(this.min + subRangeWidth, this.min + subRangeWidth + subRangeWidth),
                evaluation: "mid",
            }
        }

        if (between(value, this.min + subRangeWidth + subRangeWidth, this.max)) {
            return {
                subRange: new BiomeRange(this.min + subRangeWidth + subRangeWidth, this.max),
                evaluation: "high",
            }
        }

        return {
            subRange: new BiomeRange(this.min + subRangeWidth, this.min + subRangeWidth + subRangeWidth),
            evaluation: "mid",
        }
    }
}

export class BiomeMeta {
    name: string
    heightRange: BiomeRange
    tempRange: BiomeRange
    humidityRange: BiomeRange
    color: number

    constructor(
        name: string,
        heightRange: BiomeRange,
        tempRange: BiomeRange,
        humidityRange: BiomeRange,
        color: number,
    ) {
        this.name = name
        this.heightRange = heightRange
        this.tempRange = tempRange
        this.humidityRange = humidityRange
        this.color = color
    }
}

export const Biomes: BiomeMeta[] = [
    new BiomeMeta(
        "Abyss",
        new BiomeRange(0, 39),
        new BiomeRange(-10, 50),
        new BiomeRange(-10, Number.MAX_VALUE),
        0x090140ff,
    ),
    new BiomeMeta(
        "Ocean",
        new BiomeRange(40, 129),
        new BiomeRange(-10, 50),
        new BiomeRange(-10, Number.MAX_VALUE),
        0x2107d8ff,
    ),
    new BiomeMeta(
        "IceLands",
        new BiomeRange(0, 170),
        new BiomeRange(-Number.MAX_VALUE, 0),
        new BiomeRange(-Number.MAX_VALUE, Number.MAX_VALUE),
        0x75fcf2ff,
    ),
    new BiomeMeta(
        "Shore",
        new BiomeRange(130, 135),
        new BiomeRange(0, 50),
        new BiomeRange(-Number.MAX_VALUE, 50),
        0xc7ce00ff,
    ),
    new BiomeMeta(
        "SnowyShore",
        new BiomeRange(130, 135),
        new BiomeRange(-Number.MAX_VALUE, 0),
        new BiomeRange(-Number.MAX_VALUE, Number.MAX_VALUE),
        0xedfca8ff,
    ),
    new BiomeMeta("Forest", new BiomeRange(140, 169), new BiomeRange(0, 25), new BiomeRange(5, 30), 0x128b03ff),
    new BiomeMeta("Plains", new BiomeRange(135, 169), new BiomeRange(0, 50), new BiomeRange(0, 50), 0x40d115ff),
    new BiomeMeta(
        "FireLands",
        new BiomeRange(135, 169),
        new BiomeRange(50, Number.MAX_VALUE),
        new BiomeRange(-Number.MAX_VALUE, 30),
        0xe57307ff,
    ),
    new BiomeMeta(
        "Tundra",
        new BiomeRange(140, 169),
        new BiomeRange(-10, 0),
        new BiomeRange(5, Number.MAX_VALUE),
        0x745f4eff,
    ),
    new BiomeMeta(
        "Desert",
        new BiomeRange(135, 169),
        new BiomeRange(30, Number.MAX_VALUE),
        new BiomeRange(-Number.MAX_VALUE, 5),
        0xcbb848ff,
    ),
    new BiomeMeta("GrassyHills", new BiomeRange(160, 189), new BiomeRange(5, 25), new BiomeRange(5, 30), 0x2e7612ff),
    new BiomeMeta("ForestyHills", new BiomeRange(160, 189), new BiomeRange(5, 30), new BiomeRange(0, 30), 0x1b5504ff),
    new BiomeMeta("MuddyHills", new BiomeRange(170, 189), new BiomeRange(0, 40), new BiomeRange(0, 50), 0x984319ff),
    new BiomeMeta(
        "DryHills",
        new BiomeRange(140, 189),
        new BiomeRange(10, 40),
        new BiomeRange(-Number.MAX_VALUE, 0),
        0xc6950aff,
    ),
    new BiomeMeta(
        "SnowyHills",
        new BiomeRange(170, 189),
        new BiomeRange(-Number.MAX_VALUE, 0),
        new BiomeRange(-Number.MAX_VALUE, Number.MAX_VALUE),
        0x1fa27cff,
    ),
    new BiomeMeta(
        "DesertDunes",
        new BiomeRange(170, 189),
        new BiomeRange(30, Number.MAX_VALUE),
        new BiomeRange(-Number.MAX_VALUE, 0),
        0x7e7109ff,
    ),
    new BiomeMeta(
        "Volcano",
        new BiomeRange(170, MAX_HEIGHT),
        new BiomeRange(30, Number.MAX_VALUE),
        new BiomeRange(-Number.MAX_VALUE, 35),
        0xaf1109ff,
    ),
    new BiomeMeta(
        "RockyMountains",
        new BiomeRange(180, MAX_HEIGHT),
        new BiomeRange(-Number.MAX_VALUE, 30),
        new BiomeRange(-Number.MAX_VALUE, 40),
        0x43100dff,
    ),
    new BiomeMeta(
        "IceMountains",
        new BiomeRange(180, MAX_HEIGHT),
        new BiomeRange(-Number.MAX_VALUE, 0),
        new BiomeRange(5, Number.MAX_VALUE),
        0x5b6a63ff,
    ),
    new BiomeMeta(
        "Swamp",
        new BiomeRange(130, 170),
        new BiomeRange(0, 35),
        new BiomeRange(40, Number.MAX_VALUE),
        0x052403ff,
    ),
    new BiomeMeta(
        "RainForest",
        new BiomeRange(140, 180),
        new BiomeRange(30, 40),
        new BiomeRange(40, Number.MAX_VALUE),
        0x324b28ff,
    ),
    new BiomeMeta(
        "DryLands",
        new BiomeRange(0, 150),
        new BiomeRange(0, 40),
        new BiomeRange(-Number.MAX_VALUE, 0),
        0x834c10ff,
    ),
    new BiomeMeta("Savannah", new BiomeRange(135, 169), new BiomeRange(20, 50), new BiomeRange(-10, 10), 0x767618ff),
    new BiomeMeta(
        "GeyserLand",
        new BiomeRange(130, 170),
        new BiomeRange(40, Number.MAX_VALUE),
        new BiomeRange(40, Number.MAX_VALUE),
        0x3a3b55ff,
    ),

    new BiomeMeta(
        "None",
        new BiomeRange(0, MAX_HEIGHT),
        new BiomeRange(-Number.MAX_VALUE, Number.MAX_VALUE),
        new BiomeRange(-Number.MAX_VALUE, Number.MAX_VALUE),
        0xe513c3ff,
    ),
]

export const BiomesMap: Map<string, BiomeMeta> = new Map()
Biomes.forEach(biome => BiomesMap.set(biome.name, biome))
export const BiomeColorsMap: Map<number, BiomeMeta> = new Map()
Biomes.forEach(biome => BiomeColorsMap.set(biome.color >> 8, biome))

export const calculateBiome = (h: number, temp: number, humidity: number): BiomeMeta => {
    const found = Biomes.find(biome => {
        if (biome.heightRange.fits(h)) {
            if (biome.tempRange.fits(temp)) {
                if (biome.humidityRange.fits(humidity)) {
                    return biome
                }
            }
        }
    })
    if (found) {
        return found
    }

    // throw Error("No biome fits the following parameters: height: " + h + ", temperature: " + temp + ", humidity: " + humidity)
}
