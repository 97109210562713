import { ApplyInputResultPool } from "game-common/apply_input_result_pool"
import { InventoryAction } from "game-common/item/item"
import { MapDelta } from "game-common/map/map"
import {
    Appearance,
    BiomeMapPoint,
    BuildingPointOptions,
    Coordinate,
    Entity,
    EntityId,
    getCoordinateDistance,
    Inventory,
    Latch,
    LocationIcon,
    Message,
    MessageEnvelope,
    Movement,
    PlayerEvent,
    SIGIL_FLAG,
    SubmittedFeedback,
    TileSize,
    WorldState,
} from "game-common/models"
import { Protocol } from "game-common/protocol"

import { ClientGameLogic } from "./client_game_logic"
import { randomId } from "game-common/util"

export class Client {
    private last_ts?: number
    private lastState: boolean | undefined = undefined
    private sendState: boolean
    private primingLatch: Latch
    private incompleteEntityLatch: Latch = new Latch(2000)
    private lastMovement: Movement

    incomingWorldStates: WorldState[] = []
    ws: WebSocket
    interpolationTs: Latch = new Latch(100)
    clientGameLogic: ClientGameLogic

    constructor(clientGameLogic: ClientGameLogic) {
        this.clientGameLogic = clientGameLogic
    }

    boost = (on: boolean) => {
        this.send({ entityId: this.clientGameLogic.playerEntity.entityId, boost: on }, Protocol.type_boostAction)
    }

    fireBullet = () => {
        const player = this.clientGameLogic.playerEntity
        if (!player) {
            return
        }

        if (!this.clientGameLogic.canFireBulletFrom(player)) {
            return
        }

        this.clientGameLogic.attack(player.entityId, () => {
            const bullets: Entity[] = this.clientGameLogic.createBulletFrom(player)
            if (!bullets?.length) {
                // could not fire bullet
                return
            }

            bullets.forEach(bullet => {
                bullet.entityId = randomId()
                this.clientGameLogic.entityManager.set(bullet)
                if (bullet.spriteId !== "none") {
                    this.clientGameLogic.clientRenderer.entity().create(bullet)
                }
            })

            const entities = bullets.map(bullet => {
                let message: Message = {
                    entityId: bullet.entityId,
                }
                return message
            })

            this.send(
                {
                    message: {
                        entities,
                        origin: { ...player.location },
                        roomId: player.roomId,
                        movement: { ...player.movement },
                        weapon: player.weapon,
                    },
                },
                Protocol.type_fireWeapon,
            )
        })
    }

    spawnNpc = () => {
        // if (this.clientGameLogic.playerEntity.name?.trim() !== "aron") {
        //     return
        // }

        this.send({ entityId: this.clientGameLogic.playerEntity.entityId }, "spawn")
    }

    dropAction = () => {
        this.send({ entityId: this.clientGameLogic.playerEntity.entityId }, Protocol.type_dropAction)
    }

    resetAction = () => {
        this.send({ entityId: this.clientGameLogic.playerEntity.entityId }, Protocol.type_resetAction)
    }

    activateInventoryItem = (item: string) => {
        this.send(
            {
                message: {
                    item,
                    originEntityId: this.clientGameLogic.playerEntity.entityId,
                },
            },
            Protocol.type_activateInventoryItem,
        )
    }

    activateAbility = (shortcutKey: string) => {
        this.send(
            {
                message: {
                    shortcutKey,
                    originEntityId: this.clientGameLogic.playerEntity.entityId,
                },
            },
            Protocol.type_activateAbility,
        )
    }

    dropActiveItem = () => {
        this.send({}, Protocol.type_requestDropActiveItem)
    }

    startChat = () => {
        this.clientGameLogic.clientRenderer.gui().startChat()
    }

    stopChat = () => {
        const message = this.clientGameLogic.clientRenderer.gui().stopChat()
        if (!message) {
            return
        }

        this.send({ entityId: this.clientGameLogic.playerEntity.entityId, message }, Protocol.type_chatMessage)
    }

    captureKeystroke = (c: string) => {
        if (c.length > 1 && c !== "Backspace" && c !== "Escape" && c !== "ArrowUp") {
            return
        }
        this.clientGameLogic.clientRenderer.gui().captureKeystroke(c)
    }

    updateName = (name: string) => {
        this.send(
            {
                message: {
                    name,
                    originEntityId: this.clientGameLogic.playerEntity.entityId,
                },
            },
            Protocol.type_updateName,
        )
    }

    updateEntityFromMessage = (message: Message, entity: Entity, now: number) => {
        entity.lastUpdateTs = now
        entity.entityState = message.entityState
        entity.roomId = message.roomId
        entity.speed = message.speed
        entity.maxSpeed = message.maxSpeed
        entity.name = message.name
        entity.color = message.color
        entity.hp = message.hp
        entity.hunger = message.hunger
        entity.maxHunger = message.maxHunger
        entity.maxHp = message.hpMax
        entity.joinedTeam = message.joinedTeam
        entity.score = message.score
        entity.level = message.level
        entity.scoreTilNextLevel = message.scoreTillNextLevel
        entity.weapon = message.weapon
        entity.paralyzeUntilTs = message.paralyzeUntilTs
        entity.appearance = message.appearance
        entity.clickable = message.clickable
        entity.spriteId = message.spriteId
        entity.expirationTs = message.expirationTs
        entity.quantity = message.quantity
        entity.lightMeta = message.lightMeta
        entity.spriteScale = message.spriteScale
        entity.leaderEntityId = message.leaderEntityId
        entity.flags = {}
        entity.flags[SIGIL_FLAG] = message.sigilIcon
        entity.dimensions = message.dimensions
        entity.debug = message.debug
        entity.afk = message.afk
        entity.active = message.active

        if (message.inventory) {
            entity.inventory = message.inventory
        }

        this.clientGameLogic.clientRenderer.entity().update(entity)
    }

    // Handles messages from the server and updates client state
    processServerMessages = () => {
        const now = Date.now()
        while (true) {
            const worldState: WorldState = this.incomingWorldStates.shift()
            if (!worldState) {
                break
            }

            const updatedRoom = this.clientGameLogic.updateFromWorldState(worldState)
            if (updatedRoom) {
                // this.clientGameLogic.clientRenderer.gui().fadeOut(true)
            }

            const { messages } = worldState

            // World state is a list of entity states.
            for (let i = 0; i < messages.length; i++) {
                const message: Message = messages[i]

                if (this.clientGameLogic.isDestroyed(message.entityId)) {
                    // its been destroyed, noop
                    continue
                }

                // If this is the first time we see this entity, create a local representation.
                if (!this.clientGameLogic.hasEntity(message.entityId)) {
                    this.clientGameLogic.createEntity(message)
                }

                const entity = this.clientGameLogic.getEntity(message.entityId)
                if (!entity) {
                    continue
                }

                this.updateEntityFromMessage(message, entity, now)

                const player = this.clientGameLogic.playerEntity
                const isPlayer = message.entityId == player?.entityId

                // update other players locally
                if (isPlayer) {
                    if (!updatedRoom) {
                        const updatedLocation = { x: message.position[0], y: message.position[1] }
                        const positionUpdated =
                            updatedLocation.x !== this.clientGameLogic.playerEntity.location.x ||
                            updatedLocation.y !== this.clientGameLogic.playerEntity.location.y
                        this.clientGameLogic.updateFromPlayerMessage(message)

                        // avoid interpolating too often
                        if (player.pending_inputs.length > 0 && !this.interpolationTs.expired()) {
                            continue
                        }

                        // Received the authoritative position of this client's entity.
                        if (player.pending_inputs.length < 1 && positionUpdated) {
                            entity.location = updatedLocation
                            this.clientGameLogic.clientRenderer.updatePlayerLocation(entity)
                            continue
                        }
                        if (getCoordinateDistance(entity.location, updatedLocation) > TileSize / 2) {
                            this.primingLatch = null
                        }
                        entity.location = updatedLocation

                        // Server Reconciliation. Re-apply all the inputs not yet processed by
                        // the server.
                        let j = 0

                        while (j < player.pending_inputs.length) {
                            const input = player.pending_inputs[j]
                            if (input.input_sequence_number <= message.last_processed_input) {
                                // Already processed. Its effect is already taken into account into the world update
                                // we just got, so we can drop it.
                                player.pending_inputs.splice(j, 1)
                            } else {
                                // Not processed by the server yet. Re-apply it.
                                const resultItem = ApplyInputResultPool.instance.checkout()
                                this.clientGameLogic.advanceEntity(resultItem.data, entity, input, true)
                                ApplyInputResultPool.instance.release(resultItem)
                                j++
                            }
                        }
                    } else {
                        setTimeout(() => {
                            entity.position_buffer = []
                            const updatedLocation = { x: message.position[0], y: message.position[1] }
                            this.clientGameLogic.updateFromPlayerMessage(message)
                            entity.location = updatedLocation
                            this.clientGameLogic.clientRenderer.updatePlayerLocation(entity, false)
                        }, 100)
                    }
                } else {
                    this.clientGameLogic.updateFromNonPlayerMessage(message)

                    // Received the position of an entity other than this client's.
                    // Add it to the position buffer.
                    const timestamp = Date.now()
                    entity.position_buffer.push([
                        timestamp,
                        message.position[0],
                        message.position[1],
                        message.direction,
                        message.angle,
                    ])

                    if (!message.complete) {
                        this.clientGameLogic.incompleteEntity(entity)
                    } else {
                        this.clientGameLogic.clearIncompleteEntities([entity.entityId])
                    }
                }

                entity.visible = message.visible
            }
        }
    }

    private toSendState = (player: Entity) => {
        const { speed2: c, movement } = player
        if (!this.lastMovement) {
            this.lastMovement = { ...movement }
        }
        const dontSend = c.x === 0 && c.y === 0 && movement.angle === this.lastMovement.angle

        this.lastMovement.angle = movement.angle
        this.lastMovement.direction = movement.direction
        this.lastMovement.moving = movement.moving
        return !dontSend
    }

    // Process user inputs and send updated to the server
    processInputs = () => {
        const now_ts = Date.now()
        const last_ts = this.last_ts || now_ts
        const dt_sec = (now_ts - last_ts) / 1000.0
        this.last_ts = now_ts

        // dt_sec is the delta seconds since last update
        this.clientGameLogic.processEntities(dt_sec)
        const player = this.clientGameLogic.playerEntity

        // handle player movement

        if (player) {
            const { movement, speed } = player

            const thisState = this.toSendState(player)
            const changed = thisState !== this.lastState || this.lastState === undefined

            if (changed) {
                this.sendState = thisState
            }
            this.lastState = thisState
            if (!this.primingLatch) {
                this.primingLatch = new Latch(2000, Date.now())
                this.primingLatch.singleUse = true
            }

            if (this.sendState || (this.primingLatch && !this.primingLatch.expired())) {
                const speed2 = { x: player.speed2.x * speed, y: player.speed2.y * speed }

                let message: Message = {
                    entityType: "player",
                    press_time: dt_sec,
                    direction: movement.direction,
                    angle: movement.angle,
                    speed2,
                    input_sequence_number: player.input_sequence_number++,
                    entityId: player.entityId,
                    roomId: player.roomId,
                }

                const { x, y } = player.location
                const resultItem = ApplyInputResultPool.instance.checkout()
                this.clientGameLogic.advancePlayer(resultItem.data, message)
                const movedX = resultItem.data?.movedX
                const movedY = resultItem.data?.movedY
                ApplyInputResultPool.instance.release(resultItem)

                if (
                    (!movedX && movedY) ||
                    (movedX && !movedY && (x !== player.location.x || y !== player.location.y))
                ) {
                    if (movedX) {
                        const speed2 = { x: player.speed2.x * speed, y: 0 }
                        message.speed2 = speed2
                    }

                    if (movedY) {
                        const speed2 = { x: 0, y: player.speed2.y * speed }
                        message.speed2 = speed2
                    }
                }

                this.ws.send(
                    JSON.stringify({
                        recv_ts: Date.now(),
                        payload: message,
                    }),
                )

                // Save this input for later reconciliation.
                player.pending_inputs.push(message)
            }
        }
    }

    renderWorld = () => this.clientGameLogic.renderScene()

    interpolateEntities = () => {
        // Compute render timestamp.
        const now = Date.now()
        const render_timestamp = now - 150

        this.clientGameLogic.entityManager.iterate(null, entity => {
            if (!entity) {
                return
            }

            // No point in interpolating local things
            if (!this.clientGameLogic.shouldInterpolate(entity)) {
                return
            }

            // Find the two authoritative positions surrounding the rendering timestamp.
            const buffer = entity.position_buffer || []
            if (buffer.length < 1) {
                return
            }

            // Drop older positions.
            while (buffer.length >= 2 && buffer[1][0] <= render_timestamp) {
                buffer.shift()
            }

            // Interpolate between the two surrounding authoritative positions.
            if (buffer.length >= 2 && buffer[0][0] <= render_timestamp && render_timestamp <= buffer[1][0]) {
                const x0 = buffer[0][1]
                const x1 = buffer[1][1]

                const y0 = buffer[0][2]
                const y1 = buffer[1][2]

                const t0 = buffer[0][0]
                const t1 = buffer[1][0]

                const angle0 = buffer[0][4]
                const angle1 = buffer[1][4]

                entity.location.x = x0 + ((x1 - x0) * (render_timestamp - t0)) / (t1 - t0)
                entity.location.y = y0 + ((y1 - y0) * (render_timestamp - t0)) / (t1 - t0)

                entity.movement.direction = buffer[1][3]
                // entity.movement.angle = angle0 + (angle1 - angle0) * (render_timestamp - t0) / (t1 - t0)
                entity.movement.angle = angle1
            }
        })
    }

    incompleteEntities = () => {
        if (this.incompleteEntityLatch.expired()) {
            const nearby = this.clientGameLogic.nearbyIncompleteEntities()
            if (nearby.length > 0) {
                this.requestEntityDetail(nearby)
            }
        }
    }

    // Game update loop
    update = () => {
        this.processServerMessages()

        if (!this.clientGameLogic.playerEntity) {
            return
        }

        this.processInputs()

        this.interpolateEntities()

        this.incompleteEntities()

        this.renderWorld()

        this.clientGameLogic.cleanup()
    }

    send = (payload: {}, payloadType: string) => {
        this.ws.send(
            JSON.stringify({
                recv_ts: Date.now(),
                payloadType,
                payload,
            }),
        )
    }

    register = (username: string, password: string, email?: string) => {
        this.send(
            {
                username,
                password,
                email,
            },
            Protocol.type_upgradeGuest,
        )
    }

    login = (username: string, password: string) => {
        this.send(
            {
                username,
                password,
            },
            Protocol.type_login,
        )
    }

    logActivity = (activity: string) => {
        this.send(
            {
                activity,
            },
            Protocol.type_logActivity,
        )
    }

    submitFeedback = (feedback: SubmittedFeedback) => {
        this.send(
            {
                feedback,
            },
            Protocol.type_submitFeedback,
        )
    }

    handleGuest = (userAgent: string) => {
        this.clientGameLogic.clientRenderer
            .gui()
            .getIntro()
            .init((playerName: string, password: string) => {
                if (!password) {
                    this.connectImpl("", undefined, true, () => {
                        this.send({ playerName, userAgent }, "guest")
                    })
                } else {
                    this.connectImpl("", undefined, true, () => {
                        this.login(playerName, password)
                    })
                }
            })
    }

    invokeStatUpgradeUI = () => {
        this.send({}, Protocol.type_showStatUpgradeUI)
    }

    entityClicked = (entity: Entity) => {
        this.send(
            {
                entityId: entity.entityId,
            },
            Protocol.type_entityClicked,
        )
    }

    connect(playerName: string, accessToken?: string, guest?: boolean) {
        if (guest) {
            const userAgent = navigator?.userAgent
            this.handleGuest(userAgent)
        } else {
            this.connectImpl(playerName, accessToken, false)
        }
    }

    connectImpl(playerName: string, accessToken?: string, guest?: boolean, onConnect?: () => void) {
        const hostname = document.location.hostname
        // const isLocal = false
        const isLocal = hostname === "localhost" || hostname.startsWith("10.0")
        const apiHost = process?.env?.API_HOST || "wss://api.projectsamsara.com"

        const ws = isLocal
            ? // ? new WebSocket('ws://' + hostname + ':9999/')
              new WebSocket("ws://" + hostname + ":8080/")
            : new WebSocket(`${apiHost}`)
        // const ws = new WebSocket("ws://racho-dev.ngrok.io")

        const redirectToPortal = () => {
            if (accessToken) {
                if (isLocal) {
                    window.location.href = "http://localhost:3000/account/login"
                } else {
                    window.location.href = "https://.projectsamsara.com/?guest=true"
                }
            } else {
                if (!isLocal) {
                    alert("The server might be down, please try again in a few minutes.")
                }
                window.location.href = "/"
            }
        }
        this.ws = ws
        ws.onopen = () => {
            console.log("Connected")
            if (onConnect) {
                onConnect()
            }
        }
        ws.onerror = e => console.log(JSON.stringify(e))
        ws.onclose = () => {
            redirectToPortal()
        }
        const userAgent = navigator?.userAgent
        ws.onmessage = message => {
            const parsedMessage: MessageEnvelope = JSON.parse(message.data)
            const { payloadType, payload } = parsedMessage
            if (payloadType === Protocol.type_startSession) {
                console.log("Received start session")
                if (guest) {
                    this.send({ userAgent }, "initializeGuest")
                } else {
                    if (accessToken) {
                        console.log("Sent access token")
                        this.send({ accessToken, userAgent }, "accessToken")
                    } else {
                        console.log("Sent playername", playerName)
                        this.send({ playerName, userAgent }, "name")
                    }
                }

                return
            }

            if (payloadType === Protocol.type_setEntityId) {
                console.log("Received entityId; guest:", guest === true)
                const { entityId } = payload
                this.clientGameLogic.initializePlayer(entityId, this, guest === true)
                if (guest) {
                    this.clientGameLogic.clientRenderer.gui().getIntro().loginSuccess()
                }
                return
            }

            if (payloadType === Protocol.type_updatePlayerEntityId) {
                console.log("Received update entityId")
                const { entityId } = payload
                this.clientGameLogic.updatePlayerEntityId(entityId)
                return
            }

            if (payloadType === Protocol.type_destroyEntityId) {
                const { entityId, roomId } = payload
                if (this.clientGameLogic.playerEntity?.roomId === roomId) {
                    this.clientGameLogic.scheduleForDestruction(entityId)
                }
            }

            if (payloadType === Protocol.type_ping) {
                const { entityId, creationTs } = payload
                this.send({ creationTs, entityId }, "pong")
            }

            if (payloadType === Protocol.type_sendPleaseWait) {
                this.clientGameLogic.pleaseWait()
            }

            if (payloadType === Protocol.type_fragmentAnimation) {
                const { spriteId, location } = payload
                this.clientGameLogic.clientRenderer.entity().fragment(spriteId, location)
            }

            if (payloadType === Protocol.type_struckEntityId) {
                const { entityId, strikerEntityId } = payload
                this.clientGameLogic.doStruck(entityId, strikerEntityId)
            }

            if (payloadType === Protocol.type_entityTileEffect) {
                const { effect } = payload
                this.clientGameLogic.tileEffect(effect)
            }

            if (payloadType === Protocol.type_detonate) {
                const { entityId } = payload
                this.clientGameLogic.detonate(entityId)
            }

            if (payloadType === Protocol.type_attack) {
                const { entityId } = payload
                this.clientGameLogic.attack(entityId)
            }

            if (payloadType === Protocol.type_updateActivation) {
                const { entityId, active } = payload
                this.clientGameLogic.updateActivation(entityId, active)
            }

            if (payloadType === Protocol.type_orientation) {
                const { orientation } = payload
                this.clientGameLogic.orientation(orientation)
            }

            if (payloadType === Protocol.type_killed) {
                const { entityId } = payload
                this.clientGameLogic.killed(entityId)
            }

            if (payloadType === Protocol.type_respawned) {
                const { entityId } = payload
                this.clientGameLogic.respawned(entityId)
            }

            if (payloadType === Protocol.type_sendTeleported) {
                const { entityId } = payload
                this.clientGameLogic.teleported(entityId)
            }

            if (payloadType === Protocol.type_entityEmp) {
                const { entityId, range } = payload
                this.clientGameLogic.emp(entityId, range)
            }

            if (payloadType === Protocol.type_fadeInOut) {
                const { direction } = payload
                this.clientGameLogic.fadeInOut(direction)
            }

            if (payloadType === Protocol.type_worldState) {
                const message: WorldState = payload
                this.incomingWorldStates.push(message)
            }

            if (payloadType === Protocol.type_entityDisconnected) {
                const { entityId } = payload
                console.log("disconnect", entityId)
                this.clientGameLogic.scheduleForDestruction(entityId)
            }

            if (payloadType === Protocol.type_screenMessage) {
                const { message } = payload
                if (message) {
                    this.clientGameLogic.clientRenderer.gui().addScreenMessage(message)
                }
            }

            if (payloadType === Protocol.type_chatMessage) {
                const { message, originEntityName, originEntityId } = payload
                this.clientGameLogic.handleChat(message, originEntityId, originEntityName)
            }

            if (payloadType === Protocol.type_redrawClient) {
                this.clientGameLogic.clientRenderer.map().rerender()
            }

            if (payloadType === Protocol.type_hideRoomName) {
                const { hidden, roomName } = payload
                this.clientGameLogic.hideRoomName(hidden)
                if (!hidden && roomName) {
                    this.clientGameLogic.updateLocation(roomName)
                }
            }
            if (payloadType === Protocol.type_sendUpdateLocation) {
                const { location, roomId } = payload
                this.clientGameLogic.updateLocation(location, roomId)
            }
            if (payloadType === Protocol.type_createLocalEntity) {
                const { entity } = payload
                this.clientGameLogic.createLocalEntity(entity)
            }
            if (payloadType === Protocol.type_destroyLocalEntity) {
                const { flags } = payload
                this.clientGameLogic.destroyLocalEntity(flags)
            }
            if (payloadType === Protocol.type_soundEffect) {
                const { effect, loop, target } = payload
                this.clientGameLogic.playSoundEffect(effect, loop, target)
            }
            if (payloadType === Protocol.type_sendAnimateLocalEntityCollection) {
                const { entityId, target, position } = payload
                this.clientGameLogic.animateLocalEntityCollection(entityId, target, position)
            }
            if (payloadType === Protocol.type_animateAbility) {
                const { abilityType, duration } = payload
                this.clientGameLogic.animateAbility(abilityType, duration)
            }
            if (payloadType === Protocol.type_upgradeGuest) {
                this.clientGameLogic.upgradedAccount()
            }
            if (payloadType === Protocol.type_dayPhase) {
                const { dayPhase, dayPct } = payload
                this.clientGameLogic.updateDayPhase(dayPhase)
                this.clientGameLogic.clientRenderer.gui().updateDayPct(dayPct)
            }
            if (payloadType === Protocol.type_requestEntityDetail) {
                const { messages } = payload
                if (messages) {
                    const now = Date.now()
                    messages.forEach((message: Message) => {
                        const entity = this.clientGameLogic.getEntity(message.entityId)
                        if (entity) {
                            this.updateEntityFromMessage(message, entity, now)
                            this.clientGameLogic.clearIncompleteEntities([entity.entityId])
                        }
                    })
                }
            }
            if (payloadType === Protocol.type_login) {
                const { entityId } = payload
                if (entityId) {
                    this.clientGameLogic.clientRenderer.gui().getIntro().loginSuccess()
                    this.clientGameLogic.initializePlayer(entityId, this, false)
                    this.clientGameLogic.clientRenderer.gui().addScreenMessage({
                        target: "important",
                        text: "Welcome back!",
                        delay: 1000,
                    })
                } else {
                    this.clientGameLogic.clientRenderer.gui().getIntro().loginFailed()
                    this.clientGameLogic.clientRenderer.gui().addScreenMessage({
                        target: "important",
                        text: "Invalid player name or password.",
                    })
                }
            }
            if (payloadType === Protocol.type_mapDelta) {
                const deltas: MapDelta[] = payload.delta

                const tilemapMetaProvider = this.clientGameLogic.tilemapMetaProvider
                if (tilemapMetaProvider) {
                    deltas.forEach(delta => {
                        const {
                            x,
                            y,
                            blocking,
                            occlusion,
                            tileId,
                            layerId,
                            surfaceId,
                            mapSpriteCap,
                            collideable,
                            temporary,
                            context,
                        } = delta
                        if (tileId) {
                            if (delta.removed) {
                                debugger
                                tilemapMetaProvider.removeTileAt(x, y, layerId)
                            } else {
                                const layerMapping = tilemapMetaProvider.resolveLayerMapping(layerId)

                                const tileMap =
                                    tilemapMetaProvider.resolveTileMapFromLayerId(layerId) ||
                                    tilemapMetaProvider.entityTileMap()

                                const isReplace = layerMapping?.maptype === "tilemap" && tileMap[x][y] !== "0"
                                tilemapMetaProvider.addTileAt(
                                    x,
                                    y,
                                    blocking,
                                    occlusion,
                                    tileId,
                                    layerId,
                                    mapSpriteCap,
                                    temporary,
                                    collideable,
                                    context,
                                )
                                if (tileMap) {
                                    this.clientGameLogic.clientRenderer.map().renderAt(x, y, tileMap, layerId, {
                                        wasReplaced: isReplace,
                                        surfaceId,
                                    })
                                }

                                if (mapSpriteCap) {
                                    tilemapMetaProvider.addMapSpriteCapAt(x, y, mapSpriteCap?.context)
                                }
                            }
                        } else {
                            tilemapMetaProvider.updateBlockingAt(x, y, blocking, false)
                        }
                    })
                }
            }
            if (payloadType === Protocol.type_updateSquadState) {
                const { squadState } = payload
                this.clientGameLogic.clientRenderer.gui().updateSquadState(squadState)
            }
            if (payloadType === Protocol.type_updateEntityInteractions) {
                const { entityId, interactions } = payload
                this.clientGameLogic.clientRenderer.entity().updateEntityInteractions(entityId, interactions)
            }
            if (payloadType === Protocol.type_updateEntityPointerIcon) {
                const { entityId, show, angle } = payload
                const entity = this.clientGameLogic.entityManager.get(entityId)
                if (entity) {
                    this.clientGameLogic.clientRenderer.entity().updateEntityPointerIcon(entity, show, angle)
                }
            }
            if (payloadType === Protocol.type_buildCharacter) {
                const { entityId } = payload
                this.clientGameLogic.clientRenderer.gui().buildCharacter(entityId)
            }
            if (payloadType === Protocol.type_itemPickedUp) {
                const { entityId, itemTypeMeta, quantity } = payload
                const entity = this.clientGameLogic.entityManager.get(entityId)
                if (entity) {
                    this.clientGameLogic.itemPickup(entity, itemTypeMeta, quantity)
                }
            }
            if (payloadType === Protocol.type_showTutorial) {
                const { tutorialType } = payload

                this.clientGameLogic.clientRenderer.gui().showTutorial(tutorialType)
            }
            if (payloadType === Protocol.type_builderAction) {
                const { active } = payload
                this.clientGameLogic.playerController.builderActivation(active)
            }
            if (payloadType === Protocol.type_sendWorldMap) {
                const { map } = payload
                this.clientGameLogic.clientRenderer.gui().showWorldMap(map)
            }

            if (payloadType === Protocol.type_removeLocationIcon) {
                this.clientGameLogic.clientRenderer.map().removeLocationIcon(payload.iconId)
            }

            if (payloadType === Protocol.type_addLocationIcon) {
                this.clientGameLogic.clientRenderer.entity().updateLocationIcons(payload.icon)
            }

            if (payloadType === Protocol.type_updatePlayerBiomeColorMap) {
                const points: BiomeMapPoint[] = payload.meta?.points || []
                const icons: LocationIcon[] = payload.meta?.icons || []
                const clear: boolean = payload?.meta?.clear === true

                if (clear) {
                    const biomeColorMap = this.clientGameLogic.tilemapMetaProvider?.biomeColorMap
                    if (biomeColorMap) {
                        for (let x = 0; x < biomeColorMap.length; x++) {
                            for (let y = 0; y < biomeColorMap[0].length; y++) {
                                biomeColorMap[x][y] = 0
                            }
                        }
                        this.clientGameLogic.clientRenderer
                            .map()
                            .updateMinimap(this.clientGameLogic.tilemapMetaProvider)
                    }
                }

                points.forEach(point => {
                    if (
                        this.clientGameLogic.tilemapMetaProvider &&
                        this.clientGameLogic.tilemapMetaProvider.minimapConfig?.biomeBased &&
                        this.clientGameLogic.tilemapMetaProvider.biomeColorMap
                    ) {
                        this.clientGameLogic.tilemapMetaProvider.biomeColorMap[point.biomeCoordinate.x][
                            point.biomeCoordinate.y
                        ] = point.color
                    }
                    this.clientGameLogic.clientRenderer.map().updateBiomeColorMap(point.biomeCoordinate, point.color)
                })

                icons.forEach(icon => {
                    if (this.clientGameLogic.tilemapMetaProvider) {
                        if (
                            !this.clientGameLogic.tilemapMetaProvider.locationIcons.find(
                                next => next.x === icon.x && next.y === icon.y,
                            )
                        ) {
                            this.clientGameLogic.tilemapMetaProvider.locationIcons.push(icon)
                        }
                    }
                    this.clientGameLogic.clientRenderer.entity().updateLocationIcons(icon)
                })
            }
            if (payloadType === Protocol.type_pulseInventoryItem) {
                const { itemId } = payload
                this.clientGameLogic.clientRenderer.gui().pulseInventoryItem(itemId)
            }
            if (payloadType === Protocol.type_entityFeedback) {
                const { entityId, image, label, params } = payload
                const entity = this.clientGameLogic.entityManager.get(entityId)
                if (entity) {
                    this.clientGameLogic.clientRenderer.entity().feedback(entity, image, label, params)
                }
            }
            if (payloadType === Protocol.type_sendVisibility) {
                const { visibility } = payload
                const currentVisibility = this.clientGameLogic.clientRenderer.map().getVisibility()
                this.clientGameLogic.clientRenderer.map().update(null, {
                    ...(currentVisibility || {}),
                    ...visibility,
                })
            }
            if (payloadType === Protocol.type_locationIconEffect) {
                const { locationId, expires } = payload

                this.clientGameLogic.clientRenderer.entity().pulseLocation(locationId, expires)
            }
            if (payloadType === Protocol.type_updateAudio) {
                const { volume } = payload

                this.clientGameLogic.audio.setVolume(volume)
            }
            if (payloadType === Protocol.type_sendBuildingTilePreview) {
                if (payload.tooFar) {
                    this.clientGameLogic.playerController.clearTerrainPointer()
                } else {
                    this.clientGameLogic.playerController.updateTerrainPointer(payload.deltas)
                }
            }
        }
    }

    emitPlayerEvent = (playerEvent: PlayerEvent) => {
        this.send(playerEvent, Protocol.type_playerEvent)
    }

    startAttack = (entityId: EntityId) => {
        this.send(
            {
                entityId,
            },
            Protocol.type_startAttack,
        )
    }

    inventoryAction = (entityId: EntityId, itemId: string, action: InventoryAction) => {
        this.send(
            {
                entityId,
                itemId,
                action,
            },
            Protocol.type_inventoryAction,
        )
    }

    activateWorldPoint = (roomFinePoint: Coordinate, options?: BuildingPointOptions) => {
        this.send(
            {
                roomFinePoint,
                options,
            },
            Protocol.type_activateWorldPoint,
        )
    }

    commitWorldEdits = (roughCoordinate?: Coordinate) => {
        this.send(
            {
                roughCoordinate,
            },
            Protocol.type_commitWorldEdits,
        )
    }

    getBuildingTilePreview = (roughCoordinate: Coordinate) => {
        this.send(
            {
                roughCoordinate,
            },
            Protocol.type_getBuildingTilePreview,
        )
    }

    requestEntityDetail = (entityIds: EntityId[]) => {
        this.send(
            {
                entityIds,
            },
            Protocol.type_requestEntityDetail,
        )
    }

    requestWorldMap = () => {
        this.send({}, Protocol.type_requestWorldMap)
    }

    worldMapClicked = (coordinate: Coordinate) => {
        this.send({ coordinate }, Protocol.type_worldMapClicked)
    }

    requestVisibility = () => {
        this.send({}, Protocol.type_requestVisibility)
    }

    itemPickupAction = () => {
        this.send({}, Protocol.type_requestItemPickup)
    }

    saveAppearance = (appearance: Appearance, inventory?: Inventory) => {
        this.send(
            {
                appearance,
                inventory,
            },
            Protocol.type_saveAppearance,
        )
    }

    finalizeCharacter = () => {
        this.send({}, Protocol.type_finalizeCharacter)
    }

    requestPlayerLocations = () => {
        this.send({}, Protocol.type_requestPlayerLocations)
    }

    onDialogClose = () => {
        this.emitPlayerEvent({
            playerEventType: "DIALOG_CLOSE",
            context: {},
        })
    }

    entityTileStrike = (location: Coordinate) => {
        this.send(
            {
                location,
            },
            Protocol.type_entityTileStrikeAt,
        )
    }
}
