import { ScreenMessage } from "game-common/models"
import { Graphics } from "pixi.js"

import { GuiManager } from "../gui_manager"
import { DynamicDialog } from "./dynamic_dialog"
import { Fader } from "./fader"

export class FeatureWidget extends Graphics {
    gui: GuiManager
    surface: Graphics
    fader: Fader

    constructor(gui: GuiManager) {
        super()

        this.gui = gui
    }

    remove = () => {
        if (this.surface) {
            this.fader?.stop()
            this.removeChild(this.surface)
        }
    }

    setup = (screenMessage: ScreenMessage) => {
        if (screenMessage.action === "close") {
            if (this.surface) {
                this.fader = new Fader(this.surface, {
                    fadeInterval: 5,
                    releaseCallback: () => {
                        this.removeChild(this.surface)
                    },
                })
                this.fader.update()
            }

            return
        }

        if (this.surface) {
            this.fader?.stop()
            this.removeChild(this.surface)
        }
        this.surface = DynamicDialog.createContent(this.gui.logic, screenMessage, this.width, [], {
            backdrop: true,
        })
        this.surface.x = -150
        this.addChild(this.surface)
        const interval = setInterval(() => {
            this.surface.x += 5
            if (this.surface.x >= 0) {
                this.surface.x = 0
                clearInterval(interval)
            }
        }, 10)
    }
}
