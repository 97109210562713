import { EntityTileCharacteristics } from "../item/item_entity_tiles"
import { EntityTileDamageSpecifics, EntityTilesDamageDefaults } from "../item/item_entity_tiles_damage"
import { EntityTile } from "../map/map"
import { WeaponType } from "../models"

export class EntityTileMechanics {
    static canRegen = (entityTile: EntityTile) => {
        // can regen if there is a growth sequence defined
        return EntityTileCharacteristics[entityTile]?.growthSequence?.length > 0
    }

    static hpDecrementOnHit = (entityTile: EntityTile, weaponType: WeaponType) => {
        const specificDamage = EntityTileDamageSpecifics[entityTile]?.find(
            next => next.weaponType === weaponType,
        )?.damage
        if (specificDamage !== undefined) {
            return specificDamage
        }
        const defaultDamage = EntityTilesDamageDefaults[entityTile]
        if (defaultDamage !== undefined) {
            return defaultDamage
        }

        return 0
    }
}
