import { CollisionResult } from "./models"
import { ObjectPool, ObjectPoolItem } from "./object_pool"
import { blankCoordinate, clearCoordinate } from "./util"

export interface CollisionResultPoolItem extends ObjectPoolItem<CollisionResult> {}
export class CollisionResultPool extends ObjectPool<CollisionResult> {
    static instance: CollisionResultPool = new CollisionResultPool()

    constructor(size: number = 1000) {
        super(size)
    }

    create(): CollisionResultPoolItem {
        return {
            free: true,
            data: {
                adjust: {
                    x: -1,
                    y: -1,
                },
                collided: false,
                collision: {
                    x: -1,
                    y: -1,
                },
                intersection: {
                    x: -1,
                    y: -1,
                    intersectionType: "",
                },
            },
        }
    }

    clear(item: CollisionResultPoolItem) {
        clearCoordinate(item.data.adjust)
        ;(item.data.collided = false), clearCoordinate(item.data.collision)
        clearCoordinate(item.data.intersection)
        item.data.intersection.intersectionType = ""
    }
}
