import { Entity, EntityDimensions, Location, Movement, Weapon, WeaponCharacteristics } from "./models"
import { degrees_to_radians, radians_to_degrees, randomId } from "./util"

export const emitWeaponParticles = (weapon: Weapon, originLocation: Location, originMovement: Movement): Entity[] => {
    const characteristics = WeaponCharacteristics[weapon?.weaponType || "none"]
    if (!characteristics) {
        return []
    }

    const originLocationToUse = {
        ...originLocation,
    }
    if (characteristics.muzzleFireOffset) {
        const speed2X = characteristics.muzzleFireOffset * Math.cos(originMovement.angle)
        const speed2Y = characteristics.muzzleFireOffset * Math.sin(originMovement.angle)
        originLocationToUse.x += speed2X
        originLocationToUse.y += speed2Y
    }

    if (characteristics.maxParticles === 1) {
        const bullet = createNewBullet(weapon, originLocationToUse, originMovement)
        bullet.spriteId = characteristics.bulletSpriteId
        bullet.flags.originWeaponType = weapon?.weaponType || "none"
        return bullet ? [bullet] : []
    }

    const bullets = []
    const originAngle = originMovement.angle
    const bulletBatch = randomId()

    const particles = characteristics.maxParticles
    for (let i = -(particles / 2); i < particles - particles / 2; i++) {
        let degrees = radians_to_degrees(originAngle) + 15 + i * 30
        const bullet = createNewBullet(weapon, originLocationToUse, {
            ...originMovement,
            angle: degrees_to_radians(degrees),
        })
        if (bullet) {
            bullet.flags.originWeaponType = weapon?.weaponType || "none"
            bullet.spriteId = characteristics.bulletSpriteId
            bullet.context = { bullet: { batchId: bulletBatch } }
            bullets.push(bullet)
        }
    }
    return bullets
}

/**
 * return an un-id'd bullet
 */
export const createNewBullet = (
    weapon: Weapon,
    originLocation: Location,
    originMovement: Movement,
): Entity | undefined => {
    if (!originLocation || !originMovement) {
        return
    }
    const characteristics = WeaponCharacteristics[weapon?.weaponType || "none"]
    if (!characteristics) {
        return
    }
    const bullet = new Entity()
    bullet.maxDistance = characteristics.maxDistance
    bullet.entityType = "bullet"
    bullet.origin = {
        x: originLocation.x + Math.cos(originMovement.angle),
        y: originLocation.y + Math.sin(originMovement.angle),
    }
    bullet.location = { x: bullet.origin?.x, y: bullet.origin?.y }
    bullet.movement = { ...originMovement }
    bullet.dimensions = { ...EntityDimensions[bullet.entityType] }

    bullet.speed = characteristics.bulletSpeed
    bullet.speed2.x = Math.cos(originMovement.angle) * characteristics.bulletSpeed
    bullet.speed2.y = Math.sin(originMovement.angle) * characteristics.bulletSpeed
    bullet.roomId = originLocation.roomId
    bullet.flags = {}

    return bullet
}
