import {
    ActionType,
    Armor,
    ArmorColors,
    BauldronColors,
    Cape,
    CapeColors,
    Color,
    Eyes,
    EyesColors,
    Hair,
    HairColors,
    Nose,
    NoseColors,
    Pants,
    PantsColors,
    Race,
    RaceColors,
    Sex,
    Shield,
    ShieldColors,
    Shirt,
    ShirtColors,
    Shoe,
    ShoeColors,
    Weapon,
    WeaponColors,
} from "../character/character"
import { Bauldron } from "../item/item_type"
import { Appearance, Dimensions } from "../models"
import {
    LpcBodyDescriptor,
    LpcCharacterDescriptorCollection,
    LpcComponentDescriptor,
    LpcSheetCollection,
    LpcSpritesheetDescriptors,
} from "./lpc"

export const LpcRaceDimensions: Partial<Record<Race, Dimensions>> = {
    horse: {
        w: 128,
        h: 128,
    },
    snake: {
        w: 64,
        h: 64,
    },
    slime: {
        w: 64,
        h: 64,
    },
    wasp: {
        w: 32,
        h: 32,
    },
    spikes1: {
        w: 32,
        h: 32,
    },
    redspider: {
        w: 64,
        h: 64,
    },
    deer: {
        w: 128,
        h: 128,
    },
    rabbit: {
        w: 72,
        h: 72,
    },
    chicken: {
        w: 32,
        h: 32,
    },
}

const toLpcVariant = (race: Race, color: Color): string => {
    const raceColors = RaceColors[race] || []
    // pick the default one if its not one of the supported colors
    const resolvedColor = raceColors.includes(color) ? color : raceColors[0]

    switch (race) {
        case "orc":
            return resolvedColor === "green" ? "green_1" : resolvedColor === "lightgreen" ? "green_3" : resolvedColor
        case "reptile":
        case "reptile-winged": {
            return race === "reptile" ? `${resolvedColor}_unwinged` : `${resolvedColor}_winged`
        }
        case "skeleton": {
            return resolvedColor === "white" ? "beige" : resolvedColor
        }
    }

    return resolvedColor === "tan" ? "tanned" : resolvedColor
}

const toLpcRace = (race: Race): string => {
    switch (race) {
        case "orc":
            return "orcs"
        case "reptile-winged":
        case "reptile":
            return "reptiles"
        case "wolfman":
            return "wolf"
    }
    return race
}

export const forgeLpcBodySpritesheetDescriptors = (body: LpcBodyDescriptor): LpcSheetCollection => {
    if (!body) {
        return []
    }

    const { race, sex, color } = body
    const lpcVariant = toLpcVariant(race, color)
    const lpcRace = toLpcRace(race)

    const descriptors: LpcSheetCollection = []

    if (race === "skeleton") {
        descriptors.push({
            entityId: `body/skeleton/${lpcVariant}.png`,
            zIndex: 10,
        })
    } else if (race === "zombie") {
        descriptors.push({
            entityId: `body/male/special/zombie.png`,
            zIndex: 10,
        })
    } else if (race === "wolfman") {
        descriptors.push({
            entityId: `body/${sex}/${lpcRace}/${lpcVariant}.png`,
            zIndex: 10,
        })
        descriptors.push({
            entityId: `body/${sex}/${lpcRace}/head/${lpcVariant}.png`,
            zIndex: 100,
        })
    } else if (race === "horse") {
        descriptors.push({
            entityId: `body/${sex}/${lpcRace}/burdened/${lpcVariant}.png`,
            zIndex: 10,
        })
    } else if (race === "spikes1") {
        descriptors.push({
            entityId: `spike/spikes1.png`,
            zIndex: 10,
        })
    } else {
        descriptors.push({
            entityId: `body/${sex}/${lpcRace}/${lpcVariant}.png`,
            zIndex: 10,
        })
    }

    return descriptors
}

export const getWeaponToOversizeState = (weapon: Weapon) => {
    return ["longsword", "rapier", "mace", "scythe", "saber", "flail", "halberd", "waraxe"].includes(weapon)
}

export const forgeLpcWeaponSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Weapon>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }

    const { component, color } = descriptor

    const weaponColors = WeaponColors[component] || []
    // pick the default one if its not one of the supported colors
    const resolvedColor = weaponColors.includes(color) ? color : weaponColors[0]

    if (component === "bow") {
        const variant =
            resolvedColor === "gray"
                ? "10"
                : // its brown
                  "normal"

        return [
            {
                entityId: `weapon/bow/${variant}.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "crossbow") {
        return [
            {
                entityId: `weapon/thrust/${sex}/crossbow.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "spear") {
        const variant = resolvedColor === "brown" ? "spear_2" : `spear_${resolvedColor}`

        return [
            {
                entityId: `weapon/thrust/${sex}/${variant}.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "axe") {
        return [
            {
                entityId: `weapon/slash/${sex}/axe_2.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "warhammer") {
        return [
            {
                entityId: `weapon/slash/${sex}/warhammer.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "staff") {
        const variant = "simple_staff"
        return [
            {
                entityId: `weapon/thrust/${sex}/${variant}.png`,
                zIndex: 140,
            },
        ]
    }

    if (component === "dagger") {
        const variant = resolvedColor === "metal" ? "dagger" : `dagger_${resolvedColor}`

        return [
            {
                entityId: `weapon/slash/${sex}/${variant}.png`,
                zIndex: 140,
            },
        ]
    }

    // oversize weapons
    const weaponToOversizeState = getWeaponToOversizeState(component)
    if (weaponToOversizeState) {
        if (["flail", "halberd", "waraxe"].includes(component)) {
            return [
                {
                    entityId: `weapon/slash_oversize/${sex}/universal/${component}.png`,
                    zIndex: 140,
                },
                {
                    entityId: `weapon/slash_oversize/${sex}/${component}.png`,
                    zIndex: 150,
                    type: "oversize",
                },
            ]
        }

        return [
            {
                entityId: `weapon/slash_oversize_layered/all/universal/behind/${component}.png`,
                zIndex: 9,
            },
            {
                entityId: `weapon/slash_oversize_layered/all/universal/${component}.png`,
                zIndex: 140,
            },
            {
                entityId: `weapon/slash_oversize_layered/all/${component}.png`,
                zIndex: 150,
                type: "oversize",
            },
        ]
    }

    return []
}

export const forgeLpcHairSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Hair>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const hairColors = HairColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = hairColors.includes(color) ? color : hairColors[0]

    const lpcColor =
        resolvedColor === "brown" ? "dark_brown" : resolvedColor === "lightbrown" ? "light_brown" : resolvedColor

    return [
        {
            entityId: `hair/${descriptor.component}/${sex}/${lpcColor}.png`,
            zIndex: 120,
        },
    ]
}

export const forgeLpcNoseSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Nose>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = NoseColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    const lpcColor =
        resolvedColor === "white"
            ? "light"
            : resolvedColor === "tan"
            ? "tanned"
            : resolvedColor === "gray"
            ? "darkelf"
            : resolvedColor

    return [
        {
            entityId: `head/nose/${descriptor.component}/${sex}/${lpcColor}.png`,
            zIndex: 20,
        },
    ]
}

export const forgeLpcEyesSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Eyes>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    if (component === "cyclops") {
        return [
            {
                entityId: `eyes/${sex}/cyclope.png`,
                zIndex: 20,
            },
        ]
    }

    const colors = EyesColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    return [
        {
            entityId: `eyes/${sex}/${resolvedColor}.png`,
            zIndex: 20,
        },
    ]
}

export const forgeLpcPantsSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Pants>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = PantsColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    let zIndex = 20
    if (component === "pants" || component === "pantaloons") {
        return [
            {
                entityId: `legs/${component}/${sex}/${resolvedColor}.png`,
                zIndex,
            },
        ]
    }

    if (component === "pants-armor") {
        const lpcColor =
            resolvedColor === "gray"
                ? "7"
                : resolvedColor === "metal"
                ? "8"
                : resolvedColor === "yellow"
                ? "9"
                : resolvedColor === "gold"
                ? "8"
                : resolvedColor === "tan"
                ? "10"
                : resolvedColor === "orange"
                ? "11"
                : "7"

        return [
            {
                entityId: `legs/armour/${sex}/${lpcColor}.png`,
                zIndex,
            },
        ]
    }

    if (component === "skirts") {
        return [
            {
                entityId: `legs/${component}/${sex}/${resolvedColor}.png`,
                zIndex,
            },
        ]
    }

    if (component === "legion") {
        return [
            {
                entityId: `legs/skirts/${sex}/legion.png`,
                zIndex,
            },
        ]
    }

    return []
}

export const forgeLpcShirtSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Shirt>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = ShirtColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors?.includes(color) ? color : colors[0]

    if (!resolvedColor) {
        return []
    }

    let zIndex = 35
    let lpcColor: string = resolvedColor

    // if (component === "robe") {
    //     lpcColor = resolvedColor === "brown"
    //         ? "dark_brown"
    //         : resolvedColor === "lightbrown"
    //         ? "brown"
    //         : resolvedColor === "gray"
    //         ? "dark_gray"
    //         : resolvedColor === "lightgreen"
    //         ? "forest_green"
    //         : resolvedColor
    // }
    if (component === "corset") {
        lpcColor = resolvedColor === "brown" ? "leather" : resolvedColor
    }

    return [
        {
            entityId: `torso/clothes/${component}/${sex}/${lpcColor}.png`,
            zIndex,
        },
    ]
}

export const forgeLpcArmorSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Armor>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = ArmorColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    let zIndex = 60
    let lpcColor: string = resolvedColor
    let lpcItem = component.replace("armor-", "")

    if (component === "armor-plate") {
        lpcColor =
            resolvedColor === "gray"
                ? "11"
                : resolvedColor === "metal"
                ? "12"
                : resolvedColor === "yellow"
                ? "12"
                : resolvedColor === "gold"
                ? "14"
                : resolvedColor === "orange"
                ? "16"
                : resolvedColor === "tan"
                ? "special"
                : resolvedColor
    }
    if (component === "armor-legion") {
        lpcColor = resolvedColor === "brown" ? "bronze" : resolvedColor === "gray" ? "steel" : resolvedColor
    }

    return [
        {
            entityId: `torso/armour/${lpcItem}/${sex}/${lpcColor}.png`,
            zIndex,
        },
    ]
}

export const forgeLpcShoeSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Shoe>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = ShoeColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    let zIndex = 15
    let lpcColor: string = resolvedColor
    let lpcItem: string = component

    if (component === "boots") {
        zIndex = 25
    }

    if (component === "shoes-armor") {
        lpcItem = "armor"
        lpcColor =
            resolvedColor === "gray"
                ? "4"
                : resolvedColor === "metal"
                ? "5"
                : resolvedColor === "yellow"
                ? "6"
                : resolvedColor === "gold"
                ? "7"
                : resolvedColor === "tan"
                ? "8"
                : resolvedColor === "orange"
                ? "9"
                : resolvedColor
    }

    return [
        {
            entityId: `feet/${lpcItem}/${sex}/${lpcColor}.png`,
            zIndex,
        },
    ]
}

export const forgeLpcShieldSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Shield>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = ShieldColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    let zIndex = 141
    let lpcColor: string = resolvedColor
    let lpcItem = component.replace("shield-", "")

    if (lpcItem === "crusader" || lpcItem === "spartan") {
        return [
            {
                entityId: `shield/${sex}/${lpcItem}.png`,
                zIndex,
            },
        ]
    }

    return [
        {
            entityId: `shield/${sex}/${lpcItem}_${lpcColor}.png`,
            zIndex,
        },
    ]
}

export const forgeLpcCapeSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Cape>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = CapeColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    let lpcColor: string = resolvedColor
    let lpcItem: string = component.replace("cape-", "")

    if (component === "cape-solid") {
        return [
            {
                entityId: `cape/${lpcItem}/${sex}/${lpcColor}.png`,
                zIndex: 85,
            },
            {
                entityId: `cape/${lpcItem}_behind/${lpcColor}.png`,
                zIndex: 5,
            },
        ]
    }

    if (component === "cape-tattered") {
        return [
            {
                entityId: `cape/${lpcItem}/${sex}/${lpcColor}.png`,
                zIndex: 85,
            },
            {
                entityId: `cape/${lpcItem}_behind/${lpcColor}.png`,
                zIndex: 5,
            },
        ]
    }

    if (component === "cape-trimmed") {
        return [
            {
                entityId: `cape/${lpcItem}/${sex}/white_blue.png`,
                zIndex: 85,
            },
            {
                entityId: `cape/${lpcItem}_behind/white_blue.png`,
                zIndex: 5,
            },
        ]
    }

    return []
}

export const forgeLpcBauldronSpritesheetDescriptors = (
    descriptor: LpcComponentDescriptor<Bauldron>,
    sex: Sex,
): LpcSheetCollection => {
    if (!descriptor) {
        return []
    }
    const { component, color } = descriptor

    const colors = BauldronColors[component]
    // pick the default one if its not one of the supported colors
    const resolvedColor = colors.includes(color) ? color : colors[0]

    return [
        {
            entityId: `${component}/${sex}/${resolvedColor}.png`,
            zIndex: 65,
        },
    ]
}

export const defaultActionFor = (appearance: Appearance): ActionType => {
    if (appearance.race === "spikes1") {
        return "inactive"
    }
}

export const forgeLpcSpritesheetDescriptors = ({
    body,
    weapon,
    hair,
    nose,
    eyes,
    sex,
    pants,
    shirt,
    armor,
    shoes,
    shield,
    cape,
    bauldron,
}: LpcCharacterDescriptorCollection): LpcSpritesheetDescriptors => {
    const bodyDescriptors = forgeLpcBodySpritesheetDescriptors(body)
    const weaponDescriptors = forgeLpcWeaponSpritesheetDescriptors(weapon, sex || body.sex)
    const hairDescriptors = forgeLpcHairSpritesheetDescriptors(hair, sex || body.sex)
    const noseDescriptors = forgeLpcNoseSpritesheetDescriptors(nose, sex || body.sex)
    const eyesDescriptors = forgeLpcEyesSpritesheetDescriptors(eyes, sex || body.sex)
    const pantsDescriptors = forgeLpcPantsSpritesheetDescriptors(pants, sex || body.sex)
    const shirtDescriptors = forgeLpcShirtSpritesheetDescriptors(shirt, sex || body.sex)
    const armorDescriptors = forgeLpcArmorSpritesheetDescriptors(armor, sex || body.sex)
    const shoeDescriptors = forgeLpcShoeSpritesheetDescriptors(shoes, sex || body.sex)
    const shieldDescriptors = forgeLpcShieldSpritesheetDescriptors(shield, sex || body.sex)
    const capeDescriptors = forgeLpcCapeSpritesheetDescriptors(cape, sex || body.sex)
    const bauldronDescriptors = forgeLpcBauldronSpritesheetDescriptors(bauldron, sex || body.sex)

    let descriptors: LpcSpritesheetDescriptors = {
        body: bodyDescriptors,
        weapon: weaponDescriptors,
        hair: hairDescriptors,
        pants: pantsDescriptors,
        shirt: shirtDescriptors,
        armor: armorDescriptors,
        shoes: shoeDescriptors,
        shield: shieldDescriptors,
        cape: capeDescriptors,
        bauldron: bauldronDescriptors,
        nose: noseDescriptors,
        eyes: eyesDescriptors,
    }

    return descriptors
}
