import { ItemEffects, ItemTypeMeta } from "../item/item"
import { Entity, LightMeta } from "../models"

export const EffectTypes = ["light", "webslow"] as const
export const persistableEffects: Effect[] = ["light"]

export type Effect = (typeof EffectTypes)[number]

export interface EffectDescriptor {
    effect: Effect
    clockDuration?: number
    activationClockTs?: number
    originalLightMeta?: LightMeta
}

export class EffectMechanics {
    static itemTypeToEffect = (itemTypeMeta: ItemTypeMeta): EffectDescriptor => {
        return ItemEffects[itemTypeMeta.type]
    }

    static getEntityEffects = (entity: Entity): EffectDescriptor[] => {
        return entity?.flags?.appliedEffects || []
    }

    static getEntityEffect = (entity: Entity, effect: Effect): EffectDescriptor => {
        return this.getEntityEffects(entity).find(appliedEffect => appliedEffect.effect === effect)
    }

    static removeEntityEffect = (entity: Entity, effect: Effect) => {
        if (!entity) {
            return
        }
        const effects = this.getEntityEffects(entity).filter(appliedEffect => appliedEffect.effect !== effect)
        entity.lastStatsUpdateTs = Date.now()
        entity.flags.appliedEffects = effects
    }

    static hasEntityEffect = (entity: Entity, effect: Effect) => {
        return !!this.getEntityEffects(entity).find(appliedEffect => appliedEffect.effect === effect)
    }

    static persistableEffects = (effect: Effect[]) =>
        effect ? effect.filter((n: Effect) => persistableEffects.includes(n)) : []

    static addEntityEffect = (entity: Entity, effectDescriptor: EffectDescriptor): EffectDescriptor[] => {
        if (!entity || ["bullet", "system"].includes(entity.entityType)) {
            return []
        }
        const effects = this.getEntityEffects(entity).filter(
            appliedEffect => appliedEffect.effect !== effectDescriptor.effect,
        )
        entity.lastStatsUpdateTs = Date.now()
        entity.flags.appliedEffects = [...effects, { ...effectDescriptor }]
        return entity.flags.appliedEffects
    }

    static contactEffectsFrom = (entity: Entity): Effect[] => {
        if (!entity) {
            return []
        }
        return entity.flags?.contactEffects || []
    }

    static isAffected = (entity: Entity, effect: Effect) => {
        const race = entity?.appearance?.race
        if (effect === "webslow") {
            if (race === "redspider") {
                return false
            }
        }

        return true
    }
}
