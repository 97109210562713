
import { ItemTypes } from "./item_type"
import { ItemTypeMeta } from "./item"
export const ItemTypesToMeta: Partial<Record<ItemTypes, ItemTypeMeta[]>> = {
    "armor-leather": [
        {
            "type": "armor-leather",
            "class": "armor",
            "color": "brown",
            "spriteId": null
        }
    ],
    "armor-legion": [
        {
            "type": "armor-legion",
            "class": "armor",
            "color": "brown",
            "spriteId": null
        }
    ],
    "bauldron": [
        {
            "type": "bauldron",
            "class": "bauldron",
            "color": "brown",
            "spriteId": null
        }
    ],
    "castlekey": [
        {
            "type": "castlekey",
            "class": "key",
            "color": "gold",
            "spriteId": null
        }
    ],
    "legion": [
        {
            "type": "legion",
            "class": "pants",
            "color": "brown",
            "spriteId": null
        }
    ],
    "arrow": [
        {
            "type": "arrow",
            "class": "resource",
            "color": "brown",
            "spriteId": null
        }
    ],
    "coal": [
        {
            "type": "coal",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:161"
        }
    ],
    "copper": [
        {
            "type": "copper",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:180"
        }
    ],
    "drumstick": [
        {
            "type": "drumstick",
            "class": "resource",
            "color": "brown",
            "spriteId": null
        }
    ],
    "feather": [
        {
            "type": "feather",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:72"
        }
    ],
    "flower-single-blue": [
        {
            "type": "flower-single-blue",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:104"
        }
    ],
    "flower-single-purple": [
        {
            "type": "flower-single-purple",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:103"
        }
    ],
    "flower-single-red": [
        {
            "type": "flower-single-red",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:105"
        }
    ],
    "flower-single-yellow": [
        {
            "type": "flower-single-yellow",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:106"
        }
    ],
    "gold": [
        {
            "type": "gold",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:182"
        }
    ],
    "hide": [
        {
            "type": "hide",
            "class": "resource",
            "color": "brown",
            "spriteId": null
        }
    ],
    "iron": [
        {
            "type": "iron",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:179"
        }
    ],
    "metal-bar-copper": [
        {
            "type": "metal-bar-copper",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:180"
        }
    ],
    "metal-bar-gold": [
        {
            "type": "metal-bar-gold",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:182"
        }
    ],
    "metal-bar-iron": [
        {
            "type": "metal-bar-iron",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:179"
        }
    ],
    "metal-bar-silver": [
        {
            "type": "metal-bar-silver",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:181"
        }
    ],
    "mushroom-purple": [
        {
            "type": "mushroom-purple",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:353"
        }
    ],
    "mushroom-red": [
        {
            "type": "mushroom-red",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:355"
        }
    ],
    "mushroom-yellow": [
        {
            "type": "mushroom-yellow",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:354"
        }
    ],
    "planks": [
        {
            "type": "planks",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:25"
        }
    ],
    "rope": [
        {
            "type": "rope",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:88"
        }
    ],
    "seed": [
        {
            "type": "seed",
            "class": "resource",
            "color": "na",
            "spriteId": "seed-green.png"
        }
    ],
    "shard-metal": [
        {
            "type": "shard-metal",
            "class": "resource",
            "color": "gray"
        }
    ],
    "silver": [
        {
            "type": "silver",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:181"
        }
    ],
    "stick": [
        {
            "type": "stick",
            "class": "resource",
            "color": "na",
            "spriteId": "ore:26"
        }
    ],
    "straw": [
        {
            "type": "straw",
            "class": "resource",
            "color": "na",
            "spriteId": "lpc-flowers-plants-fungi-wood:212"
        }
    ],
    "torch": [
        {
            "type": "torch",
            "class": "resource",
            "color": "na",
            "spriteId": null
        }
    ],
    "webAmmo": [
        {
            "type": "webAmmo",
            "class": "resource",
            "color": "white"
        }
    ],
    "wood": [
        {
            "type": "wood",
            "class": "resource",
            "color": "brown",
            "spriteId": null
        }
    ],
    "shield-crusader": [
        {
            "type": "shield-crusader",
            "class": "shield",
            "color": "white",
            "spriteId": null
        }
    ],
    "shield-kite": [
        {
            "type": "shield-kite",
            "class": "shield",
            "color": "white",
            "spriteId": null
        }
    ],
    "shield-round": [
        {
            "type": "shield-round",
            "class": "shield",
            "color": "brown",
            "spriteId": null
        }
    ],
    "campfire": [
        {
            "type": "campfire",
            "class": "structure",
            "color": "na",
            "spriteId": null
        }
    ],
    "chest-wood": [
        {
            "type": "chest-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "container:140"
        }
    ],
    "door-wood": [
        {
            "type": "door-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "fence_medieval:478"
        }
    ],
    "floor-wood": [
        {
            "type": "floor-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "floors:33"
        }
    ],
    "roof-wood": [
        {
            "type": "roof-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "fence_medieval:468"
        }
    ],
    "snare-rabbit": [
        {
            "type": "snare-rabbit",
            "class": "structure",
            "color": "na",
            "spriteId": "ore:89"
        }
    ],
    "stool-small": [
        {
            "type": "stool-small",
            "class": "structure",
            "color": "na",
            "spriteId": "furniture-blonde-wood:126"
        }
    ],
    "table-large-rough": [
        {
            "type": "table-large-rough",
            "class": "structure",
            "color": "na",
            "spriteId": "furniture-blonde-wood:458"
        }
    ],
    "table-mid-round": [
        {
            "type": "table-mid-round",
            "class": "structure",
            "color": "na",
            "spriteId": "furniture-blonde-wood:124"
        }
    ],
    "wall-wood": [
        {
            "type": "wall-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "fence_medieval:410"
        }
    ],
    "wall2-wood": [
        {
            "type": "wall2-wood",
            "class": "structure",
            "color": "na",
            "spriteId": "walls2:43"
        }
    ],
    "barrel1": [
        {
            "type": "barrel1",
            "class": "structure",
            "color": "na",
            "spriteId": "container:60"
        }
    ],
    "stackedBags": [
        {
            "type": "stackedBags",
            "class": "structure",
            "color": "na",
            "spriteId": "container:449"
        }
    ],
    "stool1": [
        {
            "type": "stool1",
            "class": "structure",
            "color": "na",
            "spriteId": "furniture-blond-wood:128"
        }
    ],
    "table-mid-rough": [
        {
            "type": "table-mid-rough",
            "class": "structure",
            "color": "na",
            "spriteId": "furniture-blonde-wood:141"
        }
    ],
    "strawBed": [
        {
            "type": "strawBed",
            "class": "structure",
            "color": "na",
            "spriteId": "build_atlas:684"
        }
    ],
    "strawBedSmall": [
        {
            "type": "strawBedSmall",
            "class": "structure",
            "color": "na",
            "spriteId": "build_atlas:684"
        }
    ],
    "dagger": [
        {
            "type": "dagger",
            "class": "weapon",
            "color": "brown",
            "spriteId": null
        },
        {
            "type": "dagger",
            "class": "weapon",
            "color": "metal",
            "spriteId": null
        }
    ],
    "web": [
        {
            "type": "web",
            "class": "weapon",
            "color": "white"
        }
    ]
}
