import { SquadState } from "game-common/mechanics/combat_mechanics"
import { Callback } from "game-common/models"
import { BitmapText, Graphics, Text } from "pixi.js"
import { SpriteMeta } from "../entity_rendering_manager"
import { GuiManager } from "../gui_manager"
import { BasicSprite } from "../sprites/basic_sprite"
import { TextRenderer } from "../text/text_renderer"

export class SquadWidget extends Graphics {
    guiManager: GuiManager

    constructor(guiManager: GuiManager) {
        super()

        this.guiManager = guiManager
    }

    renderSlot = (label: string, spriteMeta: SpriteMeta, selected: boolean, callback: Callback) => {
        const slot = new Graphics()

        const item = new BasicSprite(spriteMeta)
        item.interactive = true
        item.on("pointerdown", callback)
        slot.addChild(item)

        const labelObject = new TextRenderer().size("tiny").render(label) as Text

        labelObject.x = -20
        labelObject.y = -30
        if (selected) {
            labelObject.tint = 0x00ff00
        }
        slot.addChild(labelObject)

        return slot
    }

    update = (squadState: SquadState) => {
        // clear all
        this.removeChildren()

        if (squadState.count < 1) {
            return
        }

        const baseMeta: SpriteMeta = {
            animated: false,
            color: 0xffffff,
            name: "",
            scale: 1.0,
        }

        let i = -1
        const width = 40

        {
            i++
            const scale = 1
            const name = `squad-tome.png`
            const shortcutKey = `J`
            const callback = () => {
                // alert(squadState.stance)
            }

            const slot = this.renderSlot(
                shortcutKey,
                {
                    ...baseMeta,
                    color: baseMeta.color,
                    name,
                    scale,
                },
                false,
                callback,
            )

            slot.x = i * width
            this.addChild(slot)
        }

        {
            i++
            const scale = 1
            const name = `formation-${squadState?.formation || "random"}.png`
            const shortcutKey = `K`
            const callback = () => {
                // alert(squadState.stance)
            }

            const slot = this.renderSlot(
                shortcutKey,
                {
                    ...baseMeta,
                    color: baseMeta.color,
                    name,
                    scale,
                },
                false,
                callback,
            )

            slot.x = i * width
            this.addChild(slot)
        }

        {
            i++
            const scale = 1
            const name = `stance-${squadState?.stance || "defense"}.png`
            const shortcutKey = `L`
            const callback = () => {
                // alert(squadState.stance)
            }

            const slot = this.renderSlot(
                shortcutKey,
                {
                    ...baseMeta,
                    color: baseMeta.color,
                    name,
                    scale,
                },
                false,
                callback,
            )

            slot.x = i * width
            this.addChild(slot)
        }
    }
}
