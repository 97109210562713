import { Graphics } from "pixi.js"
import { BasicSprite } from "../sprites/basic_sprite"
import { Fader } from "./fader"
import { Throbber } from "./throbber"

export class IconMeter extends Graphics {
    color: number
    max: number
    iconBase: string
    current: number
    pulser: Throbber

    constructor(iconBase: string, max: number) {
        super()
        this.iconBase = iconBase
        this.max = max
    }

    update = (amt: number) => {
        if (this.max < 1) {
            return
        }
        if (amt === this.current) {
            return
        }
        this.current = amt
        this.removeChildren()
        // this.clear()

        const amtToUse = amt / 2

        if (amtToUse < 2) {
            if (!this.pulser) {
                this.pulser = new Throbber(this, {
                    permanent: true,
                    blinkInterval: 25,
                    releaseCallback: () => {
                        this.alpha = 1.0
                    },
                })
            }
            this.pulser.update()
        } else {
            this.pulser?.stop()
        }

        for (let i = 0; i < Math.floor(this.max / 2); i++) {
            const level =
                i > amtToUse ? "empty" : i === Math.floor(amtToUse) ? (amtToUse % 1 === 0 ? "empty" : "half") : "full"

            const iconFull: BasicSprite = new BasicSprite({
                name: `${this.iconBase}-${level}.png`,
                scale: 1.0,
            })
            iconFull.x = i * iconFull.width + 3
            iconFull.y = iconFull.height / 2 + 2
            this.addChild(iconFull)
        }
    }
}
