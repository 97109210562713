import { Eyes, Nose, Weapon as Weapons } from "game-common/character/character"

import { Color, Hair, Race, Sex } from "./character/character"
import { CollisionResultPool } from "./collision_result_pool"
import { CoordinatePool } from "./coordinate_pool"
import { ItemTypeMeta } from "./item/item"
import { EntityTile, MapDelta } from "./map/map"
import { Mechanics } from "./mechanics/mechanics"
import { DayPhase } from "./mechanics/physics_mechanics"
import { VectorLine } from "./ray_casting"
import { Skill } from "./skills/skills"
import { Faction } from "./social/faction"
import {
    blankCoordinate,
    clamp,
    emptyCoordinate,
    fillCoordinate,
    getAngle,
    isDiagonal,
    radiansToCardinalDirection,
    randomIntBetweenRng,
} from "./util"
import { MinmapConfig, TileMapMetaProvider } from "./tile_map_meta_provider"
import { Effect } from "./mechanics/effect_mechanics"
import { Ammo } from "./item/item_type"

export const TileSize: number = 32

export type RunLevel = "prod" | "dev"

export type Direction = "Up" | "Down" | "Left" | "Right" | "DownRight" | "DownLeft" | "UpRight" | "UpLeft"

export const BasicDirections: Direction[] = ["Up", "Down", "Left", "Right"]

export const Directions: Direction[] = [...BasicDirections, "DownRight", "DownLeft", "UpRight", "UpLeft"]

export type EntityId = string

export type EntityType = "player" | "bullet" | "npc" | "bomb" | "system"

export type NpcType =
    | "outpost"
    | "zombie"
    | "flag"
    | "goal"
    | "door"
    | "lock"
    | "sign"
    | "bomb"
    | "emp"
    | "health"
    | "mana"
    | "spawner"
    | "fire"
    | "light"
    | "collectable"
    | "marker"
    | "campfire"

export type SeekerType = "brute" | "astar"

export type WeaponType = "bomb" | "emp" | "none" | Weapons

export interface WeaponMeta {
    bulletSpeed?: number
    firingLatchMs?: number
    manaCost?: number
    maxDistance?: number
    attackRange?: number
    maxParticles?: number
    maxEntitiesHit?: number
    duration?: number
    equipOrder?: number
    muzzleFireOffset?: number
    slotVisible?: boolean
    baseDamage?: number
    bulletSpriteId?: string
    pushStrength?: number
    bulletType?: Ammo
}

export interface AmmoMeta {
    effects?: Effect[]
    spriteId?: string
    createNpcProps?: Partial<CreateNpcProps>
}

export interface AbilityMeta {
    equipOrder: number
    shortcutKey: string
    maxCarried?: number
}

export const WeaponCharacteristics: Record<WeaponType, WeaponMeta> = {
    emp: {
        maxDistance: TileSize * 8,
        manaCost: 6,
        duration: 3000,
        equipOrder: 2,
        slotVisible: true,
    },
    bomb: {
        equipOrder: 3,
        slotVisible: true,
    },
    none: {
        slotVisible: true,
        bulletSpeed: 200,
        firingLatchMs: 400,
        manaCost: 1,
        maxDistance: 25,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
        // bulletSpriteId: "arrow-brown.png",
    },
    dagger: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 450,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    mace: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 500,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    rapier: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 500,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    saber: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 500,
        manaCost: 3,
        maxDistance: 40,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 1.5,
        bulletSpriteId: "none",
    },
    scythe: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 500,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    longsword: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 550,
        manaCost: 3,
        maxDistance: 50,
        attackRange: 64,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 2,
        pushStrength: 1,
        // bulletSpriteId: "arrow-brown.png"
        bulletSpriteId: "none",
    },
    flail: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 250,
        manaCost: 3,
        maxDistance: 45,
        attackRange: 64,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 0.75,
        pushStrength: 1,
        maxEntitiesHit: 3,
        // bulletSpriteId: "arrow-brown.png"
        bulletSpriteId: "none",
    },
    halberd: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 500,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    waraxe: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 550,
        manaCost: 1,
        maxDistance: 40,
        attackRange: 64,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 1,
        pushStrength: 2,
        // bulletSpriteId: "arrow-brown.png"
        bulletSpriteId: "none",
    },
    spear: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 525,
        manaCost: 1,
        maxDistance: 25,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "none",
    },
    axe: {
        // slotVisible: true,
        // bulletSpeed: 150,
        // firingLatchMs: 500,
        // manaCost: 1,
        // maxDistance: 20,
        // maxParticles: 1,
        // equipOrder: 0,
        // baseDamage: 4,
        // bulletSpriteId: "arrow-brown.png"
        // // bulletSpriteId: "none"

        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 500,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 2,
        equipOrder: 0,
        baseDamage: 3,
        maxEntitiesHit: 2,
        bulletSpriteId: "none",
        // bulletSpriteId: "none"
    },
    warhammer: {
        slotVisible: true,
        bulletSpeed: 150,
        firingLatchMs: 600,
        manaCost: 1,
        maxDistance: 20,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 5,
        bulletSpriteId: "none",
        pushStrength: 2,
    },
    bow: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 400,
        manaCost: 1,
        maxDistance: 160,
        attackRange: 128,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "arrow-brown.png",
        bulletType: "arrow",
    },
    web: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 400,
        manaCost: 1,
        maxDistance: 160,
        attackRange: 128,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 0,
        bulletType: "webAmmo",
        bulletSpriteId: "web-white.png",
        pushStrength: 0,
    },
    crossbow: {
        slotVisible: true,
        bulletSpeed: 500,
        firingLatchMs: 400,
        manaCost: 1,
        maxDistance: 160,
        attackRange: 128,
        maxParticles: 1,
        equipOrder: 0,
        baseDamage: 1,
        bulletSpriteId: "arrow-brown.png",
    },
    staff: {
        slotVisible: true,
    },
}

export const AbilityCharacteristics: Record<AbilityType, AbilityMeta> = {
    bomb: {
        equipOrder: 0,
        shortcutKey: "b",
        maxCarried: 20,
    },
    heal: {
        equipOrder: 1,
        shortcutKey: "q",
        maxCarried: 3,
    },
    mana: {
        equipOrder: 2,
        shortcutKey: "e",
        maxCarried: 2,
    },
    speed: {
        equipOrder: 3,
        shortcutKey: "r",
    },
}

export type LeaderboardType = string

export interface LeaderboardMeta {
    items: LeaderboardMetaItem[]
    name: LeaderboardType
    ts: number
}

export interface LeaderboardMetaItem {
    name: string
    count: number
}

export interface ClientMeta {
    showRoomName: boolean
}

export const DefaultClientMeta: ClientMeta = {
    showRoomName: false,
}

export const EntityTypes: EntityType[] = ["player", "bullet", "npc", "bomb"]

export const EntityTypesPlusSystem: EntityType[] = [...EntityTypes, "system"]

export const ScreenDimensions: Dimensions = {
    w: 640,
    h: 360,
}

export const EntityDimensions: Record<EntityType, Dimensions> = {
    player: {
        w: 25,
        h: 25,
    },
    npc: {
        w: 25,
        h: 25,
    },
    bullet: {
        w: 30,
        h: 30,
    },
    bomb: {
        w: 20,
        h: 20,
    },
    system: {
        w: 0,
        h: 0,
    },
}

export type Orientation = "north" | "south" | "east" | "west"

export type EntityState = "live" | "dead"

export type BiomeColorMap = TileMapBase<number>

export interface WorldState {
    messages: Message[]
    scores?: Record<EntityId, number>
    tilemaps?: TileMapDirectory
    worldMetaDimensions?: WorldMetaDimensions
    tilemapMeta?: Tileset[]
    minimapConfig?: MinmapConfig
    visibility?: Visibility
    leaderboards?: LeaderboardMeta[]
    roomId?: EntityId
    roomName?: string
    dayPct?: number
    dayPhase?: DayPhase
    biomeColorMap?: BiomeColorMap
    locationIcons?: LocationIcon[]
    playerLocations?: Record<EntityId, Location>
    runLevel: RunLevel
}

export interface Coordinate {
    x: number
    y: number
}

export interface MapCoordinateReport extends Coordinate {
    intersectionType: MapIntersectionType
}

export class NamedCoordinate {
    id: string
    coordinate: Coordinate
    constructor(id: string, x: number, y: number) {
        this.id = id
        this.coordinate = { x, y }
    }
}

export interface Location extends Coordinate {
    roomId: string
    quadrantId?: string
    context?: any
}

export interface BiomeMapPoint {
    biomeCoordinate: Coordinate
    color: number
}

export interface Dimensions {
    w: number
    h: number
}

export interface NamedLocation {
    locationId: string
    roomId: string
    context?: any
}

export interface TeleportPipe {
    id?: EntityId
    a: Location
    b: NamedLocation
    dimensions: Dimensions
    exitOrientation?: Orientation
}

export interface Region extends NamedLocation, Location {
    bounds: Bounds
}

export interface LocationIcon extends Location {
    id?: string
    icon: string
    initialMapFeature?: boolean
    noProximitySpawn?: boolean
    name?: string
}

export interface GeographicFeatureLocation extends Location {
    id: string
    name: string
}

export interface MinimapMeta {
    points: BiomeMapPoint[]
    icons: LocationIcon[]
    clear?: boolean
}

export interface WorldMapPlayerLocation {
    location: Coordinate
    isViewer: boolean
    label: string
}

export interface WorldMapMeta {
    map: MinimapMeta
    dimensions?: Dimensions
    playerLocations: WorldMapPlayerLocation[]
    locationIcons: LocationIcon[]
    questMarkers: LocationIcon[]
    geographicFeatureLocations: GeographicFeatureLocation[]
}

export interface Path {
    id: string
    locations: Location[]
}

export interface Sign {
    location: Location
    label1: string
    flags?: {}
}

export interface ItemDescriptor {
    itemTypeMeta: ItemTypeMeta
    quantity?: number
}

export interface InventoryItem {
    id: string
    itemType: WeaponType
    itemTypeMeta?: ItemTypeMeta
    description?: string
    active?: boolean
    readied?: boolean
    weight?: number
    flags?: any
    quantity?: number
    hp?: number
}

export interface AbilityItem {
    abilityType: AbilityType
    quantity: number
}

export interface Inventory {
    items: InventoryItem[]
    abilities: Record<string, AbilityItem>
    lastUpdateTs: number
}

export interface OutpostMeta {
    fame: number
    skills: Skill[]
    defenders: number
}

export interface Message {
    entityType?: EntityType
    npcType?: NpcType
    spriteId?: string
    entityState?: EntityState
    press_time?: number
    direction?: Direction
    angle?: number
    input_sequence_number?: number
    entityId?: EntityId
    entityIds?: EntityId[]
    ownerEntityId?: EntityId
    position?: number[]
    last_processed_input?: number
    origin?: Coordinate
    speed?: number
    maxSpeed?: number
    speed2?: Coordinate
    creationTs?: number
    maxDistance?: number
    name?: string
    color?: number
    visible?: boolean
    hunger?: number
    maxHunger?: number
    hp?: number
    hpMax?: number
    mana?: number
    roomId?: EntityId
    canShootBullets?: boolean
    canLayBomb?: boolean
    accessToken?: string
    leaderboards?: LeaderboardMeta[]
    joinedTeam?: boolean
    score?: number
    scoreTillNextLevel?: number
    level?: number
    weapon?: Weapon
    movement?: Movement
    item?: string
    shortcutKey?: string
    entities?: Message[]
    paralyzeUntilTs?: number
    lastUpdateTs?: number
    inventory?: Inventory
    upgradePoints?: number
    appearance?: Appearance
    clickable?: boolean
    complete?: boolean
    expirationTs?: number
    quantity?: number
    lightMeta?: LightMeta
    spriteScale?: number
    leaderEntityId?: EntityId
    sigilIcon?: string
    dimensions?: Dimensions
    debug?: boolean
    afk?: boolean
    active?: boolean
}

export interface MessageEnvelope {
    recv_ts: number
    payloadType: string
    payload: Message | WorldState | any
}

export interface Movement {
    moving: boolean
    direction: Direction
    angle: number
}

export type FixtureId = string

export interface IdAware {
    entityId: string
}

export interface TypeAware {
    entityType: EntityType
}

export interface UpdateTsAware {
    lastUpdateTs: number | null
}

export type PlayerEventType =
    | "DIALOG_READ"
    | "DIALOG_CLOSE"
    | "UPGRADE_STAT"
    | "CONVERSATION_RESPONSE"
    | "REQUEST_INVENTORY"
    | "REQUEST_CRAFTING"
    | "REQUEST_TRADING"
    | "REQUEST_QUESTS"
    | "SQUAD_COMMAND"
    | "REQUEST_ENTITY_INTERACTIONS"
    | "ACTIVATE_ENTITY_INTERACTION"

export interface PlayerEvent {
    playerEventType: PlayerEventType
    context: {}
}

export interface LightMeta {
    radius: number
    intensity: number
}

export interface Light extends LightMeta, IdAware {
    location: Coordinate
}

export interface Visibility {
    mask?: VectorLine[]
    range?: number
    suppressMinimap?: boolean
    darkness?: number
    lastUpdateTs?: number
    autoVisibility?: boolean
    fullOcclusion?: boolean
}

export class IndexedMap<T extends IdAware> {
    private _map: Record<string, T> = {}
    private list: string[] = []

    clear = () => {
        this._map = {}
        this.list = []
    }

    get = (id: string) => {
        return this._map[id]
    }

    has = (id: string) => {
        return !!this.get(id)
    }

    includes = (id: string) => {
        return !!this.get(id)
    }

    getAtIdx = (idx: number) => {
        if (idx > this.list.length - 1) {
            return undefined
        }
        return this._map[this.list[idx]]
    }

    keys = () => Object.keys(this._map)

    contains = (id: string): boolean => !!this._map[id]

    size = () => this.list.length

    empty = () => this.size() < 1

    add = (item: T) => {
        return this.set(item.entityId, item)
    }

    set(id: string, item: T, unshift: boolean = false) {
        const isNew = !this._map[id]
        this._map[id] = item

        if (isNew) {
            if (unshift) {
                this.list.unshift(id)
            } else {
                this.list.push(id)
            }
        }
    }

    remove(id: string): T {
        const toRemove = this._map[id]
        if (toRemove) {
            delete this._map[id]
            this.list = this.list.filter(n => n !== id)
        }
        return toRemove
    }

    iterate = (visitor: Visitor<T>, predicate?: Predicate<T>) => {
        this.list.forEach(n => {
            if (predicate) {
                if (predicate(this._map[n])) {
                    visitor(this._map[n])
                }
            } else {
                visitor(this._map[n])
            }
        })
    }

    forEach = this.iterate

    map = <V>(op: (item: T, index?: number) => V) => {
        return this.list.map(k => this._map[k]).map(op)
    }

    push = (item: T) => {
        this.set(item.entityId, item)
    }

    filter = (predicate: Predicate<T>) => {
        return this.list.map(n => this._map[n]).filter(predicate)
    }

    find = (predicate: Predicate<T>): T | undefined => {
        const key = this.list.find(n => {
            const item = this._map[n]
            if (predicate(item)) {
                return true
            }
        })
        return key ? this._map[key] : undefined
    }

    collect = (predicate?: Predicate<T>): T[] => {
        if (!predicate) {
            return [...this.list.map(n => this._map[n])]
        }

        const collection: T[] = []
        this.iterate(item => {
            collection.push(item)
        }, predicate)
        return collection
    }
}

export interface IndexedMapItem<T> extends IdAware {
    data: T
}

export class LoopingIndexedMap<T extends IdAware> extends IndexedMap<T> {
    private ctr: number = -1

    next = (): T => {
        this.ctr++
        if (this.ctr > this.size() - 1) {
            this.ctr = 0
        }

        return this.getAtIdx(this.ctr)
    }
}

export class DoubleKeyedMap<KEY1, KEY2, VAL> {
    key1Map: Map<KEY1, VAL> = new Map()
    key2Map: Map<KEY2, VAL> = new Map()

    set = (val: VAL, key1?: KEY1, key2?: KEY2) => {
        if (key1) {
            this.key1Map.set(key1, val)
        }
        if (key2) {
            this.key2Map.set(key2, val)
        }
    }

    remove = (key: KEY1 | KEY2) => {
        this.key1Map.delete(key as any)
        this.key2Map.delete(key as any)
    }

    get = (key: KEY1 | KEY2): VAL => {
        const v1 = this.key1Map.get(key as any)
        const v2 = this.key2Map.get(key as any)
        return v1 || v2
    }

    getExact = (key: KEY1, key2: KEY1): VAL => {
        const v1 = this.key1Map.get(key as any)
        const v2 = this.key2Map.get(key2 as any)
        return v1 && v2 ? v1 : undefined
    }

    has = (key: KEY1 | KEY2): boolean => {
        return !!this.get(key)
    }
}

export class LightsContainer extends IndexedMap<Light> {
    lastUpdatedTs: number

    set(id: string, item: Light, unshift: boolean = false) {
        super.set(id, item, unshift)
        this.lastUpdatedTs = Date.now()
    }

    remove(id: string): Light {
        this.lastUpdatedTs = Date.now()
        return super.remove(id)
    }
}

export class Bounds {
    x1: number
    y1: number
    x2: number
    y2: number
    width: number
    height: number

    centerCoordinate: Coordinate = blankCoordinate()

    constructor(x1: number, y1: number, x2: number, y2: number) {
        this.x1 = x1
        this.y1 = y1
        this.x2 = x2
        this.y2 = y2
        this.width = this.x2 - this.x1
        this.height = this.y2 - this.y1

        this.centerCoordinate.x = Math.floor(this.x1 + this.width / 2)
        this.centerCoordinate.y = Math.floor(this.y1 + this.height / 2)
    }

    static from(x: number, y: number, width: number, height: number) {
        return new Bounds(x, y, x + width, y + height)
    }

    collision(other: Bounds): boolean {
        if (!other) {
            return false
        }
        return !(other.x1 > this.x2 || other.x2 < this.x1 || other.y1 > this.y2 || other.y2 < this.y1)
    }

    encloses(other: Bounds): boolean {
        return this.x1 <= other.x1 && this.x2 >= other.x2 && this.y1 <= other.y1 && this.y2 >= other.y2
    }

    hasNegative(): boolean {
        return this.x1 < 0 || this.x2 < 0 || this.y1 < 0 || this.y2 < 0
    }

    includes(other: Coordinate): boolean {
        return !(other.x > this.x2 || other.x < this.x1 || other.y > this.y2 || other.y < this.y1)
    }

    recompute() {
        this.width = this.x2 - this.x1
        this.height = this.y2 - this.y1

        this.centerCoordinate.x = Math.floor(this.x1 + this.width / 2)
        this.centerCoordinate.y = Math.floor(this.y1 + this.height / 2)
    }

    static createCenteredBounds = (x: number, y: number, width: number, height: number) => {
        return new Bounds(x - width / 2, y - height / 2, x + width / 2, y + height / 2)
    }

    update = (x: number, y: number) => {
        this.x1 = x - this.width / 2
        this.y1 = y - this.height / 2
        this.x2 = x + this.width / 2
        this.y2 = x + this.height / 2

        this.centerCoordinate.x = Math.floor(this.x1 + this.width / 2)
        this.centerCoordinate.y = Math.floor(this.y1 + this.height / 2)

        return this
    }

    center = (): Coordinate => {
        return this.centerCoordinate
    }

    clone = () => {
        return new Bounds(this.x1, this.y1, this.x2, this.y2)
    }

    toString = () => {
        return `${this.x1},${this.y1},${this.x2},${this.y2}`
    }

    expand = (amount: number) => {
        this.x1 -= amount
        this.y1 -= amount
        this.x2 += amount
        this.y2 += amount
        this.recompute()

        return this
    }

    shrink = (amount: number) => {
        this.x1 += amount
        this.y1 += amount
        this.x2 -= amount
        this.y2 -= amount
        this.recompute()

        return this
    }

    translate = (x: number, y: number) => {
        this.x1 += x
        this.x2 += x
        this.y1 += y
        this.y2 += y
        this.recompute()
    }

    isCorner = (x: number, y: number) => {
        if (this.x1 === x && this.y1 === y) {
            return true
        }
        if (this.x2 - 1 === x && this.y1 === y) {
            return true
        }
        if (this.x1 === x && this.y2 - 1 === y) {
            return true
        }
        if (this.x2 - 1 === x && this.y2 - 1 === y) {
            return true
        }

        return false
    }

    visitEdges = (visitor: (x: number, y: number) => void, edge?: Direction) => {
        for (let x = this.topLeft().x; x < this.topLeft().x + this.width; x++) {
            if (!edge || edge === "Up") {
                visitor(x, this.topLeft().y)
            }
            if (!edge || edge === "Down") {
                visitor(x, this.topLeft().y + this.height - 1)
            }
        }

        for (let y = this.topLeft().y; y < this.topLeft().y + this.height; y++) {
            if (!edge || edge === "Left") {
                visitor(this.topLeft().x + this.width - 1, y)
            }
            if (!edge || edge === "Right") {
                visitor(this.topLeft().x, y)
            }
        }
    }

    randomEdge = (edge: Direction): Coordinate | null => {
        if (edge === "Up") {
            return {
                x: randomIntBetween(this.topLeft().x, this.topLeft().x + this.width),
                y: this.topLeft().y,
            }
        }
        if (edge === "Down") {
            return {
                x: randomIntBetween(this.topLeft().x, this.topLeft().x + this.width),
                y: this.topLeft().y + this.height - 1,
            }
        }
        if (edge === "Left") {
            return {
                x: this.topLeft().x,
                y: randomIntBetween(this.topLeft().y, this.topLeft().y + this.height),
            }
        }
        if (edge === "Right") {
            return {
                x: this.topLeft().x + this.width,
                y: randomIntBetween(this.topLeft().y, this.topLeft().y + this.height),
            }
        }
        return null
    }

    getAllCoordinates = (): Coordinate[] => {
        const all = []
        for (let x = this.topLeft().x; x < this.topLeft().x + this.width; x++) {
            for (let y = this.topLeft().y; y < this.topLeft().y + this.height; y++) {
                all.push({ x, y })
            }
        }
        return all
    }

    edgesAreClose = (other: Bounds, threshold: number): boolean => {
        let failed = false
        this.visitEdges((x: number, y: number) => {
            if (failed) {
                return
            }
            other.visitEdges((oX: number, oY: number) => {
                if (failed) {
                    return
                }
                failed = getDistance(x, y, oX, oY) < threshold
            })
        })

        return failed
    }

    widen = (otherBounds: Bounds[]) => {
        otherBounds.forEach((other, i) => {
            if (i === 0) {
                this.x1 = other.x1
                this.y1 = other.y1
                this.x2 = other.x2
                this.y2 = other.y2
            } else {
                if (other.x1 < this.x1) {
                    this.x1 = other.x1
                }
                if (other.x2 > this.x2) {
                    this.x2 = other.x2
                }
                if (other.y1 < this.y1) {
                    this.y1 = other.y1
                }
                if (other.y2 > this.y2) {
                    this.y2 = other.y2
                }
            }
        })

        this.recompute()
    }

    topLeft = (): Coordinate => ({
        x: this.x1,
        y: this.y1,
    })

    topRight = (): Coordinate => ({
        x: this.x2,
        y: this.y1,
    })

    bottomLeft = (): Coordinate => ({
        x: this.x1,
        y: this.y2,
    })

    bottomRight = (): Coordinate => ({
        x: this.x2,
        y: this.y2,
    })

    widenViaCoordinate = (coordinate: Coordinate) => {
        if (this.x1 === -1 || this.y1 === -1 || this.x2 === -1 || this.y2 === -1) {
            this.x1 = coordinate.x
            this.y1 = coordinate.y
            this.x2 = coordinate.x
            this.y2 = coordinate.y
        } else {
            if (this.includes(coordinate)) {
                return
            }

            if (coordinate.x < this.x1) {
                this.x1 = coordinate.x
            }

            if (coordinate.y < this.y1) {
                this.y1 = coordinate.y
            }

            if (coordinate.x > this.x2) {
                this.x2 = coordinate.x
            }

            if (coordinate.y > this.y2) {
                this.y2 = coordinate.y
            }
        }

        this.recompute()
    }
}

export interface Appearance {
    race: Race
    bodyColor: Color
    hair?: Hair
    hairColor?: Color
    nose?: Nose
    noseColor?: Color
    eyes?: Eyes
    eyesColor?: Color
    sex: Sex
    inventoryAppearanceHash: string
}

export type EntityCollisionMap = Record<EntityId, EntityId[]>
export type CollisionResult = {
    adjust: Coordinate
    collided: boolean
    collision?: Coordinate
    intersection?: MapCoordinateReport
}
export type CollisionTester = (bounds: Bounds) => boolean
export type CollisionMover = (
    bounds: Bounds,
    translate: Coordinate,
    collisionResult: CollisionResult,
    allowAdjust?: boolean,
) => void
export interface ApplyInputResult {
    movedX: boolean
    movedY: boolean
    collision?: Coordinate
    intersection?: MapCoordinateReport
}
export const DefaultManaRecoveryDelay = 600
export const DefaultBoostCostLimit = 6
export const EntityManaRecoveryDelayLimit = 200
export const EntityBoostCostLimit = 2

export class EntityMeta {
    name: string
    entityType: EntityType
    location: Location
    npcType?: NpcType
    strikeable?: boolean
    scanner?: boolean
    shooter?: boolean
    seeker?: SeekerType
    scanPlayersOnly?: boolean
    patrolLocations?: Coordinate[]
    patrolPause?: number
    randomizedPauses?: boolean
    speed?: number
    damage?: number
    damagingOnContact?: boolean
    targetLocationThresholdDistance?: number
    flags?: {}
    context?: {}
    spriteId?: string
    clickable?: boolean
    faction?: Faction
    appearance?: Appearance
    leaderFlags?: {}
    lightMeta?: LightMeta
}

export interface Weapon {
    weaponType: WeaponType
}

export type AbilityType = "heal" | "mana" | "speed" | "bomb"

export interface DeviceInfo {
    type: "web" | "mobileWeb"
}

export type Chronotype = "diurnal" | "nocturnal" | "both"

export type AttackPattern = "default" | "swoop" | "v2"

export type AggroResponse = "default" | "pause"

export interface PsychProfile {
    hostility: number
    chronotype?: Chronotype
    attackPattern?: AttackPattern
    aggroResponse?: AggroResponse
    closeOnStun?: boolean
}

export interface EntityContext {
    outpostMeta: OutpostMeta
    collectionMeta: any
    lightingMeta: {
        max: LightMeta
        lastUpdateTs: number
    }
    locationIcons: Record<string, LocationIcon>
    bullet: {
        batchId: string
    }
    denizenContext: any
    convoMeta: any
    questContext: any
    questActiveMarkerId: string
    questsContainer: QuestsContainer
    introState: any
}

export type PartialEntityContext = Partial<EntityContext>

export type EntityFlags = Record<string, boolean | number | any>

export class Entity implements IdAware, TypeAware {
    maxSpeed: number = Mechanics.entity.speed.minimum
    speed: number = Mechanics.entity.speed.minimum
    speed2: Coordinate = { x: 0, y: 0 }

    movement: Movement
    simpleDir?: string
    entityId: EntityId
    accountId?: string
    entityType: EntityType
    ownerEntityId?: EntityId

    location: Coordinate
    origin?: Coordinate

    maxDistance?: number

    position_buffer = []
    input_sequence_number: number = 0
    pending_inputs: Message[] = []

    entityState: EntityState = "live"
    entityStateTs: number

    lastMoveTs: number = 0
    hitByEntityIdTs: Record<EntityId, number> = {}

    bounds: Bounds
    fixedBounds?: {
        x1: number
        y1: number
        x2: number
        y2: number
    }

    name?: string

    maxHp: number = 3
    hp: number = 3
    mana: number = 20
    maxMana: number = 20
    hunger: number = 20
    maxHunger: number = 20
    manaRecoveryDelay: number = 600
    boostPct: number = 0.0
    boostCost: number = DefaultBoostCostLimit
    weight: number = 0

    damage: number = 1
    damagingOnContact: boolean

    lastStruckByEntityId?: EntityId
    lastStruckTs?: number
    lastStruckEntityId?: EntityId
    lastStruckEntityTs?: number

    lastAlarmedByEntityId?: EntityId
    lastAlarmedTs?: number
    lastKilledTs?: number

    npcType: NpcType
    spriteId?: string
    spriteScale?: number
    strikeable?: boolean
    immoveable?: boolean

    color?: number
    visible: boolean = true
    creationTs?: number

    flags: EntityFlags = {}
    dimensions: Dimensions
    debug?: boolean

    roomId: EntityId
    lastUpdateTs: number | null = null
    lastStatsUpdateTs: number
    lastRoomUpdateTs: number = 0

    canShootBullets?: boolean
    canLayBomb?: boolean

    boosted?: boolean
    portable?: boolean
    joinedTeam?: boolean

    guest?: boolean
    score?: number
    scoreTilNextLevel?: number
    level?: number

    paralyzeUntilTs?: number
    npcSafeUntilTs?: number

    weapon: Weapon
    inventory?: Inventory

    leaderEntityId?: EntityId
    deviceInfo?: DeviceInfo

    upgradePoints: number
    appearance?: Appearance

    clickable?: boolean
    context?: PartialEntityContext

    serverOnly?: boolean
    expirationTs?: number
    persistable?: boolean

    faction?: Faction
    quantity: number

    carried: boolean

    spawnPoint?: Location

    lightMeta?: LightMeta

    movingBackwards?: boolean

    submerged?: boolean

    superuser?: boolean

    afk?: boolean

    active?: boolean

    constructor() {
        this.location = { x: -1, y: -1 }
        this.roomId = "tutorial1"
        this.origin = { x: -1, y: -1 }
        this.movement = {
            moving: false,
            direction: "Up",
            angle: 0,
        }
        this.position_buffer = []
        this.inventory = {
            items: [],
            abilities: {},
            lastUpdateTs: Date.now(),
        }
        this.upgradePoints = 0
        this.level = 1
    }

    static fromJson = (json: any): Entity => {
        if (!json) {
            return
        }
        const entity: Entity = new Entity()
        entity.fromJson(json)
        return entity
    }

    fromEntity = (entity: Entity) => {
        this.fromJson(JSON.parse(JSON.stringify(entity)))
    }

    fromJson = (json: any): Entity => {
        if (!json) {
            return
        }
        Object.keys(json).forEach(key => {
            const value = json[key]
            if (typeof this[key] !== "function") {
                this[key] = value
            }
        })
        const fixedBounds = json.fixedBounds
        this.bounds = undefined
        if (fixedBounds) {
            this.fixedBounds = fixedBounds
            this.bounds = new Bounds(fixedBounds.x1, fixedBounds.y1, fixedBounds.x2, fixedBounds.y2)
        }
    }

    serializeToString = (): string => {
        return `${this.entityId}|${this.entityType}|${this.npcType || ""}|${this.faction || ""}|${
            this.entityState || ""
        }|${this.location.x}|${this.location.y}|${this.visible === true}|${this.strikeable}|${
            this.leaderEntityId || ""
        }|${this.dimensions.w}|${this.dimensions.h}|${this.expirationTs}|${
            this.flags ? JSON.stringify(this.flags) : ""
        }|${this.fixedBounds ? JSON.stringify(this.fixedBounds) : ""}|${JSON.stringify(this.movement)}|${
            this.paralyzeUntilTs
        }|${this.npcSafeUntilTs}|${this.afk === true}|${this.carried === true}|${this.speed}|${this.maxSpeed}|${
            this.lastStruckByEntityId || ""
        }|${this.lastStruckTs}|${this.lastStruckEntityId || ""}|${this.lastStruckEntityTs}`
    }

    static fromString = (roomId: string, text: string): Entity => {
        if (!text) {
            return
        }
        const [
            entityId,
            entityType,
            npcType,
            faction,
            entityState,
            x,
            y,
            visible,
            strikeable,
            leaderEntityId,
            dimensionsW,
            dimensionsH,
            expirationTs,
            flags,
            fixedBounds,
            movement,
            paralyzeUntilTs,
            npcSafeUntilTs,
            afk,
            carried,
            speed,
            maxSpeed,
            lastStruckByEntityId,
            lastStruckTs,
            lastStruckEntityId,
            lastStruckEntityTs,
        ] = text.split("|")
        const entity: Entity = new Entity()

        entity.entityId = entityId
        entity.entityType = entityType as EntityType
        entity.npcType =
            npcType !== "null" && npcType !== "undefined" && npcType !== "" ? (npcType as NpcType) : undefined
        entity.faction =
            faction !== "null" && faction !== "undefined" && faction !== "" ? (faction as Faction) : undefined
        entity.entityState =
            entityState !== "null" && entityState !== "undefined" && entityState !== ""
                ? (entityState as EntityState)
                : undefined
        entity.leaderEntityId =
            leaderEntityId !== "null" && leaderEntityId !== "undefined" && leaderEntityId !== ""
                ? (leaderEntityId as EntityId)
                : undefined
        entity.expirationTs =
            expirationTs !== "null" && expirationTs !== "undefined" ? parseInt(expirationTs) : undefined
        entity.flags = flags !== "" ? JSON.parse(flags) : undefined
        entity.paralyzeUntilTs =
            paralyzeUntilTs !== "null" && paralyzeUntilTs !== "undefined" ? Number.parseInt(paralyzeUntilTs) : undefined
        entity.npcSafeUntilTs =
            npcSafeUntilTs !== "null" && npcSafeUntilTs !== "undefined" ? Number.parseInt(npcSafeUntilTs) : undefined
        entity.carried = carried !== "null" && carried !== "undefined" ? carried === "true" : undefined
        entity.afk = afk === "true"

        const location: Coordinate = {
            x: parseFloat(x),
            y: parseFloat(y),
        }
        entity.location = location
        entity.roomId = roomId
        entity.visible = visible === "true"
        entity.strikeable = strikeable === "true"

        entity.dimensions = {
            w: parseInt(dimensionsW),
            h: parseInt(dimensionsH),
        }

        if (fixedBounds) {
            entity.fixedBounds = JSON.parse(fixedBounds)
            const { x1, y1, x2, y2 } = entity.fixedBounds
            entity.bounds = new Bounds(x1, y1, x2, y2)
        }

        if (movement) {
            entity.movement = JSON.parse(movement)
        }
        entity.speed = Number(speed)
        entity.maxSpeed = Number(maxSpeed)

        entity.lastStruckByEntityId = lastStruckByEntityId
        entity.lastStruckTs =
            lastStruckTs !== "null" && lastStruckTs !== "undefined" ? parseInt(lastStruckTs) : undefined
        entity.lastStruckEntityId = lastStruckEntityId
        entity.lastStruckEntityTs =
            lastStruckEntityTs !== "null" && lastStruckEntityTs !== "undefined"
                ? parseInt(lastStruckEntityTs)
                : undefined

        return entity
    }

    getGrossOrientation = (): "LeftRight" | "UpDown" => {
        const direction = radiansToCardinalDirection(this.movement.angle)
        if (["Left", "Right"].includes(direction)) {
            return "LeftRight"
        } else {
            return "UpDown"
        }
    }

    getCollisionBounds = (_x?: number, _y?: number): Bounds => {
        if (this.fixedBounds) {
            return this.bounds
        }
        const x = _x || this.location.x
        const y = _y || this.location.y

        if (!this.bounds) {
            this.bounds = new Bounds(0, 0, 0, 0)
        }

        if (Mechanics.entity.body.calculateCollisionBounds(this, this.bounds)) {
            // computed!
        } else {
            this.bounds.x1 = x - this.dimensions.w / 2
            this.bounds.y1 = y - this.dimensions.h / 2
            this.bounds.x2 = x + this.dimensions.w / 2
            this.bounds.y2 = y + this.dimensions.h / 2
        }

        this.bounds.recompute()

        return this.bounds
    }

    getBounds = (_x?: number, _y?: number): Bounds => {
        if (this.fixedBounds) {
            return this.bounds
        }
        const x = _x || this.location.x
        const y = _y || this.location.y

        if (!this.bounds) {
            this.bounds = new Bounds(0, 0, 0, 0)
        }

        this.bounds.x1 = x - (this.dimensions?.w || TileSize) / 2
        this.bounds.y1 = y - (this.dimensions?.h || TileSize) / 2
        this.bounds.x2 = x + (this.dimensions?.w || TileSize) / 2
        this.bounds.y2 = y + (this.dimensions?.h || TileSize) / 2
        this.bounds.recompute()

        return this.bounds
    }

    updateDimensions = (w: number, h: number) => {
        this.dimensions = {
            w,
            h,
        }

        return this.getBounds()
    }

    travelledDistance = () => {
        return getDistance(this.origin?.x, this.origin?.y, this.location.x, this.location.y)
    }

    updateMana = (amt: number) => {
        this.mana = Math.max(0, Math.min(this.maxMana, this.mana + amt))
    }

    updateHp = (amt: number) => {
        this.hp = Math.max(0, Math.min(this.maxHp, this.hp + amt))
    }

    updateHunger = (amt: number) => {
        this.hunger = Math.max(0, Math.min(this.maxHunger, this.hunger + amt))
    }

    hungerPct = () => {
        return this.hunger / this.maxHunger
    }

    applyInput(
        angle: number | undefined,
        press_timeRaw: number,
        speed2: Coordinate | undefined,
        applyInputResult: ApplyInputResult,
        collisionMover?: CollisionMover,
        allowAdjust?: boolean,
        allowSlide?: boolean,
    ) {
        const a = !speed2 ? undefined : getAngle(speed2, { x: 0, y: 0 })
        const diagonal: boolean = a === undefined ? false : this.isPlayer() ? isDiagonal(a) : false
        const press_time = diagonal ? press_timeRaw * 0.7 : press_timeRaw

        let x = 0,
            y = 0
        let intersection = applyInputResult.intersection

        if (angle !== undefined) {
            this.movement.angle = angle
        }

        if (speed2 !== undefined) {
            if (allowSlide) {
                const xItem = CoordinatePool.instance.checkout()
                const yItem = CoordinatePool.instance.checkout()
                const resultX = CollisionResultPool.instance.checkout()
                const resultY = CollisionResultPool.instance.checkout()

                try {
                    x += speed2.x * press_time
                    xItem.data.x = x
                    xItem.data.y = 0
                    collisionMover(this.getBounds(), xItem.data, resultX.data, allowAdjust)
                    const { adjust: adjustX, collided: collidedX, collision: c1, intersection: i1 } = resultX.data

                    y += speed2.y * press_time
                    yItem.data.x = 0
                    yItem.data.y = y
                    collisionMover(this.getBounds(), yItem.data, resultY.data, allowAdjust)
                    const { adjust: adjustY, collided: collidedY, collision: c2, intersection: i2 } = resultY.data

                    if (collidedX && collidedY) {
                        applyInputResult.movedX = false
                        applyInputResult.movedY = false
                        applyInputResult.collision.x = c1.x
                        applyInputResult.collision.y = c2.y
                        return
                    }

                    this.location.x = Math.max(adjustX.x, 0)
                    this.location.y = Math.max(adjustY.y, 0)

                    this.lastMoveTs = x > 0 || y > 0 ? Date.now() : this.lastMoveTs

                    applyInputResult.movedX = !collidedX
                    applyInputResult.movedY = !collidedY
                    applyInputResult.collision.x = c1?.x
                    applyInputResult.collision.y = c2?.y

                    applyInputResult.intersection.x = (
                        !emptyCoordinate(i1) ? i1 : !emptyCoordinate(i2) ? i2 : undefined
                    )?.x
                    applyInputResult.intersection.y = (
                        !emptyCoordinate(i1) ? i1 : !emptyCoordinate(i2) ? i2 : undefined
                    )?.y
                    applyInputResult.intersection.intersectionType = (
                        !emptyCoordinate(i1) ? i1 : !emptyCoordinate(i2) ? i2 : undefined
                    )?.intersectionType
                } finally {
                    CoordinatePool.instance.release(xItem)
                    CollisionResultPool.instance.release(resultX)
                    CoordinatePool.instance.release(yItem)
                    CollisionResultPool.instance.release(resultY)
                }
            } else {
                const item = CoordinatePool.instance.checkout()
                const result = CollisionResultPool.instance.checkout()
                try {
                    x += speed2.x * press_time
                    y += speed2.y * press_time

                    item.data.x = x
                    item.data.y = y

                    collisionMover(this.getBounds(), item.data, result.data, allowAdjust)
                    const { adjust, collided, collision, intersection: i1 } = result.data

                    fillCoordinate(i1, intersection)
                    intersection.intersectionType = i1.intersectionType

                    if (collided) {
                        applyInputResult.movedX = false
                        applyInputResult.movedY = false
                        applyInputResult.collision.x = collision.x
                        applyInputResult.collision.y = collision.y
                        fillCoordinate(intersection, applyInputResult.intersection)
                        applyInputResult.intersection.intersectionType = intersection.intersectionType
                        return
                    }

                    this.location.x = Math.max(adjust.x, 0)
                    this.location.y = Math.max(adjust.y, 0)
                } finally {
                    CoordinatePool.instance.release(item)
                    CollisionResultPool.instance.release(result)
                }
            }

            this.lastMoveTs = x > 0 || y > 0 ? Date.now() : this.lastMoveTs
        }

        applyInputResult.movedX = true
        applyInputResult.movedY = true
    }

    isDead = () => this.entityState === "dead"
    markDead = () => (this.entityState = "dead")
    isPlayer = () => this.entityType === "player"
    isParalyzed = () => (this.paralyzeUntilTs || 0) > Date.now()
    isNpcSafe = () => (this.npcSafeUntilTs || 0) > Date.now()

    safeName = () => {
        let name = this.name

        if (!name) {
            name =
                this.appearance?.race ||
                (this.flags?.isCampfire ? "campfire" : undefined) ||
                (this.flags?.isFire ? "fire" : undefined) ||
                this.npcType ||
                this.entityType
            name = this.isPlayer() ? `${name} player` : name
        }

        const max = 15
        if (name?.length > max) {
            return name.substring(0, max - 3) + "..."
        }

        return name
    }

    resetHitByEntityIdTs = () => {
        this.hitByEntityIdTs = {}
    }

    canPersist = () => {
        return this.expirationTs === undefined && this.persistable === true
    }

    lightIntensity = () => {
        return this.npcType === "light" ? 0.8 : this.lightMeta ? this.lightMeta.intensity : 0.6
    }

    lightRadius = () => {
        return this.npcType === "light" ? 50 : this.lightMeta ? this.lightMeta.radius : 30
    }
}

export type CoordinateStructure<T> = Array<Array<T>>

export type TileMapBase<T> = CoordinateStructure<T>
export type TileMap = TileMapBase<string>
export type TileLayerMapping = { maptype: "tilemap" | "sprited" | "sky" | "skyTrigger" | "occlusion"; idx: number }
export type TileMapLayerMapping = Record<string, TileLayerMapping>
export type CollisionMap = Array<Array<0 | 1>>

export type Predicate<T> = (item: T) => boolean

export type Visitor<T> = (item: T) => void

export const getDistance = (x1: number, y1: number, x2: number, y2: number) => {
    let y = x2 - x1
    let x = y2 - y1

    return Math.sqrt(x * x + y * y)
}

export const getCoordinateDistance = (a: Coordinate, b: Coordinate) => getDistance(a.x, a.y, b.x, b.y)

export const getEntityDistance = (e1: Entity, e2: Entity): number => {
    return getDistance(e1.location.x, e1.location.y, e2.location.x, e2.location.y)
}

export const randomIntBetween = (min, max, options?: { seed?: string; rngFn?: Function }) => {
    // min and max included
    if (options?.seed) {
        return randomIntBetweenRng(min, max, options.seed)
    }

    const fnToUse = options?.rngFn || Math.random

    return Math.floor(fnToUse() * (max - min + 1) + min)
}

export const randomFloatBetween = (min: number, max: number) => {
    const str = (Math.random() * (max - min) + min).toFixed(3)
    return parseFloat(str)
}

export const pctChance = (
    pct: number,
    options?: {
        randomIntBetween?: Function
        seed?: string
    },
) => {
    // min and max included
    if (options?.randomIntBetween) {
        return options.randomIntBetween(0, 100) >= 100 - pct
    }

    if (options?.seed) {
        return randomIntBetween(0, 100, { seed: options.seed }) >= 100 - pct
    }

    return randomIntBetween(0, 100) >= 100 - pct
}

export const randomBool = () => {
    return pctChance(50)
}

export interface ScreenMessage {
    text?: string
    dynamicText?: (DynamicTextMeta | string)[]
    target?: string
    roomId?: EntityId
    entityId?: EntityId
    duration?: number
    delay?: number
    displayCallback?: Callback
    readEvent?: string
    forceTarget?: boolean
    progressive?: boolean
    action?: "close" | "openDynamicDialog"
    actionToken?: any
    context?: any
    columnCount?: number
    onCloseEmitEvent?: PlayerEvent
    suppressOnCloseEmitEvent?: boolean
    messageId?: string
}

export type TileType =
    | "blocking"
    | "hidden"
    | "dense"
    | "denseVertical"
    | "entityTile"
    | "deep"
    | "floor"
    | "occlusion"
    | "collideable"

export interface TileBreakageMeta {
    descriptors: {
        tilesetId: string
        compositeTileId: string
        thresholdLow: number
        thresholdHigh?: number
    }[]
}

export interface TileMeta {
    tileTypes: TileType[]
    compositeTileId?: string
    compositeTileParent?: boolean
    malleable?: boolean
    spriteId?: string
    entityTileId?: EntityTile
    renderZIdxOffset?: number
    renderYOffset?: number
    renderXOffset?: number
    variant?: string
    breakageMeta?: TileBreakageMeta
    allowedDirections?: Direction[]
    notAllowedDirections?: Direction[]
}

export const createBlankTileMeta = (): TileMeta => {
    return {
        tileTypes: [],
    }
}

// a tile can have many properties
// tileId -> tile properties
export type TileMapMeta = Record<string, TileMeta>

// a tileset can have many tiles
// and metadata about the tileset
export interface Tileset {
    width: number
    height: number
    tilesetId: string
    tiles: TileMapMeta
    firstGid: number
}

// tilesetId -> Tileset
export type TilesetDirectory = Record<
    string,
    {
        meta: TileMapMeta
        dimensions: Dimensions
    }
>

// a map can have many tilesets:
// mapId -> Tilesets
export type AtlasDirectory = Record<string, Tileset[]>

export interface LayerDescriptor {
    entityId: string
    layerClass: TileLayerMapping
    tilemap: TileMap
}

export interface MapSpriteCap {
    x: number
    y: number
    context?: any
}

export interface TileMapDirectory {
    mapId: string
    tilemaps: TileMap[]
    blocking: TileMap
    occlusion: TileMap
    entityTilemapIdx?: number
    entityTileLayerId?: string
    sky?: TileMap[]
    skyTrigger?: TileMap
    sprited?: TileMap[]
    spriteFragments?: FragmentSpriteMeta[]
    mapSpriteCaps: MapSpriteCap[]
    deltas: Record<string, Record<string, MapDelta>> // layer -> position coordinate -> map delta
    layerIdToTileMap?: TileMapLayerMapping
}

export type Biome = string

export interface WorldMetaDimensions {
    width: number
    height: number
    quadrantWidth: number
    quadrantHeight: number
    roomWidth: number
    roomHeight: number
    roomsCountX: number
    roomsCountY: number
    sectorWidth: number
    sectorHeight: number
}

export interface WorldMeta {
    id: string
    dimensions: WorldMetaDimensions
    roomBiomesDirectory: Record<string, Biome[]>
    biomesToRooms: Record<Biome, string[]>
    allBiomes: Biome[]
    biomeColorMap: TileMapBase<number>
    locationIconsMap: Record<string, LocationIcon>
    geographicFeatures: Record<string, GeographicFeature[]>
    mapFeatures: Record<string, MapFeature[]>
}

export type MapIntersectionType = "" | "blocking" | "entityTile" | "collideable"

export interface FragmentMapEntry {
    x: number
    y: number
    tilePos: number
    renderZIdxOffset?: number
    roofLayer: "below"
}

export interface FragmentSpriteMeta {
    id: string
    mapEntries: FragmentMapEntry[]
    width: number
    height: number
    location: Coordinate
    fineLocationNudge?: Coordinate
    debugTxt?: string
}

export interface NumberedTileMeta {
    tileMeta: TileMeta
    tileNumber: number
    children?: NumberedTileMeta[]
}

export const tiledCollisionTester = (tilemapMeta: TileMapMetaProvider): CollisionTester => {
    const { isCollisionAt } = tilemapMeta

    const func = (bounds: Bounds): boolean => {
        const x = clamp(Math.floor(bounds.x2 / TileSize), 0, tilemapMeta.width())
        const y1 = clamp(Math.floor(bounds.y1 / TileSize), 0, tilemapMeta.height())
        const y2 = clamp(Math.floor(bounds.y2 / TileSize), 0, tilemapMeta.height())
        for (let y = y1; y <= y2; y++) {
            if (x > tilemapMeta.width() - 1 || y > tilemapMeta.height() - 1) {
                continue
            }
            if (isCollisionAt(x, y)) {
                return true
            }
        }

        const x1 = clamp(Math.floor(bounds.x1 / TileSize), 0, tilemapMeta.width())
        const x2 = clamp(Math.floor(bounds.x2 / TileSize), 0, tilemapMeta.height())
        const y = clamp(Math.floor(bounds.y1 / TileSize), 0, tilemapMeta.height())
        for (let x = x1; x <= x2; x++) {
            if (x > tilemapMeta.width() - 1 || y > tilemapMeta.height() - 1) {
                continue
            }
            if (isCollisionAt(x, y)) {
                return true
            }
        }
    }

    return func
}

export const collisionMover = (tilemapMetaProvider: TileMapMetaProvider, allowAdjust?: boolean): CollisionMover => {
    const { isCollisionAt, intersectionAt } = tilemapMetaProvider

    const func = (bounds: Bounds, translate: Coordinate, result: CollisionResult) => {
        let blocked = false

        // going right
        if (translate.x > 0) {
            const x = clamp(Math.floor((bounds.x2 + translate.x) / TileSize), 0, tilemapMetaProvider.width())
            const y1 = clamp(Math.floor(bounds.y1 / TileSize), 0, tilemapMetaProvider.height())
            const y2 = clamp(Math.floor(bounds.y2 / TileSize), 0, tilemapMetaProvider.height())
            let adjust = undefined
            for (let y = y1; y <= y2; y++) {
                if (x > tilemapMetaProvider.width() - 1 || y > tilemapMetaProvider.height() - 1) {
                    continue
                }
                if (
                    isCollisionAt(
                        x,
                        y,
                        bounds.centerCoordinate.x + translate.x,
                        bounds.centerCoordinate.y + translate.y,
                        "Right",
                    )
                ) {
                    blocked = true
                    adjust = x * TileSize - 1
                    result.collision.x = x
                    result.collision.y = y
                }
            }
            if (!blocked) {
                bounds.x1 += translate.x
                bounds.x2 += translate.x
            } else if (allowAdjust) {
                bounds.x2 = adjust
                bounds.x1 = adjust - bounds.width
            }
        }

        // // going left
        if (translate.x < 0) {
            const x = clamp(Math.floor((bounds.x1 + translate.x) / TileSize), 0, tilemapMetaProvider.width())
            const y1 = clamp(Math.floor(bounds.y1 / TileSize), 0, tilemapMetaProvider.height())
            const y2 = clamp(Math.floor(bounds.y2 / TileSize), 0, tilemapMetaProvider.height())
            let adjust = undefined
            for (let y = y1; y <= y2; y++) {
                if (x > tilemapMetaProvider.width() - 1 || y > tilemapMetaProvider.height() - 1) {
                    continue
                }
                if (
                    isCollisionAt(
                        x,
                        y,
                        bounds.centerCoordinate.x + translate.x,
                        bounds.centerCoordinate.y + translate.y,
                        "Left",
                    )
                ) {
                    blocked = true
                    adjust = x * TileSize + TileSize + 1
                    result.collision.x = x
                    result.collision.y = y
                }
            }
            if (!blocked) {
                bounds.x1 += translate.x
                bounds.x2 += translate.x
            } else if (allowAdjust) {
                bounds.x1 = adjust
                bounds.x2 = adjust + bounds.width
            }
        }

        // going up
        if (translate.y < 0) {
            const x1 = clamp(Math.floor(bounds.x1 / TileSize), 0, tilemapMetaProvider.width())
            const x2 = clamp(Math.floor(bounds.x2 / TileSize), 0, tilemapMetaProvider.width())
            const y = clamp(Math.floor((bounds.y1 + translate.y) / TileSize), 0, tilemapMetaProvider.height())
            let adjust = undefined
            for (let x = x1; x <= x2; x++) {
                if (x > tilemapMetaProvider.width() - 1 || y > tilemapMetaProvider.height() - 1) {
                    continue
                }
                if (
                    isCollisionAt(
                        x,
                        y,
                        bounds.centerCoordinate.x + translate.x,
                        bounds.centerCoordinate.y + translate.y,
                        "Up",
                    )
                ) {
                    blocked = true
                    adjust = y * TileSize + TileSize + 1
                    result.collision.x = x
                    result.collision.y = y
                }
            }
            if (!blocked) {
                bounds.y1 += translate.y
                bounds.y2 += translate.y
            } else if (allowAdjust) {
                bounds.y1 = adjust
                bounds.y2 = adjust + bounds.height
            }
        }

        // going down
        if (translate.y > 0) {
            const x1 = clamp(Math.floor(bounds.x1 / TileSize), 0, tilemapMetaProvider.height())
            const x2 = clamp(Math.floor(bounds.x2 / TileSize), 0, tilemapMetaProvider.height())
            const y = clamp(Math.floor((bounds.y2 + translate.y) / TileSize), 0, tilemapMetaProvider.height())
            let adjust = undefined
            for (let x = x1; x <= x2; x++) {
                if (x > tilemapMetaProvider.width() - 1 || y > tilemapMetaProvider.height() - 1) {
                    continue
                }
                if (
                    isCollisionAt(
                        x,
                        y,
                        bounds.centerCoordinate.x + translate.x,
                        bounds.centerCoordinate.y + translate.y,
                        "Down",
                    )
                ) {
                    blocked = true
                    adjust = y * TileSize - 1
                    result.collision.x = x
                    result.collision.y = y
                }
            }
            if (!blocked) {
                bounds.y1 += translate.y
                bounds.y2 += translate.y
            } else if (allowAdjust) {
                bounds.y1 = adjust - bounds.height
                bounds.y2 = adjust
            }
        }

        result.collided = blocked

        const x = bounds.x1 + bounds.width / 2
        const y = bounds.y1 + bounds.height / 2

        const xx = clamp(Math.floor((bounds.x2 + translate.x) / TileSize), 0, tilemapMetaProvider.width())
        const yy = clamp(Math.floor((bounds.y1 + translate.y) / TileSize), 0, tilemapMetaProvider.height())

        const intersection = intersectionAt(xx, yy)

        if (intersection) {
            result.intersection.x = xx
            result.intersection.y = yy
            result.intersection.intersectionType = intersection
        }

        result.adjust.x = x
        result.adjust.y = y
    }

    return func
}

export class Latch {
    private ts: number
    threshold: number
    private expireCallback?: Function
    singleUse: boolean

    constructor(threshold: number, now?: number, expireCallback?: Function) {
        this.ts = now || 0
        this.threshold = threshold
        this.expireCallback = expireCallback
    }

    expired = (write: boolean = true): boolean => {
        const now = Date.now()
        if (now - this.ts > this.threshold) {
            if (!this.singleUse && write) {
                this.ts = now
            }
            if (this.expireCallback) {
                this.expireCallback()
            }
            return true
        }

        return false
    }

    update = (threshold: number) => {
        this.threshold = threshold
    }

    reset = () => (this.ts = Date.now())

    clear = () => (this.ts = 0)
}

export type Callback = () => void

export type TextDecoration = "underline" | "disabled"

export type DynamicTextType =
    | "text"
    | "button"
    | "button|dynamic"
    | "horizontalLine"
    | "sprite"
    | "grid"
    | "entity"
    | "container"

export interface DynamicTextMeta {
    text: string
    pause?: number
    link?: string
    context?: any
    clickEventEmitter?: PlayerEvent
    type?: DynamicTextType
    sameLine?: boolean
    textDecorations?: TextDecoration[]
    leftSpacer?: number
    leftMargin?: number
    topMargin?: number
    bottomMargin?: number
    spriteId?: string
    column?: number
    exactLocation?: Coordinate
    sameX?: boolean
    children?: DynamicTextMeta[]
    hoverText?: string[]
    font?: string
    scale?: number
    color?: number
}

export interface ItemDetailOption {
    title: string
    onClickContext: any
}

export interface ItemDetailMeta {
    id?: string
    title: string
    options: ItemDetailOption[]
    spriteId: string
    quantity?: number
    active?: boolean
    activeColor?: number
    hoverText?: string[]
}

export const SIGIL_FLAG: string = "sigil"

export type TutorialType = "hotbar" | "controlbar" | "map" | "companion"

export const MAX_READIED_COUNT = 6

export interface Soundscape {
    soundtrack?: string
    ambience?: string
}

export interface SubmittedFeedback {
    feedback: string
    email: string
}

export interface DungeonSpawnMeta {
    spawnerEntityId?: EntityId
    roomId: string
    roomJson: any
}

export interface AnimatedFeedbackParams {
    variant?: "default" | "static"
    color?: number
    font?: string
    alignment?: "center"
    duration?: number
    scrollSpeed?: number
    type?: "foot" | "vibrate"
    action?: "remove"
    id?: string
    immediate?: boolean
}

export interface CreateNpcProps {
    id?: EntityId
    location: Location
    type: NpcType
    spriteId?: string
    patrolLocations?: Coordinate[]
    patrolPause?: number
    portable?: boolean
    color?: number
    name?: string
    flags?: {}
    colliderRange?: number
    appearance?: Appearance
    hp?: number
    hunger?: number
    speed?: number
    weaponType?: WeaponType
    inventory?: Inventory
    clickable?: boolean
    context?: {}
    strikeable?: boolean
    msUntilExpiration?: number
    expirationTs?: number
    persistable?: boolean
    faction?: Faction
    angle?: number
    quantity?: number
    visible?: boolean
    lightMeta?: LightMeta
    leaderId?: EntityId
    dimensions?: Dimensions
    immoveable?: boolean
}

export interface BuildingPointOptions {
    delete?: boolean
}

export const tileMapIterator = <T>(tileMap: TileMapBase<T>, visitor: (x: number, y: number, t: T) => void) => {
    for (let y = 0; y < tileMap.length; y++) {
        for (let x = 0; x < tileMap[y].length; x++) {
            visitor(x, y, tileMap[y][x])
        }
    }
}

export interface GeographicFeature {
    biome: Biome
    biomeCoordinates: Coordinate[]
    center?: Coordinate
    name: string
    id: string
}

export type MapFeatureType = "cave"

export interface MapFeature {
    name: string
    id: string
    roomId: string
    globalLocation: Location
    type: MapFeatureType
}

export interface Descriptor {
    id: string
    description: string
}

export interface QuestObjective extends Descriptor {
    status?: "pending" | "active" | "complete"
    markerLocation?: Location
}

export interface Quest extends IdAware {
    ownerEntityId: EntityId
    triggerIds?: EntityId[]
    participantEntityIds: EntityId[]
    name: string
    descripton?: string
    icon?: LocationIcon
    objectives?: QuestObjective[]
    createdAt: number
    context?: any
    status: "active" | "complete"
}

export interface QuestsContainer {
    currentQuestId?: string
    activeQuests: Quest[]
    completedQuests: Quest[]
}
