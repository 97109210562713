
import { ItemCharacteristics, ItemClass } from "./item"
export const ItemClassCharacteristics: Partial<Record<ItemClass, ItemCharacteristics>> = {
    "armor": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "bauldron": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "cape": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "key": {
        "readyable": true,
        "exclusiveReadiness": true
    },
    "pants": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "resource": {
        "readyable": true,
        "exclusiveReadiness": true,
        "stackable": true,
        "disallowedClassesOnActive": [
            "weapon",
            "structure"
        ]
    },
    "shield": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "shirt": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "shoes": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true
    },
    "structure": {
        "readyable": true,
        "exclusiveReadiness": true,
        "stableAngleOnDrop": true,
        "unbuyable": true,
        "disallowedClassesOnActive": [
            "weapon",
            "resource"
        ]
    },
    "weapon": {
        "equippable": true,
        "readyable": true,
        "exclusiveReadiness": true,
        "hasDurability": true,
        "disallowedClassesOnActive": [
            "resource",
            "structure"
        ]
    }
}
