import { Entity } from "game-common/models"
import { Application, Graphics } from "pixi.js"

import { ClientGameLogic } from "../../../client_game_logic"
import { ClientRenderer } from "../../../client_models"
import { EntityRenderingManager } from "./entity_rendering_manager"
import { GuiManager } from "./gui_manager"
import { Keyboard } from "./input/keyboard"
import { Mouse } from "./input/mouse"
import { MapRenderingManager } from "./map_rendering_manager"
import { MinimapSurface } from "./minimap_surface"
import { ShadowRendering } from "./shadow_rendering"
import { WorldOverlaySurface } from "./world_overlay_surface"
import { ScalingParams } from "../../../client_util"

export class PixiJsClientRenderer implements ClientRenderer {
    static _app: Application
    private app: Application
    private _gui: GuiManager
    private _entity: EntityRenderingManager
    private _map: MapRenderingManager

    private _mouse: Mouse
    private _keyboard: Keyboard

    private _logic: ClientGameLogic
    private debugSurface: Graphics

    private debugVision = false
    private shadowRendering: ShadowRendering

    constructor(app: Application, logic: ClientGameLogic) {
        PixiJsClientRenderer._app = app
        this.app = app
        this._logic = logic
        const stage = this.app.stage
        stage.interactive = true
        stage.sortableChildren = true

        const surface = new Graphics()
        surface.sortableChildren = true
        stage.addChild(surface)
        surface.scale.set(ScalingParams.surfaceZoom)

        const worldOverlaySurface = new WorldOverlaySurface(logic)
        stage.addChild(worldOverlaySurface)

        const minimapSurface = new MinimapSurface(logic)
        this._gui = new GuiManager(stage, minimapSurface, logic)
        this._gui.init()

        ////////////////////////////////////////

        const skySurface = new Graphics()
        skySurface.sortableChildren = true
        skySurface.zIndex = 1000000

        this._map = new MapRenderingManager(surface, skySurface, minimapSurface, worldOverlaySurface, logic)
        this._map.init()

        this._entity = new EntityRenderingManager(surface, minimapSurface, worldOverlaySurface, logic, this, app)
        this._entity.init()

        this._mouse = new Mouse()
        this._keyboard = new Keyboard()

        const debugSurface = new Graphics()
        this.debugSurface = debugSurface
        debugSurface.sortableChildren = true

        surface.addChild(skySurface)
        stage.addChild(debugSurface)

        this.shadowRendering = new ShadowRendering(app, surface, this.debugVision, logic)
    }

    gui = () => this._gui
    entity = () => this._entity
    map = () => this._map
    mouse = () => this._mouse
    keyboard = () => this._keyboard
    logic = () => this._logic

    getApp = () => this.app

    updatePlayerLocation = (entity: Entity, force?: boolean) => {
        this._map.updatePlayerLocation(entity, force)
    }

    render = () => {
        // world
        this._map.renderTileMap()

        // entities
        this._logic.entityManager.iterate(null, entity => {
            this._entity.render(entity, this._logic.playerEntity.entityId)
        })

        this.shadowRendering.renderVisibility({
            location: this._logic.playerEntity.location,
            polyMap: this._map.getVisibilityMap(),
            visibility: this._map.getVisibility(),
            debug: this.debugVision ? this.debugSurface : undefined,
            lights: this._entity.getLights(),
        })
    }
}
