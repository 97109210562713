import { HumanoidRaceTypes, Race } from "../character/character"
import {
    AggroResponse,
    AttackPattern,
    Bounds,
    Dimensions,
    Entity,
    EntityDimensions,
    EntityId,
    NpcType,
} from "../models"
import { ItemMechanics } from "./item_mechanics"
import { Mechanics } from "./mechanics"

class Speed {
    static minimum: number = 100
    static maximum: number = 200
    static pctIncrease: number = 0.045
    static maximumSpeedEncumberancePct = 0.2

    static perPointIncrease = () => {
        const range = this.range()
        const upgradeAmount = Math.round(range * this.pctIncrease)
        return upgradeAmount
    }

    static maximumNumberOfUpgrades = () => {
        return Math.floor(this.maximum / this.perPointIncrease())
    }

    static increaseCostFrom = (speed: number) => {
        const diffFromMinimum = speed - this.minimum
        const numberOfInceases = Math.floor(diffFromMinimum / this.perPointIncrease())
        return Math.max(1, Math.floor(Math.log2(numberOfInceases)))
    }

    static maxNormalizedSpeed = () => Math.floor((this.maximum - this.minimum) / this.perPointIncrease())

    static toNormalizedSpeed = (rawSpeed: number) => Math.floor((rawSpeed - this.minimum) / this.perPointIncrease())

    private static range = () => this.maximum - this.minimum

    static toNormalizedSpeedPctOfMax = (rawSpeed: number) => (rawSpeed - this.minimum) / this.range()

    static toNormalizedSpeedPctOfMin = (rawSpeed: number) => rawSpeed / this.minimum

    static fromPctOfMax = (pct: number) => this.minimum + this.range() * pct

    static upgrade = (fromSpeed: number) => {
        if (fromSpeed >= this.maximum) {
            return
        }
        const upgradeAmount = this.perPointIncrease()
        let updatedMax = fromSpeed + upgradeAmount
        const diff = this.maximum - updatedMax
        if (diff < upgradeAmount) {
            updatedMax = this.maximum
        }

        if (updatedMax > this.maximum) {
            return
        }

        return updatedMax
    }

    static needsSpeedRecomputation = (entity: Entity, lastComputationTs: number) => {
        const toCmpare = Math.max(entity.inventory.lastUpdateTs || 0, entity.lastStatsUpdateTs || 0)
        return toCmpare > lastComputationTs
    }

    static effectiveSpeed = (entity: Entity) => {
        if (entity.isPlayer()) {
            const baselineSpeed = entity.boosted
                ? entity.maxSpeed + Math.floor(entity.maxSpeed * Math.min(1.0, 0.6 + entity.boostPct))
                : entity.maxSpeed

            const weightSpeedReductionBudget = this.maximumSpeedEncumberancePct * this.maximum
            const weightPctOfMax = ItemMechanics.weight.computeWeightPctOfMax(entity.inventory)
            const speedReduction = Math.min(weightSpeedReductionBudget * weightPctOfMax, baselineSpeed * 0.65)

            const weightBased = baselineSpeed - speedReduction
            const speed = weightBased * (entity.movingBackwards ? 0.7 : 1.0)

            const isSlowed = Mechanics.effect.hasEntityEffect(entity, "webslow")
            return isSlowed ? speed * 0.2 : speed
        } else {
            // for now ... entities aren't affected by boosts or weight
            const speed = entity.speed * (entity.movingBackwards ? 0.7 : 1.0)
            const isSlowed = Mechanics.effect.hasEntityEffect(entity, "webslow")
            return isSlowed ? speed * 0.2 : speed
        }
    }
}

class HitPoints {
    static minimum: number = 3
    static maximum: number = 50

    private static range = () => this.maximum - this.minimum

    static perPointIncrease = () => 1

    static increaseCostFrom = (hp: number) => {
        const diffFromMinimum = hp - this.minimum
        const numberOfInceases = Math.floor(diffFromMinimum / this.perPointIncrease())
        return Math.max(1, Math.floor(Math.log2(numberOfInceases)))
    }

    static upgrade = (fromHp: number) => {
        if (fromHp >= this.maximum) {
            return
        }
        const upgradeAmount = this.perPointIncrease()
        let updatedMax = fromHp + upgradeAmount
        const diff = this.maximum - updatedMax
        if (diff < upgradeAmount) {
            updatedMax = this.maximum
        }

        if (updatedMax > this.maximum) {
            return
        }

        return updatedMax
    }

    static fromPctOfMax = (pct: number) => this.minimum + this.range() * pct
}

class Levels {
    static maximum = 600
    private static upgradePointsForLevel = (level: number): number => {
        return Math.max(1, Math.floor(Math.log1p(level)))
    }

    static totalUpgradePointsPerLevel: Record<number, number> = {}
    static ugradePointsAtLevel: Record<number, number> = {}
    static {
        let totalUpgradePoints = 0
        for (let i = 1; i <= this.maximum; i++) {
            const upgradePoints = this.upgradePointsForLevel(i)
            this.ugradePointsAtLevel[i] = upgradePoints
            totalUpgradePoints += upgradePoints
            this.totalUpgradePointsPerLevel[i] = totalUpgradePoints
        }
    }
}

class MinMax {
    maximum = 100
    minimum = 0

    constructor(min: number, max: number) {
        this.minimum = min
        this.maximum = max
    }

    range = () => this.maximum - this.minimum
    fromPctOfMax = (pct: number) => this.minimum + this.range() * pct
    toPctOfMax = (aggression: number) => aggression / this.maximum
}

class Inventory {
    private static inventoryBearingNpcTypes: Set<NpcType> = new Set()
    static {
        this.inventoryBearingNpcTypes.add("zombie")
        this.inventoryBearingNpcTypes.add("outpost")
    }

    static canEquipWeapon = (entity: Entity) => {
        if (["zombie"].includes(entity.npcType)) {
            return true
        }

        return false
    }

    static hasEquipment = (entity: Entity) => {
        if (entity.npcType) {
            if (entity.flags.forcePersist) {
                return true
            }
            if (!this.inventoryBearingNpcTypes.has(entity.npcType)) {
                return false
            }
            if (entity.npcType === "outpost") {
                return true
            }
        }

        // for now ... only humanoids can have equipment
        // if (!Body.isHumanoid(entity?.appearance?.race)) {
        //     return false
        // }

        return true
    }
}

class Body {
    static isHumanoid = (race: Race) => {
        return (HumanoidRaceTypes as unknown as string[]).includes(race)
    }

    static isShort = (entity: Entity) => {
        return !this.isHumanoid(entity?.appearance?.race)
    }

    static isFlat = (entity: Entity) => {
        const race = entity?.appearance?.race
        if (!race) {
            return false
        }
        return ["spikes1"].includes(race)
    }

    static isSmall = (entity: Entity) => {
        const race = entity?.appearance?.race
        if (!race) {
            return false
        }

        if (this.isHumanoid(race)) {
            return false
        }

        return ["slime", "rabbit", "chicken", "snake", "wasp"].includes(race)
    }

    static hasAppearance = (npcType: NpcType) => {
        return !["outpost", "collectable", "marker"].includes(npcType)
    }

    static dimensions = (entity: Entity): Dimensions => {
        if (this.isHumanoid(entity?.appearance?.race)) {
            return {
                w: 25,
                h: 50,
            }
        }

        return EntityDimensions[entity.entityType]
    }

    static calculateCollisionBounds = (entity: Entity, bounds: Bounds): boolean => {
        const isHorselike = ["deer", "horse"].includes(entity.appearance?.race || "")
        const x = entity.location.x
        const y = entity.location.y

        if (isHorselike) {
            const direction = entity.getGrossOrientation()
            if (direction === "LeftRight") {
                bounds.x1 = x - entity.dimensions.w
                bounds.y1 = y - entity.dimensions.h * 1.5
                bounds.x2 = x + entity.dimensions.w
                bounds.y2 = y + entity.dimensions.h * 0.5
            } else {
                bounds.x1 = x - entity.dimensions.w / 2
                bounds.y1 = y - entity.dimensions.h * 1.5
                bounds.x2 = x + entity.dimensions.w / 2
                bounds.y2 = y + entity.dimensions.h / 2
            }

            return true
        }

        return false
    }

    static calculateZIndexBoost = (entity: Entity): number => {
        if (entity.npcType === "collectable") {
            return 0
        } else {
            return (
                (this.isSmall(entity) ? 85 : this.isHumanoid(entity?.appearance?.race) ? 100 : 90) + entity.location.y
            )
        }
    }
}

class Psych {
    private static hostilityMinMax = new MinMax(0, 100)

    static hostility = () => this.hostilityMinMax

    static attackPattern = (race: Race): AttackPattern => Mechanics.population.psych(race)?.attackPattern || "default"

    static aggroResponse = (race: Race): AggroResponse => Mechanics.population.psych(race)?.aggroResponse || "default"

    static closeOnStun = (race: Race): boolean => Mechanics.population.psych(race)?.closeOnStun
}

export const InteractionTypes = ["burn", "take", "chat", "inventory", "crown"] as const

export type InteractionType = (typeof InteractionTypes)[number]

export interface EntityInteractions {
    specific: Map<EntityId, InteractionType[]>
    interactions: InteractionType[]
}

class Flags {
    static updateFlag = (entity: Entity, flags: any) => {
        const updateFlags = (entity: Entity, flag: string) => {
            const value = flags[flag]
            if (value === null) {
                // delete flag
                delete entity.flags[flag]
            } else if (typeof value !== "string") {
                entity.flags[flag] = value
            } else {
                const result = value.matchAll(/\{(.*?)\}/g).next().value
                if (result) {
                    const matched = result[1]
                    const [operand, impact] = matched.split(":")
                    if (operand === "increment") {
                        const updatedValue = (entity.flags[flag] || 0) + parseInt(impact)
                        entity.flags[flag] = updatedValue
                    }
                    if (operand === "decrement") {
                        const updatedValue =  Math.max(0, (entity.flags[flag] || 0) - parseInt(impact))
                        entity.flags[flag] = updatedValue
                    }
                    if (operand === "timestamp") {
                        entity.flags[flag] = Date.now() + parseInt(impact)
                    }
                } else {
                    entity.flags[flag] = value
                }
            }
        }

        if (entity) {
            Object.keys(flags).forEach(flag => {
                updateFlags(entity, flag)
            })
        }

        return entity
    }
}

class Interactions {}

export class EntityMechanics {
    static inventory = Inventory
    static body = Body
    static speed = Speed
    static hitPoints = HitPoints
    static levels = Levels
    static psych = Psych
    static interactions = Interactions
    static flags = Flags
}
