import { ActionType } from "game-common/character/character"
import { LpcSheet } from "game-common/lpc/lpc"
import { Coordinate, Entity, IdAware, IndexedMap, randomIntBetween } from "game-common/models"
import { Callback, degrees_to_radians, radians_to_cardinal_dir, randomId } from "game-common/util"
import {
    AnimatedSprite,
    Application,
    BitmapText,
    Container,
    Graphics,
    LoaderResource,
    Rectangle,
    Sprite,
    Texture,
} from "pixi.js"

import { SpriteMeta } from "../entity_rendering_manager"
import { BaseRenderable } from "./base_renderable"
import { lpcCompositeGenerator } from "./lpc_composite_generator"
import { LoadingLifecycle, spriteSheetLoader } from "./spritesheet_loader"

export const AnimationDictionaryTypes = [
    "walk-left",
    "walk-right",
    "walk-back",
    "walk-front",
    "idle-left",
    "idle-right",
    "idle-back",
    "idle-front",
    "thrust-left",
    "thrust-right",
    "thrust-back",
    "thrust-front",
    "slash-left",
    "slash-right",
    "slash-back",
    "slash-front",
    "shoot-left",
    "shoot-right",
    "shoot-back",
    "shoot-front",
    "hurt-front",
    "activate",
    "deactivate",
    "inactive",
    "active",
] as const

const AnimationTypeToActionType: Record<AnimationDictionaryType, ActionType> = {
    activate: "activate",
    deactivate: "deactivate",
    inactive: "inactive",
    active: "activate",
    "walk-left": "walk",
    "walk-right": "walk",
    "walk-back": "walk",
    "walk-front": "walk",
    "idle-left": "idle",
    "idle-right": "idle",
    "idle-back": "idle",
    "idle-front": "idle",
    "thrust-left": "thrust",
    "thrust-right": "thrust",
    "thrust-back": "thrust",
    "thrust-front": "thrust",
    "slash-left": "slash",
    "slash-right": "slash",
    "slash-back": "slash",
    "slash-front": "slash",
    "shoot-left": "shoot",
    "shoot-right": "shoot",
    "shoot-back": "shoot",
    "shoot-front": "shoot",
    "hurt-front": "hurt",
}

export type AnimationDictionaryType = (typeof AnimationDictionaryTypes)[number]

export type LpcAnimationRenderMeta = Record<AnimationDictionaryType, SpriteMeta>

export type LpcRenderProps = {
    isSubmerged?: boolean
}

export interface LpcSpriteRenderMeta {
    alwaysShowLabel?: boolean
    disallowMoveAttackAnimation?: boolean
    renderMeta?: Partial<LpcAnimationRenderMeta>
    attackAction?: ActionType
    angle?: number
    descriptors: LpcSheet[]
    hasHalo?: boolean
    isSubmerged?: boolean
    hasShadow?: boolean
    id?: string
    priority?: boolean
    loadObserver?: LoadObserver
    defaultAction?: ActionType
}

const defaultRenderMeta: LpcAnimationRenderMeta = {
    activate: {
        scale: 1,
        speed: 0.25,
    },
    deactivate: {
        scale: 1,
        speed: 0.25,
    },
    inactive: {
        scale: 1,
    },
    active: {
        scale: 1,
    },
    "walk-left": {
        scale: 1,
        speed: 0.25,
    },
    "walk-right": {
        scale: 1,
        speed: 0.25,
    },
    "walk-back": {
        scale: 1,
        speed: 0.25,
    },
    "walk-front": {
        scale: 1,
        speed: 0.25,
    },
    "idle-left": {
        scale: 1,
    },
    "idle-right": {
        scale: 1,
    },
    "idle-back": {
        scale: 1,
    },
    "idle-front": {
        scale: 1,
    },
    "thrust-left": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-right": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-back": {
        scale: 1,
        speed: 0.55,
    },
    "thrust-front": {
        scale: 1,
        speed: 0.55,
    },
    "slash-left": {
        scale: 1,
        speed: 0.25,
    },
    "slash-right": {
        scale: 1,
        speed: 0.25,
    },
    "slash-back": {
        scale: 1,
        speed: 0.25,
    },
    "slash-front": {
        scale: 1,
        speed: 0.25,
    },
    "shoot-left": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-right": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-back": {
        scale: 1,
        speed: 0.85,
    },
    "shoot-front": {
        scale: 1,
        speed: 0.85,
    },
    "hurt-front": {
        scale: 1,
    },
}

const animationToAction = (animation: AnimationDictionaryType): ActionType => animation.split("-")[0] as ActionType

const isDirectional = (type: AnimationDictionaryType) => type.includes("-")

const isAttackAnimation = (animationName: AnimationDictionaryType) =>
    ["shoot", "thrust", "slash"].includes(animationToAction(animationName))

const isActivationAnimation = (animationName: AnimationDictionaryType) =>
    ["activate", "deactivate"].includes(animationToAction(animationName))

const isWalkAnimation = (animationName: AnimationDictionaryType) => ["walk"].includes(animationToAction(animationName))

const extractDirection = (animation: AnimationDictionaryType) => animation.split("-")[1]

type AnimationLoadingState = LoadingLifecycle | "failed"

interface ActionListener extends IdAware {
    callback: Callback<void>
}

const actionTypeToAnimationAction = (actionType: ActionType): AnimationDictionaryType => {
    if (actionType === "activate") {
        return "activate"
    }
    if (actionType === "deactivate") {
        return "deactivate"
    }
    if (actionType === "inactive") {
        return "inactive"
    }
    if (actionType === "active") {
        return "active"
    }
}

const isLooped = (animation: AnimationDictionaryType) => {
    if (isAttackAnimation(animation) || isActivationAnimation(animation)) {
        return false
    }

    return true
}

export class LoadObserver {
    doneCallback: Callback<void>
    jobs: Set<string> = new Set()
    completed: boolean

    constructor(doneCallback: Callback<void>) {
        this.doneCallback = doneCallback
    }

    offer = (job: string) => {
        this.jobs.add(job)
    }

    complete = (job: string) => {
        this.jobs.delete(job)
        if (this.completed) {
            return
        }

        if (this.jobs.size < 1) {
            this.completed = true
            this.doneCallback()
        }
    }

    clear = () => {
        this.jobs.clear()
        this.completed = false
    }
}

export class LpcCompositeSprite extends BaseRenderable {
    spriteAngle: number = 0

    currentColor: number
    animation: AnimatedSprite
    sprite: Sprite
    labelText: BitmapText

    container: Container

    lastX: number
    lastY: number

    action: AnimationDictionaryType = "idle-front"
    prevAction: AnimationDictionaryType = "idle-front"

    animations: Record<AnimationDictionaryType, AnimatedSprite> = {} as Record<AnimationDictionaryType, AnimatedSprite>

    idleTimeout: any

    disallowMoveAttackAnimation: boolean
    renderMeta: LpcAnimationRenderMeta
    renderProps: LpcRenderProps
    spriteRootName: string

    facingDir: string = "front"
    descriptors: LpcSheet[]

    spritesheetResource: LoaderResource

    attackAction: ActionType = "idle"

    animationDictionaryTypeLoadingState: Map<AnimationDictionaryType, AnimationLoadingState> = new Map()
    app: Application
    initialized: boolean
    identifier: string

    creationTs: number
    halo: Graphics
    shadow: Graphics
    id: string
    priority: boolean

    actionCompleteListeners: Map<ActionType, IndexedMap<ActionListener>> = new Map()

    loadObserver?: LoadObserver

    defaultAction?: ActionType

    constructor({
        descriptors,
        renderMeta = defaultRenderMeta,
        attackAction,
        angle,
        hasHalo,
        hasShadow,
        id,
        priority,
        loadObserver,
        isSubmerged,
        defaultAction,
    }: LpcSpriteRenderMeta) {
        super()

        this.id = id
        this.priority = priority

        this.renderMeta = { ...defaultRenderMeta, ...renderMeta }
        this.renderProps = {
            isSubmerged,
        }
        this.attackAction = attackAction

        this.spriteAngle = angle
        this.facingDir = this.calcDir(angle)

        this.container = new Graphics()
        this.addChild(this.container)
        this.loadObserver = loadObserver

        if (hasHalo) {
            const halo = new Graphics()
            halo.alpha = 0.5
            halo.beginFill(0xffffff)
            halo.drawCircle(32, 25, 13)
            halo.endFill()
            this.halo = halo
            this.container.addChild(halo)
        }

        if (hasShadow) {
            const shadow = new Graphics()
            shadow.alpha = 0.2
            shadow.beginFill(0x000000)

            if (isSubmerged) {
                // shorter
                shadow.drawEllipse(32, 55, 16, 8)
            } else {
                shadow.drawEllipse(32, 60, 16, 8)
            }

            shadow.endFill()
            this.shadow = shadow
            this.container.addChild(shadow)
        }

        this.descriptors = descriptors
        this.creationTs = Date.now()
        this.defaultAction = defaultAction
    }

    logme = (msg: string, m2: any = "", m3: any = "") => {
        // console.log(this.creationTs, "@@@", this.identifier, msg, m2, m3)
    }

    initalize = (id: string, angle: number) => {
        this.identifier = id

        const f = this.calcDir(angle)
        const action = actionTypeToAnimationAction(this.defaultAction) || (("idle-" + f) as any)
        let descriptorCountdown = this.descriptors.length
        this.logme("initializing sheets", id)

        const s = Date.now()
        this.loadObserver?.offer(action)
        this.descriptors.forEach((descriptor, i) => {
            const sheetId = `lpc/spritesheets/${descriptor.entityId}.json`
            const url = `assets/${sheetId}`
            this.logme("loading", url)

            spriteSheetLoader.load(url, _ => {
                descriptorCountdown -= 1
                if (descriptorCountdown <= 0) {
                    this.initialized = true
                    this.logme("@@@ all sheets loaded", action)
                    this.loadObserver?.complete(action)

                    const elapsed = Date.now() - s
                    this.activateAnimation(action, true)
                }
            })
        })
    }

    recolor = (color: number) => {
        if (this.currentColor === color) {
            return
        }
        this.currentColor = color
        if (this.animation) {
            this.animation.tint = color
        }
        if (this.sprite) {
            this.sprite.tint = color
        }
    }

    calcDir(angle: number): string {
        return radians_to_cardinal_dir(angle)
    }

    doMovement(entity: Entity): void {
        const { movement } = entity
        if (!movement) {
            return
        }

        if (this.spriteAngle !== movement.angle) {
            const facingDir = this.calcDir(movement.angle)
            if (facingDir !== this.facingDir) {
                const parts = this.action.split("-")
                const updated = `${parts[0]}-${facingDir}` as any
                this.activateAnimation(updated)
            }
            this.facingDir = facingDir
        }
        this.spriteAngle = movement.angle

        const { x, y } = entity.location

        if (x !== this.lastX || y !== this.lastY) {
            if (this.idleTimeout !== undefined) {
                clearTimeout(this.idleTimeout)
                this.idleTimeout = undefined
            }

            if (this.lastX !== undefined && this.lastY !== undefined && !isAttackAnimation(this.action)) {
                this.activateAnimation(`walk-${this.facingDir}` as AnimationDictionaryType)
            }

            this.lastX = x
            this.lastY = y
        } else {
            if (this.idleTimeout === undefined) {
                this.idleTimeout = setTimeout(() => {
                    if (isAttackAnimation(this.action)) {
                        return
                    }
                    this.activateAnimation(`idle-${this.facingDir}` as AnimationDictionaryType)
                }, 50)
            }
        }
    }

    postAnimation = (animation: AnimationDictionaryType): AnimationDictionaryType => {
        if (isAttackAnimation(animation)) {
            return `idle-${extractDirection(this.action)}` as AnimationDictionaryType
        }
        if (animation === "activate") {
            return "active"
        }
        if (animation === "deactivate") {
            return "inactive"
        }
    }

    doPreparation = (action: AnimationDictionaryType) => {
        this.logme("@@@ kicked off", action)

        this.animationDictionaryTypeLoadingState.set(action, "loading")

        lpcCompositeGenerator.generate(
            this.priority,
            action,
            this.renderMeta[action],
            this.renderProps,
            this.descriptors,
            (animation: AnimatedSprite) => {
                if (animation) {
                    this.container.addChild(animation)
                    this.logme("caching", action)
                    animation.loop = isLooped(action)
                    const listeners = this.actionCompleteListeners.get(animationToAction(action))
                    if (listeners?.size() > 0) {
                        const totalFrames = animation.totalFrames
                        animation.onFrameChange = (frame: number) => {
                            const pct = frame / (totalFrames - 1)
                            if (pct > 0.5) {
                                listeners.forEach(listener => {
                                    listener.callback()
                                    listeners.remove(listener.entityId)
                                })
                            }
                        }
                    }

                    const postAnimation = this.postAnimation(action)
                    if (postAnimation) {
                        animation.onComplete = () => {
                            this.activateAnimation(postAnimation)
                        }
                    }

                    this.animations[action] = animation
                    this.logme("is all prepared for", action)
                    this.animationDictionaryTypeLoadingState.set(action, "loaded")
                    this.activateAnimation(action, true)
                } else {
                    this.logme("! could not prepare", action)
                    this.animationDictionaryTypeLoadingState.set(action, "failed")
                }
            },
        )
    }

    prepare = (updatedAction: AnimationDictionaryType) => {
        this.logme("@@@ prepare", updatedAction)
        if (this.animations[updatedAction]) {
            this.logme("@@@ already exists", updatedAction)
            // already exists
            return
        }
        const loadState = this.animationDictionaryTypeLoadingState.get(updatedAction)
        if (loadState && loadState !== "loaded" && loadState !== "failed") {
            this.logme("@@@", updatedAction, "still in progress")
            // still in progress
            return
        }

        if (loadState === "failed") {
            this.logme("@@@", updatedAction, "failed")
            return
        }

        // kick it off
        this.doPreparation(updatedAction)
    }

    activateAnimation = (updatedAction: AnimationDictionaryType, force?: boolean) => {
        if (!this.initialized) {
            return
        }

        // this.logme('->', updatedAction)
        if (updatedAction === this.action && !force) {
            // noop
            return
        }

        const source = this.animations[`${this.action}`]

        const toUpdate = this.animations[`${updatedAction}`]
        if (!toUpdate) {
            this.logme(updatedAction, "not present, prepping")
            this.prepare(`${updatedAction}`)
            return
        }

        if (source) {
            // stop and hide the current animation
            source.visible = false
            source.stop()
        }

        // start and show the next animation
        this.action = updatedAction
        toUpdate.visible = true
        toUpdate.gotoAndPlay(0)
    }

    addActionListener = (action: ActionType, actionListener: ActionListener) => {
        let listeners = this.actionCompleteListeners.get(action)
        if (!listeners) {
            listeners = new IndexedMap<ActionListener>()
            this.actionCompleteListeners.set(action, listeners)
        }
        listeners.add(actionListener)
    }

    doAttack(entity: Entity, callback?: Callback<void>): void {
        if (isAttackAnimation(this.action)) {
            // noop - already attacking
            return
        }

        if (this.disallowMoveAttackAnimation && isWalkAnimation(this.action)) {
            return
        }
        this.prevAction = this.action
        const newAction: AnimationDictionaryType = `${this.attackAction}-${this.facingDir}` as AnimationDictionaryType
        if (callback) {
            this.addActionListener(animationToAction(newAction), { entityId: randomId(), callback })
        }
        this.activateAnimation(newAction)
    }

    fragment = (entity: Entity) => {
        let actionToUse = this.action
        if (!this.renderMeta[this.action]) {
            actionToUse = `idle-${this.facingDir}` as AnimationDictionaryType
        }
        if (!this.renderMeta[actionToUse]) {
            return
        }

        const { scale } = this.renderMeta[actionToUse]
        const surface = new Graphics()
        surface.x = this.parent?.x - this.parent.width * 0.5
        surface.y = this.parent?.y - this.parent.height * 0.5
        surface.scale.set(scale)
        surface.rotation = this.container.rotation
        this.parent.parent.addChild(surface)

        const sprite: AnimatedSprite = this.animations[`${actionToUse}`]
        if (!sprite) {
            return
        }
        const f: Texture = sprite.textures[sprite.currentFrame] as any

        const { x: origX, y: origY, width, height } = f._frame

        const divisor = height < 50 || width < 50 ? 3 : randomIntBetween(10, 20)

        let awaitedCount = 0
        const cleaner = () => {
            if (awaitedCount < 1) {
                surface.parent.removeChild(surface)
            }
        }

        for (let y = 0; y < Math.floor(height / divisor); y++) {
            for (let x = 0; x < Math.floor(width / divisor); x++) {
                const fragX = origX + x * divisor
                const fragY = origY + y * divisor
                const fragWidth = divisor
                const fragHeight = divisor

                const frag = new Texture(
                    f.baseTexture,
                    new Rectangle(fragX, fragY, fragWidth, fragHeight),
                    undefined,
                    undefined,
                    f.rotate,
                    f.defaultAnchor,
                )

                const fragSprite = new Sprite(frag)
                fragSprite.x = x * divisor
                fragSprite.y = y * divisor
                // fragSprite.scale.set(1.5)

                surface.addChild(fragSprite)

                const angle = degrees_to_radians(randomIntBetween(0, 360))

                const speed2: Coordinate = {
                    x: Math.cos(angle),
                    y: Math.sin(angle),
                }

                let rotation = 0
                let startTs = Date.now()
                let changedColor1
                let changedColor2

                awaitedCount++

                const interval = setInterval(() => {
                    fragSprite.x += speed2.x * 10
                    fragSprite.y += speed2.y * 10
                    fragSprite.alpha -= 0.1

                    rotation += 1
                    if (rotation > 360) {
                        rotation = 0
                    }

                    fragSprite.angle = rotation

                    if (fragSprite.alpha < 0.7 && !changedColor1) {
                        fragSprite.tint = 0xffc0c0
                        changedColor1 = true
                    }

                    if (fragSprite.alpha < 0.5 && !changedColor2) {
                        fragSprite.tint = 0xff0000
                        changedColor2 = true
                    }

                    if (Date.now() - startTs > 500) {
                        try {
                            if (fragSprite && fragSprite.parent) {
                                fragSprite.parent.removeChild(fragSprite)
                                // fragSprite.texture.destroy(true)
                            }
                        } catch (e) {
                            //
                        }
                        clearInterval(interval)
                        awaitedCount--
                        cleaner()
                    }
                }, 50)
            }
        }
    }
}
