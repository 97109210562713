import { DefaultAttackActionType, WeaponAction } from "game-common/character/character"
import { ItemTypeMeta, locateInventoryActiveItem, locateInventoryItemObjectByItemType } from "game-common/item/item"
import { LpcSheet, toDescriptor } from "game-common/lpc/lpc"
import { defaultActionFor, forgeLpcSpritesheetDescriptors, LpcRaceDimensions } from "game-common/lpc/lpc-forge"
import { InteractionType } from "game-common/mechanics/entity_mechanics"
import { Mechanics } from "game-common/mechanics/mechanics"
import { AnimatedFeedbackParams, Entity, EntityId, TileSize } from "game-common/models"
import { Container, Graphics, filters } from "pixi.js"

import { EntityInteractionCallback, PlayerEntityId } from "../../../../client_game_logic"
import { AnimatedFeedback } from "../effect/animated_feedback"
import { WorldOverlayItem } from "../world_overlay_surface"
import { AvatarHud } from "./avatar_hud"
import { BaseRenderable } from "./base_renderable"
import { LoadObserver, LpcCompositeSprite } from "./lpc_composite_sprite"
import { Callback } from "game-common/util"
import { Fader } from "../gui/fader"
import { ItemTypes } from "game-common/item/item_type"

export interface FeedbackContainer {
    interval: any
    jobs: any[]
}

export class LpcComposite extends BaseRenderable {
    private sprite: LpcCompositeSprite
    private hud: AvatarHud
    private drawableSurface: BaseRenderable
    private clickArea: BaseRenderable
    private worldOverlayItem: WorldOverlayItem
    private halo: boolean
    private haloObject: Graphics
    private entityId: EntityId
    blinkingInterval: any
    private rebuildTimeout: any
    private animatedFeedback: AnimatedFeedback
    private loadObserver: LoadObserver
    private colorMatrix: any
    private submerged: boolean
    private runningFeedback: Map<string, FeedbackContainer> = new Map()

    constructor(
        entityId: EntityId,
        interactionCallback: EntityInteractionCallback,
        loadObserver?: LoadObserver,
        isClientPlayer?: boolean,
    ) {
        super()
        this.entityId = entityId
        this.clickArea = new BaseRenderable()
        this.loadObserver = loadObserver
        this.hud = new AvatarHud(
            0xffffff,
            {
                renderActions: {},
                alwaysShowLabel: false,
            },
            this.clickArea,
            (_: EntityId, interaction: InteractionType) => interactionCallback(this.entityId, interaction),
            isClientPlayer,
        )

        this.drawableSurface = new BaseRenderable()
        this.animatedFeedback = new AnimatedFeedback()

        let colorMatrix = new filters.ColorMatrixFilter()
        this.colorMatrix = colorMatrix
        this.filters = [colorMatrix]
    }

    getClickSurface = (): BaseRenderable => {
        return this.clickArea
    }

    private rebuildLayers = (entity: Entity) => {
        const dimensions = Mechanics.entity.body.dimensions(entity)

        const race = entity.appearance?.race
        const sex = entity.appearance?.sex
        const hair = entity.appearance?.hair
        const nose = entity.appearance?.nose
        const eyes = entity.appearance?.eyes

        const weaponAction = WeaponAction[entity?.weapon?.weaponType || "none"] || DefaultAttackActionType[race]

        const descriptors = forgeLpcSpritesheetDescriptors({
            body: {
                race,
                sex,
                color: entity.appearance?.bodyColor,
            },
            hair: hair
                ? {
                      component: hair,
                      color: entity.appearance?.hairColor,
                  }
                : undefined,
            nose: nose
                ? {
                      component: nose,
                      color: entity.appearance?.noseColor,
                  }
                : undefined,
            eyes: eyes
                ? {
                      component: eyes,
                      color: entity.appearance?.eyesColor,
                  }
                : undefined,
            ///////
            weapon: toDescriptor(
                locateInventoryItemObjectByItemType(entity.inventory, entity?.weapon?.weaponType as ItemTypes)
                    ?.itemTypeMeta,
            ),
            pants: toDescriptor(locateInventoryActiveItem(entity.inventory, "pants")),
            shirt: toDescriptor(locateInventoryActiveItem(entity.inventory, "shirt")),
            armor: toDescriptor(locateInventoryActiveItem(entity.inventory, "armor")),
            shoes: toDescriptor(locateInventoryActiveItem(entity.inventory, "shoes")),
            shield: toDescriptor(locateInventoryActiveItem(entity.inventory, "shield")),
            cape: toDescriptor(locateInventoryActiveItem(entity.inventory, "cape")),
            bauldron: toDescriptor(locateInventoryActiveItem(entity.inventory, "bauldron")),
        })

        const all = []
        descriptors.body?.forEach(next => all.push(next))
        const isHumanoid = Mechanics.entity.body.isHumanoid(race)
        if (isHumanoid) {
            descriptors.hair?.forEach(next => all.push(next))
            descriptors.nose?.forEach(next => all.push(next))
            descriptors.eyes?.forEach(next => all.push(next))

            descriptors.weapon?.forEach(next => all.push(next))
            descriptors.pants?.forEach(next => all.push(next))
            descriptors.shirt?.forEach(next => all.push(next))
            descriptors.armor?.forEach(next => all.push(next))
            descriptors.shoes?.forEach(next => all.push(next))
            descriptors.shield?.forEach(next => all.push(next))
            descriptors.cape?.forEach(next => all.push(next))
            descriptors.bauldron?.forEach(next => all.push(next))
        }

        const sortedDescriptors = all.sort((a: LpcSheet, b: LpcSheet) => a.zIndex - b.zIndex)

        const id = race + " " + entity?.safeName()
        const priority = PlayerEntityId.value === entity.entityId
        const oldSprite = this.sprite

        let loadObserver
        if (this.loadObserver) {
            loadObserver = this.loadObserver
            oldSprite?.remove()
        } else {
            if (oldSprite) {
                loadObserver = new LoadObserver(() => {
                    new Fader(oldSprite, {
                        releaseCallback: () => {
                            oldSprite?.remove()
                        },
                        fadeAlphaDecrement: 0.05,
                    }).update()
                })
            }
        }

        loadObserver?.clear()

        this.sprite = new LpcCompositeSprite({
            attackAction: weaponAction,
            angle: entity.movement.angle,
            descriptors: sortedDescriptors,
            hasHalo: this.halo,
            hasShadow: isHumanoid,
            id,
            priority,
            loadObserver: loadObserver,
            isSubmerged: this.submerged,
            defaultAction: defaultActionFor(entity.appearance),
        })

        this.sprite.initalize(id, entity.movement.angle)
        this.haloObject = this.sprite.halo
        const raceDimensions = LpcRaceDimensions[entity?.appearance?.race] || {
            w: 64,
            h: 64,
        }
        const renderable = this.sprite as Graphics
        renderable.x = -raceDimensions.w / 2
        renderable.y = -raceDimensions.h / 2
        this.addChild(this.sprite)

        if (this.clickArea.parent) {
            this.clickArea.parent.removeChild(this.parentArea)
        }
        this.clickArea.clear()
        this.clickArea.beginFill(0xff0000, 0.0001)
        this.clickArea.drawRect(0, 0, dimensions.w, dimensions.h)
        this.clickArea.x = -dimensions.w / 2
        this.clickArea.y = -dimensions.h / 2 + TileSize * 0.15
        this.clickArea.endFill()
        this.addChild(this.clickArea)

        if (this.haloObject) {
            this.updateHaloColor(entity.hp, entity.maxHp)
        }
    }

    doMovement(entity?: Entity) {
        this.sprite?.doMovement(entity)
        this.drawableSurface.doMovement(entity)
    }

    update(hp: number, hpMax: number) {
        this.hud.update(hp, hpMax)
        this.updateHaloColor(hp, hpMax)
    }

    updateName(name: string) {
        this.hud.updateName(name)
    }

    recolor(color: number) {
        this.hud.recolor(color)
    }

    updateHaloColor(hp: number, hpMax) {
        if (!this.haloObject) {
            return
        }

        const hpPct = hp / hpMax
        if (hpPct < 0.7) {
            if (hpPct < 0.4) {
                this.haloObject.tint = 0xff0000
            } else {
                this.haloObject.tint = 0xffa500
            }
        } else {
            this.haloObject.tint = 0x00ff00
        }
    }

    updateBlinking(blinking: boolean): void {
        if (!blinking) {
            this.visible = true
            if (this.blinkingInterval) {
                clearInterval(this.blinkingInterval)
                this.blinkingInterval = null
            }
        } else {
            if (this.blinkingInterval) {
                return
            }

            this.blinkingInterval = setInterval(() => {
                this.visible = !this.visible
            }, 100)
        }
    }

    addTag() {
        this.hud.addTag()
    }

    removeTag() {
        this.hud.removeTag()
    }

    updateLevel(level: number) {
        this.hud.updateLevel(level)
    }

    doAttack(entity: Entity, callback?: Callback<void>) {
        this.sprite?.doAttack(entity, callback)
    }

    doDamaged(entity: Entity) {
        this.sprite?.doDamaged(entity)

        this.colorMatrix.blackAndWhite(true)
        this.colorMatrix.contrast(1, true)
        this.colorMatrix.saturate(1, true)
        setTimeout(() => {
            this.colorMatrix.reset()
        }, 100)
    }

    spoke(message: string) {
        this.hud.spoke(message)
    }

    getDrawableSurface(): Container {
        return this.drawableSurface.getDrawableSurface()
    }

    fragment = (entity: Entity) => {
        this.sprite?.fragment(entity)
        this.drawableSurface.fragment(entity)
    }

    updatePaperdoll(entity: Entity): void {
        if (!entity) {
            return
        }
        const { appearance } = entity
        if (!appearance) {
            return
        }

        if (this.rebuildTimeout !== undefined) {
            clearTimeout(this.rebuildTimeout)
        }

        // console.trace()

        this.rebuildTimeout = setTimeout(() => {
            this.rebuildLayers(entity)
            this.rebuildTimeout = undefined
        }, 250)
    }

    updateHalo(entity: Entity, enabled: boolean) {
        this.halo = enabled
        this.updatePaperdoll(entity)

        if (this.haloObject) {
            this.updateHaloColor(entity.hp, entity.maxHp)
        }
    }

    updateSpeed(factor: number) {
        //
    }

    onMouseOver = (show: boolean) => {
        this.hud.onMouseOver(show)
    }

    setWorldOverlayItem = (item: WorldOverlayItem) => {
        this.worldOverlayItem = item
        this.worldOverlayItem.addChild(this.hud)
    }

    updateInteractions = (interactions: InteractionType[]) => {
        this.hud.updateInteractions(interactions)
    }

    updatePointerIcon(show: boolean, angle?: number) {
        this.hud.updatePointerIcon(show, angle)
    }

    itemPickedUp(entity: Entity, itemTypeMeta: ItemTypeMeta, quantity: number) {
        this.feedback(
            entity,
            itemTypeMeta.spriteId || `${itemTypeMeta.type}-${itemTypeMeta.color}.png`,
            quantity > 0 ? `+${quantity}` : "",
            {
                font: "EntityImportantFont",
                color: 0x00ff00,
            },
        )
    }

    private scheduleFeedbackJob(image: string, label: string, params: AnimatedFeedbackParams, type: string): void {
        const job = this.animatedFeedback.setup(
            0,
            this.worldOverlayItem.height / 2,
            image,
            label,
            this.worldOverlayItem,
            {
                ...params,
                immediate: true,
            },
        )

        let container = this.runningFeedback.get(type)
        if (!container) {
            container = {
                interval: null, // This will be potentially updated later
                jobs: [],
            }
            this.runningFeedback.set(type, container)
        }
        container.jobs.push(job)
    }

    feedback(entity: Entity, image: string, label: string, params?: AnimatedFeedbackParams) {
        console.log("Feedback")
        const { type, action } = params
        if (action === "remove") {
            const feedbackContainer = this.runningFeedback.get(type)
            if (feedbackContainer) {
                clearTimeout(feedbackContainer.interval)
                console.log("removing", feedbackContainer.jobs.length)
                feedbackContainer.jobs.forEach(job => this.animatedFeedback?.remove?.(job))
            } else {
                console.log("no feedback container")
            }
            this.runningFeedback.delete(type)
        } else {
            if (type === "foot") {
                // Immediately invoke the function for the first iteration
                this.scheduleFeedbackJob(image, label, params, type)
                const container = this.runningFeedback.get(type)

                let i = 1
                let startTs = Date.now()
                const job = () => {
                    if (Date.now() - startTs > 1500) {
                        // timeout
                        console.log("feedback job timeout!")
                        return
                    }
                    console.log("job count", i)
                    i++
                    this.scheduleFeedbackJob(image, label, params, type)
                    container.interval = setTimeout(job, 100)
                }
                container.interval = setTimeout(job, 100)
            } else {
                this.animatedFeedback.setup(
                    0,
                    this.worldOverlayItem.height / 2,
                    image,
                    label,
                    this.worldOverlayItem,
                    params,
                    this.sprite,
                )
            }
        }
    }

    updateSigil(sigil?: string) {
        this.hud.updateSigil(sigil)
    }

    getYOffset() {
        return -this.height * 0.1
    }

    updateSubmerged(submerged: boolean) {
        this.submerged = submerged
    }

    updateAfk(afk: boolean) {
        if (this.sprite) {
            this.sprite.alpha = afk ? 0.3 : 1.0
        }
    }

    updateActivation(entity: Entity, active: boolean) {
        if (!this.sprite) {
            return
        }

        this.sprite.activateAnimation(active ? "activate" : "deactivate")
    }
}
