import { Entity } from "../models"

export interface DayPhase {
    name: string
    brightness: number
    dayPct: number
}

const forcedPhase: string =
null
// "night"
// "day"

const dayLengthMinutes = 10

export const DayPhases: Record<string, DayPhase> =
    forcedPhase === "night"
        ? {
              night: {
                  name: "night",
                  brightness: 0.4,
                  dayPct: 1.0,
              },
          }
        : forcedPhase === "day"
        ? {
              day: {
                  name: "day",
                  brightness: 1.0,
                  dayPct: 1.0,
              },
          }
        : {
              dawn: {
                  name: "dawn",
                  brightness: 0.4,
                  dayPct: 0.05,
              },
              morning: {
                  name: "morning",
                  brightness: 0.7,
                  dayPct: 0.2,
              },
              day: {
                  name: "day",
                  brightness: 1.0,
                  dayPct: 0.55,
              },
              dusk: {
                  name: "dusk",
                  brightness: 0.5,
                  dayPct: 0.6,
              },
              night: {
                  name: "night",
                  brightness: 0.3,
                  dayPct: 0.8,
              },
              darkestNight: {
                  name: "darkestNight",
                  brightness: 0.2,
                  dayPct: 1.0,
              },

              // "night": {
              //     name: "night",
              //     brightness: 0.20,
              //     dayPct: 1.0,
              // },

              // "night": {
              //     name: "night",
              //     brightness: 0.20,
              //     dayPct: 0.50
              // },
              // "day": {
              //     name: "day",
              //     brightness: 1.0,
              //     dayPct: 1.00
              // },
          }

export type DayPhaseType = keyof DayPhase

export const DayPhaseTypes: DayPhaseType[] = Object.keys(DayPhases).map(n => n as DayPhaseType)

export class PhysicsMechanics {
    static pushable = (target: Entity): boolean => {
        return target.npcType === "zombie" || target.npcType === "collectable"
    }

    static dayLengthClockCycles = () => dayLengthMinutes * 60

    static computeDayPct = (clockTime: number): number => {
        const dayTick = clockTime % this.dayLengthClockCycles()
        const dayPct = dayTick / this.dayLengthClockCycles()

        return dayPct
    }

    static computeDayPhase = (clockTime: number): DayPhase => {
        const dayPct = this.computeDayPct(clockTime)

        for (let i = 0; i < DayPhaseTypes.length; i++) {
            const isFirst = i === 0
            const isLast = i === DayPhaseTypes.length - 1

            const thisPhase = DayPhases[DayPhaseTypes[i]]
            const start = isFirst ? 0 : DayPhases[DayPhaseTypes[i - 1]].dayPct
            const end = thisPhase.dayPct
            if (dayPct >= start && (isLast ? dayPct <= end : dayPct < end)) {
                return thisPhase
            }
        }

        return null
    }

    static isDay = (dayPhase: DayPhase) => {
        return dayPhase?.name === "day" || dayPhase?.name === "morning"
    }

    static isNight = (dayPhase: DayPhase) => {
        return dayPhase?.name === "night"
    }

    static campfireDurationClockCycles = () => this.dayLengthClockCycles() * 1
}
