import { Coordinate, EntityId, LocationIcon } from "game-common/models"
import { fineToRoughCoordinates, toCoordinateFromSimple } from "game-common/util"
import { Graphics } from "pixi.js"
import { ClientGameLogic } from "../../../client_game_logic"
import { ClientRenderable } from "./entity_rendering_manager"
import { Throbber } from "./gui/throbber"
import { BasicSprite } from "./sprites/basic_sprite"
import { MinimapItem } from "./sprites/minimapItem"
import { TileMapMetaProvider, MinmapConfig } from "game-common/tile_map_meta_provider"

export type PointHoverListener = (mouseOver: boolean, label?: string) => void

export class MinimapSurface extends Graphics {
    private entityIdToMinimapRenderable: Map<EntityId, ClientRenderable> = new Map()
    private entityUpdatePauseTs: number = Date.now()
    private isVisible: boolean = false
    private localBiomeColorMapUpdate: Map<string, number> = new Map()
    private localLocationIconUpdate: Map<string, BasicSprite> = new Map()
    private pointHoverListener: PointHoverListener
    private logic: ClientGameLogic
    private mapIsFlashing: boolean
    private minimapConfig: MinmapConfig
    private iconChildren: Map<string, Graphics> = new Map()

    constructor(logic: ClientGameLogic) {
        super()
        this.logic = logic
        this.visible = false
    }

    setup = () => {}

    get = (entityId: EntityId): ClientRenderable => {
        return this.entityIdToMinimapRenderable.get(entityId)
    }

    entities = () => Array.from(this.entityIdToMinimapRenderable.keys())

    offer = (entity: EntityId, isClientPlayer: boolean = false): ClientRenderable => {
        const existing = this.entityIdToMinimapRenderable.get(entity)
        if (existing) {
            // already exists
            const currentRoomId = this.logic.roomId
            const entityObj = this.logic.entityManager.get(entity)
            if (entityObj?.roomId === currentRoomId) {
                existing.visible = true
            }

            return existing
        }
        const minimapRenderable: ClientRenderable = new MinimapItem()

        const recolor = (color?: number) => {
            if (isClientPlayer) {
                // you appear green
                minimapRenderable.recolor(color || 0x00ff00)
            } else {
                // all others appear white
                minimapRenderable.recolor(color || 0xffb6c1)
            }
        }
        recolor()

        this.addChild(minimapRenderable)
        this.entityIdToMinimapRenderable.set(entity, minimapRenderable)

        minimapRenderable.interactive = true
        const player = this.logic.entityManager.get(entity)
        if (player && player.entityId === this.logic.playerEntity?.entityId) {
            minimapRenderable.on("mouseover", () => {
                if (this.pointHoverListener) {
                    this.pointHoverListener(true, "You")
                    minimapRenderable.recolor(0xff0000)
                }
            })
            minimapRenderable.on("mouseout", () => {
                if (this.pointHoverListener) {
                    this.pointHoverListener(false)
                    recolor()
                }
            })
        }

        return minimapRenderable
    }

    remove = (entity: EntityId) => {
        const renderable = this.entityIdToMinimapRenderable.get(entity)
        if (!renderable) {
            console.log("No renderable", entity)
            return
        }

        this.removeChild(renderable)
        this.entityIdToMinimapRenderable.delete(entity)
        console.log("Removed renderable", entity)
    }

    roomUpdated = (suppressMinimap: boolean) => {
        // hide everything
        this.entityIdToMinimapRenderable.forEach(renderable => {
            renderable.visible = false
        })

        this.visible = !suppressMinimap
        this.isVisible = this.visible
        this.entityUpdatePauseTs = Date.now() + 500
    }

    updateBiomeColorMap = (coordinate: Coordinate, color: number) => {
        if (color === 0) {
            return
        }
        const key = `${coordinate.x}-${coordinate.y}`
        if (this.localBiomeColorMapUpdate.get(key) === color) {
            return
        }
        this.localBiomeColorMapUpdate.set(key, color)

        this.beginFill(color, 0.4)
        this.drawRect(coordinate.x, coordinate.y, 1, 1)
        this.endFill()
    }

    clearBiomeColorMap = () => {
        this.clear()
        this.localBiomeColorMapUpdate.clear()
    }

    removeLocationIcon = (iconId: string) => {
        const sprite = this.iconChildren.get(iconId)
        this.localLocationIconUpdate.delete(iconId)
        if (sprite) {
            sprite.parent.removeChild(sprite)
        }
        this.iconChildren.delete(iconId)
    }

    updateLocationIcon = (icon: LocationIcon) => {
        const key = icon.id
        if (this.localLocationIconUpdate.has(key)) {
            return
        }

        const quadrantCoordinate = this.computeQuadrantOffsetCoordinate()

        const sprite = new BasicSprite({
            name: icon.icon,
            color: 0xffffff,
            scale: 0.05,
        })
        sprite.x = icon.x - quadrantCoordinate.x + sprite.width / 2
        sprite.y = icon.y - quadrantCoordinate.y + sprite.height / 2
        this.addChild(sprite)
        this.iconChildren.set(key, sprite)
        sprite.interactive = true
        const original = sprite.tint
        sprite.on("mouseover", event => {
            if (this.pointHoverListener) {
                this.pointHoverListener(true, icon.name)
                sprite.recolor(0xff0000)
            }
        })
        sprite.on("mouseout", () => {
            if (this.pointHoverListener) {
                this.pointHoverListener(false)
                sprite.recolor(original || 0xffffff)
            }
        })

        this.localLocationIconUpdate.set(key, sprite)

        this.pulseLocation(icon.id)
    }

    flashMap = () => {
        if (this.mapIsFlashing) {
            return
        }
        const overlay = new Graphics()
        overlay.zIndex = 0
        overlay.x = this.x
        overlay.y = this.y
        this.parent.addChild(overlay)
        overlay.beginFill(0xffffff, 0.5)
        overlay.drawRect(0, 0, this.width, this.height)
        overlay.endFill()

        this.mapIsFlashing = true

        const throbber = new Throbber(overlay, {
            blinkInterval: 50,
            minAlpha: 0.1,
            expires: 750,
            releaseCallback: () => {
                this.parent.removeChild(overlay)
                this.mapIsFlashing = false
            },
        })
        throbber.update()
    }

    pulseLocation = (locationId: string, expires: number = 10000) => {
        this.logic.audio.mapLocationFound()
        this.flashMap()

        const location = this.localLocationIconUpdate.get(locationId)
        if (location) {
            const originalColor = location.currentColor
            location.recolor(0xff0000)
            const throbber = new Throbber(location, {
                blinkInterval: 50,
                minAlpha: 0.5,
                expires,
                releaseCallback: () => {
                    location.recolor(originalColor)
                },
            })
            throbber.update()
        }
    }

    computeQuadrantOffsetCoordinate = (): Coordinate => {
        const { worldMetaDimensions } = this.minimapConfig

        const biomeQuadrantStrideX = worldMetaDimensions.roomWidth / worldMetaDimensions.sectorWidth
        const biomeQuadrantStrideY = worldMetaDimensions.roomHeight / worldMetaDimensions.sectorHeight
        const quadrantBiomesWidth = biomeQuadrantStrideX * worldMetaDimensions.quadrantWidth
        const quadrantBiomesHeight = biomeQuadrantStrideY * worldMetaDimensions.quadrantHeight

        const quadrantCoordinate = toCoordinateFromSimple(this.logic.quadrantId)
        quadrantCoordinate.x = quadrantCoordinate.x * quadrantBiomesWidth
        quadrantCoordinate.y = quadrantCoordinate.y * quadrantBiomesHeight
        return quadrantCoordinate
    }

    update = (tilemapMetaProvider: TileMapMetaProvider) => {
        if (!tilemapMetaProvider) {
            return
        }

        const wasEmpty = this.localLocationIconUpdate.size < 1
        const previousLocationIds = [...this.localLocationIconUpdate.keys()]
        this.minimapConfig = tilemapMetaProvider.minimapConfig
        this.localBiomeColorMapUpdate.clear()
        this.localLocationIconUpdate.clear()
        this.clear()
        this.iconChildren.forEach(child => {
            this.removeChild(child)
        })
        this.iconChildren.clear()

        const { biomeColorMap, locationIcons } = tilemapMetaProvider

        if (biomeColorMap) {
            for (let x = 0; x < biomeColorMap.length; x++) {
                for (let y = 0; y < biomeColorMap[0].length; y++) {
                    const color = biomeColorMap[x][y]
                    if (color !== 0) {
                        const key = `${x}-${y}`
                        this.localBiomeColorMapUpdate.set(key, color)
                    }

                    this.beginFill(color, 0.4)
                    this.drawRect(x, y, 1, 1)
                    this.endFill()
                }
            }
        }

        if (locationIcons) {
            const quadrantCoordinate = this.computeQuadrantOffsetCoordinate()

            locationIcons.forEach(icon => {
                if (icon.quadrantId && icon.quadrantId !== this.logic.quadrantId) {
                    return
                }
                const sprite = new BasicSprite({
                    name: icon.icon,
                    color: 0xffffff,
                    scale: 0.05,
                })
                sprite.x = icon.x - quadrantCoordinate.x + sprite.width / 2
                sprite.y = icon.y - quadrantCoordinate.y + sprite.height / 2
                this.addChild(sprite)
                this.iconChildren.set(icon.id, sprite)

                const key = icon.id
                const shouldPulse = !wasEmpty && !previousLocationIds.includes(key)
                this.localLocationIconUpdate.set(key, sprite)

                sprite.interactive = true
                let original
                sprite.on("mouseover", event => {
                    if (this.pointHoverListener) {
                        original = sprite.getCurrentColor()
                        this.pointHoverListener(true, icon.name)
                        sprite.recolor(0xff0000)
                    }
                })
                sprite.on("mouseout", () => {
                    if (this.pointHoverListener) {
                        this.pointHoverListener(false)
                        sprite.recolor(original)
                    }
                })

                if (shouldPulse) {
                    // this.pulseLocation(key, 5000)
                }
            })
        }

        this.width = 100
        this.height = 100
        this.visible = this.isVisible
    }

    updateEntity = (entity: EntityId, roomId: EntityId, location: Coordinate, force: boolean = false) => {
        if (!force && Date.now() < this.entityUpdatePauseTs) {
            return
        }

        const minimapRenderable = this.entityIdToMinimapRenderable.get(entity)
        if (!minimapRenderable) {
            return
        }

        const [_, roomX, roomY] = roomId.split("-")

        const rough = fineToRoughCoordinates(location)
        const roughRoomX = rough.x
        const roughRoomY = rough.y

        if (!minimapRenderable.visible) {
            minimapRenderable.visible = true
        }

        if (this.minimapConfig?.biomeBased) {
            const { worldMetaDimensions } = this.minimapConfig
            const biomeX = roughRoomX / worldMetaDimensions.sectorWidth
            const biomeY = roughRoomY / worldMetaDimensions.sectorHeight

            const biomeQuadrantStrideX = worldMetaDimensions.roomWidth / worldMetaDimensions.sectorWidth
            const biomeQuadrantStrideY = worldMetaDimensions.roomHeight / worldMetaDimensions.sectorHeight

            const globalX = Number(roomX) * biomeQuadrantStrideX + biomeX
            const globalY = Number(roomY) * biomeQuadrantStrideY + biomeY

            const quadrantBiomesWidth = biomeQuadrantStrideX * worldMetaDimensions.quadrantWidth
            const quadrantBiomesHeight = biomeQuadrantStrideY * worldMetaDimensions.quadrantHeight

            minimapRenderable.x = Math.min(globalX % quadrantBiomesWidth, quadrantBiomesWidth - 1)
            minimapRenderable.y = Math.min(globalY % quadrantBiomesHeight, quadrantBiomesHeight - 1)
        } else {
            minimapRenderable.x = roughRoomX
            minimapRenderable.y = roughRoomY
        }
        this.width = 100
        this.height = 100
    }

    register = (listener: PointHoverListener) => (this.pointHoverListener = listener)
}
