import { Coordinate, TileSize } from "game-common/models"
import { TileMapMetaProvider } from "game-common/tile_map_meta_provider"
import { ScalingParams } from "../../../client_util"

export class TranslationComputer {
    tilemapMetaProvider: TileMapMetaProvider
    modifierX: number
    modifierY: number
    hasRunOnce: boolean = false
    ts: number
    noDelay: boolean = false
    xStatus: "left" | "center" | "right" | undefined
    yStatus: "top" | "center" | "bottom" | undefined

    update = (tilemapMetaProvider: TileMapMetaProvider) => {
        this.tilemapMetaProvider = tilemapMetaProvider
        this.ts = Date.now()
        this.reset()
    }

    reset = () => {
        this.hasRunOnce = false
        this.xStatus = undefined
        this.yStatus = undefined
    }

    ready = () => {
        if (Date.now() - this.ts < 150) {
            return false
        }
        return true
    }

    compute = (location: Coordinate, force?: boolean) => {
        const X_FACTOR = ScalingParams.viewportX
        const Y_FACTOR = ScalingParams.viewportY - 1
        this.modifierX = undefined
        this.modifierY = undefined
        if (!this.hasRunOnce) {
            if (!force && !this.noDelay && !this.ready()) {
                return
            }

            const modifierX =
                location.x > TileSize * X_FACTOR
                    ? (this.tilemapMetaProvider.width() - X_FACTOR) * TileSize
                    : TileSize * X_FACTOR

            const modifierY =
                location.y > TileSize * Y_FACTOR
                    ? (this.tilemapMetaProvider.height() - Y_FACTOR) * TileSize
                    : TileSize * Y_FACTOR
            this.modifierX = -modifierX + 50
            this.modifierY = -modifierY + 50
            this.hasRunOnce = true
        } else {
            const inBoundsX =
                location.x > TileSize * X_FACTOR &&
                location.x < (this.tilemapMetaProvider.width() - X_FACTOR) * TileSize
            const inBoundsY =
                location.y > TileSize * Y_FACTOR &&
                location.y < (this.tilemapMetaProvider.height() - Y_FACTOR) * TileSize

            if (inBoundsX) {
                this.modifierX = -location.x + 50
            } else {
                this.modifierX = undefined
            }
            if (inBoundsY) {
                this.modifierY = -location.y + 50
            } else {
                this.modifierY = undefined
            }
        }

        if (location.x <= TileSize * X_FACTOR) {
            this.xStatus = "left"
        } else if (location.x >= (this.tilemapMetaProvider.width() - X_FACTOR) * TileSize) {
            this.xStatus = "right"
        } else {
            this.xStatus = "center"
        }

        if (location.y <= TileSize * Y_FACTOR) {
            this.yStatus = "top"
        } else if (location.y >= (this.tilemapMetaProvider.height() - Y_FACTOR) * TileSize) {
            this.yStatus = "bottom"
        } else {
            this.yStatus = "center"
        }
    }
}
