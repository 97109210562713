import {
    Color,
    EyesColors,
    Hair,
    HairColors,
    HairTypes,
    Nose,
    Pants,
    PantsColors,
    RaceColors,
    RaceNoses,
    SexPants,
    SexShirts,
    SexShoes,
    Shirt,
    ShirtColors,
    Shoe,
    ShoeColors,
} from "game-common/character/character"
import {
    ItemTypeMeta,
    addInventoryItem,
    generateItems,
    locateInventoryActiveItem,
    locateInventoryActiveItemObject,
    randomPants,
    randomShirt,
    removeInventoryItem,
} from "game-common/item/item"
import { randomAppearanceFor } from "game-common/lpc/lpc"
import {
    Appearance,
    Coordinate,
    DynamicTextMeta,
    Entity,
    IdAware,
    IndexedMap,
    Inventory,
    pctChance,
} from "game-common/models"
import {
    Callback,
    chunkSentence,
    degrees_to_radians,
    getAngle,
    radians_to_degrees,
    randomId,
    randomOneOf,
} from "game-common/util"
import { Graphics, Text } from "pixi.js"
import { v4 as uuidv4 } from "uuid"

import { ClientGameLogic } from "../../../../client_game_logic"
import { BOTTOM, GuiManager } from "../gui_manager"
import { DynamicText } from "./dynamic_text"
import { DyanmicTextContainer, DyanmicTextContainerProps } from "./dynamic_text_container"
import { FullScreenModal } from "./fullscreen_modal"
import { LpcComposite } from "../sprites/lpc_composite"
import { SexTypes } from "game-common/character/character"
import { Sex } from "game-common/character/character"
import { TextRenderer } from "../text/text_renderer"
import { Fader } from "./fader"
import { BasicSprite } from "../sprites/basic_sprite"
import { Help } from "./help"

class Page extends DyanmicTextContainer implements IdAware {
    entityId: PageId

    constructor(props?: DyanmicTextContainerProps) {
        super(props)
        this.entityId = uuidv4()
    }

    build() {
        //
    }

    tearDown() {
        //
    }
}

class CharacterBuilderPage extends Page {
    builder: CharacterBuilder
    inject = (builder: CharacterBuilder) => (this.builder = builder)
}

class IntroPage1 extends CharacterBuilderPage {
    built: boolean

    build() {
        let f = {} as any
        const doneCallback = (c: () => void) => {
            f.fn = c
        }
        if (this.built) {
            return doneCallback
        }

        this.wipe()

        const textContainer = new DyanmicTextContainer({
            progressive: true,
        })

        textContainer.appendChunkedText(
            "You have been exiled to the barbarous lands of the West, for the Emperor will suffer no rivals in the East.",
        )

        this.append2(textContainer, {
            leftMargin: 20,
            topMargin: 100,
        })

        textContainer.playback(() => {
            if (f.fn) {
                f.fn()
            }
        })

        this.builder.updateNavButtons([
            {
                title: "Next",
                callback: () => {
                    this.builder.switchPage("build")
                },
            },
        ])

        this.built = true

        return doneCallback
    }

    tearDown() {
        this.built = false
    }
}

class IntroPage2 extends CharacterBuilderPage {
    build() {
        this.wipe()

        const header = new TextRenderer().variant("LogoFont").color(0x00ff00).render("Journey Onward") as Graphics

        this.append2(header, {
            exactLocation: {
                x: 400 - header.width / 2,
                y: 0,
            },
        })

        const textContainer = new DyanmicTextContainer({
            progressive: true,
        })

        textContainer.appendChunkedText("Beware of the local warlords! Raise your forces and conquer this savage land.")
        textContainer.append2("", {
            pause: 1000,
        })
        textContainer.appendChunkedText("1. Gather firewood. Wait for night time, and create a campfire.")
        textContainer.appendChunkedText("2. Stay within that fire's light, and recruit the mercenaries that come.")
        textContainer.appendChunkedText("3. Seek your enemies' strongholds, and defeat them.")
        textContainer.append2("", {
            pause: 1000,
        })
        textContainer.appendChunkedText("Explore this land, and harden yourself through its perils!")

        this.append2(textContainer, {
            topMargin: 80,
            leftMargin: 20,
        })

        const campfire = new BasicSprite({
            name: "lpc-terrain:760",
            scale: 1.0,
            color: 0xffffff,
        })
        const fire = new BasicSprite({
            name: "fire",
            scale: 1.0,
            color: 0xffffff,
        })
        fire.x = 3
        fire.y = -23

        const animation = new Graphics()
        animation.addChild(campfire)
        animation.addChild(fire)
        animation.scale.set(3.0)

        this.append2(animation, {
            leftMargin: 670,
            topMargin: 30,
        })
        textContainer.playback()
    }
}

class ReviewPage extends CharacterBuilderPage {
    build = () => {
        this.wipe()

        const header = new TextRenderer().variant("LogoFont").color(0x00ff00).render("Controls") as Graphics

        this.append2(header, {
            exactLocation: {
                x: 400 - header.width / 2,
                y: 0,
            },
        })

        Help.attachHelp(this)

        this.builder.updateNavButtons([
            {
                title: "Back",
                callback: () => {
                    this.builder.resetNavButtons()
                    this.builder.switchPage("intro2")
                },
            },
        ])
    }
}

class BuildPage extends CharacterBuilderPage {
    entity: Entity
    appearance: Appearance
    inventory: Inventory
    guiManager: GuiManager
    lpcComposite: LpcComposite
    center: Coordinate = {
        x: 400,
        y: 300,
    }
    compositeLocation: Coordinate

    constructor(guiManager: GuiManager, entity: Entity, props?: DyanmicTextContainerProps) {
        super(props)
        this.guiManager = guiManager
        const builderEntity = new Entity()
        builderEntity.movement.angle = degrees_to_radians(0)
        builderEntity.appearance = { ...entity.appearance }
        builderEntity.inventory = { ...entity.inventory }

        this.entity = builderEntity
        this.guiManager.logic.clientRenderer.mouse().addListener(this.mouseListener)
    }

    tearDown() {
        this.guiManager.logic.clientRenderer.mouse().removeListener(this.mouseListener)
    }

    mouseListener = event => {
        const angle = getAngle(event.position, this.center)
        this.entity.movement.angle = angle

        if (this.lpcComposite) {
            this.lpcComposite.doMovement(this.entity)
        }
    }

    generateInventory = (): Inventory => {
        return {
            items: generateItems(this.appearance.sex, {
                pants: true,
                shirt: this.appearance.sex === "male" ? pctChance(60) : true,
                shoe: true,
                weapon: true,
            }),
            abilities: {},
            lastUpdateTs: 0,
        }
    }

    handleInventory = () => {
        const pants = locateInventoryActiveItemObject(this.inventory, "pants")
        if (!SexPants[this.entity.appearance.sex].includes(pants?.itemTypeMeta.type as Pants)) {
            // need new pants
            if (pants) {
                this.entity = removeInventoryItem(this.entity, pants.id)
            }
            const newPantsType = randomPants(this.entity.appearance.sex)
            this.entity = addInventoryItem(this.entity, {
                id: uuidv4(),
                itemType: "none",
                itemTypeMeta: newPantsType,
                quantity: 1,
                active: true,
            })
        }

        const shirt = locateInventoryActiveItemObject(this.inventory, "shirt")
        if (!SexShirts[this.entity.appearance.sex].includes(shirt?.itemTypeMeta.type as Shirt)) {
            // need new shirt
            if (shirt) {
                this.entity = removeInventoryItem(this.entity, shirt.id)
            }
            const newShirtType = randomShirt(this.entity.appearance.sex)
            this.entity = addInventoryItem(this.entity, {
                id: uuidv4(),
                itemType: "none",
                itemTypeMeta: newShirtType,
                quantity: 1,
                active: true,
            })
        }

        this.entity.inventory = this.inventory
    }

    build = () => {
        this.wipe()

        if (!this.appearance) {
            this.appearance = this.entity.appearance || randomAppearanceFor(undefined, "human", "male")
            this.appearance.nose = "straight"
            this.appearance.noseColor = this.appearance.bodyColor
        }

        if (!this.inventory) {
            this.inventory = this.entity.inventory || this.generateInventory()
        }

        const header = new TextRenderer().variant("LogoFont").color(0x00ff00).render("Build your character") as Graphics

        this.append2(header, {
            exactLocation: {
                x: 400 - header.width / 2,
                y: 0,
            },
        })

        if (!this.lpcComposite) {
            const lpcComposite = new LpcComposite("", () => {})
            this.lpcComposite = lpcComposite
            this.lpcComposite.scale.set(3)
            const compositeLocation: Coordinate = {
                x: 400 - this.lpcComposite.width,
                y: 200 - this.lpcComposite.height,
            }
            this.compositeLocation = compositeLocation
        }

        this.lpcComposite.updatePaperdoll(this.entity)

        this.lpcComposite.x = 50
        this.lpcComposite.y = 70
        this.append2(this.lpcComposite, {
            exactLocation: this.compositeLocation,
        })

        const spacer = (height?: number) => {
            const spacer = new Graphics()
            spacer.x = 0
            spacer.y = 0
            spacer.drawRect(0, 0, 0, height || 15)
            return spacer
        }

        const toggles = new DyanmicTextContainer()

        const left = new DyanmicTextContainer()
        this.append2(left, {
            exactLocation: {
                x: this.compositeLocation.x - 180,
                y: 150,
            },
        })

        const right = new DyanmicTextContainer()
        this.append2(right, {
            exactLocation: {
                x: this.compositeLocation.x + 80,
                y: 150,
            },
        })

        const top = new DyanmicTextContainer()
        this.append2(top, {
            exactLocation: {
                x: this.compositeLocation.x - 50,
                y: this.compositeLocation.y - 130,
            },
        })

        const bottom = new DyanmicTextContainer()
        this.append2(bottom, {
            exactLocation: {
                x: this.compositeLocation.x - 50,
                y: this.compositeLocation.y + 100,
            },
        })
        const printControl = <T>(
            location: "left" | "right" | "top" | "bottom",
            title: string,
            range: T[],
            t: T,
            setter: (t: T) => void,
            suppressNone?: boolean,
        ) => {
            const previous = <T>(range: T[], t: T, setter: (t: T) => void) => {
                let idx = range.indexOf(t) - 1
                if (idx < 0) {
                    idx = range.length - 1
                }
                setter(range[idx])
                this.build()
            }

            const next = <T>(range: T[], t: T, setter: (t: T) => void) => {
                let idx = range.indexOf(t) + 1
                if (idx > range.length - 1) {
                    idx = 0
                }
                setter(range[idx])
                this.build()
            }

            const control = new DyanmicTextContainer()

            control.append2(
                new DynamicText("<", {
                    type: "button|dynamic",
                    onClick: () => previous<T>(range as any, t, setter),
                }),
            )

            control.append2(`${title}`, {
                sameLine: true,
                leftMargin: 5,
            })

            control.append2(
                new DynamicText(">", {
                    type: "button|dynamic",
                    onClick: () => next<T>(range as any, t, setter),
                }),
                { leftSpacer: 75, sameLine: true },
            )

            if (!suppressNone) {
                control.append(" ", true)
                control.append2(
                    new DynamicText("None", {
                        type: "button|dynamic",
                        onClick: () => {
                            setter(undefined)
                            this.build()
                        },
                    }),
                    { sameLine: true },
                )
            }

            if (location === "left") {
                left.append2(control)
            }
            if (location === "right") {
                right.append2(control)
            }
            if (location === "top") {
                top.append2(control)
            }
            if (location === "bottom") {
                bottom.append2(control)
            }
        }

        printControl<Color>(
            "left",
            "Skin",
            RaceColors[this.entity.appearance.race] as any,
            this.entity.appearance.bodyColor,
            (t: Color) => {
                this.entity.appearance.bodyColor = t
                this.entity.appearance.noseColor = t
            },
            true,
        )

        printControl<Sex>(
            "left",
            "Sex",
            SexTypes as any,
            this.entity.appearance.sex,
            (t: Sex) => {
                this.entity.appearance.sex = t
                this.handleInventory()
            },
            true,
        )

        printControl<Hair | "none">(
            "top",
            "Hair",
            ["none", ...(HairTypes as any)],
            this.entity.appearance.hair,
            (t: Hair | "none") => {
                if (t == "none") {
                    this.entity.appearance.hair = null
                } else {
                    this.entity.appearance.hair = t
                }
            },
            true,
        )

        if (this.entity.appearance.hair) {
            // hair color
            printControl<Color>(
                "top",
                "Color",
                HairColors[this.entity.appearance.hair] as any,
                this.entity.appearance.hairColor,
                (t: Color) => (this.entity.appearance.hairColor = t),
                true,
            )
        }

        printControl<Nose>(
            "right",
            "Nose",
            RaceNoses[this.appearance.race] as any,
            this.appearance.nose,
            (t: Nose) => {
                this.appearance.nose = t
                this.appearance.noseColor = this.appearance.bodyColor
            },
            true,
        )

        printControl<Color>(
            "right",
            "Eyes",
            EyesColors[this.appearance.eyes] as any,
            this.appearance.eyesColor,
            (t: Color) => (this.appearance.eyesColor = t),
            true,
        )

        toggles.x = 150
        toggles.y = 20
        this.append2(toggles, {
            ignorePrevious: true,
        })

        printControl<Shirt | "none">(
            "bottom",
            "Shirt",
            this.appearance.sex === "male"
                ? [...SexShirts[this.appearance.sex], "none"]
                : SexShirts[this.appearance.sex],
            locateInventoryActiveItem(this.entity.inventory, "shirt")?.type as any,
            (t: Shirt | "none") => {
                const shirt = locateInventoryActiveItemObject(this.entity.inventory, "shirt")
                if (shirt) {
                    this.entity = removeInventoryItem(this.entity, shirt.id)
                }

                if (t !== "none") {
                    const itemTypeMeta: ItemTypeMeta = {
                        type: t,
                        class: "shirt",
                        color: randomOneOf(ShirtColors[t]),
                    }
                    this.entity = addInventoryItem(this.entity, {
                        id: randomId(),
                        itemType: "none",
                        itemTypeMeta,
                        active: true,
                    })
                } else {
                    // alert("hey")
                }
            },
            true,
        )

        printControl<Pants>(
            "bottom",
            "Pants",
            SexPants[this.appearance.sex] as any,
            locateInventoryActiveItem(this.entity.inventory, "pants")?.type as any,
            (t: Pants) => {
                const pants = locateInventoryActiveItemObject(this.entity.inventory, "pants")
                if (pants) {
                    this.entity = removeInventoryItem(this.entity, pants.id)
                }
                const itemTypeMeta: ItemTypeMeta = {
                    type: t,
                    class: "pants",
                    color: randomOneOf(PantsColors[t]),
                }

                this.entity = addInventoryItem(this.entity, {
                    id: randomId(),
                    itemType: "none",
                    itemTypeMeta,
                    active: true,
                })
            },
            true,
        )

        printControl<Shoe>(
            "bottom",
            "Shoes",
            SexShoes[this.appearance.sex] as any,
            locateInventoryActiveItem(this.entity.inventory, "shoes")?.type as any,
            (t: Shoe) => {
                const shoe = locateInventoryActiveItemObject(this.entity.inventory, "shoes")
                if (shoe) {
                    this.entity = removeInventoryItem(this.entity, shoe.id)
                }
                const itemTypeMeta: ItemTypeMeta = {
                    type: t,
                    class: "shoes",
                    color: randomOneOf(ShoeColors[t]),
                }

                this.entity = addInventoryItem(this.entity, {
                    id: randomId(),
                    itemType: "none",
                    itemTypeMeta,
                    active: true,
                })
            },
            true,
        )

        toggles.x = 150
        toggles.y = 20
        this.append2(toggles, {
            ignorePrevious: true,
        })

        this.append2(spacer(5))

        this.builder.updateNavButtons([
            {
                title: "Random Appearance",
                callback: () => {
                    this.appearance = randomAppearanceFor(this.entity, "human", this.entity.appearance.sex)
                    this.entity.appearance = this.appearance
                    this.handleInventory()
                    this.build()
                },
            },
            {
                title: "Random Outfit",
                callback: () => {
                    this.inventory = this.generateInventory()
                    this.handleInventory()
                    this.build()
                },
            },
            {
                title: "Next",
                callback: () => {
                    this.builder.submitCharacter(this.appearance, this.inventory)
                    this.builder.resetNavButtons()
                    this.builder.switchPage("intro2")
                },
            },
        ])
    }
}

interface NavButtonMeta {
    title: string
    callback: Callback<void>
}

type PageId = "intro1" | "intro2" | "review" | "build"

export class CharacterBuilder extends FullScreenModal {
    logic: ClientGameLogic

    pageMap: IndexedMap<CharacterBuilderPage> = new IndexedMap()
    currentPageId: PageId = "intro1"
    navButtons: NavButtonMeta[] = []

    main: DyanmicTextContainer
    pageContainer: DyanmicTextContainer
    navContainer: DyanmicTextContainer

    initialized: boolean

    constructor(guiManager: GuiManager, logic: ClientGameLogic) {
        super(guiManager, {
            opaque: true,
            unescapable: true,
        })
        this.logic = logic
    }

    updateNavButtons = (buttons: NavButtonMeta[]) => {
        this.navButtons = buttons
    }

    submitCharacter = (appearance: Appearance, inventory: Inventory) => {
        this.logic.playerController.client.saveAppearance(appearance, inventory)
    }

    resetNavButtons = () => {
        this.navButtons = [
            {
                title: "Edit character",
                callback: () => {
                    this.switchPage("build")
                },
            },
            {
                title: "Review controls",
                callback: () => {
                    this.switchPage("review")
                },
            },
            {
                title: "Lets go!",
                callback: () => {
                    this.close()
                },
            },
        ]
    }

    initialize = () => {
        const player = this.guiManager.getPlayer()

        if (player.inventory.items?.length < 1) {
            return
        }

        if (!player.appearance) {
            return
        }

        const intro1 = new IntroPage1()
        intro1.entityId = "intro1"
        const intro2 = new IntroPage2()
        intro2.entityId = "intro2"
        const review = new ReviewPage()
        review.entityId = "review"
        const build = new BuildPage(this.guiManager, player)
        build.entityId = "build"

        this.pageMap.set(intro1.entityId, intro1)
        this.pageMap.set(intro2.entityId, intro2)
        this.pageMap.set(review.entityId, review)
        this.pageMap.set(build.entityId, build)

        this.pageMap.iterate(page => {
            page.inject(this)
        })

        this.resetNavButtons()
        this.initialized = true

        return true
    }

    switchPage = (newPageId: PageId) => {
        this.main.wipe()

        const currentPage = this.pageMap.get(this.currentPageId)
        currentPage.parent.removeChild(currentPage)
        this.pageContainer.wipe()
        const page = this.pageMap.get(newPageId)
        page.build()
        this.pageContainer.append(page)
        this.currentPageId = newPageId

        page.alpha = 0
        new Fader(page, {
            direction: "in",
            minAlpha: 1.0,
            fadeAlphaDecrement: 0.01,
        }).update()

        this.main.append(this.pageContainer)
        this.rebuildNav()
    }

    buildNav = () => {
        this.navContainer.wipe()

        const navButtonsContainer = new DyanmicTextContainer()

        this.navButtons.forEach((btnMeta, i) => {
            const btn: DynamicText = new DynamicText(btnMeta.title, {
                type: "button|dynamic",
                onClick: () => {
                    btnMeta.callback()
                },
            })

            navButtonsContainer.append2(btn, {
                sameLine: true,
                leftMargin: i > 0 ? 5 : undefined,
                leftSpacer: i == this.navButtons.length - 1 ? 765 - btn.width : undefined,
            })
        })
        this.navContainer.append2(navButtonsContainer)
    }

    rebuildNav = () => {
        this.buildNav()
        const line = new Graphics()
        line.beginFill(0xffffff)
        line.drawRect(0, 0, 770, 2)
        line.endFill()
        this.main.append2(line, {
            exactLocation: {
                x: 5,
                y: BOTTOM - this.navContainer.height - 10 - 10,
            },
        })
        this.main.append2(this.navContainer, {
            exactLocation: {
                x: 5,
                y: BOTTOM - this.navContainer.height - 10,
            },
        })

        line.alpha = 0.0
        this.navContainer.alpha = 0.0

        new Fader(line, {
            direction: "in",
            minAlpha: 1.0,
            fadeAlphaDecrement: 0.01,
        }).update()

        new Fader(this.navContainer, {
            direction: "in",
            minAlpha: 1.0,
            fadeAlphaDecrement: 0.01,
        }).update()
    }

    createContent = () => {
        this.main = new DyanmicTextContainer()
        this.pageContainer = new DyanmicTextContainer()
        this.navContainer = new DyanmicTextContainer()

        if (!this.initialized) {
            if (!this.initialize()) {
                return this.main
            }
        }
        const { currentPageId } = this
        const page = this.pageMap.get(currentPageId)
        const doneCallback = page.build() as any
        this.pageContainer.append2(page)
        this.main.append2(this.pageContainer)

        if (!doneCallback) {
            this.rebuildNav()
        } else {
            doneCallback(() => {
                this.rebuildNav()
            })
        }

        return this.main
    }
}
