import { sound } from "@pixi/sound"
import { ItemTypeMeta } from "game-common/item/item"
import { Entity, EntityId, Latch, randomBool, randomFloatBetween } from "game-common/models"
import { randomOneOf } from "game-common/util"
import { IMediaInstance, Loader } from "pixi.js"

import { ClientGameLogic } from "./client_game_logic"

interface FootstepMeta {
    latch: Latch
    soundIdx: number
    soundIdxMax: number
}

interface SoundtrackMeta {
    trackId: string
    player: IMediaInstance
}

export class Audio {
    private logic: ClientGameLogic
    private walkSoundEffectLatch: Map<EntityId, FootstepMeta> = new Map()
    private playingSoundtrack: Record<string, SoundtrackMeta> = {}
    private static soundOptions: Record<string, any> = {}
    private volume: number = 0.7

    constructor(logic: ClientGameLogic) {
        this.logic = logic
    }

    static loadAssets = () => {
        // sound effects
        // for (let i = 0; i <= 8; i++) {
        //     Loader.shared.add(`footsteps${i}`, `assets/boots/${i}.ogg`)
        // }
        for (let i = 0; i <= 1; i++) {
            Loader.shared.add(`footsteps${i}`, `assets/grass/${i}.ogg`)
        }
        for (let i = 0; i <= 1; i++) {
            Loader.shared.add(`swamp${i}`, `assets/swamp/${i}.ogg`)
        }

        const audioPopulator = (category: string, action: string, numAssets: number, extension: string = "mp3") => {
            for (let i = 1; i <= numAssets; i++) {
                Loader.shared.add(`${category}-${action}-${i}`, `assets/${category}/${action}/${i}.${extension}`)
            }
        }
        // audioPopulator("zombie", "attack", 7)
        // audioPopulator("zombie", "death", 6)
        // audioPopulator("zombie", "detect", 5)
        // audioPopulator("zombie", "hit", 6)
        // audioPopulator("human", "death", 8, "ogg")

        // soundtrack
        // Loader.shared.add('dome1.ogg', 'assets/dome1.ogg')
        Loader.shared.add("overworld1.ogg", "assets/overworld1.ogg")
        Loader.shared.add("overworld-day-noises.ogg", "assets/overworld-day-noises.ogg")
        Loader.shared.add("overworld-night-noises.ogg", "assets/overworld-night-noises.ogg")
        Loader.shared.add("dungeon-ambience.ogg", "assets/dungeon-ambience.ogg")
        Loader.shared.add("door-open.ogg", "assets/door-open.ogg")
        Loader.shared.add("to_battle.ogg", "assets/to_battle.ogg")

        Audio.soundOptions["overworld1.ogg"] = {
            volume: 0.15,
        }
        Audio.soundOptions["overworld-day-noises.ogg"] = {
            volume: 3.8,
        }
        Audio.soundOptions["overworld-night-noises.ogg"] = {
            volume: 0.25,
        }
        Audio.soundOptions["dungeon-ambience.ogg"] = {
            volume: 0.5,
        }
        Audio.soundOptions["powerup"] = {
            volume: 0.5,
        }
        Audio.soundOptions["door_close"] = {
            volume: 0.05,
        }
        Audio.soundOptions["door_open"] = {
            volume: 0.05,
        }
        Audio.soundOptions["door-open.ogg"] = {
            volume: 0.25,
        }
    }

    walking = (entity: Entity) => {
        //
    }

    emp = (entity: Entity) => {
        if (!this.logic.isTooDistantFromPlayer(entity)) {
            this.play("emp", {
                volume: 0.25,
            })
        }
    }

    attack = (entity: Entity) => {
        if (!this.logic.isTooDistantFromPlayer(entity)) {
            this.play(["bow", "crossbow"].includes(entity.weapon?.weaponType) ? "bowshot" : "swordswing", {
                volume: 0.05,
                speed: 0.85 + (randomBool() ? -1 : 1) * randomFloatBetween(0.0, 0.03),
            })
        }
    }

    hit = (entity: Entity) => {
        this.play(`hit${randomOneOf([1, 2])}`, {
            volume: 0.075,
            speed: 0.75 + Math.min(0.25, Math.random()),
        })

        // if (entity.npcType === "zombie") {
        //     if (randomIntBetween(0, 2) === 0) {
        //         this.play(`zombie-hit-${randomIntBetween(1, 6)}`, {
        //             volume: 0.20,
        //             speed: 0.85 + Math.min(0.15, Math.random())
        //         })
        //     }
        // }
    }

    death = (entity: Entity) => {
        // if (entity.npcType === "zombie") {
        //     if (randomBool()) {
        //         this.play(`zombie-death-${randomIntBetween(1, 6)}`, {
        //             volume: 0.20,
        //             speed: 0.85 + Math.min(0.15, Math.random())
        //         })
        //     }
        // }
    }

    destroy = (entity: Entity) => {
        this.walkSoundEffectLatch.delete(entity.entityId)
    }

    walk = (entity: Entity) => {
        const baseline = 300
        let meta = this.walkSoundEffectLatch.get(entity.entityId)
        if (!meta) {
            meta = {
                latch: new Latch(baseline),
                soundIdx: -1,
                soundIdxMax: 2,
            }
            this.walkSoundEffectLatch.set(entity.entityId, meta)
        }
        meta.latch.update(baseline)
        if (meta.latch.expired()) {
            meta.soundIdx++
            if (meta.soundIdx > meta.soundIdxMax - 1) {
                meta.soundIdx = 0
            }
            const footstep = entity.submerged ? `swamp${meta.soundIdx}` : `footsteps${meta.soundIdx}`
            this.play(footstep, {
                volume: 0.08 + (randomBool() ? -1 : 1) * randomFloatBetween(0.01, 0.03),
                speed: 0.85 + (randomBool() ? -1 : 1) * randomFloatBetween(0.0, 0.15),
            })
        }
    }

    mapLocationFound = () => {
        this.play("map_location", {
            volume: 0.5,
        })
    }

    pickup = (itemTypeMeta: ItemTypeMeta) => {
        if (itemTypeMeta.type === "shard-metal") {
            this.play("coins", {
                volume: 0.1,
            })
        } else {
            this.play("cloth", {
                volume: 0.05,
            })
        }
    }

    static isSoundtrack = (effect: string) => {
        if (!effect) {
            return true
        }
        if (effect === "overworld1.ogg") {
            return true
        }
        if (effect === "overworld-day-noises.ogg") {
            return true
        }
        if (effect === "overworld-night-noises.ogg") {
            return true
        }
        if (effect === "dungeon-ambience.ogg") {
            return true
        }
        if (effect === "to_battle.ogg") {
            return true
        }

        return false
    }

    soundEffect = async (effect: string, loop: boolean, target: string) => {
        if (this.logic.runLevel !== "prod") {
            return
        }
        if (Audio.isSoundtrack(effect)) {
            const targetSoundtrack = this.playingSoundtrack[target]

            if (targetSoundtrack && (!effect || targetSoundtrack?.trackId !== effect)) {
                // if no effect, or its a different track for the target
                // then halt it
                if (!effect) {
                    delete this.playingSoundtrack[target]
                }
                const player = targetSoundtrack?.player

                // halt the currently played soundtrack
                if (player) {
                    const maxVolume = player.volume
                    const incr = Math.max(0.01, maxVolume / 50)
                    let prev = -1
                    const volInterval = setInterval(() => {
                        if (player.volume < 0.01 || prev === player.volume) {
                            clearInterval(volInterval)
                            player.stop()
                            return
                        }

                        prev = player.volume
                        player.set("volume", player.volume - incr)
                    }, 50)
                }
            }

            if (!effect) {
                return
            }

            if (targetSoundtrack && targetSoundtrack.trackId === effect) {
                // soundtrack already playing for the target
                return
            }

            const targetVolume = (Audio.soundOptions[effect]?.volume || 1.0) * this.volume
            const incr = Math.max(0.01, targetVolume / 50)
            const track: SoundtrackMeta = {
                trackId: effect,
                player: null,
            }
            this.playingSoundtrack[target] = track

            const instance = await sound.play(effect, {
                ...(Audio.soundOptions[effect] || {}),
                volume: 0.01 * this.volume,
                loop,
            })
            const volInterval = setInterval(() => {
                if (instance.volume >= targetVolume) {
                    clearInterval(volInterval)
                    return
                }

                instance.set("volume", (instance.volume + incr) * this.volume)
            }, 50)
            track.player = instance

            return
        }

        const playOptions = {
            ...(Audio.soundOptions[effect] || {}),
        }

        const volume = (playOptions.volume || 1.0) * this.volume
        await sound.play(effect, {
            ...playOptions,
            volume,
        })
    }

    // stopSoundtrack = () => {
    //     this.activeSoundtrackId = null
    //     this.playingSoundtrack.stop()
    // }

    play = (s: string, options: any = {}) => {
        if (this.logic.runLevel !== "prod") {
            return
        }
        const volume =
            (options.volume === undefined ? (Audio.soundOptions[s]?.volume || 1.0) * this.volume : options.volume) *
            this.volume
        sound?.play(s, {
            ...options,
            volume,
        })
    }

    setVolume = (volume: number) => {
        this.volume = volume
        Object.values(this.playingSoundtrack).forEach((track: SoundtrackMeta) => {
            if (track.player) {
                const updatedVolume = (Audio.soundOptions[track.trackId]?.volume || 1.0) * volume
                track.player.set("volume", updatedVolume)
            }
        })
    }
}
