import {
    Color,
    Eyes,
    EyesColors,
    Hair,
    HairColors,
    HairTypes,
    Nose,
    NoseColors,
    Race,
    RaceColors,
    RaceEyes,
    RaceNoses,
    RaceSexes,
    RaceTypes,
    Sex,
} from "game-common/character/character"
import { randomAppearanceFor } from "game-common/lpc/lpc"
import { Appearance, Entity } from "game-common/models"
import { Graphics } from "pixi.js"
import { ClientGameLogic } from "../../../../client_game_logic"
import { GuiManager } from "../gui_manager"
import { LpcLayered } from "../sprites/lpc_layered"
import { DyanmicTextContainer } from "./dynamic_text_container"
import { FullScreenModal } from "./fullscreen_modal"

export class CharacterCustomizer extends FullScreenModal {
    logic: ClientGameLogic

    private race: Race = "human"
    private bodyColor: Color = "white"
    private sex: Sex = "male"
    private hair: Hair
    private hairColor: Color
    private nose: Nose
    private noseColor: Color
    private eyes: Eyes
    private eyesColor: Color
    private entity: Entity

    constructor(guiManager: GuiManager, logic: ClientGameLogic) {
        super(guiManager)
        this.logic = logic
    }

    save = () => {
        const appearance: Appearance = {
            race: this.race,
            bodyColor: this.bodyColor,
            sex: this.sex,
            hair: this.hair,
            hairColor: this.hairColor,
            nose: this.nose,
            noseColor: this.noseColor,
            eyes: this.eyes,
            eyesColor: this.eyesColor,
            inventoryAppearanceHash: this.entity.appearance.inventoryAppearanceHash,
        }
        this.logic.saveAppearance(appearance)
        this.modal.close()
    }

    createContent = () => {
        if (!this.entity) {
            this.entity = this.guiManager.getPlayer()
        }

        const main = new DyanmicTextContainer({
            defaultTextFont: "ChatFont",
        })

        const spacer = () => {
            const spacer = new Graphics()
            spacer.x = 0
            spacer.y = 0
            spacer.drawRect(0, 0, 0, 10)
            return spacer
        }

        const refresh = () => {
            if ((this.nose && !RaceNoses[this.race]) || RaceNoses[this.race]?.indexOf(this.nose) < 0) {
                this.nose = RaceNoses[this.race] ? RaceNoses[this.race][0] : undefined
            }
            if ((this.eyes && !RaceEyes[this.race]) || RaceEyes[this.race]?.indexOf(this.eyes) < 0) {
                this.eyes = RaceEyes[this.race] ? RaceEyes[this.race][0] : undefined
            }
            if ((this.sex && !RaceSexes[this.race]) || RaceSexes[this.race]?.indexOf(this.sex) < 0) {
                this.sex = RaceSexes[this.race][0]
            }

            this.entity.appearance.race = this.race
            this.entity.appearance.bodyColor = this.bodyColor
            this.entity.appearance.sex = this.sex
            this.entity.appearance.hair = this.hair
            this.entity.appearance.hairColor = this.hairColor
            this.entity.appearance.nose = this.nose
            this.entity.appearance.noseColor = this.noseColor
            this.entity.appearance.eyes = this.eyes
            this.entity.appearance.eyesColor = this.eyesColor
            this.update()
        }

        const printControl = <T>(title: string, range: T[], t: T, setter: (t: T) => void, suppressNone?: boolean) => {
            const previous = <T>(range: T[], t: T, setter: (t: T) => void) => {
                let idx = range.indexOf(t) - 1
                if (idx < 0) {
                    idx = range.length - 1
                }
                setter(range[idx])
                refresh()
            }

            const next = <T>(range: T[], t: T, setter: (t: T) => void) => {
                let idx = range.indexOf(t) + 1
                if (idx > range.length - 1) {
                    idx = 0
                }
                setter(range[idx])
                refresh()
            }

            main.append(`${title}: ` + (t || " - none - "))
            main.append("Previous", false, undefined, undefined, undefined, "button", () => {
                previous<T>(range as any, t, setter)
            })
            main.append(" ", true)
            main.append("Next", true, undefined, undefined, undefined, "button", () => {
                next<T>(range as any, t, setter)
            })
            if (!suppressNone) {
                main.append(" ", true)
                main.append("None", true, undefined, undefined, undefined, "button", () => {
                    setter(undefined)
                    refresh()
                })
            }
            main.append(spacer())
        }

        main.append("Randomize", true, undefined, undefined, undefined, "button", () => {
            const { race, bodyColor, sex, hair, hairColor, nose, noseColor, eyes, eyesColor } = randomAppearanceFor()
            this.race = race
            this.bodyColor = bodyColor
            this.sex = sex
            this.hair = hair
            this.hairColor = hairColor
            this.nose = nose
            this.noseColor = noseColor
            this.eyes = eyes
            this.eyesColor = eyesColor
            refresh()
        })
        main.append(" ", true)
        main.append("Save", true, undefined, undefined, undefined, "button", () => {
            this.save()
        })

        main.append(spacer())
        main.append(spacer())

        // race
        printControl<Race>("Race", RaceTypes as any, this.race, (t: Race) => (this.race = t), true)
        // bodyColor
        printControl<Color>(
            "Body Color",
            RaceColors[this.race] as any,
            this.bodyColor,
            (t: Color) => (this.bodyColor = t),
            true,
        )

        // sex
        printControl<Sex>("Sex", RaceSexes[this.race] as any, this.sex, (t: Sex) => (this.sex = t), true)

        // hair
        printControl<Hair>("Hair", HairTypes as any, this.hair, (t: Hair) => (this.hair = t))

        if (this.hair) {
            // hair color
            printControl<Color>(
                "Hair Color",
                HairColors[this.hair] as any,
                this.hairColor,
                (t: Color) => (this.hairColor = t),
            )
        }

        if (RaceEyes[this.race]?.length > 0) {
            // Eyes
            printControl<Eyes>("Eyes", RaceEyes[this.race] as any, this.eyes, (t: Eyes) => (this.eyes = t))

            if (this.eyes) {
                printControl<Color>(
                    "Eyes Color",
                    EyesColors[this.eyes] as any,
                    this.eyesColor,
                    (t: Color) => (this.eyesColor = t),
                )
            }
        }

        if (RaceNoses[this.race]?.length > 0) {
            // Nose
            printControl<Nose>("Nose", RaceNoses[this.race] as any, this.nose, (t: Nose) => (this.nose = t))

            if (this.nose) {
                printControl<Color>(
                    "Nose Color",
                    NoseColors[this.nose] as any,
                    this.noseColor,
                    (t: Color) => (this.noseColor = t),
                )
            }
        }

        const lpcComposite = new LpcLayered("", () => {})
        lpcComposite.updatePaperdoll(this.entity)
        lpcComposite.scale.set(4)

        lpcComposite.x = this.width() - 150
        lpcComposite.y = 250

        lpcComposite.doMovement(this.entity)

        main.addChild(lpcComposite)

        return main
    }
}
