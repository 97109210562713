import { Cape, Color, Pants, Sex, SexCapes, SexPants, SexShirts, SexShoes, Shirt, Shoe } from "../character/character"
import {
    activateInventoryItem,
    addInventoryItem,
    CraftableItemTypeMeta,
    createInventoryItem,
    ItemCharacteristics,
    ItemClass,
    ItemMaterial,
    ItemRecipe,
    ItemTypeMeta,
    locateInventoryActiveItemObject,
    locateInventoryItemObjectByItemTypeMeta,
    maxInventoryItemsSize,
    resolveItemTypeToMeta,
    TradingValueItemTypeMeta,
    updateInventoryItemQuantity,
} from "../item/item"
import { ItemClassTypes } from "../item/item_class"
import { ItemClassCharacteristics } from "../item/item_class_characteristics"
import { itemRecipes } from "../item/item_crafting"
import { BaseTradeValue } from "../item/item_trade"
import { AllItemTypes, Ammo, ItemTypes } from "../item/item_type"
import { ItemTypeCharacteristics } from "../item/item_type_characteristics"
import { ItemTypesProtection } from "../item/item_type_protection"
import { ItemTypesWeight } from "../item/item_type_weight"
import { AmmoMeta, Entity, Inventory, InventoryItem, TileSize, WeaponCharacteristics, WeaponType } from "../models"
import { FeatureCatalogs } from "../room/feature_catalog"
import { FragmentPointer } from "../room/house/feature/house"
import { Skill } from "../skills/skills"
import { randomId } from "../util"
import { Mechanics } from "./mechanics"

class Weight {
    static maximumItemWeight = 15
    static maximumTotalItemWeight = 0

    static {
        this.maximumTotalItemWeight = ItemClassTypes.map(type => {
            const itemTypeWeights = ItemTypesWeight[type] || {}
            const sorted = Object.keys(itemTypeWeights)
                .map(t => itemTypeWeights[t])
                .sort()
            return sorted[sorted.length - 1] || 0
        }).reduce((a, n) => a + n * this.maximumItemWeight, 0)
    }

    static computeItemWeight = (item: InventoryItem) => {
        const { itemTypeMeta } = item
        if (!itemTypeMeta) {
            return 0
        }

        const { type } = itemTypeMeta
        const itemTypes = ItemTypesWeight[itemTypeMeta.class]
        if (!itemTypes) {
            return 0
        }

        return (itemTypes[type] || 0) * this.maximumItemWeight
    }

    static computeWeight = (inventory: Inventory) => {
        // only consider active (equipped) items
        const activeItems = inventory.items?.filter(item => item.active)
        return activeItems?.map(i => this.computeItemWeight(i)).reduce((a, n) => a + n, 0)
    }

    static computeWeightPctOfMax = (inventory: Inventory) => {
        const f = this.computeWeight(inventory)
        return f / this.maximumTotalItemWeight
    }
}

class Protection {
    static maximumItemProtection = 15
    static maximumTotalItemProtection = 0

    static {
        this.maximumTotalItemProtection = ItemClassTypes.map(type => {
            const itemTypeProtections = ItemTypesProtection[type] || {}
            const sorted = Object.keys(itemTypeProtections)
                .map(t => itemTypeProtections[t])
                .sort()

            return sorted[sorted.length - 1] || 0
        }).reduce((a, n) => a + n * this.maximumItemProtection, 0)
    }

    static computeItemProtection = (item: InventoryItem) => {
        return this.computeItemProtectionPct(item.itemTypeMeta) * this.maximumItemProtection
    }

    static computeItemProtectionPct = (itemTypeMeta: ItemTypeMeta) => {
        if (!itemTypeMeta) {
            return 0
        }

        const { type } = itemTypeMeta
        const itemTypes = ItemTypesProtection[itemTypeMeta.class]
        if (!itemTypes) {
            return 0
        }

        return itemTypes[type] || 0
    }

    static computeProtection = (inventory: Inventory) => {
        // only consider active (equipped) items
        const activeItems = inventory.items?.filter(item => item.active) || []
        return activeItems.map(i => this.computeItemProtection(i)).reduce((a, n) => a + n, 0)
    }

    static computeProtectionPctOfMax = (inventory: Inventory) => {
        const f = this.computeProtection(inventory)
        return f / this.maximumTotalItemProtection
    }
}

class Properties {
    private static hasProperty = (itemTypeMeta: ItemTypeMeta, prop: keyof ItemCharacteristics): boolean => {
        if (prop === "disallowedClassesOnActive" || prop === "buildType") {
            return (
                !!ItemTypeCharacteristics[itemTypeMeta.type]?.[prop] ||
                !!ItemClassCharacteristics[itemTypeMeta.class]?.[prop]
            )
        }

        if (ItemTypeCharacteristics[itemTypeMeta.type]?.[prop] !== undefined) {
            return ItemTypeCharacteristics[itemTypeMeta.type][prop]
        }
        const klass = ItemClassCharacteristics[itemTypeMeta.class]
        return klass?.[prop]
    }

    static toItemTypeMeta = (itemType: ItemTypes, color?: Color) => {
        return resolveItemTypeToMeta(itemType, color, true)
    }

    static toSpriteId = (itemType: ItemTypes, color?: Color) => {
        const itemTypeMeta = this.toItemTypeMeta(itemType, color)
        if (!itemTypeMeta) {
            return ""
        }
        return itemTypeMeta.spriteId || `${itemTypeMeta.type}-${itemTypeMeta.color}.png`
    }

    static buildable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "buildable")
    }

    static exclusiveReadiness = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "exclusiveReadiness")
    }

    static buildType = (itemTypeMeta: ItemTypeMeta): BuildType => {
        return ItemTypeCharacteristics[itemTypeMeta.type].buildType
    }

    static disallowedClassesOnActive = (itemTypeMeta: ItemTypeMeta): ItemClass[] => {
        if (!this.hasProperty(itemTypeMeta, "disallowedClassesOnActive")) {
            return []
        }

        return (
            ItemClassCharacteristics[itemTypeMeta.class]["disallowedClassesOnActive"] ||
            ItemTypeCharacteristics[itemTypeMeta.type]["disallowedClassesOnActive"] ||
            []
        )
    }

    static useable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "useable")
    }

    static readyable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "readyable")
    }

    static showWorldOnUse = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "useHasWorldEffect")
    }

    static stackable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "stackable")
    }

    static unsellable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "unsellable")
    }

    static unbuyable = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "unbuyable")
    }

    static hasDurability = (itemTypeMeta: ItemTypeMeta) => {
        return this.hasProperty(itemTypeMeta, "hasDurability")
    }

    static currency = (itemTypeMeta: ItemTypeMeta) => {
        // todo make this less brittle
        if (itemTypeMeta.type === "shard-metal") {
            return true
        }

        return false
    }

    static equippable = (itemTypeMeta: ItemTypeMeta, entity: Entity) => {
        if (!ItemClassCharacteristics[itemTypeMeta.class]?.equippable) {
            return false
        }

        if (itemTypeMeta.class === "shirt") {
            const items = SexShirts[entity.appearance?.sex]
            return !!items && !!items.find(i => i === itemTypeMeta.type)
        }

        if (itemTypeMeta.class === "pants") {
            const items = SexPants[entity.appearance?.sex]
            return !!items && !!items.find(i => i === itemTypeMeta.type)
        }

        if (itemTypeMeta.class === "shoes") {
            const items = SexShoes[entity.appearance?.sex]
            return !!items && !!items.find(i => i === itemTypeMeta.type)
        }

        if (itemTypeMeta.class === "cape") {
            const items = SexCapes[entity.appearance?.sex]
            return !!items && !!items.find(i => i === itemTypeMeta.type)
        }

        return true
    }

    static itemSex = (itemTypeMeta: ItemTypeMeta): Sex | "both" | "na" => {
        const { type, class: itemClass } = itemTypeMeta
        const evaluator = (male: boolean, female: boolean) => {
            if (male && female) {
                return "both"
            }
            if (!male && female) {
                return "female"
            }
            if (male && !female) {
                return "male"
            }
            return "na"
        }

        if (itemClass === "cape") {
            return evaluator(SexCapes["male"]?.includes(type as Cape), SexCapes["female"]?.includes(type as Cape))
        }

        if (itemClass === "pants") {
            return evaluator(SexPants["male"]?.includes(type as Pants), SexPants["female"]?.includes(type as Pants))
        }

        if (itemClass === "shirt") {
            return evaluator(SexShirts["male"]?.includes(type as Shirt), SexShirts["female"]?.includes(type as Shirt))
        }

        if (itemClass === "shoes") {
            return evaluator(SexShoes["male"]?.includes(type as Shoe), SexShoes["female"]?.includes(type as Shoe))
        }

        return "na"
    }
}

class Trading {
    static isCurrency = (item: InventoryItem) => {
        return ItemTypeCharacteristics[item.itemTypeMeta.type]?.isCurrency
    }

    static isAmmo = (item: InventoryItem) => {
        return ItemTypeCharacteristics[item.itemTypeMeta.type]?.isAmmo
    }

    static isItemSellable = (item: InventoryItem) => {
        if (Properties.unsellable(item.itemTypeMeta)) {
            return false
        }
        const baseTradeValue = BaseTradeValue[item.itemTypeMeta.type]
        if (baseTradeValue && BaseTradeValue[item.itemTypeMeta.type]?.quantity < 1) {
            return false
        }

        return true
    }

    static computeSellValueCost = (itemTypeMeta: ItemTypeMeta, quantity: number): TradingValueItemTypeMeta => {
        const base = BaseTradeValue[itemTypeMeta.type]
        if (base) {
            // markdown; todo, this might depend on the seller and the buyer
            const markup = 1
            return {
                itemType: base.itemType,
                quantity: Math.ceil(base.quantity * markup * quantity),
            }
        }

        if (itemTypeMeta.class === "resource") {
            return {
                itemType: "shard-metal",
                quantity: 1,
            }
        }

        return {
            itemType: "shard-metal",
            quantity: 10,
        }
    }

    static isItemBuyable = (itemTypeMeta: ItemTypeMeta): boolean => {
        return true
    }

    static computeBuyValueCost = (itemType: ItemTypes, quantity: number): TradingValueItemTypeMeta => {
        const base = BaseTradeValue[itemType]
        if (!base) {
            return {
                itemType: "shard-metal",
                quantity: 10,
            }
        }
        // markup; todo, this might depend on the seller and the buyer
        const markup = 1.25
        return {
            itemType: base.itemType,
            quantity: Math.ceil(base.quantity * markup * quantity),
        }
    }

    static getTradableItemTypes = () => {
        const buyable = []
        Object.keys(AllItemTypes).forEach(itemType => {
            const itemTypeMeta = ItemMechanics.properties.toItemTypeMeta(itemType as ItemTypes)
            if (!ItemMechanics.properties.unbuyable(itemTypeMeta)) {
                buyable.push(itemTypeMeta)
            }
        })

        return buyable
    }
}

export const buildTypes = ["wall", "decor", "entity", "roof", "floor", "floor2"] as const
export type BuildType = (typeof buildTypes)[number]
export type CraftableEntityType = "door" | "chest"

export interface CraftableMeta {
    buildType: BuildType
    fragmentPointer?: FragmentPointer
    entityType?: CraftableEntityType
    itemType?: ItemTypes
}

interface Ingredient {
    itemType: ItemTypes
    quantity: number
}

export interface CraftableReport {
    craftable: boolean
    isValid: boolean
    missingIngredients?: Ingredient[]
    missingSkills?: Skill[]
}

class Crafting {
    private static toKey = (next: ItemTypeMeta): string => next.class + "-" + next.type + "-" + next.color

    static getRecipeFor = (toFind: ItemTypeMeta): ItemRecipe => {
        return itemRecipes.find(next => this.toKey(next.itemTypeMeta) === this.toKey(toFind))
    }

    // todo - dry this up with computeCraftableItemTypes
    static isCraftable = (itemTypeMeta: ItemTypeMeta, inventory: Inventory, skills: Skill[] = []): CraftableReport => {
        const recipe = itemRecipes.find(
            r => r.itemTypeMeta.type === itemTypeMeta.type && itemTypeMeta.color == r.itemTypeMeta.color,
        )
        if (!recipe) {
            return {
                craftable: false,
                isValid: false,
            }
        }

        const resourceMap = inventory.items
            .filter(item => item.itemTypeMeta.class === "resource")
            .reduce((acc, next) => {
                const key = this.toKey(next.itemTypeMeta)
                return {
                    ...acc,
                    [key]: {
                        quantity: next.quantity || 1,
                        itemType: next.itemTypeMeta,
                    },
                }
            }, {})

        const { requiredSkills = [] } = recipe
        if (requiredSkills.length > 0 && !!requiredSkills.find(s => !skills.includes(s))) {
            return {
                craftable: false,
                isValid: false,
            }
        }

        const missingIngredients: Ingredient[] = []
        const foundIngredients = recipe.ingredients.filter(required => {
            const toLookupKey = this.toKey(required.itemTypeMeta)
            const ownedQuantity = resourceMap[toLookupKey]?.quantity || 0
            const found = ownedQuantity >= required.quantity
            if (!found) {
                const quantityDelta = Math.abs(ownedQuantity - required.quantity)
                const itemType = resourceMap[toLookupKey]?.itemType?.type
                missingIngredients.push({
                    itemType,
                    quantity: quantityDelta,
                })
            }
            return found
        })
        const craftable = foundIngredients.length === recipe.ingredients.length
        return {
            craftable,
            isValid: true,
            missingIngredients,
        }
    }

    static computeCraftableItemTypes = (inventory: Inventory, skills: Skill[] = []): CraftableItemTypeMeta[] => {
        const resourceMap = inventory.items
            .filter(item => item.itemTypeMeta.class === "resource")
            .reduce((acc, next) => {
                const key = this.toKey(next.itemTypeMeta)
                return {
                    ...acc,
                    [key]: next.quantity || 1,
                }
            }, {})

        return itemRecipes
            .filter(recipe => {
                const { requiredSkills = [] } = recipe
                if (requiredSkills.length > 0 && !!requiredSkills.find(s => !skills.includes(s))) {
                    return false
                }

                const foundIngredients = recipe.ingredients.filter(required => {
                    const toLookupKey = this.toKey(required.itemTypeMeta)
                    const ownedQuantity = resourceMap[toLookupKey] || 0
                    return ownedQuantity > 0
                })

                return foundIngredients.length === recipe.ingredients.length
            })
            .map(recipe => {
                const foundIngredients = recipe.ingredients.filter(required => {
                    const toLookupKey = this.toKey(required.itemTypeMeta)
                    const ownedQuantity = resourceMap[toLookupKey] || 0
                    return ownedQuantity >= required.quantity
                })
                return {
                    itemTypeMeta: recipe.itemTypeMeta,
                    craftable: foundIngredients.length === recipe.ingredients.length,
                }
            })
    }

    static computeCraftableMeta = (item: InventoryItem): CraftableMeta => {
        return this.computeCraftableMetaFromItemType(item?.itemTypeMeta?.type)
    }

    static computeCraftableMetaFromItemType = (itemType: ItemTypes): CraftableMeta => {
        if (!itemType) {
            return null
        }

        const pointer = FeatureCatalogs.instance().fragmentByItemType[itemType]
        if (pointer) {
            const feature = FeatureCatalogs.instance().featureByTag(pointer.catalog, pointer.tag)
            return feature?.craftableMeta
        }

        const itemTypeMeta = resolveItemTypeToMeta(itemType)
        const buildType = Mechanics.item.properties.buildType(itemTypeMeta)
        if (buildType) {
            return {
                itemType,
                buildType,
            }
        }

        return null
    }
}

class Projectile {
    private static ammoTypeToMeta: Map<Ammo, AmmoMeta>

    static resolveAmmoMetaFromAmmo = (ammoType: Ammo): AmmoMeta => {
        if (!this.ammoTypeToMeta) {
            this.ammoTypeToMeta = new Map()
            this.ammoTypeToMeta.set("webAmmo", {
                spriteId: "arrow-brown.png",
                effects: ["webslow"],
                createNpcProps: {
                    spriteId: "spiderweb-exact.png",
                    flags: {
                        contactEffects: ["webslow"],
                    },
                    msUntilExpiration: 5000,
                    dimensions: {
                        w: TileSize * 2,
                        h: TileSize * 2,
                    },
                },
            })
            this.ammoTypeToMeta.set("arrow", {
                spriteId: "arrow-brown.png",
                // createNpcProps: {
                //     spriteId: "arrow-brown.png",
                //     msUntilExpiration: 5000,
                // }
            })
        }
        return this.ammoTypeToMeta.get(ammoType)
    }

    static resolveProjectileSpriteId = (weaponType: WeaponType) => {
        const characteristic = WeaponCharacteristics[weaponType]
        if (!characteristic) {
            return
        }

        const spriteId = characteristic.bulletSpriteId
        if (spriteId) {
            return spriteId
        }
        const bulletType = characteristic.bulletType
        if (!bulletType) {
            return
        }

        let ammoMeta: AmmoMeta
        return ammoMeta?.spriteId
    }

    static isStunTypeWeapon = (weaponType: WeaponType) => {
        const characteristic = WeaponCharacteristics[weaponType]
        if (!characteristic) {
            return false
        }

        const bulletType = characteristic.bulletType
        if (!bulletType) {
            return false
        }

        return this.resolveAmmoMetaFromAmmo(bulletType)?.effects?.includes("webslow")
    }
}

export type ItemCondition = "new" | "sturdy" | "worn" | "fragile"

class Durability {
    static computeMaxDurability = (itemTypeMeta: ItemTypeMeta): number | undefined => {
        if (!Properties.hasDurability(itemTypeMeta)) {
            return
        }

        const material = Material.computeItemMaterial(itemTypeMeta)
        if (material === "metal") {
            return 100
        }
        if (material === "wood") {
            return 70
        }
        // todo - this should be more sophisticated, based on type and composition of the item
        return 100
    }

    static computeItemConditionPct = (inventoryItem: InventoryItem): number => {
        const maxDurabilty = this.computeMaxDurability(inventoryItem.itemTypeMeta)
        if (maxDurabilty === undefined) {
            return
        }
        const { hp = 0 } = inventoryItem
        const pct = hp / maxDurabilty
        return pct
    }

    static computeItemCondition = (inventoryItem: InventoryItem): ItemCondition | undefined => {
        const pct = this.computeItemConditionPct(inventoryItem)
        if (pct === undefined) {
            return
        }

        if (pct > 0.75) {
            return "new"
        }

        if (pct > 0.5) {
            return "sturdy"
        }

        if (pct > 0.25) {
            return "worn"
        }

        return "fragile"
    }
}

class Material {
    // todo - make this better
    static computeItemMaterial = (itemTypeMeta: ItemTypeMeta): ItemMaterial => {
        if (itemTypeMeta.class === "weapon") {
            if (["brown", "lightbrown"].includes(itemTypeMeta.color)) {
                return "wood"
            }
        }

        return "metal"
    }
}

export interface AddItemToInventoryResults {
    success: boolean
    excessQuantity?: number
    updatedEntity?: Entity
    updatedItem?: InventoryItem
    inventoryItemAdded?: boolean
    addedItems?: InventoryItem[]
}

export interface AddItemToInventoryProps {
    entity: Entity
    sourceItemEntity?: Entity
    sourceItem?: InventoryItem
    item: ItemTypeMeta
    quantity: number
}

export type CreateNewItemProps = AddItemToInventoryProps
export interface CreateNewItemResults {
    updatedEntity: Entity
    createdItem: InventoryItem
}

class InventoryMechanics {
    static stackLimit = () => 50

    static inventoryFull = (entity: Entity): boolean => {
        return entity.inventory?.items?.length > maxInventoryItemsSize
    }

    static canReceive = (receiver: Entity, itemType: ItemTypeMeta, quantity: number): boolean => {
        const isStackable = Properties.stackable(itemType)
        const stackLimit = this.stackLimit()
        const inventorySize = receiver.inventory?.items?.length
        const allSlotsOccupied = inventorySize >= maxInventoryItemsSize
        if (!isStackable && allSlotsOccupied) {
            return false
        }

        const existingItem = locateInventoryItemObjectByItemTypeMeta(receiver.inventory, itemType, stackLimit)
        const maximumNumberOfNewSlots = maxInventoryItemsSize - inventorySize
        let quantityForExistingItem = 0
        if (existingItem) {
            // update the existing item up to its capacity
            quantityForExistingItem = stackLimit - (existingItem.quantity || 1)
        }

        const quantityForNewItems = quantity - quantityForExistingItem
        const numberOfNewItems = Math.ceil(quantityForNewItems / stackLimit)

        if (numberOfNewItems > maximumNumberOfNewSlots) {
            return false
        }

        return true
    }

    static createNewItem = ({
        entity,
        sourceItemEntity,
        sourceItem,
        item,
        quantity,
    }: CreateNewItemProps): CreateNewItemResults => {
        // create new item because there's no existing stackable item to add to,
        // or that item is full up wrto stackable limit
        const isStackable = ItemMechanics.properties.stackable(item)
        const hp = !isStackable ? sourceItem?.hp : sourceItemEntity?.hp
        const newItem = createInventoryItem(item, {
            sourceItem: sourceItemEntity,
            quantity,
            hp,
            id: randomId(),
            flags: sourceItem?.flags,
        })
        let updatedEntity = addInventoryItem(entity, newItem)

        if (item.class === "weapon" && !entity.weapon && Mechanics.entity.inventory.canEquipWeapon(entity)) {
            const weaponType = item.type as WeaponType
            entity.weapon = {
                weaponType,
            }
            updatedEntity = activateInventoryItem(updatedEntity, newItem.id)
        }

        if (item.class === "armor") {
            const activeItem = locateInventoryActiveItemObject(entity.inventory, "armor")
            if (!activeItem) {
                updatedEntity = activateInventoryItem(updatedEntity, newItem.id)
            }
        }

        return {
            updatedEntity,
            createdItem: newItem,
        }
    }

    static addItemToInventory = ({
        entity,
        sourceItemEntity,
        sourceItem,
        item,
        quantity = 1,
    }: AddItemToInventoryProps): AddItemToInventoryResults => {
        const currentInventorySize = entity?.inventory?.items.length
        const isFull = currentInventorySize >= maxInventoryItemsSize
        const isStackable = ItemMechanics.properties.stackable(item)
        const stackLimit = isStackable ? InventoryMechanics.stackLimit() : 1
        const existingItem = locateInventoryItemObjectByItemTypeMeta(entity.inventory, item, stackLimit)

        const totalQuantity = (existingItem ? existingItem.quantity || 1 : 0) + quantity
        if (totalQuantity < 1) {
            return {
                success: false,
            }
        }

        if (totalQuantity < stackLimit) {
            // the total amount fits within the slot
            if (existingItem) {
                // an existing item exists, update it
                const updatedEntity = updateInventoryItemQuantity(entity, existingItem, quantity)
                return {
                    success: true,
                    updatedEntity,
                    updatedItem: existingItem,
                }
            } else {
                if (isFull) {
                    return {
                        success: false,
                    }
                } else {
                    // an existing item doesn't exist, create one
                    const { updatedEntity, createdItem } = this.createNewItem({
                        entity,
                        sourceItemEntity,
                        sourceItem,
                        item,
                        quantity,
                    })

                    return {
                        success: true,
                        updatedEntity,
                        addedItems: [createdItem],
                    }
                }
            }
        } else {
            // the total amount exceeds the slot, overflow other items if its possible

            const maximumNumberOfNewSlots = maxInventoryItemsSize - entity.inventory.items.length
            let updatedEntity: Entity
            let quantityForExistingItem = 0
            let createdItems: InventoryItem[] = []
            if (existingItem) {
                // update the existing item up to its capacity
                quantityForExistingItem = stackLimit - (existingItem.quantity || 1)
                updatedEntity = updateInventoryItemQuantity(entity, existingItem, quantityForExistingItem)
            }

            // spread the rest to new items
            const quantityForNewItems = quantity - quantityForExistingItem
            const numberOfNewItems = Math.ceil(quantityForNewItems / stackLimit)

            if (isFull && numberOfNewItems > 0) {
                return {
                    success: false,
                    updatedEntity,
                    updatedItem: existingItem,
                    excessQuantity: quantityForNewItems,
                }
            }

            let totalAdded = 0
            for (let i = 0; i < numberOfNewItems; i++) {
                if (i > maximumNumberOfNewSlots - 1) {
                    break
                }
                const itemsToAdd = i == numberOfNewItems - 1 ? quantityForNewItems - i * stackLimit : stackLimit
                const createResults = this.createNewItem({
                    entity: updatedEntity || entity,
                    sourceItemEntity,
                    sourceItem,
                    item,
                    quantity: itemsToAdd,
                })
                updatedEntity = createResults.updatedEntity
                createdItems.push(createResults.createdItem)
                totalAdded += itemsToAdd
            }

            return {
                success: true,
                excessQuantity: quantityForNewItems - totalAdded,
                updatedItem: existingItem,
                updatedEntity,
                addedItems: createdItems,
            }
        }
    }
}

export class ItemMechanics {
    static weight = Weight
    static protection = Protection
    static crafting = Crafting
    static trading = Trading
    static properties = Properties
    static projectile = Projectile
    static durability = Durability
    static material = Material
    static inventory = InventoryMechanics
}
