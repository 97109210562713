import { BitmapText } from "pixi.js"
import { SpriteMeta } from "../entity_rendering_manager"
import { BasicSprite } from "./basic_sprite"

export class SignSprite extends BasicSprite {
    labelText: BitmapText

    constructor(spriteMeta: SpriteMeta) {
        super(spriteMeta)

        this.labelText = new BitmapText("", {
            fontName: "SignFont",
            align: "center",
        })
        this.labelText.visible = false
        this.labelText.x = -this.container.width * 0.23
        this.labelText.y = -this.container.height * 0.3
        this.addChild(this.labelText)
    }

    updateName(name: string) {
        if (name) {
            this.labelText.text = name.replace(/ /g, "\n")
            this.labelText.visible = true
        } else {
            this.labelText.visible = false
        }
    }
}
