import { Application } from "pixi.js"
import { ClientGameLogic } from "../../../client_game_logic"
import { ClientRenderer, RenderingFactory } from "../../../client_models"
import { PixiJsClientRenderer } from "./pixijs_client_renderer"

export class PixiJsRenderingFactory implements RenderingFactory {
    private app: Application

    constructor(app: Application) {
        this.app = app
    }

    create = (logic: ClientGameLogic): ClientRenderer => {
        return new PixiJsClientRenderer(this.app, logic)
    }
}
