import { Race } from "../character/character"
import { PsychProfile } from "../models"
import { EntityMechanics } from "./entity_mechanics"

export interface CreatureBaselineMeta {
    maxSpeed: number
    hp: number
    psych: PsychProfile
    scanDistanceTiles?: number
}

export class PopulationMechanics {
    private static default: CreatureBaselineMeta = {
        maxSpeed: EntityMechanics.speed.fromPctOfMax(0),
        hp: EntityMechanics.hitPoints.minimum,
        psych: {
            hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.25),
            chronotype: "diurnal",
            attackPattern: "default",
            aggroResponse: "default",
            closeOnStun: false,
        },
    }

    private static creatureBaselines: Partial<Record<Race, CreatureBaselineMeta>> = {
        slime: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0) * 0.15,
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.1),
                chronotype: "diurnal",
            },
        },
        snake: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0) * 0.4,
            hp: 1,
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.6),
                chronotype: "diurnal",
            },
        },
        deer: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(1.0),
            hp: EntityMechanics.hitPoints.fromPctOfMax(0.1),
            psych: {
                hostility: EntityMechanics.psych.hostility().minimum,
            },
        },
        rabbit: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0.7),
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().minimum,
                chronotype: "diurnal",
            },
        },
        chicken: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0.05),
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().minimum,
                chronotype: "diurnal",
            },
        },
        wasp: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0.05),
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.2),
                chronotype: "diurnal",
                attackPattern: "swoop",
            },
        },
        spikes1: {
            maxSpeed: 0,
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(1.0),
                chronotype: "diurnal",
            },
        },
        redspider: {
            maxSpeed: EntityMechanics.speed.fromPctOfMax(0.05),
            hp: EntityMechanics.hitPoints.minimum,
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.2),
                chronotype: "diurnal",
                closeOnStun: true,
                attackPattern: "v2",
            },
        },
        skeleton: {
            maxSpeed: EntityMechanics.speed.minimum,
            hp: EntityMechanics.hitPoints.fromPctOfMax(0.3),
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.5),
                chronotype: "nocturnal",
                attackPattern: "v2",
            },
            scanDistanceTiles: 6,
        },
        zombie: {
            maxSpeed: 5,
            hp: EntityMechanics.hitPoints.fromPctOfMax(0.3),
            psych: {
                hostility: EntityMechanics.psych.hostility().fromPctOfMax(0.5),
                chronotype: "nocturnal",
                attackPattern: "v2",
            },
            scanDistanceTiles: 6,
        },
    }

    static speed = (race: Race, pctOfMax: number = 1.0) => {
        const baseline = this.creatureBaselines[race] || this.default
        return baseline.maxSpeed * pctOfMax
    }

    static hp = (race: Race, pctOfMax: number = 1.0) => {
        const baseline = this.creatureBaselines[race] || this.default
        return baseline.hp * pctOfMax
    }

    static scanDistanceTiles = (race: Race) => {
        const baseline = this.creatureBaselines[race] || this.default
        return baseline.scanDistanceTiles || 5
    }

    static psych = (race: Race): PsychProfile => (this.creatureBaselines[race] || this.default).psych
}
