import { Callback } from "game-common/models"
import { Graphics } from "pixi.js"

interface ThrobberProps {
    expires?: number
    releaseCallback?: Callback
    blinkInterval?: number
    minAlpha?: number
    maxAlpha?: number
    permanent?: boolean
    cycleCallback?: Callback
}

export class Throbber {
    private flasherInterval: any
    private subject: Graphics
    private expires?: number
    private releaseCallback?: Callback
    private container: Graphics
    private dir: boolean
    private blinkInterval: number
    private minAlpha: number
    private maxAlpha: number
    private permanent?: boolean
    private cycleCallback?: Callback

    constructor(subject: Graphics, props?: ThrobberProps) {
        this.subject = subject
        this.expires = !props?.permanent ? Date.now() + props?.expires : undefined
        this.releaseCallback = props?.releaseCallback
        this.cycleCallback = props?.cycleCallback
        this.blinkInterval = props?.blinkInterval || 100
        this.minAlpha = props?.minAlpha || 0.4
        this.maxAlpha = props?.maxAlpha || 1.5
    }

    private release = () => {
        this.subject.alpha = 1.0
        if (this.releaseCallback) {
            this.releaseCallback()
        }
        let cycles = 0
        const releaseInterval = setInterval(() => {
            if (this.subject.alpha >= 1.0 && cycles > 0) {
                clearInterval(releaseInterval)
                return
            }
            this.subject.alpha += this.dir ? 0.1 : -0.1
            if (this.subject.alpha <= 0.4 || this.subject.alpha >= 1.5) {
                this.dir = !this.dir
                cycles++
            }
        }, this.blinkInterval)
    }

    update = () => {
        if (this.flasherInterval) {
            return
        }
        this.flasherInterval = setInterval(() => {
            this.subject.alpha += this.dir ? 0.1 : -0.1
            if (this.subject.alpha <= this.minAlpha || this.subject.alpha >= this.maxAlpha) {
                this.dir = !this.dir
                if (this.cycleCallback) {
                    this.cycleCallback()
                }
            }
            if (!!this.expires && Date.now() > this.expires) {
                this.stop()
                return
            }
        }, this.blinkInterval)
    }

    stop = () => {
        this.release()
        clearInterval(this.flasherInterval)
        this.flasherInterval = undefined
    }
}
