import { ItemTypeMeta } from "game-common/item/item"
import { InteractionType } from "game-common/mechanics/entity_mechanics"
import {
    AnimatedFeedbackParams,
    Coordinate,
    Entity,
    EntityId,
    LightsContainer,
    Location,
    LocationIcon,
    SIGIL_FLAG,
    TileSize,
    WeaponType,
} from "game-common/models"
import { Application, Container, Graphics } from "pixi.js"

import { ClientGameLogic } from "../../../client_game_logic"
import { IEntityRenderingManager } from "../../../client_models"
import { MinimapSurface } from "./minimap_surface"
import { PixiJsClientRenderer } from "./pixijs_client_renderer"
import { BaseRenderable } from "./sprites/base_renderable"
import { BasicSprite } from "./sprites/basic_sprite"
import { Blank } from "./sprites/blank"
import { Bomb } from "./sprites/bomb"
import { Bullet } from "./sprites/bullet"
import { Goal } from "./sprites/goal"
import { LpcComposite } from "./sprites/lpc_composite"
import { Other } from "./sprites/other"
import { Plus } from "./sprites/plus"
import { SignSprite } from "./sprites/sign_sprite"
import { WorldOverlaySurface } from "./world_overlay_surface"
import { Callback } from "game-common/util"
import { TileMapMetaProvider } from "game-common/tile_map_meta_provider"
import { Mechanics } from "game-common/mechanics/mechanics"
import { isMobile } from "../../../client_util"

export type ClientRenderable = Partial<BaseRenderable> & Graphics

export const EntityRenderActionTypes = ["move", "idle", "attack"] as const
export type EntityRenderActions = (typeof EntityRenderActionTypes)[number]

export interface SpriteMeta {
    name?: string
    scale: number
    speed?: number
    animated?: boolean
    color?: number
    alpha?: number
    supportLabel?: boolean
    rotating?: boolean
    looping?: boolean
    onLoopDone?: () => void
    animationSpeed?: number
    rotation?: number
    spriteScale?: number
    tileMapMetaProvider?: TileMapMetaProvider
    isSubmerged?: boolean
}

export interface EntityRenderMeta {
    renderActions: Partial<Record<EntityRenderActions, SpriteMeta>>
    alwaysShowLabel?: boolean
    disallowMoveAttackAnimation?: boolean
    spriteRootName?: string
}

interface EntityMeta {
    name: string
    color: number
    hp: number
    hunger: number
    maxHunger: number
    maxHp: number
    mana: number
    visible: boolean
    level: number
    speed: number
    joinedTeam: boolean
    paralyzeUntilTs: number
    upgradePoints: number
    weaponType?: WeaponType
    apperanceKey?: string
    x: number
    y: number
    expirationTs?: number
    clickable: boolean
    quantity?: number
    spriteScale?: number
    spriteId?: string
    leaderEntityId?: EntityId
    sigil?: string
    submerged?: boolean
    afk?: boolean
}

export class EntityRenderingManager implements IEntityRenderingManager {
    private surface: Container
    private entityIdToRenderable: Record<EntityId, ClientRenderable> = {}
    private logic: ClientGameLogic
    private localEntityMetaCache: Record<EntityId, EntityMeta> = {}
    private clientRenderer: PixiJsClientRenderer
    private minimapSurface: MinimapSurface
    private worldOverlaySurface: WorldOverlaySurface
    private lights: LightsContainer = new LightsContainer()

    constructor(
        surface: Container,
        minimapSurface: MinimapSurface,
        worldOverlaySurface: WorldOverlaySurface,
        logic: ClientGameLogic,
        clientRenderer: PixiJsClientRenderer,
        app: Application,
    ) {
        this.surface = surface
        this.minimapSurface = minimapSurface
        this.worldOverlaySurface = worldOverlaySurface
        this.logic = logic
        this.clientRenderer = clientRenderer
    }

    init = () => {
        // noop, todo?
    }

    cacheEntity = (entity: Entity) => {
        const item: EntityMeta = {
            name: entity.name,
            color: entity.color,
            hp: entity.hp,
            hunger: entity.hunger,
            maxHunger: entity.maxHunger,
            maxHp: entity.maxHp,
            mana: entity.mana,
            visible: entity.visible,
            level: 0,
            speed: entity.speed,
            joinedTeam: undefined,
            paralyzeUntilTs: entity.paralyzeUntilTs,
            upgradePoints: entity.upgradePoints,
            apperanceKey: this.computeAppearanceKey(entity),
            weaponType: entity.weapon?.weaponType,
            x: entity.location.x,
            y: entity.location.y,
            expirationTs: entity.expirationTs,
            clickable: entity.clickable,
            quantity: entity.quantity,
            leaderEntityId: entity.leaderEntityId,
        }
        this.localEntityMetaCache[entity.entityId] = item
        return item
    }

    computeAppearanceKey = (entity: Entity): string => {
        return (
            `${entity.submerged}-` +
            `${entity?.weapon?.weaponType}-` +
            `${entity?.appearance?.race}-` +
            `${entity?.appearance?.sex}-` +
            `${entity?.appearance?.hair}-` +
            `${entity?.appearance?.inventoryAppearanceHash}`
        )
    }

    isPaperdollDifferent = (cachedEntity: EntityMeta, entity: Entity): boolean => {
        return cachedEntity.apperanceKey !== this.computeAppearanceKey(entity)
    }

    renderEntity = (entity: Entity, meta?: SpriteMeta): ClientRenderable => {
        let renderable: ClientRenderable
        switch (entity.npcType) {
            case "flag": {
                renderable = new BasicSprite({ color: entity.color, name: "flag", scale: 0.6, animated: true })
                break
            }
            case "spawner": {
                renderable = new BasicSprite({ color: 0xffffff, name: "spawner", scale: 2.0, animated: true })
                break
            }
            case "bomb": {
                renderable = new BasicSprite({
                    color: 0xffffff,
                    name: "bomb.png",
                    scale: 1.0,
                    animated: false,
                    rotating: true,
                    ...(meta || {}),
                })
                break
            }
            case "emp": {
                renderable = new BasicSprite({
                    color: 0xffffff,
                    name: "emp.png",
                    scale: 1.0,
                    animated: false,
                    rotating: true,
                })
                break
            }
            case "sign": {
                renderable = new SignSprite({
                    color: entity.color,
                    name: "sign.png",
                    scale: 0.55,
                    animated: false,
                })
                break
            }
            case "goal": {
                renderable = new Goal(entity.color)
                break
            }
            case "light": {
                renderable = new Blank()
                break
            }
            case "door": {
                renderable = new BasicSprite({ name: "arch.png", scale: 2.0, animated: false })
                // renderable = new BasicSprite(entity.color, "arch", 2)
                renderable.zIndex = 1000000
                break
            }
            case "lock": {
                renderable = new BasicSprite({ name: "lock.png", scale: 0.5, animated: false })
                break
            }
            case "fire": {
                renderable = new BasicSprite({ color: 0xff0000, name: "fire", scale: 1.0, animated: true })
                break
            }
            case "health":
                renderable = new Plus(0x00ff00, true)
                break
            case "mana": {
                renderable = new Plus(0x399cbd, true)
                break
            }
            case "outpost": {
                renderable = new BasicSprite({
                    name: entity.spriteId,
                    scale: 1.0,
                    color: 0xffffff,
                    rotating: entity.expirationTs !== undefined,
                })
                break
            }
            default: {
                renderable = new LpcComposite(entity.entityId, this.logic.interactionCallback)
            }
        }

        return renderable
    }

    attachClickHandler = (entity: Entity, entityRenderable: ClientRenderable) => {
        const renderable = entityRenderable.getClickSurface()

        renderable.interactive = true
        const unHover = () => {
            this.logic.playerController.unHoveredEntity(entity.entityId)

            if (renderable.active) {
                return
            }

            entityRenderable.onMouseOver(false)
            this.logic.playerController.enabledMouseAction(true)
        }

        renderable.on(isMobile() ? "tap" : "mouseover", () => {
            this.logic.requestEntityInteractions(entity.entityId)
            entityRenderable.onMouseOver(true)
            this.logic.playerController.enabledMouseAction(!renderable.active)
            this.logic.playerController.setHoveredEntity(entity.entityId)

            if (isMobile()) {
                setTimeout(unHover, 1500)
            }
        })
        renderable.on("mouseout", unHover)

        const callback = () => {
            this.logic.entityClicked(entity)
        }
        renderable.on("click", callback)
        renderable.on("tap", callback)
    }

    updateEntityInteractions = (entityId: EntityId, interactions: InteractionType[]) => {
        const renderable = this.entityIdToRenderable[entityId]
        if (!renderable) {
            return
        }
        renderable.updateInteractions(interactions)
    }

    create = (entity: Entity): ClientRenderable => {
        this.cacheEntity(entity)
        const isClientPlayer = this.logic.playerEntity.entityId === entity.entityId

        if (entity.entityType === "player") {
            const renderable: ClientRenderable = new LpcComposite(
                entity.entityId,
                this.logic.interactionCallback,
                undefined,
                isClientPlayer,
            )

            this.entityIdToRenderable[entity.entityId] = renderable
            this.surface.addChild(renderable)
            if (entity.isPlayer()) {
                this.minimapSurface.offer(entity.entityId, isClientPlayer)
            }
            this.attachClickHandler(entity, renderable)
            const worldOverlayItem = this.worldOverlaySurface.offer(entity)
            renderable.setWorldOverlayItem(worldOverlayItem)

            renderable.fadeIn()

            if (this.logic.isTooDistantFromPlayer(entity)) {
                renderable.visible = false
                worldOverlayItem.visible = false
            }

            return renderable
        }

        if (entity.entityType === "bullet") {
            if (entity.spriteId !== "none") {
                const renderable: ClientRenderable = new Bullet(0xffffff)
                // const renderable = new BasicSprite({
                //     name: entity.spriteId,
                //     scale: 1.0,
                //     color: 0xFF0000,
                // })
                renderable.x = entity.location.x
                renderable.y = entity.location.y
                renderable.rotation = entity.movement.angle
                this.entityIdToRenderable[entity.entityId] = renderable

                this.surface.addChild(renderable)
                this.attachClickHandler(entity, renderable)
                return renderable
            }
        }

        if (entity.npcType === "collectable") {
            const renderable = new BasicSprite({
                name: entity.spriteId,
                scale: 1.0,
                color: 0xffffff,
                animated: false,
                supportLabel: true,
                rotating: entity.expirationTs !== undefined,
            })
            renderable.container.rotation = entity.movement.angle
            this.entityIdToRenderable[entity.entityId] = renderable
            if (entity.quantity) {
                renderable.updateName(`${entity.quantity}`)
            }
            renderable.recolor(0xffffff)

            this.surface.addChild(renderable)
            this.attachClickHandler(entity, renderable)

            if (this.logic.isTooDistantFromPlayer(entity)) {
                renderable.visible = false
            }

            return renderable
        }

        if (entity.npcType === "marker") {
            if (entity.spriteId) {
                const renderable = new BasicSprite(
                    {
                        name: entity.spriteId,
                        scale: 1.0,
                        color: 0xffffff,
                        tileMapMetaProvider: this.logic.tilemapMetaProvider,
                    },
                    (_: EntityId, interaction: InteractionType) =>
                        this.logic.interactionCallback(entity.entityId, interaction),
                )
                renderable.rotation = entity.movement.angle
                this.entityIdToRenderable[entity.entityId] = renderable
                this.surface.addChild(renderable)
                this.attachClickHandler(entity, renderable)
                const worldOverlayItem = this.worldOverlaySurface.offer(entity)
                renderable.setWorldOverlayItem(worldOverlayItem)

                if (entity.spriteScale !== undefined) {
                    renderable.scale.set(entity.spriteScale)
                }

                const tileMeta = this.logic.tilemapMetaProvider.resolveTileMeta(entity.spriteId)
                if (tileMeta) {
                    if (tileMeta.renderZIdxOffset) {
                        // renderable.defaultZIndex = tileMeta.renderZIdxOffset
                        renderable.defaultZIndex = tileMeta.renderZIdxOffset
                        renderable.zIndex = renderable.defaultZIndex
                    }
                }

                if (this.logic.isTooDistantFromPlayer(entity)) {
                    renderable.visible = false
                    worldOverlayItem.visible = false
                }
                return renderable
            } else {
                const renderable = new Blank()
                this.entityIdToRenderable[entity.entityId] = renderable
                this.surface.addChild(renderable)
                this.attachClickHandler(entity, renderable)
                if (entity.spriteScale !== undefined) {
                    renderable.scale.set(entity.spriteScale)
                }
                return renderable
            }
        }

        if (entity.entityType === "bomb") {
            const renderable: ClientRenderable = new Bomb(entity)
            renderable.x = entity.location.x
            renderable.y = entity.location.y
            this.entityIdToRenderable[entity.entityId] = renderable

            this.surface.addChild(renderable)
            this.attachClickHandler(entity, renderable)
            return renderable
        }

        let renderable: ClientRenderable = this.renderEntity(entity)
        if (!renderable) {
            return
        }
        renderable.fadeIn()
        this.attachClickHandler(entity, renderable)

        let minimappable = false

        switch (entity.npcType) {
            case "flag":
            case "goal": {
                minimappable = true
                break
            }
            case "door": {
                minimappable = true
                break
            }
            default: {
                if (entity.isPlayer()) {
                    // if (entity.npcType === "zombie") {
                    minimappable = true
                } else {
                    minimappable = false
                }
            }
        }

        if (minimappable) {
            this.minimapSurface.offer(entity.entityId)
        }

        this.entityIdToRenderable[entity.entityId] = renderable
        this.surface.addChild(renderable)
        const worldOverlayItem = this.worldOverlaySurface.offer(entity)
        renderable.setWorldOverlayItem(worldOverlayItem)

        if (this.logic.isTooDistantFromPlayer(entity)) {
            renderable.visible = false
            worldOverlayItem.visible = false
        }
        return renderable
    }

    private get = (entity: Entity): ClientRenderable | undefined => {
        return this.entityIdToRenderable[entity.entityId]
    }

    private isClientPlayer = (entity: Entity) => entity?.entityId === this.clientRenderer.logic().playerEntity.entityId

    updateEntityId = (originalEntityId: EntityId, updatedEntityId: EntityId) => {
        this.entityIdToRenderable[updatedEntityId] = this.entityIdToRenderable[originalEntityId]
        delete this.entityIdToRenderable[originalEntityId]

        this.localEntityMetaCache[updatedEntityId] = this.localEntityMetaCache[originalEntityId]
        delete this.localEntityMetaCache[originalEntityId]

        this.worldOverlaySurface.updateEntityId(originalEntityId, updatedEntityId)
    }

    isSubmerged = (entity: Entity) => {
        return this.logic.tilemapMetaProvider?.isDeepAt(
            Math.floor(entity.location.x / TileSize),
            Math.floor(entity.location.y / TileSize),
        )
    }

    update = (entity: Entity) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }
        const minimapRenderable = this.minimapSurface.get(entity.entityId)

        let cachedEntity: EntityMeta = this.localEntityMetaCache[entity.entityId]
        const wasCached = !!cachedEntity
        if (!cachedEntity) {
            this.cacheEntity(entity)
        }

        if (cachedEntity?.expirationTs !== entity.expirationTs) {
            renderable.updateRotating(entity.expirationTs !== undefined)
        }

        if (cachedEntity?.name !== entity.name) {
            renderable.updateName(entity.name || "")
            cachedEntity.name = entity.name

            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
        }

        if (cachedEntity?.color !== entity.color) {
            renderable.recolor(entity.color)
            cachedEntity.color = entity.color
        }

        if (cachedEntity?.spriteScale !== entity.spriteScale) {
            renderable.updateScale(entity.spriteScale)
            cachedEntity.spriteScale = entity.spriteScale
        }

        if (cachedEntity?.quantity !== entity.quantity) {
            renderable.updateName(entity.quantity > 1 ? `${entity.quantity}` : "")
            cachedEntity.quantity = entity.quantity
        }

        if (cachedEntity?.clickable !== entity.clickable) {
            this.attachClickHandler(entity, renderable)
            cachedEntity.clickable = entity.clickable
        }

        if (cachedEntity.submerged !== entity.submerged) {
            renderable.updateSubmerged(entity.submerged)
            cachedEntity.submerged = entity.submerged
        }

        if (this.isPaperdollDifferent(cachedEntity, entity)) {
            cachedEntity.apperanceKey = this.computeAppearanceKey(entity)
            renderable.updatePaperdoll(entity)
        }

        if (cachedEntity?.hp !== entity.hp) {
            renderable.update(entity.hp, entity.maxHp)
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.hp = entity.hp
        }

        if (cachedEntity?.hunger !== entity.hunger) {
            renderable.update(entity.hp, entity.maxHunger)
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.hunger = entity.hunger
        }

        if (cachedEntity?.maxHp !== entity.maxHp) {
            renderable.update(entity.hp, entity.maxHp)
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.maxHp = entity.maxHp
        }

        if (cachedEntity?.mana !== entity.mana) {
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.mana = entity.mana
        }

        if (cachedEntity?.upgradePoints !== entity.upgradePoints) {
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.upgradePoints = entity.upgradePoints
        }

        if (cachedEntity?.speed !== entity.speed) {
            const factor = entity.speed / entity.maxSpeed
            renderable.updateSpeed(factor)
            cachedEntity.speed = entity.speed
        }

        if (cachedEntity?.upgradePoints !== entity.upgradePoints) {
            if (this.isClientPlayer(entity)) {
                this.clientRenderer.gui().update(entity)
            }
            cachedEntity.upgradePoints = entity.upgradePoints
        }

        if (cachedEntity?.joinedTeam !== entity.joinedTeam) {
            cachedEntity.joinedTeam = entity.joinedTeam
            if (entity.joinedTeam) {
                renderable.addTag()
            } else {
                renderable.removeTag()
            }
        }

        if (cachedEntity?.sigil !== entity?.flags[SIGIL_FLAG]) {
            cachedEntity.sigil = entity?.flags[SIGIL_FLAG]
            renderable.updateSigil(cachedEntity.sigil)
        }

        if (cachedEntity?.spriteId !== entity?.spriteId) {
            cachedEntity.spriteId = entity?.spriteId
            renderable.updateSpriteId(cachedEntity.spriteId)
        }

        if (cachedEntity.visible !== entity.visible) {
            if (!entity.visible) {
                renderable.recolor(0xffffff80)
                if (minimapRenderable) {
                    minimapRenderable.visible = false
                }
            } else {
                renderable.recolor(entity.color)
                if (minimapRenderable) {
                    minimapRenderable.visible = true
                }
            }
            cachedEntity.visible = entity.visible
            if (this.isClientPlayer(entity)) {
                // renderable.updateBlinking(!entity.visible)
                if (entity.visible) {
                    renderable.alpha = 1.0
                } else {
                    renderable.alpha = 0.0
                }
            }
        }

        if (cachedEntity.afk !== entity.afk) {
            cachedEntity.afk = entity.afk
            renderable.updateAfk(cachedEntity.afk)
        }

        if (cachedEntity.level !== entity.level) {
            renderable.updateLevel(entity.level || 1)
            cachedEntity.level = entity.level
        }
        if (Mechanics.entity.body.isFlat(entity)) {
            renderable.zIndex = 0
        } else {
            const zIndexBoost = Mechanics.entity.body.calculateZIndexBoost(entity)
            renderable.zIndex = zIndexBoost + renderable.defaultZIndex || 0
        }

        const locationChanged = !(cachedEntity.x === entity.location.x && cachedEntity.y === entity.location.y)
        const entityLight = this.lights.get(entity.entityId)
        const lightChanged =
            !this.lights.contains(entity.entityId) ||
            entityLight?.intensity !== entity.lightMeta?.intensity ||
            entityLight?.radius !== entity.lightMeta?.radius

        if (locationChanged) {
            entity.submerged = this.isSubmerged(entity)
        }

        if (
            ((this.isClientPlayer(entity) || entity.lightMeta) && (locationChanged || lightChanged)) ||
            (entity.npcType === "light" && lightChanged)
        ) {
            this.lights.set(entity.entityId, {
                entityId: entity.entityId,
                location: entity.location,
                intensity: entity.lightIntensity(),
                radius: entity.lightRadius(),
            })
            cachedEntity.x = entity.location.x
            cachedEntity.y = entity.location.y
        }
        if (!wasCached || cachedEntity?.leaderEntityId !== entity.leaderEntityId) {
            if (this.logic.playerEntity.entityId !== entity.leaderEntityId) {
                // renderable.updateBlinking(true)
                renderable.updateHalo(entity, false)
            } else {
                // renderable.updateBlinking(false)
                renderable.updateHalo(entity, true)
            }
            cachedEntity.leaderEntityId = entity.leaderEntityId
        }
    }

    destroy = (entity: Entity) => {
        delete this.localEntityMetaCache[entity.entityId]
        this.minimapSurface.remove(entity.entityId)

        const renderable = this.entityIdToRenderable[entity.entityId]
        if (renderable) {
            renderable.remove()
            delete this.entityIdToRenderable[entity.entityId]
            if (renderable.sideCar) {
                renderable.sideCar.remove()
            }
        }
        this.worldOverlaySurface.remove(entity)
        this.lights.remove(entity.entityId)
    }

    render = (entity: Entity, playerEntityId: EntityId) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        if (!this.logic.isTooDistantFromPlayer(entity)) {
            if (entity.entityId !== playerEntityId) {
                renderable.visible = entity.visible
            }

            renderable.updateRenderedLocationFrom(entity)
            renderable.doMovement(entity)
            this.worldOverlaySurface.updateEntity(entity)
        }

        if (!this.logic.clientRenderer.map().getVisibility()?.suppressMinimap) {
            this.minimapSurface.updateEntity(entity.entityId, entity.roomId, entity.location)
        }
    }

    detonate = (entity: Entity) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.detonate()
    }

    emp = (entity: Entity, range: number) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        const halo = new Graphics()
        this.surface.addChild(halo)

        halo.beginFill(0xff000080, 0.25)
        halo.drawCircle(1, 5, 5)
        halo.endFill()
        halo.x = renderable.x
        halo.y = renderable.y

        let scale = 1.0
        let roomId = entity.roomId
        const expandInterval = setInterval(() => {
            scale += 0.5
            halo.scale.set(scale)
            halo.x = renderable.x - halo.width * 0.1
            halo.y = renderable.y - halo.height / 2
            if (halo.height > range) {
                clearInterval(expandInterval)
                let alpha = 1.0

                let startTs = Date.now()
                const contractInterval = setInterval(() => {
                    const changedRooms = roomId !== entity.roomId
                    if (Date.now() - startTs > 3000 || changedRooms) {
                        clearInterval(contractInterval)
                        if (changedRooms) {
                            this.surface.removeChild(halo)
                        } else {
                            setInterval(() => {
                                alpha -= 0.01
                                halo.alpha = alpha
                                if (alpha <= 0.0) {
                                    this.surface.removeChild(halo)
                                }
                            }, 5)
                        }
                    }
                }, 250)
            }
        }, 4)
    }

    attack = (entity: Entity, callback?: Callback<void>) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.doAttack(entity, callback)
    }

    updateActivation = (entity: Entity, active: boolean) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.updateActivation(entity, active)
    }

    animateStrikeOn = (target: Entity) => {
        const struckEffect = new Other(0xff0000, 10)
        const renderable = this.get(target)
        if (!renderable) {
            return
        }
        // struckEffect.x = renderable.x
        // struckEffect.y = renderable.y
        // struckEffect.zIndex = renderable.zIndex + 1
        // this.surface.addChild(struckEffect)

        // struckEffect.alpha = 0.5

        // let startTS = Date.now()
        // let totalDuration = 150
        // let scale = 0
        // let incr = 0
        // const itr = setInterval(() => {
        //     const duration = Date.now() - startTS

        //     incr = duration > totalDuration / 2 ? -1 : 1
        //     scale += incr
        //     struckEffect.scale.set(scale, scale)
        //     if (duration > totalDuration) {
        //         clearInterval(itr)
        //         this.surface?.removeChild(struckEffect)
        //     }
        // }, 17)

        renderable.doDamaged(target)
    }

    spoke = (entity: Entity, message: string) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.spoke(message)
    }

    death = (entity: Entity) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.fragment(entity)
    }

    getLights = (): LightsContainer | undefined => {
        return this.lights
    }

    clearBiomeColorMap = () => {
        this.minimapSurface.clearBiomeColorMap()
    }

    updateLocationIcons = (icon: LocationIcon) => {
        this.minimapSurface.updateLocationIcon(icon)
    }

    updatePlayerLocations = (playerLocations: Record<EntityId, Location>) => {
        const clientPlayerEntityId = this.clientRenderer.logic().playerEntity.entityId
        if (!clientPlayerEntityId) {
            return
        }

        if (!playerLocations) {
            return
        }

        this.minimapSurface.entities().forEach(entityId => {
            // remove any players not present in the latest
            // player location manifest sent from the server
            const entity = this.logic.entityManager.get(entityId)
            if (!playerLocations[entityId] || entity?.roomId !== this.clientRenderer.logic().playerEntity.roomId) {
                this.minimapSurface.remove(entityId)
            }
        })

        Object.keys(playerLocations).forEach((entityId: EntityId) => {
            const location = playerLocations[entityId]

            if (location?.quadrantId && location.quadrantId !== this.clientRenderer.logic().quadrantId) {
                return
            }

            this.minimapSurface.offer(entityId)

            this.minimapSurface.updateEntity(
                entityId,
                location.roomId,
                {
                    x: location.x,
                    y: location.y,
                },
                true,
            )
        })
    }

    pulseLocation = (locationId: string, expires?: number) => {
        this.minimapSurface.pulseLocation(locationId, expires)
    }

    updateEntityPointerIcon = (entity: Entity, show: boolean, angle?: number) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.updatePointerIcon(show, angle)
    }

    itemPickedUp = (entity: Entity, itemTypeMeta: ItemTypeMeta, quantity: number) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.itemPickedUp(entity, itemTypeMeta, quantity)
    }

    feedback = (entity: Entity, image: string, label: string, params: AnimatedFeedbackParams) => {
        const renderable = this.get(entity)
        if (!renderable) {
            return
        }

        renderable.feedback(entity, image, label, params)
    }

    fragment = (spriteId: string, location: Coordinate) => {
        const renderable = new BasicSprite({
            name: spriteId,
            scale: 1.0,
            color: 0xffffff,
        })

        const fragmentContainer = new Graphics()
        fragmentContainer.x = location.x
        fragmentContainer.y = location.y - renderable.height * 0.25
        fragmentContainer.addChild(renderable)
        this.surface.addChild(fragmentContainer)

        renderable.fragment(null, () => {
            renderable.visible = false
        })

        setTimeout(() => {
            this.surface.removeChild(fragmentContainer)
        }, 1500)
    }
}

export const fineToRoughCoordinates = (fineCoordinate: Coordinate) => {
    return { x: Math.round(fineCoordinate.x / TileSize), y: Math.round(fineCoordinate.y / TileSize) }
}
